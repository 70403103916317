import React, { useEffect, useState } from "react";
import { DatePicker } from "orcs-design-system";
import PropTypes from "prop-types";

import { DEFAULT_DATE_FORMAT } from "src/consts/global";
import { useCheckDays } from "./useCheckDays";

const MembershipDate = ({
  membership,
  onDatesChange,
  isPastEditEnabled,
  hasError,
}) => {
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    if (hasError) {
      setFocusedInput(null);
    }
  }, [hasError]);

  const { startDate, endDate } = membership;
  const { disabled, initialVisibleMonth, isOutsideRange, isDayHighlighted } =
    useCheckDays({
      startDate,
      endDate,
      isPastEditEnabled,
      focusedInput,
    });

  return (
    <DatePicker
      range
      small
      daySize={36}
      readOnly
      showClearDates={!!endDate}
      startDate={startDate}
      endDate={endDate}
      initialVisibleMonth={initialVisibleMonth}
      focusedInput={focusedInput}
      onFocusChange={setFocusedInput}
      onDatesChange={onDatesChange}
      displayFormat={DEFAULT_DATE_FORMAT}
      height="38px"
      anchorDirection="right"
      startDatePlaceholderText="Start Date"
      endDatePlaceholderText="Ongoing"
      minimumNights={0}
      isDayHighlighted={isDayHighlighted}
      isOutsideRange={isOutsideRange}
      disabled={disabled}
      invalid={hasError}
    />
  );
};

MembershipDate.propTypes = {
  membership: PropTypes.object,
  onDatesChange: PropTypes.func,
  isPastEditEnabled: PropTypes.bool,
  hasError: PropTypes.bool,
};

export default MembershipDate;
