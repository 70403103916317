import React, { useMemo } from "react";
import { map, get, values, filter } from "lodash";
import { TAGS } from "src/consts/comments";
import CommentContextProvider from "src/comments/context";
import {
  useForecastContext,
  useMainQuery,
  PAGE_MODES,
} from "./ForecastContext";

const createPlannerCountTags = (activeAllocationProject) => {
  const filters = [TAGS.USER_COMMENT, TAGS.ALLOCATION.COMMENT];

  if (activeAllocationProject) {
    filters.push([TAGS.ALLOCATION_PROJECT_ID(activeAllocationProject.id)]);
  }

  return {
    filters,
  };
};

const createForecastCountTags = (allocationProjects) => {
  const forecastAllocationProjects = filter(
    allocationProjects,
    (ap) => ap.forecast || ap.isActive
  );

  const allocationProjectTags = map(
    forecastAllocationProjects,
    (allocationProject) => TAGS.ALLOCATION_PROJECT_ID(allocationProject.id)
  );

  return {
    filters: [
      [TAGS.USER_COMMENT],
      [TAGS.ALLOCATION.COMMENT],
      allocationProjectTags,
    ],
  };
};

const withCommentContextProvider = (BaseComponent) => (props) => {
  const [{ pageMode, model, lookupData }] = useForecastContext();
  const { loading } = useMainQuery();

  const { activeAllocationProject } = lookupData;
  const allocationProjects = values(
    get(lookupData, "historicAllocationProjectsLookup")
  );
  const rootGroupId = get(model, "rootGroup.id");
  const isDemand = get(model, "rootGroup.isDemand");
  const isPlanner = pageMode === PAGE_MODES.PLANNER;
  const isForecast = pageMode === PAGE_MODES.FORECAST;

  const countTags = useMemo(
    () =>
      isPlanner
        ? createPlannerCountTags(activeAllocationProject)
        : createForecastCountTags(allocationProjects),
    [isPlanner, activeAllocationProject, allocationProjects]
  );

  return useMemo(
    () => {
      return (
        <CommentContextProvider
          showActiveAllocationProjects
          showHistoricAllocationProjects={isPlanner}
          showForecastAllocationProjects={isForecast}
          groupId={rootGroupId}
          saveTags={[
            TAGS.USER_COMMENT,
            TAGS.ALLOCATION.COMMENT,
            isPlanner ? TAGS.ALLOCATION.PLANNING : TAGS.ALLOCATION.FORECASTING,
            isDemand ? TAGS.ALLOCATION.DEMAND : TAGS.ALLOCATION.SUPPLY,
          ]}
          countTags={countTags}
          queryTags={{
            filters: [
              [TAGS.ALLOCATION.COMMENT],
              [
                TAGS.USER_COMMENT,
                TAGS.ALLOCATION.ACTION.FORECASTED_FTE,
                TAGS.ALLOCATION.ACTION.FORECASTED_SKILLS,
                TAGS.ALLOCATION.ACTION.CONFIDENCE,
                TAGS.ALLOCATION.ACTION.REQUEST_SUBMITTED,
                TAGS.ALLOCATION.ACTION.APPROVAL_SUBMITTED,
                isDemand
                  ? TAGS.ALLOCATION.ACTION.REQUESTED
                  : TAGS.ALLOCATION.ACTION.APPROVED,
              ],
            ],
          }}
          isReady={!loading}
        >
          <BaseComponent {...props} />
        </CommentContextProvider>
      );
    },
    [allocationProjects, rootGroupId] // eslint-disable-line
  );
};

export default withCommentContextProvider;
