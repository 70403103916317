export const MAIN_QUERY_COMPLETE = "MAIN_QUERY_COMPLETE";
export const MAIN_QUERY_ERROR = "MAIN_QUERY_ERROR";
export const MAIN_QUERY_REFRESH = "MAIN_QUERY_REFRESH";
export const SECONDARY_QUERY_COMPLETE = "SECONDARY_QUERY_COMPLETE";
export const SECONDARY_QUERY_ERROR = "SECONDARY_QUERY_ERROR";
export const SUPPLY_GROUP_SELECTED = "SUPPLY_GROUP_SELECTED";
export const SUB_SUPPLY_GROUP_SELECTED = "SUB_SUPPLY_GROUP_SELECTED";
export const SUB_SUPPLY_GROUP_CLEARED = "SUB_SUPPLY_GROUP_CLEARED";
export const TARGET_GROUP_SEARCHED = "TARGET_GROUP_SEARCHED";
export const PERSON_SEARCHED = "PERSON_SEARCHED";
export const PERSON_FILTER_SELECTED = "PERSON_FILTER_SELECTED";
export const OPEN_PERSON_ALLOCATIONS = "OPEN_PERSON_ALLOCATIONS";
export const CLOSE_PERSON_ALLOCATIONS = "CLOSE_PERSON_ALLOCATIONS";
export const PERSON_ALLOCATIONS_UPDATED = "PERSON_ALLOCATIONS_UPDATED";
