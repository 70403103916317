import { get, findIndex, toLower, map } from "lodash";

import { MIN_TEAM_NAME_LENGTH } from "src/consts/global";

/**
 * Validate the team name to make sure:
 * 1. Team name length is at least 3 characters (excluding leading and trailing spaces).
 * 2. The new team name should not be duplicated as the sibling teams (case insenstive).
 *
 * @param {*} team
 * @param {*} parentGroup
 * @param {*} setErrorMessage
 */
export const validateTeamName = (team, parentGroup, setErrorMessage) => {
  if (team.name.length < MIN_TEAM_NAME_LENGTH) {
    setErrorMessage("Minimum name length is 3 characters.");
    return false;
  }

  const siblingTeams = get(parentGroup, "childTeams", []);
  if (
    findIndex(
      siblingTeams,
      (sibling) => toLower(sibling.name) === toLower(team.name)
    ) >= 0
  ) {
    setErrorMessage(
      `Duplicate name '${team.name}' found in the same parent group.`
    );
    return false;
  }

  setErrorMessage(null);
  return true;
};

/*
 * Validate team: name, parent and type
 *
 */
export const validateTeam = (team, parentGroup, setErrorMessage) => {
  if (!validateTeamName(team, parentGroup, setErrorMessage)) {
    return false;
  }

  if (!team.parentId) {
    setErrorMessage("Please select a parent group");
    return false;
  }

  if (!team.type) {
    setErrorMessage("Please select a type");
    return false;
  }

  setErrorMessage(null);
  return true;
};

export const resetDefaults = () => ({
  parentId: "",
  type: "",
  rawName: "",
  name: "",
});

export const getTypeName = (option, groupTypes) => {
  if (!option) {
    return "Team";
  }

  const type = get(groupTypes, option.value, {});

  return type.shortName || type.name || "Team";
};

export const createTeamTypesOptions = (groupTypes, selectedParentOption) => {
  if (!selectedParentOption) {
    return [];
  }

  const type = get(selectedParentOption, "type");
  const childTypes = get(groupTypes[type], "childTypes", []);

  return map(childTypes, (t) => ({
    value: t,
    label: get(groupTypes[t], "name", t),
  }));
};
