import { Button, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import icons from "src/config/icons";

const EditDescriptionButton = ({ onSetEditMode, showDescription }) => {
  return (
    <Button
      variant="ghost"
      small
      iconLeft
      data-testid="details-change-description-button"
      onClick={onSetEditMode}
    >
      <Icon icon={icons.pen} />
      {`${showDescription ? "Edit" : "Add"} details`}
    </Button>
  );
};

EditDescriptionButton.propTypes = {
  onSetEditMode: PropTypes.func,
  showDescription: PropTypes.bool,
};

export default EditDescriptionButton;
