import { get, sum, set } from "lodash";

const VERTICAL_SPACING = 100; // Spacing between ranks
const HORIZONTAL_SPACING = 100; // Base spacing between siblings
const MIN_NODE_WIDTH = 200; // Minimum width to allocate per node

let anchorPositions = {};
let currentViewId = null;

export const getLayout = (nodes, edges) => {
  const nextViewId = nodes.find((node) =>
    window.location.pathname.includes(node.id)
  )?.id;

  // Reset anchor positions if we are on a different page (determined by node.id found in window.location.pathname)
  if (currentViewId !== nextViewId) {
    anchorPositions = {};
    currentViewId = nextViewId;
  }

  // Filter out hidden nodes first
  const visibleNodes = nodes.filter((node) => !node.data?.hidden);
  const visibleEdges = edges.filter((edge) => {
    const sourceVisible = visibleNodes.some((node) => node.id === edge.source);
    const targetVisible = visibleNodes.some((node) => node.id === edge.target);
    return sourceVisible && targetVisible;
  });

  // Create maps for incoming and outgoing edges for each node
  const incomingEdges = {};
  const outgoingEdges = {};

  // Initialize edge maps
  visibleNodes.forEach((node) => {
    incomingEdges[node.id] = [];
    outgoingEdges[node.id] = [];
  });

  // Populate edge maps
  visibleEdges.forEach((edge) => {
    incomingEdges[edge.target].push(edge);
    outgoingEdges[edge.source].push(edge);
  });

  // Function to get node rank by traversing up through incoming edges
  const getNodeRank = (nodeId, visited = new Set()) => {
    if (visited.has(nodeId)) return 0;
    visited.add(nodeId);

    // If no incoming edges, this is a root node (rank 0)
    if (!incomingEdges[nodeId]?.length) return 0;

    // Get the maximum rank of all parent nodes + 1
    const parentRanks = incomingEdges[nodeId].map((edge) =>
      getNodeRank(edge.source, visited)
    );

    return Math.max(...parentRanks, 0) + 1;
  };

  // Calculate ranks and organize nodes by rank
  const nodeRanks = {}; // Store individual node ranks
  const rankGroups = {}; // Group nodes by rank

  visibleNodes.forEach((node) => {
    const rank = getNodeRank(node.id);
    nodeRanks[node.id] = rank;

    // Initialize rank array if it doesn't exist
    if (!rankGroups[rank]) {
      rankGroups[rank] = [];
    }

    // Add node to its rank group
    rankGroups[rank].push(node);
  });

  // Calculate Y positions based on rank
  const rankYPositions = {};
  let currentY = 0;

  Object.keys(rankGroups)
    // Sort ranks in ascending order

    .sort((a, b) => Number(a) - Number(b))
    // Calculate Y positions based on maxHeight of each rank. This determines where the next rank will start.
    .forEach((rank) => {
      rankYPositions[rank] = currentY;
      const maxHeight = Math.max(
        ...rankGroups[rank].map((node) => node.height || 0)
      );
      currentY += maxHeight + VERTICAL_SPACING;
    });

  // Final positioned nodes will be stored here
  const positionedNodes = [];

  Object.entries(rankGroups).forEach(([rank, noods]) => {
    // Calculate overall width of this rank
    const overallRankWidth = sum(
      noods.map((node) => (node.width || 0) + HORIZONTAL_SPACING)
    );
    // Keep track of current X position. Start with total width divided by 2 to center the rank
    let currentX = -(overallRankWidth / 2);

    // Position nodes in this rank
    noods.forEach((node, index) => {
      // Get anchored position if it exists
      const anchoredPosition = get(anchorPositions, [node.id]);

      // Get Y position for this rank (from previously calculated Y positions)
      const y = rankYPositions[rank];

      // Calculate X position based on current X and index
      let x = currentX + index * HORIZONTAL_SPACING;

      // Increment current X by node width to determine next starting X
      currentX += node.width || MIN_NODE_WIDTH;

      // If anchored position exists, use it and update current X
      if (anchoredPosition) {
        x = anchoredPosition.x;
        currentX = x + node.width + HORIZONTAL_SPACING;
      }

      positionedNodes.push({
        ...node,
        rank,
        targetPosition: "top",
        sourcePosition: "bottom",
        position: { y, x },
      });
    });
  });

  // Track positions if they are anchor nodes
  positionedNodes.forEach((node) => {
    if (node.isAnchor) {
      set(anchorPositions, [node.id], node.position);
    }
  });

  return [positionedNodes, edges, currentViewId, anchorPositions];
};
