import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Box } from "orcs-design-system";

import FteBadge from "src/components/TeamDetailPage/FteBadge";
import PersonAvatarAndDetails from "src/components/Person/PersonItem/sub-components/PersonAvatarAndDetails";
import { getAltBadgeGroups } from "src/util/group";
import { getPersonContainerId } from "./utils";

const GroupHeader = ({
  person,
  team,
  groupTypes,
  allocationProject,
  onEditingAllocations,
  workspace,
}) => {
  const badgeGroups = getAltBadgeGroups({
    group: team,
    person,
    groupTypes,
  });

  const renderMemberFteBadge = useMemo(() => {
    return (member, memberInTeam) => (
      <FteBadge workspace={workspace} member={member} team={memberInTeam} />
    );
  }, [workspace]);

  return ReactDOM.createPortal(
    <Box id={`person-${person.aggregateId}`}>
      <PersonAvatarAndDetails
        person={person}
        badgeGroups={badgeGroups}
        groupTypes={groupTypes}
        group={team}
        cardView={false}
        isDisplayActionsButton={true}
        canChangeAllocations={true}
        onEditingAllocations={() => onEditingAllocations(person)}
        renderFteBadge={renderMemberFteBadge}
        workspace={workspace}
        allocationProject={allocationProject}
      />
    </Box>,
    document.querySelector(`#${getPersonContainerId(person)}`)
  );
};

GroupHeader.propTypes = {
  person: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  allocationProject: PropTypes.object.isRequired,
  groupTypes: PropTypes.object.isRequired,
  onEditingAllocations: PropTypes.func.isRequired,
  workspace: PropTypes.object.isRequired,
};

export default GroupHeader;
