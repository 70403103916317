import { useContext, useMemo } from "react";
import MentionContext from "./MentionContext";

const useMention = () => {
  const { KeyDownHandler, ChangeHandler, clearMentionSearchState } =
    useContext(MentionContext);

  const value = useMemo(
    () => ({ KeyDownHandler, ChangeHandler, clearMentionSearchState }),
    [KeyDownHandler, ChangeHandler, clearMentionSearchState]
  );

  return value;
};

export default useMention;
