import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Loading } from "orcs-design-system";
import TagsExpandHeader from "./TagsExpandHeader";
import TagItems from "./TagItems";

const Tags = ({ grouping, isTagsExpanded, dispatch, inlineStyle = false }) => {
  return (
    <>
      <TagsExpandHeader
        loading={false}
        isTagsExpanded={isTagsExpanded}
        grouping={grouping}
        dispatch={dispatch}
        inlineStyle={inlineStyle}
      />
      <Suspense fallback={<Loading small ml="r" />}>
        <TagItems
          grouping={grouping}
          isTagsExpanded={isTagsExpanded}
          dispatch={dispatch}
        />
      </Suspense>
    </>
  );
};

Tags.propTypes = {
  isTagsExpanded: PropTypes.bool,
  inlineStyle: PropTypes.bool,
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Tags;
