import React from "react";
import PropTypes from "prop-types";
import ChapterModal from "src/allocation/components/EnhancedAddNewRoleModal/ChapterModal";
import PlaceholderModal from "src/allocation/components/EnhancedAddNewRoleModal/PlaceholderModal";
import EnhancedAddRoleModal from "./EnhancedAddRoleModal";

const DisplayAddRoleModal = ({ enableAddVacantRole, isPlannerPage }) => {
  let modalComponent = ChapterModal;

  if (isPlannerPage && enableAddVacantRole) {
    modalComponent = PlaceholderModal;
  }

  return <EnhancedAddRoleModal Component={modalComponent} />;
};

DisplayAddRoleModal.propTypes = {
  enableAddVacantRole: PropTypes.bool,
  isPlannerPage: PropTypes.bool,
};

export default DisplayAddRoleModal;
