import React from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";

import {
  useLookupData,
  useRootGroup,
  useSearchMode,
  ACTIONS,
} from "../../../context/ForecastContext";
import { shouldLoadIndirectMembers } from "../../util/groupTypes";

const AddVacantRoleRow = ({ lineItem, dispatch }) => {
  const searchMode = useSearchMode();
  const rootGroup = useRootGroup();
  const { groupLookup, groupTypesLookup, vacantRoleLabel } = useLookupData();

  if (searchMode || !rootGroup.isDemand) {
    return null;
  }

  const handleAddVacantRole = () => {
    const sourceGroupId = lineItem.group.id;
    const targetGroupId = lineItem.grouping.id;

    dispatch({
      type: ACTIONS.OPEN_ADD_VACANT_ROLE_MODAL,
      searchVariables: {
        sourceGroupId,
        targetGroupId,
        includeIndirectTeamMembers: shouldLoadIndirectMembers(
          sourceGroupId,
          groupLookup,
          groupTypesLookup
        ),
        size: 1000,
      },
      sourceGroupId,
      targetGroupId,
      lineItem,
    });
  };

  return (
    <ActionsMenuItem
      onClick={handleAddVacantRole}
      data-testid="add-vacant-role-button"
    >{`Add ${vacantRoleLabel || "vacant role"}`}</ActionsMenuItem>
  );
};

AddVacantRoleRow.propTypes = {
  lineItem: PropTypes.object,
  dispatch: PropTypes.func,
};

export default AddVacantRoleRow;
