import React, { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import debug from "debug";
import HideTeamDialog from "src/allocation/components/HideTeamModal/HideTeamDialog";

import { markGroupAsHidden } from "src/allocation/team.graphql";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";

import { useForecastContext, ACTIONS } from "../../context/ForecastContext";

const debugLog = debug("orchestrated:hide-team");

const HideTeamModal = () => {
  const [
    {
      lookupData: { groupLookup },
      userInteraction: {
        hidingTeam: { isOpen, groupId },
      },
    },
    dispatch,
  ] = useForecastContext();

  // manage separate mutation error state, so we can clear it
  const [mutationError, setMutationError] = useState();
  const [onHideTeamMutation, { loading: mutationLoading }] = useMutation(
    markGroupAsHidden,
    {
      onCompleted: () => {
        dispatch({ type: ACTIONS.TEAM_HIDDEN, groupId });
        dispatch({ type: ACTIONS.MAIN_QUERY_REFRESH, groupId });
      },
      onError: (err) => {
        setMutationError(err);
      },
    }
  );

  const handleHideTeam = useCallback(
    () => async () => {
      try {
        await onHideTeamMutation({
          variables: {
            id: groupId,
            isHidden: true,
          },
        });

        eventEmitter.emit(EVENTS.GROUP_REMOVED, groupLookup[groupId]);
      } catch (err) {
        // intentionally swallowed, 'mutationError' handles error.
        debugLog(err);
      }
    },
    [onHideTeamMutation, groupId, groupLookup]
  );

  const handleCloseModal = useCallback(() => {
    if (!mutationLoading) {
      setMutationError(null);
      dispatch({
        type: ACTIONS.CLOSE_HIDE_TEAM_MODAL,
      });
      dispatch({
        type: ACTIONS.MAIN_QUERY_REFRESH,
        backgroundRefresh: true,
      });
    }
  }, [dispatch, setMutationError, mutationLoading]);

  if (!isOpen) {
    return null;
  }

  const selectedGroup = groupLookup[groupId];
  if (isOpen) {
    return (
      <HideTeamDialog
        selectedTargetGroup={selectedGroup}
        visible={isOpen}
        onDismissHideTeamModal={handleCloseModal}
        onHideTeam={handleHideTeam}
        loading={mutationLoading}
        error={mutationError}
      />
    );
  }

  return null;
};

export default HideTeamModal;
