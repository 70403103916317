import React from "react";
import PropTypes from "prop-types";
import { isNil } from "lodash";
import styled from "styled-components";
import { TextInput, Box, Small, Flex } from "orcs-design-system";
import { roundFte } from "src/util/roundingStrategy";
import { SAVE_STATUS } from "../../../context/ForecastContext";
import useSaveFteForCell from "../../Shared/hooks/useSaveFteForCell";
import FailedIndicator from "../../Shared/FailedIndicator";

const NumberInput = styled(TextInput)`
  width: 50px;
  padding: 5px 8px 6px 8px;
`;

const RequestedFTEInput = ({ cell, dispatch, desiredInputAsDelta }) => {
  const { valueSaveStatus, value, defaultedValue } = cell;

  const { onValueChange, saveFte } = useSaveFteForCell(cell, dispatch);

  const onChange = ({ floatValue = null }) => {
    let realValue = floatValue;

    if (desiredInputAsDelta) {
      realValue = roundFte(defaultedValue - floatValue);
    }
    onValueChange({ floatValue: realValue });
  };

  const onHandleBlur = () => {
    let valueToSave = value;

    // Set to defaultedValue to make sure always saving a number
    if (isNil(valueToSave)) {
      valueToSave = defaultedValue;
    }

    // For real time fte mode, no need to check the existing value, always save
    // reason is there is no tick for this mode, should be always save
    saveFte(valueToSave, false);
  };

  const isValid = valueSaveStatus === SAVE_STATUS.SUCCESS;
  const isInvalid = valueSaveStatus === SAVE_STATUS.FAILED;

  let inputValue = value;
  if (desiredInputAsDelta) {
    inputValue = isNil(value) ? 0 : roundFte(Math.abs(value - defaultedValue));
  }

  return (
    <Box style={{ position: "relative" }}>
      <FailedIndicator isFailed={isInvalid} retry={onHandleBlur} />
      <Flex alignItems="center">
        {desiredInputAsDelta && (
          <Small mr="xs" fontSize="1.7rem">
            -
          </Small>
        )}
        <NumberInput
          height="32px"
          value={inputValue}
          data-testid="forecast-fte-input"
          valid={isValid || undefined}
          invalid={isInvalid || undefined}
          numberProps={{
            allowNegative: false,
            onValueChange: onChange,
            onBlur: onHandleBlur,
          }}
        />
      </Flex>
    </Box>
  );
};

RequestedFTEInput.propTypes = {
  cell: PropTypes.object,
  dispatch: PropTypes.func,
  desiredInputAsDelta: PropTypes.bool,
};

export default RequestedFTEInput;
