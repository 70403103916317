import { map, isEmpty, debounce } from "lodash";
import { useLazyQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { pagedSearchTags } from "src/queries/tags.graphql";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";

const usePagedSearchTags = ({ tagTypes }) => {
  const [searchTags, { data: tagsData, loading }] = useLazyQuery(
    pagedSearchTags,
    {
      skip: isEmpty(tagTypes),
    }
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchTags = useCallback(
    debounce(searchTags, DEBOUNCE_SEARCH_TIME),
    [searchTags]
  );

  const tags = useMemo(() => {
    if (!isEmpty(tagsData?.results?.tags)) {
      return map(tagsData?.results?.tags, (tag) => ({
        ...tag,
        label: tag.displayValue,
        value: tag.id,
      }));
    }
    return [];
  }, [tagsData]);

  return {
    tags,
    loading,
    debouncedSearchTags,
  };
};

export default usePagedSearchTags;
