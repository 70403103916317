import { NODE_TYPES } from "src/components/NodeVisualizer/consts";
import { HEADER_SPACING } from "./consts";

const calculateAveragePosition = (nodeId, nodes, edges, direction = "both") => {
  const relatedNodes = [];
  const collapsedChild = nodes.find(
    (n) =>
      n.type === NODE_TYPES.SHOW_MORE_OBJECTIVES_NODE &&
      n.data.hierarchyParentIds.includes(nodeId)
  );

  if (collapsedChild) {
    relatedNodes.push(collapsedChild);
  }

  if (direction === "both" || direction === "ancestors") {
    // Get ancestors
    const ancestors = edges
      .filter((edge) => edge.target === nodeId)
      .map((edge) => nodes.find((n) => n.id === edge.source))
      .filter(Boolean);
    relatedNodes.push(...ancestors);
  }

  if (direction === "both" || direction === "descendants") {
    // Get descendants
    const descendants = edges
      .filter((edge) => edge.source === nodeId)
      .map((edge) => nodes.find((n) => n.id === edge.target))
      .filter(Boolean);
    relatedNodes.push(...descendants);
  }

  if (relatedNodes.length === 0) return null;

  // Sort relatedNodes by a consistent property for stable order
  relatedNodes.sort((a, b) => a.id.localeCompare(b.id));

  // Calculate the average position
  const avgPosition =
    relatedNodes.reduce((SUM, node) => SUM + (node.position?.y || 0), 0) /
    relatedNodes.length;

  return avgPosition > HEADER_SPACING ? avgPosition : HEADER_SPACING;
};

export default calculateAveragePosition;
