/* eslint-disable import/prefer-default-export */
import serializeOldSlateObjToHtml from "./serializer/oldSlateHtmlSerializer";
import serializeToHtml from "./serializer/slateHtmlSerializer";

export const serialize = (msg, reverse) => {
  // Old Slage object is like { object: "value", document: {...} } version <= 0.47
  // And old Slate object is always for user input comments, no system generated
  if (msg && msg.object === "value") {
    return serializeOldSlateObjToHtml(msg);
  }

  // Serialize new version Slate obj (version > 0.50)
  return serializeToHtml(msg, reverse);
};
