import { useMemo } from "react";

import { useTheme } from "styled-components";
import {
  useGroupTypes,
  useWorkspace,
} from "src/contexts/global/WorkspaceContext";
import { useAllocationProject } from "src/contexts/global/GlobalContext";
import { useAuth0 } from "src/contexts/auth0Provider";
import {
  getNodesAndEdgesGroupMembersByGroupType,
  initializeNodesAndEdges,
  extractGroupNodesAndEdges,
} from "./utils/index";

// TODO: de-spaghetify this file
export const useOrgChartNodesAndEdges = ({ data, team, state }) => {
  const theme = useTheme();
  const groupTypes = useGroupTypes();
  const allocationProject = useAllocationProject();
  const workspace = useWorkspace();
  const { user } = useAuth0();

  const contextualData = useMemo(
    () => ({
      state,
      data,
      team,
      groupTypes,
      theme,
      workspace,
      user,
      allocationProject,
      viewGroup: team,
    }),
    [state, data, team, groupTypes, theme, workspace, user, allocationProject]
  );

  return useMemo(() => {
    let membersNodesAndEdges = { edges: [], nodes: [] };

    const groupType = groupTypes[team.type];

    if (
      state?.viewOptions?.groupMembersBySupplyOrDemand &&
      state?.viewOptions?.showPeople
    ) {
      membersNodesAndEdges = getNodesAndEdgesGroupMembersByGroupType({
        type: groupType?.isSupply ? "demand" : "supply",
        contextualData,
        team,
        data,
      });
    } else if (state?.viewOptions?.showPeople) {
      membersNodesAndEdges = initializeNodesAndEdges({
        data,
        team,
        contextualData,
      });
    }

    const groupNodesAndEdges = extractGroupNodesAndEdges(data, team);

    return {
      nodes: [...membersNodesAndEdges.nodes, ...groupNodesAndEdges.nodes],
      edges: [...membersNodesAndEdges.edges, ...groupNodesAndEdges.edges],
    };
  }, [data, team, state, groupTypes, contextualData]);
};
