import React from "react";
import PropTypes from "prop-types";
import { Popover, Badge } from "orcs-design-system";
import { numberToLocaleString } from "src/util/toLocaleString";

import FteBadgeToolTip from "./FteBadgeToolTip";

export const FteInnerBadge = ({ children, loading, hasError = false }) => {
  if (hasError) {
    return <Badge>ERR</Badge>;
  }

  if (loading) {
    return <Badge>FTE</Badge>;
  }

  return (
    <Badge
      display="block"
      noWrap={true}
      className="guided-tour-team-details-fte"
    >
      {children}
    </Badge>
  );
};

FteInnerBadge.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
};

const FteBadge = ({
  fte,
  directWorkforceFte = null,
  isLoading = false,
  hasError = false,
  isCapacity = false,
  isAllocated = false,
  hideWhenZero = false,
  showToolTip = false,
  showToolTipDirectWorkforceFteStats = false,
  showToolTipDirectWorkforceFlag = false,
  toolTipPosition = "bottomLeft",
}) => {
  if (hideWhenZero && !fte) {
    return null;
  }

  const tooltip = showToolTip && (
    <FteBadgeToolTip
      fte={fte}
      directWorkforceFte={directWorkforceFte}
      showToolTipDirectWorkforceFteStats={showToolTipDirectWorkforceFteStats}
      showToolTipDirectWorkforceFlag={showToolTipDirectWorkforceFlag}
      isCapacity={isCapacity}
      isAllocated={isAllocated}
    />
  );

  const displayValue = `${numberToLocaleString(fte)} FTE`;

  return showToolTip && tooltip ? (
    <Popover
      text={tooltip}
      direction={toolTipPosition}
      textAlign="center"
      width="fit-content"
    >
      <FteInnerBadge
        isCapacity={isCapacity}
        isAllocated={isAllocated}
        hasError={hasError}
        loading={isLoading}
      >
        {displayValue}
      </FteInnerBadge>
    </Popover>
  ) : (
    <FteInnerBadge
      fte={fte}
      isCapacity={isCapacity}
      isAllocated={isAllocated}
      loading={isLoading}
      hasError={hasError}
    >
      {displayValue}
    </FteInnerBadge>
  );
};

FteBadge.propTypes = {
  fte: PropTypes.number,
  directWorkforceFte: PropTypes.number,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  isCapacity: PropTypes.bool,
  isAllocated: PropTypes.bool,
  showToolTip: PropTypes.bool,
  toolTipPosition: PropTypes.string,
  showToolTipDirectWorkforceFteStats: PropTypes.bool,
  showToolTipDirectWorkforceFlag: PropTypes.bool,
  hideWhenZero: PropTypes.bool,
};

export default FteBadge;
