import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { get, isNil } from "lodash";

import { FTE_SUM_TYPE } from "src/consts/team";

import FteBadge from "../FteBadge";
import { getFteSum, getFteSumWithDirectWorkforce } from "./getFteSum.graphql";

const FteQueryBadge = ({
  groupId,
  fteSumType,
  fteSumTarget,
  hideWhenLoading,
  hideWhenZero,
  showToolTip = false,
  queryDirectWorkforce = false,
  toolTipPosition = "bottom",
  showToolTipDirectWorkforceFteStats = false,
  showToolTipDirectWorkforceFlag = false,
}) => {
  const query = queryDirectWorkforce ? getFteSumWithDirectWorkforce : getFteSum;
  const { data, error } = useQuery(query, {
    variables: {
      groupId,
      fteSumType,
      fteSumTarget,
    },
    fetchPolicy: "cache-and-network",
  });

  const isLoading = isNil(data);

  if (hideWhenLoading && isLoading) {
    return null;
  }

  const fte = get(data, "group.fte");
  const directWorkforceFte = get(data, "group.directWorkforceFte");
  const hasError = !isNil(error);

  const isCapacity = fteSumType === FTE_SUM_TYPE.CAPACITY_FTE;
  const isAllocated = fteSumType === FTE_SUM_TYPE.ALLOCATED_FTE;

  return (
    <FteBadge
      fte={fte}
      directWorkforceFte={directWorkforceFte}
      isLoading={isLoading}
      hasError={hasError}
      hideWhenZero={hideWhenZero}
      isCapacity={isCapacity}
      isAllocated={isAllocated}
      showToolTip={showToolTip}
      toolTipPosition={toolTipPosition}
      showToolTipDirectWorkforceFteStats={showToolTipDirectWorkforceFteStats}
      showToolTipDirectWorkforceFlag={showToolTipDirectWorkforceFlag}
    />
  );
};

FteQueryBadge.propTypes = {
  groupId: PropTypes.string.isRequired,
  fteSumType: PropTypes.string.isRequired,
  fteSumTarget: PropTypes.string.isRequired,
  queryDirectWorkforce: PropTypes.bool,
  hideWhenLoading: PropTypes.bool,
  hideWhenZero: PropTypes.bool,
  showToolTip: PropTypes.bool,
  toolTipPosition: PropTypes.string,
  showToolTipDirectWorkforceFteStats: PropTypes.bool,
  showToolTipDirectWorkforceFlag: PropTypes.bool,
};

export default FteQueryBadge;
