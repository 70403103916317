import React from "react";
import { sortBy, map, filter, includes, isEmpty, reduce } from "lodash";
import { ActionsMenuItem } from "orcs-design-system";
import { saveAs } from "file-saver";

import {
  VIEW_MODES,
  PAGE_MODES,
  useViewMode,
  usePageMode,
  useForecastModel,
} from "../../../context/ForecastContext";

const processMovedGroups = (movedGroups, groupingLookup) => {
  // Only target moved out groupings - remove all new groupings moved in
  const filtered = filter(movedGroups, ({ grouping }) => grouping.isMovedOut);
  const groupings = map(filtered, "grouping");

  const sortByHierarchy = (g) => g.group.hierarchyIds.length;
  // Sort the moved groups from top to bottom like UI
  const parentGroupings = sortBy(
    filter(groupings, (g) => !isEmpty(g.childGroupings)),
    sortByHierarchy
  );

  // Make sure parent grouping is processed first
  const sortedParentGroupings = reduce(
    parentGroupings,
    (prev, g) => {
      const { newParent } = g;
      const index = prev.indexOf(g);

      // Make sure parent is processed first
      if (newParent) {
        const { id: newParentId } = newParent;
        const newParentGrouping = groupingLookup[newParentId];

        if (newParentGrouping && !includes(prev, newParentGrouping)) {
          if (index >= 0) {
            prev.splice(index, 1, newParentGrouping, g);
          } else {
            prev.push(newParentGrouping);
          }
        }
      }

      if (!includes(prev, g)) {
        prev.push(g);
      }

      return prev;
    },
    []
  );

  // Remove unmoved parents - heirarchy does not change
  const filteredParent = filter(sortedParentGroupings, (g) => g.newParent);

  const childGroupings = sortBy(
    filter(groupings, (g) => isEmpty(g.childGroupings)),
    sortByHierarchy
  );

  const movedGroupings = [...filteredParent, ...childGroupings];

  const movedMap = {};

  const result = map(movedGroupings, (g) => {
    const { id, newParent, isDirectMove, parent, group } = g;

    const {
      id: newParentId,
      hierarchyIds: newParentHierarchy,
      name: newParentName,
    } = newParent || {};

    const { hierarchyIds, name } = group;

    let next = newParentHierarchy;
    if (newParent && movedMap[newParentId]) {
      next = movedMap[newParentId];
    }

    const oldParentIds = [...hierarchyIds];
    oldParentIds.pop();

    if (isDirectMove) {
      movedMap[id] = [...next, id];

      // for direct move (group with a tag), just get the new heirarchy and return
      return [
        id,
        `"${name}"`,
        "direct move",
        `"${oldParentIds.join(";")}"`,
        "",
        "",
        `"${newParentName}"`,
        `"${next.join(";")}"`,
      ].join(",");
    }

    // For not direct move (move with parent), need to find the really moved parent
    let movedParent = parent;

    while (movedParent) {
      if (!movedParent.isMovedOut || movedParent.isDirectMove) {
        break;
      }
      movedParent = movedParent.parent;
    }

    const {
      id: movedParentId,
      group: { name: movedParentName },
    } = movedParent;

    // Replace the old hierarchy with new parent:
    // First find the moved parent id index, then remove them out, add new heirarchy in
    const newParentIds = [...oldParentIds];
    newParentIds.splice(0, oldParentIds.indexOf(movedParentId), ...next);

    movedMap[id] = [...newParentIds, id];

    return [
      id,
      `"${name}"`,
      "move with parent",
      `"${oldParentIds.join(";")}"`,
      movedParentId,
      `"${movedParentName}"`,
      `"${newParentName}"`,
      `"${newParentIds.join(";")}"`,
    ].join(",");
  });

  result.unshift(
    [
      "Team ID",
      "Team name",
      "Move type",
      "Current parent ids",
      "Moved parent id",
      "Moved parent name",
      "New parent name",
      "New parent ids",
    ].join(",")
  );

  return result.join("\n");
};

const DownloadMovedGroupsButton = () => {
  const viewMode = useViewMode();
  const pageMode = usePageMode();
  const model = useForecastModel();

  const {
    dynamicModel: {
      lookups: { groupingLookup },
    },
    movedGroupingsMap,
  } = model;

  const enableExtra = includes(window.location.search, "enableExtra");

  if (!enableExtra) {
    return null;
  }

  if (
    pageMode !== PAGE_MODES.PLANNER &&
    viewMode !== VIEW_MODES.GROUPED_BY_DEMAND
  ) {
    return null;
  }

  const handleDownload = () => {
    const result = processMovedGroups(movedGroupingsMap, groupingLookup);

    const filename = `moved-groups-${model.rootGroup.id}.csv`;

    saveAs(result, filename);
  };

  return (
    <ActionsMenuItem onClick={handleDownload}>
      Download moved out groups to csv
    </ActionsMenuItem>
  );
};

export default DownloadMovedGroupsButton;
