export const TAGS_VISIBLE_IN_TYPE = {
  OrgChart: "OrgChart",
};

export const TAGS_VISIBLE_IN_COMPONENTS_LIST = [
  { key: "Directory", label: "Directory", value: "Directory" },
  { key: "Teamform", label: "TeamForm", value: "Teamform" },
  {
    key: "IndividualsHistory",
    label: "Individual's history",
    value: "IndividualsHistory",
  },
  {
    key: "Navigation",
    label: "Navigation",
    value: "Navigation",
  },
  {
    key: "SkillsRequirement",
    label: "Skills requirement",
    value: "SkillsRequirement",
  },
  {
    key: "AppliedCountTooltip",
    label: "Applied count tooltip",
    value: "AppliedCountTooltip",
  },
  {
    key: TAGS_VISIBLE_IN_TYPE.OrgChart,
    label: "Org Chart",
    value: TAGS_VISIBLE_IN_TYPE.OrgChart,
  },
];

export const TAG_CONFIG_FIELDS = [
  "allowGroupToHaveTag",
  "allowPersonToHaveTag",
  "allowMultipleOnEntity",
  "allowNewTags",
  "showPreviousAppliedTags",
  "details",
  "id",
  "isReserved",
  "name",
  "isEditableByUsers",
  "isGroupBadge",
  "isGroupAssociation",
  "allowedToEditTagDisplayValue",
  "isPersonBadge",
  "readOnly",
  "allowEditingReadOnlyTagsByAdmin",
  "allowEditingReadOnlyTagsByPowerUser",
  "hideShowInfoIconInTagDisplay",
  "hideTagTypeInTagDisplay",
];

export const ATTRIBUTE_ROW_TYPE = {
  values: "values",
  others: "others",
  entityTag: "entityTag",
};

export const DEFAULT_ALLOWED_GROUP_TYPE = {
  key: "ALL",
  label: "All group types",
  value: "ALL",
};

export const ADD_NEW_TAG_LINK = {
  id: "__AddNew__",
  label: "+ Add new value",
  isActive: true,
  isTagValue: false,
};

export const LOAD_MORE_TAGS_LINK = {
  id: "__LoadMore__",
  label: "+ Load more tags",
  isActive: true,
  isTagValue: true,
  isLoadMoreTags: true,
};

export const NO_MORE_RESULTS_ID = "no-more-results";
export const REPORTS_RESOURCE_CREATION_STATUS = {
  SUBMITTED: "SUBMITTED",
  PENDING: "PENDING",
  RUNNABLE: "RUNNABLE",
  STARTING: "STARTING",
  RUNNING: "RUNNING",
  SUCCEEDED: "SUCCEEDED",
  FAILED: "FAILED",
};
