import React, { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { Modal, Box } from "orcs-design-system";
import { find } from "lodash";

import AllocationModalHeader from "src/allocation/components/AllocationModalHeader";
import IndividualPersonAllocationContainer from "src/allocation/pages/PersonAllocationPage/IndividualPersonAllocationContainer";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import PersonMemberships from "src/components/PersonDetailPage/PersonMemberships";

import {
  useUserInteraction,
  useActiveAllocationProject,
  useDispatch,
  ACTIONS,
  useLookupData,
  useModelLookups,
} from "../../../context/ForecastContext";
import useUpdateAllocationsCache from "../../Shared/Members/hooks/useUpdateAllocationsCache";
import {
  refetchQuery,
  updateGroupMacroAllocation,
} from "./AllocationModal.util";

const AllocationModal = () => {
  const client = useApolloClient();
  const { enableFutureAllocationsUI } = useWorkspaceFeatureFlags();
  const { showMemberMoveBadge, enableBulkMoveWithUpdate, enableMemberFteEdit } =
    useLookupData();
  const {
    editingAllocation: { isOpen, targetPerson, searchVariables },
  } = useUserInteraction();
  const activeAllocationProject = useActiveAllocationProject();
  const modelLookups = useModelLookups();
  const dispatch = useDispatch();
  const updateAllocationsCache = useUpdateAllocationsCache();

  const allocationProjectId = activeAllocationProject.id;

  const handleHideModal = () => {
    // Refresh the planner page
    dispatch({
      type: ACTIONS.MAIN_QUERY_REFRESH,
      backgroundRefresh: true,
    });

    dispatch({
      type: ACTIONS.CLOSE_ALLOCATION_MODAL,
    });
  };

  const { aggregateId: personId, fte } = targetPerson || {};

  const onAllocationAdded = useCallback(
    async (newTargetId, allocation) => {
      const { lineItemLookup } = modelLookups;
      const { sourceGroupId, targetGroupId: currentTargetGroupId } =
        searchVariables;

      if (showMemberMoveBadge) {
        // refetch
        refetchQuery(client, currentTargetGroupId, searchVariables);
      }

      // If lineItem already existing, refetch
      if (lineItemLookup[`${newTargetId}-${sourceGroupId}`]) {
        // refetch
        refetchQuery(client, newTargetId, searchVariables);
      }

      // Not in the view, check whether target group has the line item
      if (enableBulkMoveWithUpdate) {
        // Try to find whether there are macro allocation record exist
        // Fix the macro allocation records, load target group macro allocations
        await updateGroupMacroAllocation({
          client,
          allocationProjectId,
          newTargetId,
          sourceGroupId,
          allocation,
        });
      }
    },
    [
      allocationProjectId,
      client,
      enableBulkMoveWithUpdate,
      modelLookups,
      searchVariables,
      showMemberMoveBadge,
    ]
  );

  const onPersonAllocated = async (proxy, { data }, params) => {
    const { individualAllocations } = data;
    const {
      variables: { targetGroupId: newTargetId },
    } = params;

    const allocation = find(
      individualAllocations,
      (a) => a.targetGroupId === newTargetId && a.status !== "unallocated"
    );

    await onAllocationAdded(newTargetId, allocation);
  };

  const onMembershipAdded = useCallback(
    async (memberships, params) => {
      const { groupId: newTargetId } = params;

      const allocation = find(memberships, (m) => m.groupId === newTargetId);

      await onAllocationAdded(newTargetId, allocation);
    },
    [onAllocationAdded]
  );

  const onAllocationRemoved = useCallback(
    (newTargetId) => {
      const { groupingLookup, lineItemLookup } = modelLookups;
      const { sourceGroupId } = searchVariables;

      // Not in the view
      if (
        !groupingLookup[newTargetId] ||
        !lineItemLookup[`${newTargetId}-${sourceGroupId}`]
      ) {
        return;
      }

      // The lineItem is in the current planner page, refresh if members expanded
      refetchQuery(client, newTargetId, searchVariables);
    },
    [client, modelLookups, searchVariables]
  );

  const onPersonUnallocated = (proxy, result, params) => {
    const {
      variables: { targetGroupId: newTargetId },
    } = params;

    onAllocationRemoved(newTargetId);
  };

  const onMembershipRemoved = useCallback(
    (memberships, params) => {
      const { groupId: newTargetId } = params;

      onAllocationRemoved(newTargetId);
    },
    [onAllocationRemoved]
  );

  const onAllocationFteUpdated = useCallback(
    (targetGroupId, requestedFte) => {
      // If enableMemberFteEdit flag is off, do nothing here
      // as the member row only shows the baseline allocation fte
      if (!enableMemberFteEdit) {
        return;
      }

      updateAllocationsCache(personId, targetGroupId, requestedFte);

      dispatch({
        type: ACTIONS.TOGGLE_ALL_MEMBERS_EXPANSION,
        targetValue: true,
      });

      setTimeout(() => {
        dispatch({
          type: ACTIONS.TOGGLE_ALL_MEMBERS_EXPANSION,
          targetValue: false,
        });
      }, 200);
    },
    [dispatch, enableMemberFteEdit, personId, updateAllocationsCache]
  );

  const onPersonFteUpdated = (proxy, result, params) => {
    const {
      variables: { targetGroupId, requestedFte },
    } = params;

    onAllocationFteUpdated(targetGroupId, requestedFte);
  };

  const onMembershipFteUpdated = useCallback(
    (memberships, membership) => {
      const { groupId: targetGroupId, fte: requestedFte } = membership;

      onAllocationFteUpdated(targetGroupId, requestedFte);
    },
    [onAllocationFteUpdated]
  );

  if (!targetPerson || !activeAllocationProject) {
    return null;
  }

  if (enableFutureAllocationsUI) {
    const layout = (modalBody, viewToggle) => (
      <Modal
        visible={isOpen}
        maxWidth="90vw"
        maxHeight="90vh"
        width="70vw"
        height="60vh"
        onConfirm={() => {}}
        onClose={handleHideModal}
        headerContent={
          <AllocationModalHeader
            person={targetPerson}
            viewToggle={viewToggle}
          />
        }
      >
        <Box p="xxs" height="100%">
          {modalBody}
        </Box>
      </Modal>
    );

    return (
      <PersonMemberships
        key="memberships"
        person={targetPerson}
        onMembershipAdded={onMembershipAdded}
        onMembershipRemoved={onMembershipRemoved}
        onMembershipFteUpdated={onMembershipFteUpdated}
        layoutFunction={layout}
      />
    );
  }

  const legacyLayout = (...children) => {
    return (
      <Modal
        visible={isOpen}
        maxWidth="90vw"
        maxHeight="90vh"
        width="70vw"
        height="60vh"
        onConfirm={() => {}}
        onClose={handleHideModal}
        headerContent={<AllocationModalHeader person={targetPerson} />}
        footerContent={children[2]}
      >
        <Box p="xxs">
          {children[0]}
          {children[1]}
        </Box>
      </Modal>
    );
  };

  return (
    <IndividualPersonAllocationContainer
      allocationProjectId={allocationProjectId}
      personId={personId}
      personFte={fte}
      onPersonAllocated={onPersonAllocated}
      onPersonUnallocated={onPersonUnallocated}
      onPersonAllocationUpdated={onPersonFteUpdated}
      layoutFunction={legacyLayout}
    />
  );
};

export default AllocationModal;
