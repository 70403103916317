import { useQuery } from "@apollo/client";
import { get, map, sortBy } from "lodash";
import { useMemo } from "react";
import { getValueGraph } from "src/queries/valueGraph.graphql";

const useFetchObjectives = ({ objectives, input, skip }) => {
  const { data, loading, error } = useQuery(getValueGraph, {
    variables: {
      input,
    },
    skip,
  });

  const sortedObjectives = useMemo(() => {
    return sortBy(objectives || get(data, "valueGraph.objectives"), (o) =>
      get(o, "name")
    );
  }, [objectives, data]);

  /**
   * TODO:: the getValueGraph query returns field of dataSource not datasource
   * where as group type returns datasource. this needs to be fixed
   */
  const convertedObjectives = map(sortedObjectives, (objective) => ({
    ...objective,
    datasource: objective.dataSource,
  }));

  return {
    sortedObjectives: convertedObjectives,
    loading,
    error,
  };
};

export { useFetchObjectives };
