import React from "react";
import PropTypes from "prop-types";

import CommentModelContainer from "src/comments/containers/CommentModelContainer";
import { CommentCountsProvider } from "./CommentCountsContext";
import { CommentModalContextProvider } from "./CommentModalContext";

const CommentContextProvider = ({
  children,
  groupId,
  saveTags = [],
  countTags,
  queryTags,
  showHistoricAllocationProjects = false,
  showActiveAllocationProjects = false,
  showForecastAllocationProjects = false,
  isReady = true,
}) => {
  return (
    <CommentCountsProvider
      groupId={groupId}
      countTags={countTags}
      isReady={isReady}
    >
      <CommentModalContextProvider
        saveTags={saveTags}
        queryTags={queryTags}
        showHistoricAllocationProjects={showHistoricAllocationProjects}
        showActiveAllocationProjects={showActiveAllocationProjects}
        showForecastAllocationProjects={showForecastAllocationProjects}
      >
        {children}
        <CommentModelContainer />
      </CommentModalContextProvider>
    </CommentCountsProvider>
  );
};

CommentContextProvider.propTypes = {
  children: PropTypes.node,
  groupId: PropTypes.string,
  saveTags: PropTypes.arrayOf(PropTypes.string),
  queryTags: PropTypes.object,
  countTags: PropTypes.object,
  showHistoricAllocationProjects: PropTypes.bool,
  showActiveAllocationProjects: PropTypes.bool,
  showForecastAllocationProjects: PropTypes.bool,
  isReady: PropTypes.bool,
};

export default CommentContextProvider;
