import React from "react";
import PropTypes from "prop-types";
import { Box } from "orcs-design-system";
import { map } from "lodash";
import Heading from "../Heading";
import SelectedChapter from "./SelectedChapter";

const SelectedChaptersPanel = ({
  groups,
  handleInputChange,
  isPlannerPage,
  expandToGroup,
  selectedChapterHeading,
  disableDecimal,
}) => {
  return (
    <Box height="60%">
      <Heading sectionHeading={selectedChapterHeading} />
      <Box
        p="s"
        borderRadius={2}
        boxBorder="default"
        height="100%"
        overflow="auto"
      >
        {map(groups, (group) => {
          return (
            <SelectedChapter
              key={group.id}
              group={group}
              handleInputChange={handleInputChange}
              isPlannerPage={isPlannerPage}
              expandToGroup={expandToGroup}
              disableDecimal={disableDecimal}
            />
          );
        })}
      </Box>
    </Box>
  );
};

SelectedChaptersPanel.propTypes = {
  groups: PropTypes.array,
  handleInputChange: PropTypes.func,
  isPlannerPage: PropTypes.bool,
  expandToGroup: PropTypes.func,
  selectedChapterHeading: PropTypes.string,
  disableDecimal: PropTypes.bool,
};

export default SelectedChaptersPanel;
