import React, { useState, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import { TextInput, Flex, Divider, Small, H5, H4 } from "orcs-design-system";
import { map, debounce, size, sumBy, lowerFirst } from "lodash";
import pluralize from "pluralize";
import AllocationProjectPropType from "src/custom-prop-types/allocationProject";
import GroupPropType from "src/custom-prop-types/group";

import { roundFte } from "src/util/roundingStrategy";
import LoadingPage from "src/components/LoadingPage";
import {
  FancyCardHeader,
  FancyCardBody,
} from "src/components/FancyCard/FancyCard";

import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";
import { EmptyColumn, CustomGrid } from "src/allocation/allocation.styled";

import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";
import Row from "./Row";

const HeadingRow = ({ allocationProject }) => {
  return (
    <>
      <EmptyColumn />
      <Small color="greyDark" textAlign="center" mt="s">
        <strong>
          Currently allocated FTE for {allocationProject.allocationName}
        </strong>
      </Small>
      <Small color="greyDark" textAlign="center" mt="s">
        <strong>Approved FTE for {allocationProject.allocationName}</strong>
      </Small>
      <Small color="greyDark" textAlign="center" mt="s">
        <strong>Status</strong>
      </Small>
    </>
  );
};

HeadingRow.propTypes = {
  allocationProject: AllocationProjectPropType.isRequired,
};

const TotalsRow = ({ sourceGroups }) => {
  const totalCurrentlyAllocatedFte = sumBy(sourceGroups, "allocatedFte");
  const totalTargetAllocationFte = sumBy(sourceGroups, "targetFte");
  return (
    <>
      <H5>TOTALS</H5>
      <H5 textAlign="center">{roundFte(totalCurrentlyAllocatedFte)}</H5>
      <H5 textAlign="center">{roundFte(totalTargetAllocationFte)}</H5>
      <Divider spanGrid />
    </>
  );
};

TotalsRow.propTypes = {
  sourceGroups: PropTypes.arrayOf(GroupPropType).isRequired,
};

const SourceGroups = ({
  loading,
  allocationProject,
  selectedGroup,
  sourceGroups,
  onSearch,
  initialSearchText,
  supplyLabel,
}) => {
  const [searchText, setSearchText] = useState(initialSearchText);
  const onSearchChange = useCallback(
    (e) => {
      setSearchText(e.target.value);
      debounce((value) => {
        onSearch(value);
      }, DEBOUNCE_SEARCH_TIME)(e.target.value);
    },
    [onSearch, setSearchText]
  );

  const onRowClick = useCallback(
    (group) => {
      setSearchText(group.name);
      onSearch(group.name);
    },
    [onSearch, setSearchText]
  );

  const sourceGroupCount = size(sourceGroups);

  return (
    <>
      <FancyCardHeader>
        <Flex alignItems="center" mb="s">
          <H4>
            {!loading && sourceGroupCount}{" "}
            {pluralize(supplyLabel, sourceGroupCount)} in{" "}
            <strong>
              <GroupHierarchyBreadcrumb
                showBreadcrumb={false}
                group={selectedGroup}
                titleOnly={true}
              />
            </strong>
          </H4>
        </Flex>
        <TextInput
          type="text"
          placeholder={`Search for ${lowerFirst(supplyLabel)}`}
          iconRight={["far", "search"]}
          fullWidth
          value={searchText}
          onChange={onSearchChange}
        />
      </FancyCardHeader>
      <FancyCardBody>
        <CustomGrid
          width="100%"
          gridTemplateColumns="auto 90px 90px 40px"
          gridGap="10px"
          scrollbarFix
        >
          <HeadingRow allocationProject={allocationProject} />
          <TotalsRow sourceGroups={sourceGroups} />
          {map(sourceGroups, (group) => (
            <Fragment key={group.id}>
              <Row group={group} onRowClick={onRowClick} />
              <Divider spanGrid />
            </Fragment>
          ))}
        </CustomGrid>
        {loading && <LoadingPage />}
      </FancyCardBody>
    </>
  );
};

SourceGroups.propTypes = {
  loading: PropTypes.bool.isRequired,
  allocationProject: AllocationProjectPropType.isRequired,
  selectedGroup: GroupPropType.isRequired,
  sourceGroups: PropTypes.arrayOf(GroupPropType).isRequired,
  onSearch: PropTypes.func.isRequired,
  initialSearchText: PropTypes.string,
  supplyLabel: PropTypes.string,
};

export default SourceGroups;
