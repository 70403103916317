import { Small } from "orcs-design-system";
import React from "react";

const NotAllocatedMsg = () => {
  return (
    <Small color="greyDark">
      <em>This person currently has no memberships.</em>
    </Small>
  );
};

export default React.memo(NotAllocatedMsg);
