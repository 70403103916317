import { gql } from "@apollo/client";

const sharedAttributes = `
  nodeType
  hierarchyDepth
  hierarchyParentIds
  id
  name
  kind
  type
  measureIds
  teamIds`;

const sharedObjectiveAttributes = `
  progress
  status
  statusColor
  externalUrl
  datasource`;

export const getTeamStrategyQuery = gql`
  query getTeamStrategyQuery($teamId: String!) {
    getTeamStrategy(teamId: $teamId) {
      nodes {
        ... on TeamStrategyObjectiveNode {
          ${sharedAttributes}
          ${sharedObjectiveAttributes}
        }
        ... on TeamStrategyTeamNode {
          ${sharedAttributes}
          avatarUrl
          memberCount
        }
        ... on TeamStrategyWorkNode {
          ${sharedAttributes}
          ${sharedObjectiveAttributes}
        }
        ... on TeamStrategyMeasureNode {
          ${sharedAttributes}
          ${sharedObjectiveAttributes}
        }
      }
    }
  }
`;
