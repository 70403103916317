import { isNil } from "lodash";

const getConstraintPopoverContent = (constraint, isDefaultFromInput) => {
  if (!isNil(constraint)) {
    return "User specified target state";
  }

  if (isDefaultFromInput) {
    return "Prepopulated from user entered value";
  }

  return "Prepopulated from Actual value";
};

export default getConstraintPopoverContent;
