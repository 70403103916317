import React from "react";
import { Flex } from "orcs-design-system";
import { FancyCardFooter } from "src/components/FancyCard/FancyCard";

import TeamButtons from "./TeamButtons";

const Footer = () => {
  return (
    <FancyCardFooter data-testid="request-individual-allocation-footer">
      <Flex alignItems="center">
        <TeamButtons />
      </Flex>
    </FancyCardFooter>
  );
};

export default Footer;
