import { INDIRECT_PLACEHOLDER } from "src/allocation/pages/ForecastPage/context/reducer/macroAllocationTypes";

// eslint-disable-next-line import/prefer-default-export
export const getExpandHeaderLabel = ({ group, isExpanded, isSupply, type }) => {
  const roleExpandContractAction = isExpanded ? "hide" : "show";
  const lineItemMode = isSupply ? "teams" : "forecasts";

  if (type === INDIRECT_PLACEHOLDER) {
    return `Click to ${roleExpandContractAction} ${group.type} level adjustments.`;
  }
  return `Click to ${roleExpandContractAction} direct ${group.type} ${lineItemMode}`;
};
