/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import React from "react";
import { useSpring, a } from "@react-spring/three";
import { animationConfig } from "reagraph";
import { DoubleSide } from "three";

export const TFSphereWithBorder = ({
  color,
  borderColor,
  id,
  size,
  opacity,
  animated,
}) => {
  const { scale, nodeOpacity } = useSpring({
    from: {
      // Note: This prevents incorrect scaling w/ 0
      scale: [0.00001, 0.00001, 0.00001],
      nodeOpacity: 0,
    },
    to: {
      scale: [size, size, size],
      nodeOpacity: opacity,
    },
    config: {
      ...animationConfig,
      duration: animated ? 1 : 0,
    },
  });

  return (
    <a.mesh userData={{ id, type: "node" }} scale={scale}>
      <a.mesh>
        <ringGeometry attach="geometry" args={[1, 1.1, 32]} />
        <a.meshPhongMaterial
          attach="material"
          opacity={1}
          color={borderColor}
        />
      </a.mesh>
      <a.mesh>
        <circleGeometry attach="geometry" args={[1, 25, 25]} />
        <a.meshPhongMaterial
          attach="material"
          side={DoubleSide}
          transparent={true}
          fog={true}
          opacity={opacity}
          color={color}
        />
      </a.mesh>
    </a.mesh>
  );
};

TFSphereWithBorder.defaultProps = {
  opacity: 1,
  active: false,
};
