import { useMutation } from "@apollo/client";
import React, { useCallback, useState, useEffect } from "react";
import { chunk } from "lodash";
import { Box, H5, Flex, Button, Notification, Small } from "orcs-design-system";

import { bulkMoveIndividuals } from "src/allocation/allocation.graphql";
import ErrorNotification from "src/components/ErrorNotification";
import {
  useUserInteraction,
  useActiveAllocationProject,
  useDispatch,
  ACTIONS,
  useLookupData,
} from "../../../context/ForecastContext";
import GroupingMembersTreeModal from "../BulkAddingTagsModal/GroupingMembersTreeModal";
import useLazyLoadMembers from "../BulkAddingTagsModal/useLazyLoadMembers";
import useGroupingMembersTree from "../BulkAddingTagsModal/useGroupingMembersTree";
import {
  createMutationList,
  refreshChangedLineItems,
  countTotalSelected,
} from "./BulkMoveMembers.logic";

const MAX_MOVE_LIMIT = 100;

const BulkDeallocationsModal = () => {
  const {
    bulkDeallocations: { isOpen, grouping },
  } = useUserInteraction();
  const allocationProject = useActiveAllocationProject();
  const {
    enableBulkMoveWithUpdate,
    enableMemberAllocation,
    enableMemberFteEdit,
  } = useLookupData();
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setIsProcessing(false);
      setError(null);
      setProgress(null);
    }
  }, [isOpen]);

  const handleCloseModal = useCallback(() => {
    if (isProcessing) {
      return;
    }

    dispatch({
      type: ACTIONS.CLOSE_BULK_DEALLOCATIONS_MODAL,
    });
  }, [dispatch, isProcessing]);

  const {
    client,
    loadLatestMembers: loadMembers,
    getMembersQueryVariables,
  } = useLazyLoadMembers();

  // TODO: make a proper grouping tree including these methods inside...
  const {
    treeData,
    selectedGroups,
    selectedPeople,
    handleNodeSelected,
    handleChecked,
  } = useGroupingMembersTree({
    isTreeActive: isOpen,
    grouping,
    loadMembers,
  });

  const [bulkMoveMembers] = useMutation(bulkMoveIndividuals);

  const runBulkDeallocate = useCallback(async () => {
    const { allocations, lineItems } = createMutationList(selectedPeople);

    if (allocations.length > MAX_MOVE_LIMIT) {
      setError(
        `Too many members to move (selected ${allocations.length} limit: ${MAX_MOVE_LIMIT}).`
      );
      return;
    }

    try {
      setIsProcessing(true);
      const allocationProjectId = allocationProject.id;

      if (enableMemberAllocation && enableMemberFteEdit) {
        // When enabling member fte edit, the individual allocation is triggering
        // a lot of commands, including adding macro allocations and updating
        // realtime current total fte in macro allocations, chunk the allocations array,
        // so that each time processing a relatively safe number to avoid back end timeout
        const chunks = chunk(allocations, 10);

        for (let i = 0, j = chunks.length; i < j; i += 1) {
          const current = chunks[i];
          setProgress(`${i + 1}/${j}`);
          // eslint-disable-next-line no-await-in-loop
          await bulkMoveMembers({
            variables: {
              input: {
                allocations: current,
                allocationProjectId,
              },
            },
          });
        }

        setProgress("");
      } else {
        // Bulk move members
        await bulkMoveMembers({
          variables: {
            input: {
              allocations,
              allocationProjectId,
            },
          },
        });
      }

      // Refetch line item members list
      await refreshChangedLineItems({
        client,
        allocationProjectId,
        lineItems,
        getMembersQueryVariables,
        enableBulkMoveWithUpdate,
      });

      // Refresh the planner page
      dispatch({
        type: ACTIONS.MAIN_QUERY_REFRESH,
        backgroundRefresh: true,
      });
      handleCloseModal();
    } catch (e) {
      setError(e);
    } finally {
      setIsProcessing(false);
    }
  }, [
    selectedPeople,
    allocationProject,
    bulkMoveMembers,
    client,
    getMembersQueryVariables,
    dispatch,
    handleCloseModal,
    enableMemberAllocation,
    enableMemberFteEdit,
    enableBulkMoveWithUpdate,
  ]);

  if (!isOpen && !grouping) {
    return null;
  }

  // Set deallocate and cancel button to disabled if processing or have any error
  const isBusy = isProcessing || !!error;
  const totalSelected = countTotalSelected(selectedPeople);

  const footerContent = (
    <Box width="100%">
      <Small>{`Total ${totalSelected} selected. Max ${MAX_MOVE_LIMIT}.`}</Small>
      {isProcessing && !error && (
        <Notification loading closable={false}>
          {`Deallocating members in progress (${progress || ""}) ...`}
        </Notification>
      )}
      {error && (
        <ErrorNotification
          message={`Sorry, an error occurred, please refresh and try deallocating again. (${progress} - ${error.message})`}
          error={error}
          closable={false}
        />
      )}
      <Flex alignItems="center" justifyContent="flex-end" width="100%">
        <Button
          onClick={handleCloseModal}
          variant="ghost"
          mr="r"
          disabled={isBusy}
        >
          Cancel
        </Button>
        <Button
          onClick={runBulkDeallocate}
          variant={isBusy ? "disabled" : "success"}
          disabled={isBusy}
        >
          Deallocate members
        </Button>
      </Flex>
    </Box>
  );

  return (
    <GroupingMembersTreeModal
      visible={isOpen}
      grouping={grouping}
      treeData={treeData}
      onHideModal={handleCloseModal}
      onNodeSelected={handleNodeSelected}
      onNodeChecked={handleChecked}
      selectedGroups={selectedGroups}
      selectedPeople={selectedPeople}
      title={<H5>Deallocate members</H5>}
      footerContent={footerContent}
      leftViewTitle="Select members you would like to deallocate"
      width={100}
    />
  );
};

export default BulkDeallocationsModal;
