import PropTypes from "prop-types";
import React from "react";
import { components } from "react-select";

const CustomSingleValue = ({ children, ...props }) => {
  return <components.SingleValue {...props}>{children}</components.SingleValue>;
};

CustomSingleValue.propTypes = {
  children: PropTypes.object,
  props: PropTypes.object,
};

export default CustomSingleValue;
