import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { isNil } from "lodash";
import { Box, Text, Flex } from "orcs-design-system";
import styled from "styled-components";

import CellPropType from "src/custom-prop-types/cell";
import { numberToLocaleString } from "src/util/toLocaleString";

import Comment from "../../Shared/Comment";
import SkillsInput from "../../Shared/SkillsInput";
import SkillsDisplay from "../../Shared/SkillsDisplay";
import NotificationDot from "../../Shared/NotificationDot";
import TotalFteSum from "../../Shared/TotalFteSum";

import ForecastInput from "./ForecastInput";

const CellWrapper = styled(Box)`
  position: relative;
`;

const NotificationWrapper = styled.div`
  position: absolute;
  left: 100%;
  top: 9px;
`;

const Cell = ({
  cell,
  dispatch,
  isMembersExpanded = false,
  showIntersectingFteSum,
}) => {
  const {
    value,
    isStatic,
    isEditable,
    isRequirementInputEnabled,
    notification,
    currentFteSum,
  } = cell;
  const updateMethodRef = useRef(null);

  return useMemo(() => {
    if (isStatic) {
      return (
        <Flex alignItems="center" justifyContent="center">
          {showIntersectingFteSum && <TotalFteSum fteSum={currentFteSum} />}
          <Text
            textAlign="right"
            className="guided-tour-allocation-forecast-cell-read-only"
            fontWeight={isMembersExpanded && "bold"}
          >
            {numberToLocaleString(value)}
          </Text>
        </Flex>
      );
    }

    if (!isEditable) {
      const { defaultedValue } = cell;
      const displayValue = isNil(value) ? defaultedValue : value;
      return (
        <Box
          width="100%"
          className="guided-tour-allocation-forecast-cell-readonly"
        >
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Text pl="21px" width="60px">
              {numberToLocaleString(displayValue)}
            </Text>
            <Comment cell={cell} dispatch={dispatch} />
          </Flex>
          <Flex mt="s" justifyContent="center">
            {isRequirementInputEnabled && <SkillsDisplay cell={cell} />}
          </Flex>
        </Box>
      );
    }

    return (
      <CellWrapper
        width="100%"
        className="guided-tour-allocation-forecast-cell-can-edit"
      >
        <Flex
          alignItems="center"
          justifyContent={showIntersectingFteSum ? "flex-end" : "center"}
          mb="xs"
        >
          {showIntersectingFteSum && <TotalFteSum fteSum={currentFteSum} />}
          <ForecastInput
            cell={cell}
            dispatch={dispatch}
            updateMethodRef={updateMethodRef}
          />
          <Comment cell={cell} dispatch={dispatch} />
        </Flex>
        {isRequirementInputEnabled && (
          <SkillsInput
            cell={cell}
            dispatch={dispatch}
            updateMethodRef={updateMethodRef}
          />
        )}
        <NotificationWrapper>
          <NotificationDot notification={notification} isCell />
        </NotificationWrapper>
      </CellWrapper>
    );
  }, [cell.hash, isMembersExpanded]); // eslint-disable-line
};

Cell.propTypes = {
  cell: CellPropType,
  dispatch: PropTypes.func.isRequired,
  isMembersExpanded: PropTypes.bool,
};

export default Cell;
