import React from "react";
import PropTypes from "prop-types";
import {
  PLANNER_REQUESTOR_MSG_TYPE,
  PLANNER_APPROVER_MSG_TYPE,
} from "src/consts/announcement";

import PopoverNotification from "./PopoverNotification";
import ProjectAnnouncement from "./ProjectAnnouncement";

const PlannerNotification = ({ activeAllocationProject, rootGroup }) => {
  const { isDemand, isSource } = rootGroup;

  if (!isDemand && !isSource) {
    return null;
  }

  const {
    isRequestorPlannerReadonly,
    isApproverPlannerReadonly,
    requestorPlannerReadonlyBanner,
    requestorPlannerReadonlyHover,
    approverPlannerReadonlyBanner,
    approverPlannerReadonlyHover,
    announcements,
  } = activeAllocationProject;

  if (isDemand && isRequestorPlannerReadonly) {
    return (
      <PopoverNotification
        text={requestorPlannerReadonlyBanner}
        popoverText={requestorPlannerReadonlyHover}
        colour="warning"
      />
    );
  }

  if (isSource && isApproverPlannerReadonly) {
    return (
      <PopoverNotification
        text={approverPlannerReadonlyBanner}
        popoverText={approverPlannerReadonlyHover}
        colour="warning"
      />
    );
  }

  return (
    <ProjectAnnouncement
      announcements={announcements}
      messageType={
        isDemand ? PLANNER_REQUESTOR_MSG_TYPE : PLANNER_APPROVER_MSG_TYPE
      }
    />
  );
};

PlannerNotification.propTypes = {
  activeAllocationProject: PropTypes.object.isRequired,
  rootGroup: PropTypes.object.isRequired,
};

export default PlannerNotification;
