export const DND_TYPE_PEOPLE = "people";
export const HIERARCHY_SEPARATOR = "\\";
export const HIERARCHY_SEPARATOR_REGEX = /\\/gm;
export const STATUS_UNALLOCATED = "unallocated";
export const OVER_ALLOCATED = "over-allocated";
export const UNDER_ALLOCATED = "under-allocated";
export const NEWLY_ADDED = "newly-added";
export const REMOVED = "removed";
export const PAGE_SIZE = 25;
export const PAGE_NEIGHBOURS = 2;

export const SUBMITTED = "SUBMITTED";
export const PENDING = "PENDING";
export const SAVED = "SAVED";
export const NONE = "NONE";

export const NOOP = () => {};

export const MemberSearchKeys = ["fullName", "teams", "tags.label"];

export const APPROVAL_REASON_TYPES = {
  REJECTION: "REJECTION",
};

export const ES_DATE_FORMAT = "YYYY.MM.DD";

export const DEFAULT_PLANNER_TAB_NAME = "Planner";
export const DEFAULT_FORECAST_TAB_NAME = "Forecast";

export const DEFAULT_OUTCOME_LABEL = "Strategy";
export const DEFAULT_STRATEGY_PLACEHOLDER_TEXT =
  "This team currently has no linked strategy items.";

export const FORECAST_SUBMIT_PENDING_MSG = "Unsubmitted forecast changes";

export const DEFAULT_CONSTRAINT_LABEL = "target";
export const DEFAULT_TOTAL_LABEL = "total";

export const FORECAST_DEFAULT_DECIMAL = { decimalplaces: 2 };
