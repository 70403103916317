import moment from "moment";

export const useCheckDays = ({
  startDate,
  endDate,
  isPastEditEnabled,
  focusedInput,
}) => {
  const today = moment().startOf("day");
  const yesterday = today.clone().subtract(1, "days");
  const isPastStartDate = startDate.isBefore(today);
  const isPastEndDate = !!endDate && endDate.isBefore(today);

  // Disable startDate edit if
  // - user is not admin
  // - does not allow edit past
  // - startDate is in the past
  const disableStartDate =
    !isPastEditEnabled && isPastStartDate ? "startDate" : undefined;
  const disableEndDate =
    !isPastEditEnabled && isPastEndDate ? "endDate" : undefined;
  const disabled =
    disableStartDate && disableEndDate
      ? true
      : disableStartDate || disableEndDate;

  const isEndDateFocused = focusedInput === "endDate";
  const isStartDateFocus = focusedInput === "startDate";

  // If endDate focused, display endDate or this month, if not, display startDate
  const initialVisibleMonth = () =>
    isEndDateFocused ? endDate || today : startDate;

  const isOutsideRange = (day) => {
    if (isEndDateFocused) {
      // If startDate is in the past (disabled), set the endDate until 1 day before today
      // So user can end the the current membership on yesterday
      if (disableStartDate) {
        return day.isBefore(yesterday);
      }

      // if startDate is not disabled, endDate can go util it
      return day.isBefore(startDate);
    }

    if (isStartDateFocus) {
      // If past date edit is enabled, user can edit date from startDate until endDate
      if (isPastEditEnabled) {
        return day.isAfter(endDate);
      }

      // If past date edit is not enabled, user can only edit date from today until endDate
      return day.isBefore(today) || day.isAfter(endDate);
    }

    return false;
  };

  const isDayHighlighted = (date) => {
    return today.isSame(date, "day");
  };

  return {
    disabled,
    initialVisibleMonth,
    isOutsideRange,
    isDayHighlighted,
  };
};
