import { Grid } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const COLUMNS_DEFAULT = `repeat(auto-fit, minmax(350px, 1fr))`;
const COLUMNS_LARGE = `repeat(auto-fit, minmax(550px, 1fr))`;

const FluidGrid = ({
  className,
  gridColumns = COLUMNS_DEFAULT,
  loading = false,
  placeholder,
  "data-testid": dataTestId,
  children,
  largerColumns,
  mt,
}) => {
  return (
    <Grid
      className={className}
      data-testid={dataTestId}
      loading={loading}
      gridGap="r"
      gridAutoFlow="row"
      mt={mt || "r"}
      gridTemplateColumns={largerColumns ? COLUMNS_LARGE : gridColumns}
    >
      {loading && placeholder}
      {!loading && children}
    </Grid>
  );
};

FluidGrid.propTypes = {
  className: PropTypes.string,
  mt: PropTypes.string,
  "data-testid": PropTypes.string,
  children: PropTypes.node,
  placeholder: PropTypes.node,
  gridColumns: PropTypes.string,
  loading: PropTypes.bool,
  largerColumns: PropTypes.bool,
};

export default FluidGrid;
