import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { STATUS } from "src/components/BulkMoveMembersModal/consts";

const { Notification } = require("orcs-design-system");

const getStyleProps = (status) => {
  if (status === STATUS.PROGRESS) {
    return {
      colour: "warning",
      icon: null,
    };
  }

  if (status === STATUS.ERROR) {
    return {
      colour: "danger",
      icon: ["fas", "exclamation-circle"],
    };
  }

  return {
    colour: "success",
    icon: ["fas", "check-circle"],
  };
};

const StyledNotification = styled(Notification)`
  padding: 3px 6px 4px 6px;
`;

const NotificationMessage = ({ onCloseNotification, status }) => {
  if (isEmpty(status)) return null;

  const { colour, icon } = getStyleProps(status.status);

  return (
    <StyledNotification
      mt="s"
      onDismiss={() => onCloseNotification()}
      closable={status.status !== STATUS.PROGRESS}
      colour={colour}
      icon={icon}
      loading={status.status === STATUS.PROGRESS}
    >
      {status.message}
    </StyledNotification>
  );
};

NotificationMessage.propTypes = {
  onCloseNotification: PropTypes.func,
  status: PropTypes.object,
};

export default NotificationMessage;
