import { Box } from "orcs-design-system";
import styled from "styled-components";

import { getActiveTheme } from "src/services/localStorage";

export const DETAILS_HEADER_HEIGHT = 190;
export const DETAILS_HEADER_HEIGHT_COMPACT = 160;
export const DETAILS_HEADER_HEIGHT_FALLBACK = "fit-content";

export const PERSON_ADDRESS_MAP_WIDTH = 1600;
export const PERSON_ADDRESS_MAP_HEIGHT = DETAILS_HEADER_HEIGHT;
export const PERSON_ADDRESS_MAP_COMPACT_HEIGHT = DETAILS_HEADER_HEIGHT_COMPACT;
export const PERSON_ADDRESS_MAP_ZOOM = "10";

export const getPersonHeaderHeight = ({
  isCompactTheme,
  hasImage,
  isImageRequest,
}) => {
  const height = isCompactTheme
    ? PERSON_ADDRESS_MAP_COMPACT_HEIGHT
    : PERSON_ADDRESS_MAP_HEIGHT;
  if (isImageRequest) {
    return height;
  }
  if (hasImage) return `${height}px`;
  return DETAILS_HEADER_HEIGHT_FALLBACK;
};

export const getTeamHeaderHeight = (bgImg) => {
  return !bgImg
    ? DETAILS_HEADER_HEIGHT_FALLBACK
    : getActiveTheme() === "compact"
    ? `${DETAILS_HEADER_HEIGHT_COMPACT}px`
    : `${DETAILS_HEADER_HEIGHT}px`;
};

const defaultBgGradient = `linear-gradient(
  to right,
  rgb(246, 247, 248) 0%,
  rgb(237, 238, 241) 20%,
  rgb(246, 247, 248) 40%,
  rgb(246, 247, 248) 100%
)`;

export const TeamHeaderBox = styled(Box)`
  background-image: ${({ bgGradient = defaultBgGradient, bgImg }) =>
    bgImg ? `url(${bgImg})` : bgGradient};
  background-size: cover;
  background-position: center center;
`;

export const PersonHeaderBox = styled(Box)`
  position: relative;
  background-image: ${(props) =>
    props.image ? `url(data:image/png;base64,${props.image})` : "none"};
  background-position: center;
  background-size: cover;
  background-repeat: ${(props) => (props.image ? "no-repeat" : "initial")};
`;
