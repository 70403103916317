import React from "react";
import PropTypes from "prop-types";
import { Notification } from "orcs-design-system";
import GroupPropType from "src/custom-prop-types/group";

const successNotificationStyle = {
  bottom: "20px",
  colour: "success",
  icon: ["fas", "check-circle"],
};

const HideTeamNotification = ({ team, onDismissNotification }) => {
  const { name, isHidden } = team;
  return (
    <Notification
      floating
      centered
      onDismiss={onDismissNotification}
      {...successNotificationStyle}
    >
      Team {name} is now marked {isHidden ? "hidden" : "visible"}
    </Notification>
  );
};

HideTeamNotification.propTypes = {
  team: GroupPropType.isRequired,
  onDismissNotification: PropTypes.func,
};

export default HideTeamNotification;
