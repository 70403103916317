import React from "react";
import PropTypes from "prop-types";
import { Small, Icon } from "orcs-design-system";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { includes, some } from "lodash";

import { TAGS } from "src/consts/comments";

import FormattedCommentContent from "./FormattedCommentContent";

const StyledSystemComment = styled.div`
  margin: 2px 0 0 0;
  display: flex;
  align-items: center;
  > * {
    color: ${themeGet("colors.greyDark")};
  }
  > * + * {
    margin-left: ${themeGet("space.xs")};
  }
`;

const Saved = () => {
  return (
    <>
      <Icon icon={["fas", "save"]} size="sm" />
      <Small uppercase fontWeight="bold">
        Saved:
      </Small>
    </>
  );
};

const Submitted = () => {
  return (
    <>
      <Icon icon={["fas", "cloud-upload"]} size="sm" />
      <Small uppercase fontWeight="bold">
        Submitted:
      </Small>
    </>
  );
};

const SystemLabel = ({ tags }) => {
  if (
    some(tags, (t) =>
      includes(
        [TAGS.ALLOCATION.ACTION.REQUESTED, TAGS.ALLOCATION.ACTION.APPROVED],
        t
      )
    )
  ) {
    return <Saved />;
  }
  if (
    some(tags, (t) =>
      includes(
        [
          TAGS.ALLOCATION.ACTION.REQUEST_SUBMITTED,
          TAGS.ALLOCATION.ACTION.APPROVAL_SUBMITTED,
        ],
        t
      )
    )
  ) {
    return <Submitted />;
  }

  return null;
};

SystemLabel.propTypes = {
  tags: PropTypes.array,
};

const SystemComment = ({ tags, content, contentMarkup }) => (
  <StyledSystemComment>
    <SystemLabel tags={tags} />
    <Small fontWeight="bold">
      <FormattedCommentContent
        content={content}
        contentMarkup={contentMarkup}
        reverse
      />
    </Small>
  </StyledSystemComment>
);

SystemComment.propTypes = {
  tags: PropTypes.array,
  content: PropTypes.string,
  contentMarkup: PropTypes.string,
};
export default SystemComment;
