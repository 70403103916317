import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Box, Modal, Button, Icon, Flex, H5 } from "orcs-design-system";
import { reduce, isEmpty, filter, includes, map, split, find } from "lodash";

import { TAGS, MOVE_GROUP_THREAD_ID } from "src/consts/comments";
import { HIERARCHY_SEPARATOR } from "src/allocation/consts";
import icons from "src/config/icons";
import TeamDropdown from "src/allocation/components/TeamDropdown";
import { useSearchGroups } from "src/pages/InternalDashboard/useSearchHooks";
import ErrorNotification from "src/components/ErrorNotification";
import {
  moveToNewParent as moveToNewParentMutation,
  revertMovedGroup as revertMovedGroupMutation,
} from "src/allocation/team.graphql";
import { saveComment as saveCommentMutation } from "src/comments/comments.graphql";
import GroupHierarchyQueryBreadcrumb from "src/components/GroupHierarchyBreadcrumb/GroupHierarchyQueryBreadcrumb";

import {
  useUserInteraction,
  useLookupData,
} from "../../context/ForecastContext";

const getCurrentParent = (group, grouping, newParent, isMovedGroup) => {
  if (isMovedGroup) {
    return newParent;
  }

  if (group.directParent) {
    return group.directParent;
  }

  if (isEmpty(group.parentIds)) {
    return { id: null, name: null };
  }

  if (grouping && grouping.parent && grouping.parent.group) {
    return grouping.parent.group;
  }

  if (group.hierarchy) {
    // Build parent from hierarchy
    const parents = split(group.hierarchy, HIERARCHY_SEPARATOR);
    if (parents.length > 1) {
      return {
        id: group.directParentId,
        name: parents[parents.length - 2],
      };
    }
  }

  // We should never come here... just use id as name
  return { id: group.directParentId, name: group.directParentId };
};

const buildCommentTags = (targetGroup, currentParentGroup, newParentGroup) => {
  const tags = [
    TAGS.SYSTEM_COMMENT,
    TAGS.ALLOCATION.COMMENT,
    TAGS.ALLOCATION.PLANNING,
    TAGS.MOVING_GROUP,
    TAGS.GROUP_ID(targetGroup.id),
    TAGS.GROUP_ID(newParentGroup.id),
  ];

  const currentParentId = currentParentGroup.id;
  if (currentParentId) {
    tags.push(TAGS.GROUP_ID(currentParentId));
  }

  return tags;
};

const buildCommentContent = (
  targetGroup,
  currentParentGroup,
  newParentGroup
) => {
  const currentParentName = currentParentGroup.name;
  return `${targetGroup.name} moved ${
    currentParentName ? `from ${currentParentName} ` : ""
  }to ${newParentGroup.name}`;
};

const MoveGroupByTagModal = ({ onHideModal }) => {
  const {
    moveGroup: { isOpen, grouping },
  } = useUserInteraction();
  const { targetTypes, enableMoveGroupByTag, groupTypesLookup } =
    useLookupData();

  const [newParentOption, setNewParentOption] = useState(null);
  const [error, setError] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setNewParentOption(null);
      setError(null);
      setIsAdding(false);
    }
  }, [isOpen]);

  const { group, newParent, tags } = grouping || {};
  const { type, directParentId } = group || {};

  const targetParentGroupTypes = useMemo(() => {
    if (type) {
      // If the current type is the first level under root, then can only move around root group
      const rootGroupTypes = filter(groupTypesLookup, (v) => v.isRoot);
      for (let i = 0, j = rootGroupTypes.length; i < j; i += 1) {
        const groupType = rootGroupTypes[i];
        if (includes(groupType.childTypes, type)) {
          return map(rootGroupTypes, "id");
        }
      }
    }
    // Only load types that can have child
    const targetParentTypes = reduce(
      targetTypes,
      (prev, t) => {
        const { childTypes } = groupTypesLookup[t] || {};
        if (!isEmpty(childTypes)) {
          prev.push(t);
        }
        return prev;
      },
      []
    );
    return targetParentTypes;
  }, [groupTypesLookup, targetTypes, type]);

  const isMovedGroup = useMemo(
    () =>
      !!find(tags, (t) => {
        return (
          // check any NEW_PARENT tag and the group id is in the child position
          t.type === "NEW_PARENT" && includes(t.displayValue, `${group.id}:`)
        );
      }),
    [tags, group]
  );

  const existingParent = useMemo(() => {
    return isMovedGroup ? [newParent, group] : [{ id: directParentId }, group];
  }, [directParentId, isMovedGroup, newParent, group]);

  const { loadOptions, isSearching } = useSearchGroups({
    groupTypes: targetParentGroupTypes,
    existingGroups: existingParent,
    includeMembers: false,
  });

  const [moveGroupToNewParent] = useMutation(moveToNewParentMutation);

  const [revertMovedGroup] = useMutation(revertMovedGroupMutation);

  const [saveComment] = useMutation(saveCommentMutation);

  const selectNewParentGroup = useCallback(async (option) => {
    setNewParentOption(option);
  }, []);

  const handleCloseModal = useCallback(() => {
    setError(null);
    if (onHideModal) onHideModal();
  }, [onHideModal]);

  const saveMovingGroupComment = useCallback(
    async (targetGroup, currentParentGroup, newParentGroup) => {
      await saveComment({
        variables: {
          threadId: MOVE_GROUP_THREAD_ID(targetGroup.id, newParentGroup.id),
          tags: buildCommentTags(
            targetGroup,
            currentParentGroup,
            newParentGroup
          ),
          content: buildCommentContent(
            targetGroup,
            currentParentGroup,
            newParentGroup
          ),
          contentMarkup: "",
        },
      });
    },
    [saveComment]
  );

  const onMoveGroupBtnClick = useCallback(async () => {
    if (!newParentOption || !newParentOption.value) {
      return;
    }

    const isOldParent = newParentOption.value === group.directParentId;

    // If group is not moved and trying to move to current parent group, do nothing
    if (!isMovedGroup && isOldParent) {
      return;
    }

    setIsAdding(true);
    try {
      // For associations, we need to maintain only one
      // so need to move the group back to original group
      // Probably the group is a child of a move group,
      // need to make sure the group is really moved
      if (isMovedGroup && newParent) {
        await revertMovedGroup({
          variables: {
            groupId: group.id,
            parentGroupId: newParent.id,
          },
        });
      }

      // If moving the group back, then stop adding new association
      if (!isOldParent) {
        // Move the group to a new parent
        await moveGroupToNewParent({
          variables: {
            groupId: group.id,
            parentGroupId: newParentOption.value,
            displayValue: `${group.id}:${newParentOption.value}`,
          },
        });
      }

      const currentParentGroup = getCurrentParent(
        group,
        grouping,
        newParent,
        isMovedGroup
      );
      const newParentGroup = newParentOption.group;
      await saveMovingGroupComment(group, currentParentGroup, newParentGroup);

      handleCloseModal();
    } catch (e) {
      setError(e);
    } finally {
      setIsAdding(false);
    }
  }, [
    newParentOption,
    grouping,
    group,
    isMovedGroup,
    saveMovingGroupComment,
    handleCloseModal,
    newParent,
    revertMovedGroup,
    moveGroupToNewParent,
  ]);

  if (!isOpen || !enableMoveGroupByTag) {
    return null;
  }

  return (
    <Modal
      visible={isOpen}
      width="60vw"
      overflow="visible"
      onClose={handleCloseModal}
    >
      <Box p="xs">
        <Flex alignItems="center" flexWrap="wrap" mb="r">
          <H5 mr="xs">Move</H5>
          <GroupHierarchyQueryBreadcrumb
            group={group}
            showGroupTypeBadge
            showBreadcrumb={false}
          />
          <H5 ml="xxs">to:</H5>
        </Flex>
        <Flex alignItems="flex-end" pr="s">
          <TeamDropdown
            id="move-group-by-tag"
            placeholder="Search and select new parent team"
            isSearchable
            isLoading={isSearching}
            options={[]}
            loadOptions={loadOptions}
            onSelectTeam={selectNewParentGroup}
            value={newParentOption}
            cacheOptions={false}
          />
          <Button
            iconLeft
            variant={isAdding ? "disabled" : "success"}
            large
            ml="r"
            height="54px"
            onClick={onMoveGroupBtnClick}
            isLoading={isAdding}
          >
            <Icon icon={icons.pen} />
            Move
          </Button>
        </Flex>
        {!!error && (
          <ErrorNotification
            message={`Sorry, an error occurred, please try searching again. (${
              error.message || ""
            })`}
            error={error}
            closable={false}
          />
        )}
      </Box>
    </Modal>
  );
};

MoveGroupByTagModal.propTypes = {
  onHideModal: PropTypes.func,
};

export default MoveGroupByTagModal;
