import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { isEmpty, map } from "lodash";
import { Flex, Small, Spacer, Icon, FlexItem } from "orcs-design-system";

import ObjectiveAttributeBadges from "./ObjectiveAttributeBadges";

const ParentGroupsContainer = styled.div`
  margin-left: 53px;
  margin-bottom: 10px;
  padding-right: 15px;
`;

const ObjectiveParents = ({ parentObjectives, isExpanded }) => {
  if (!isExpanded || isEmpty(parentObjectives)) {
    return null;
  }

  return (
    <ParentGroupsContainer>
      <Spacer my="xs">
        {map(parentObjectives, (g) => (
          <Flex key={g.id} alignItems="center">
            <FlexItem flex="0 0 auto">
              <Icon mr="s" icon={["far", "bullseye-arrow"]} color="success" />
            </FlexItem>
            <Small mr="s" color="greyDark">
              {g.name}
            </Small>
            <FlexItem flex="0 0 auto" ml="auto">
              <ObjectiveAttributeBadges objective={g} />
            </FlexItem>
          </Flex>
        ))}
      </Spacer>
    </ParentGroupsContainer>
  );
};

ObjectiveParents.propTypes = {
  parentObjectives: PropTypes.array,
  isExpanded: PropTypes.bool,
};

export default ObjectiveParents;
