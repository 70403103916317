import stringToColor from "color-string";
import { isEmpty, toLower } from "lodash";

/**
 * Convert a color string to a percieved luminance value (0-1) using the approach from @see https://stackoverflow.com/a/596243/1107473
 * @param {string} colorString a color string, e.g. "white", "#fff", "rgb(255, 255, 255)"
 * @param {boolean} darkenByAlpha useful if the background is dark, e.g. 80% transparency will reduce the luminance value by 80%
 * @returns {number} 0-1 where 0 is darkest and 1 is brightest
 */
const colorToLuminance = (colorString, darkenByAlpha = false) => {
  const colorStrLower = toLower(colorString);

  // no color string
  if (isEmpty(colorStrLower)) {
    return NaN;
  }

  // string to color
  const color = stringToColor.get.rgb(colorStrLower);
  if (isEmpty(color)) {
    return NaN;
  }

  // color parts
  const [red, green, blue, alpha] = color;

  /** @see https://stackoverflow.com/a/596243/1107473 */
  let percievedLuminance = Math.sqrt(
    0.299 * red ** 2 + 0.587 * green ** 2 + 0.114 * blue ** 2
  );

  // from 0-255 to 0-1 (toFixed rounds numbers which makes white 1 instead of 0.9999999999)
  percievedLuminance = (percievedLuminance / 255).toFixed(5);

  // darken? e.g. 0.2 alpha will make the color 80% darker
  if (darkenByAlpha) {
    percievedLuminance *= alpha;
  }

  // clamp to 0-1
  percievedLuminance = Math.min(Math.max(percievedLuminance, 0), 1);

  return percievedLuminance;
};

export default colorToLuminance;
