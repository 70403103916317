import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

import {
  useForecastContext,
  useLookupData,
} from "../../context/ForecastContext";

import TreeIndentWrapper from "../Shared/TreeIndentWrapper";

import Members from "./GroupingMembers";
import ConstraintGroupingExpandHeader from "./ConstraintGroupingExpandHeader";

const ConstraintGrouping = ({ grouping }) => {
  const { enableMembersInForecast } = useLookupData();

  const [
    {
      userInteraction: { expandedGroupIds },
    },
    dispatch,
  ] = useForecastContext();

  const { id, nestedLevel, childGroupings } = grouping;

  const isExpanded = expandedGroupIds[id];

  return useMemo(
    () => (
      <>
        <ConstraintGroupingExpandHeader
          grouping={grouping}
          isExpanded={isExpanded}
          dispatch={dispatch}
          enableMembersInForecast={enableMembersInForecast}
        />
        {enableMembersInForecast && (
          <Members
            grouping={grouping}
            dispatch={dispatch}
            isExpanded={isExpanded}
          />
        )}
        {isExpanded && (
          <TreeIndentWrapper indentLevel={nestedLevel}>
            {map(childGroupings, (childGrouping) => (
              <ConstraintGrouping
                key={childGrouping.id}
                grouping={childGrouping}
              />
            ))}
          </TreeIndentWrapper>
        )}
      </>
    ),
    [grouping.hash, isExpanded] // eslint-disable-line
  );
};

ConstraintGrouping.propTypes = {
  grouping: PropTypes.object.isRequired,
};

export default ConstraintGrouping;
