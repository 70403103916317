import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { fulfillVacantRole } from "src/queries/allocation.graphql";
import { getIndividualAllocationsQueryUpdater } from "src/util/updateIndividualAllocationCache";

const useFulfillVacantRole = () => {
  const [fulfillVacantRoleMutation] = useMutation(fulfillVacantRole);
  const updateAllocateToNewGroupCache = useCallback(
    (cache, result, queryVariables) => {
      getIndividualAllocationsQueryUpdater(cache, result, queryVariables);
    },
    []
  );

  return async ({ placeholderId, allocationProjectId, personId }) => {
    await fulfillVacantRoleMutation({
      variables: {
        input: {
          allocationProjectId,
          placeholderId,
          personId,
        },
      },
      update: (cache, result) => {
        updateAllocateToNewGroupCache(cache, result, {
          personId,
          allocationProjectId,
        });
      },
    });
  };
};

export default useFulfillVacantRole;
