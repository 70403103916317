import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Flex, H5 } from "orcs-design-system";
import GroupTypeBadge from "src/components/GroupTypeBadge";

const ModalHeader = ({ group }) => {
  const name = get(group, "name", "");

  return (
    <Flex alignItems="center">
      <H5 mr="s">{name}</H5>
      <GroupTypeBadge group={group} showToolTip toolTipPosition="bottomRight" />
    </Flex>
  );
};

ModalHeader.propTypes = {
  group: PropTypes.object,
};

export default ModalHeader;
