import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "orcs-design-system";

import Comment from "../../Shared/Comment";

const CommentCell = ({ lineItem, dispatch }) => {
  const cell = lineItem.cells[0] || { hash: 0 };

  return useMemo(() => {
    if (!cell) {
      return null;
    }

    return (
      <Box width="100%">
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Comment cell={cell} dispatch={dispatch} />
        </Flex>
      </Box>
    );
  }, [cell.hash]); // eslint-disable-line
};

CommentCell.propTypes = {
  cell: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default CommentCell;
