import React, { useCallback } from "react";
import { Box, Flex, Icon, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useHistory } from "react-router-dom";

import TeamAllocationsLink from "src/allocation/components/TeamAllocationsLink";
import TeamLink from "src/components/TeamLink";
import GroupPropType from "src/custom-prop-types/group";
import { teamDetailsPath } from "src/util/routing";
import { TEAM_TAB, url } from "src/consts/urlPaths";

import * as hierarchySearchUtil from "./HierarchySearchResult.util";

const Hierarchy = React.memo((props) => {
  const {
    group,
    parentGroup,
    hierarchyTokens = [],
    highlighted,
    selectedSourceGroup,
  } = props;
  const history = useHistory();

  const { id, isHidden } = group;
  const selectedSourceGroupId = get(selectedSourceGroup, "id");
  const parentGroupId = get(parentGroup, "id");

  const onTeamAllocationsLinkClick = useCallback(() => {
    let search = `?target_id=${id}`;
    if (selectedSourceGroupId !== parentGroupId) {
      search = `${search}&source_id=${selectedSourceGroupId}`;
    }
    history.push({
      pathname: url(
        `/:workspaceId?/teams/${parentGroupId}/${TEAM_TAB.APPROVE_ALLOCATION_NUMBERS}`
      ),
      search,
    });
  }, [history, id, parentGroupId, selectedSourceGroupId]);

  const highlight = hierarchySearchUtil.highlighter(highlighted);
  const groupName = highlight(group.name);
  const hierarchyLabel = hierarchySearchUtil.getGroupLabel(
    hierarchyTokens,
    highlight
  );

  return (
    <Box key={id}>
      <Flex alignItems="center">
        <Spacer mr="xs">
          <Icon color="grey" icon={["fas", "users"]} size="xs" />
          <TeamLink
            bold
            isHidden={isHidden}
            showSwitchView={true}
            teamName={groupName}
            teamUrl={teamDetailsPath({ id })}
          />
          <TeamAllocationsLink
            title="View in planner"
            onLinkClick={onTeamAllocationsLinkClick}
          />
        </Spacer>
      </Flex>
      <Flex alignItems="center" flexWrap="wrap">
        {hierarchyLabel}
      </Flex>
    </Box>
  );
});

Hierarchy.displayName = "Hierarchy";
Hierarchy.propTypes = {
  group: GroupPropType,
  parentGroup: GroupPropType,
  selectedSourceGroup: GroupPropType,
  hierarchyTokens: PropTypes.arrayOf(PropTypes.string),
  highlighted: PropTypes.arrayOf(PropTypes.object),
};

export default Hierarchy;
