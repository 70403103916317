import React, { useMemo } from "react";
import { isEmpty } from "lodash";

import { Icon } from "orcs-design-system";

import { useLocation, matchPath } from "react-router";
import styled from "styled-components";
import { useDateSettings } from "src/contexts/global/ApolloSettingsContext";
import { useSidebarGuidedTour } from "src/contexts/global/GuidedTourContext";
import {
  useActiveFilterCount,
  useFteMode,
} from "src/contexts/filterAndFte/FilterAndFteContext";

import useGuidedTourTrigger from "src/guidedTour/useGuidedTourTrigger";
import useTourForRouteMatch from "src/guidedTour/useTourForRouteMatch";
import {
  isPeoplePage,
  isSummaryPage,
  isSearchPage,
  isVisualisationPage,
  isTeamAllocationsPage,
  isStrategyPage,
} from "src/util/url";
import useDefaultRoutePath from "src/app/Restricted/useDefaultRoutePath";

import Url, { matchPaths } from "src/consts/urlPaths";

import ThemeSwitchToggle from "src/components/Nav/ThemeSwitchToggle";

import WorkspaceAnnouncements from "src/components/PersonDetailPage/WorkspaceAnnouncements";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import Restricted from "src/app/Restricted";
import useRestricted from "src/app/Restricted/useRestricted";
import useShowInvite from "src/components/InviteUser/hooks/useShowInvite";
import useGroupsNavigationData from "src/components/GroupsNavigation/useGroupsNavigationData";
import GroupsNavigationLoader from "src/components/GroupsNavigation";
import TagsNavigation from "src/components/TagsNavigation";
import InviteUser from "src/components/InviteUser";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING, EVENT_LOCATIONS } from "src/consts/eventTracking";
import InfoSidebarContent from "./components/InfoSidebarContent";
import FilterSidebarContent from "./components/FilterSidebarContent";
import FilterSearchResults from "./components/FilterSearchResults";
import VisualisationSettingsSidebarContent from "./components/VisualisationSettingsSidebarContent";
import HistoricalDataSidebarContent from "./components/HistoricalDataSidebarContent";
import FteDataSidebarContent from "./components/FteDataSidebarContent";
import SettingsContent from "./components/SettingsContent";
import AppSwitcherContent from "./components/AppSwitcherContent";
import HomeLink from "./components/HomeLink";
import SummaryLink from "./components/SummaryLink";
import PeopleLink from "./components/PeopleLink";
import StrategyInsights from "./components/StrategyInsights";

const CustomIcon = styled(Icon)`
  path {
    fill: white !important;
  }
`;

const useSideNavItemBuilder = ({
  themeSwitcher,
  clickedItem,
  user,
  tagTypesConfig,
  logout,
  featureFlags,
  eventEmitter,
  setClickedItem,
}) => {
  // Get workspace announcements heading
  const workspace = useWorkspace();
  const shouldShowInvite = useShowInvite({ workspace, user });
  const activeFilterCount = useActiveFilterCount();
  const fteMode = useFteMode();

  const match = matchPaths();

  const dateSettings = useDateSettings();
  const { availableInSidebar } = useSidebarGuidedTour();

  const tourName = useTourForRouteMatch();
  const triggerGuidedTour = useGuidedTourTrigger();
  const tourAvailable = availableInSidebar?.[tourName];

  const [isRestricted, isPartiallyRestricted] = useRestricted();

  const { panelHeading, notifications } =
    workspace?.config?.notifications?.landingPageNotifications || {};
  const heading = panelHeading || "Announcements";
  const showDot = !isEmpty(notifications);

  const groupsNavProps = useGroupsNavigationData({
    workspace,
    featureFlags,
    isSelected: clickedItem?.name === "Browse teams",
    eventEmitter,
  });

  const searchFilterBadge = (type) => {
    switch (type) {
      case "all":
        return null;
      case "people":
        return (
          <CustomIcon
            icon={["fas", "user"]}
            title="People"
            size="xs"
            transform="right-1"
          />
        );
      case "teams":
        return (
          <CustomIcon
            icon={["fas", "users"]}
            title="Teams"
            size="xs"
            transform="right-1"
          />
        );
      case "tags":
        return (
          <CustomIcon
            icon={["fas", "tag"]}
            title="Tags"
            size="xs"
            transform="right-1 down-1"
          />
        );
      default:
        return "";
    }
  };
  const location = useLocation();
  const defaultRoutePath = useDefaultRoutePath();

  const sidebarTabs = useMemo(() => {
    return [
      {
        iconName: "home",
        name: "Home",
        component: HomeLink,
        actionType: "link",
        isActive: !!matchPath(location.pathname, {
          path: defaultRoutePath,
          exact: true,
        }),
        onClick: () => {
          trackEvent(EVENT_TRACKING.URL_CLICKED, {
            action: "home_sidebar_selected",
          });
        },
      },
      {
        iconName: "layer-group",
        name: "Browse teams",
        component: () => <GroupsNavigationLoader {...groupsNavProps} />,
        onClick: (item) => {
          if (clickedItem?.name !== "Browse teams") {
            setClickedItem(item);
          }
        },
        large: true,
        actionType: "component",
      },
      {
        iconName: "tag",
        name: "Browse tags",
        component: () => (
          <TagsNavigation
            tagTypesConfig={tagTypesConfig}
            selectedTab={clickedItem?.name === "Browse tags" ? "tagsTree" : ""}
          />
        ),
        onClick: (item) => {
          if (clickedItem?.name !== "Browse tags") {
            setClickedItem(item);
          }
        },
        actionType: "component",
      },
      {
        iconName: "users",
        name: "People",
        component: PeopleLink,
        actionType: "link",
        hide: isRestricted,
        isActive: !!matchPath(location.pathname, {
          path: Url.PEOPLE,
          exact: true,
        }),
      },
      {
        iconName: "chart-line",
        name: "Insights",
        component: SummaryLink,
        actionType: "link",
        hide: isRestricted,
        isActive: !!matchPath(location.pathname, {
          path: Url.INSIGHTS,
          exact: true,
        }),
        onClick: () => {
          trackEvent(EVENT_TRACKING.INSIGHTS_CLICKED, {
            location: EVENT_LOCATIONS.NAV_BAR,
          });
        },
      },
      {
        iconName: "external-link",
        name: "Apps & workspaces",
        component: () => <AppSwitcherContent />,
        actionType: "component",
      },
      {
        iconName: "history",
        name: "Point in time",
        hide: isTeamAllocationsPage(match) || isRestricted,
        pageSpecific: true,
        component: () => (
          <Restricted>
            <HistoricalDataSidebarContent />
          </Restricted>
        ),
        badgeNumber: isEmpty(dateSettings) ? null : "!",
        actionType: "component",
      },
      {
        iconName: "filter",
        name: "Filter",
        hide: !isSummaryPage(match?.path) || isRestricted,
        pageSpecific: true,
        component: () => <FilterSidebarContent />,
        ...(activeFilterCount !== 0 && {
          badgeNumber: activeFilterCount,
        }),
        isExpandedByDefault: matchPath(location.pathname, {
          path: Url.INSIGHTS,
        }),
        actionType: "component",
      },
      {
        iconName: "filter",
        name: "Filter",
        hide: !isPeoplePage(match?.path) || isRestricted,
        pageSpecific: true,
        component: () => <FilterSidebarContent />,
        ...(activeFilterCount !== 0 && {
          badgeNumber: activeFilterCount,
        }),
        isExpandedByDefault: matchPath(location.pathname, {
          path: Url.PEOPLE,
          exact: true,
        }),
        actionType: "component",
      },
      {
        iconName: "filter",
        isExpandedByDefault: matchPath(location.pathname, {
          path: Url.SEARCH,
          exact: false,
        }),
        name: "Filter search results",
        hide: !isSearchPage(match?.path) || isRestricted,
        pageSpecific: true,
        badgeNumber: searchFilterBadge(match?.params?.type),
        component: () => (
          <Restricted>
            <FilterSearchResults match={match} />
          </Restricted>
        ),
        actionType: "component",
        isActive: matchPath(location.pathname, {
          path: "/search",
          exact: false,
        }),
      },
      {
        iconName: "bolt",
        name: "Team alignment",
        isExpandedByDefault: true,
        hide: !isStrategyPage(match?.params),
        pageSpecific: true,
        component: () => (
          <Restricted>
            <StrategyInsights />
          </Restricted>
        ),
        actionType: "component",
      },
      {
        iconName: "people-arrows",
        name: "Toggle FTE",
        hide: !isSummaryPage(match?.path) || isRestricted,
        pageSpecific: true,
        component: () => (
          <Restricted>
            <FteDataSidebarContent />
          </Restricted>
        ),
        badgeNumber: fteMode ? "!" : null,
        actionType: "component",
      },
      {
        iconName: "chart-network",
        isExpandedByDefault: matchPath(location.pathname, {
          path: Url.VISUALISATION,
        }),
        pageSpecific: true,
        name: "Visualisation options",
        actionType: "component",
        hide: !isVisualisationPage(match?.path) || isRestricted,
        component: () => (
          <Restricted>
            <VisualisationSettingsSidebarContent />
          </Restricted>
        ),
      },
      {
        iconName: "play-circle",
        name: "Guided tour",
        hide:
          isSummaryPage(match?.path) ||
          !(tourName && tourAvailable) ||
          isRestricted,
        pageSpecific: true,
        actionType: "button",
        onClick: () => triggerGuidedTour(tourName),
        adminOnly: true,
      },
      {
        iconName: "sparkles",
        name: heading,
        bottomAligned: true,
        badgeDot: showDot,
        component: () => (
          <Restricted>
            <WorkspaceAnnouncements inSideNav={true} />
          </Restricted>
        ),
        actionType: "component",
        onClick: () => {
          trackEvent(EVENT_TRACKING.URL_CLICKED, {
            action: "announcements_sidebar_selected",
          });
        },
        hide: isRestricted,
      },
      {
        iconName: "cog",
        name: "Settings",
        bottomAligned: true,
        component: () => (
          <Restricted>
            <SettingsContent />
          </Restricted>
        ),
        actionType: "component",
        hide: isRestricted || isPartiallyRestricted,
      },
      {
        iconName: "user-plus",
        name: "Invite",
        hide: !shouldShowInvite,
        large: true,
        bottomAligned: true,
        component: () => <InviteUser workspace={workspace} user={user} />,
        actionType: "component",
      },
      {
        iconName: "eye",
        name: "View options",
        bottomAligned: true,
        component: () => <ThemeSwitchToggle themeSwitcher={themeSwitcher} />,
        actionType: "component",
        hide: !themeSwitcher,
      },
      {
        iconName: "question-circle",
        name: "Get help",
        bottomAligned: true,
        component: () => <InfoSidebarContent />,
        actionType: "component",
      },
      {
        iconName: "lock",
        name: "Logout",
        bottomAligned: true,
        onClick: logout,
        actionType: "button",
        hide: !logout,
      },
    ];
  }, [
    activeFilterCount,
    clickedItem?.name,
    dateSettings,
    fteMode,
    groupsNavProps,
    heading,
    isPartiallyRestricted,
    isRestricted,
    logout,
    match,
    setClickedItem,
    shouldShowInvite,
    showDot,
    tagTypesConfig,
    themeSwitcher,
    tourAvailable,
    tourName,
    triggerGuidedTour,
    user,
    workspace,
    location,
    defaultRoutePath,
  ]);

  return sidebarTabs;
};

export default useSideNavItemBuilder;
