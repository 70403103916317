import React from "react";
import PropTypes from "prop-types";
import { replace } from "lodash";
import RichTextContent from "src/components/RichTextContent";
import AuthorTitle from "../AuthorTitle";

const DownloadHistoryContent = ({ content, authorTitle, group }) => {
  const updatedContent = group
    ? replace(content, group.id, group.name)
    : content;

  return (
    <>
      <RichTextContent content={updatedContent} />
      <AuthorTitle action="Downloaded" authorTitle={authorTitle} />
    </>
  );
};

DownloadHistoryContent.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  authorTitle: PropTypes.string,
  group: PropTypes.object,
};

export default DownloadHistoryContent;
