import { useQuery, useMutation } from "@apollo/client";
import { get, isNil, isEmpty } from "lodash";
import { Box, H3 } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { changePersonSupplyGroup } from "src/allocation/person.graphql";
import { getSourceGroups } from "src/allocation/team.graphql";
import ErrorNotification from "src/components/ErrorNotification";

import SupplyGroupSearch from "./SupplyGroupSearch";

const SupplyGroupAllocation = ({
  person,
  groupTypes,
  onSupplyGroupChanged,
  workspace,
}) => {
  const supplyLabel = get(
    workspace,
    "config.allocation.supplyLabel",
    "Chapter"
  );
  const [changeSupplyGroup, { error, loading: isChanging }] = useMutation(
    changePersonSupplyGroup
  );

  const { aggregateId, sourceGroupIds, memberOf } = person;

  const {
    data,
    loading: isLoading,
    error: loadError,
  } = useQuery(getSourceGroups, {
    variables: {
      groupIds: sourceGroupIds,
    },
    skip: !isNil(memberOf) || isEmpty(sourceGroupIds),
  });

  const onAllocationChange = (newGroup, originalGroup) => {
    const variables = {
      personId: aggregateId,
      groupId: newGroup ? newGroup.id : null,
    };

    changeSupplyGroup({
      variables,
      update(cache, { data: { result } }) {
        if (onSupplyGroupChanged) {
          onSupplyGroupChanged({
            newGroup,
            originalGroup,
            cache,
            result,
            variables,
          });
        }
      },
    });
  };

  return (
    <Box>
      <H3>{`${supplyLabel} memberships`}</H3>
      <SupplyGroupSearch
        person={person}
        groupTypes={groupTypes}
        isLoading={isLoading || isChanging}
        groups={memberOf || get(data, "sourceGroups", [])}
        onAllocationChange={onAllocationChange}
      />
      {(error || loadError) && (
        <ErrorNotification
          message="Sorry, an error occurred, please try again."
          error={error || loadError}
        />
      )}
    </Box>
  );
};

SupplyGroupAllocation.propTypes = {
  person: PropTypes.object,
  groupTypes: PropTypes.object,
  onSupplyGroupChanged: PropTypes.func,
  workspace: PropTypes.object,
};

export default SupplyGroupAllocation;
