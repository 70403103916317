/* eslint-disable no-continue */
import { isEmpty, isNil, isArray } from "lodash";
import {
  NOTIFICATION_STATUS,
  COLUMN_TYPES,
} from "../../context/ForecastContext";

export const isGroupingHasNotifications = ({ notifications }) => {
  if (isEmpty(notifications)) {
    return false;
  }

  for (let i = 0, j = notifications.length; i < j; i += 1) {
    const { notification } = notifications[i];

    if (isNil(notification.status)) {
      continue;
    }

    if (notification.status === NOTIFICATION_STATUS.INFO) {
      continue;
    }

    if (
      isArray(notification.groupMessage) &&
      !isEmpty(notification.groupMessage)
    ) {
      return true;
    }

    if (notification.groupMessage) {
      return true;
    }
  }

  return false;
};

export const isLineItemHasNotifications = ({ cells }) => {
  if (isEmpty(cells)) {
    return false;
  }

  for (let i = 0, j = cells.length; i < j; i += 1) {
    const { columnType, notification } = cells[i];

    if (columnType !== COLUMN_TYPES.PLANNER_APPROVED) {
      continue;
    }

    if (isNil(notification.status)) {
      continue;
    }

    if (notification.status === NOTIFICATION_STATUS.INFO) {
      continue;
    }

    if (notification.message) {
      return true;
    }
  }

  return false;
};
