import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Text, Box, Flex, Spacer } from "orcs-design-system";

import { numberToLocaleString } from "src/util/toLocaleString";

import NotificationDot from "../../Shared/NotificationDot";
import DeltaLabel from "../../Shared/DeltaLabel";
import EmptyColumn from "../../Shared/EmptyColumn";
import Input from "./Input";

const ApproverCell = ({ cell, dispatch, hideApproverColumn }) => {
  const { value, defaultedValue, delta, isEditable, notification } = cell;

  return useMemo(() => {
    if (hideApproverColumn) {
      return <EmptyColumn />;
    }
    if (!isEditable) {
      const submittedValue = numberToLocaleString(value, { defaultedValue });
      return (
        <>
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
            className="guided-tour-allocation-planner-approver-cell-readonly"
          >
            <Spacer mx="xs">
              <Text>{submittedValue}</Text>
              <DeltaLabel delta={delta} />
            </Spacer>
          </Flex>
          <Flex alignItems="center" justifyContent="flex-start" width="100%">
            <NotificationDot notification={notification} isCell />
          </Flex>
        </>
      );
    }

    return (
      <>
        <Box
          width="100%"
          className="guided-tour-allocation-planner-approver-cell"
        >
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Input cell={cell} dispatch={dispatch} />
          </Flex>
        </Box>
        <NotificationDot notification={notification} isCell />
      </>
    );
  }, [cell.hash]); // eslint-disable-line
};

ApproverCell.propTypes = {
  cell: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  hideApproverColumn: PropTypes.bool,
};

export default ApproverCell;
