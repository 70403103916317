import { filter, keyBy, reduce, isString } from "lodash";

import {
  attributeTypes,
  getAllAttributesByType,
  getDescriptionAttribute,
} from "src/util/group";

import { changeTypes } from "./TeamEdit.config";

const convertDescription = (value) => {
  // If description value already a string
  if (isString(value)) {
    return value;
  }

  return JSON.stringify(value);
};

export const getAttributes = (formAttributes) =>
  reduce(
    formAttributes,
    (attributes, formAttribute) => {
      const { id, label, value, deleted, attributeType } = formAttribute;
      if (!deleted || id) {
        const isDescription = attributeTypes.DESCRIPTION === attributeType;
        attributes.push({
          id,
          value: isDescription ? convertDescription(value) : value,
          deleted,
          label,
          attributeType,
        });
      }
      return attributes;
    },
    []
  );

// convert array of attribute objects to an object with key as the attribute form ID
export const getInitialFormAttributes = (attributes) => {
  const formAttributes = {};
  const descriptionAttribute = getDescriptionAttribute(attributes);
  formAttributes[attributeTypes.DESCRIPTION] = {
    ...descriptionAttribute,
    attributeType: attributeTypes.DESCRIPTION,
    attributeFormId: attributeTypes.DESCRIPTION,
  };
  const linkAttributes = getAllAttributesByType(
    attributes,
    attributeTypes.LINK
  );
  const allFormAttributes = reduce(
    linkAttributes,
    (formAttributesAcc, attribute, index) => {
      const attributeFormId = `${attributeTypes.LINK}-${index + 1}`;
      const formAttribute = { ...attribute, attributeFormId };
      return { ...formAttributesAcc, [attributeFormId]: formAttribute };
    },
    formAttributes
  );

  return allFormAttributes;
};

export const getLinksFormAttributes = (formAttributes) => {
  const linkAttributes = filter(
    formAttributes,
    (formAttribute) => formAttribute.attributeType === attributeTypes.LINK
  );
  return keyBy(linkAttributes, "attributeFormId");
};

export const applyFormAttributeChange = ({
  formAttributes,
  attributeFormId,
  attributeType,
  changeValue,
  changeType,
}) => {
  const existingFormAttribute = formAttributes[attributeFormId];
  const newFormAttribute = {
    ...existingFormAttribute,
    attributeFormId,
  };
  if (!existingFormAttribute) {
    newFormAttribute.attributeType = attributeType;
  }
  if (changeType === changeTypes.LABEL) newFormAttribute.label = changeValue;
  if (changeType === changeTypes.VALUE) newFormAttribute.value = changeValue;
  if (changeType === changeTypes.DELETE) newFormAttribute.deleted = true;
  return newFormAttribute;
};
