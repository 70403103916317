import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";

import { ACTIONS } from "../../../context/ForecastContext";

const RenameTeamButton = ({ grouping, dispatch }) => {
  const { groupId, canRename } = grouping;

  const handleRenameTeam = useCallback(
    (e) => {
      e.stopPropagation();

      dispatch({
        type: ACTIONS.SHOW_RENAME_TEAM_MODAL,
        groupId,
      });
    },
    [groupId, dispatch]
  );

  if (!canRename) return null;

  return <ActionsMenuItem onClick={handleRenameTeam}>Rename</ActionsMenuItem>;
};

RenameTeamButton.propTypes = {
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default RenameTeamButton;
