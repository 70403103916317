import React from "react";
import PropTypes from "prop-types";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import OrgChartContextProvider from "./context/OrgChartContextProvider";
import TeamVisualiser from "./components/TeamVisualiser";

const OrgChart = ({ team, groupTypes }) => {
  return (
    <OrgChartContextProvider team={team} groupTypes={groupTypes}>
      <TeamVisualiser key={team?.id} />
    </OrgChartContextProvider>
  );
};

OrgChart.propTypes = {
  team: PropTypes.oneOfType([GroupPropType]),
  groupTypes: GroupTypesPropType,
};

export default React.memo(OrgChart);
