import React from "react";
import { Box, Flex } from "orcs-design-system";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import GroupPropType from "src/custom-prop-types/group";

import { TeamHeadingPlaceholder } from "src/components/TeamDetailPage/PlaceholderComponents";
import TeamHeading from "../TeamHeading";
import ActionsMenu from "./ActionsMenu";

const Heading = ({ team, refetch }) => {
  const isGroupActive = !team?.isHidden && !team?.isDeleted;
  return (
    <Box bg="white" p="r" boxBorder="default" borderRadius={2}>
      <Flex alignItems="center" justifyContent="space-between">
        {isEmpty(team) ? (
          <TeamHeadingPlaceholder />
        ) : (
          <>
            <TeamHeading team={team} />
            {isGroupActive && <ActionsMenu team={team} refetch={refetch} />}
          </>
        )}
      </Flex>
    </Box>
  );
};

Heading.propTypes = {
  team: GroupPropType,
  refetch: PropTypes.func,
};

export default Heading;
