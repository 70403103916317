import React from "react";
import { Box, Button } from "orcs-design-system";
import styled from "styled-components";
import LoadingPage from "src/components/LoadingPage";
import ErrorPage from "src/components/ErrorPage";

import {
  PAGE_MODES,
  useMainQuery,
  usePageMode,
  useLookupData,
} from "../context/ForecastContext";

import ForecastMainGuidedTour from "./GuidedTours/ForecastMain";
import ForecastLineItemGuidedTour from "./GuidedTours/ForecastLineItem";
import ActionBar from "./ActionBar";
import Groupings from "./Groupings";
import AddNewChapterButton from "./ActionBar/ActionMenu/AddNewChapterButton";

import DisplayAddRoleModal from "./Modals/DisplayAddRoleModal";
import AddNewTeamModal from "./Modals/AddNewTeamModal";
import ExportModal from "./Modals/ExportModal";
import CommentsModal from "./Modals/CommentsModal";
import HideTeamModal from "./Modals/HideTeamModal";
import RenameTeamModal from "./Modals/RenameTeamModal";
import LinkObjectivesModal from "./Modals/LinkObjectivesModal";
import AddSkillsModal from "./Modals/AddSkillsModal";
import EditTeamDetailsModal from "./Modals/EditTeamDetailsModal";
import TeamDetailsModal from "./Modals/TeamDetailsModal";
import AllocationModal from "./Modals/AllocationModal";
import MoveGroupModal from "./Modals/MoveGroupModal";
import BulkAddingTagsModal from "./Modals/BulkAddingTagsModal";
import {
  BulkMoveMembersModal,
  BulkDeallocationsModal,
} from "./Modals/BulkMoveMembersModal";
import AddVacantRoleModal from "./Modals/AddVacantRoleModal";
import AddMemberModal from "./Modals/AddMemberModal";
import MoveMemberModal from "./Modals/MoveMemberModal";
import RemoveMemberModal from "./Modals/RemoveMemberModal";

const ForecastLayout = styled(Box)`
  --scrollbar-size: 8px;
  --scrollbar-minlength: 30px; /* Minimum length of scrollbar thumb (width of horizontal, height of vertical) */
  --scrollbar-ff-width: thin; /* FF-only accepts auto, thin, none */
  --scrollbar-track-color: rgba(0, 0, 0, 0.05);
  --scrollbar-color: rgba(0, 0, 0, 0.2);
  --scrollbar-color-hover: rgba(0, 0, 0, 0.35);
  --scrollbar-color-active: rgba(0, 0, 0, 0.5);
  height: 100%;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: var(--scrollbar-ff-width);
  scrollbar-color: var(--scrollbar-color) var(--scrollbar-track-color);
  &::-webkit-scrollbar {
    height: var(--scrollbar-size);
    width: var(--scrollbar-size);
  }
  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color-hover);
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: var(--scrollbar-color-active);
  }
  &::-webkit-scrollbar-thumb:vertical {
    min-height: var(--scrollbar-minlength);
  }
  &::-webkit-scrollbar-thumb:horizontal {
    min-width: var(--scrollbar-minlength);
  }
`;

const ForecastContent = styled.div`
  min-width: ${({ minWidth }) => minWidth};
`;

const ButtonLayout = (children) => {
  return <Box mt="l">{children}</Box>;
};

const ForecastPage = () => {
  const { error, loading, backgroundRefresh } = useMainQuery();
  const pageMode = usePageMode();

  const minWidth = "max-content";

  const showLoading = loading && !backgroundRefresh;

  const {
    enableMemberAllocation,
    hideAllocateButton,
    enableAddMember,
    enableMoveGroups,
    enableBulkAddingTag,
    enableBulkMoveMembers,
    enableBulkDeallocations,
    enableAddVacantRole,
  } = useLookupData();

  if (error) {
    return <ErrorPage error={error} showHeader={false} showContainer={false} />;
  }

  return (
    <ForecastLayout
      bg="white"
      shadow="default"
      borderRadius="2"
      p="r"
      pageMode={pageMode}
      className="guided-tour-allocation-forecast-welcome"
    >
      <ForecastMainGuidedTour />
      <ForecastLineItemGuidedTour />

      <DisplayAddRoleModal
        isPlannerPage={pageMode === PAGE_MODES.PLANNER}
        enableAddVacantRole={enableAddVacantRole}
      />
      <AddNewTeamModal />
      <ExportModal />
      <CommentsModal />
      <HideTeamModal />
      <RenameTeamModal />
      <LinkObjectivesModal />
      <AddSkillsModal />
      <EditTeamDetailsModal />
      <TeamDetailsModal />
      {enableMemberAllocation && <AllocationModal />}
      {enableMemberAllocation && <MoveMemberModal />}
      {enableMemberAllocation && hideAllocateButton && <RemoveMemberModal />}
      {enableMoveGroups && <MoveGroupModal />}
      {enableBulkAddingTag && <BulkAddingTagsModal />}
      {enableBulkMoveMembers && <BulkMoveMembersModal />}
      {enableBulkDeallocations && <BulkDeallocationsModal />}
      {enableAddVacantRole && <AddVacantRoleModal />}
      {enableAddMember && enableMemberAllocation && <AddMemberModal />}

      <ForecastContent
        minWidth={minWidth}
        pageMode={pageMode}
        className="guided-tour-allocation-forecast-main-panel"
      >
        <ActionBar />
        {showLoading ? (
          <LoadingPage waitingFor="forecastPageQuery" />
        ) : (
          <Groupings />
        )}
        <AddNewChapterButton as={Button} layout={ButtonLayout} />
      </ForecastContent>
    </ForecastLayout>
  );
};

export default ForecastPage;
