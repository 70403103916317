import PropTypes from "prop-types";
import React from "react";
import { useSlate } from "slate-react";

import { StyledRteButton } from "src/components/RichTextEditor/RichTextEditor.styled";
import Icon from "../RteIcon";

/**
 * Render a Toolbar action button
 */
const Button = (
  { className, format, icon, isActive, formatText, ...props },
  ref
) => {
  const editor = useSlate();

  const onMouseDown = (evt) => {
    evt.preventDefault();
    formatText(editor, format);
  };

  return (
    <StyledRteButton
      aria-pressed={isActive(editor, format) ? "true" : "false"}
      aria-label={format}
      data-testid={`cp-rte-button-${format}`}
      isActive={isActive(editor, format)}
      onMouseDown={onMouseDown}
      {...props}
      ref={ref}
    >
      <Icon icon={icon} />
    </StyledRteButton>
  );
};

Button.displayName = `RteButton`;
const RteButton = React.forwardRef(Button);

Button.propTypes = {
  className: PropTypes.string,
  format: PropTypes.string,
  isActive: PropTypes.func,
  icon: PropTypes.array,
  formatText: PropTypes.func,
};

export default RteButton;
