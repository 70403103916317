import { Select } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import SelectComponents from "src/components/SelectComponents";

const noOptions = () => "No search results";

const EntityDropdown = ({
  id,
  placeholder,
  isSearchable,
  isLoading,
  options,
  loadOptions,
  onSelectEntity,
  value,
  noOptionsMessage = noOptions,
  formatOptionLabel,
  disabled,
  ...props
}) => {
  return (
    <Select
      inputId={id}
      isDisabled={disabled}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      cacheOptions={false}
      backspaceRemovesValue={true}
      isClearable={true}
      isSearchable={isSearchable}
      isLoading={isLoading}
      defaultOptions={options}
      loadOptions={loadOptions}
      onChange={onSelectEntity}
      formatOptionLabel={formatOptionLabel}
      value={value}
      menuPlacement="auto"
      components={SelectComponents}
      selectType="async"
      {...props}
    />
  );
};

EntityDropdown.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  isSearchable: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  loadOptions: PropTypes.func,
  onSelectEntity: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
};

export default EntityDropdown;
