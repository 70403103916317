import { Button } from "orcs-design-system";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const PaginationContainer = styled.nav`
  display: flex;
`;

export const PaginationLink = styled(Button)`
  border-radius: 0;
  border-right: 0;
  padding: 0;
  width: 29px;
  height: 29px;
  background: ${({ isActive }) =>
    isActive
      ? (props) => themeGet("colors.primary")(props)
      : (props) => themeGet("colors.white")(props)};
  color: ${({ isActive }) =>
    isActive
      ? (props) => themeGet("colors.white")(props)
      : (props) => themeGet("colors.primaryDark")(props)};
  cursor: ${({ isActive }) => (isActive ? "default" : "pointer")};
  font-weight: 600;
  font-size: 1.2rem;
  border-color: ${({ isActive }) =>
    isActive
      ? (props) => themeGet("colors.primary")(props)
      : (props) => themeGet("colors.greyLighter")(props)};
  &:hover {
    background-color: ${(props) => themeGet("colors.primary")(props)};
    border-color: ${(props) => themeGet("colors.primary")(props)};
    border-right: 0;
    color: ${(props) => themeGet("colors.white")(props)};
  }
  &:focus {
    box-shadow: none;
    background-color: ${(props) => themeGet("colors.primaryLightest")(props)};
    border-color: ${({ isActive }) =>
      isActive
        ? (props) => themeGet("colors.primaryLightest")(props)
        : (props) => themeGet("colors.greyLighter")(props)};
  }
  &:first-child {
    border-radius: ${(props) => themeGet("radii.2")(props)} 0 0
      ${(props) => themeGet("radii.2")(props)};
  }
  &:last-child {
    border-radius: 0 ${(props) => themeGet("radii.2")(props)}
      ${(props) => themeGet("radii.2")(props)} 0;
    border-right: solid 1px ${(props) => themeGet("colors.greyLighter")(props)};
    &:hover {
      border-right: solid 1px ${(props) => themeGet("colors.primary")(props)};
    }
  }
`;
