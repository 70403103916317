import { map } from "lodash";
import { Divider, Flex, FlexItem, Small } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { NameWithExternalUrl } from "src/components/TeamStrategiesTable/components/NameWithExternalUrl";
import ObjectiveTypeIcon from "./ObjectiveTypeIcon";
import ObjectiveBadgeRenderer from "./sub-components/ObjectiveBadgeRenderer";
import sortObjectives from "./utils/sortObjectives";
import { COMPANY_OBJECTIVE_TYPE } from "./consts";

const ObjectiveItems = ({
  objectives,
  groupTypes,
  ml,
  mb,
  objectiveType,
  hasActualParents,
}) => {
  const sortedObjectives = sortObjectives(objectives);

  return map(sortedObjectives, (objective, index) => {
    const nameStyles = (objectiveType === COMPANY_OBJECTIVE_TYPE && {
      fontWeight: "bold",
    }) || {
      color: "greyDark",
      mr: "s",
    };

    return (
      <React.Fragment key={index}>
        {!hasActualParents && index > 0 && <Divider light dash my="xs" />}
        {hasActualParents && <Divider light dash my="xs" />}
        <Flex ml={ml} mb={mb}>
          <FlexItem flex="0 0 auto">
            <ObjectiveTypeIcon group={objective} groupTypes={groupTypes} />
            <Small {...nameStyles}>
              <NameWithExternalUrl objective={objective}>
                {objective.name}
              </NameWithExternalUrl>
            </Small>
          </FlexItem>

          <FlexItem flex="0 0 auto" ml="auto">
            <ObjectiveBadgeRenderer
              objective={objective}
              groupTypes={groupTypes}
              objectiveType={objectiveType}
            />
          </FlexItem>
        </Flex>
      </React.Fragment>
    );
  });
};

ObjectiveItems.propTypes = {
  objectives: PropTypes.array,
  groupTypes: PropTypes.object,
  ml: PropTypes.string,
  mb: PropTypes.string,
  objectiveType: PropTypes.string,
  hasActualParents: PropTypes.bool,
};

export default ObjectiveItems;
