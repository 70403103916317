import React from "react";
import { startsWith, last, split, find } from "lodash";
import PropTypes from "prop-types";
import { P, StyledLink } from "orcs-design-system";
import { TAGS } from "src/consts/comments";
import { fullDisplayName } from "src/util/personName";

import { personDetailsPath, teamDetailsPath } from "../../util/routing";
import AuthorTitle from "../AuthorTitle";
import GenericHistoryContent from "./GenericHistoryContent";

const GROUP_ID_PREFIX = TAGS.GROUP_ID("");
const PERSON_ID_PREFIX = TAGS.PERSON_ID("");

const getPersonLinkFromId = (content, person) => {
  const dividerIndex = content.indexOf("]");
  const personName = fullDisplayName(person);

  if (dividerIndex === -1) {
    return {
      dividerIndex: -1,
    };
  }

  return {
    dividerIndex,
    otherContents: content.substring(dividerIndex + 1),
    personLink: (
      <StyledLink to={personDetailsPath(person)}>{personName}</StyledLink>
    ),
  };
};

const getPersonLinkFromTags = (content, tags) => {
  const dividerIndex = content.indexOf("}}");

  if (dividerIndex === -1) {
    return {
      dividerIndex: -1,
    };
  }

  const personName = content.substring(2, dividerIndex);

  const personTag = find(tags, (t) => startsWith(t, PERSON_ID_PREFIX));
  const aggregateId = last(split(personTag, ":"));

  return {
    dividerIndex,
    otherContents: content.substring(dividerIndex + 2),
    personLink: (
      <StyledLink to={personDetailsPath({ aggregateId })}>
        {personName}
      </StyledLink>
    ),
  };
};

const getTeamLink = (otherContents, tags) => {
  const teamNameDivider = otherContents.indexOf("{{");
  const teamNameEndDivider = otherContents.indexOf("}}");

  if (teamNameDivider === -1 || teamNameEndDivider === -1) {
    return {
      teamNameDivider,
      teamNameEndDivider,
    };
  }

  const groupTag = find(tags, (t) => startsWith(t, GROUP_ID_PREFIX));
  const groupId = last(split(groupTag, ":"));

  const teamName = otherContents.substring(
    teamNameDivider + 2,
    teamNameEndDivider
  );

  return {
    teamNameDivider,
    teamNameEndDivider,
    teamLink: <StyledLink to={teamDetailsPath(groupId)}>{teamName}</StyledLink>,
  };
};

const MembershipMutationHistory = ({ content, authorTitle, tags, person }) => {
  const { dividerIndex, otherContents, personLink } = person
    ? getPersonLinkFromId(content, person)
    : getPersonLinkFromTags(content, tags);

  if (dividerIndex === -1) {
    return (
      <GenericHistoryContent content={content} authorTitle={authorTitle} />
    );
  }

  const { teamNameDivider, teamNameEndDivider, teamLink } = getTeamLink(
    otherContents,
    tags
  );

  if (teamNameDivider === -1 || teamNameEndDivider === -1) {
    <GenericHistoryContent content={content} authorTitle={authorTitle} />;
  }

  const preContent = otherContents.substring(0, teamNameDivider);
  const postContent = otherContents.substring(teamNameEndDivider + 2);

  return (
    <>
      <P>
        {personLink}
        {preContent}
        {teamLink}
        {postContent}
      </P>
      <AuthorTitle action="Changed" authorTitle={authorTitle} />
    </>
  );
};

MembershipMutationHistory.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  authorTitle: PropTypes.string,
  tags: PropTypes.array,
  person: PropTypes.object,
};

export default MembershipMutationHistory;
