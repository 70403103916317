import React, { useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import Auth0UserProps from "src/custom-prop-types/auth0user";

import { useUserPerson } from "src/contexts/global/GlobalContext";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";

import RestrictedRoute from "src/app/Restricted/Route";
import useDefaultRoutePath from "src/app/Restricted/useDefaultRoutePath";
import PATHS from "../consts/urlPaths";
import useSegment from "./useSegment";

import { PageTitle } from "./Helmet";
import getRoutes from "./getRoutes";

const Routes = (props) => {
  const { user } = props;

  const userPerson = useUserPerson();
  const { pathname } = useLocation();
  const { disablePersonalisation } = useWorkspaceFeatureFlags();
  const defaultRoutePath = useDefaultRoutePath();

  const routes = useMemo(
    () => getRoutes(props, userPerson, disablePersonalisation),
    [props, userPerson, disablePersonalisation]
  );

  // Set up analytics for Route / User change
  useSegment(user, userPerson, routes);

  return (
    <Switch>
      {/* Adding a rule to detect an extra slash at the end of url and redirect to the same url without the slash */}
      <Redirect
        from="/:url*(/+)"
        to={{ ...location, pathname: pathname.slice(0, -1) }}
      />

      {routes.map((route) => {
        if (route.isDisabled) return null;

        const render = (rProps) => {
          return (
            <>
              <PageTitle title={route.title} id={rProps?.match.params.id} />
              <route.Component {...rProps} {...props} />
            </>
          );
        };

        return route.isRestricted ? (
          <RestrictedRoute key={route.path} {...route} render={render} />
        ) : (
          <Route key={route.path} {...route} render={render} />
        );
      })}

      {/* Summary page renamed to Insights */}
      <Redirect from={PATHS.SUMMARY_LEGACY} to={PATHS.INSIGHTS} />

      {/* Redirect added so that shared-components from TD can redirect to the correct Peron URLs */}
      <Redirect
        from={PATHS.PERSON_DETAILS_FROM_DIRECTORY}
        to={`${PATHS.PERSON_DETAILS_FROM_DIRECTORY}/details`}
      />

      {/* Redirect added so that shared-components from TD can redirect to the correct Team URLs */}
      <Redirect
        from={PATHS.TEAM_DETAILS_BASE_PATH}
        to={`${PATHS.TEAM_DETAILS_BASE_PATH}/details`}
      />

      <Route>
        <Redirect to={{ pathname: defaultRoutePath }} />
      </Route>
    </Switch>
  );
};

Routes.propTypes = {
  user: Auth0UserProps,
};

export default Routes;
