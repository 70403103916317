import PropTypes from "prop-types";
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useReactFlow } from "reactflow";
import { last, uniq, uniqBy } from "lodash";
import { NODE_TYPES } from "../consts";
import { createCollapsedNodeId, getAncestorsAndDescendants } from "./utils";

// Create the context
const ObjectiveContext = createContext();

export const DEFAULT_COLLAPSE_COUNT = 5;

export const ObjectiveStateProvider = ({
  children,
  getLayout,
  nodes: initialNodes,
}) => {
  const [hoveredObjective, setHoveredObjective] = useState();
  const [pinnedObjectives, setPinnedObjectives] = useState([]);
  const [collapsedHeaders, setCollapsedHeaders] = useState([]);
  const [currentlyExpandedNode, setCurrentlyExpandedNode] = useState();
  const reactFlow = useReactFlow();

  const onPinObjective = useCallback((objective) => {
    setPinnedObjectives((prevPinnedObjectives) => {
      const isPinned = prevPinnedObjectives.some((o) => o.id === objective.id);

      if (isPinned) {
        return prevPinnedObjectives.filter((o) => o.id !== objective.id);
      }

      return [...prevPinnedObjectives, objective];
    });
  }, []);

  const onToggleExpandedHeaders = useCallback(
    (header, isFooter) => {
      const headerId = header.headerId || header.id;

      setCollapsedHeaders((prevCollapsedHeaders) => {
        const isHeaderCollapsed = prevCollapsedHeaders.some(
          (cHeader) => cHeader.id === headerId
        );

        if (isHeaderCollapsed) {
          return prevCollapsedHeaders.filter((h) => h.id !== headerId);
        }

        return [...prevCollapsedHeaders, header];
      });

      const hasPinnedChildren = pinnedObjectives.some(
        (pinned) => header.depth === pinned.hierarchyDepth
      );

      if (hasPinnedChildren && !isFooter) return;

      setTimeout(() => {
        const jumpTarget = reactFlow
          .getNodes()
          .find((n) => n.id === createCollapsedNodeId(header.id));

        if (jumpTarget) {
          reactFlow.fitView({
            nodes: [jumpTarget],
            duration: 500,
            maxZoom: 0.5,
          });
        }
      }, 0);
    },
    [reactFlow, pinnedObjectives]
  );

  const filterNode = useCallback(
    (node, nodes) => {
      const { data: objective } = node;
      const { ancestors, descendants } = getAncestorsAndDescendants(
        nodes,
        objective
      );

      const relativeIds = [...ancestors, ...descendants].map((n) => n.id);

      const isVisible = pinnedObjectives.some(
        (pinned) =>
          pinned.id === objective.id || relativeIds.includes(pinned.id)
      );

      if (
        collapsedHeaders.some(
          (header) =>
            header.nodeType === objective.nodeType &&
            header.depth === objective.hierarchyDepth
        ) &&
        !isVisible
      ) {
        return false;
      }

      return true;
    },
    [collapsedHeaders, pinnedObjectives]
  );

  const mutateNodesAndEdges = useCallback(
    (_nodes, _edges, readyForLayout) => {
      if (!collapsedHeaders.length || !readyForLayout) {
        if (pinnedObjectives.length || currentlyExpandedNode) {
          const [layoutedNodes] = getLayout(
            _nodes.map((n) =>
              n.id === currentlyExpandedNode?.id ? currentlyExpandedNode : n
            ),
            _edges
          );

          return [layoutedNodes, _edges];
        }
        return [_nodes, _edges];
      }

      const updatedEdges = [..._edges];
      const filteredNodes = _nodes.filter((node) => filterNode(node, _nodes));
      const isHeaderNode = (node, header) =>
        node.data.nodeType === header.nodeType &&
        node.data.hierarchyDepth === header.depth;

      let finalNodes = filteredNodes;

      collapsedHeaders.forEach((header) => {
        const collapsedNodes = _nodes.filter(
          (node) => !filterNode(node, _nodes) && isHeaderNode(node, header)
        );

        if (collapsedNodes.length === 0) return;

        const headerNode = _nodes.find((node) => node.data.id === header.id);

        const lastItemInCollapsedSection = last(
          finalNodes
            .filter((node) => isHeaderNode(node, header))
            .sort((a, b) => a.position.y - b.position.y)
        );

        const { position, depth } = lastItemInCollapsedSection || {
          position: {
            x: headerNode.position.x,
            y: collapsedNodes.sort((a, b) => a.position.y - b.position.y)[0]
              ?.position.y,
          },
          depth: headerNode.depth,
        };

        const hierarchyParentIds = uniq(
          collapsedNodes.map((node) => node.data.hierarchyParentIds).flat()
        );
        const COLLAPSED_NODE = {
          id: createCollapsedNodeId(header.id),
          position: {
            x: position.x,
            y: 0,
          },
          label: `and ${collapsedNodes.length} more`,
          data: {
            id: createCollapsedNodeId(header.id),
            headerId: header.id,
            hierarchyParentIds,
            hiddenCount: collapsedNodes.length,
            hasParents: hierarchyParentIds.length > 0,
            collapsedNodes,
            nodeType: header?.nodeType,
          },
          depth,
          type: NODE_TYPES.SHOW_MORE_OBJECTIVES_NODE,
          zIndex: 3,
        };

        finalNodes.push(COLLAPSED_NODE);

        collapsedNodes.forEach((collapsedNode) => {
          const childNodes = finalNodes.filter((n) =>
            n.data.hierarchyParentIds.includes(collapsedNode.id)
          );

          childNodes.forEach((child) => {
            updatedEdges.push({
              id: `${createCollapsedNodeId(header.id)}-${child.id}`,
              target: child.id,
              source: createCollapsedNodeId(header.id),
              type: "objective-edge",
            });
          });
        });

        hierarchyParentIds.forEach((parentId) => {
          updatedEdges.push({
            id: `${parentId}-${createCollapsedNodeId(header.id)}`,
            source: parentId,
            target: createCollapsedNodeId(header.id),
            type: "objective-edge",
          });
        });
      });

      collapsedHeaders.forEach((header) => {
        const node = finalNodes.find((n) => n.data.id === header.id);

        const parent = finalNodes.find(
          (n) => n.id.includes("collapsed") && n.depth === node.depth + 1
        );

        if (parent) {
          updatedEdges.push({
            id: `${parent.id}-${createCollapsedNodeId(header.id)}`,
            source: parent.id,
            target: createCollapsedNodeId(header.id),
            type: "objective-edge",
          });
        }
      });

      const [layoutedNodes] = getLayout(
        filteredNodes.map((n) =>
          n.id === currentlyExpandedNode?.id ? currentlyExpandedNode : n
        ),
        _edges
      );

      finalNodes = finalNodes.map((node) => ({
        ...node,
        position: {
          ...node.position,
          ...(layoutedNodes.find((n) => n.id === node.id).position || {}),
        },
      }));

      const mutatedEdges = uniqBy(
        updatedEdges.filter((edge) => {
          const source = finalNodes.find((node) => node.id === edge.source);
          const target = finalNodes.find((node) => node.id === edge.target);

          return source && target;
        }),
        "id"
      );

      return [finalNodes, mutatedEdges];
    },
    [
      collapsedHeaders,
      filterNode,
      getLayout,
      pinnedObjectives,
      currentlyExpandedNode,
    ]
  );

  const getCollapsibleNodesCount = useCallback(
    (header, nodes) => {
      return nodes.filter(
        (node) =>
          node.data.hierarchyDepth === header.depth &&
          !pinnedObjectives.map((p) => p.id).includes(node.data.id)
      ).length;
    },
    [pinnedObjectives]
  );

  useEffect(() => {
    const initiallyCollapsedNodes = initialNodes
      ?.filter((n) => n.type === NODE_TYPES.OBJECTIVE_HEADER_NODE)
      .filter((node) => {
        const collapsibleCount = initialNodes.filter(
          (n) => n.data.hierarchyDepth === node.depth
        ).length;

        return collapsibleCount > DEFAULT_COLLAPSE_COUNT;
      })
      .map((n) => n.data);

    setCollapsedHeaders(initiallyCollapsedNodes);
  }, [initialNodes]);

  const contextValue = useMemo(
    () => ({
      hoveredObjective,
      setHoveredObjective,
      onPinObjective,
      onToggleExpandedHeaders,
      collapsedHeaders,
      mutateNodesAndEdges,
      pinnedObjectives,
      getCollapsibleNodesCount,
      setCurrentlyExpandedNode,
      currentlyExpandedNode,
    }),
    [
      hoveredObjective,
      onPinObjective,
      onToggleExpandedHeaders,
      collapsedHeaders,
      mutateNodesAndEdges,
      pinnedObjectives,
      getCollapsibleNodesCount,
      setCurrentlyExpandedNode,
      currentlyExpandedNode,
    ]
  );

  return (
    <ObjectiveContext.Provider value={contextValue}>
      {children}
    </ObjectiveContext.Provider>
  );
};

ObjectiveStateProvider.propTypes = {
  children: PropTypes.node,
  getLayout: PropTypes.func,
  nodes: PropTypes.arrayOf(PropTypes.object),
};

export const useObjectiveState = () => useContext(ObjectiveContext);
