import React from "react";
import { Box } from "orcs-design-system";

const EmptyColumn = () => {
  return (
    <>
      <Box />
      <Box />
    </>
  );
};

EmptyColumn.propTypes = {};

export default React.memo(EmptyColumn);
