/* eslint-disable import/prefer-default-export */
import { get, isEmpty, isArray } from "lodash";

// import { reportError } from "src/util/sentry";

import { BLOCK_TAGS } from "./RichTextEditor.config";

const isValidSlateContent = (content) => {
  return get(content, "children") || get(content, "[0].children");
};

// get a slate object if possible from stringified payload
export const getSlateContent = (content) => {
  if (isEmpty(content)) {
    return [
      {
        type: BLOCK_TAGS.p,
        children: [{ text: "" }],
      },
    ];
  }

  // If content is an array, validate first
  if (isArray(content) && isValidSlateContent(content)) {
    return content;
  }

  try {
    // If content is string, parse it
    const contentJSON = JSON.parse(content);

    if (!isValidSlateContent(contentJSON)) {
      throw Error(content);
    }

    return contentJSON;
  } catch (e) {
    // reportError("Not a valid Slate JSON", {
    //   content: e,
    // });
    // not a valid Slate object, just a string
    return [
      {
        type: BLOCK_TAGS.p,
        children: [{ text: content || "" }],
      },
    ];
  }
};
