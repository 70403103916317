import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { Flex, Text, Small } from "orcs-design-system";

import { numberToLocaleString } from "src/util/toLocaleString";
import {
  getLineItemsTotalsByType,
  getLineItemsTotalCostsByType,
} from "src/allocation/pages/ForecastPage/context/reducer/modelReducer/util/lineItemsWithType";
import { getExpandHeaderLabel } from "src/allocation/pages/ForecastPage/components/ForecastGroupings/LineItems/LineItemsExpandHeader.logic";
import useGridConfig from "../../util/gridConfig";
import CustomGrid from "../../Shared/CustomGrid";
import TreeExpand from "../../Shared/TreeExpand";
import TotalCostCell from "../../Shared/TotalCostCell";
import {
  useFinancialForecasting,
  ACTIONS,
} from "../../../context/ForecastContext";
import TotalFteSum from "../../Shared/TotalFteSum";

const LineItemsExpandHeader = ({
  grouping,
  isExpanded,
  dispatch,
  type,
  expandId,
  showIntersectingFteSum,
}) => {
  const { group, nestedLevel, isSupply, lineItemTotalFtes } = grouping;
  const shouldShowCost = useFinancialForecasting();
  const gridConfig = useGridConfig();
  const { templateColumns, gap } = gridConfig.forecast;
  const lineItemTotalsByType = getLineItemsTotalsByType(grouping, type);
  const totalCost = getLineItemsTotalCostsByType(grouping, type);

  const expandHeaderLabel = getExpandHeaderLabel({
    group,
    isExpanded,
    isSupply,
    type,
  });
  const handleToggle = useCallback(() => {
    dispatch({
      type: ACTIONS.TOGGLE_GROUPING_ROLE_TREE_EXPANSION,
      id: expandId,
    });
  }, [expandId, dispatch]);

  return (
    <TreeExpand
      isOpen={isExpanded}
      indentLevel={nestedLevel}
      onToggle={handleToggle}
      controlsGroupRoles={true}
    >
      <CustomGrid
        gridTemplateColumns={templateColumns}
        gridGap={gap}
        className="guided-tour-allocation-forecast-expand-roles"
      >
        <Small mr="auto" data-testid={`${expandId}-lineItem-toggle`}>
          {expandHeaderLabel}
        </Small>
        {shouldShowCost && <TotalCostCell lineItem={{ totalCost }} />}
        {map(lineItemTotalsByType, ({ columnId, total }, index) => (
          <Flex key={columnId} alignItems="center" justifyContent="center">
            {showIntersectingFteSum && lineItemTotalFtes && (
              <TotalFteSum fteSum={lineItemTotalFtes[index]} />
            )}
            <Text weight="bold">{numberToLocaleString(total)}</Text>
          </Flex>
        ))}
      </CustomGrid>
    </TreeExpand>
  );
};

LineItemsExpandHeader.propTypes = {
  grouping: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  type: PropTypes.string,
  expandId: PropTypes.string,
  showIntersectingFteSum: PropTypes.bool,
};

export default LineItemsExpandHeader;
