import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { FlexItem, Flex } from "orcs-design-system";

import GroupTypeBadge from "src/components/GroupTypeBadge";
import { buildLineItemsId } from "src/allocation/util/group";

import useGridConfig from "../../util/gridConfig";
import {
  ACTIONS,
  MACRO_ALLOCATION_TYPES,
  useFinancialForecasting,
  useLookupData,
} from "../../../context/ForecastContext";
import CustomGrid from "../../Shared/CustomGrid";
import TreeExpand from "../../Shared/TreeExpand";
import FteQueryBadgeForSupply from "../../Shared/FteQueryBadgeForSupply";
import CostAndBudgetCell from "../../Shared/CostAndBudgetCell";
import GroupName from "../../Shared/GroupName";
import GroupingActionMenu from "../../Shared/GroupingActionMenu";

import GroupingTotals from "./GroupingTotals";

const GroupingExpandHeader = ({
  grouping,
  isExpanded,
  dispatch,
  viewMode,
  rootGroup,
}) => {
  const shouldShowCost = useFinancialForecasting();
  const { hideBudgetTooltip, hideBudgetColumn } = useLookupData();
  const gridConfig = useGridConfig();
  const {
    id,
    group,
    nestedLevel,
    canToggleLineItems,
    isSupply,
    root: { isSupply: isRootGroupSupply },
  } = grouping;
  const { templateColumns, gap } = gridConfig.forecast;

  const handleToggle = useCallback(() => {
    dispatch({
      type: ACTIONS.TOGGLE_GROUPING_TREE_EXPANSION,
      id,
      lineItemsId: buildLineItemsId(
        id,
        MACRO_ALLOCATION_TYPES.INDIRECT_PLACEHOLDER
      ),
    });
  }, [id, dispatch]);

  return (
    <TreeExpand
      hasConnectedTreeExpand={canToggleLineItems}
      isOpen={isExpanded}
      indentLevel={nestedLevel}
      onToggle={handleToggle}
    >
      <CustomGrid
        gridTemplateColumns={templateColumns}
        gridGap={gap}
        className="guided-tour-allocation-forecast-grouping-header"
      >
        <Flex
          alignItems="center"
          className="guided-tour-allocation-forecast-grouping-group-name"
        >
          <FlexItem flex="1 1 auto" maxWidth="450px">
            <GroupName group={group} />
          </FlexItem>
          <FlexItem flex="0 0 auto" mx="xs">
            <GroupTypeBadge
              group={group}
              supplyHasOwnColour
              showToolTip
              toolTipPosition="bottomRight"
            />
          </FlexItem>
          {isRootGroupSupply && isSupply && (
            <FlexItem flex="0 0 auto" mr="2">
              <FteQueryBadgeForSupply isSupply={isSupply} group={group} />
            </FlexItem>
          )}
        </Flex>
        {shouldShowCost && (
          <CostAndBudgetCell
            grouping={grouping}
            viewMode={viewMode}
            rootGroup={rootGroup}
            hideBudgetColumn={hideBudgetColumn}
            hideBudgetTooltip={hideBudgetTooltip}
          />
        )}
        <GroupingTotals
          grouping={grouping}
          dispatch={dispatch}
          isRootGroupSupply={isRootGroupSupply}
          shouldShowCost={shouldShowCost && nestedLevel === 0}
        />
        <GroupingActionMenu grouping={grouping} dispatch={dispatch} />
      </CustomGrid>
    </TreeExpand>
  );
};

GroupingExpandHeader.propTypes = {
  grouping: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired,
  rootGroup: PropTypes.object.isRequired,
};

export default GroupingExpandHeader;
