import { useQuery } from "@apollo/client";
import { get, isEmpty, filter, map, keyBy, orderBy, head } from "lodash";
import moment from "moment";
import { Grid, FlexItem, Select, Box, Notification } from "orcs-design-system";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from "react";

import GroupsList from "src/components/Team/GroupsList";
import { numberToLocaleString } from "src/util/toLocaleString";
import getTagsByGroupId from "src/util/getTagsByGroupId";
import useSortOptions, { sortPrecedence } from "src/hooks/useSortOptions";
import { GROUPS_SORT_OPTIONS } from "src/consts/team";
import { Line as PlaceholderLine } from "src/components/Placeholder/PlaceholderStyles";
import AvatarPlaceholder from "src/components/Placeholder/AvatarPlaceholder";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import { copywriting } from "src/pages/TeamPage/sub-components/TeamMembersPanel.config";
import {
  getTeamChildTeamsQuery,
  getMemberCountForTeamsQuery,
} from "src/queries/group.graphql";
import { getLocalSortOption } from "src/services/localStorage";
import { SORT_INDEX } from "src/consts/storage";
import Panel from "src/components/Panel";
import {
  sortGroupsByName,
  isGroupVisible,
  buildChildTeamHierarchy,
} from "src/util/group";

import { getCloneHierarchyJobs } from "src/queries/team.graphql";
import {
  BATCH_JOB_STATUSES,
  cloneJobNotifications,
  SortByOptionLabel,
} from "../../utils/index";

import NewChildBox from "./NewChildBox";

const EXPLORE_PLACEHOLDER_ROWS = 6;

const GroupActions = ({
  childGroupsCount,
  showAddNewTeam,
  team,
  groupTypes,
  onAddingNewGroup,
  onNewGroupAdded,
  selectedSort,
  onSelectSortType,
  options,
}) => {
  const gridColumns =
    childGroupsCount < 1
      ? "minmax(312px,1fr)"
      : "minmax(312px,1fr) minmax(140px,140px)";

  return showAddNewTeam ? (
    <FlexItem flex={["1 1 100%", "1 1 100%", "1 1 auto"]}>
      <Grid
        gridGap={["s", "s", "r"]}
        gridTemplateColumns={["1fr", "1fr", gridColumns]}
      >
        <NewChildBox
          parentGroup={team}
          groupTypes={groupTypes}
          onAddingNewGroup={onAddingNewGroup}
          onNewGroupAdded={onNewGroupAdded}
        />
        {Boolean(childGroupsCount) && (
          <Box display={["none", "none", "block"]}>
            <Select
              data-testid="cp-team-explore-select"
              inputId="cp-team-explore-select"
              options={options}
              isSearchable={false}
              value={selectedSort}
              formatOptionLabel={SortByOptionLabel}
              onChange={onSelectSortType}
              ariaLabel="Sort teams by"
            />
          </Box>
        )}
      </Grid>
    </FlexItem>
  ) : (
    Boolean(childGroupsCount) && (
      <FlexItem flex="0 0 140px">
        <Select
          data-testid="cp-team-explore-select"
          inputId="cp-team-explore-select"
          options={options}
          isSearchable={false}
          value={selectedSort}
          onChange={onSelectSortType}
          ariaLabel="Sort teams by"
        />
      </FlexItem>
    )
  );
};

GroupActions.propTypes = {
  team: PropTypes.oneOfType([PropTypes.number, GroupPropType]),
  childGroupsCount: PropTypes.number,
  groupTypes: GroupTypesPropType,
  showAddNewTeam: PropTypes.bool,
  onAddingNewGroup: PropTypes.func,
  onNewGroupAdded: PropTypes.func,
  selectedSort: PropTypes.object,
  onSelectSortType: PropTypes.func,
  options: PropTypes.object,
};

const TeamExplorePanel = ({
  teamId,
  team,
  groupTypes,
  showAddNewTeam,
  renderFteBadge,
  renderPrincipleStatus,
  workspace,
}) => {
  const { data: childTeamsData, loading: childTeamsDataLoading } = useQuery(
    getTeamChildTeamsQuery,
    {
      variables: {
        groupId: teamId,
      },
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
      skip: !teamId,
    }
  );
  const loading = childTeamsDataLoading && !childTeamsData;
  const childTeams = get(childTeamsData, "team.childTeams" || []);

  const groupIds = map(childTeams, "id");
  const { data: teamsMemberCountData } = useQuery(getMemberCountForTeamsQuery, {
    variables: {
      groupIds,
    },
    fetchPolicy: "cache-and-network",
    skip: isEmpty(groupIds),
  });

  const teamsMemberCountMap = keyBy(
    get(teamsMemberCountData, "teams", []),
    "id"
  );

  const filteredChildTeams = useMemo(() => {
    return map(
      filter(childTeams, (t) => isGroupVisible(t)),
      ({ id, ...rest }) => {
        return {
          id,
          ...rest,
          ...get(teamsMemberCountMap, id, {}),
          hierarchy: buildChildTeamHierarchy(team, rest),
        };
      }
    );
  }, [childTeams, team, teamsMemberCountMap]);

  const {
    data: cloneJobs,
    startPolling,
    stopPolling,
  } = useQuery(getCloneHierarchyJobs, {
    variables: { groupId: teamId },
    fetchPolicy: "cache-and-network",
  });

  const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);
  const newChildRef = useRef(null);

  const initialSort = sortPrecedence({
    sortOptions: GROUPS_SORT_OPTIONS,
    sortTeamsByGroupType: get(
      workspace,
      "config.featureFlags.sortTeamsByGroupType"
    ),
    sessionSortValue: getLocalSortOption(SORT_INDEX.TEAMS_EXPLORE_PANEL),
  });

  const { options, selectedSort, onSelectSortType } = useSortOptions(
    GROUPS_SORT_OPTIONS,
    SORT_INDEX.TEAMS_EXPLORE_PANEL,
    initialSort
  );

  useEffect(() => {
    setIsAddingNewGroup(false);
    newChildRef.current = null;
  }, [teamId]);

  const onAddingNewGroup = useCallback((isAdding) => {
    setIsAddingNewGroup(isAdding);
  }, []);

  const onNewGroupAdded = useCallback((group) => {
    newChildRef.current = group.id;
  }, []);

  const childGroups = loading
    ? Array(EXPLORE_PLACEHOLDER_ROWS).fill(0)
    : sortGroupsByName(filteredChildTeams);

  const childGroupsCount = childGroups.length;
  const childGroupsCountDisplay = loading
    ? "-"
    : numberToLocaleString(childGroupsCount);

  const isChildGroupsEmpty =
    !loading && !isAddingNewGroup && isEmpty(childGroups);

  const cloneJobStatusNotification = useMemo(() => {
    const jobs = get(cloneJobs, "cloneJobs.batchJobs" || {});
    const latestJob = head(orderBy(jobs, ["updatedAt"], ["desc"]));

    if (!latestJob) {
      return null;
    }
    if (
      [BATCH_JOB_STATUSES.CREATED, BATCH_JOB_STATUSES.IN_PROGRESS].includes(
        latestJob.status
      )
    ) {
      startPolling(5000);
      return cloneJobNotifications(latestJob);
    }
    stopPolling();
    const fiveMinutesAgo = moment().subtract(5, "minutes");
    if (moment(latestJob.updatedAt).isAfter(fiveMinutesAgo)) {
      return cloneJobNotifications(latestJob);
    }
    return null;
  }, [cloneJobs, startPolling, stopPolling]);

  // When childGroups is empty, only display this panel when allocations feature flag is true
  // and this type can create child
  if (isChildGroupsEmpty && !showAddNewTeam) {
    return null;
  }

  const tagsByGroupId = getTagsByGroupId(childGroups);

  return (
    <Panel
      title={`${childGroupsCountDisplay} ${pluralize(
        copywriting.childGroup,
        childGroupsCount
      )}`}
      actions={
        <GroupActions
          childGroupsCount={childGroupsCount}
          onSelectSortType={onSelectSortType}
          onAddingNewGroup={onAddingNewGroup}
          onNewGroupAdded={onNewGroupAdded}
          team={team}
          groupTypes={groupTypes}
          selectedSort={selectedSort}
          options={options}
          showAddNewTeam={showAddNewTeam}
        />
      }
      wrap="wrap"
      data-testid="cp-explore-container"
    >
      {cloneJobStatusNotification && (
        <Notification
          key={Math.random()}
          floating={true}
          centered={true}
          bottom={20}
          colour={cloneJobStatusNotification.status}
        >
          {cloneJobStatusNotification.message}
        </Notification>
      )}

      {loading && (
        <PlaceholderLine
          data-testid="cp-explore-placeholder-title"
          width={240}
          height={54}
        />
      )}
      {isAddingNewGroup && <AvatarPlaceholder row={1} />}
      <GroupsList
        loading={loading}
        supportedGroups={childGroups}
        tagsByGroupId={tagsByGroupId}
        groupTypes={groupTypes}
        withSort
        selectedSort={selectedSort}
        newChildId={newChildRef.current}
        renderFteBadge={renderFteBadge}
        renderPrincipleStatus={renderPrincipleStatus}
        workspace={workspace}
      />
    </Panel>
  );
};

TeamExplorePanel.propTypes = {
  teamId: PropTypes.string,
  team: PropTypes.oneOfType([PropTypes.number, GroupPropType]),
  groupTypes: GroupTypesPropType,
  showAddNewTeam: PropTypes.bool,
  renderFteBadge: PropTypes.func,
  renderPrincipleStatus: PropTypes.func,
  workspace: PropTypes.object,
};

export default React.memo(TeamExplorePanel);
