import { Box, Button, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import icons from "src/config/icons";
import GroupPropType from "src/custom-prop-types/group";

import RemoveAssociationModal from "./RemoveAssociationModal";

const RemoveAssociationButton = ({
  subjectTeam,
  associatedTeam,
  tags,
  refetch,
}) => {
  const [isRemoveAssociationModalVisible, setIsRemoveAssociationModalVisible] =
    useState(false);
  const toggleRemoveAssociationModal = useCallback(
    (isVisible) => () => {
      setIsRemoveAssociationModalVisible(isVisible);
      if (!isVisible) {
        refetch();
      }
    },
    [refetch]
  );

  return (
    <>
      <Box ml="s">
        <Button
          iconOnly
          small
          variant="danger"
          onClick={toggleRemoveAssociationModal(true)}
          data-testid="remove-association-button"
          ariaLabel="Remove association"
        >
          <Icon icon={icons.cancel} />
        </Button>
      </Box>
      <RemoveAssociationModal
        subjectTeam={subjectTeam}
        associatedTeam={associatedTeam}
        tags={tags}
        onHideModal={toggleRemoveAssociationModal(false)}
        isVisible={isRemoveAssociationModalVisible}
      />
    </>
  );
};

RemoveAssociationButton.propTypes = {
  subjectTeam: GroupPropType,
  associatedTeam: GroupPropType,
  tags: PropTypes.array,
  refetch: PropTypes.func,
};

export default RemoveAssociationButton;
