import React from "react";
import PropTypes from "prop-types";
import { P } from "orcs-design-system";
import AuthorTitle from "../AuthorTitle";

const PlanningHistoryContent = ({
  content,
  authorTitle,
  demandGroup,
  supplyGroup,
  allocationProjectName,
}) => {
  const demandGroupName = demandGroup ? demandGroup.name : "";
  const supplyGroupName = supplyGroup ? supplyGroup.name : "";
  let fullContent = `${demandGroupName} ${content} from ${supplyGroupName}`;
  if (allocationProjectName) {
    fullContent = `${fullContent} in period ${allocationProjectName}`;
  }
  return (
    <>
      <P>{fullContent}</P>
      <AuthorTitle action="Made" authorTitle={authorTitle} />
    </>
  );
};

PlanningHistoryContent.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  authorTitle: PropTypes.string,
  demandGroup: PropTypes.object,
  supplyGroup: PropTypes.object,
  allocationProjectName: PropTypes.string,
};

export default PlanningHistoryContent;
