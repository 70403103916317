import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Tabs } from "orcs-design-system";
import { get, isEmpty } from "lodash";
import {
  DEFAULT_PLANNER_TAB_NAME,
  DEFAULT_FORECAST_TAB_NAME,
} from "src/allocation/consts";
import getGroupFeatureVisibility from "src/util/getGroupFeatureVisibility";
import {
  useWorkspace,
  useWorkspaceFeatureFlags,
} from "src/contexts/global/WorkspaceContext";
import getAllocationVisibility from "src/allocation/util/getAllocationVisibility";
import { TabsPlaceholder } from "src/components/TeamDetailPage/PlaceholderComponents";
import isSupplyDemandGroupType from "src/util/isSupplyDemandGroupType";
import { TEAM_TAB } from "../../consts/urlPaths";
import { useAllocationProject } from "../../contexts/global/GlobalContext";
import RefreshButton from "./RefreshButton";

export const RefreshButtonWrapper = styled.div`
  margin-left: auto;
`;

const TeamTabs = ({ team = {} }) => {
  const isTeamActive = !team?.isHidden && !team?.isDeleted;
  const workspace = useWorkspace();
  const {
    disableAssociations,
    enableTeamStrategyTab,
    enableFutureAllocationsUI,
    enableFutureAllocationsTimeline,
  } = useWorkspaceFeatureFlags();
  const activeAllocationProject = useAllocationProject();
  const { config } = workspace;
  const allocationVisibility = getAllocationVisibility({
    group: team,
    config,
    activeAllocationProject,
  });
  const groupFeatureVisibility = getGroupFeatureVisibility({
    group: team,
    config,
  });
  const plannerTabName =
    get(workspace, "config.allocation.plannerTabName") ||
    DEFAULT_PLANNER_TAB_NAME;
  const forecastTabName =
    get(workspace, "config.allocation.forecastTabName") ||
    DEFAULT_FORECAST_TAB_NAME;
  const [isSupply, isDemand] = isSupplyDemandGroupType(team, workspace);
  const isSupplyOrDemand = isSupply || isDemand;

  const teamTabsList = [
    {
      label: "Details",
      path: TEAM_TAB.DETAILS,
      className: "guided-tour-team-details-tab",
    },
    {
      label: "Allocations",
      path: TEAM_TAB.MEMBERSHIPS,
      isVisible:
        !!isTeamActive &&
        !!isSupplyOrDemand &&
        !!enableFutureAllocationsUI &&
        !!enableFutureAllocationsTimeline &&
        !!groupFeatureVisibility.allocations,
    },
    {
      label: "Strategy",
      path: TEAM_TAB.STRATEGY,
      isVisible: !!isTeamActive && !!enableTeamStrategyTab,
    },
    {
      label: "Associations",
      path: TEAM_TAB.ASSOCIATIONS,
      isVisible: !!isTeamActive && !disableAssociations,
    },
    {
      label: "Visualisation",
      path: TEAM_TAB.VISUALISATION,
      isVisible: !!isTeamActive && !!groupFeatureVisibility.visualisation,
    },
    {
      label: "Principles",
      path: TEAM_TAB.PRINCIPLES,
      id: "principles-tab",
      isVisible: !!isTeamActive && !!groupFeatureVisibility.principles,
    },
    {
      label: plannerTabName,
      path: TEAM_TAB.REQUEST_ALLOCATION_NUMBERS,
      isVisible: !!isTeamActive && !!allocationVisibility.requestNumbers2,
    },
    {
      label: plannerTabName,
      path: TEAM_TAB.APPROVE_ALLOCATION_NUMBERS,
      isVisible: !!isTeamActive && !!allocationVisibility.approveNumbers2,
    },
    {
      label: forecastTabName,
      path: TEAM_TAB.FORECAST,
      isVisible: !!isTeamActive && !!allocationVisibility.forecastNumbers,
    },
    {
      label: "Team Builder",
      path: TEAM_TAB.INDIVIDUAL_ALLOCATIONS,
      isVisible:
        !!isTeamActive &&
        (!!allocationVisibility.requestPeople ||
          !!allocationVisibility.individualAllocations),
    },
    {
      label: "History",
      path: TEAM_TAB.HISTORY,
      isVisible: !!groupFeatureVisibility.history,
    },
  ];

  return (
    <Box py="r">
      {isEmpty(team) ? (
        <TabsPlaceholder />
      ) : (
        <>
          <Tabs tabsList={teamTabsList} />
          <RefreshButtonWrapper>
            <RefreshButton displayOnly hiddenWhenNotRefreshing />
          </RefreshButtonWrapper>
        </>
      )}
    </Box>
  );
};
TeamTabs.propTypes = {
  team: PropTypes.object,
};
export default TeamTabs;
