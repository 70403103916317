import React from "react";
import {
  map,
  filter,
  startsWith,
  forEach,
  includes,
  last,
  split,
  find,
  keyBy,
} from "lodash";
import { isDemandGroup } from "src/util/customerConfig";
import { TAGS } from "src/consts/comments";
import copywriting from "src/components/Tag/TagTypeForm.config";
import {
  TYPE_ALLOCATION,
  TYPE_OBJECTIVES,
  TYPE_OTHER,
  TYPE_PLANNING,
  TYPE_FORECAST,
  TYPE_SUPPLY_UPDATE,
  TYPE_TAGGING,
  TYPE_DOWNLOAD,
  TYPE_GROUP_UPDATE,
  TYPE_PERSON_UPDATE,
  TYPE_MEMBERSHIPS,
} from "./consts";
import GenericHistoryContent from "./GenericHistoryContent";
import PlanningHistoryContent from "./PlanningHistoryContent";
import TaggingHistoryContent from "./TaggingHistoryContent";
import PlanningSubmissionContent from "./PlanningSubmissionContent";
import MoveGroupHistoryContent from "./MoveGroupHistoryContent";
import PersonHistoryContent from "./PersonHistoryContent";
import SetGroupManagerHistoryContent from "./SetGroupManagerHistoryContent";
import DownloadHistoryContent from "./DownloadHistoryContent";
import GroupUpdateHistoryContent from "./GroupUpdateHistoryContent";
import MembershipMutationHistory from "./MembershipMutationHistory";

const { ALLOCATED, FTE_UPDATED, UNALLOCATED } =
  TAGS.ALLOCATION.INDIVIDUAL_ALLOCATION_ACTION;

const INDIVIDUAL_ALLOCATION_TAG = TAGS.ALLOCATION.INDIVIDUAL_ALLOCATION;
const OBJECTIVE_TAG = TAGS.OBJECTIVE.COMMENT;
const PLANNING_TAG = TAGS.ALLOCATION.PLANNING;
const FORECAST_TAG = TAGS.ALLOCATION.FORECASTING;
const PLANNING_SUBMIT_TAG = TAGS.ALLOCATION.ACTION.REQUEST_SUBMITTED;
const FORECAST_SUBMIT_TAG = TAGS.ALLOCATION.ACTION.FORECAST_SUBMITTED;

const GROUPID_PREFIX = TAGS.GROUP_ID("");
const ORIGINAL_DISPLAY_VALUE_PREFIX = TAGS.TAG_ORIGINAL_DISPLAY_VALUE("");

const ICONS = {
  [ALLOCATED]: ["fas", "user-plus"],
  [FTE_UPDATED]: ["fas", "user-edit"],
  [UNALLOCATED]: ["fas", "user-minus"],
  [TYPE_DOWNLOAD]: ["fas", "download"],
};

const getIcon = (tags) => {
  for (let i = 0, j = tags.length; i < j; i += 1) {
    const tag = tags[i];
    switch (tag) {
      case ALLOCATED:
        return ICONS[ALLOCATED];
      case UNALLOCATED:
        return ICONS[UNALLOCATED];
      default: // do nothing
    }
  }

  // By default, return user edit icon
  return ICONS[FTE_UPDATED];
};

const getHistoryType = (tags) => {
  // Individual allocation tag
  for (let i = 0, j = tags.length; i < j; i += 1) {
    const tag = tags[i];
    switch (tag) {
      case INDIVIDUAL_ALLOCATION_TAG:
        return TYPE_ALLOCATION;
      case OBJECTIVE_TAG:
        return TYPE_OBJECTIVES;
      case PLANNING_TAG:
        return TYPE_PLANNING;
      case FORECAST_TAG:
        return TYPE_FORECAST;
      case TAGS.SUPPLY_GROUP_UPDATE:
        return TYPE_SUPPLY_UPDATE;
      case TAGS.TAGGING.COMMENT:
        return TYPE_TAGGING;
      case TAGS.DOWNLOAD:
        return TYPE_DOWNLOAD;
      case TAGS.GROUP_UPDATE:
        return TYPE_GROUP_UPDATE;
      case TAGS.PERSON_UPDATE:
        return TYPE_PERSON_UPDATE;
      case TAGS.MEMBERSHIP_UPDATE:
        return TYPE_MEMBERSHIPS;
      default: // do nothing
    }
  }

  return TYPE_OTHER;
};

const getRelatedGroupIds = (tags) => {
  const groupIds = [];
  for (let i = 0, j = tags.length; i < j; i += 1) {
    const tag = tags[i];

    if (startsWith(tag, GROUPID_PREFIX)) {
      const parts = tag.split(":");
      if (parts.length === 2 && parts[1]) {
        groupIds.push(parts[1]);
      }
    }
  }

  return groupIds;
};

const getDemandAndSupplyGroup = (relatedGroupIds, groupsMap, groupTypes) => {
  let demandGroup = null;
  let supplyGroup = null;

  if (relatedGroupIds && groupsMap && groupTypes) {
    forEach(relatedGroupIds, (id) => {
      const group = groupsMap[id];
      if (!group) {
        return;
      }

      if (isDemandGroup(groupTypes, group)) {
        demandGroup = group;
      } else {
        supplyGroup = group;
      }
    });
  }

  return [demandGroup, supplyGroup];
};

const safeJsonParse = (value) => {
  const result = {};
  try {
    result.value = JSON.parse(value);
  } catch (e) {
    result.error = e;
  }
  return result;
};

export const convertTagTypeCommentContent = (commentContent, tags) => {
  const copywritingKeys = Object.keys(copywriting);
  const commentObject = safeJsonParse(commentContent);
  if (!commentObject.error && commentObject.value) {
    const commentEntries = Object.entries(commentObject.value);

    const historyItems = map(commentEntries, ([key, value]) => {
      if (key === "name") {
        return ` Name updated: ${value}`;
      }

      if (key === "details") {
        return ` Description updated`;
      }

      if (copywritingKeys.includes(key)) {
        return ` ${copywriting[key]} - toggled ${value ? "on" : "off"}`;
      }

      if (key === "displayValue") {
        const origninalDisplayValueTag = find(tags, (t) =>
          startsWith(t, ORIGINAL_DISPLAY_VALUE_PREFIX)
        );
        const origninalDisplayValue = last(
          split(origninalDisplayValueTag, ":")
        );
        if (origninalDisplayValue) {
          return `Tag display value updated from "${origninalDisplayValue}" to "${value}"`;
        }
        return ` Tag displayValue updated to ${value}`;
      }

      return `${key}: ${value}`;
    });
    return historyItems.toString();
  }
  return commentContent;
};

export const convertCommentToHistoryRecord = ({
  comment,
  groupsMap,
  groupTypes,
  person,
}) => {
  const { id, content, authorTitle, timestamp, tags } = comment;

  const historyType = getHistoryType(tags);

  // Ignore other history types
  if (historyType === TYPE_OTHER) {
    return null;
  }

  let relatedGroupIds = null;
  let convertedContent = null;
  let icon = null;
  if (historyType === TYPE_PLANNING || historyType === TYPE_FORECAST) {
    relatedGroupIds = getRelatedGroupIds(tags);
    convertedContent = { content, authorTitle };
  } else if (
    historyType === TYPE_ALLOCATION ||
    historyType === TYPE_PERSON_UPDATE ||
    historyType === TYPE_SUPPLY_UPDATE
  ) {
    convertedContent = (
      <PersonHistoryContent
        content={content}
        authorTitle={authorTitle}
        tags={tags}
      />
    );
  } else if (historyType === TYPE_GROUP_UPDATE) {
    convertedContent = (
      <GroupUpdateHistoryContent comment={comment} groupsMap={groupsMap} />
    );
  } else if (historyType === TYPE_TAGGING) {
    relatedGroupIds = getRelatedGroupIds(tags);
    const [demandGroup, supplyGroup] = getDemandAndSupplyGroup(
      relatedGroupIds,
      groupsMap,
      groupTypes
    );
    convertedContent = (
      <TaggingHistoryContent
        content={convertTagTypeCommentContent(content, tags)}
        authorTitle={authorTitle}
        supplyGroup={supplyGroup}
        demandGroup={demandGroup}
        tags={tags}
      />
    );
  } else if (historyType === TYPE_DOWNLOAD) {
    icon = ICONS[TYPE_DOWNLOAD];
    [relatedGroupIds] = getRelatedGroupIds(tags);

    convertedContent = (
      <DownloadHistoryContent
        content={content}
        authorTitle={authorTitle}
        group={groupsMap[relatedGroupIds]}
      />
    );
  } else if (historyType === TYPE_MEMBERSHIPS) {
    convertedContent = (
      <MembershipMutationHistory
        content={content}
        authorTitle={authorTitle}
        person={person}
        tags={tags}
      />
    );
  } else {
    convertedContent = (
      <GenericHistoryContent content={content} authorTitle={authorTitle} />
    );
  }

  return {
    id,
    content: convertedContent,
    icon: icon || getIcon(tags, historyType),
    timestamp,
    type: historyType,
    relatedGroupIds,
    tags,
  };
};

export const convertCommentsToHistory = ({
  comments,
  groupsMap,
  groupTypes,
  person,
}) => {
  // Comments are already sorted in back end by timestamp desc
  return filter(
    map(comments, (comment) =>
      convertCommentToHistoryRecord({ comment, groupsMap, groupTypes, person })
    ),
    (h) => !!h
  );
};

const createPlanningRecord = (record, content) => {
  return {
    ...record,
    content,
  };
};

const getAllocationProjectName = ({ h, allocationProjectsById }) => {
  if (h.type === TYPE_FORECAST) {
    const allocationProjectTag = find(h.tags, (t) =>
      startsWith(t, "ALLOCATION_PROJECT_ID")
    );
    if (allocationProjectTag) {
      const parts = allocationProjectTag.split(":");
      const allocationProjectId = last(parts);
      const allocationProject = allocationProjectsById[allocationProjectId];
      if (allocationProject) {
        return allocationProject.allocationName;
      }
    }
  }
  return null;
};
export const updatePlanningHistoryRecords = ({
  historyData,
  groupsMap,
  groupTypes,
  allocationProjects,
}) => {
  const historyRecords = [];
  let submissionRecordCreated = false;
  const allocationProjectsById = keyBy(allocationProjects, "id");
  forEach(historyData, (h) => {
    const { type, relatedGroupIds, tags } = h;

    if (type !== TYPE_PLANNING && type !== TYPE_FORECAST) {
      historyRecords.push(h);
      return;
    }

    if (includes(tags, TAGS.PERSON_PLACEHOLDER)) {
      historyRecords.push(
        createPlanningRecord(h, <GenericHistoryContent {...h.content} />)
      );
      return;
    }

    if (includes(tags, TAGS.MOVING_GROUP)) {
      historyRecords.push(
        createPlanningRecord(h, <MoveGroupHistoryContent {...h.content} />)
      );
      return;
    }

    if (includes(tags, TAGS.SET_GROUP_MANAGER)) {
      historyRecords.push(
        createPlanningRecord(
          h,
          <SetGroupManagerHistoryContent {...h.content} />
        )
      );
      return;
    }

    const [demandGroup, supplyGroup] = getDemandAndSupplyGroup(
      relatedGroupIds,
      groupsMap,
      groupTypes
    );

    if (
      includes(tags, PLANNING_SUBMIT_TAG) ||
      includes(tags, FORECAST_SUBMIT_TAG)
    ) {
      // Only create submission record once
      if (!submissionRecordCreated) {
        submissionRecordCreated = true;
        const newSubmissionContent = (
          <PlanningSubmissionContent
            {...h.content}
            demandGroup={demandGroup}
            type={type}
          />
        );
        historyRecords.push(createPlanningRecord(h, newSubmissionContent));
      }
      return;
    }

    const newPlanningContent = (
      <PlanningHistoryContent
        {...h.content}
        demandGroup={demandGroup}
        supplyGroup={supplyGroup}
        allocationProjectName={getAllocationProjectName({
          h,
          allocationProjectsById,
        })}
      />
    );
    historyRecords.push(createPlanningRecord(h, newPlanningContent));
  });

  return historyRecords;
};
