import React, { Suspense, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { withRouter } from "react-router";
import { Box } from "orcs-design-system";
import { get, isEmpty, keyBy } from "lodash";

import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import { matchPaths } from "src/consts/urlPaths";
import ErrorPage from "src/components/ErrorPage";
import Loading from "src/components/LoadingPage";
import TeamNotFound from "src/components/TeamDetailPage/TeamNotFound";
import Heading from "src/components/TeamDetailPage/HeadingV2";
import DataExportModal from "src/dataExport/components/DataExportModal";
import { DataExportProvider } from "src/dataExport/context";
import ErrorNotification from "src/components/ErrorNotification";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";
import { isTeamPage } from "src/util/url";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { useGlobalSearchDefaultScopeContext } from "../../components/Search/GlobalSearchContext";
import TeamTabs from "./TeamTabsV2";
import {
  getTeamDetail as getTeamDetailQuery,
  getTeamCsvExport as getTeamCsvExportQuery,
  getMemberCountForTeams as getMemberCountForTeamsQuery,
} from "./team.graphql";
import Routes from "./Routes";

const TeamDetailPage = (props) => {
  const { setDefaultSearchScope } = useGlobalSearchDefaultScopeContext();
  const { match } = props;
  const {
    params: { id: teamId },
  } = match;

  const workspace = useWorkspace();
  const featureFlags = useFeatureFlags();

  const { data, loading, error, refetch } = useQuery(getTeamDetailQuery, {
    variables: {
      teamId,
    },
    onCompleted: (response) => {
      const teamData = get(response, "team");
      if (
        (!teamData.parentIds.length && workspace.rootGroups.length > 1) ||
        teamData.parentIds.length
      ) {
        // Disable global scoped search till me make it an "opt in" option.
        // setDefaultSearchScope(teamData);
        setDefaultSearchScope(null);
      } else {
        setDefaultSearchScope(null);
      }

      eventEmitter.emit(EVENTS.CURRENT_GROUP_UPDATED, teamData);
    },
    errorPolicy: "all",
    skip: !teamId,
  });

  const { data: teamMemberCountData } = useQuery(getMemberCountForTeamsQuery, {
    variables: {
      teamIds: [teamId],
    },
    fetchPolicy: "cache-and-network",
    skip: !teamId,
  });

  useEffect(() => {
    return () => {
      const matchedPath = matchPaths();
      if (!isTeamPage(matchedPath?.path)) {
        eventEmitter.emit(EVENTS.CURRENT_GROUP_UPDATED, null);
      }
    };
  }, []);

  const memberCountMap = useMemo(() => {
    return keyBy(get(teamMemberCountData, "teams", []), "id");
  }, [teamMemberCountData]);

  const team = useMemo(() => {
    return {
      ...get(data, "team"),
      ...get(memberCountMap, teamId, {}),
    };
  }, [data, memberCountMap, teamId]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (!loading && isEmpty(team)) {
    return <TeamNotFound />;
  }

  return (
    <DataExportProvider>
      <DataExportModal
        query={getTeamCsvExportQuery}
        variables={{ groupId: teamId }}
        prefixFilename={`membership-download-${teamId}`}
        renderError={({ error: dataExportError, message }) => (
          <ErrorNotification error={dataExportError} message={message} />
        )}
      />
      <Box className="guided-tour-team-details-welcome">
        <Heading
          team={team}
          featureFlags={featureFlags}
          refetch={refetch}
          loading={loading}
        />
        <TeamTabs team={team} />{" "}
      </Box>
      <Box
        borderTopLeftRadius="0"
        borderTopRightRadius="0"
        borderBottomLeftRadius={2}
        borderBottomRightRadius={2}
        boxBorder="default"
        p="r"
        bg="white"
        height="100%"
      >
        <Suspense fallback={<Loading />}>
          <Routes
            loading={loading}
            teamId={teamId}
            team={team}
            refetch={refetch}
          />
        </Suspense>
      </Box>
    </DataExportProvider>
  );
};

TeamDetailPage.propTypes = {
  match: PropTypes.object,
};

export default withRouter(TeamDetailPage);
