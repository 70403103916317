import { get, set, findIndex, union, unionWith, isEqual } from "lodash";
import { TAGS, ALLOCATION_THREAD_ID } from "src/consts/comments";
import {
  getComments as getCommentsQuery,
  getCommentCounts as getCommentCountsQuery,
  getAllocationProjectsWithCommentCounts as getAllocationProjectsWithCommentCountsQuery,
} from "src/comments/comments.graphql";

export const parseState = (state) => {
  const { commentTargetGroup, commentRoleGroup, commentAllocationProjectId } =
    state;

  const commentTargetGroupId =
    get(commentTargetGroup, "id") || get(commentTargetGroup, "groupId");
  const commentRoleGroupId =
    get(commentRoleGroup, "id") || get(commentRoleGroup, "groupId");

  return {
    commentAllocationProjectId,
    commentTargetGroupId,
    commentRoleGroupId,
  };
};

export const getCommentQueryVariablesFromState = (state, queryTags) => {
  const {
    commentAllocationProjectId,
    commentTargetGroupId,
    commentRoleGroupId,
  } = parseState(state);

  const { filters } = queryTags;

  const merged = unionWith(
    [
      [TAGS.ALLOCATION_PROJECT_ID(commentAllocationProjectId)],
      [TAGS.GROUP_ID(commentRoleGroupId)],
      [TAGS.GROUP_ID(commentTargetGroupId)],
    ],
    filters,
    isEqual
  );

  return { filters: merged };
};

export const getAllocationProjectQueryVariablesFromState = (
  state,
  queryTags,
  showHistoricAllocationProjects,
  showActiveAllocationProjects,
  showForecastAllocationProjects
) => {
  const { commentTargetGroupId, commentRoleGroupId } = parseState(state);

  const { filters } = queryTags;
  const merged = unionWith(
    [
      [TAGS.USER_COMMENT],
      [TAGS.GROUP_ID(commentRoleGroupId)],
      [TAGS.GROUP_ID(commentTargetGroupId)],
    ],
    filters,
    isEqual
  );
  return {
    filters: merged,
    includeHistoricAllocationProjects: showHistoricAllocationProjects,
    includeActiveAllocationProjects: showActiveAllocationProjects,
    includeForecastAllocationProjects: showForecastAllocationProjects,
  };
};

export const getSaveTags = (
  targetAllocationProjectId,
  state,
  saveTags = []
) => {
  const { commentTargetGroupId, commentRoleGroupId } = parseState(state);
  return union(saveTags, [
    TAGS.GROUP_ID(commentRoleGroupId),
    TAGS.GROUP_ID(commentTargetGroupId),
    TAGS.ALLOCATION_PROJECT_ID(targetAllocationProjectId),
  ]);
};

export const getThreadId = (targetAllocationProjectId, state) => {
  const { commentTargetGroupId, commentRoleGroupId } = parseState(state);

  const threadId = ALLOCATION_THREAD_ID(
    targetAllocationProjectId,
    commentRoleGroupId,
    commentTargetGroupId
  );

  return threadId;
};

const getAllocationProjectIdFromThreadId = (threadId) => {
  const regex =
    /^allocation-(?<allocationProjectId>[a-zA-Z\d-]{21})-[a-zA-Z\d-]{8}-[a-zA-Z\d-]{8}$/gm;
  const result = regex.exec(threadId);
  const [, allocationProjectId] = [].concat(result);
  return allocationProjectId;
};

export const saveCommentMutationUpdater = (proxy, variables, comment) => {
  try {
    const { result } = proxy.readQuery({
      query: getCommentsQuery,
      variables,
    });

    const proxyData = { result: { ...result } };
    const currentComments = get(proxyData, "result.comments", []);
    const updatedProxyData = set(proxyData, "result.comments", [
      ...currentComments,
      comment,
    ]);

    proxy.writeQuery({
      query: getCommentsQuery,
      variables,
      data: updatedProxyData,
    });
  } catch {
    // DO NOTHING
  }
};

export const commentCountUpdater = (
  proxy,
  variables,
  comment,
  forceUpdateCommentCounts
) => {
  const { threadId } = comment;
  try {
    const { counts } = proxy.readQuery({
      query: getCommentCountsQuery,
      variables,
    });

    const proxyData = { counts: [...(counts || [])] };
    const index = findIndex(
      proxyData.counts,
      (count) => count.threadId === threadId
    );
    if (index >= 0) {
      proxyData.counts[index] = {
        ...proxyData.counts[index],
        count: proxyData.counts[index].count + 1,
      };
    } else {
      proxyData.counts.push({
        threadId,
        count: 1,
        __typename: "CommentCount",
      });
    }
    proxy.writeQuery({
      query: getCommentCountsQuery,
      variables,
      data: proxyData,
    });

    forceUpdateCommentCounts();
  } catch {
    // DO NOTHING
  }
};

export const commentCountsByAllocationProjectUpdater = (
  proxy,
  variables,
  comment
) => {
  try {
    const { counts } = proxy.readQuery({
      query: getAllocationProjectsWithCommentCountsQuery,
      variables,
    });

    const proxyData = { counts: [...(counts || [])] };

    const updatedAllocationProjectId = getAllocationProjectIdFromThreadId(
      comment.threadId
    );

    const index = findIndex(proxyData.counts, [
      "allocationProject.id",
      updatedAllocationProjectId,
    ]);

    if (index >= 0) {
      proxyData.counts[index] = {
        ...proxyData.counts[index],
        count: proxyData.counts[index].count + 1,
      };
    }

    proxy.writeQuery({
      query: getAllocationProjectsWithCommentCountsQuery,
      variables,
      data: proxyData,
    });
  } catch {
    // DO NOTHING
  }
};
