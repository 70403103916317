/* eslint-disable no-param-reassign */
import { forEach, filter, map, last, size, sortBy, some } from "lodash";

const addTreeLeafNodes = (
  rootNode,
  childGroups,
  level,
  isDisplayHiddenTeams,
  activeNodeId
) => {
  const newGroupNodes = filter(childGroups, (g) => {
    if (last(g.parentIds) === rootNode.group.id) {
      if (isDisplayHiddenTeams || !g.isHidden) {
        return true;
      }
    }
    return false;
  });

  rootNode.children =
    size(newGroupNodes) > 0 &&
    sortBy(
      map(newGroupNodes, (g) => ({
        name: g.name,
        isParent: false,
        isRoot: false,
        toggled: level === 0,
        active: g.id === activeNodeId,
        group: g,
        children: null,
      })),
      // case insensitive search
      [(group) => group.name.toLowerCase()]
    );

  forEach(rootNode.children, (newRoot) => {
    const isActiveNode = addTreeLeafNodes(
      newRoot,
      childGroups,
      level + 1,
      isDisplayHiddenTeams,
      activeNodeId
    );
    if (isActiveNode) {
      newRoot.toggled = true;
      rootNode.toggled = true;
    }
  });

  return some(newGroupNodes, (g) => g.id === activeNodeId);
};

const createTreeStructure = (
  parentGroup,
  descendantGroups,
  isDisplayHiddenTeams,
  activeNodeId
) => {
  const rootNode = {
    name: parentGroup.name,
    active: parentGroup.id === activeNodeId,
    isParent: false,
    isRoot: true,
    toggled: true,
    group: parentGroup,
    children: null,
  };

  addTreeLeafNodes(
    rootNode,
    descendantGroups,
    1,
    isDisplayHiddenTeams,
    activeNodeId
  );

  return rootNode;
};

export default createTreeStructure;
