import React from "react";
import PropTypes from "prop-types";
import { Badge, FlexItem, Popover, StyledLink } from "orcs-design-system";

import { get, join, split, tail } from "lodash";
import { TEAM_TAB, url } from "src/consts/urlPaths";
import { useLookupData } from "../../../context/ForecastContext";

const getPopoverContent = ({ grouping, enableMoveGroupByTag, label }) => {
  if (enableMoveGroupByTag) {
    const { newParent, isMovedOut, isMovedIn } = grouping;
    const movedParent = isMovedOut
      ? newParent
      : get(grouping, "group.directParent", null);
    return movedParent ? (
      <>
        <span>
          {isMovedOut && "Moved to:"}
          {isMovedIn && "Moved from:"}
        </span>
        <StyledLink
          display="block"
          to={url(
            `/:workspaceId?/teams/${movedParent.id}/${TEAM_TAB.REQUEST_ALLOCATION_NUMBERS}`
          )}
          target="_blank"
        >
          <u>{movedParent.name}</u>
        </StyledLink>
      </>
    ) : null;
  }
  const { movedParent, isMovedIn, isMovedInternally } = grouping;
  const isNew = isMovedIn && !movedParent;
  const popoverTextOptions = {
    isMovedInternally: {
      isMovedIn: "Internal move from:",
      isMovedOut: "Internal move to:",
    },
    isMoved: {
      isMovedIn: "Moved from:",
      isMovedOut: "Moved to:",
    },
  };
  const popoverText = get(popoverTextOptions, [
    isMovedInternally ? "isMovedInternally" : "isMoved",
    isMovedIn ? "isMovedIn" : "isMovedOut",
  ]);

  return movedParent ? (
    <>
      <span>{popoverText}</span>
      <StyledLink
        display="block"
        to={url(
          `/:workspaceId?/teams/${movedParent.id}/${TEAM_TAB.REQUEST_ALLOCATION_NUMBERS}`
        )}
        target="_blank"
      >
        <u>{movedParent.name}</u>
      </StyledLink>
    </>
  ) : isNew ? (
    `Newly added ${label}`
  ) : null;
};

const getContent = ({ enableMoveGroupByTag, grouping }) => {
  if (enableMoveGroupByTag) {
    return "Proposed Move";
  }
  const { isMovedOut, isMovedIn, movedParent, isMovedInternally } = grouping;
  const isNew = isMovedIn && !movedParent;
  return isMovedOut
    ? "Moved out"
    : isMovedIn
    ? isNew
      ? "Added"
      : "Moved in"
    : isMovedInternally
    ? "Moved"
    : null;
};

const MovedGroupBadge = ({ grouping }) => {
  const { addTeamBtnLabel, enableMoveGroupByTag } = useLookupData();
  const label = addTeamBtnLabel
    ? join(tail(split(addTeamBtnLabel, " ")), " ")
    : "team";

  const handleLinkClick = (e) => {
    e.stopPropagation();
  };

  const popoverContent = getPopoverContent({
    enableMoveGroupByTag,
    label,
    grouping,
  });

  const badgeContent = getContent({
    enableMoveGroupByTag,
    grouping,
  });

  return (
    <FlexItem onClick={handleLinkClick} ml="s">
      <Popover
        direction="top"
        width="240px"
        textAlign="center"
        text={popoverContent}
      >
        <Badge variant="warning">{badgeContent}</Badge>
      </Popover>
    </FlexItem>
  );
};

MovedGroupBadge.propTypes = {
  grouping: PropTypes.object,
};

export default MovedGroupBadge;
