import React, { useState, useEffect, useId } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {
  StyledWrapper,
  StyledTextInput,
  StyledLabel,
} from "./InputBadge.styled";
import { StartDateOptions } from "./StartDateOptions";

export const InputBadge = ({
  disabled,
  label,
  onChange,
  startDate,
  endDate,
  value: currentValue,
  withDateOption,
  isAdmin,
  isPastEditEnabled,
  hasError,
  fteConfig,
}) => {
  const [value, setValue] = useState(currentValue);
  const [inputValue, setInputValue] = useState(currentValue);
  const [isUpdating, setIsUpdating] = useState(false);
  const inputId = useId();

  const isSameValue = value === currentValue;

  const disablePast =
    !isPastEditEnabled && endDate && endDate.isBefore(moment().startOf("day"));

  useEffect(() => {
    // Revert value when there is an error
    if (hasError) {
      setValue(currentValue);
      setInputValue(currentValue);
    }
  }, [currentValue, hasError]);

  const onFocus = () => {
    if (withDateOption) {
      setIsUpdating(true);
    }
  };

  const onBlur = () => {
    if (isSameValue) {
      setIsUpdating(false);
      return;
    }

    // When input blur and user needs to select a date, do not update the value
    if (withDateOption) {
      return;
    }

    onChange(value);
  };

  const onValueChange = ({ floatValue, value: userInput }) => {
    setValue(floatValue);
    setInputValue(userInput);
  };

  const onCancel = () => {
    setValue(currentValue);
    setInputValue(currentValue);

    setIsUpdating(false);
  };

  const onConfirm = (targetDate) => {
    setIsUpdating(false);

    if (isSameValue) {
      return;
    }

    onChange(value, targetDate);
  };

  const numberProps = {
    allowNegative: false,
    decimalScale: fteConfig.decimal,
    onFocus,
    onValueChange,
    onBlur,
  };

  return (
    <StyledWrapper>
      <StyledTextInput
        height="38px"
        id={`fte-input${inputId}`}
        value={inputValue}
        numberProps={numberProps}
        disabled={disabled || disablePast}
        invalid={hasError}
      />
      <StyledLabel htmlFor={`fte-input${inputId}`}>{label}</StyledLabel>
      {withDateOption && isUpdating && (
        <StartDateOptions
          startDate={startDate}
          endDate={endDate}
          onConfirm={onConfirm}
          onCancel={onCancel}
          disabled={isSameValue}
          isAdmin={isAdmin}
          isPastEditEnabled={isPastEditEnabled}
        />
      )}
    </StyledWrapper>
  );
};

InputBadge.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.number,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func,
  withDateOption: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isPastEditEnabled: PropTypes.bool,
  hasError: PropTypes.bool,
  fteConfig: PropTypes.object,
};
