import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";

import { ACTIONS } from "../../../context/ForecastContext";

const AddMemberButton = ({ lineItem, dispatch }) => {
  const handleAddMember = useCallback(
    (e) => {
      e.stopPropagation();

      dispatch({
        type: ACTIONS.SHOW_ADD_MEMBER_MODAL,
        grouping: lineItem.grouping,
        lineItem,
      });
    },
    [lineItem, dispatch]
  );

  return (
    <ActionsMenuItem onClick={handleAddMember}>Add member</ActionsMenuItem>
  );
};

AddMemberButton.propTypes = {
  lineItem: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default AddMemberButton;
