import React from "react";
import PropTypes from "prop-types";
import { Notification } from "orcs-design-system";

const ProgressNotification = ({ typeName }) => {
  return (
    <Notification loading closable={false}>
      {typeName} creation in progress.
    </Notification>
  );
};

ProgressNotification.propTypes = {
  typeName: PropTypes.string.isRequired,
};

export default ProgressNotification;
