import { isEmpty } from "lodash";
import { useCallback } from "react";

import useLocalStorage from "./useLocalStorage";

const getInitialLayoutFlags = () => ({ cardView: true });

export const useEditableLayoutFlags = () => {
  const [localStorageLayoutFlags, setLayoutFlags] = useLocalStorage(
    "td.layout-flags",
    {}
  );
  const layoutFlags = isEmpty(localStorageLayoutFlags)
    ? getInitialLayoutFlags()
    : localStorageLayoutFlags;

  const setLayoutFlag = useCallback(
    (flagName, state) => {
      setLayoutFlags((current) => {
        return { ...current, [flagName]: state };
      });
    },
    [setLayoutFlags]
  );

  return {
    layoutFlags,
    setLayoutFlag,
  };
};
export const useReadonlyLayoutFlags = () => {
  const [localStorageLayoutFlags] = useLocalStorage("td.layout-flags", {});
  const layoutFlags = isEmpty(localStorageLayoutFlags)
    ? getInitialLayoutFlags()
    : localStorageLayoutFlags;

  return {
    layoutFlags,
  };
};
