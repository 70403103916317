import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { H6, Small, Spacer, Flex } from "orcs-design-system";

import allocationLogoSrc from "src/media/icon__allocations.svg";

import { getPopoverTitle } from "./Utils";
import AllocationProjectContent from "./AllocationProjectContent";

const AllocationProjectLogo = styled.img`
  width: 20px;
  height: auto;
  margin-right: 5px;
`;

const PopOverContent = ({
  column,
  notification,
  isColumnReadonly,
  isConstraintsMode,
  rootGroup,
  showTotalCost,
}) => {
  const { allocationProject, columnType } = column;
  const { columnMessage } = notification;
  const title = getPopoverTitle(allocationProject, columnType);

  return (
    <Spacer my="s">
      <Flex
        alignItems="center"
        style={{ marginTop: allocationProject ? "10px" : undefined }}
      >
        <AllocationProjectLogo src={allocationLogoSrc} />
        <H6 weight="bold">{title}</H6>
      </Flex>

      {allocationProject && (
        <AllocationProjectContent
          allocationProject={allocationProject}
          isColumnReadonly={isColumnReadonly}
          isConstraintsMode={isConstraintsMode}
          rootGroup={rootGroup}
          showTotalCost={showTotalCost}
        />
      )}
      {!!columnMessage && <Small>* {columnMessage}</Small>}
    </Spacer>
  );
};

PopOverContent.propTypes = {
  column: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  isColumnReadonly: PropTypes.bool.isRequired,
  isConstraintsMode: PropTypes.bool.isRequired,
  rootGroup: PropTypes.object.isRequired,
  showTotalCost: PropTypes.bool,
};

export default PopOverContent;
