import { filter, reject, concat, get } from "lodash";
import {
  getIndividualAllocations,
  approverTeamBuilderMainQueryPart3,
} from "./allocation.graphql";

export const getIndividualAllocationsQueryUpdater =
  (variables) =>
  (proxy, { data: { individualAllocations } }) => {
    try {
      const proxyData = proxy.readQuery({
        query: getIndividualAllocations,
        variables,
      });

      const filteredAllocations = filter(
        individualAllocations,
        (allocation) => allocation.status !== "unallocated"
      );

      proxy.writeQuery({
        query: getIndividualAllocations,
        variables,
        data: {
          ...proxyData,
          individualAllocations: filteredAllocations,
        },
      });
    } catch {
      // DO NOTHING
    }
  };

export const getApproverIndividualAllocationDetailsQueryUpdater =
  (variables, refresh) =>
  (proxy, { data: { individualAllocations } }) => {
    try {
      const proxyData = proxy.readQuery({
        query: approverTeamBuilderMainQueryPart3,
        variables,
      });

      const { individualAllocations: existingIndividualAllocations } =
        proxyData;

      const personId = get(individualAllocations, "[0].personId");

      const updatedIndividualAllocations = concat(
        reject(existingIndividualAllocations, { personId }),
        individualAllocations
      );

      proxy.writeQuery({
        query: approverTeamBuilderMainQueryPart3,
        variables,
        data: {
          ...proxyData,
          individualAllocations: updatedIndividualAllocations,
        },
      });

      const cascadeUpdater = getIndividualAllocationsQueryUpdater({
        allocationProjectId: variables.allocationProjectId,
        personId,
      });
      cascadeUpdater(proxy, { data: { individualAllocations } });

      refresh();
    } catch {
      // DO NOTHING
    }
  };
