import { includes, filter, overEvery } from "lodash";

import {
  OVER_ALLOCATED,
  UNDER_ALLOCATED,
  NEWLY_ADDED,
  REMOVED,
} from "src/allocation/consts";

export default (members, selectedFilter, subGroupId) => {
  const filters = [];

  // remove any members that are newly added & removed (undo of an allocation)
  filters.push((member) => !(member.isNewlyAdded && member.isRemoved));

  if (subGroupId) {
    filters.push((member) => includes(member.directTeamIds, subGroupId));
  }

  if (selectedFilter === OVER_ALLOCATED) {
    filters.push((member) => member.remainingFte < 0);
  }

  if (selectedFilter === UNDER_ALLOCATED) {
    filters.push((member) => member.remainingFte > 0);
  }

  if (selectedFilter === NEWLY_ADDED) {
    filters.push((member) => member.isNewlyAdded);
  }

  if (selectedFilter === REMOVED) {
    filters.push((member) => member.isRemoved);
  } else {
    // by default don't show any removed members
    filters.push((member) => !member.isRemoved);
  }

  return filter(members, overEvery(filters));
};
