import React from "react";
import PropTypes from "prop-types";

import { FTE_SUM_TYPE, FTE_SUM_TARGET } from "src/consts/team";
import FteQueryBadge from "src/components/FteQueryBadge";

const FteQueryBadgeForSupply = ({
  isSupply,
  group,
  queryDirectWorkforce = false,
}) => {
  if (!isSupply) {
    return null;
  }

  return (
    <FteQueryBadge
      groupId={group.id}
      fteSumTarget={FTE_SUM_TARGET.ALL_MEMBERS}
      fteSumType={FTE_SUM_TYPE.CAPACITY_FTE}
      queryDirectWorkforce={queryDirectWorkforce}
      showToolTip
      showToolTipDirectWorkforceFteStats
      toolTipPosition="bottomRight"
    />
  );
};

FteQueryBadgeForSupply.propTypes = {
  isSupply: PropTypes.bool.isRequired,
  group: PropTypes.object.isRequired,
  queryDirectWorkforce: PropTypes.bool,
};

export default React.memo(FteQueryBadgeForSupply);
