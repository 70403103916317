/* eslint-disable no-param-reassign */

import { reduce, partition, forEach, reverse, find } from "lodash";

const parentCount = ({ parent, count = 0 }) => {
  if (parent.parent) {
    return parentCount({ parent: parent.parent, count: count + 1 });
  }
  return { parent, count };
};

const findParentNode = (previouslyInsertedNodes, parentIds) => {
  const reversedIds = reverse([...parentIds]); // take copy of parentIds as reverse mutates array.
  return reduce(
    reversedIds,
    (hit, id) => {
      if (hit) {
        return hit;
      }

      return find(previouslyInsertedNodes, (p) => id === p.group.id);
    },
    null
  );
};

export default (rootGroup, groups, selectedGroupId) => {
  const rootNode = {
    name: `${rootGroup.name} Summary`,
    active: rootGroup.id === selectedGroupId,
    group: rootGroup,
    toggled: true,
    isParent: true,
    isFunctionGroup: false,
    children: [],
    parent: null,
  };

  let groupsYetToInsert = [...groups];
  let groupsToInsert = [];
  let newlyInsertedNodes = [rootNode];
  for (let hierarchyLevel = 0; hierarchyLevel < 999; hierarchyLevel += 1) {
    const previouslyInsertedNodes = newlyInsertedNodes;
    newlyInsertedNodes = [];
    [groupsToInsert, groupsYetToInsert] = partition(
      groupsYetToInsert,
      (t) => t.parentIds.length === hierarchyLevel
    );

    // eslint-disable-next-line no-loop-func
    forEach(groupsToInsert, (group) => {
      const parentNode =
        findParentNode(previouslyInsertedNodes, group.parentIds) || rootNode;

      const node = {
        group,
        name: group.name,
        active: group.id === selectedGroupId,
        toggled:
          group.id === selectedGroupId ||
          parentCount({ parent: parentNode }).count < 2,
        isParent: false,
        isFunctionGroup: false,
        children: null,
        parent: parentNode,
      };

      if (!parentNode.children) {
        parentNode.children = [];
      }
      parentNode.children.push(node);
      parentNode.children.sort((a, b) =>
        a.group.name.localeCompare(b.group.name)
      );
      newlyInsertedNodes.push(node);
    });

    if (groupsYetToInsert.length === 0) {
      break;
    }
  }

  return rootNode;
};
