import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { get, filter, map, uniq } from "lodash";

import PropTypes from "prop-types";
import LinkObjectivesModal from "src/allocation/components/LinkObjectivesModal";
import {
  addAssociatedGroup,
  removeAssociatedGroup,
  setObjectiveVerifiedFor,
} from "src/allocation/team.graphql";
import { LINK_OBJECTIVE_DATASOURCE } from "src/consts/objectives";
import {
  useRootGroup,
  useUserInteraction,
  useActiveAllocationProject,
  useDispatch,
  ACTIONS,
} from "../../context/ForecastContext";
import { isObjectivesVerified } from "../util/objectives";

const LinkObjectivesModalContainer = ({
  datasource = LINK_OBJECTIVE_DATASOURCE.PLANNER,
}) => {
  const {
    linkingObjectives: { isOpen, targetGroup, grouping },
  } = useUserInteraction();

  const linkedStrategyIds = map(
    filter(grouping?.group?.linkedStrategies, { datasource }),
    "id"
  );
  const objectiveIds = [
    ...(grouping?.group?.objectiveIds || []),
    ...linkedStrategyIds,
  ];

  const { isLineOfBusiness, isDemand } = useRootGroup();
  const activeAllocationProject = useActiveAllocationProject();
  const dispatch = useDispatch();

  const groupId = get(targetGroup, "id");
  const allocationProjectId = get(activeAllocationProject, "id");

  const handleHideModal = useCallback(() => {
    dispatch({
      type: ACTIONS.CLOSE_LINK_OBJECTIVES_DIALOG,
    });
  }, [dispatch]);

  const [addObjectiveToGroup] = useMutation(addAssociatedGroup);
  const [removeObjectiveFromGroup] = useMutation(removeAssociatedGroup);
  const [setObjectivesStatus, { loading: isVerifying }] = useMutation(
    setObjectiveVerifiedFor
  );

  if (!isLineOfBusiness && !isDemand) {
    return null;
  }

  const onLinkObjective = async (targetId) => {
    const result = await addObjectiveToGroup({
      variables: {
        groupId,
        targetId,
        allocationProjectId,
      },
    });

    const linkedId = get(result, "data.group.id");
    if (!linkedId) {
      // This should never happen
      return;
    }

    // Need to get it again, as we want to keep the objectiveIds in the modal options
    const existingLinkedStrategies = get(targetGroup, "linkedStrategies") || [];

    dispatch({
      type: ACTIONS.LINKED_OBJECTIVES_UPDATE,
      groupId,
      objectiveIds: targetGroup?.objectiveIds || [],
      linkedStrategies: [
        ...existingLinkedStrategies,
        {
          id: linkedId,
          datasource,
        },
      ],
    });
  };

  const onRemoveObjective = async (targetId) => {
    const result = await removeObjectiveFromGroup({
      variables: {
        groupId,
        targetId,
        allocationProjectId,
      },
    });

    const removedId = get(result, "data.group.id");

    if (!removedId) {
      // This should never happen
      return;
    }

    // Need to get it again, as we want to keep the objectiveIds in the modal options
    const ids = get(targetGroup, "objectiveIds") || [];
    const existingLinkedStrategies = get(targetGroup, "linkedStrategies") || [];

    dispatch({
      type: ACTIONS.LINKED_OBJECTIVES_UPDATE,
      groupId,
      objectiveIds: filter(ids, (id) => id !== removedId),
      linkedStrategies: filter(
        existingLinkedStrategies,
        (strategy) => strategy.id !== removedId
      ),
    });
  };

  const verifyLinkedObjectives = async () => {
    try {
      await setObjectivesStatus({
        variables: {
          groupId,
          allocationProjectId,
        },
      });
      // Update the target
      dispatch({
        type: ACTIONS.VERIFY_LINKED_OBJECTIVES,
        groupId,
      });
    } catch (e) {
      // do nothing here
    }
  };

  return (
    <LinkObjectivesModal
      rootGroup={grouping?.root}
      targetGroup={targetGroup}
      objectiveIds={uniq(objectiveIds)}
      visible={isOpen}
      onHideModal={handleHideModal}
      onLinkObjective={onLinkObjective}
      onRemoveObjective={onRemoveObjective}
      isVerifyMode={!isObjectivesVerified(grouping)}
      isVerifying={isVerifying}
      verifyLinkedObjectives={verifyLinkedObjectives}
    />
  );
};

LinkObjectivesModalContainer.propTypes = {
  datasource: PropTypes.string,
};

export default LinkObjectivesModalContainer;
