import { useContext, useMemo } from "react";
import MentionContext from "./MentionContext";

const useMentionMenu = () => {
  const { target, index, search, tags, loading } = useContext(MentionContext);
  const value = useMemo(
    () => ({ target, index, search, tags, loading }),
    [target, index, search, tags, loading]
  );
  return value;
};

export default useMentionMenu;
