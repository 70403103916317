import React, { useReducer } from "react";
import { PropTypes } from "prop-types";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import { useWorkspaceTagConfig } from "src/contexts/global/WorkspaceContext";
import useOrgChartDataLoader from "../hooks/useOrgChartDataLoader";
import useUpdateChildTeamsCacheOnAddition from "../hooks/useUpdateChildTeamsCacheOnAddition";
import OrgChartContext from "./OrgChartContext";
import { orgChartReducer, getInitialOrgChartState } from "./reducer";

const OrgChartContextProvider = ({ team, groupTypes, children }) => {
  const tagConfig = useWorkspaceTagConfig();
  const [state, dispatch] = useReducer(
    orgChartReducer,
    getInitialOrgChartState({ tagConfig })
  );
  const { data, loading, refetch } = useOrgChartDataLoader({
    team,
    dispatch,
  });

  useUpdateChildTeamsCacheOnAddition(team);

  return (
    <OrgChartContext.Provider
      value={{
        loading,
        groupTypes,
        state,
        dispatch,
        data,
        team,
        refetch,
      }}
    >
      {children}
    </OrgChartContext.Provider>
  );
};

OrgChartContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  team: PropTypes.oneOfType([GroupPropType]),
  groupTypes: GroupTypesPropType,
};

export default OrgChartContextProvider;
