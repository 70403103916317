import React from "react";
import PropTypes from "prop-types";
import { Popover, Badge } from "orcs-design-system";
import moment from "moment";
import { isEmpty } from "lodash";

const GroupDeletedBadge = ({ removedAt }) => {
  const tooltip = !isEmpty(removedAt)
    ? `This team was removed ${moment(removedAt).fromNow()}`
    : "This team was removed";
  return (
    <Popover text={tooltip} direction="bottom" textAlign="center">
      <Badge display="block">REMOVED</Badge>
    </Popover>
  );
};

GroupDeletedBadge.propTypes = {
  removedAt: PropTypes.string.isRequired,
};

export default GroupDeletedBadge;
