import React, { useState } from "react";
import { filter, get, includes } from "lodash";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";

import GroupPropType from "src/custom-prop-types/group";
import {
  getPrincipleStatus as getPrincipleStatusQuery,
  selectPrincipleSet as selectPrincipleSetMutation,
  listPrincipleSets as listPrincipleSetsQuery,
} from "src/pages/TeamDetailPage/team.graphql";
import Loading from "../../components/LoadingPage";
import Principles from "../../components/TeamDetailPage/Principles";

const PrinciplesTabPage = ({ history, team, refetchTeamDetail }) => {
  const { data: { principleSets } = {}, loading } = useQuery(
    listPrincipleSetsQuery
  );
  const { data } = useQuery(getPrincipleStatusQuery, {
    variables: { teamId: team?.id },
    skip: !team?.id,
  });
  const principles = get(data, "team.principles", []);

  const [selectPrincipleSet] = useMutation(selectPrincipleSetMutation);
  const [mutating, setMutating] = useState(false);

  const setPrincipleSetId = async (teamId, principleSetId) => {
    setMutating(true);
    await selectPrincipleSet({
      variables: {
        teamId,
        principleSetId,
      },
    });
    await refetchTeamDetail();
    setMutating(false);
  };

  const principleOptions = filter(principleSets, (principle) =>
    includes(principle.suitableGroupTypes, get(team, "type"))
  );

  return loading ? (
    <Loading />
  ) : (
    <Principles
      mutating={mutating}
      history={history}
      team={team}
      principleSets={principleOptions}
      setPrincipleSetId={setPrincipleSetId}
      principles={principles}
    />
  );
};

PrinciplesTabPage.propTypes = {
  history: PropTypes.object.isRequired,
  team: GroupPropType,
  refetchTeamDetail: PropTypes.func.isRequired,
};

export default PrinciplesTabPage;
