export const DATE_OPTIONS = {
  TODAY: "today",
  START_DAY: "startDay",
  CHOSEN_DAY: "chosenDay",
};

export const getOutsideRange =
  ({ startDate, endDate, today, isAdmin, isPastEditEnabled }) =>
  (day) => {
    // If edit past enabled, can not change before startDate
    // If not, check today
    let targetStartDate = today;
    if (isAdmin || isPastEditEnabled) {
      targetStartDate = startDate;
    }

    return day.isBefore(targetStartDate) || day.isAfter(endDate);
  };

export const getNextStartDate = ({ option, today, targetDate, startDate }) => {
  let nextStartDate = null;

  // If startDate selected, only need to update membership fte, no need to pass it back
  // If today or a chosen date selected, pass it back.
  if (option === DATE_OPTIONS.TODAY) {
    nextStartDate = today;
  } else if (option === DATE_OPTIONS.CHOSEN_DAY) {
    nextStartDate = targetDate.startOf("day");
  }

  // If nextStartDate is the same as startDate, set to null (do nothing)
  if (startDate.isSame(nextStartDate, "day")) {
    nextStartDate = null;
  }

  return nextStartDate;
};
