import React from "react";
import { Box } from "orcs-design-system";
import PropTypes from "prop-types";

import GroupDescription from "src/allocation/components/GroupDescription";
import Heading from "../Heading";

const ChapterDescriptionPanel = ({ group, copywriting }) => {
  return (
    <Box height="40%">
      <Heading sectionHeading={copywriting.CHAPTER_DESCRIPTION_PANEL} />
      <Box height="80%" data-testid="chapter-description">
        <GroupDescription group={group} />
      </Box>
    </Box>
  );
};

ChapterDescriptionPanel.propTypes = {
  group: PropTypes.object,
  copywriting: PropTypes.object,
};

export default ChapterDescriptionPanel;
