import { useState } from "react";
import { isEqual, isNil } from "lodash";
import { isContentEmpty } from "src/comments/components/RichTextEditor/serializer/slatePlainTextSerializer";

const useSkillModalState = ({
  skills,
  value,
  defaultedValue,
  skillsInputRef,
}) => {
  const [fteValue, setFteValue] = useState(value);
  const [isSaving, setIsSaving] = useState(false);
  const [isFormSubmittable, setIsFormSubmittable] = useState(false);

  const updateFormStatus = (currentSkills, currentFteValue) => {
    // Form is valid when skills changed, fte value changed and skills are not empty
    const isFormValid =
      (!isEqual(skills, currentSkills) || currentFteValue !== value) &&
      !isContentEmpty(currentSkills);

    setIsFormSubmittable(isFormValid);
  };

  const handleSave = async (saveRequest, isPlannerPage, onHideModal) => {
    if (isSaving || !isFormSubmittable) {
      return;
    }

    try {
      setIsSaving(true);
      const inputSkills = skillsInputRef.current.getValue();
      let adjustedValue = fteValue;

      // For Planner page, never save null or undefined to back end
      if (isPlannerPage) {
        adjustedValue = isNil(fteValue) ? defaultedValue : fteValue;
      }

      await saveRequest(adjustedValue, inputSkills);
    } finally {
      setIsSaving(false);
      setIsFormSubmittable(false);
      onHideModal();
    }
  };

  const onFteValueChange = ({ floatValue = null }) => {
    setFteValue(floatValue);
    updateFormStatus(skillsInputRef.current.getValue(), floatValue);
  };

  const onFteInputBlur = () => {
    if (isNil(fteValue)) {
      setFteValue(defaultedValue);
    }
  };

  return {
    fteValue,
    isSaving,
    isFormSubmittable,
    updateFormStatus,
    handleSave,
    onFteValueChange,
    onFteInputBlur,
  };
};

export default useSkillModalState;
