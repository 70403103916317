import React from "react";
import PropTypes from "prop-types";
import { find, startsWith, split, last, isString } from "lodash";
import { P, StyledLink } from "orcs-design-system";
import { TAGS } from "src/consts/comments";
import { personDetailsPath } from "../../util/routing";
import AuthorTitle from "../AuthorTitle";
import GenericHistoryContent from "./GenericHistoryContent";

const PERSON_ID_PREFIX = TAGS.PERSON_ID("");

const PersonHistoryContent = ({ content, authorTitle, tags }) => {
  const personIdTag = find(tags, (t) => startsWith(t, PERSON_ID_PREFIX));

  if (!personIdTag || !isString(content) || content[0] !== "{") {
    return (
      <GenericHistoryContent content={content} authorTitle={authorTitle} />
    );
  }

  const aggregateId = last(split(personIdTag, ":"));
  const dividerIndex = content.indexOf("}");

  if (dividerIndex === -1 || !aggregateId) {
    return (
      <GenericHistoryContent content={content} authorTitle={authorTitle} />
    );
  }

  const personName = content.substring(1, dividerIndex);
  const otherContents = content.substring(dividerIndex + 1);

  return (
    <>
      <P>
        <StyledLink to={personDetailsPath({ aggregateId })}>
          {personName}
        </StyledLink>
        {otherContents}
      </P>
      <AuthorTitle action="Changed" authorTitle={authorTitle} />
    </>
  );
};

PersonHistoryContent.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  authorTitle: PropTypes.string,
  tags: PropTypes.array,
};

export default PersonHistoryContent;
