import moment from "moment";
import { get, isObject } from "lodash";

// Helper - assumes local storage value is a JSON object

const ls = window.localStorage;

export const removeLocalStorageItem = (storageKey) => {
  if (ls) {
    ls.removeItem(storageKey);
  }
};

export const getLocalStorageItem = (storageKey) => {
  if (ls && ls.getItem(storageKey)) {
    try {
      return JSON.parse(ls.getItem(storageKey));
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};

export const addLocalStorageItem = (storageKey, value) => {
  if (ls) {
    ls.setItem(storageKey, JSON.stringify(value));
  }
};

export const updateLocalStorageItem = (storageKey, value) => {
  if (ls) {
    const currentValue = getLocalStorageItem(storageKey);
    if (isObject(currentValue) && isObject(value)) {
      const updatedValue = {
        ...currentValue,
        ...value,
      };
      addLocalStorageItem(storageKey, updatedValue);
    } else {
      addLocalStorageItem(storageKey, value);
    }
  }
};

// Key PreFixes

const PIT_PREFIX = "pm.date-settings";
const FILTER_PREFIX = "pm.filter-settings";
const FTE_PREFIX = "pm.fte-settings";
const GUIDED_TOUR_PREFIX = "pm.guided-tour-settings";
const AUTH0_PREFIX = "pm.auth0-settings";

// Date settings
// TODO: include workspaceId in the date settings storage key
// Currently couldn't easily do that as workspace is not always available during the initialisation.
const localStorageDateSettingsKey = (tenantId, workspaceId, username) =>
  `${PIT_PREFIX}-${tenantId}-${workspaceId}-${username}`;

export const getDateSettingsFromLocalStorage = (
  tenantId,
  workspaceId,
  username
) => {
  const storageKey = localStorageDateSettingsKey(
    tenantId,
    workspaceId,
    username
  );
  const dateSettings = getLocalStorageItem(storageKey);
  if (dateSettings) {
    const fromDate = dateSettings.fromDate && moment(dateSettings.fromDate);
    const toDate = dateSettings.toDate && moment(dateSettings.toDate);
    return { ...dateSettings, fromDate, toDate };
  }
  return {};
};

export const saveDateSettingsToLocalStorage = (
  tenantId,
  workspaceId,
  username,
  value
) => {
  const storageKey = localStorageDateSettingsKey(
    tenantId,
    workspaceId,
    username
  );
  addLocalStorageItem(storageKey, value);
};

export const deleteDateSettingsFromLocalStorage = (
  tenantId,
  workspaceId,
  username
) => {
  const storageKey = localStorageDateSettingsKey(
    tenantId,
    workspaceId,
    username
  );
  removeLocalStorageItem(storageKey);
};

// Removes all date settings for all tenants, workspaces and users...
// TODO: consider removing this and always target a specific tenant, workspace and user.
export const deleteAllDateSettingsFromLocalStorage = () => {
  // TODO: Fix please
  // eslint-disable-next-line no-restricted-syntax
  for (const key in window.localStorage) {
    if (key.startsWith(PIT_PREFIX)) {
      window.localStorage.removeItem(key);
    }
  }
};

// Filter

const localStorageFiltersKey = (tenantId, workspaceId, username) =>
  `${FILTER_PREFIX}-${tenantId}-${workspaceId}-${username}`;

export const getFiltersFromLocalStorage = (tenantId, workspaceId, username) => {
  const storageKey = localStorageFiltersKey(tenantId, workspaceId, username);
  return getLocalStorageItem(storageKey);
};

export const saveFiltersToLocalStorage = (
  tenantId,
  workspaceId,
  username,
  value
) => {
  const storageKey = localStorageFiltersKey(tenantId, workspaceId, username);
  addLocalStorageItem(storageKey, value);
};

export const deleteFiltersFromLocalStorage = (
  tenantId,
  workspaceId,
  username
) => {
  const storageKey = localStorageFiltersKey(tenantId, workspaceId, username);
  removeLocalStorageItem(storageKey);
};

// FTE

const localStorageFTEKey = (tenantId, workspaceId, username) =>
  `${FTE_PREFIX}-${tenantId}-${workspaceId}-${username}`;

export const getFTEFromLocalStorage = (tenantId, workspaceId, username) => {
  const storageKey = localStorageFTEKey(tenantId, workspaceId, username);
  return getLocalStorageItem(storageKey);
};

export const saveFTEToLocalStorage = (
  tenantId,
  workspaceId,
  username,
  value
) => {
  const storageKey = localStorageFTEKey(tenantId, workspaceId, username);
  addLocalStorageItem(storageKey, value);
};

export const deleteFTEFromLocalStorage = (tenantId, workspaceId, username) => {
  const storageKey = localStorageFTEKey(tenantId, workspaceId, username);
  removeLocalStorageItem(storageKey);
};

// Guided tour
// TODO: Refactor to make consistent with the rest

const localStorageTourKey = (username, tourName) =>
  `${GUIDED_TOUR_PREFIX}-${tourName}-${username}`;

export const saveGuidedTourToLocalStorage = (username, tourName, tourState) => {
  if (window.localStorage) {
    const storageKey = localStorageTourKey(username, tourName);
    // delete recursive, non-important part of tourState that does not stringify
    // eslint-disable-next-line
    delete tourState.step;
    window.localStorage.setItem(storageKey, JSON.stringify(tourState));
  }
};

export const getGuidedTourFromLocalStorage = (username, tourName) => {
  if (window.localStorage) {
    const storageKey = localStorageTourKey(username, tourName);
    try {
      return JSON.parse(window.localStorage.getItem(storageKey));
    } catch {
      return undefined;
    }
  }
  return undefined;
};

export const deleteGuidedTourFromLocalStorage = (username, tourName) => {
  if (window.localStorage) {
    const storageKey = localStorageTourKey(username, tourName);
    window.localStorage.removeItem(storageKey);
  }
  return undefined;
};

// Auth0

const localStorageAuth0UserKey = (userEmail) => `${AUTH0_PREFIX}-${userEmail}`;

export const saveActiveTenant = (userEmail, tenant) => {
  const auth0UserStorageKey = localStorageAuth0UserKey(userEmail);
  updateLocalStorageItem(auth0UserStorageKey, { activeTenant: tenant });
};

export const getActiveTenant = (userEmail) => {
  const auth0UserStorageKey = localStorageAuth0UserKey(userEmail);
  const localStorageItem = getLocalStorageItem(auth0UserStorageKey);
  return get(localStorageItem, "activeTenant");
};

export const saveActiveTheme = (themeName) => {
  addLocalStorageItem("theme", themeName);
};

export const getActiveTheme = () => {
  return getLocalStorageItem("theme");
};

export const writeToLocalStorage = (localStorageKey, valueKey, value) => {
  if (ls) {
    const localStorageItem = ls.getItem(localStorageKey);
    const localStorageValue = localStorageItem
      ? JSON.parse(localStorageItem)
      : {};
    const storedValue = JSON.stringify({
      ...localStorageValue,
      [valueKey]: value,
    });
    ls.setItem(localStorageKey, storedValue);
  }
};

export const saveLocalSortOption = (component, value) => {
  writeToLocalStorage("sort", component, value);
};

export const getLocalSortOption = (component) => {
  const localStorageItem = getLocalStorageItem("sort");
  return get(localStorageItem, component);
};
