/* istanbul ignore file */
import { get } from "lodash";
import { Grid, Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { ModalProvider } from "src/contexts/withModal";
import PersonPropType from "src/custom-prop-types/person";
import useUpdateTeamDetails from "src/hooks/useUpdateTeamDetails";
import TeamDetailsPanel from "src/pages/TeamPage/sub-components/TeamDetailsPanel";
import TeamExplorePanel from "src/pages/TeamPage/sub-components/TeamExplorePanel";
import TeamMembersPanel from "src/pages/TeamPage/sub-components/TeamMembersPanel";
import { canAddNewTeam } from "src/util/allocations";
import { isGroupActive } from "src/util/group";
import useTagsVisibleInFilter from "src/app/Restricted/useTagsVisibleInFilter";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import OrgChart from "../OrgChart";

export const TeamsPanel = ({
  loading,
  teamId,
  team,
  groupTypes,
  user,
  userPerson,
  workspace,
  allocationProject,
  renderTeamFteBadge,
  renderMemberFteBadge,
  renderPrincipleStatus,
  refetch,
}) => {
  const tagsVisibleIn = useTagsVisibleInFilter();
  const {
    handleUpdateTeamDetails,
    canUpdateGroupAttributes,
    editDetailsMode,
    setEditDetailsMode,
  } = useUpdateTeamDetails({ team, refetch });

  // handle shifting of components
  const directMemberCount = get(team, "directMemberCount");

  const gridColumns =
    directMemberCount === 0 ? "1fr" : "repeat(auto-fit,minmax(520px,1fr))";

  const showAddNewTeam =
    canAddNewTeam(user, groupTypes, team) && isGroupActive(team);

  const { enableTeamDetailsOrgChart } = useWorkspaceFeatureFlags();

  return (
    <Box dataTestId="team-info-shared-component">
      <TeamDetailsPanel
        key="team-details-panel"
        loading={loading}
        team={team}
        groupTypes={groupTypes}
        user={user}
        handleUpdateTeamDetails={handleUpdateTeamDetails}
        canUpdateGroupAttributes={canUpdateGroupAttributes}
        editDetailsMode={editDetailsMode}
        setEditDetailsMode={setEditDetailsMode}
        workspace={workspace}
        tagsVisibleIn={tagsVisibleIn}
      />
      {enableTeamDetailsOrgChart ? (
        <OrgChart team={team} groupTypes={groupTypes} />
      ) : (
        <Grid gridGap="r" gridTemplateColumns={["1fr", "1fr", gridColumns]}>
          <TeamMembersPanel
            key="team-members-panel"
            teamId={teamId}
            team={team}
            workspace={workspace}
            allocationProject={allocationProject}
            user={user}
            userPerson={userPerson}
            renderFteBadge={renderMemberFteBadge}
          />
          <TeamExplorePanel
            key="team-explore"
            teamId={teamId}
            team={team}
            groupTypes={groupTypes}
            showAddNewTeam={showAddNewTeam}
            renderFteBadge={renderTeamFteBadge}
            renderPrincipleStatus={renderPrincipleStatus}
            workspace={workspace}
          />
        </Grid>
      )}
    </Box>
  );
};

TeamsPanel.propTypes = {
  loading: PropTypes.bool,
  teamId: PropTypes.string,
  team: PropTypes.object,
  groupTypes: PropTypes.object,
  user: PersonPropType,
  workspace: PropTypes.object,
  allocationProject: PropTypes.func,
  userPerson: PersonPropType,
  refetch: PropTypes.func,
  renderTeamFteBadge: PropTypes.func,
  renderMemberFteBadge: PropTypes.func,
  renderPrincipleStatus: PropTypes.func,
};

const TeamInfo = (props) => {
  return (
    <ModalProvider>
      <TeamsPanel {...props} />
    </ModalProvider>
  );
};

export default TeamInfo;
