import { forEach, map } from "lodash";
import moment from "moment";
import { READABLE_DATE_FORMAT } from "src/consts/global";
import { toDateRanges } from "src/util/memberships";
import { toFloat } from "./membership";

export const ZERO_FTE_WARNING = "Zero FTE is not allowed";
export const FIELD_FTE = "fte";
export const FIELD_DATES = "dates";

export const FUTURE_DATE = moment().add(20, "years").endOf("day");

export class ValidationError extends Error {
  constructor(message, data) {
    super(message);
    this.name = "ValidationError";
    this.data = data;
  }
}

const getAllValidDates = (
  targetStartDate,
  targetEndDate,
  demandMemberships
) => {
  const allDates = [targetStartDate, targetEndDate];

  // Add dates between targetStartDate and targetEndDate
  forEach(demandMemberships, (m) => {
    // targetStartDate is before the startDate
    if (targetStartDate.isBefore(m.startDate, "day")) {
      allDates.push(m.startDate);
    }

    // endDate is null or after the targetEndDate
    if (
      (!m.endDate && targetEndDate === FUTURE_DATE) ||
      targetEndDate.isAfter(m.endDate, "day")
    ) {
      allDates.push(m.endDate || FUTURE_DATE);
    }
  });

  return allDates;
};

const endDateToString = (endDate) => {
  if (!endDate) {
    return "ongoing";
  }

  return endDate.format(READABLE_DATE_FORMAT);
};

const getUpdatedFieldMessage = (updatedMembership, existingMembership) => {
  const { currentCompositeId, fte, startDate, endDate } = updatedMembership;

  if (!currentCompositeId) {
    return {
      message: `Adding ${fte} FTE from ${startDate.format(
        READABLE_DATE_FORMAT
      )} to ${endDateToString(endDate)}`,
      fieldName: null,
    };
  }

  if (!existingMembership) {
    return {
      message: "",
      fieldName: null,
    };
  }

  const {
    fte: existingFte,
    startDate: existingStartDate,
    endDate: existingEndDate,
  } = existingMembership;

  if (fte !== existingFte) {
    return {
      message: `Updating FTE to ${fte}`,
      fieldName: FIELD_FTE,
    };
  }

  if (startDate && !startDate.isSame(existingStartDate, "day")) {
    return {
      message: `Updating start date to ${startDate.format(
        READABLE_DATE_FORMAT
      )}`,
      fieldName: FIELD_DATES,
    };
  }

  if (
    (!endDate && existingEndDate) ||
    !endDate.isSame(existingEndDate, "day")
  ) {
    return {
      message: `Updating end date to ${endDateToString(endDate)}`,
      fieldName: FIELD_DATES,
    };
  }

  return {
    message: "",
    fieldName: null,
  };
};

export const buildMessage = (
  overAllocated,
  updatedMembership,
  existingMembership
) => {
  const { message: updatedFieldMsg, fieldName } = getUpdatedFieldMessage(
    updatedMembership,
    existingMembership
  );

  const dates = map(overAllocated, ({ start, end }) => {
    return `${start.format(READABLE_DATE_FORMAT)} - ${
      end === FUTURE_DATE ? "Ongoing" : end.format(READABLE_DATE_FORMAT)
    }`;
  });

  return {
    message: `${updatedFieldMsg} exceeds the person's capacity on the following dates: ${dates.join(
      ", "
    )}`,
    fieldName,
  };
};

const getUpdatedMemberships = (updatedMembership, demandMemberships) => {
  const { currentCompositeId } = updatedMembership;

  // Check whether updatedMembership has currentCompositeId or not,
  // if it does not have one, then it's a new membership, need to add it into the array
  if (!currentCompositeId) {
    return [...demandMemberships, updatedMembership];
  }

  // Replace membership with updatedMembership
  return map(demandMemberships, (m) => {
    if (m.currentCompositeId === currentCompositeId) {
      return updatedMembership;
    }

    return m;
  });
};

export const validateFteCap = ({
  person,
  updatedMembership,
  demandMemberships,
  fteConfig = {},
}) => {
  const { startDate, endDate } = updatedMembership;
  const targetStartDate = startDate;
  const targetEndDate = endDate || FUTURE_DATE;

  const allDates = getAllValidDates(
    targetStartDate,
    targetEndDate,
    demandMemberships
  );
  const dateRanges = toDateRanges(allDates);

  const targetMemberships = getUpdatedMemberships(
    updatedMembership,
    demandMemberships
  );
  const overAllocated = [];

  forEach(dateRanges, (dateRange) => {
    let totalFte = 0;

    forEach(targetMemberships, (m) => {
      // Case 1: Start date must be same or before the date range start
      // end date must be:
      // - null (ongoing)
      // - same or after the date range end
      // Case 2: Start date and end date are the same as date range start
      const isInRange =
        (m.startDate.isSameOrBefore(dateRange.start, "day") &&
          (!m.endDate || m.endDate.isSameOrAfter(dateRange.end, "day"))) ||
        (dateRange.start.isSame(m.startDate, "day") &&
          dateRange.start.isSame(m.endDate, "day"));

      if (isInRange) {
        // Add updated fte or existing fte
        totalFte += m.fte;
      }
    });

    const allocatedFte = toFloat(totalFte, fteConfig.decimal);

    if (allocatedFte > person.fte) {
      overAllocated.push({ ...dateRange, fte: allocatedFte });
    }
  });

  return overAllocated;
};

export const isReducingMembership = (updatedMembership, currentMembership) => {
  if (!currentMembership) {
    return false;
  }

  // Fte is reduced
  if (updatedMembership.fte < currentMembership.fte) {
    return true;
  }

  const { startDate: existingStartDate, endDate: existingEndDate } =
    currentMembership;
  const { startDate, endDate } = updatedMembership;

  // Compare date spans
  if (!existingEndDate) {
    if (endDate) {
      // If existing membership is ongoing, new membership has end date
      return true;
    }

    if (startDate.isAfter(existingStartDate, "day")) {
      // If end date still ongoing, check start date is after existing
      return true;
    }
  } else if (endDate) {
    const existingSpan = existingEndDate.diff(existingStartDate, "days");
    const updatedSpan = endDate.diff(startDate, "days");

    // If updated membership is shorter than existing membership
    if (updatedSpan < existingSpan) {
      return true;
    }
  }

  return false;
};

export const validateMembership = ({
  person,
  updatedMembership,
  demandMemberships,
  existingMembership,
  existingFte,
  isZeroFteAllowed,
  enableFteCap,
  fteConfig,
}) => {
  if (existingFte !== 0 && !isZeroFteAllowed && updatedMembership.fte === 0) {
    throw new ValidationError(ZERO_FTE_WARNING);
  }

  if (enableFteCap) {
    // Always allow user to reduce FTE even it's over allocated
    // this is mainly for already over allocated case
    if (
      updatedMembership.fte < existingFte ||
      isReducingMembership(updatedMembership, existingMembership)
    ) {
      return;
    }

    const overAllocated = validateFteCap({
      person,
      updatedMembership,
      demandMemberships,
      fteConfig,
    });

    if (overAllocated.length > 0) {
      const { message, fieldName } = buildMessage(
        overAllocated,
        updatedMembership,
        existingMembership
      );
      throw new ValidationError(message, { fieldName });
    }
  }
};

export const getErrorMessage = ({
  apiError,
  isOverAllocated,
  overAllocatedPeriods,
  membership,
  currentMembership,
}) => {
  if (apiError) {
    return apiError.message;
  }

  if (isOverAllocated) {
    const { message } = buildMessage(
      overAllocatedPeriods,
      membership,
      currentMembership
    );

    return message;
  }

  return null;
};
