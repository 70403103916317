import { Flex, Toggle } from "orcs-design-system";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import React, { memo, useCallback, useMemo } from "react";
import { ACTION_TYPES } from "src/components/OrgChart/context/actionTypes";
import { useOrgChartContext } from "src/components/OrgChart/context/OrgChartContext";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";

const AnimatedToggleWrapper = styled.div`
  transition: all 0.4s ease-in-out;
  opacity: 1;
  max-height: 24px; /* Adjust based on your Toggle height */
  margin-bottom: ${({ hideBottomMargin, theme }) =>
    hideBottomMargin ? "0" : themeGet("space.r")({ theme })};

  &.hidden {
    display: none;
    max-height: 0;
    margin: 0;
    margin-bottom: 0;
  }
`;

const ViewOptions = () => {
  const { state, dispatch, team, data } = useOrgChartContext();
  const groupTypes = useGroupTypes();
  const groupType = groupTypes?.[team?.type];

  const toggleViewOptions = useCallback(
    (key) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_VIEW_OPTIONS,
        payload: {
          ...state.viewOptions,
          [key]: !state.viewOptions[key],
        },
      });
    },
    [dispatch, state.viewOptions]
  );

  const toggles = useMemo(() => {
    const type = groupType?.isSupply ? "demand" : "supply";

    const getHandlerAndValue = (key) => ({
      onChange: () => toggleViewOptions(key),
      isChecked: state?.viewOptions?.[key],
    });

    return [
      {
        id: "org-chart-view-options-showPeople",
        label: "Show people",
        isVisible: true,
        ...getHandlerAndValue("showPeople"),
      },
      {
        id: "org-chart-view-options-groupMembersBySupplyOrDemand",
        label: `Group members by ${type}`,
        isVisible:
          data?.team?.members?.length &&
          groupType &&
          state?.viewOptions?.showPeople,
        ...getHandlerAndValue("groupMembersBySupplyOrDemand"),
      },
    ];
  }, [
    groupType,
    state?.viewOptions,
    toggleViewOptions,
    data?.team?.members?.length,
  ]);

  return (
    <Flex flexDirection="column">
      {toggles.map((toggle, index) => {
        const nextToggle = toggles?.[index + 1];
        return (
          <AnimatedToggleWrapper
            key={toggle.id}
            className={!toggle.isVisible ? "hidden" : ""}
            hideBottomMargin={toggle.isVisible && !nextToggle?.isVisible}
          >
            <Toggle
              id={toggle.id}
              label={toggle.label}
              checked={toggle.isChecked}
              onChange={toggle.onChange}
            />
          </AnimatedToggleWrapper>
        );
      })}
    </Flex>
  );
};

export default memo(ViewOptions);
