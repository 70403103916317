import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { ActionsMenu, ActionsMenuItem, Button, Icon } from "orcs-design-system";

const MembershipActionsMenu = ({
  membership,
  handleOnEndAllocation,
  handleOnDestroy,
  isSupply,
  enableChangeSupplyGroup,
  isPastEditEnabled,
}) => {
  const { startDate, endDate } = membership;

  if (isSupply && !enableChangeSupplyGroup) {
    return null;
  }

  const menuItems = [];

  if (!endDate && !isSupply) {
    menuItems.push(
      <ActionsMenuItem key="endAllocationBtn" onClick={handleOnEndAllocation}>
        End allocation
      </ActionsMenuItem>
    );
  }

  // Enable the delete button for current and future allocations
  // or if past edit is enabled
  const isPastStartDate = startDate.isBefore(moment(), "day");
  if (!isPastStartDate || (isPastEditEnabled && isPastStartDate)) {
    menuItems.push(
      <ActionsMenuItem key="deleteMembershipBtn" onClick={handleOnDestroy}>
        Delete
      </ActionsMenuItem>
    );
  }

  const customMenuButton = (
    <Button
      variant="dangerAlternate"
      iconOnly
      type="button"
      width="38px"
      height="38px"
      disabled={!menuItems.length}
    >
      <Icon icon={["fas", "times"]} />
    </Button>
  );

  return (
    <ActionsMenu
      direction="left-start"
      ml="auto"
      customTriggerComponent={customMenuButton}
    >
      {menuItems}
    </ActionsMenu>
  );
};

MembershipActionsMenu.propTypes = {
  membership: PropTypes.object,
  handleOnEndAllocation: PropTypes.func,
  handleOnDestroy: PropTypes.func,
  isSupply: PropTypes.bool,
  enableChangeSupplyGroup: PropTypes.bool,
  isPastEditEnabled: PropTypes.bool,
};

export default MembershipActionsMenu;
