import React, { useState, useCallback, useMemo, Suspense } from "react";
import { Modal, Box, Loading, Spacer, Flex, Button } from "orcs-design-system";
import { get, find, concat, isNil, some } from "lodash";
import moment from "moment";
import { useApolloClient, useMutation } from "@apollo/client";
import { nanoid } from "nanoid";
import AllocationPersonSearch from "src/components/AllocationPersonSearch";
import {
  useWorkspace,
  useWorkspaceFeatureFlags,
} from "src/contexts/global/WorkspaceContext";
import { allocateIndividualAllocation } from "src/allocation/allocation.graphql";
import { getTeam as getTeamQuery } from "src/allocation/team.graphql";
import { addMembershipOnly as addMembershipGql } from "src/queries/memberships.graphql";
import { fullDisplayName } from "src/util/personName";
import ErrorNotification from "src/components/ErrorNotification";
import { getIndividualAllocationsQueryUpdater } from "src/allocation/updateIndividualAllocationCache";
import { copywriting } from "src/pages/PersonDetailPage/PersonDetailPage.config";
import { getRemainingFte } from "src/util/fteUtil";

import {
  useUserInteraction,
  useDispatch,
  useGroupTypesLookup,
  useActiveAllocationProject,
  ACTIONS,
} from "../../../context/ForecastContext";
import PersonAllocation from "./PersonAllocation";
import ModalHeader from "./ModalHeader";
import { getSearchFilter, refetchLatestMembers } from "./AddMemberModal.logic";

const AddMemberModal = () => {
  const client = useApolloClient();
  const { enableFutureAllocationsUI } = useWorkspaceFeatureFlags();
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [supplySourceGroup, setSupplySourceGroup] = useState(null);
  const workspace = useWorkspace();
  const { id: allocationProjectId } = useActiveAllocationProject() || {};
  const groupTypes = useGroupTypesLookup();

  const [allocatingPerson, setAllocatingPerson] = useState(false);
  const [membersAdded, setMembersAdded] = useState([]);
  const [error, setError] = useState(null);

  const {
    addMember: {
      isOpen,
      grouping,
      lineItem,
      targetFte,
      beforeAllocate,
      dirtyAllocations,
    },
  } = useUserInteraction();

  const dispatch = useDispatch();

  const handleHideModal = () => {
    setSelectedPerson(null);
    setSupplySourceGroup(null);
    setAllocatingPerson(false);
    setMembersAdded([]);
    setError(null);
    dispatch({
      type: ACTIONS.CLOSE_ADD_MEMBER_MODAL,
    });
    dispatch({
      type: ACTIONS.MAIN_QUERY_REFRESH,
      backgroundRefresh: true,
    });
  };

  const teamId = get(grouping, "group.id");

  const updateAllocateToNewGroupCache = useCallback(
    (cache, result, queryVariables) => {
      getIndividualAllocationsQueryUpdater(cache, result, queryVariables);
    },
    []
  );

  const mutationEndpoint = enableFutureAllocationsUI
    ? addMembershipGql
    : allocateIndividualAllocation;

  const [allocateToNewGroup] = useMutation(mutationEndpoint);

  const searchFilter = useMemo(() => {
    return getSearchFilter(lineItem, groupTypes);
  }, [lineItem, groupTypes]);

  const loadSourceGroup = useCallback(
    async (sourceGroupId) => {
      let sourceGroup;

      if (lineItem) {
        sourceGroup = lineItem.group;
      } else {
        const { data: sourceGroupData } = await client.query({
          query: getTeamQuery,
          variables: { teamId: sourceGroupId },
          skip: !sourceGroupId,
        });

        sourceGroup = get(sourceGroupData, "result", null);
      }
      setSupplySourceGroup(sourceGroup);

      return sourceGroup;
    },
    [client, lineItem]
  );

  const onPersonSelected = useCallback(
    async (optionValue) => {
      const person = get(optionValue, "person");
      if (!person) {
        return;
      }

      setError(null);
      setSupplySourceGroup(null);
      const sourceGroupId = (person.sourceGroupIds || [])[0];

      try {
        if (!sourceGroupId) {
          throw new Error(
            `${fullDisplayName(
              person
            )} is in a structure that does not participate in planner and can not be added`
          );
        }

        setAllocatingPerson(true);

        if (beforeAllocate) {
          await beforeAllocate(person);
        }

        const sourceGroup = await loadSourceGroup(sourceGroupId);

        const isPersonAlreadyAllocated = !!find(
          person.allocations,
          ({ targetGroupId }) => targetGroupId === teamId
        );

        const personId = person.aggregateId;
        const requestedFte = isNil(targetFte)
          ? getRemainingFte({
              allocations: person.allocations,
              totalFte: person.fte,
            })
          : targetFte;

        if (!isPersonAlreadyAllocated) {
          if (enableFutureAllocationsUI) {
            const startDate = moment().format("YYYY-MM-DD");
            await allocateToNewGroup({
              variables: {
                input: {
                  personId,
                  groupId: teamId,
                  startDate,
                  endDate: null,
                  fte: requestedFte,
                },
                withMemberships: false,
              },
            });
          } else {
            await allocateToNewGroup({
              variables: {
                allocationId: nanoid(),
                allocationProjectId,
                targetGroupId: teamId,
                personId: person.aggregateId,
                requestedFte,
              },
              update: (cache, result) => {
                updateAllocateToNewGroupCache(cache, result, {
                  personId,
                  allocationProjectId,
                });
              },
            });
          }
        }

        setMembersAdded(concat(membersAdded, [person]));

        // Refetch latest members for the lineItem
        setTimeout(() => {
          refetchLatestMembers({
            client,
            groupTypes,
            targetGroupId: teamId,
            supplyGroup: sourceGroup,
          });
        }, 200);

        setSelectedPerson(person);
        setAllocatingPerson(false);
      } catch (e) {
        setError(e);
      }
    },
    [
      beforeAllocate,
      loadSourceGroup,
      targetFte,
      membersAdded,
      teamId,
      enableFutureAllocationsUI,
      allocateToNewGroup,
      allocationProjectId,
      updateAllocateToNewGroupCache,
      client,
      groupTypes,
    ]
  );

  const buttonVariant = useMemo(() => {
    if (allocatingPerson) {
      return "disabled";
    }

    if (!some(dirtyAllocations, Boolean)) {
      return "success";
    }

    return "disabled";
  }, [allocatingPerson, dirtyAllocations]);

  return (
    <Modal
      data-testid="add-member-modal"
      visible={isOpen}
      height="80vh"
      width="80vw"
      maxHeight="90vh"
      maxWidth="90vw"
      onClose={handleHideModal}
      headerContent={<ModalHeader grouping={grouping} lineItem={lineItem} />}
      footerContent={
        <Box width="100%">
          <Flex alignItems="center" justifyContent="flex-end" width="100%">
            <Button onClick={handleHideModal} mr="r" variant={buttonVariant}>
              Done
            </Button>
          </Flex>
        </Box>
      }
    >
      <Box mt="s">
        <Suspense fallback={<Loading small ml="r" />}>
          <AllocationPersonSearch
            targetGroupId={teamId}
            autoSearch={!!lineItem}
            filter={searchFilter}
            onPersonSelected={onPersonSelected}
            allocatedOptionLabel={copywriting.ALLOCATED}
            allocateOptionLabel={copywriting.ALLOCATE}
          />
        </Suspense>
        {allocatingPerson && (
          <Spacer my="l">
            <Loading centered large />
          </Spacer>
        )}
        {!allocatingPerson && selectedPerson && (
          <PersonAllocation
            person={selectedPerson}
            groupTypes={groupTypes}
            allocationProjectId={allocationProjectId}
            workspace={workspace}
            supplySourceGroup={supplySourceGroup}
          />
        )}
        {error && (
          <Spacer my="l">
            <ErrorNotification
              message={error.message}
              closable={false}
              report={false}
            />
          </Spacer>
        )}
      </Box>
    </Modal>
  );
};

export default AddMemberModal;
