import { useUserPerson } from "src/contexts/global/GlobalContext";
import { getUserTimezone } from "src/util/getLocalTime";

export const useShouldDisplayLocalTime = ({ showLocalTime, member }) => {
  const userPerson = useUserPerson();
  if (!showLocalTime) {
    return false;
  }
  const userTimezone = getUserTimezone(userPerson);
  const memberTimezone = getUserTimezone(member);

  return userTimezone !== memberTimezone;
};
