import { isNumber, parseInt } from "lodash";

export const numberToLocaleString = (
  value,
  { decimalplaces = 2, defaultValue = null } = {}
) => {
  const lang = navigator.language || "en-AU";

  return isNumber(value)
    ? value.toLocaleString(lang, { maximumFractionDigits: decimalplaces })
    : defaultValue;
};

export const getBreakPointAsNumber = (breakpoint, defaultWidth) => {
  return parseInt(breakpoint) || defaultWidth;
};
