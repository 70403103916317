import React from "react";
import styled from "styled-components";
import { Box, Flex, Spacer } from "orcs-design-system";
import themeGet from "@styled-system/theme-get";
import ProjectNotification from "../Notifications";

import useGridConfig from "../util/gridConfig";

import {
  VIEW_MODES,
  PAGE_MODES,
  usePageMode,
  useMainQuery,
  useViewMode,
  useRootGroup,
  useLookupData,
} from "../../context/ForecastContext";

import CustomGrid from "../Shared/CustomGrid";
import ColumnHeaders from "./ColumnHeaders";
import PlannerSubmitButton from "./PlannerSubmitButton";
import ForecastSubmitButton from "./ForecastSubmitButton";
import ViewMode from "./ViewMode";
import ActionMenu from "./ActionMenu";
import BudgetHeader from "./BudgetHeader";
import SearchSuggestionBox from "./SearchSuggestionBox";
import MembersFilter from "./MembersFilter";

const ActionBarLayout = styled(Box)`
  position: sticky;
  top: -${themeGet("space.r")};
  margin-top: -${themeGet("space.r")};
  z-index: 11;
  margin-bottom: ${themeGet("space.s")};
  border-bottom: 1px solid ${themeGet("colors.greyDark")};
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px 0px;
`;

const getPlannerConfig = (gridConfig, isLoB) => {
  return isLoB
    ? [gridConfig.lineOfBusiness.templateColumns, gridConfig.lineOfBusiness.gap]
    : [gridConfig.planner.templateColumns.actionBar, gridConfig.planner.gap];
};

const getForecastConfig = (gridConfig, isConstraints) => {
  return isConstraints
    ? [gridConfig.constraint.templateColumns, gridConfig.constraint.gap]
    : [gridConfig.forecast.templateColumns, gridConfig.forecast.gap];
};

const ActionBar = () => {
  const pageMode = usePageMode();
  const { loading, backgroundRefresh } = useMainQuery();
  const viewMode = useViewMode();
  const { isLineOfBusiness } = useRootGroup();
  const gridConfig = useGridConfig();
  const {
    enableForecastSubmitting,
    enableSearchView,
    enableForecastSearchView,
    enableMembersFilter,
  } = useLookupData();

  if (isLineOfBusiness) {
    return null;
  }

  const isConstraints = viewMode === VIEW_MODES.GROUPED_BY_CONSTRAINTS;

  const [templateColumns, templateGap] =
    pageMode === PAGE_MODES.PLANNER
      ? getPlannerConfig(gridConfig, isLineOfBusiness)
      : getForecastConfig(gridConfig, isConstraints);

  const showLoading = loading && !backgroundRefresh;
  const isPlanner = pageMode === PAGE_MODES.PLANNER;
  const isForecast = pageMode === PAGE_MODES.FORECAST;
  const showHeaders = !showLoading;
  const showSearchBox =
    (enableSearchView && isPlanner) ||
    (enableForecastSearchView && isForecast && !isConstraints);

  return (
    <ActionBarLayout
      width="100%"
      bg="white"
      p="10px 0"
      className="guided-tour-allocation-forecast-action-bar"
    >
      <CustomGrid gridTemplateColumns={templateColumns} gridGap={templateGap}>
        <Flex justifyContent="flex-start" alignItems="center" flexWrap="wrap">
          <Spacer mr="s">
            <ViewMode />
            {isPlanner && <PlannerSubmitButton />}
            {isForecast && enableForecastSubmitting && <ForecastSubmitButton />}
            {showSearchBox && <SearchSuggestionBox key={viewMode} />}
            {isPlanner && enableMembersFilter && <MembersFilter />}
          </Spacer>
          <ProjectNotification />
        </Flex>
        {showHeaders && <BudgetHeader />}
        {showHeaders && <ColumnHeaders viewMode={viewMode} />}
        {showHeaders && <ActionMenu />}
      </CustomGrid>
    </ActionBarLayout>
  );
};

export default ActionBar;
