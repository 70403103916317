import { size } from "lodash";

export const checkAdjustIndent = (
  isIndirectInSupplySide,
  isIndirectInSupplyMode
) => {
  if (isIndirectInSupplySide || isIndirectInSupplyMode) {
    return true;
  }

  return false;
};

export const isAddDivider = (
  index,
  lineItemsWithType,
  isIndirectInSupplyMode,
  canAddLineItem
) => {
  if (index === size(lineItemsWithType) - 1) {
    if (isIndirectInSupplyMode) {
      return true;
    }
    return !canAddLineItem;
  }
  return true;
};
