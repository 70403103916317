import React, { useEffect, useState, useMemo, useCallback } from "react";
import { trim, map, isEmpty, isString, values, reduce, filter } from "lodash";
import moment from "moment";
import { Box, Loading, Notification } from "orcs-design-system";
import styled from "styled-components";
import Fuse from "fuse.js";
import { useApolloClient } from "@apollo/client";
import SuggestionBox from "src/allocation/components/SuggestionBox";
import {
  useTagTypes,
  useWorkspaceFeatureFlags,
} from "src/contexts/global/WorkspaceContext";
import { searchGroupMembersQuery } from "src/allocation/allocation.graphql";
import { getPitPeopleByIds as getPitPeopleByIdsQuery } from "src/allocation/members.graphql";
import { TAG_SKILL } from "src/consts/tags";
import { pagedSearchTags } from "src/queries/tags.graphql";

import {
  useDispatch,
  useMainQuery,
  useSearchMode,
  useModelLookups,
  useViewMode,
  useActiveAllocationProject,
  ACTIONS,
  useGroupSearchMode,
  useLookupData,
  usePageMode,
  PAGE_MODES,
  VIEW_MODES,
} from "../../context/ForecastContext";
import * as util from "./SearchSuggestionBox.logic";

export const PAGED_TAGS_LIMIT = 5;

const BoxWrapper = styled(Box)`
  position: relative;
`;

const StyledLoading = styled(Loading)`
  position: absolute;
  right: -16px;
  z-index: 15;
  top: 12px;
`;

const SearchSuggestionBox = () => {
  const tagTypes = useTagTypes();
  const client = useApolloClient();
  const activeAllocationProject = useActiveAllocationProject();
  const { variables } = useMainQuery();
  const pageMode = usePageMode();
  const viewMode = useViewMode();
  const searchMode = useSearchMode();
  const groupSearchMode = useGroupSearchMode();
  const { groupingLookup, lineItemLookup } = useModelLookups();
  const dispatch = useDispatch();
  const [tagOptions, setTagOptions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const workspaceFeatureFlags = useWorkspaceFeatureFlags();
  const {
    supplyLabel,
    demandLabel,
    plannerSearchOptions,
    plannerSearchInvalidTags,
    plannerSearchLimit,
    forecastSearchOptions,
    forecastSearchInvalidTags,
    forecastSearchLimit,
  } = useLookupData();

  const isPlannerPage = pageMode === PAGE_MODES.PLANNER;
  const isDemandView = viewMode === VIEW_MODES.GROUPED_BY_DEMAND;
  const tagTypesForSearch = useMemo(
    () =>
      workspaceFeatureFlags?.doNotLoadSkillTagsInPlannerSearch
        ? filter(tagTypes?.person, (tagType) => tagType !== TAG_SKILL)
        : tagTypes?.person,
    [tagTypes?.person, workspaceFeatureFlags?.doNotLoadSkillTagsInPlannerSearch]
  );

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), 3000);
    }
  }, [error]);

  const groupNameFuse = useMemo(() => {
    return new Fuse(
      values(groupingLookup),
      util.createFuseSearchOptions(["group.name"])
    );
  }, [groupingLookup]);

  const lineItemNameFuse = useMemo(() => {
    return new Fuse(
      values(lineItemLookup),
      util.createFuseSearchOptions(["group.name"])
    );
  }, [lineItemLookup]);

  const pagedTags = useCallback(
    async (str) => {
      if (!str.match(/[0-9A-Za-z ',-]+/)) {
        return [];
      }

      const includeInactiveTags = isPlannerPage
        ? plannerSearchInvalidTags
        : forecastSearchInvalidTags;

      const result = await client.query({
        query: pagedSearchTags,
        variables: {
          input: {
            limit: PAGED_TAGS_LIMIT,
            filter: {
              searchTerm: str,
              tagTypes: tagTypesForSearch,
              includeInactiveTags,
            },
          },
        },
      });

      const searchResult = result?.data?.results?.tags || [];
      if (isEmpty(searchResult)) {
        return [];
      }

      const options = map(searchResult, (item) => {
        return {
          ...item,
          label: item.displayValue,
          value: item.id,
          type: item.tagTypeName || item.type,
        };
      });

      setTagOptions(options);
      return options;
    },
    [
      client,
      forecastSearchInvalidTags,
      isPlannerPage,
      plannerSearchInvalidTags,
      tagTypesForSearch,
    ]
  );

  const onSearchSuggestion = useCallback(
    async (str) => {
      const trimmedStr = trim(str);
      if (!trimmedStr || trimmedStr.length < util.MIN_SEARCH_LENGTH) {
        return [];
      }

      const configedOptions = isPlannerPage
        ? plannerSearchOptions
        : forecastSearchOptions;

      const optionsList = isEmpty(configedOptions)
        ? Object.keys(util.AllSearchOptions)
        : configedOptions;

      let shouldSearchTags = false;
      let hasSingleTagOptions = false;

      const options = reduce(
        optionsList,
        (prev, o) => {
          const optionFunc = util.AllSearchOptions[o];

          if (o === util.SINGLE_TAG) {
            // Search tags after the loop
            shouldSearchTags = true;
            hasSingleTagOptions = true;
            return prev;
          }

          if (o === util.TAGS_GENERIC_TYPE) {
            shouldSearchTags = true;
          }

          if (optionFunc) {
            const option = optionFunc(trimmedStr);

            // Replace label for group name type
            if (option.id === util.GROUP_NAME_TYPE) {
              util.replaceGroupNameOptionLabel(
                option,
                isDemandView ? demandLabel : supplyLabel
              );
            } else if (option.id === util.LINE_ITEM_NAME_TYPE) {
              util.replaceLineItemNameOptionLabel(
                option,
                isDemandView ? supplyLabel : demandLabel
              );
            }
            prev.push(option);
          }

          return prev;
        },
        []
      );

      if (shouldSearchTags) {
        const targetTags = await pagedTags(trimmedStr);

        if (hasSingleTagOptions) {
          options.push(...targetTags);
        }
      }

      return options;
    },
    [
      isPlannerPage,
      plannerSearchOptions,
      forecastSearchOptions,
      pagedTags,
      isDemandView,
      demandLabel,
      supplyLabel,
    ]
  );

  const createQueryParams = useCallback(
    (option) => {
      if (option.type === util.PERSON_NAME_TYPE) {
        return {
          searchTerms: [option.value],
        };
      }

      if (option.type === util.JOB_TITLE_TYPE) {
        return {
          jobTitle: option.value,
        };
      }

      // When searching for tags, include the baseline pit
      const pitDate = moment(activeAllocationProject.baselineDate).toJSON();

      if (option.type === util.WITH_TAGS_TYPE) {
        return {
          tagIds: map(tagOptions, "id"),
          pitDate,
        };
      }

      if (option.type === util.TAGS_GENERIC_TYPE) {
        if (isEmpty(tagOptions)) {
          setError(`No tags found by searching "${option.value}"`);
          return null;
        }

        return {
          tagIds: map(tagOptions, "id"),
          pitDate,
        };
      }

      // For all other types, try tagIds
      return {
        tagIds: [option.value],
        pitDate,
      };
    },
    [activeAllocationProject, tagOptions]
  );

  const dispatchSearchResult = useCallback(
    (members, searchError) => {
      dispatch({
        type: ACTIONS.SEARCH_COMPLETED,
        members,
        error: searchError,
        groupingLookup,
        lineItemLookup,
        searchMode: true,
        viewMode,
      });
    },
    [dispatch, groupingLookup, lineItemLookup, viewMode]
  );

  const dispatchGroupSearchResult = useCallback(
    (targetGroupings, isLineItemSearch) => {
      dispatch({
        type: ACTIONS.GROUP_SEARCH_COMPLETED,
        groupSearchMode: true,
        targetGroupings,
        isLineItemSearch,
      });
    },
    [dispatch]
  );

  const dispatchFinishSearch = useCallback(async () => {
    // Setting searchMode to false make take a big re-render if there is a lot of search results
    // so, setting it to loading state first, then clear state
    dispatch({
      type: ACTIONS.TOGGLE_PAGE_LOADING,
      loading: true,
    });

    setTimeout(() => {
      dispatch({
        type: ACTIONS.APPLY_DEFAULT_GROUPINGS_TREE_EXPANSION,
        toUpdate: {
          expandedMemberListIds: {},
          searchMode: false,
          groupSearchMode: false,
          searchResult: null,
        },
      });
    }, 100);
    setTimeout(() => {
      dispatch({
        type: ACTIONS.TOGGLE_PAGE_LOADING,
        loading: false,
      });
    }, 150);
  }, [dispatch]);

  const searchGroupName = useCallback(
    (option) => {
      const searchResult = groupNameFuse.search(option.value);
      const targetGroupings = map(searchResult, "item");

      dispatchGroupSearchResult(targetGroupings);
    },
    [dispatchGroupSearchResult, groupNameFuse]
  );

  const searchLineItemName = useCallback(
    (option) => {
      const searchResult = lineItemNameFuse.search(option.value);
      const targetGroupings = map(searchResult, "item");

      dispatchGroupSearchResult(targetGroupings, true);
    },
    [dispatchGroupSearchResult, lineItemNameFuse]
  );

  const onSearch = useCallback(
    async (option) => {
      if (!option || !option.value || option.isInput) {
        if (searchMode || groupSearchMode) {
          dispatchFinishSearch();
        }
        return;
      }

      // Use id for group name or line item name matching
      if (option.id === util.GROUP_NAME_TYPE) {
        searchGroupName(option);
        return;
      }

      if (option.id === util.LINE_ITEM_NAME_TYPE) {
        searchLineItemName(option);
        return;
      }

      const params = createQueryParams(option);
      if (!params) {
        return;
      }

      setIsSearching(true);
      try {
        const limit = isPlannerPage ? plannerSearchLimit : forecastSearchLimit;

        const { data } = await client.query({
          query: searchGroupMembersQuery,
          fetchPolicy: "network-only",
          variables: {
            ...variables,
            input: {
              groupId: variables.groupId,
              ...params,
              limit,
            },
          },
        });

        const { members, error: searchError } = data.result;

        let updatedMembers = members;
        // Load pit allocations
        if (!searchError) {
          const memberIds = map(members, "aggregateId");

          if (!isEmpty(memberIds)) {
            const { data: pitData } = await client.query({
              query: getPitPeopleByIdsQuery,
              fetchPolicy: "network-only",
              variables: {
                ids: memberIds,
                specifiedDate: moment(
                  activeAllocationProject.baselineDate
                ).toJSON(),
              },
            });

            updatedMembers = util.mergePeople(members, pitData.people);
          }
        }

        dispatchSearchResult(updatedMembers, searchError);
      } catch (e) {
        setError(e);
      }
      setIsSearching(false);
    },
    [
      createQueryParams,
      searchMode,
      groupSearchMode,
      dispatchFinishSearch,
      searchGroupName,
      searchLineItemName,
      isPlannerPage,
      plannerSearchLimit,
      forecastSearchLimit,
      client,
      variables,
      dispatchSearchResult,
      activeAllocationProject,
    ]
  );

  return (
    <BoxWrapper width="250px">
      <SuggestionBox
        placeholder="Type to search"
        value={null}
        loadSuggestionsPromise={onSearchSuggestion}
        onSearch={onSearch}
      />
      {isSearching && <StyledLoading inverted />}
      {error && (
        <Notification
          bottom="20px"
          colour="danger"
          centered
          floating
          closable={false}
        >
          {isString(error)
            ? error
            : "Sorry, an error occurred, please try searching again."}
        </Notification>
      )}
    </BoxWrapper>
  );
};

export default React.memo(SearchSuggestionBox);
