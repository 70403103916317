import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

import {
  COLUMN_TYPES,
  useLookupData,
  useRootGroup,
} from "../../../context/ForecastContext";

import CurrentCell from "../CurrentCell";
import RequestorCell from "../RequestorCell";
import ApproverCell from "../ApproverCell";
import RealTimeFTECell from "./RealTimeFTECell";

const LineItemCells = ({
  lineItem,
  isMembersExpanded,
  hideApproverColumn,
  dispatch,
  withRequestNotification,
}) => {
  const rootGroup = useRootGroup();
  const { enableMemberFteEdit } = useLookupData();

  return map(lineItem.cells, (cell) => {
    if (cell.columnType === COLUMN_TYPES.PLANNER_CURRENT) {
      return (
        <CurrentCell
          key={cell.id}
          cell={cell}
          isMembersExpanded={isMembersExpanded}
        />
      );
    }
    if (cell.columnType === COLUMN_TYPES.PLANNER_REQUESTED) {
      return enableMemberFteEdit && rootGroup.isDemand ? (
        <RealTimeFTECell key={cell.id} cell={cell} dispatch={dispatch} />
      ) : (
        <RequestorCell
          key={cell.id}
          cell={cell}
          dispatch={dispatch}
          withRequestNotification={withRequestNotification}
        />
      );
    }

    if (cell.columnType === COLUMN_TYPES.PLANNER_APPROVED) {
      return (
        <ApproverCell
          key={cell.id}
          cell={cell}
          dispatch={dispatch}
          hideApproverColumn={hideApproverColumn}
        />
      );
    }

    return null;
  });
};

LineItemCells.propTypes = {
  lineItem: PropTypes.object.isRequired,
  isMembersExpanded: PropTypes.bool,
  hideApproverColumn: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  withRequestNotification: PropTypes.bool,
};

export default LineItemCells;
