import React, { useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Button,
  Spacer,
  Icon,
  Modal,
  H3,
  P,
  Flex,
  Loading,
} from "orcs-design-system";

import ErrorNotification from "src/components/ErrorNotification";
import {
  getForecastCsvExport,
  getPlannerCsvExport,
} from "src/allocation/allocation.graphql";

import {
  useForecastContext,
  ACTIONS,
  PAGE_MODES,
} from "../../context/ForecastContext";

const ExportModal = () => {
  const [
    {
      pageMode,
      lookupData: { parentGroupId },
      userInteraction: { exportCsv },
    },
    dispatch,
  ] = useForecastContext();

  const isForecastPage = pageMode === PAGE_MODES.FORECAST;

  const csvExportQuery = isForecastPage
    ? getForecastCsvExport
    : getPlannerCsvExport;

  const [getCsvExport, { data, loading, error }] = useLazyQuery(
    csvExportQuery,
    {
      variables: { groupId: parentGroupId },
      fetchPolicy: "network-only",
    }
  );

  const handleCancel = useCallback(() => {
    dispatch({
      type: ACTIONS.CLOSE_EXPORT_DIALOG,
    });
    dispatch({
      type: ACTIONS.MAIN_QUERY_REFRESH,
      backgroundRefresh: true,
    });
  }, [dispatch]);

  const handleConfirm = useCallback(() => {
    dispatch({
      type: ACTIONS.EXPORT_COMPLETE,
      data,
      groupId: parentGroupId,
      fileName: isForecastPage ? "Forecast" : "Planner",
    });
  }, [data, dispatch, parentGroupId, isForecastPage]);

  if (exportCsv.isOpen && !exportCsv.isProcessing && !loading) {
    dispatch({
      type: ACTIONS.EXPORT_PROCESSING,
    });
    getCsvExport();
  }

  const modalFooter = (
    <Flex>
      <Spacer mr="s">
        <Button
          onClick={handleConfirm}
          variant="success"
          iconLeft
          disabled={loading || error}
        >
          <Icon icon={["fas", "cloud-download"]} />
          {loading ? "Processing..." : "Save"}
        </Button>
        <Button
          variant="ghost"
          onClick={handleCancel}
          iconLeft
          disabled={loading || error}
        >
          <Icon icon={["fas", "times"]} />
          Cancel
        </Button>
      </Spacer>
    </Flex>
  );

  return (
    <Modal
      visible={exportCsv.isOpen}
      width="420px"
      height="250px"
      maxHeight="90vh"
      maxWidth="90vw"
      onConfirm={handleConfirm}
      onClose={handleCancel}
      footerContent={modalFooter}
    >
      <Spacer mb="l">
        <Flex alignItems="center">
          <Icon icon={["fas", "download"]} size="1" mr="s" />
          <H3>Forecast CSV Export</H3>
        </Flex>
        {loading && (
          <Flex alignItems="center">
            <Loading />
            <P ml="s">Processing...</P>
          </Flex>
        )}
        {!loading && error && <ErrorNotification error={error} />}
        {!loading && !error && data && (
          <Flex alignItems="center">
            <Icon
              icon={["fas", "check-circle"]}
              size="1.5x"
              mr="s"
              color="success"
            />
            <P>Processing complete</P>
          </Flex>
        )}
      </Spacer>
    </Modal>
  );
};

export default ExportModal;
