import { useApolloClient } from "@apollo/client";
import {
  debounce,
  get,
  reduce,
  split,
  without,
  map,
  includes,
  find,
} from "lodash";
import { Button, Flex, Icon, Divider } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useMemo, useEffect, useCallback } from "react";

import icons from "src/config/icons";
import { listSupplyGroups } from "src/allocation/team.graphql";
import TeamDropdown from "src/components/TeamDropdown";
import { getSupplyGroupTypes } from "src/util/directory-customerConfig";
import ErrorNotification from "src/components/ErrorNotification";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";

const noOptionsMessage = ({ inputValue }) => {
  if (inputValue) {
    return "No search results";
  }

  return "Type to search";
};

const createSelectOptions = (groups, defaultOption) => {
  return reduce(
    groups,
    (prev, group) => {
      if (defaultOption.value !== group.id) {
        prev.push({
          label: group.name,
          value: group.id,
          hierarchy: group.hierarchy,
          type: group.type,
          group,
        });
      }
      return prev;
    },
    []
  );
};

const shouldDisableChangeBtn = (isLoading, selectedOption, defaultOption) => {
  if (isLoading) {
    return "disabled";
  }

  if (!selectedOption) {
    return defaultOption.value ? "success" : "disabled";
  }

  return selectedOption.value === defaultOption.value ? "disabled" : "success";
};

const SupplyGroupSearch = ({
  groups,
  groupTypes,
  isLoading,
  onAllocationChange,
}) => {
  const client = useApolloClient();

  const supplyGroupTypes = useMemo(() => {
    const types = getSupplyGroupTypes(groupTypes);
    return map(types, "id");
  }, [groupTypes]);

  const defaultOption = useMemo(() => {
    const group = find(groups, (g) => includes(supplyGroupTypes, g.type));

    if (!group) {
      return {};
    }

    return {
      label: group.name,
      value: group.id,
      hierarchy: group.hierarchy,
      type: group.type,
      group,
    };
  }, [groups, supplyGroupTypes]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [queryError, setQueryError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (defaultOption && defaultOption.value) {
      setSelectedOption(defaultOption);
    }
  }, [defaultOption]);

  const getQueryVars = useCallback(
    (keywordArray = "") => ({
      search: without(split(keywordArray, " "), "") || [keywordArray],
      typeFilters: supplyGroupTypes,
      limit: 100,
    }),
    [supplyGroupTypes]
  );

  const loadOptions = useMemo(
    () =>
      debounce((input, callback) => {
        setIsSearching(true);
        client
          .query({
            query: listSupplyGroups,
            variables: getQueryVars(input),
          })
          .then(({ data: newData, error: newError }) => {
            if (newError) {
              setQueryError(newError);
            }

            callback(
              newData
                ? createSelectOptions(
                    get(newData, "result.groups", []),
                    defaultOption
                  )
                : []
            );
            setIsSearching(false);
          });
      }, DEBOUNCE_SEARCH_TIME),
    [client, getQueryVars, defaultOption]
  );

  const onSelectGroup = (option) => {
    setSelectedOption(option);
  };

  const onAllocateToSupplyGroup = () => {
    const group = selectedOption ? selectedOption.group : null;
    onAllocationChange(group, defaultOption.group);
  };

  const buttonVariant = shouldDisableChangeBtn(
    isLoading,
    selectedOption,
    defaultOption
  );

  return (
    <>
      <Flex alignItems="flex-end" py="r" pr="s">
        <TeamDropdown
          id="team-select-dropdown"
          placeholder="Type to search"
          noOptionsMessage={noOptionsMessage}
          isSearchable={true}
          isLoading={isSearching || isLoading}
          options={true}
          loadOptions={loadOptions}
          onSelectTeam={onSelectGroup}
          value={selectedOption}
          height="54px"
          isClearable
        />
        <Button
          iconLeft
          variant={buttonVariant}
          large
          ml="r"
          height="54px"
          onClick={onAllocateToSupplyGroup}
          isLoading={isLoading}
          data-testid="cp-supply-change-btn"
        >
          <Icon icon={icons.pen} />
          Change
        </Button>
      </Flex>
      {queryError && (
        <ErrorNotification
          message="Sorry, an error occurred, please try searching again."
          error={queryError}
        />
      )}
      <Divider light />
    </>
  );
};

SupplyGroupSearch.propTypes = {
  groups: PropTypes.array,
  groupTypes: PropTypes.object,
  isLoading: PropTypes.bool,
  onAllocationChange: PropTypes.func,
};

export default React.memo(SupplyGroupSearch);
