import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Popover, Icon, StatusDot } from "orcs-design-system";
import RichTextContent from "src/components/RichTextContent";

const SkillsRequirementIcon = ({ skills, popoverDirection = "bottomLeft" }) => {
  const skillsNote = <RichTextContent content={skills[0]} fontSize="1.2rem" />;
  return (
    <Popover
      direction={popoverDirection}
      width="250px"
      textAlign="left"
      text={!isEmpty(skills) && skillsNote}
      data-testid="skills-reuirement-icon"
    >
      <StatusDot variant="purple" icon>
        <Icon size="xs" icon={["fas", "info"]} color="white" />
      </StatusDot>
    </Popover>
  );
};

SkillsRequirementIcon.propTypes = {
  skills: PropTypes.object.isRequired,
  popoverDirection: PropTypes.string,
};

export default SkillsRequirementIcon;
