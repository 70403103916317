import React from "react";
import { H3, Popover } from "orcs-design-system";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import {
  VIEW_MODES,
  useLookupData,
  useRootGroup,
  useViewMode,
  useFinancialForecasting,
  useActiveAllocationProject,
} from "../../context/ForecastContext";

import render from "../util/renderStringTemplate";
import useCurrencySymbol from "../Shared/hooks/useCurrencySymbol";
import BudgePopOverContent from "./BudgetPopOverContent";

const BudgetHeader = () => {
  const workspace = useWorkspace();
  const { useDeltaForBudget } = useLookupData();
  const allocationProject = useActiveAllocationProject();
  const {
    allocationName,
    forecastBudgetColumnName,
    forecastBudgetColumnTooltip,
    forecastBudgetDeltaColumnName,
    forecastBudgetDeltaColumnTooltip,
  } = allocationProject;

  const rootGroup = useRootGroup();
  const viewMode = useViewMode();
  const withBudget = useFinancialForecasting();
  const currencySymbol = useCurrencySymbol();

  if (
    rootGroup.isLineOfBusiness ||
    viewMode === VIEW_MODES.GROUPED_BY_CONSTRAINTS ||
    !withBudget
  ) {
    return null;
  }

  const templateData = {
    AllocationProjectName: allocationName,
  };

  const { config } = workspace;

  const templates = {
    forecastBudgetColumnName:
      forecastBudgetColumnName ||
      config.allocation?.financialYear ||
      "Forecast / Budget",
    forecastBudgetColumnTooltip:
      forecastBudgetColumnTooltip || "Forecast / Budget",
    forecastBudgetDeltaColumnName:
      forecastBudgetDeltaColumnName ||
      `In Plan ${currencySymbol} / Target ${currencySymbol}`,
    forecastBudgetDeltaColumnTooltip:
      forecastBudgetDeltaColumnTooltip ||
      `In Plan ${currencySymbol} / Target ${currencySymbol}`,
  };

  const popOverContent = (
    <BudgePopOverContent
      title={render(
        useDeltaForBudget
          ? templates.forecastBudgetDeltaColumnTooltip
          : templates.forecastBudgetColumnTooltip,
        templateData
      )}
      allocationProject={allocationProject}
      rootGroup={rootGroup}
    />
  );

  if (useDeltaForBudget) {
    return (
      <Popover
        direction="bottomLeft"
        width="208px"
        textAlign="left"
        text={popOverContent}
      >
        <H3 fontSize="1.4rem" weight="bold">
          {render(templates.forecastBudgetDeltaColumnName, templateData)}
        </H3>
      </Popover>
    );
  }

  return (
    <Popover
      direction="bottomLeft"
      width="208px"
      textAlign="left"
      text={popOverContent}
    >
      <H3 fontSize="1.4rem" weight="bold">
        {render(templates.forecastBudgetColumnName, templateData)}
      </H3>
    </Popover>
  );
};

export default BudgetHeader;
