import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Text, Flex, Box, Spacer } from "orcs-design-system";

import { numberToLocaleString } from "src/util/toLocaleString";

import SkillsDisplay from "../../Shared/SkillsDisplay";
import SkillsInput from "../../Shared/SkillsInput";
import NotificationDot from "../../Shared/NotificationDot";
import DeltaLabel from "../../Shared/DeltaLabel";
import Input from "./Input";

const RequestorCell = ({ cell, dispatch, withRequestNotification }) => {
  const {
    value,
    defaultedValue,
    delta,
    isEditable,
    notification,
    isRequirementInputEnabled,
  } = cell;
  const updateMethodRef = useRef(null);

  return useMemo(() => {
    if (!isEditable) {
      const submittedValue = numberToLocaleString(value, { defaultedValue });
      return (
        <>
          <Box
            width="100%"
            className="guided-tour-allocation-planner-requestor-cell-readonly"
          >
            <Flex alignItems="center" justifyContent="center" width="100%">
              <Spacer mx="xs">
                <Text ml="21px">{submittedValue}</Text>
                <DeltaLabel delta={delta} />
              </Spacer>
            </Flex>
            {isRequirementInputEnabled && cell.skills && (
              <Flex mt="s" justifyContent="center">
                <SkillsDisplay cell={cell} />
              </Flex>
            )}
          </Box>
          <Flex alignItems="center" justifyContent="flex-start" width="100%">
            {withRequestNotification && (
              <NotificationDot notification={notification} isCell />
            )}
          </Flex>
        </>
      );
    }
    return (
      <>
        <Box
          width="100%"
          className="guided-tour-allocation-planner-requestor-cell"
        >
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Input
              cell={cell}
              dispatch={dispatch}
              updateMethodRef={updateMethodRef}
            />
          </Flex>
          {isRequirementInputEnabled && (
            <Box mt="xs">
              <SkillsInput
                cell={cell}
                dispatch={dispatch}
                updateMethodRef={updateMethodRef}
              />
            </Box>
          )}
        </Box>
        <NotificationDot notification={notification} isCell />
      </>
    );
  }, [cell.hash]); // eslint-disable-line
};

RequestorCell.propTypes = {
  cell: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default RequestorCell;
