import { get, isEmpty, map } from "lodash";
import { Flex, P, Spacer, Badge } from "orcs-design-system";
import React from "react";

import PersonPropType from "src/custom-prop-types/person";

const Bands = ({ person }) => {
  const personBands = get(person, "bands", []);
  return !isEmpty(personBands) ? (
    <Flex flexWrap="wrap" alignItems="center">
      <Spacer m={1}>
        {map(personBands, (v, i) => (
          <Badge key={i} data-testId="band-badge">
            {v}
          </Badge>
        ))}
      </Spacer>
    </Flex>
  ) : (
    <P>No Band</P>
  );
};

Bands.propTypes = {
  person: PersonPropType,
};

export default Bands;
