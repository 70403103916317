import React from "react";
import {
  usePageMode,
  useActiveAllocationProject,
  useRootGroup,
  PAGE_MODES,
} from "../../context/ForecastContext";

import PlannerNotification from "./PlannerNotification";
import ForecastNotification from "./ForecastNotification";

const ProjectNotification = () => {
  const pageMode = usePageMode();
  const activeAllocationProject = useActiveAllocationProject();
  const rootGroup = useRootGroup();

  if (pageMode === PAGE_MODES.PLANNER) {
    return (
      <PlannerNotification
        activeAllocationProject={activeAllocationProject}
        rootGroup={rootGroup}
      />
    );
  }

  if (pageMode === PAGE_MODES.FORECAST) {
    return (
      <ForecastNotification
        activeAllocationProject={activeAllocationProject}
        rootGroup={rootGroup}
      />
    );
  }

  return null;
};

export default ProjectNotification;
