import { Button, Icon, Popover } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { getActiveTheme } from "src/services/localStorage";
import { STATUS } from "src/components/BulkMoveMembersModal/consts";

const buttonSize = getActiveTheme() === "compact" ? "32px" : "38px";

const BulkEditButton = ({ onClick, showBulkAction, status }) => {
  const text = showBulkAction ? "Disable edit mode" : "Enable edit mode";

  return (
    <Popover text={text} direction="topLeft" textAlign="center" width="120px">
      <Button
        variant="default"
        onClick={onClick}
        iconOnly
        disabled={status?.status === STATUS.PROGRESS}
        type="button"
        width={buttonSize}
        height={buttonSize}
        ariaLabel={text}
        aria-expanded={showBulkAction ? "true" : "false"}
      >
        <Icon icon={["fas", "pen"]} size="sm" ariaLabel={text} />
      </Button>
    </Popover>
  );
};

BulkEditButton.propTypes = {
  onClick: PropTypes.func,
  showBulkAction: PropTypes.bool,
  status: PropTypes.object,
};

export default BulkEditButton;
