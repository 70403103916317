export const copywriting = {
  DEFAULT_DESCRIPTION: "Write your team description here",
  FORM_VALIDATION_ERROR: "Please address errors on the form",
  FORM_SAVE_ERROR: "We're having issues updating the team, please try again",
  FORM_SAVING: "Updating team details",
  FIELD_URL_ERROR: "Please enter a valid URL",
};

export const changeTypes = {
  LABEL: "label",
  VALUE: "value",
  DELETE: "delete",
};

export const formFieldsLabel = {
  DESCRIPTION: "Description",
  LINK: "Links",
};

export const linksLabelAndPlaceholder = {
  LABEL_DESCRIPTION: "Link text",
  LABEL_VALUE: "Link URL",
  PLACEHOLDER_DESCRIPTION: "Add the text that will show with this link",
  PLACEHOLDER_VALUE: "Enter URL for link (e.g. Jira, Confluence, Trello, etc.)",
  ERROR_TEXT: "Please enter a valid",
  ADD_ITEM_TEXT: "Add link",
  DELETE_ITEM_TEXT: "Delete link",
};
