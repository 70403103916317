import { get, isEmpty } from "lodash";
import { Box, Flex } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PropertyOverridesSection from "src/pages/TeamPage/sub-components/TeamDetailsPanel/PropertyOverridesSection";
import TeamTagsSections from "src/pages/TeamPage/sub-components/TeamDetailsPanel/TeamTagsSections";
import ExpandableContent from "src/components/ExpandableContent";
import TeamDetailsStrategyTable from "src/pages/TeamPage/sub-components/TeamDetailsPanel/TeamDetailsStrategyTable";
import TeamDescription from "./TeamDescription";
import ObjectivesSection from "./Objectives";

const TeamDetailsPanel = ({
  loading,
  team,
  groupTypes,
  featureFlags,
  user,
  handleUpdateTeamDetails,
  canUpdateGroupAttributes,
  editDetailsMode,
  setEditDetailsMode,
  workspace,
  tagsVisibleIn,
}) => {
  const objectiveIds = get(team, "objectiveIds");
  const showObjectives = !isEmpty(objectiveIds);
  const workspaceFeatureFlags = get(workspace, "config.featureFlags", {});
  const enableTableViewInTeamDetails = get(
    workspace,
    "config.featureFlags.enableTableViewInTeamDetails",
    false
  );

  return (
    <>
      <Box
        borderRadius={2}
        boxBorder="default"
        shadow="default"
        p="r"
        mb="r"
        bg="white"
        width="100%"
      >
        <Flex alignItems="center" flexWrap="wrap">
          <TeamDescription
            attributes={get(team, "attributes")}
            loading={loading}
            canUpdateGroupAttributes={canUpdateGroupAttributes}
            groupTypes={groupTypes}
            featureFlags={featureFlags}
            handleUpdateTeamDetails={handleUpdateTeamDetails}
            editDetailsMode={editDetailsMode}
            setEditDetailsMode={setEditDetailsMode}
          />
          {!loading && (
            <TeamTagsSections
              team={team}
              user={user}
              tagsVisibleIn={tagsVisibleIn}
              featureFlags={workspaceFeatureFlags}
            />
          )}
          <PropertyOverridesSection
            propertyOverrides={team?.propertyOverrides}
            groupTypes={groupTypes}
            group={team}
            user={user}
            workspace={workspace}
          />
        </Flex>
      </Box>
      {showObjectives && (
        <Box
          borderRadius={2}
          boxBorder="default"
          shadow="default"
          p="r"
          mb="r"
          bg="white"
          width="100%"
        >
          {enableTableViewInTeamDetails ? (
            <ExpandableContent>
              <TeamDetailsStrategyTable team={team} />
            </ExpandableContent>
          ) : (
            <ObjectivesSection
              objectiveIds={objectiveIds}
              groupTypes={groupTypes}
            />
          )}
        </Box>
      )}
    </>
  );
};

TeamDetailsPanel.propTypes = {
  loading: PropTypes.bool,
  team: GroupPropType,
  groupTypes: GroupTypesPropType,
  featureFlags: PropTypes.object,
  user: PropTypes.object,
  handleUpdateTeamDetails: PropTypes.func.isRequired,
  canUpdateGroupAttributes: PropTypes.bool,
  editDetailsMode: PropTypes.bool,
  setEditDetailsMode: PropTypes.func,
  workspace: PropTypes.object,
  tagsVisibleIn: PropTypes.string,
};

export default React.memo(TeamDetailsPanel);
