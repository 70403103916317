import { map, union, uniq } from "lodash";
import * as ACTIONS from "../actionTypes";

const gatherUniqueTeamIds = (mainQueryData) => {
  const {
    membersAndTargetGroups,
    macroAllocationSummaries,
    individualAllocations,
  } = mainQueryData;

  const uniqueMembersAndTargetGroups = union(
    ...map(membersAndTargetGroups.members, "directTeamIds"),
    map(membersAndTargetGroups.groups, "groupId")
  );

  const uniqueMacroAllocationSummaryGroups = uniq(
    map(macroAllocationSummaries, "groupId")
  );
  const uniqueIndividualAllocationGroups = uniq(
    map(individualAllocations, "targetGroupId")
  );

  return union(
    uniqueMembersAndTargetGroups,
    uniqueMacroAllocationSummaryGroups,
    uniqueIndividualAllocationGroups
  );
};

const gatherUniquePeopleIds = (mainQueryData) => {
  const { membersAndTargetGroups, individualAllocations } = mainQueryData;

  return union(
    map(membersAndTargetGroups.members, "aggregateId"),
    map(individualAllocations, "personId")
  );
};

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.MAIN_QUERY_COMPLETE: {
      const { data } = action;
      return {
        ...state,
        skip: false,
        loading: true,
        variables: {
          ...state.variables,
          otherGroupIds: gatherUniqueTeamIds(data),
          peopleIds: gatherUniquePeopleIds(data),
        },
      };
    }
    case ACTIONS.SECONDARY_QUERY_COMPLETE: {
      const { data } = action;
      return {
        ...state,
        data,
        skip: true,
        loading: false,
      };
    }
    case ACTIONS.SECONDARY_QUERY_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        loading: false,
      };
    }
    default:
      return state;
  }
};
