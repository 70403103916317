const { reduce } = require("lodash");

const getTagsByGroupId = (groups) => {
  return reduce(
    groups,
    (acc, group) => {
      acc[group.id] = group.tags;
      return acc;
    },
    {}
  );
};

export default getTagsByGroupId;
