import { partition, filter, includes, some, sortBy } from "lodash";
import moment from "moment";
import "moment-timezone";

import { TAGS } from "src/consts/comments";
import { serialize } from "./components/RichTextEditor/RichTextSerializer";

const noteDateFormat = {
  sameDay: "[Today] h:mma",
  nextDay: "[Tomorrow] h:mma",
  nextWeek: "dddd",
  lastDay: "[Yesterday] h:mma",
  lastWeek: "[Last] dddd",
  sameElse: "DD/MM/YYYY",
};

export const setupTimezone = (timezone) => {
  const localTimezone = timezone || moment.tz.guess();
  moment.tz.setDefault(localTimezone);
};

export const getDateFromTimestamp = (date) =>
  moment(date).calendar(null, noteDateFormat);

export const getFormattedMsg = (content, contentMarkup, reverse) => {
  if (contentMarkup === "slate") {
    return serialize(JSON.parse(content), reverse);
  }
  return content;
};

export const getCurrentTime = () => moment().valueOf();

export const compareTimestamp = (t1, t2) => {
  const time1 = moment(t1);
  const time2 = moment(t2);
  return time1 > time2;
};

const filterFunc = (isDisplayStatus) => (comment) => {
  return (
    some(comment.tags, (t) =>
      includes([TAGS.USER_COMMENT, TAGS.SYSTEM_HIGHLIGHT], t)
    ) ||
    (includes(comment.tags, TAGS.SYSTEM_STATUS) && isDisplayStatus) ||
    (includes(comment.tags, TAGS.SYSTEM_APPROVER_STATUS) && !isDisplayStatus)
  );
};

export const filterVisibleComments = (comments, isDisplayStatus) => {
  return filter(comments, filterFunc(isDisplayStatus));
};

export const sortComments = (comments) => {
  return sortBy(comments, (c) => moment(c.timestamp));
};

export const partitionHistoricComments = (
  comments,
  historicCutOffTimestamp
) => {
  return partition(comments, (comment) => {
    return compareTimestamp(comment.timestamp, historicCutOffTimestamp);
  });
};
