import React from "react";
import PropTypes from "prop-types";

import moment from "moment";
import { useAllocationConfig } from "src/contexts/global/WorkspaceContext";
import { FullPageLoading } from "src/components/FullPageLoading";
import useUserRole from "src/app/Restricted/useUserRole";
import { ADMIN_ROLE } from "src/services/auth";

import MembershipList from "../MembershipList";
import { RelativeContainer } from "../RelativeContainer";

const isCurrentNewMembership = (newMembership) => {
  return !!newMembership?.startDate?.isBefore(
    moment().add(1, "day").startOf("day")
  );
};

const MembershipsListView = ({
  person,
  isLoaded,
  isUpdating,
  demandMemberships,
  supplyMemberships,
  currentMemberships,
  newMembership,
  futureMemberships,
  pastMemberships,
  fteTotal,
  fteAvailable,
  selectedGroup,
  setSelectedGroup,
  addMembership,
  moveMembership,
  removeMembership,
  updateMembership,
  updateMembershipGroup,
  fteConfig,
  teamToAddError,
}) => {
  const userRole = useUserRole();
  const {
    supplyLabel,
    enableChangeSupplyGroup,
    enablePastAllocationEdits,
    enableAllocationFteCap: enableFteCap,
  } = useAllocationConfig();
  const isAdmin = userRole === ADMIN_ROLE;

  // Admin user can always edit past allocations, or based on the flag
  const isPastEditEnabled = enablePastAllocationEdits || isAdmin;

  const currentNewMembership = isCurrentNewMembership(newMembership);

  return (
    <RelativeContainer pb="xl">
      {!isLoaded && <FullPageLoading />}
      <MembershipList
        title={supplyLabel}
        collapseExpandMode="EXPAND"
        person={person}
        memberships={supplyMemberships}
        onUpdateGroup={updateMembershipGroup}
        onDestroy={removeMembership}
        isLoading={isUpdating}
        isSupply
        enableChangeSupplyGroup={enableChangeSupplyGroup}
        isPastEditEnabled={isPastEditEnabled}
        fteConfig={fteConfig}
      />
      {pastMemberships?.length > 0 && (
        <MembershipList
          title="Past allocations"
          collapseExpandMode="COLLAPSE"
          person={person}
          memberships={pastMemberships}
          onUpdate={updateMembership}
          onDestroy={removeMembership}
          onReplace={addMembership}
          isLoading={isUpdating}
          isAdmin={isAdmin}
          isPastEditEnabled={isPastEditEnabled}
          fteConfig={fteConfig}
        />
      )}
      <MembershipList
        title={
          currentMemberships.length
            ? "Currently allocated to"
            : "Add new membership"
        }
        showCollapseExpand={false}
        memberships={currentMemberships}
        person={person}
        newMembership={currentNewMembership ? newMembership : null}
        fteTotal={fteTotal}
        collapseExpandMode="EXPAND"
        fteAvailable={fteAvailable}
        onCreate={addMembership}
        onMove={moveMembership}
        onDestroy={removeMembership}
        onUpdate={updateMembership}
        onUpdateGroup={updateMembershipGroup}
        onReplace={addMembership}
        isLoading={isUpdating}
        isAdmin={isAdmin}
        isPastEditEnabled={isPastEditEnabled}
        enableFteCap={enableFteCap}
        demandMemberships={demandMemberships}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        fteConfig={fteConfig}
        teamToAddError={teamToAddError}
      />
      {(futureMemberships?.length > 0 || !currentNewMembership) && (
        <MembershipList
          title="Future allocations"
          collapseExpandMode="EXPAND"
          memberships={futureMemberships}
          person={person}
          newMembership={!currentNewMembership ? newMembership : null}
          onCreate={addMembership}
          onMove={moveMembership}
          onUpdate={updateMembership}
          onDestroy={removeMembership}
          onReplace={addMembership}
          isLoading={isUpdating}
          isAdmin={isAdmin}
          isPastEditEnabled={isPastEditEnabled}
          enableFteCap={enableFteCap}
          demandMemberships={demandMemberships}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          fteConfig={fteConfig}
        />
      )}
    </RelativeContainer>
  );
};

MembershipsListView.propTypes = {
  person: PropTypes.object,
  isUpdating: PropTypes.bool,
  isLoaded: PropTypes.bool,
  demandMemberships: PropTypes.array,
  supplyMemberships: PropTypes.array,
  currentMemberships: PropTypes.array,
  newMembership: PropTypes.object,
  futureMemberships: PropTypes.array,
  pastMemberships: PropTypes.array,
  fteTotal: PropTypes.number,
  fteAvailable: PropTypes.number,
  selectedGroup: PropTypes.object,
  setSelectedGroup: PropTypes.func,
  addMembership: PropTypes.func,
  moveMembership: PropTypes.func,
  removeMembership: PropTypes.func,
  updateMembership: PropTypes.func,
  updateMembershipGroup: PropTypes.func,
  fteConfig: PropTypes.object,
  teamToAddError: PropTypes.object,
};

export default MembershipsListView;
