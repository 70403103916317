import React from "react";
import PropTypes from "prop-types";
import { isNil } from "lodash";
import styled from "styled-components";
import { Popover, TextInput } from "orcs-design-system";

const StyledNumberInput = styled(TextInput)`
  padding: 4px 7px 5px 7px;
`;

const PlannerRequestFteInput = ({
  value,
  defaultedValue,
  hasPrepopulatedValue,
  prepopulatedValue,
  numberProps,
  disabled,
  isValid,
  isInvalid,
  inputRef,
}) => {
  const placeholder = `${defaultedValue}`;

  const isPrepopulatedState =
    hasPrepopulatedValue &&
    !isNil(prepopulatedValue) &&
    prepopulatedValue !== value;

  const prepopulatedMessage = isPrepopulatedState
    ? "This number was pre-populated from forecast"
    : null;

  return (
    <Popover direction="top" text={prepopulatedMessage}>
      <StyledNumberInput
        fullWidth
        placeholder={placeholder}
        value={isPrepopulatedState ? prepopulatedValue : value}
        disabled={disabled}
        valid={isValid || undefined}
        invalid={isInvalid || undefined}
        numberProps={numberProps}
        ref={inputRef}
        height="28px"
      />
    </Popover>
  );
};

PlannerRequestFteInput.propTypes = {
  value: PropTypes.number,
  defaultedValue: PropTypes.number,
  hasPrepopulatedValue: PropTypes.bool,
  prepopulatedValue: PropTypes.number,
  numberProps: PropTypes.object,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  inputRef: PropTypes.object,
};

export default PlannerRequestFteInput;
