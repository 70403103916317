import { useQuery } from "@apollo/client";
import { get, filter, compact, map } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import { TAG_ENTITY_TEAM_TYPE, TAGS_UI_COMPONENTS } from "src/consts/tags";

import { tagTypeConfigsForEntityType as tagTypeConfigsForEntityTypeQuery } from "./queries/tags.graphql";
import TagSelectorContainer from "./sub-components/TagSelectorContainer";
import TagsEditor from "./TagsEditor";

const TagsEditorContainerSingleGroupAssociation = ({
  label,
  onTagSelected: tagSelectedCallback,
  tagsVisibleIn = TAGS_UI_COMPONENTS.DIRECTORY,
}) => {
  const [tag, setTag] = useState(null);

  const { data: tagTypesConfigData, loading: loadingTagConfig } = useQuery(
    tagTypeConfigsForEntityTypeQuery,
    {
      variables: {
        entityType: "group",
        ...(tagsVisibleIn && { visibleIn: tagsVisibleIn }),
      },
    }
  );

  const tagTypesConfig = filter(
    get(tagTypesConfigData, "tagTypesConfig"),
    (tagType) => tagType.isGroupAssociation && tagType.id !== "NEW_PARENT"
  );
  const tagTypes = map(tagTypesConfig, "id");

  const onTagSelected = useCallback(
    (selectedTag) => {
      setTag(selectedTag);
      if (tagSelectedCallback) tagSelectedCallback(selectedTag);
    },
    [setTag, tagSelectedCallback]
  );

  const onRemoveTag = useCallback(() => {
    // never remove tag once set for this component
    // setTag(null);
  }, []);

  return (
    <TagsEditor
      tagTypesConfig={tagTypesConfig}
      tagTypes={tagTypes}
      editOnly={true}
      isAssociationTag={true}
      loading={loadingTagConfig}
      existingTags={compact([tag])}
      setNewTag={onTagSelected}
      removeTag={onRemoveTag}
      label={label}
      components={{ TagSelector: TagSelectorContainer }}
      entityType={TAG_ENTITY_TEAM_TYPE}
    />
  );
};

TagsEditorContainerSingleGroupAssociation.propTypes = {
  label: PropTypes.string,
  onTagSelected: PropTypes.func,
  tagsVisibleIn: PropTypes.string,
};

export default TagsEditorContainerSingleGroupAssociation;
