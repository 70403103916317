import React from "react";
import PropTypes from "prop-types";
import RichTextContent from "src/components/RichTextContent";
import AuthorTitle from "../AuthorTitle";

const GenericHistoryContent = ({ content, authorTitle }) => {
  return (
    <>
      <RichTextContent content={content} />
      <AuthorTitle action="Changed" authorTitle={authorTitle} />
    </>
  );
};

GenericHistoryContent.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  authorTitle: PropTypes.string,
};

export default GenericHistoryContent;
