import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

import { INDIRECT_PLACEHOLDER } from "src/allocation/pages/ForecastPage/context/reducer/macroAllocationTypes";
import { showIndirectPlaceholderForecasts } from "src/allocation/pages/ForecastPage/components/ForecastGroupings/ForecastGrouping.logic";
import {
  useViewMode,
  useExpandedGroupIds,
  useGroupTypesLookup,
  useDispatch,
  useRootGroup,
  useSearchMode,
  useGroupSearchMode,
  useSearchResult,
} from "../../context/ForecastContext";
import TreeIndentWrapper from "../Shared/TreeIndentWrapper";
import GroupingExpandHeader from "./GroupingExpandHeader";
import LineItems from "./LineItems";

const ForecastGrouping = ({ grouping }) => {
  const viewMode = useViewMode();
  const rootGroup = useRootGroup();
  const expandedGroupIds = useExpandedGroupIds();
  const groupTypesLookup = useGroupTypesLookup();
  const searchMode = useSearchMode();
  const groupSearchMode = useGroupSearchMode();
  const searchResult = useSearchResult();
  const dispatch = useDispatch();

  const { id, nestedLevel, childGroupings } = grouping;
  const isExpanded = expandedGroupIds[id];
  const isShowIndirectPlaceholderForecasts = showIndirectPlaceholderForecasts({
    groupTypesLookup,
    grouping,
    viewMode,
  });

  return useMemo(
    () => {
      if (
        (searchMode || groupSearchMode) &&
        searchResult &&
        !searchResult.expandedGroupIdsForSearch[id]
      ) {
        return null;
      }

      return (
        <>
          <GroupingExpandHeader
            grouping={grouping}
            isExpanded={isExpanded}
            dispatch={dispatch}
            viewMode={viewMode}
            rootGroup={rootGroup}
          />

          {isExpanded && (
            <TreeIndentWrapper indentLevel={nestedLevel}>
              {isShowIndirectPlaceholderForecasts && (
                <LineItems
                  grouping={grouping}
                  type={INDIRECT_PLACEHOLDER}
                  canToggleLineItemsOverride={true}
                  dispatch={dispatch}
                />
              )}
              <LineItems grouping={grouping} dispatch={dispatch} />
              {map(childGroupings, (childGrouping) => (
                <ForecastGrouping
                  key={childGrouping.id}
                  grouping={childGrouping}
                />
              ))}
            </TreeIndentWrapper>
          )}
        </>
      );
    },
    [grouping.hash, isExpanded] // eslint-disable-line
  );
};

ForecastGrouping.propTypes = {
  grouping: PropTypes.object.isRequired,
};

export default ForecastGrouping;
