import React from "react";
import { Box, StatusDot, Icon } from "orcs-design-system";
import icons from "src/config/icons";

const ReadonlyNotification = () => {
  return (
    <Box mx="s">
      <StatusDot icon variant="danger">
        <Icon icon={icons.lock} color="white" size="xs" transform="up-2" />
      </StatusDot>
    </Box>
  );
};

export default React.memo(ReadonlyNotification);
