import { useApolloClient, useMutation } from "@apollo/client";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Box, H6, Modal, Flex, Button, Notification } from "orcs-design-system";

import { isNil } from "lodash";
import AllocationModalHeader from "src/allocation/components/AllocationModalHeader";
import { useAllocationConfig } from "src/contexts/global/WorkspaceContext/WorkspaceContext";
import { bulkMoveIndividuals } from "src/allocation/allocation.graphql";
import TeamDropdown from "src/allocation/components/TeamDropdown";
import { useSearchGroups } from "src/pages/InternalDashboard/useSearchHooks";
import ErrorNotification from "src/components/ErrorNotification";
import {
  useUserInteraction,
  useActiveAllocationProject,
  useDispatch,
  useLookupData,
  ACTIONS,
} from "../../context/ForecastContext";
import { findAllocation } from "../Shared/Members/utils/person.util";
import { refetchQuery } from "./AllocationModal/AllocationModal.util";

const MoveMemberModal = () => {
  const client = useApolloClient();
  const allocationConfig = useAllocationConfig();
  const {
    moveMember: { isOpen, grouping, person, searchVariables, getPeopleRefetch },
  } = useUserInteraction();
  const { showMemberMoveBadge } = useLookupData();
  const allocationProject = useActiveAllocationProject();
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState(false);
  const [targetGroupOption, setTargetGroupOption] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setTargetGroupOption(null);
      setIsProcessing(false);
      setError(null);
    }
  }, [isOpen]);

  const handleCloseModal = useCallback(() => {
    if (isProcessing) {
      return;
    }

    dispatch({
      type: ACTIONS.CLOSE_MOVE_MEMBER_MODAL,
    });
  }, [dispatch, isProcessing]);

  // Use existing bulk move members to move only one person
  const [bulkMoveMembers] = useMutation(bulkMoveIndividuals);

  const existingGroups = useMemo(
    () => (grouping ? [grouping.group] : []),
    [grouping]
  );

  const { loadOptions, isSearching } = useSearchGroups({
    groupTypes: allocationConfig.targetTypes,
    existingGroups,
    includeMembers: false,
  });

  const runMoveMember = useCallback(async () => {
    if (!targetGroupOption || !targetGroupOption.group) {
      return;
    }

    const { group: targetGroup } = targetGroupOption;
    const toGroupId = targetGroup.id;
    const {
      aggregateId,
      fte,
      allocations: existingAllocations,
      currentAllocations,
    } = person;

    const allocation = findAllocation(
      currentAllocations || existingAllocations,
      grouping.id
    );

    const requestedFte =
      allocation && !isNil(allocation.fte) ? allocation.fte : fte;

    const allocations = [
      {
        personId: aggregateId,
        requestedFte,
        fromGroupId: grouping.id,
        toGroupId,
      },
    ];

    try {
      setIsProcessing(true);

      // Bulk move members
      await bulkMoveMembers({
        variables: {
          input: {
            allocations,
            allocationProjectId: allocationProject.id,
          },
        },
      });

      if (showMemberMoveBadge) {
        setTimeout(() => {
          // Refetch existing members list
          refetchQuery(client, searchVariables.targetGroupId, searchVariables);
          getPeopleRefetch();
        }, 100);
      }

      setTimeout(() => {
        // Refetch new members list
        refetchQuery(client, toGroupId, searchVariables);
      }, 200);

      // Refresh the planner page
      dispatch({
        type: ACTIONS.MAIN_QUERY_REFRESH,
        backgroundRefresh: true,
      });

      handleCloseModal();
    } catch (e) {
      setError(e);
    } finally {
      setIsProcessing(false);
    }
  }, [
    targetGroupOption,
    person,
    grouping,
    bulkMoveMembers,
    allocationProject,
    showMemberMoveBadge,
    dispatch,
    handleCloseModal,
    getPeopleRefetch,
    client,
    searchVariables,
  ]);

  if (!isOpen && !grouping) {
    return null;
  }

  // Set move and cancel button to disabled if processing or have any error
  const isBusy = isProcessing || !!error;

  const footerContent = (
    <Box width="100%">
      <Flex alignItems="center" justifyContent="flex-end" width="100%">
        <Button
          onClick={handleCloseModal}
          variant="ghost"
          mr="r"
          disabled={isBusy}
        >
          Cancel
        </Button>
        <Button
          onClick={runMoveMember}
          variant={isBusy ? "disabled" : "success"}
          disabled={isBusy}
        >
          Move member
        </Button>
      </Flex>
    </Box>
  );

  return (
    <Modal
      visible={isOpen}
      width="60vw"
      overflow="visible"
      headerContent={<AllocationModalHeader person={person} />}
      footerContent={footerContent}
      onClose={handleCloseModal}
    >
      <>
        <H6 my="r" weight="bold">
          Where would you like to move this person to?
        </H6>
        <Box width="100%">
          <TeamDropdown
            id="move-members-target-group"
            placeholder="Search and select target team"
            isSearchable
            isLoading={isSearching}
            options={[]}
            loadOptions={loadOptions}
            onSelectTeam={setTargetGroupOption}
            value={targetGroupOption}
            cacheOptions={false}
          />
        </Box>
        <Box mt="r">
          {isProcessing && !error && (
            <Notification loading closable={false}>
              Moving in progress ...
            </Notification>
          )}
          {error && (
            <ErrorNotification
              message={`Sorry, an error occurred, please refresh and try moving again. (${error.message})`}
              error={error}
              closable={false}
            />
          )}
        </Box>
      </>
    </Modal>
  );
};

export default MoveMemberModal;
