import React, { useMemo } from "react";
import PropTypes from "prop-types";

import Members from "../../Shared/Members";
import LineItemContent from "./LineItemContent";

const LineItem = ({
  lineItem,
  isMembersExpanded,
  dispatch,
  hideApproverColumn,
  shouldShowCost,
  enableMembersInPlanner,
  isForecast,
  enableMemberFteEdit,
}) => {
  return useMemo(
    () => {
      return (
        <>
          <LineItemContent
            lineItem={lineItem}
            isMembersExpanded={isMembersExpanded}
            shouldShowCost={shouldShowCost}
            hideApproverColumn={hideApproverColumn}
            dispatch={dispatch}
            withMembersExpandIcon={enableMembersInPlanner}
            isForecast={isForecast}
            enableMemberFteEdit={enableMemberFteEdit}
          />
          {enableMembersInPlanner && isMembersExpanded && (
            <Members
              lineItem={lineItem}
              withExtraColumn={shouldShowCost}
              dispatch={dispatch}
            />
          )}
        </>
      );
    },
    [lineItem.hash, isMembersExpanded] // eslint-disable-line
  );
};

LineItem.propTypes = {
  lineItem: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  showMemberTags: PropTypes.bool,
  hideApproverColumn: PropTypes.bool,
  enableMemberAllocation: PropTypes.bool,
  shouldShowCost: PropTypes.bool,
  enableMembersInPlanner: PropTypes.bool,
  isForecast: PropTypes.bool.isRequired,
  enableMemberFteEdit: PropTypes.bool.isRequired,
};

export default LineItem;
