import React from "react";
import PropTypes from "prop-types";
import { H2 } from "orcs-design-system";
import GroupPropType from "src/custom-prop-types/group";

const GroupName = ({ group, isMovedOut = false }) => {
  const { name, isHidden } = group;
  if (isHidden || isMovedOut) {
    return (
      <H2 fontSize={2}>
        <strike>{name}</strike>
      </H2>
    );
  }
  return <H2 fontSize={2}>{name}</H2>;
};

GroupName.propTypes = {
  group: GroupPropType.isRequired,
  isMovedOut: PropTypes.bool,
};

export default GroupName;
