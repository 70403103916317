import { Box, Flex, FlexItem, H3 } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import IndividualPersonAllocationPage from "./IndividualPersonAllocationPage";
import SupplyGroupAllocation from "./SupplyGroupAllocation";

const IndividualPersonAllocationPageLayout = (...children) => {
  return (
    <Box>
      <H3 mb="r">Team memberships</H3>
      <Box>
        {children[0]}
        {children[1]}
      </Box>
      {children[2]}
    </Box>
  );
};

const PersonAllocationPage = ({
  allocationProjectId,
  person,
  personId,
  personFte,
  onPersonAllocating,
  onPersonAllocated,
  onPersonUnallocated,
  onPersonAllocationUpdated,
  withSupplyGroupAllocation = false,
  onSupplyGroupChanged,
  withPageTitle = true,
  workspace,
  groupTypes,
}) => {
  if (!withSupplyGroupAllocation) {
    return (
      <Box pt="r">
        <IndividualPersonAllocationPage
          allocationProjectId={allocationProjectId}
          personId={personId}
          personFte={personFte}
          onPersonAllocating={onPersonAllocating}
          onPersonAllocated={onPersonAllocated}
          onPersonUnallocated={onPersonUnallocated}
          onPersonAllocationUpdated={onPersonAllocationUpdated}
          groupTypes={groupTypes}
          layoutFunc={IndividualPersonAllocationPageLayout}
          workspace={workspace}
        />
      </Box>
    );
  }

  return (
    <>
      {withPageTitle && <H3 bold>Allocations</H3>}
      <Flex
        width="100%"
        pt="r"
        flexDirection={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
        ]}
      >
        <FlexItem flex="1 1 50%" mr={["0", "0", "0", "0", "s"]}>
          <IndividualPersonAllocationPage
            allocationProjectId={allocationProjectId}
            personId={personId}
            personFte={personFte}
            onPersonAllocating={onPersonAllocating}
            onPersonAllocated={onPersonAllocated}
            onPersonUnallocated={onPersonUnallocated}
            onPersonAllocationUpdated={onPersonAllocationUpdated}
            layoutFunc={IndividualPersonAllocationPageLayout}
            groupTypes={groupTypes}
            workspace={workspace}
          />
        </FlexItem>
        <FlexItem
          flex="1 1 50%"
          ml={["0", "0", "0", "0", "s"]}
          mb={["r", "r", "r", "r", "0"]}
        >
          <SupplyGroupAllocation
            person={person}
            groupTypes={groupTypes}
            onSupplyGroupChanged={onSupplyGroupChanged}
            workspace={workspace}
          />
        </FlexItem>
      </Flex>
    </>
  );
};

PersonAllocationPage.propTypes = {
  allocationProjectId: PropTypes.string,
  person: PropTypes.object,
  personId: PropTypes.string,
  personFte: PropTypes.number,
  onPersonAllocating: PropTypes.func,
  onPersonAllocated: PropTypes.func,
  onPersonUnallocated: PropTypes.func,
  onPersonAllocationUpdated: PropTypes.func,
  withSupplyGroupAllocation: PropTypes.bool,
  onSupplyGroupChanged: PropTypes.func,
  withPageTitle: PropTypes.bool,
  workspace: PropTypes.object,
  groupTypes: PropTypes.object,
};

export default PersonAllocationPage;
