/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";

import * as notesUtil from "../../utils";

const PURIFY_CONFIG = {
  ADD_ATTR: ["target"],
};

const FormattedCommentContent = ({ content, contentMarkup, reverse }) => {
  const htmlStr = DOMPurify.sanitize(
    notesUtil.getFormattedMsg(content, contentMarkup, reverse),
    PURIFY_CONFIG
  );

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: htmlStr,
      }}
    />
  );
};

FormattedCommentContent.propTypes = {
  content: PropTypes.string.isRequired,
  contentMarkup: PropTypes.string,
  reverse: PropTypes.bool,
};

export default FormattedCommentContent;
