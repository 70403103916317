export const TYPE_ALLOCATION = "allocation";
export const TYPE_OBJECTIVES = "objectives";
export const TYPE_PLANNING = "planning";
export const TYPE_FORECAST = "forecast";
export const TYPE_SUPPLY_UPDATE = "supply-update";
export const TYPE_TAGGING = "tags";
export const TYPE_DOWNLOAD = "download";
export const TYPE_GROUP_UPDATE = "group-update";
export const TYPE_PERSON_UPDATE = "person-update";
export const TYPE_MEMBERSHIPS = "memberships-update";

export const TYPE_OTHER = "other";
