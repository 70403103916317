// eslint-disable-next-line import/prefer-default-export
export const copywriting = {
  FORM_INVALID: "Please select an image to save",
  FORM_SAVE_ERROR: "We're having issues saving the image, please try again",
  RESULTS_PLACEHOLDER: "No images found",
  RESULTS_LOADING: "Loading...",
  SEARCH_ERROR:
    "We're having retrieving images from Unsplash, please try again",
  LOADMORE_ERROR:
    "There was an error loading more results, please try searching again",
  ATTRIBUTION_LABEL: "Photos by Unsplash",
  SEARCH_PLACEHOLDER: "Start typing to search",
};

export const sizing = {
  MODAL_HEIGHT: "80vh",
  MODAL_WIDTH: "90vw",
  MODAL_MAXHEIGHT: "90vh",
  MODAL_MAXWIDTH: "90vw",
  CARD_WIDTH: 190,
  CARD_GUTTER: 8,
  CARD_LABEL_HEIGHT: 30,
};

export const searchConfig = {
  // Number of items to retrieve per pagination request
  LIMIT: 20,
  // The threshold at which to pre-fetch data.
  // A threshold X means that new data should start loading when a user scrolls within X cells
  // of the end of your items array.
  THRESHOLD: 16,
  /* 
    important - leave to 2
    This number is used for determining the number of grid cells outside 
    of the visible window to render. The default value is 2 which means "render 2 
    windows worth (2 * height) of content before and after the items in the visible window". 
    A value of 3 would be 3 windows worth of grid cells, so it's a linear relationship. 
    Overscanning is important for preventing tearing when scrolling through items in the grid, 
    but setting too high of a value may create too much work for React to handle, 
    so it's best that you tune this value accordingly.
  */
  OVERSCANBY: 2,
};
