/* eslint-disable no-param-reassign */
import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { forEach, reduce, keys } from "lodash";

import { getMembersById as getMembersByIdQuery } from "src/allocation/members.graphql";

const useLoadMembersInRange = (lineItem) => {
  const { cells = [] } = lineItem;

  const [fteForRanges, allMemberIds] = useMemo(() => {
    const totalColumns = cells.length;

    const ftes = reduce(
      cells,
      (prev, cell, index) => {
        const { members } = cell;

        forEach(members, (m) => {
          const { personId, proratedFte } = m;

          const person = prev[personId];

          if (person) {
            person[index] += proratedFte;
          } else {
            // Add new person, and pro-rated FTE to person
            prev[personId] = new Array(totalColumns).fill(0);
            prev[personId][index] = proratedFte;
          }
        });

        return prev;
      },
      {}
    );

    const ids = keys(ftes);

    return [ftes, ids];
  }, [cells]);

  const { data, loading } = useQuery(getMembersByIdQuery, {
    variables: {
      ids: allMemberIds,
    },
    skip: !allMemberIds.length,
  });

  return {
    loading,
    allMembers: data?.people || [],
    fteForRanges,
  };
};

export default useLoadMembersInRange;

/* eslint-enable no-param-reassign */
