import React from "react";
import PropTypes from "prop-types";
import { map, isEmpty } from "lodash";

import {
  useForecastModel,
  useSearchMode,
  useGroupSearchMode,
  useSearchResult,
} from "../../context/ForecastContext";
import SearchErrorMessage from "../Shared/SearchErrorMessage";
import ExpandAllGroupButton from "../Shared/ExpandAllGroupButton";
import ForecastGrouping from "./ForecastGrouping";

const SearchModeView = ({ groupings }) => {
  const searchResult = useSearchResult();

  if (searchResult.error) {
    return <SearchErrorMessage message={searchResult.error.message} />;
  }

  if (searchResult && isEmpty(searchResult.expandedGroupIdsForSearch)) {
    return <SearchErrorMessage message="No matches found" />;
  }

  return (
    <>
      {map(groupings, (grouping) => (
        <ForecastGrouping key={grouping.groupId} grouping={grouping} />
      ))}
    </>
  );
};

SearchModeView.propTypes = {
  groupings: PropTypes.array,
};

const ForecastGroupings = () => {
  const {
    dynamicModel: { groupings },
  } = useForecastModel();
  const searchMode = useSearchMode();
  const groupSearchMode = useGroupSearchMode();

  if (searchMode || groupSearchMode) {
    return <SearchModeView groupings={groupings} />;
  }

  return (
    <>
      <ExpandAllGroupButton groupings={groupings} />
      {map(groupings, (grouping) => (
        <ForecastGrouping key={grouping.id} grouping={grouping} />
      ))}
    </>
  );
};

export default ForecastGroupings;
