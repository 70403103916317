import { useQuery, useMutation } from "@apollo/client";
import { get, isEmpty, partition, size } from "lodash";
import { Box } from "orcs-design-system";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState, useMemo } from "react";

import moment from "moment";
import { changePersonSupplyGroup } from "src/allocation/person.graphql";
import { numberToLocaleString } from "src/util/format";
import { addMembershipOnly } from "src/queries/memberships.graphql";
import MemberActions from "src/components/MemberActions/index";
import useBulkMoveMembers from "src/components/BulkMoveMembersModal/hooks/useBulkMoveMembers";
import useSortOptions, { sortPrecedence } from "src/hooks/useSortOptions";
import Panel from "src/components/Panel";
import PersonAllocationsModal from "src/components/Person/AllocationsModal";
import { getPersonItemRowId } from "src/components/Person/PersonItem/PersonItem.util";
import AllocationPersonSearch from "src/components/AllocationPersonSearch";
import ErrorNotification from "src/components/ErrorNotification";
import { useModal } from "src/contexts/withModal";
import GroupPropType from "src/custom-prop-types/group";
import PersonPropType from "src/custom-prop-types/person";
import WorkspacePropType from "src/custom-prop-types/workspace";
import { useUpdateTeamAndMemberDetails } from "src/hooks/useUpdateTeamAndMemberDetails";
import { copywriting } from "src/pages/TeamPage/sub-components/TeamMembersPanel.config";
import { getTeamMembersQuery } from "src/queries/group.graphql";
import { getLocalSortOption } from "src/services/localStorage";
import { SORT_INDEX } from "src/consts/storage";
import {
  isRealTimeAllocationsEnabled,
  isAddingMemberEnabled,
} from "src/util/allocations";
import { getFteSumInput, getRemainingFte } from "src/util/fteUtil";
import {
  getAltSupplyOrDemandLabel,
  getSupplyGroup,
  isGroupActive,
} from "src/util/group";
import { getGroupType } from "src/util/groupType";

import useBulkAction from "../../hooks/useBulkAction";
import { getFilteredMemberList } from "../../utils";

import MembersList from "./sub-components/MembersList";
import VacantRolesList from "./sub-components/VacantRolesList";

const GroupByNameOption = { value: "groupByName", label: "Sort by Name" };

const TeamMembersPanel = ({
  teamId,
  team,
  workspace,
  allocationProject,
  user,
  featureFlags,
  userPerson,
  renderFteBadge,
}) => {
  const { data: membersData, loading: membersDataLoading } = useQuery(
    getTeamMembersQuery,
    {
      variables: { groupId: teamId },
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
      skip: !teamId,
    }
  );

  const loading = membersDataLoading && !membersData;
  const members = useMemo(() => {
    return get(membersData, "team.members") || [];
  }, [membersData]);

  const [, dispatch] = useModal();
  const [fullListMode, setFullListMode] = useState(false);
  const [isAddingToGroup, setIsAddingToGroup] = useState(false);
  const [selectedPersonId, setSelectedPersonId] = useState();
  const [newMemberId, setNewMemberId] = useState(null);
  const [anyError, setAnyError] = useState();
  const supplyOrDemandLabel = getAltSupplyOrDemandLabel(team, workspace);
  const groupTypes = get(workspace, "config.groupTypes");
  const supplyLabel = get(workspace, "config.allocation.supplyLabel");

  const {
    targetGroupOption,
    handleSelectPerson,
    handleBulkMove,
    setTargetGroupOption,
    selectedMembers,
    setSelectedMembers,
    status,
    setStatus,
  } = useBulkMoveMembers({
    team,
    allocationProject,
  });

  const {
    showBulkAction,
    toggleBulkAction,
    showBulkEditButton,
    membersFilterOption,
    setMembersFilterOption,
  } = useBulkAction({
    user,
    workspace,
    allocationProject,
    groupTypes,
    team,
    setSelectedMembers,
    setStatus,
  });

  const filteredMembersList = useMemo(() => {
    return getFilteredMemberList(members, membersFilterOption);
  }, [members, membersFilterOption]);

  const sortOptions = useMemo(
    () => [
      GroupByNameOption,
      { value: "groupByType", label: `Sort by ${supplyOrDemandLabel}` },
    ],
    [supplyOrDemandLabel]
  );

  const initialSort = sortPrecedence({
    sortOptions,
    sessionSortValue: getLocalSortOption(SORT_INDEX.TEAMS_MEMBER_PANEL),
  });

  const { options, selectedSort, onSelectSortType } = useSortOptions(
    sortOptions,
    SORT_INDEX.TEAMS_MEMBER_PANEL,
    initialSort
  );

  const updateTeamAndMemberDetails = useUpdateTeamAndMemberDetails();

  useEffect(() => {
    setNewMemberId(null);
    setSelectedPersonId(null);
    setAnyError(null);
    setIsAddingToGroup(false);
  }, [teamId]);

  const [allocateToNewGroupMutation] = useMutation(addMembershipOnly);

  const [changeSupplyGroupMutation, { error: changeSupplyGroupError }] =
    useMutation(changePersonSupplyGroup);

  const toggleMembersList = useCallback(() => {
    setFullListMode((flag) => !flag);
  }, [setFullListMode]);

  const allocationsEnabled =
    isRealTimeAllocationsEnabled({
      user,
      workspace,
      allocationProject,
    }) && isAddingMemberEnabled(workspace, team);

  const onEditingPersonAllocations = useCallback(
    ({ person, teamToAdd, teamToAddError }) => {
      const onHidePersonModal = () => {
        dispatch({ type: "setModalVisible", isModalVisible: false });
      };
      const personForUpdate = { ...person };

      const modalComponent = (
        <PersonAllocationsModal
          isVisible={true}
          person={personForUpdate}
          workspace={workspace}
          groupTypes={groupTypes}
          allocationProject={allocationProject}
          onHideModal={onHidePersonModal}
          showAlreadyAllocatedNotification={true}
          forceUpdatePerson
          teamToAdd={teamToAdd}
          teamToAddError={teamToAddError}
        />
      );
      dispatch({ type: "setModalComponent", modalComponent });
    },
    [allocationProject, workspace, dispatch, groupTypes]
  );

  const updateSupplyGroup = useCallback(
    async (person) => {
      const variables = {
        personId: person.aggregateId,
        groupId: team.id,
      };

      const originalSupplyGroup = getSupplyGroup({ person, groupTypes });
      await changeSupplyGroupMutation({
        variables,
        update() {
          updateTeamAndMemberDetails({
            person,
            groups: [team, originalSupplyGroup],
            targetGroup: team,
            fteSumInput: getFteSumInput(team, groupTypes),
          }).then(() => {
            setIsAddingToGroup(false);
          });
        },
      });
      setIsAddingToGroup(false);
    },
    [changeSupplyGroupMutation, groupTypes, team, updateTeamAndMemberDetails]
  );

  const allocateToGroup = useCallback(
    async (person) => {
      const variables = {
        input: {
          startDate: moment().format("YYYY-MM-DD"),
          groupId: team.id,
          personId: person.aggregateId,
          fte: getRemainingFte({
            allocations: person.allocations,
            totalFte: person.fte,
          }),
        },
      };

      const fteSumInput = getFteSumInput(team, groupTypes);
      await allocateToNewGroupMutation({
        variables,
        update: () => {
          updateTeamAndMemberDetails({
            person,
            groups: [team],
            targetGroup: team,
            fteSumInput,
          }).then(() => {
            setIsAddingToGroup(false);

            const hasExistingAllocations = !isEmpty(person.allocations);
            if (hasExistingAllocations) {
              onEditingPersonAllocations({ person });
            }
          });
        },
        // if we get an error from the backend here just open the allocations modal and display the error there.
        // the user may be able to fix the error there by changing fte values etc.
        onError: (error) => {
          onEditingPersonAllocations({
            person,
            teamToAdd: team,
            teamToAddError: error,
          });
        },
      });
    },
    [
      allocateToNewGroupMutation,
      groupTypes,
      onEditingPersonAllocations,
      team,
      updateTeamAndMemberDetails,
    ]
  );

  const onPersonSelected = useCallback(
    async (value) => {
      if (!value) {
        return;
      }

      const { person, alreadyInGroup } = value;

      if (person) {
        setFullListMode(true);
        setSelectedPersonId(person.aggregateId);

        if (!alreadyInGroup) {
          setIsAddingToGroup(true);
          setNewMemberId(person.aggregateId);
          const groupType = getGroupType(team, groupTypes);

          try {
            if (groupType && groupType.isSupply) {
              await updateSupplyGroup(person);
            } else {
              await allocateToGroup(person);
            }
          } finally {
            setIsAddingToGroup(false);
          }
        } else {
          setNewMemberId(null);
          const elem = document.querySelector(`#${getPersonItemRowId(person)}`);
          if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
    },
    [
      team,
      setSelectedPersonId,
      setNewMemberId,
      groupTypes,
      allocateToGroup,
      updateSupplyGroup,
    ]
  );

  const isAllocationAllowed =
    !groupTypes?.[team?.type]?.disableToHaveDirectReports;

  const teamAllocationsPersonSearch = allocationsEnabled &&
    isGroupActive(team) && (
      <AllocationPersonSearch
        existingMembers={filteredMembersList}
        onPersonSelected={onPersonSelected}
        onAddToGroup={() => null}
        groupTypes={groupTypes}
        disabled={!isAllocationAllowed}
        allocatedOptionLabel={copywriting.ALLOCATED}
        allocateOptionLabel={copywriting.ALLOCATE}
      />
    );

  useEffect(() => {
    if (changeSupplyGroupError) {
      setAnyError(changeSupplyGroupError);
    }
  }, [changeSupplyGroupError]);

  const [vacantRoles, people] = useMemo(() => {
    return partition(
      filteredMembersList,
      (m) => m.isPlaceholder || m.isVacancy
    );
  }, [filteredMembersList]);

  const directMemberCountDisplay = loading
    ? "-"
    : numberToLocaleString(size(people));

  return (
    <Panel
      title={`${directMemberCountDisplay} ${pluralize(
        copywriting.directMember,
        size(filteredMembersList)
      )}`}
      data-testid="team-members-list"
      actions={
        <MemberActions
          team={team}
          options={options}
          selectedSort={selectedSort}
          onSelectSortType={onSelectSortType}
          handleBulkMove={handleBulkMove}
          setTargetGroupOption={setTargetGroupOption}
          setSelectedMembers={setSelectedMembers}
          setStatus={setStatus}
          status={status}
          toggleBulkAction={toggleBulkAction}
          teamId={teamId}
          members={filteredMembersList}
          memberCount={filteredMembersList.length}
          showBulkEditButton={showBulkEditButton}
          selectedMembers={selectedMembers}
          groupTypes={groupTypes}
          showBulkAction={showBulkAction}
          targetGroupOption={targetGroupOption}
          supplyLabel={supplyLabel}
          setMembersFilterOption={setMembersFilterOption}
          isAllocationAllowed={isAllocationAllowed}
        >
          {teamAllocationsPersonSearch}
        </MemberActions>
      }
      wrap="wrap"
    >
      {anyError && (
        <Box mt="r">
          <ErrorNotification
            onDismiss={() => setAnyError(null)}
            message={anyError?.message}
            error={anyError}
            closable={true}
          />
        </Box>
      )}
      <MembersList
        bulkEditStatus={status}
        showPersonCheckbox={showBulkAction}
        handleSelectPerson={handleSelectPerson}
        selectedMembers={selectedMembers}
        fullListMode={fullListMode}
        selectedSort={selectedSort}
        loading={loading}
        team={team}
        groupTypes={groupTypes}
        members={people}
        newMemberId={newMemberId}
        selectedPersonId={selectedPersonId}
        isAddingToGroup={isAddingToGroup}
        toggleMembersList={toggleMembersList}
        user={user}
        userPerson={userPerson}
        featureFlags={featureFlags}
        workspace={workspace}
        allocationProject={allocationProject}
        renderFteBadge={renderFteBadge}
      />
      <VacantRolesList
        vacantRoles={vacantRoles}
        bulkEditStatus={status}
        showPersonCheckbox={showBulkAction}
        handleSelectPerson={handleSelectPerson}
        selectedMembers={selectedMembers}
        selectedSort={selectedSort}
        loading={loading}
        team={team}
        groupTypes={groupTypes}
        newMemberId={newMemberId}
        selectedPersonId={selectedPersonId}
        user={user}
        userPerson={userPerson}
        featureFlags={featureFlags}
        workspace={workspace}
        allocationProject={allocationProject}
        renderFteBadge={renderFteBadge}
      />
    </Panel>
  );
};

TeamMembersPanel.propTypes = {
  teamId: PropTypes.string,
  team: GroupPropType,
  workspace: WorkspacePropType,
  allocationProject: PropTypes.object,
  user: PropTypes.object,
  featureFlags: PropTypes.object,
  userPerson: PersonPropType,
  renderFteBadge: PropTypes.func,
};

export default React.memo(TeamMembersPanel);
