import { Box, Modal } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import PersonPropType from "src/custom-prop-types/person";

import PersonMembershipsPanel from "./PersonMembershipsPanel";
import LegacyAllocationsPanel from "./LegacyAllocationsPanel";
import ModalHeader from "./ModalHeader";

const PersonAllocationsModal = ({
  isVisible,
  person,
  groupTypes,
  allocationProject,
  workspace,
  onHideModal,
  currentGroup,
  forceUpdatePerson = false,
  showAlreadyAllocatedNotification,
  teamToAdd,
  teamToAddError,
}) => {
  const { enableFutureAllocationsUI } = useWorkspaceFeatureFlags();

  if (!isVisible || !person) {
    return null;
  }

  const layoutFunction = (modalBody, viewToggle) => (
    <Modal
      data-testid="cp-allocation-modal"
      visible={isVisible}
      maxWidth="90vw"
      maxHeight="90vh"
      height={["90vh", "90vh", "90vh", "90vh", "80vh"]}
      width={["90vw", "90vw", "90vw", "90vw", "90vw", "80vw"]}
      onClose={onHideModal}
      headerContent={
        <ModalHeader
          person={person}
          showAlreadyAllocatedNotification={showAlreadyAllocatedNotification}
          viewToggle={viewToggle}
        />
      }
    >
      <Box px="xs">{modalBody}</Box>
    </Modal>
  );

  if (enableFutureAllocationsUI) {
    return (
      <PersonMembershipsPanel
        person={person}
        currentGroup={currentGroup}
        groupTypes={groupTypes}
        layoutFunction={layoutFunction}
        forceUpdatePerson={forceUpdatePerson}
        teamToAdd={teamToAdd}
        teamToAddError={teamToAddError}
      />
    );
  }

  return layoutFunction(
    <LegacyAllocationsPanel
      person={person}
      workspace={workspace}
      groupTypes={groupTypes}
      allocationProject={allocationProject}
      currentGroup={currentGroup}
      forceUpdatePerson={forceUpdatePerson}
    />
  );
};

PersonAllocationsModal.propTypes = {
  isVisible: PropTypes.bool,
  person: PersonPropType.isRequired,
  groupTypes: PropTypes.object.isRequired,
  allocationProject: PropTypes.object.isRequired,
  workspace: PropTypes.object.isRequired,
  onHideModal: PropTypes.func,
  currentGroup: PropTypes.object,
  forceUpdatePerson: PropTypes.bool,
  showAlreadyAllocatedNotification: PropTypes.bool,
  teamToAdd: PropTypes.object,
  teamToAddError: PropTypes.object,
};

export default React.memo(PersonAllocationsModal);
