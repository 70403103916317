import themeGet from "@styled-system/theme-get";
import { StyledLink, Small, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import icon from "src/config/icons";
import { getExplorePath } from "src/util/paths";

const CustomLink = styled(StyledLink)`
  color: ${(props) =>
    props.isDarkTheme
      ? themeGet("colors.greyLight")
      : themeGet("colors.greyDark")};
`;

const TOP_LINK = "Top";

const TopLink = ({ isDarkTheme }) => {
  return (
    <>
      <Small fontSize="0">
        <CustomLink
          isDarkTheme={isDarkTheme}
          fontSize="0"
          to={getExplorePath()}
          data-testid="breadcrumb-link-top"
        >
          {TOP_LINK}
        </CustomLink>
      </Small>
      <Icon icon={icon.breadcrumbAngle} color="grey" mx="xs" />
    </>
  );
};

TopLink.propTypes = {
  isDarkTheme: PropTypes.bool,
};

export default TopLink;
