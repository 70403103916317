import { useQuery } from "@apollo/client";
import { map, get, isEmpty } from "lodash";
import { Box, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import ExpandableContent from "src/components/ExpandableContent";
import * as placeholder from "src/components/Placeholder/PlaceholderStyles";
import { getObjectivesQuery } from "src/queries/group.graphql";

import { OBJECTIVE_ID_WITH_NO_PARENT } from "./consts";
import { convertObjectives, hasParents } from "./convertObjectives.util";
import WithActualParent from "./WithActualParent";
import WithDummyParent from "./WithDummyParent";

const ObjectivesSection = ({ objectiveIds, groupTypes }) => {
  const { data, loading } = useQuery(getObjectivesQuery, {
    variables: {
      objectiveIds,
    },
    errorPolicy: "all",
  });

  const objectives = get(data, "objectives", []);

  const allObjectives = useMemo(() => {
    return convertObjectives(objectives);
  }, [objectives]);
  const hasActualParents = hasParents(objectives);

  if (isEmpty(allObjectives)) {
    return null;
  }

  if (loading) {
    return (
      <Spacer mb="s">
        <placeholder.Line
          data-testid="cp-team-description-placeholder-line"
          width={150}
          height={34}
        />
        <placeholder.Line
          data-testid="cp-team-description-placeholder-line"
          width={260}
          height={24}
        />
        <placeholder.Line
          data-testid="cp-team-description-placeholder-line"
          width={260}
          height={24}
        />
      </Spacer>
    );
  }

  return (
    <ExpandableContent>
      <div data-testid="objectives">
        <Box mb="s">
          {map(allObjectives, (objective) => {
            return objective?.id === OBJECTIVE_ID_WITH_NO_PARENT ? (
              <WithDummyParent
                objective={objective}
                groupTypes={groupTypes}
                hasActualParents={hasActualParents}
              />
            ) : (
              <WithActualParent objective={objective} groupTypes={groupTypes} />
            );
          })}
        </Box>
      </div>
    </ExpandableContent>
  );
};

ObjectivesSection.propTypes = {
  objectiveIds: PropTypes.array,
  groupTypes: PropTypes.object,
};

export default React.memo(ObjectivesSection);
