import React, { useState } from "react";
import PropTypes from "prop-types";
import { get, isEmpty, filter, map, uniq } from "lodash";
import { useMutation } from "@apollo/client";
import { removeAssociatedGroup } from "src/allocation/team.graphql";
import { LINK_OBJECTIVE_DATASOURCE } from "src/consts/objectives";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import { ACTIONS } from "../../../context/ForecastContext";

import ObjectivesExpandHeader from "./ObjectivesExpandHeader";
import ObjectivesBody from "./ObjectivesBody";
import { useFetchObjectives } from "./hooks/useFetchObjectives";
import { useObjectiveAttributeFilter } from "./hooks/useObjectiveAttributeFilter";
import { getObjectiveIds } from "./utils/getObjectiveIds";

const Objectives = ({
  grouping,
  isObjectivesExpanded,
  dispatch,
  inlineStyle = false,
  activeAllocationProject,
  loadOnExpand = false,
}) => {
  const groupTypes = useGroupTypes();
  const [isUpdating, setIsUpdating] = useState(false);

  const { id: allocationProjectId } = activeAllocationProject;
  const { groupId, group, canEditObjectives, root: rootGroup } = grouping;
  const attributeFilter = useObjectiveAttributeFilter({ group });

  const linkedObjectiveIds = map(
    filter(group?.linkedStrategies, {
      datasource: LINK_OBJECTIVE_DATASOURCE.PLANNER,
    }),
    "id"
  );

  const objectiveIds = uniq([
    ...linkedObjectiveIds,
    ...(group?.objectiveIds || []),
  ]);
  const objectives = get(group, "objectives", null);
  const filteredObjectiveIds = getObjectiveIds({
    group,
    groupTypes,
  });

  const { sortedObjectives, loading, error } = useFetchObjectives({
    objectives,
    input: {
      objectiveIds: filteredObjectiveIds,
      options: {
        attributeFilter,
        includeDirectParentObjectives: true,
      },
    },
    skip:
      Boolean(objectives) ||
      isEmpty(filteredObjectiveIds) ||
      !grouping.showObjectives ||
      (loadOnExpand && !isObjectivesExpanded),
  });

  const [removeObjectiveFromGroup] = useMutation(removeAssociatedGroup);

  const removeObjective = async (targetId) => {
    setIsUpdating(true);

    try {
      const result = await removeObjectiveFromGroup({
        variables: {
          groupId,
          targetId,
          allocationProjectId,
        },
      });

      const removedId = get(result, "data.group.id");
      if (!removedId) {
        // This should never happen
        return;
      }
      const current = get(group, "objectiveIds");
      const linkedStrategies = get(group, "linkedStrategies");

      dispatch({
        type: ACTIONS.LINKED_OBJECTIVES_UPDATE,
        groupId,
        objectiveIds: filter(current, (id) => id !== removedId),
        linkedStrategies: filter(linkedStrategies, (s) => s.id !== removedId),
      });
    } finally {
      setIsUpdating(false);
    }
  };

  if (
    error ||
    !grouping.showObjectives ||
    ((!canEditObjectives ||
      (!rootGroup.isDemand && !rootGroup.isLineOfBusiness)) &&
      isEmpty(objectiveIds))
  ) {
    // exit early if there are no objectives and we're not supposed to add any either.
    return null;
  }

  return (
    <>
      <ObjectivesExpandHeader
        loading={loading}
        isObjectivesExpanded={isObjectivesExpanded}
        grouping={grouping}
        dispatch={dispatch}
        inlineStyle={inlineStyle}
      />
      {isObjectivesExpanded && (
        <ObjectivesBody
          grouping={grouping}
          loading={loading}
          objectives={sortedObjectives}
          dispatch={dispatch}
          removeObjective={removeObjective}
          isUpdating={isUpdating}
          activeAllocationProject={activeAllocationProject}
          inlineStyle={inlineStyle}
        />
      )}
    </>
  );
};

Objectives.propTypes = {
  isObjectivesExpanded: PropTypes.bool,
  inlineStyle: PropTypes.bool,
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  activeAllocationProject: PropTypes.object.isRequired,
  loadOnExpand: PropTypes.bool,
};

export default Objectives;
