import React, { useRef } from "react";
import { Flex, FlexItem, Box, TextInput } from "orcs-design-system";
import styled from "styled-components";
import PropTypes from "prop-types";
import GroupHierarchyQueryBreadcrumb from "src/components/GroupHierarchyBreadcrumb/GroupHierarchyQueryBreadcrumb";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import ExpandButton from "src/components/ExpandButton";

const NumberInput = styled(TextInput)`
  width: 50px;
  padding: 5px 8px 6px 8px;
`;

const SelectedChapter = ({
  group,
  handleInputChange,
  isPlannerPage,
  expandToGroup,
  disableDecimal = false,
}) => {
  const btnRef = useRef();

  const onInputChange = (e) => {
    const { name: id, value: fte } = e.target;
    handleInputChange({ id, fte });
  };

  const expandGroup = () => {
    expandToGroup([...(group.hierarchyIds || [])]);

    // Blur clicked button
    setTimeout(() => {
      if (btnRef && btnRef.current) {
        btnRef.current.blur();
      }
    }, 0);
  };

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        mb="s"
        flexWrap="wrap"
      >
        <FlexItem flex="0 0 auto" mr="s">
          <ExpandButton
            popoverText="Show in tree hierarchy"
            clickHandler={expandGroup}
            btnRef={btnRef}
          />
        </FlexItem>
        {isPlannerPage && (
          <FlexItem flex="0 0 auto" mr="s">
            <NumberInput
              height="32px"
              placeholder="0"
              defaultValue="1"
              onChange={onInputChange}
              name={group.id}
              ariaLabel="FTE value"
              numberProps={{
                allowNegative: false,
                ...(disableDecimal && {
                  decimalScale: 0,
                }),
              }}
            />
          </FlexItem>
        )}
        <FlexItem flex="1 1" mr="s">
          <GroupHierarchyQueryBreadcrumb group={group} showTeamLinkWithIcon />
        </FlexItem>
        <FlexItem ml="auto" flex="0 0 auto">
          <GroupTypeBadge group={group} />
        </FlexItem>
      </Flex>
    </Box>
  );
};

SelectedChapter.propTypes = {
  group: PropTypes.object,
  handleInputChange: PropTypes.func,
  isPlannerPage: PropTypes.bool,
  expandToGroup: PropTypes.func,
  disableDecimal: PropTypes.bool,
};

export default SelectedChapter;
