import {
  forEach,
  map,
  groupBy,
  entries,
  sumBy,
  filter,
  uniq,
  set,
} from "lodash";
import { roundFte } from "src/util/roundingStrategy";
import { buildInitialDisplay } from "./display";

const sumFte = (individualAllocations = []) => {
  return roundFte(sumBy(individualAllocations, "fte"));
};

export default ({
  model: modelArg,
  personId,
  individualAllocations: newIndividualAllocations,
  personFilter,
  personSearch,
  targetGroupSearch,
}) => {
  const { groupLookup, targetGroupLookup, memberLookup, mainData } = modelArg;
  const { individualAllocations: existingIndividualAllocations } = mainData;

  // replace existing individualAllocations with updated changes.
  const individualAllocations = [
    ...filter(existingIndividualAllocations, (ia) => ia.personId !== personId),
    ...newIndividualAllocations,
  ];

  const newMainData = { ...mainData, individualAllocations };
  const model = { ...modelArg, mainData: newMainData };

  // rebuild groupLookup & targetGroupLookup
  forEach(
    entries(groupBy(newIndividualAllocations, "targetGroupId")),
    ([targetGroupId, newAllocationsForTargetGroup]) => {
      const { targetGroup } = newAllocationsForTargetGroup[0];
      if (!groupLookup[targetGroupId]) {
        // a new group
        groupLookup[targetGroupId] = targetGroup;
      }
      if (!targetGroupLookup[targetGroupId]) {
        const newTargetGroup = {
          groupId: targetGroupId,
          group: targetGroup,
          tags: [],
          currentlyAllocatedFte: sumFte(newAllocationsForTargetGroup),
          targetAllocationFte: 0,
        };
        if (newTargetGroup.currentlyAllocatedFte !== 0) {
          targetGroupLookup[targetGroupId] = newTargetGroup;
        }
      } else {
        // an existing group
        const existingTargetGroup = targetGroupLookup[targetGroupId];
        const individualAllocationsForTargetGroup = filter(
          individualAllocations,
          (a) => a.targetGroupId === targetGroupId
        );
        existingTargetGroup.currentlyAllocatedFte = sumFte(
          individualAllocationsForTargetGroup
        );
        if (
          existingTargetGroup.currentlyAllocatedFte === 0 &&
          existingTargetGroup.targetAllocationFte === 0
        ) {
          delete targetGroupLookup[targetGroupId];
        }
      }
    }
  );

  // set new member allocation details
  const existingMember = memberLookup[personId];
  const allocations = map(
    // overwrite
    newIndividualAllocations,
    (allocation) => ({
      ...allocation,
      personId,
      targetGroup: groupLookup[allocation.targetGroupId],
    })
  );
  set(memberLookup, personId, {
    ...existingMember,
    allocations,
    teamNames: uniq(map(allocations, "targetGroup.name")),
    remainingFte: roundFte(existingMember.person.fte - sumFte(allocations)),
  });

  return {
    ...model,
    display: buildInitialDisplay({
      model,
      personFilter,
      personSearch,
      targetGroupSearch,
    }),
  };
};
