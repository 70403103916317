import PropTypes from "prop-types";

export const GroupAttribute = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  subType: PropTypes.string,
});

export const FormGroupAttribute = PropTypes.shape({
  formAttributeId: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  subType: PropTypes.string,
});
