import icon from "src/config/icons";

const BOLD = "bold";
const ITALIC = "italic";
const UNDERLINE = "underline";
const CODE = "code";
const BULLETED_LIST = "bulleted-list";
const NUMBERED_LIST = "numbered-list";

export const LIST_TYPES = [BULLETED_LIST, NUMBERED_LIST];
export const LinkBtn = ["link", icon.link];
export const MarkBtns = [
  [BOLD, icon.bold],
  [ITALIC, icon.italic],
  [UNDERLINE, icon.underline],
];
export const BlockBtns = [
  [BULLETED_LIST, icon.bulletList],
  [NUMBERED_LIST, icon.orderedList],
];

/**
 * Tags to blocks.
 *
 * @type {Object}
 */
export const BLOCK_TAGS = {
  p: "paragraph",
  li: "list-item",
  ul: "bulleted-list",
  ol: "numbered-list",
  blockquote: "quote",
  h1: "heading-one",
  h2: "heading-two",
  link: "link",
};

/**
 * Tags to marks.
 *
 * @type {Object}
 */
export const MARK_TAGS = {
  strong: BOLD,
  em: ITALIC,
  u: UNDERLINE,
  code: CODE,
};

/**
 * Tags to inline elems.
 *
 * @type {Object}
 */
export const INLINE_TAGS = {
  mention: "mention",
};
