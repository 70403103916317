import { find, includes, isEmpty } from "lodash";

const isObjectiveSelectable = ({ groupTypes, targetGroup, option }) => {
  const types = groupTypes?.[targetGroup?.type]?.planning?.objectiveTypes || [];
  if (isEmpty(types)) {
    return true;
  }
  const typeAttribute = find(option?.attributes, { attributeType: "type" });
  return includes(types, typeAttribute?.value);
};

export { isObjectiveSelectable };
