import { Flex, Small, Spacer, Badge } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const AllocationResult = ({
  isLoading,
  allocatedFte,
  personFte,
  remainingFte,
}) => {
  if (isLoading) {
    return null;
  }

  let badge = null;
  if (allocatedFte > personFte) {
    badge = <Badge variant="danger">Over-allocated</Badge>;
  } else if (allocatedFte === personFte) {
    badge = <Badge variant="success">Fully allocated</Badge>;
  } else if (allocatedFte < personFte) {
    badge = (
      <Badge variant="warning">{remainingFte} FTE Remaining capacity</Badge>
    );
  }

  return (
    <Flex alignItems="center" justifyContent="flex-end" width="100%" mt="s">
      <Spacer ml="s">
        <Small color="greyDark" weight="bold">
          ALLOCATED TOTAL:
        </Small>
        <Badge>{allocatedFte} FTE</Badge>
        {badge}
      </Spacer>
    </Flex>
  );
};

AllocationResult.propTypes = {
  isLoading: PropTypes.bool,
  allocatedFte: PropTypes.number,
  personFte: PropTypes.number,
  remainingFte: PropTypes.number,
};

export default AllocationResult;
