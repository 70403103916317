import React from "react";
import { map, filter } from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Badge, Flex, Button, Icon, Spacer, Popover } from "orcs-design-system";
import PersonPropType from "src/custom-prop-types/person";
import IndividualAllocationPropType from "src/custom-prop-types/individualAllocation";
import RefPropType from "src/custom-prop-types/refPropType";

import { roundFte } from "src/util/roundingStrategy";
import PersonLink from "src/components/PersonLink";
import AllocationStatusIcon from "src/allocation/components/AllocationStatusIcon";
import { TAG_RESOURCEAVAILABILITY } from "src/consts/tags";

const CustomBadge = styled(Badge)`
  white-space: pre-line;
  word-break: break-word;
  text-align: left;
`;

const CustomButton = styled(Button)`
  cursor: ${(props) => (props.isDragging ? "grabbing" : "grab")};
  user-select: none;
`;

const CustomPersonLink = styled(PersonLink)`
  font-weight: 600;
`;

const PersonAllocationDetails = ({
  person,
  allocations,
  city,
  onAllocate,
  isDragging,
  drag,
}) => {
  const { bands, fte, remainingFte, tags } = person;

  const renderTeamFteBadges = (teamAllocations) =>
    map(teamAllocations, ({ fte: teamFte, targetGroup }) => {
      const { name, id } = targetGroup;
      return (
        <CustomBadge variant="success" key={id}>
          {name} ({roundFte(teamFte)} FTE)
        </CustomBadge>
      );
    });
  const availabilityTags = filter(tags, ["type", TAG_RESOURCEAVAILABILITY]);

  const buttonLabel = "Press and hold to drag person to a team";

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        mb="xs"
      >
        <Flex alignItems="center">
          <Spacer mr="xs">
            <CustomPersonLink person={person} />
            <AllocationStatusIcon
              remainingFte={remainingFte}
              popoverDirection="right"
            />
          </Spacer>
        </Flex>
        <Flex alignItems="center" justifyContent="flex-end">
          <Button iconLeft small onClick={onAllocate}>
            <Icon icon={["far", "edit"]} />
            Edit
          </Button>
          <Popover text={buttonLabel} width="fit-content" direction="top">
            <CustomButton
              mx="xs"
              variant="ghost"
              iconOnly
              height="25px"
              width="25px"
              small
              isDragging={isDragging}
              ref={drag}
              ariaLabel={buttonLabel}
            >
              <Icon icon={["far", "arrows-alt"]} />
            </CustomButton>
          </Popover>
        </Flex>
      </Flex>
      <Flex flexWrap="wrap" alignItems="center">
        <Spacer mr="xs" mt="xs">
          {availabilityTags &&
            map(availabilityTags, (tag) => (
              <Badge key={tag.id} variant="primaryLight">
                {tag.label}
              </Badge>
            ))}
          <Badge>{fte} FTE</Badge>
          {bands && map(bands, (band) => <Badge key={band}>{band}</Badge>)}
          {city ? <CustomBadge>{city}</CustomBadge> : null}
          {renderTeamFteBadges(allocations)}
        </Spacer>
      </Flex>
    </>
  );
};

const MemoedPersonAllocationDetails = React.memo(PersonAllocationDetails);

PersonAllocationDetails.displayName = "PersonAllocationDetails";

PersonAllocationDetails.propTypes = {
  person: PersonPropType.isRequired,
  allocations: PropTypes.arrayOf(IndividualAllocationPropType),
  city: PropTypes.string,
  onAllocate: PropTypes.func,
  isDragging: PropTypes.bool,
  drag: RefPropType,
};

export default MemoedPersonAllocationDetails;
