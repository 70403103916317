import React from "react";
import PropTypes from "prop-types";
import { Divider, Icon } from "orcs-design-system";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import ThemeSwitchToggle from "src/components/Nav/ThemeSwitchToggleV2";

const LogoutButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: ${themeGet("radii.2")};
  gap: ${themeGet("space.s")};
  padding: ${themeGet("space.xs")} ${themeGet("space.s")};
  font-size: ${themeGet("fontSizes.1")};
  cursor: pointer;
  color: ${themeGet("colors.greyDarkest")};
  font-family: ${themeGet("fonts.main")};
  font-weight: ${themeGet("fontWeights.2")};
  &:hover, &:focus {
  background-color: ${themeGet("colors.primaryLightest")};
`;

const UserMenuContent = ({ themeSwitcher, logout }) => {
  return (
    <>
      <ThemeSwitchToggle themeSwitcher={themeSwitcher} />
      <Divider light />
      <LogoutButton onClick={logout}>
        <Icon icon={["fas", "lock"]} />
        Logout
      </LogoutButton>
    </>
  );
};

UserMenuContent.propTypes = {
  themeSwitcher: PropTypes.func,
  logout: PropTypes.func,
};

export default UserMenuContent;
