import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Flex, FlexItem, Icon, Small } from "orcs-design-system";

const HistoryRecord = ({ content, icon, timestamp }) => {
  const recordDate = moment(timestamp);

  return (
    <Flex alignItems="center" py="s">
      <Icon icon={icon} mr="s" />
      <Flex alignItems="center" flex="1 1 auto" flexWrap="wrap">
        <FlexItem flex="1 1 auto" mr="s">
          {content}
        </FlexItem>
        <FlexItem flex="0 0 auto">
          <Small fontStyle="italic" ml="auto" color="greyDark">
            {recordDate.fromNow()} - {recordDate.format("Do MMMM YYYY")}
          </Small>
        </FlexItem>
      </Flex>
    </Flex>
  );
};

HistoryRecord.propTypes = {
  content: PropTypes.node,
  timestamp: PropTypes.string,
  icon: PropTypes.array,
};

export default React.memo(HistoryRecord);
