import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { sizing } from "../Unsplash.config";
import {
  MasonryAuthor,
  MasonryCardContainer,
  MasonryImage,
  MasonryLabel,
} from "../Unsplash.styled";

const MasonryCard = ({ data, onClick, selected }) => {
  const altDescription = get(data, "alt_description");
  const imgSmall = get(data, "urls.thumb");
  const author = get(data, "user.name");
  const imgColour = get(data, "color");
  const imageRatio = get(data, "width", 1) / get(data, "height", 1);
  const cardHeight = sizing.CARD_WIDTH / imageRatio;
  const imgId = get(data, "id");
  const isSelected = selected === imgId;
  const attributionQueryParam = `?utm_source=orchestrated&utm_medium=referral`;
  const authorAttributionLink =
    get(data, "user.links.html") + attributionQueryParam;

  return (
    <MasonryCardContainer
      type="button"
      aria-pressed={isSelected}
      data-testid={`cp-unsplash-masonry-card-${imgId}`}
      onClick={onClick}
      color={imgColour}
      width={sizing.CARD_WIDTH}
      height={cardHeight}
      isSelected={isSelected}
    >
      <MasonryImage
        data-testid={`cp-unsplash-masonry-img-${imgId}`}
        src={imgSmall}
        alt={altDescription}
      />
      <MasonryLabel py={2} height={sizing.CARD_LABEL_HEIGHT}>
        Photo by:&nbsp;
        <MasonryAuthor
          href={authorAttributionLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {author}
        </MasonryAuthor>
      </MasonryLabel>
    </MasonryCardContainer>
  );
};

MasonryCard.propTypes = {
  selected: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
};

export default MasonryCard;
