import { useQuery } from "@apollo/client";
import { get, filter, map, isEmpty, reject, includes } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { TAGS_UI_COMPONENTS } from "src/consts/tags";

// import { setupTagsFontAwesome } from "../../setupFontAwesome";

import { tagTypeConfigsForEntityType as tagTypeConfigsForEntityTypeQuery } from "./queries/tags.graphql";
import TagSelectorContainer from "./sub-components/TagSelectorContainer";
import TagsEditor from "./TagsEditor";
import useBulkAddTag from "./useBulkAddTag";
import useBulkRemoveTag from "./useBulkRemoveTag";

const BulkTagsEditorContainer = ({
  entities,
  entityType,
  onEntityTagClick,
  readOnly,
  tagTypesFilter,
  withAppliedCount,
  comments: commentsMetadata,
  groupType,
  mt,
  existingTags,
  onTagSelect,
  onTagRemove,
  tagsVisibleIn = TAGS_UI_COMPONENTS.DIRECTORY,
}) => {
  const { data: tagTypesConfigData, loading } = useQuery(
    tagTypeConfigsForEntityTypeQuery,
    {
      variables: {
        entityType,
        ...(tagsVisibleIn && { visibleIn: tagsVisibleIn }),
        ...(groupType && { groupType }),
      },
    }
  );

  const tagTypesConfig = useMemo(() => {
    const configs = reject(
      get(tagTypesConfigData, "tagTypesConfig"),
      "isGroupAssociation"
    );

    if (isEmpty(tagTypesFilter)) {
      return configs;
    }

    return filter(configs, (type) => {
      return includes(tagTypesFilter, type.id);
    });
  }, [tagTypesConfigData, tagTypesFilter]);

  const tagTypes = map(tagTypesConfig, "id");

  const removeTag = useBulkRemoveTag({
    entityType,
    entities,
    commentsMetadata,
    tagTypes,
    onTagRemove,
  });

  const addTag = useBulkAddTag({
    entityType,
    entities,
    commentsMetadata,
    onTagSelect,
    onTagRemove,
  });

  return (
    <TagsEditor
      readOnly={readOnly}
      setNewTag={addTag}
      removeTag={removeTag}
      existingTags={existingTags}
      loading={loading}
      tagTypesConfig={tagTypesConfig}
      tagTypes={tagTypes}
      onEntityTagClick={onEntityTagClick}
      withAppliedCount={withAppliedCount}
      mt={mt}
      components={{ TagSelector: TagSelectorContainer }}
      editOnly={true}
      bulkTagMode={true}
      entityType={entityType}
    />
  );
};

BulkTagsEditorContainer.propTypes = {
  entities: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  onEntityTagClick: PropTypes.func,
  tagTypesFilter: PropTypes.array,
  comments: PropTypes.object,
  groupType: PropTypes.string,
  withAppliedCount: PropTypes.bool,
  mt: PropTypes.string,
  existingTags: PropTypes.array,
  onTagSelect: PropTypes.func,
  onTagRemove: PropTypes.func,
  tagsVisibleIn: PropTypes.string,
};

export default BulkTagsEditorContainer;
