import React from "react";
import PropTypes from "prop-types";
import { Box, P, Flex } from "orcs-design-system";

import GroupTypeBadge from "src/components/GroupTypeBadge";
import GroupAccessUnauthorizedError from "src/components/ErrorPage/GroupAccessUnauthorizedError";

const AllocationsUnavailable = ({
  group,
  allocationProject = {},
  allocationVisibility,
  isInvalidRoute,
}) => {
  if (allocationVisibility.isDeniedAccess)
    return <GroupAccessUnauthorizedError />;

  if (allocationVisibility.isAllocationsClosed) {
    const { allocationName } = allocationProject;
    const { type } = group;
    return (
      <Box
        bg="white"
        shadow="default"
        p="r"
        borderRadius="2"
        height="calc(100vh - 266px)"
      >
        <Box bg="greyLightest" p="r" boxBorder="default">
          <P textAlign="center">
            <strong>{allocationName}</strong>&nbsp;resource allocation is not
            enabled for this {type}.
          </P>
        </Box>
      </Box>
    );
  }

  if (isInvalidRoute)
    return (
      <Box
        bg="white"
        shadow="default"
        p="r"
        borderRadius="2"
        height="calc(100vh - 266px)"
      >
        <Box bg="greyLightest" p="r" boxBorder="default">
          <Flex alignItems="center">
            <P textAlign="center" mr="s">
              Not supported by this
            </P>
            <GroupTypeBadge group={group} />
          </Flex>
        </Box>
      </Box>
    );

  return null;
};

AllocationsUnavailable.propTypes = {
  group: PropTypes.object.isRequired,
  allocationVisibility: PropTypes.object.isRequired,
  allocationProject: PropTypes.object,
  isInvalidRoute: PropTypes.bool,
};

export default AllocationsUnavailable;
