import React, { useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { get, map, sortBy, over } from "lodash";
import PropTypes from "prop-types";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";

import {
  getIndividualAllocations,
  allocateIndividualAllocation,
  updateFteIndividualAllocation,
  unallocateIndividualAllocation,
} from "src/allocation/allocation.graphql";
import { getGroupName } from "src/allocation/util/group";

import { getIndividualAllocationsQueryUpdater } from "src/allocation/updateIndividualAllocationCache";
import IndividualPersonAllocation from "./IndividualPersonAllocation";

const PersonAllocationContainer = ({
  allocationProjectId,
  personId,
  personFte,
  onPersonAllocating,
  onPersonAllocated,
  onPersonUnallocated,
  onPersonAllocationUpdated,
  layoutFunction,
  withSearch,
  notifyStatus,
}) => {
  const {
    config: { allocation: allocationConfig },
  } = useWorkspace();

  const { error, data, loading, variables } = useQuery(
    getIndividualAllocations,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        personId,
        allocationProjectId,
      },
      skip: !personId || !allocationProjectId,
    }
  );

  const queryDateUpdater = getIndividualAllocationsQueryUpdater(variables);

  const onAllocateToNewGroupMutationOption = onPersonAllocated
    ? {
        update: over([queryDateUpdater, onPersonAllocated]),
      }
    : { update: queryDateUpdater };
  const unallocateIndividualAllocationMutationOption = onPersonUnallocated
    ? {
        update: over([queryDateUpdater, onPersonUnallocated]),
      }
    : { update: queryDateUpdater };
  const updateFteIndividualAllocationMutationOption = onPersonAllocationUpdated
    ? {
        update: over([queryDateUpdater, onPersonAllocationUpdated]),
      }
    : { update: queryDateUpdater };

  const [allocateToNewGroupMutation, { error: allocateToGroupMutationError }] =
    useMutation(
      allocateIndividualAllocation,
      onAllocateToNewGroupMutationOption
    );
  const [
    unallocateFromGroupMutation,
    { error: allocateFromGroupMutationError },
  ] = useMutation(
    unallocateIndividualAllocation,
    unallocateIndividualAllocationMutationOption
  );
  const [updateAllocationMutation, { error: updateAllocationMutationError }] =
    useMutation(
      updateFteIndividualAllocation,
      updateFteIndividualAllocationMutationOption
    );

  const onAllocateToNewGroup = useCallback(
    async (mutationArgs) => {
      if (onPersonAllocating) {
        await onPersonAllocating(true);
      }
      try {
        await allocateToNewGroupMutation(mutationArgs);
      } finally {
        // no catch, errors will be handled in state above
        if (onPersonAllocating) {
          await onPersonAllocating(false);
        }
      }
    },
    [onPersonAllocating, allocateToNewGroupMutation]
  );

  const onUnallocateFromGroup = useCallback(
    async (mutationArgs) => {
      if (onPersonAllocating) {
        await onPersonAllocating(true);
      }
      try {
        await unallocateFromGroupMutation(mutationArgs);
      } finally {
        // no catch, errors will be handled in state above
        if (onPersonAllocating) {
          await onPersonAllocating(false);
        }
      }
    },
    [onPersonAllocating, unallocateFromGroupMutation]
  );

  const onUpdateAllocation = useCallback(
    async (mutationArgs) => {
      if (onPersonAllocating) {
        await onPersonAllocating(true);
      }
      try {
        await updateAllocationMutation(mutationArgs);
      } finally {
        // no catch, errors will be handled in state above
        if (onPersonAllocating) {
          await onPersonAllocating(false);
        }
      }
    },
    [onPersonAllocating, updateAllocationMutation]
  );

  const anyError =
    allocateFromGroupMutationError || updateAllocationMutationError || error;

  const allocations = get(data, "individualAllocations", []);

  const sortedAllocations = sortBy(
    map(allocations, (allocation) => ({
      ...allocation,
      name: getGroupName(allocation.targetGroupHierarchy),
    })),
    "name"
  );

  return (
    <IndividualPersonAllocation
      error={anyError}
      allocationProjectId={allocationProjectId}
      isLoading={loading}
      onAllocateToNewGroup={onAllocateToNewGroup}
      onUpdateAllocation={onUpdateAllocation}
      onUnallocateFromGroup={onUnallocateFromGroup}
      allocations={sortedAllocations}
      personFte={personFte}
      personId={personId}
      allocationConfig={allocationConfig}
      layoutFunction={layoutFunction}
      withSearch={withSearch}
      notifyStatus={notifyStatus}
      allocateToGroupMutationError={allocateToGroupMutationError}
    />
  );
};

PersonAllocationContainer.propTypes = {
  allocationProjectId: PropTypes.string,
  personId: PropTypes.string,
  personFte: PropTypes.number,
  onPersonAllocating: PropTypes.func,
  onPersonAllocated: PropTypes.func,
  onPersonUnallocated: PropTypes.func,
  onPersonAllocationUpdated: PropTypes.func,
  layoutFunction: PropTypes.func,
  withSearch: PropTypes.bool,
  notifyStatus: PropTypes.func,
};

export default PersonAllocationContainer;
