import { filter } from "lodash";

const getRowFilter =
  ({ showChildTeams }) =>
  (row) => {
    if (showChildTeams) {
      return row.children;
    }
    return filter(row.children, (child) => child?.kind !== "team");
  };

export { getRowFilter };
