import React, { useCallback, useMemo, useState } from "react";
import { Modal, Box } from "orcs-design-system";
import { map } from "lodash";

import IndividualPersonAllocationContainer from "src/allocation/pages/PersonAllocationPage/IndividualPersonAllocationContainer";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import PersonMemberships from "src/components/PersonDetailPage/PersonMemberships";

import {
  ACTIONS,
  useUserInteraction,
  useModel,
  useAllocationProject,
  useDispatch,
} from "../context";
import ModalHeader from "./ModalHeader";

const IndividualAllocationModal = () => {
  const { enableFutureAllocationsUI } = useWorkspaceFeatureFlags();
  const dispatch = useDispatch();
  const {
    viewingAllocations: { isOpen, personId },
    targetGroupSearch,
    personSearch,
    personFilter,
  } = useUserInteraction();
  const allocationProject = useAllocationProject();
  const { memberLookup } = useModel();
  const member = memberLookup[personId] || {};
  const [isAllocating, setIsAllocating] = useState(false);

  const onAllocationsChanged = useCallback(
    (allocations) => {
      dispatch({
        type: ACTIONS.PERSON_ALLOCATIONS_UPDATED,
        individualAllocations: allocations,
        personId,
        targetGroupSearch,
        personSearch,
        personFilter,
      });
    },
    [dispatch, personId, targetGroupSearch, personSearch, personFilter]
  );

  const onPersonAllocationsChanged = useCallback(
    (_, { data }) => {
      const { individualAllocations } = data;
      onAllocationsChanged(individualAllocations);
    },
    [onAllocationsChanged]
  );

  const membershipsChanged = useCallback(
    (memberships) => {
      const allocations = map(memberships, (m) => {
        return {
          ...m,
          targetGroupId: m.groupId,
          targetGroup: m.group,
        };
      });
      onAllocationsChanged(allocations);
    },
    [onAllocationsChanged]
  );

  const onAllocating = useCallback(
    (yep) => {
      setIsAllocating(yep);
    },
    [setIsAllocating]
  );

  const onClose = useCallback(() => {
    if (!isAllocating) {
      dispatch({
        type: ACTIONS.CLOSE_PERSON_ALLOCATIONS,
      });
      dispatch({
        type: ACTIONS.MAIN_QUERY_REFRESH,
        backgroundRefresh: true,
      });
    }
  }, [dispatch, isAllocating]);

  return useMemo(() => {
    const { person } = member;
    if (!person) {
      return null;
    }

    if (enableFutureAllocationsUI) {
      const layout = (modalBody, viewToggle) => (
        <Modal
          visible={isOpen}
          maxWidth="90vw"
          maxHeight="90vh"
          width="70vw"
          height="60vh"
          onConfirm={() => {}}
          onClose={onClose}
          headerContent={
            <ModalHeader person={person} viewToggle={viewToggle} />
          }
        >
          <Box p="xxs" height="100%">
            {modalBody}
          </Box>
        </Modal>
      );

      return (
        <PersonMemberships
          key="memberships"
          person={person}
          onMembershipUpdating={onAllocating}
          onMembershipAdded={membershipsChanged}
          onMembershipRemoved={membershipsChanged}
          onMembershipFteUpdated={membershipsChanged}
          layoutFunction={layout}
        />
      );
    }

    const LegacyLayout = (...children) => (
      <Modal
        visible={isOpen}
        maxWidth="90vw"
        maxHeight="90vh"
        width="70vw"
        height="60vh"
        onConfirm={() => {}}
        onClose={onClose}
        headerContent={<ModalHeader person={person} />}
        footerContent={children[2]}
      >
        <Box p="xxs">
          {children[0]}
          {children[1]}
        </Box>
      </Modal>
    );

    return (
      <IndividualPersonAllocationContainer
        allocationProjectId={allocationProject.id}
        personId={person.aggregateId}
        personFte={person.fte}
        onPersonAllocating={onAllocating}
        onPersonAllocated={onPersonAllocationsChanged}
        onPersonUnallocated={onPersonAllocationsChanged}
        onPersonAllocationUpdated={onPersonAllocationsChanged}
        layoutFunction={LegacyLayout}
      />
    );
  }, [isOpen, personId, isAllocating]); // eslint-disable-line
};

export default IndividualAllocationModal;
