import PropTypes from "prop-types";
import React from "react";

import themeGet from "@styled-system/theme-get";
import styled from "styled-components";
import { TagMentionContextProvider } from "src/comments/components/RichTextEditor/plugins/mentions";
import Editor from "./sub-components/Editor";
import EditorWithMention from "./sub-components/EditorWithMentions";

const Label = styled.label`
  display: block;
  z-index: 2;
  text-align: left;
  font-size: 1.4rem;
  transition: ${themeGet("transition.transitionDefault")};
  padding-right: 12px;
  margin-bottom: ${themeGet("space.xs")};
`;

/**
 * Rich Text Editor using Slate
 *
 * @type {Component}
 */
const RichTextEditor = ({
  defaultValue,
  height,
  placeholder,
  isWithMentions = false,
  onTextChange,
  className,
  label,
  autoFocus,
}) => {
  return (
    <div className={className}>
      {label && <Label>{label}</Label>}
      {isWithMentions ? (
        <TagMentionContextProvider>
          <EditorWithMention
            data-testid="cp-rte-with-mention"
            defaultValue={defaultValue}
            height={height}
            placeholder={placeholder}
            onTextChange={onTextChange}
            autoFocus={autoFocus}
          />
        </TagMentionContextProvider>
      ) : (
        <Editor
          data-testid="cp-rte"
          defaultValue={defaultValue}
          height={height}
          placeholder={placeholder}
          onTextChange={onTextChange}
          autoFocus={autoFocus}
        />
      )}
    </div>
  );
};

RichTextEditor.propTypes = {
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  height: PropTypes.number,
  onTextChange: PropTypes.func,
  isWithMentions: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
};

RichTextEditor.defaultProps = {
  autoFocus: true,
};

export default RichTextEditor;
