import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";

import { ACTIONS } from "../../../context/ForecastContext";

const BulkDeallocationsButton = ({ grouping, dispatch }) => {
  const handleBulkDeallocation = useCallback(
    (e) => {
      e.stopPropagation();

      dispatch({
        type: ACTIONS.SHOW_BULK_DEALLOCATIONS_MODAL,
        grouping,
      });
    },
    [grouping, dispatch]
  );

  return (
    <ActionsMenuItem onClick={handleBulkDeallocation}>
      Deallocate members
    </ActionsMenuItem>
  );
};

BulkDeallocationsButton.propTypes = {
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default BulkDeallocationsButton;
