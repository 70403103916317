import { useQuery } from "@apollo/client";
import { get } from "lodash";
import { Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import { Switch, Route } from "react-router-dom";

import RestrictedRoute from "src/app/Restricted/Route";
import MatchPropType from "src/custom-prop-types/match";
import PersonPropType from "src/custom-prop-types/person";
import { getPersonQuery } from "src/queries/person.graphql";
import { throwIfRequired, throwNotFoundError } from "src/util/error";
import PATHS, { PERSON_TAB } from "src/consts/urlPaths";
import PersonTabs from "src/components/PersonDetailPage/PersonTabs";
import Restricted from "src/app/Restricted";
import PersonVisualisation from "src/components/PersonDetailPage/PersonVisualisation";
import PersonAllocationTabPage from "src/components/PersonDetailPage/PersonAllocationTabPage";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";

import UserDashboard from "src/components/PersonDetailPage/UserDashboard";
import PersonHistory from "src/components/PersonDetailPage/PersonHistory";
import PersonMemberships from "src/components/PersonDetailPage/PersonMemberships";
import PersonNotFound from "src/components/PersonDetailPage/PersonNotFound";
import { DIRECTORY_ROLE } from "src/services/auth";
import useUserRole from "../../app/Restricted/useUserRole";
import { useGlobalSearchContext } from "../../components/Search/GlobalSearchContext";
import PersonHeaderPanel from "./sub-components/PersonHeaderPanel";
import PersonDetailsTab from "./sub-components/PersonDetailsTab";

const withBasePath = (path) => `${PATHS.PERSON_DETAILS_BASE_PATH}/${path}`;
const withHomeBasePath = (path) => `${PATHS.USER_HOME_BASE_PATH}/${path}`;

const PersonDetailPageContainer = (props) => {
  const {
    match,
    workspace,
    featureFlags,
    showFte = false,
    userPersonId,
    userHomeMode,
  } = props;
  const { setDefaultSearchScope } = useGlobalSearchContext();
  const userRole = useUserRole();
  const { enableFutureAllocationsUI } = useWorkspaceFeatureFlags();
  const personId = userPersonId || match.params.id;

  const { data, loading, error } = useQuery(getPersonQuery, {
    variables: { aggregateId: personId },
    errorPolicy: "all",
    onCompleted: () => {
      setDefaultSearchScope(null);
    },
    skip: !personId,
  });

  throwIfRequired(error);

  const person = get(data, "person");
  const aggregateId = get(person, "aggregateId");
  const tagConfig = get(workspace, "config.tagConfig");

  if ((!person || !aggregateId) && !loading) {
    if (userRole === DIRECTORY_ROLE) {
      throw throwNotFoundError(`Person id not found: ${personId}`);
    }
    return <PersonNotFound />;
  }

  return (
    <>
      <Spacer mb="r">
        <PersonHeaderPanel
          loading={loading}
          person={person}
          personId={personId}
          tagConfig={tagConfig}
          showFte={showFte}
        />
      </Spacer>
      <Restricted>
        <Spacer mb="r">
          <PersonTabs userHomeMode={userHomeMode} person={person} />
        </Spacer>
      </Restricted>

      <Switch>
        <Route
          exact
          path={[
            withHomeBasePath(PERSON_TAB.DETAILS),
            withBasePath(PERSON_TAB.DETAILS),
          ]}
        >
          <PersonDetailsTab {...props} loading={loading} person={person} />
        </Route>

        <RestrictedRoute
          exact
          path={[
            withHomeBasePath(PERSON_TAB.VISUALISATION),
            withBasePath(PERSON_TAB.VISUALISATION),
          ]}
        >
          {person && (
            <PersonVisualisation
              history={history}
              person={person}
              match={match}
            />
          )}
        </RestrictedRoute>

        {!enableFutureAllocationsUI && (
          <RestrictedRoute
            exact
            path={[
              withHomeBasePath(PERSON_TAB.ALLOCATIONS),
              withBasePath(PERSON_TAB.ALLOCATIONS),
            ]}
          >
            {person && (
              <PersonAllocationTabPage
                person={person}
                featureFlags={featureFlags}
              />
            )}
          </RestrictedRoute>
        )}

        {enableFutureAllocationsUI && (
          <RestrictedRoute
            exact
            path={[
              withHomeBasePath(PERSON_TAB.ALLOCATIONS),
              withBasePath(PERSON_TAB.ALLOCATIONS),
            ]}
          >
            {person && (
              <PersonMemberships person={person} featureFlags={featureFlags} />
            )}
          </RestrictedRoute>
        )}

        <RestrictedRoute
          exact
          path={[withHomeBasePath(PERSON_TAB.USER_DASHBOARD)]}
        >
          {userHomeMode && person && <UserDashboard person={person} />}
        </RestrictedRoute>

        <RestrictedRoute
          exact
          path={[
            withHomeBasePath(PERSON_TAB.HISTORY),
            withBasePath(PERSON_TAB.HISTORY),
          ]}
        >
          {person && <PersonHistory person={person} />}
        </RestrictedRoute>
      </Switch>
    </>
  );
};

PersonDetailPageContainer.propTypes = {
  match: MatchPropType.isRequired,
  user: PropTypes.object,
  workspace: PropTypes.object,
  allocationProject: PropTypes.object,
  featureFlags: PropTypes.object,
  userPerson: PersonPropType,
  showExtra: PropTypes.bool,
  showFte: PropTypes.bool,
  dateSettings: PropTypes.object,
  userPersonId: PropTypes.string,
  tagsVisibleIn: PropTypes.string,
  userHomeMode: PropTypes.bool,
};

export default PersonDetailPageContainer;
