import React, { useCallback, useState } from "react";
import { Box } from "orcs-design-system";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import NodeVisualizer from "src/components/NodeVisualizer";
import { useOrgChartContext } from "../../context/OrgChartContext";
import { useOrgChartNodesAndEdges } from "../../hooks/useOrgChartNodesAndEdges";
import OrgChatPanel from "../OrgChartPanel";

const Container = styled(Box)`
  height: 800px;
`;

const TeamVisualiser = () => {
  const { data, team, loading, state } = useOrgChartContext();
  const [initialFitViewTriggered, setInitialFitViewTriggered] = useState(false);
  const location = useLocation();

  const { nodes, edges } = useOrgChartNodesAndEdges({ data, team, state });

  const afterLayout = useCallback(
    ({ reactFlow, nodes: layoutedNodes }) => {
      if (initialFitViewTriggered) return;

      setInitialFitViewTriggered(true);

      const currentViewTeam = layoutedNodes.filter((node) =>
        location.pathname.includes(node.id)
      );
      const anchoredNodes = layoutedNodes.filter((n) => n.isAnchor);

      reactFlow.fitView({
        padding: 0.1,
        nodes: currentViewTeam.length ? currentViewTeam : anchoredNodes,
        zoom: 0.8,
      });

      setTimeout(() => {
        reactFlow.setViewport({ zoom: 0.7, y: 10 }, { duration: 2000 });
      }, 500);
    },
    [initialFitViewTriggered, location]
  );

  return (
    <Container>
      <NodeVisualizer
        key={team?.id}
        loading={loading}
        initialNodes={nodes}
        initialEdges={edges}
        afterLayout={afterLayout}
        noWheelZoom
        animatedNodesEnabled
      >
        <OrgChatPanel />
      </NodeVisualizer>
    </Container>
  );
};

export default React.memo(TeamVisualiser);
