import PropTypes from "prop-types";
import React from "react";
import { Icon, Small, Spacer, Button, Flex } from "orcs-design-system";

import icon from "src/config/icons";
import { copywriting } from "../TeamEdit.config";

const ModalFooter = ({ onConfirm, isSaving, onCancel, onSaveError }) => {
  return (
    <Flex flexWrap="wrap" width="100%">
      <Spacer mr="s">
        <Button
          onClick={onConfirm}
          variant="success"
          iconLeft
          disabled={isSaving}
          isLoading={isSaving}
          data-testid="cp-teamedit-form-save-btn"
        >
          <Icon icon={icon.save} />
          Save
        </Button>
        <Button variant="ghost" onClick={onCancel} iconLeft>
          <Icon icon={icon.cancel} />
          Cancel
        </Button>
      </Spacer>
      {onSaveError && (
        <Small
          color="danger"
          fontWeight="bold"
          display="block"
          ml="auto"
          my="s"
        >
          <Icon icon={icon.exclamation} mr="xs" />
          {onSaveError}
        </Small>
      )}
      {isSaving && (
        <Small
          color="success"
          fontWeight="bold"
          display="block"
          ml="auto"
          my="s"
        >
          <Icon icon={icon.save} mr="xs" />
          {copywriting.FORM_SAVING}
        </Small>
      )}
    </Flex>
  );
};

ModalFooter.propTypes = {
  onConfirm: PropTypes.func,
  isSaving: PropTypes.bool,
  onCancel: PropTypes.func,
  onSaveError: PropTypes.string,
};

export default React.memo(ModalFooter);
