import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "orcs-design-system";

const HistoryTypeCheckBox = ({ type, label, checked, onFilterChange }) => {
  return (
    <Checkbox label={label} checked={checked} onChange={onFilterChange(type)} />
  );
};

HistoryTypeCheckBox.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onFilterChange: PropTypes.func,
};

export default React.memo(HistoryTypeCheckBox);
