import React from "react";
import PropTypes from "prop-types";
import { Badge, Flex, FlexItem, H2 } from "orcs-design-system";
import GroupPropType from "src/custom-prop-types/group";
import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";
import { roundFte } from "src/util/roundingStrategy";

const PeopleCardTitle = ({
  loading,
  totalCount,
  selectedSourceGroup,
  totalFte,
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      data-testid="people-list-title"
    >
      <H2 sizing="small" weight="light">
        {!loading ? totalCount : ""} People in{" "}
        <strong>
          <GroupHierarchyBreadcrumb
            showBreadcrumb={false}
            group={selectedSourceGroup}
            titleOnly={true}
          />
        </strong>
      </H2>
      <FlexItem flex="0 0 auto" ml="xs">
        <Badge>{loading ? "" : roundFte(totalFte)} FTE</Badge>
      </FlexItem>
    </Flex>
  );
};

PeopleCardTitle.propTypes = {
  loading: PropTypes.bool.isRequired,
  selectedSourceGroup: GroupPropType.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalFte: PropTypes.number.isRequired,
};

export default PeopleCardTitle;
