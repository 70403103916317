import { Handle } from "reactflow";
import styled, { css } from "styled-components";
import themeGet from "@styled-system/theme-get";
import { Badge, Button, Card } from "orcs-design-system";

export const EDGE_COLOR = "#b8bbbd";

const DEFAULT_CARD_THEME = {
  color: "greyLight",
  light: "grey",
  alt: "greyLight",
  animationVariant: "primary",
};

export const getVisibilityStyles = ({ $hidden, xPos, yPos }) => {
  const hidden = $hidden || (xPos === 0 && yPos === 0);

  return css`
    pointer-events: ${hidden ? "none" : "auto"} !important;
    opacity: ${hidden ? 0 : 1};
  `;
};

export const HIGHLIGHTED_ANIMATION = {
  DURATION: 700,
  REPETITIONS: 4,
};

export const StyledCard = styled(Card)((props) => {
  const {
    $theme = DEFAULT_CARD_THEME,
    active,
    width = "460px",
    minWidth = "300px",
    maxWidth = "500px",
    cursor = "pointer",
  } = props;
  const { animationVariant = DEFAULT_CARD_THEME.animationVariant } = $theme;
  const animationTiming = `${HIGHLIGHTED_ANIMATION.DURATION}ms ${HIGHLIGHTED_ANIMATION.REPETITIONS} linear`;

  return css`
    @property --rotate {
      syntax: "<angle>";
      initial-value: 132deg;
      inherits: false;
    }

    --border-size: 2px;
    --bg-color: #ffffff;
    --rotate: 0deg;
    position: relative;
    width: ${width};
    min-width: ${minWidth};
    max-width: ${maxWidth};
    cursor: ${cursor};
    border: solid ${active ? "4px" : "var(--border-size)"}
      ${themeGet(`colors.${active ? $theme?.light : $theme?.alt}`)};
    background: var(--bg-color);
    border-radius: ${themeGet("radii.2")} !important;
    box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.1);
    ${getVisibilityStyles(props)}
    z-index: 2;

    transition: all 1s;

    &.highlighted {
      border-width: 2px;
      overflow: hidden;
      animation: pulse ${animationTiming};
    }

    &.highlighted::before {
      content: "";
      width: 120%;
      aspect-ratio: 1 / 1;
      border-radius: ${themeGet("radii.2")};
      background-image: linear-gradient(
        var(--rotate),
        ${themeGet(`colors.${animationVariant}Lighter`)},
        ${themeGet(`colors.${animationVariant}Lightest`)},
        ${themeGet(`colors.white`)}
      );
      position: absolute;
      z-index: -1;
      top: -160%;
      left: -10%;
      animation: spin ${animationTiming};
    }

    &.highlighted::after {
      --offsetSize: calc(var(--border-size) + 1px);
      --size: calc(100% - var(--offsetSize) - var(--offsetSize));
      content: "";
      width: var(--size);
      height: var(--size);
      border-radius: ${themeGet("radii.2")};
      background: var(--bg-color);
      position: absolute;
      z-index: -1;
      top: var(--offsetSize);
      left: var(--offsetSize);
      animation: spin ${animationTiming};
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.04);
      }

      100% {
        transform: scale(1);
      }
    }

    @keyframes spin {
      0% {
        --rotate: 0deg;
      }
      100% {
        --rotate: 360deg;
      }
    }
  `;
});

export const StyledBadge = styled(Badge)`
  display: flex;
  min-width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  background: ${(props) => themeGet(`colors.${props.color}`)};
`;

const getHandleStyle = ({ $hidden, $yPosition }) => {
  if ($hidden) {
    return css`
      background: transparent;
      border: none;
    `;
  }

  return css`
    ${$yPosition && `top: ${$yPosition};`}

    &.triangle {
      width: 0px;
      height: 0px;
      border-radius: 0;
      background: transparent;
    }
  `;
};

export const TargetHandle = styled(Handle)(({ $hidden, $yPosition }) => {
  return css`
    &.react-flow__handle {
      ${getHandleStyle({ $hidden, $yPosition })}

      &.triangle {
        left: -20px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 18px solid ${EDGE_COLOR};
      }
    }
  `;
});

export const SourceHandle = styled(Handle)(({ $hidden, $yPosition }) => {
  return css`
    &.react-flow__handle {
      ${getHandleStyle({ $hidden, $yPosition })}

      &.triangle {
        right: -20px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 18px solid ${EDGE_COLOR};
      }
    }
  `;
});

export const StyledSimpleButton = styled(Button)`
  background: ${themeGet("colors.white")};
  border: 1px solid ${themeGet("colors.greyLighter")};
  color: ${themeGet("colors.greyDarker")};
  &:hover:not(:disabled) {
    background: ${themeGet("colors.primaryLightest")};
    color: ${themeGet("colors.greyDarker")};
    border: 1px solid ${themeGet("colors.greyLighter")};
  }
  &:hover:disabled {
    background: white;
    color: ${themeGet("colors.greyDarker")};
    border: 1px solid ${themeGet("colors.greyLighter")};
    cursor: pointer;
  }
`;
