import React from "react";
import PropTypes from "prop-types";
import { includes } from "lodash";
import { Checkbox, Flex, Popover } from "orcs-design-system";
// todo: move this some place central/sensible
import ObjectiveAttributeBadges from "src/allocation/pages/ForecastPage/components/PlannerGroupings/Objectives/ObjectiveAttributeBadges";
import ObjectiveStatusBadge from "src/pages/TeamPage/sub-components/TeamDetailsPanel/Objectives/ObjectiveStatusBadge";
import { NameWithExternalUrl } from "src/components/TeamStrategiesTable/components/NameWithExternalUrl";
import { isObjectiveSelectable } from "./utils/isObjectiveSelectable";

const ObjectiveCheckbox = ({
  option,
  currentSelect,
  objectiveIds,
  isUpdating,
  handleObjectiveSelect,
  groupTypes,
  targetGroup,
  showStatusBadge,
  hideExternalUrl,
}) => {
  const { id } = option;
  const checked = includes(currentSelect, id);
  const existing = includes(objectiveIds, id);
  const colour = checked ? (existing ? "" : "success") : "";
  const isSelectable = isObjectiveSelectable({
    groupTypes,
    targetGroup,
    option,
  });

  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      my="s"
    >
      <NameWithExternalUrl
        objective={option}
        showExternalUrl={!hideExternalUrl}
      >
        <Popover
          width="fit-content"
          text={
            !isSelectable
              ? "This team type can not be linked to this strategy type"
              : ""
          }
        >
          <Checkbox
            label={option.name}
            checked={checked}
            colour={colour}
            disabled={
              isUpdating ||
              !isSelectable ||
              includes(targetGroup?.objectiveIds, id)
            }
            onChange={(e) => {
              e.stopPropagation();
              handleObjectiveSelect(option)(e);
            }}
            fontSize="1.2rem"
          />
        </Popover>
      </NameWithExternalUrl>
      <Flex
        flexWrap="wrap"
        alignItems="flex-end"
        flex="0 0 auto"
        flexDirection={showStatusBadge ? "row" : "column"}
        ml="auto"
      >
        {showStatusBadge && <ObjectiveStatusBadge objective={option} />}
        <ObjectiveAttributeBadges objective={option} />
      </Flex>
    </Flex>
  );
};

ObjectiveCheckbox.propTypes = {
  option: PropTypes.object,
  currentSelect: PropTypes.array,
  objectiveIds: PropTypes.array,
  isUpdating: PropTypes.bool,
  handleObjectiveSelect: PropTypes.func,
  groupTypes: PropTypes.object,
  targetGroup: PropTypes.object,
  showStatusBadge: PropTypes.bool,
  hideExternalUrl: PropTypes.bool,
};

export default ObjectiveCheckbox;
