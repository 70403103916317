import { saveAs } from "file-saver";
import { get, pickBy, reduce, find } from "lodash";
import {
  preparePersonData,
  prepareTeamData,
} from "../../../util/prepareVisualisationData";
import { NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION } from "../../../util/buildNodes";

export const handleChartDownload = (chart) => {
  const dataURItoBlob = (dataURI) => {
    // convert base64 to raw binary data
    const byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob
    const arrToBlob = new Blob([arrayBuffer], { type: mimeString });
    return arrToBlob;
  };

  chart.current
    .image({
      fit: "chart",
      height: 4000, // TODO: We could dynamically calculate more reasonable values here
      width: 4000,
    })
    .then((image) => saveAs(dataURItoBlob(image), "visualisation.png"));
};

export const getFormattedNodes = (targetNode, nodes) => {
  // The targetNode and nodes do not update in tandem. If a user selects a new targetNode via
  // the people search-bar, the targetNode will change, while the nodes will remain unchanged until
  // the nodes for the new targetNode are recieved from useQuery. Thus we must handle the case
  // where the targetNode doesn't exist in the nodes list.
  if (find(nodes, { id: targetNode.id }) === undefined) {
    return [];
  }

  let formattedNodes = [];

  if (targetNode.type === "person") {
    formattedNodes = preparePersonData(nodes, targetNode.id);
  } else if (targetNode.type === "team" || targetNode.type === "sourceTeam") {
    formattedNodes = prepareTeamData(nodes, targetNode.id);
  }
  return formattedNodes;
};

const pickItem = (item, settings, nonGroupAssociationIds) => {
  if (!item.type) {
    return true;
  }
  if (item.type !== "node") {
    return !(
      item.type === "link" &&
      item.relationshipType === NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION &&
      !settings.groupAssociation
    );
  }

  const itemDataType = get(item, "data.type");
  if (!itemDataType) {
    return true;
  }

  const itemDataTypeLower = itemDataType.toLowerCase();
  if (
    (itemDataTypeLower === "team" || itemDataTypeLower === "sourceteam") &&
    (settings.groupAssociation || nonGroupAssociationIds[item.id])
  ) {
    return true;
  }
  return settings[itemDataTypeLower];
};

export const pickItems = (data, settings) => {
  // an object that has only the ids that appear in relationships that aren't group associations
  const nonGroupAssociationIds = reduce(
    data,
    (ids, item) => {
      if (
        item.type === "link" &&
        item.relationshipType !== NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION
      ) {
        // eslint-disable-next-line no-param-reassign
        ids[item.id1] = true;
        // eslint-disable-next-line no-param-reassign
        ids[item.id2] = true;
      }
      return ids;
    },
    {}
  );
  return settings
    ? pickBy(data, (item) => pickItem(item, settings, nonGroupAssociationIds))
    : data;
};
