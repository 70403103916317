import React from "react";
import PropTypes from "prop-types";
import ObjectiveProgressBar from "src/components/ObjectiveProgressBar";

const ProgressCell = ({ row }) => {
  return (
    <ObjectiveProgressBar objective={row?.original} containerWidth={100} />
  );
};

ProgressCell.propTypes = {
  row: PropTypes.object,
};

export default ProgressCell;
