import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Flex, Box } from "orcs-design-system";

import { ACTIONS } from "../../../context/ForecastContext";
import AnimatedExpandIcon from "../../Shared/AnimatedExpandIcon";

import LineItemGroupHierarchy from "./LineItemGroupHierarchy";

const IconWrapper = styled.div`
  padding: 5px 10px 5px 13px;
`;

const LineItemLeftControls = ({
  lineItem,
  isMembersExpanded,
  isSupply,
  isRootGroupSupply,
  dispatch,
  withMembersExpandIcon = false,
}) => {
  const { id: lineItemId, group } = lineItem;

  const hierarchy = (
    <LineItemGroupHierarchy
      group={group}
      isSupply={isSupply}
      isRootGroupSupply={isRootGroupSupply}
    />
  );

  if (!withMembersExpandIcon) {
    return <Box maxWidth="550px">{hierarchy}</Box>;
  }

  const handleToggle = () => {
    dispatch({
      type: ACTIONS.TOGGLE_MEMBERS_EXPANSION,
      lineItemId,
    });
  };

  return (
    <Flex alignItems="center" maxWidth="550px">
      <IconWrapper onClick={handleToggle}>
        <AnimatedExpandIcon isOpen={isMembersExpanded} />
      </IconWrapper>
      {hierarchy}
    </Flex>
  );
};

LineItemLeftControls.propTypes = {
  lineItem: PropTypes.object.isRequired,
  isMembersExpanded: PropTypes.bool,
  isSupply: PropTypes.bool.isRequired,
  isRootGroupSupply: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  withMembersExpandIcon: PropTypes.bool,
};

export default LineItemLeftControls;
