import React from "react";
import PropTypes from "prop-types";

import GroupHierarchyQueryBreadcrumb from "src/components/GroupHierarchyBreadcrumb/GroupHierarchyQueryBreadcrumb";
import { useAllocationConfig } from "src/contexts/global/WorkspaceContext/WorkspaceContext";

import GroupNameWithHierarchy from "../../Shared/GroupNameWithHierarchy";

const LineItemGroupHierarchy = ({ group, isRootGroupSupply }) => {
  const { switchToLoadSupplyGroups } = useAllocationConfig();

  if (switchToLoadSupplyGroups && !isRootGroupSupply) {
    return <GroupNameWithHierarchy group={group} />;
  }

  return (
    <GroupHierarchyQueryBreadcrumb
      group={group}
      showGroupTypeBadge
      showGroupTypeBadgeShowToolTip
      groupTypeBadgeToolTipPosition="right"
    />
  );
};

LineItemGroupHierarchy.propTypes = {
  group: PropTypes.object.isRequired,
  isRootGroupSupply: PropTypes.bool,
};

export default LineItemGroupHierarchy;
