/* eslint-disable no-param-reassign */

import { forEach, isEmpty, isNil } from "lodash";
import { roundFte } from "src/util/roundingStrategy";
import { hashGrouping, setGroupingWithCalculatedSumTotals } from "./util";

export const setGroupingTotalFteSum = (grouping, columns) => {
  const { childGroupings, lineItems } = grouping;

  const summedTotalFtes = new Array(columns.length).fill(0);
  const lineItemTotalFtes = new Array(columns.length).fill(0);

  forEach(childGroupings, (childGrouping) => {
    const childrenGroupingTotals = setGroupingTotalFteSum(
      childGrouping,
      columns
    );

    forEach(columns, (column, index) => {
      const totalFteSum = childrenGroupingTotals[index];
      summedTotalFtes[index] += totalFteSum;
    });
  });

  forEach(lineItems, (lineItem) => {
    const { cells } = lineItem;

    forEach(cells, (cell, index) => {
      const { currentFteSum } = cell;
      const fteSum = isNil(currentFteSum) ? 0 : currentFteSum;

      lineItemTotalFtes[index] += fteSum;
      summedTotalFtes[index] += fteSum;
    });
  });

  grouping.summedTotalFtes = summedTotalFtes;
  grouping.lineItemTotalFtes = lineItemTotalFtes;

  return summedTotalFtes;
};

const updateIntersectingFteSumForDateRanges = (state, action) => {
  const { dynamicModel } = state;
  const {
    columns,
    groupings,
    lookups: { cellLookup },
  } = dynamicModel;

  const { dataForRanges, enableForecastPrefillWithIntersectingFte } = action;

  if (isEmpty(dataForRanges) || !cellLookup) {
    return state;
  }

  forEach(dataForRanges, (data) => {
    const { id, fte, members } = data;

    const cellId = `macro-allocation-${id}`;
    const cell = cellLookup[cellId];

    if (!cell) {
      return;
    }

    cell.currentFteSum = roundFte(fte);

    // Add members inside the date range
    cell.members = members;

    // If flag enabled, use current fte sum as defaultedValue
    if (
      enableForecastPrefillWithIntersectingFte &&
      cell.defaultedValue !== cell.currentFteSum
    ) {
      cell.defaultedValue = cell.currentFteSum;
    }
  });

  forEach(groupings, (grouping) => {
    setGroupingTotalFteSum(grouping, columns);

    if (enableForecastPrefillWithIntersectingFte) {
      setGroupingWithCalculatedSumTotals(grouping, columns);
    }

    hashGrouping(grouping);
  });

  return {
    ...state,
  };
};

export default updateIntersectingFteSumForDateRanges;

/* eslint-enable no-param-reassign */
