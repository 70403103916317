import { Small, Toggle } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import useThemeSwitching from "src/hooks/useThemeSwitching";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";

const ThemeSwitchToggle = ({ themeSwitcher }) => {
  const { isCompactTheme, onToggleTheme } = useThemeSwitching();
  const onSpacingChange = ({ target }) => {
    const switchToCompact = !isCompactTheme;
    onToggleTheme(switchToCompact);
    themeSwitcher(switchToCompact);
    trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
      setting: "theme",
      value: target.value,
    });
  };

  return (
    <>
      <Small fontWeight="600" fontSize="0">
        Compact theme
      </Small>
      <Toggle
        id="compactTheme"
        label={isCompactTheme ? "On" : "Off"}
        small
        checked={isCompactTheme}
        onChange={onSpacingChange}
      />
    </>
  );
};

ThemeSwitchToggle.propTypes = {
  themeSwitcher: PropTypes.func,
};

export default React.memo(ThemeSwitchToggle);
