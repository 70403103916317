/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Icon, H3, Flex, FlexItem, Toggle } from "orcs-design-system";

import FancyCard, {
  FancyCardHeader,
  FancyCardBody,
  FancyCardFooter,
} from "src/components/FancyCard/FancyCard";
import GroupPropType from "src/custom-prop-types/group";
import TreeView from "src/allocation/components/TreeView";
import GroupStatus from "src/allocation/components/GroupStatus";
import GroupResponse from "src/allocation/components/GroupResponse";
import { RequestAllocationContext } from "src/allocation/contexts/requestAllocationContext";

import ToggleButton from "../../../ToggleButton";
import createTreeStructure from "./createTreeStructure";

const NodeStatus = ({ node }) => {
  return (
    <>
      <GroupStatus
        macroAllocations={node.macroAllocations}
        statusField="requestStatus"
        directionRight={node.isParent}
      />
      <GroupResponse
        macroAllocations={node.macroAllocations}
        timeStampField="approvalSubmittedAt"
        operation="Approved"
      />
    </>
  );
};

NodeStatus.propTypes = {
  node: PropTypes.shape({
    macroAllocations: PropTypes.array,
    isParent: PropTypes.bool,
  }),
};

const TargetGroupsTreeView = ({
  requestorGroup,
  descendantGroups,
  onGroupSelected,
  onNewTeamClick,
  onButtonClick,
  isToggled,
}) => {
  const [
    { isTreeRefreshed, isDisplayHiddenTeams, selectedTargetGroup },
    dispatch,
  ] = useContext(RequestAllocationContext);

  const [treeData, setTreeData] = useState(null);

  useEffect(() => {
    const treeStruct = createTreeStructure(
      requestorGroup,
      descendantGroups,
      isDisplayHiddenTeams,
      selectedTargetGroup.id
    );
    setTreeData(treeStruct);
    // eslint-disable-next-line
  }, [isDisplayHiddenTeams, requestorGroup, descendantGroups, isTreeRefreshed]);

  const onNodeSelected = useCallback(
    (node) => {
      onGroupSelected(node.group, !!node.children, node.isRoot);
    },
    [onGroupSelected]
  );

  const onToggleDisplayHiddenTeam = () => {
    // reset selected node to parent
    dispatch({
      type: "selectTargetGroup",
      selectedTargetGroup: requestorGroup,
      isParent: true,
      isRoot: true,
    });
    dispatch({
      type: "toggleDislayHiddenTeams",
    });
  };

  return (
    <FancyCard>
      <FancyCardHeader>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          {!isToggled && (
            <>
              <H3>Teams</H3>
              <FlexItem ml="auto" mr="s">
                <Toggle
                  small
                  id="toggleHiddenTeamRequestorTeamBuilder"
                  label="Show hidden teams"
                  checked={isDisplayHiddenTeams}
                  onChange={onToggleDisplayHiddenTeam}
                />
              </FlexItem>
            </>
          )}
          <ToggleButton onButtonClick={onButtonClick} isToggled={isToggled} />
        </Flex>
      </FancyCardHeader>
      <FancyCardBody>
        {!isToggled && treeData && (
          <TreeView
            data={treeData}
            onNodeSelected={onNodeSelected}
            nodeStatus={NodeStatus}
          />
        )}
      </FancyCardBody>
      {!isToggled && (
        <FancyCardFooter>
          <Button iconLeft small variant="ghost" onClick={onNewTeamClick}>
            <Icon icon={["fas", "plus"]} />
            Add new team
          </Button>
        </FancyCardFooter>
      )}
    </FancyCard>
  );
};

TargetGroupsTreeView.propTypes = {
  requestorGroup: GroupPropType.isRequired,
  descendantGroups: PropTypes.arrayOf(GroupPropType).isRequired,
  onGroupSelected: PropTypes.func.isRequired,
  onNewTeamClick: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func,
  isToggled: PropTypes.bool,
};

export default TargetGroupsTreeView;
