import React from "react";
import { partial, get, find } from "lodash";
import moment from "moment";

import CommentModalTitle from "src/comments/components/CommentModalTitle";
import CommentsModal from "src/comments/components/CommentsModal";
import { useCommentModal } from "../context/CommentModalContext";

const isAllocationProjectHistoric = (allocationProject) => {
  return (
    allocationProject &&
    !allocationProject.isActive &&
    !allocationProject.forecast &&
    moment(allocationProject.startDate).isBefore()
  );
};

const checkCanAddNote = (
  commentAllocationProjectId,
  allocationProjectsWithCounts
) => {
  return !isAllocationProjectHistoric(
    get(
      find(allocationProjectsWithCounts, [
        "allocationProject.id",
        commentAllocationProjectId,
      ]),
      "allocationProject"
    )
  );
};

const CommentModelContainer = () => {
  const {
    saveComment,
    comments,
    loading,
    isShowCommentModal,
    commentTargetGroup,
    commentRoleGroup,
    commentAllocationProjectId,
    allocationProjectsWithCounts,
    hideCommentModal,
    setCommentAllocationProjectId,
  } = useCommentModal();

  if (!isShowCommentModal) {
    return null;
  }

  const handleOnAddNote = partial(saveComment, commentAllocationProjectId);
  const canAddNote = checkCanAddNote(
    commentAllocationProjectId,
    allocationProjectsWithCounts
  );

  const notesTitle = (
    <CommentModalTitle
      roleGroup={commentRoleGroup}
      targetGroup={commentTargetGroup}
      allocationProjectsWithCounts={allocationProjectsWithCounts}
      commentAllocationProjectId={commentAllocationProjectId}
      onChange={setCommentAllocationProjectId}
    />
  );

  return (
    <CommentsModal
      title={notesTitle}
      placeholder={
        canAddNote
          ? "Type your notes here, preferred band, skills..."
          : "Comments are closed"
      }
      onCancel={hideCommentModal}
      canAddNote={canAddNote}
      onAddNote={handleOnAddNote}
      loading={loading}
      comments={comments}
    />
  );
};

export default CommentModelContainer;
