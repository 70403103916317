import React from "react";
import PropTypes from "prop-types";

import GroupHierarchyQueryBreadcrumb from "src/components/GroupHierarchyBreadcrumb/GroupHierarchyQueryBreadcrumb";
import { useAllocationConfig } from "src/contexts/global/WorkspaceContext/WorkspaceContext";

import FteQueryBadgeForSupply from "../../Shared/FteQueryBadgeForSupply";
import GroupNameWithHierarchy from "../../Shared/GroupNameWithHierarchy";

const LineItemGroupHierarchy = ({ group, isSupply, isRootGroupSupply }) => {
  const { switchToLoadSupplyGroups } = useAllocationConfig();

  const extra = <FteQueryBadgeForSupply isSupply={isSupply} group={group} />;

  if (switchToLoadSupplyGroups && !isRootGroupSupply) {
    return <GroupNameWithHierarchy group={group} extra={extra} />;
  }

  return (
    <GroupHierarchyQueryBreadcrumb
      group={group}
      showGroupTypeBadge
      showGroupTypeBadgeShowToolTip
      groupTypeBadgeToolTipPosition="right"
      extra={extra}
    />
  );
};

LineItemGroupHierarchy.propTypes = {
  group: PropTypes.object.isRequired,
  isSupply: PropTypes.bool.isRequired,
  isRootGroupSupply: PropTypes.bool,
};

export default LineItemGroupHierarchy;
