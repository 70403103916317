import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { map, range } from "lodash";
import { H5, Flex, Avatar, Divider } from "orcs-design-system";
import { numberToLocaleString } from "src/util/toLocaleString";

import { displayInitials, fullDisplayName } from "src/util/personName";
import PersonLink from "src/components/PersonLink";
import { FILTERS } from "../../../context/ForecastContext";
import MemberTags from "./MemberTags";
import PersonAllocationBadges from "./PersonAllocationBadges";
import MemberRowActionMenu from "./MemberRowActionMenu";
import { findAllocation } from "./utils/person.util";

import PersonFteInput from "./PersonFteInput";

const MemberRow = ({
  person,
  lineItem,
  targetGroupId,
  sourceGroupId,
  isLast,
  onAllocatePerson,
  onMovePerson,
  onRemovePerson,
  searchVariables,
  membersFilter,
  withExtraColumn = false,
  showMemberTags = false,
  isAllocationEnabled = false,
  showMemberMoveBadge = false,
  isPageReadonly = false,
  isTagsReadOnly = false,
  enableMemberFteEdit,
  updatePersonFte,
  enableTagsAppliedCount,
  isForecast,
  hideApproverColumn,
  hideBudgetColumn,
}) => {
  const fte = useMemo(() => {
    if (person.isAllocated) {
      return 0;
    }

    if (lineItem.grouping.isMovedIn) {
      return 0;
    }

    const memberAllocation = findAllocation(
      person.prevAllocations || person.allocations,
      targetGroupId
    );

    if (!memberAllocation) {
      return 0;
    }

    return memberAllocation.fte;
    // Purposely stop the update of this useMemo => if person allocations changed
    // we may lose this allocation totally. Need to keep it for UI display
    // TODO: Find out how we can keep the baseline fte for this person...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItem.grouping.isMovedIn]);

  // Check if filters applied
  if (
    membersFilter &&
    ((person.isAllocated && membersFilter === FILTERS.BASELINE_FILTER) ||
      (person.isUnallocated && membersFilter === FILTERS.FUTURE_FILTER))
  ) {
    return null;
  }

  const personLink = (
    <PersonLink
      person={person}
      target="_blank"
      isUnallocated={person.isUnallocated || person.disabled}
    />
  );

  const showPersonFteInput =
    isAllocationEnabled &&
    enableMemberFteEdit &&
    !person.disabled &&
    !person.isUnallocated;

  let amountOfDivs;
  if (isForecast) {
    // forecast
    amountOfDivs = enableMemberFteEdit
      ? lineItem.cells.length + 1
      : lineItem.cells.length - 1;
  } else if (hideApproverColumn && !hideBudgetColumn) {
    // planner
    amountOfDivs = enableMemberFteEdit
      ? lineItem.cells.length
      : lineItem.cells.length + 2;
  } else {
    // planner
    amountOfDivs = enableMemberFteEdit
      ? lineItem.cells.length + 1
      : lineItem.cells.length + 2;
  }

  return (
    <>
      <Flex
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        width="100%"
        pl="xl"
      >
        <Flex alignItems="center">
          <Avatar
            sizing="small"
            image={person.avatar}
            mb={showMemberTags ? "s" : "0"}
            title={personLink}
            imageAlt={`Avatar for ${fullDisplayName(person)}`}
            subtitle={person.jobTitle}
            initials={displayInitials(person)}
          />
          {showMemberMoveBadge && <PersonAllocationBadges person={person} />}
        </Flex>
        <MemberTags
          showMemberTags={showMemberTags}
          person={person}
          readOnly={isTagsReadOnly || isPageReadonly}
          comments={{
            groupIds: [targetGroupId, sourceGroupId],
          }}
          enableTagsAppliedCount={enableTagsAppliedCount}
        />
      </Flex>
      {withExtraColumn && <div />}
      <H5>{numberToLocaleString(fte)}</H5>
      {showPersonFteInput && (
        <PersonFteInput
          person={person}
          targetGroupId={targetGroupId}
          updatePersonFte={updatePersonFte}
        />
      )}
      {map(range(amountOfDivs), (num) => (
        <div key={num} />
      ))}
      <MemberRowActionMenu
        person={person}
        lineItem={lineItem}
        isAllocationEnabled={isAllocationEnabled}
        onAllocatePerson={onAllocatePerson}
        searchVariables={searchVariables}
        onMovePerson={onMovePerson}
        onRemovePerson={onRemovePerson}
      />
      {!isLast && (
        <Divider maxWidth="calc(100% - 18px)" ml="48px" light spanGrid />
      )}
    </>
  );
};

MemberRow.propTypes = {
  person: PropTypes.object.isRequired,
  isForecast: PropTypes.bool.isRequired,
  lineItem: PropTypes.object.isRequired,
  targetGroupId: PropTypes.string.isRequired,
  sourceGroupId: PropTypes.string,
  onAllocatePerson: PropTypes.func,
  onMovePerson: PropTypes.func,
  onRemovePerson: PropTypes.func,
  searchVariables: PropTypes.object,
  membersFilter: PropTypes.string,
  isLast: PropTypes.bool.isRequired,
  withExtraColumn: PropTypes.bool,
  showMemberTags: PropTypes.bool,
  isAllocationEnabled: PropTypes.bool,
  showMemberMoveBadge: PropTypes.bool,
  isPageReadonly: PropTypes.bool,
  isTagsReadOnly: PropTypes.bool,
  enableMemberFteEdit: PropTypes.bool,
  updatePersonFte: PropTypes.func,
  enableTagsAppliedCount: PropTypes.bool,
  hideApproverColumn: PropTypes.bool.isRequired,
  hideBudgetColumn: PropTypes.bool.isRequired,
};

export default MemberRow;
