import React from "react";
import PropTypes from "prop-types";
import { COLUMN_TYPES } from "../../../context/ForecastContext";
import { getDirectReportNotification } from "../../../context/reducer/modelReducer/util/newNotification/newGroupLevelNotification";
import { higherNotificationStatus } from "../../../context/reducer/modelReducer/util/setNotifications/setGroupingWithNotifications";
import NotificationDot from "../../Shared/NotificationDot";

const DirectReportNotification = ({
  grouping,
  groupTypesLookup,
  existingNotification,
}) => {
  const directReportNotification = getDirectReportNotification(
    grouping,
    COLUMN_TYPES.PLANNER_REQUESTED,
    groupTypesLookup
  );

  const notification = higherNotificationStatus(
    directReportNotification,
    existingNotification
  );

  return <NotificationDot notification={notification} isGrouping />;
};

DirectReportNotification.propTypes = {
  grouping: PropTypes.object,
  groupTypesLookup: PropTypes.object,
  existingNotification: PropTypes.object,
};

export default DirectReportNotification;
