import React, { useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { map, debounce, slice, get, size } from "lodash";
import styled from "styled-components";
import {
  Flex,
  FlexItem,
  Badge,
  TextInput,
  H4,
  Select,
} from "orcs-design-system";
import PersonPropType from "src/custom-prop-types/person";
import GroupPropType from "src/custom-prop-types/group";
import { getOptionByValue } from "src/util/selectUtil";
import FancyCard, {
  FancyCardHeader,
  FancyCardBody,
  FancyCardFooter,
} from "src/components/FancyCard/FancyCard";
import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";
import Pagination from "src/components/Pagination/Pagination";
import {
  OVER_ALLOCATED,
  UNDER_ALLOCATED,
  NEWLY_ADDED,
  REMOVED,
  PAGE_SIZE,
  PAGE_NEIGHBOURS,
} from "src/allocation/consts";
import { RequestAllocationContext } from "src/allocation/contexts/requestAllocationContext";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";
import Row from "./Row";

const CustomSelect = styled(Select)`
  width: 200px;
  font-size: 1.4rem;
  margin-top: 0 !important;
  .Select-control {
    height: 40px;
  }
  .Select-placeholder,
  .Select-value-label {
    line-height: 38px;
  }
`;

const FilterOptions = [
  { label: "None", value: null },
  { label: "Show over-allocated", value: OVER_ALLOCATED },
  { label: "Show under-allocated", value: UNDER_ALLOCATED },
  { label: "Show newly added", value: NEWLY_ADDED },
  { label: "Show removed", value: REMOVED },
];

const People = ({
  loading,
  requestorGroup,
  selectedGroup,
  allocationProjectId,
  members,
  onSearch,
  totalCount,
  totalFte,
  onFilter,
  selectedFilter,
}) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const totalMemberCount = size(members);
  const [{ selectedTargetGroup }] = useContext(RequestAllocationContext);

  const onSearchChange = useCallback(
    (e) => {
      debounce((value) => {
        onSearch(value);
        setCurrentPageIndex(0);
      }, DEBOUNCE_SEARCH_TIME)(e.target.value);
    },
    [onSearch, setCurrentPageIndex]
  );

  const onFilterChange = useCallback(
    (selected) => {
      const value = get(selected, "value");
      onFilter(value);
      setCurrentPageIndex(0);
    },
    [onFilter, setCurrentPageIndex]
  );

  const onNavigationChange = useCallback(
    (pagedData) => {
      const { currentPage } = pagedData;
      setCurrentPageIndex(currentPage - 1);
    },
    [setCurrentPageIndex]
  );

  const startIndex = currentPageIndex * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const visibleMembers = slice(members, startIndex, endIndex);

  return (
    <FancyCard>
      <FancyCardHeader>
        <Flex alignItems="center" justifyContent="space-between">
          <H4>
            {!loading && totalCount} People in{" "}
            <strong>
              <GroupHierarchyBreadcrumb
                showBreadcrumb={false}
                group={selectedGroup}
                titleOnly={true}
              />
            </strong>
          </H4>
          <FlexItem flex="0 0 auto" ml="xs">
            <Badge>{totalFte} FTE</Badge>
          </FlexItem>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mt="s"
        >
          <FlexItem flex="1 1 auto" mr="s">
            <TextInput
              type="text"
              ariaLabel="Search for people or teams"
              placeholder="Search for people or teams"
              iconRight={["far", "search"]}
              fullWidth
              onChange={onSearchChange}
            />
          </FlexItem>
          <FlexItem flex="0 0 auto">
            <CustomSelect
              ariaLabel="Filter selection"
              placeholder="Select Filter"
              options={FilterOptions}
              value={getOptionByValue(FilterOptions, selectedFilter)}
              onChange={onFilterChange}
              isSearchable={false}
              aria-labelledby="react-select-18-placeholder"
            />
          </FlexItem>
        </Flex>
      </FancyCardHeader>
      <FancyCardBody>
        {map(visibleMembers, (member) => (
          <Row
            key={member.aggregateId}
            allocationProjectId={allocationProjectId}
            group={requestorGroup}
            person={member}
            allocations={member.mergedAllocations}
            readOnly={selectedTargetGroup.isHidden}
          />
        ))}
      </FancyCardBody>
      <FancyCardFooter>
        <Pagination
          totalRecords={totalMemberCount}
          pageLimit={PAGE_SIZE}
          pageNeighbours={PAGE_NEIGHBOURS}
          onPageChanged={onNavigationChange}
        />
      </FancyCardFooter>
    </FancyCard>
  );
};

People.propTypes = {
  loading: PropTypes.bool.isRequired,
  requestorGroup: GroupPropType.isRequired,
  selectedGroup: GroupPropType.isRequired,
  allocationProjectId: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(PersonPropType).isRequired,
  totalCount: PropTypes.number.isRequired,
  totalFte: PropTypes.number.isRequired,
  onSearch: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  selectedFilter: PropTypes.oneOf([
    null,
    OVER_ALLOCATED,
    UNDER_ALLOCATED,
    NEWLY_ADDED,
    REMOVED,
  ]),
};

export default People;
