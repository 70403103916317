import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

import GroupHeader from "./GroupHeader";

const TimelineGroupHeaders = ({
  isTimelineRendered,
  groups,
  team,
  groupTypes,
  onEditingAllocations,
  workspace,
  setIsGroupHeadersRendered,
}) => {
  useEffect(() => {
    if (isTimelineRendered) {
      setIsGroupHeadersRendered(true);
    }
  }, [isTimelineRendered, setIsGroupHeadersRendered]);

  if (!isTimelineRendered) {
    return null;
  }

  return (
    <>
      {map(groups, (g) => {
        if (!g.person) {
          return null;
        }
        return (
          <GroupHeader
            key={g.id}
            person={g.person}
            team={team}
            groupTypes={groupTypes}
            onEditingAllocations={onEditingAllocations}
            workspace={workspace}
          />
        );
      })}
    </>
  );
};

TimelineGroupHeaders.propTypes = {
  isTimelineRendered: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  team: PropTypes.object.isRequired,
  groupTypes: PropTypes.object.isRequired,
  onEditingAllocations: PropTypes.func.isRequired,
  workspace: PropTypes.object.isRequired,
  setIsGroupHeadersRendered: PropTypes.func.isRequired,
};

export default TimelineGroupHeaders;
