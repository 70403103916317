import { useReducer } from "react";
import { get, reduce } from "lodash";
import constate from "constate";
import { useQuery } from "@apollo/client";
import { getCommentCounts as getCommentCountsQuery } from "src/comments/comments.graphql";

const useCommentCountsQuery = ({ groupId, countTags, isReady }) => {
  const [, forceUpdateCommentCounts] = useReducer((x) => x + 1, 0);
  const { filters } = countTags;

  const {
    data: getCommentCountsQueryData,
    variables: getCommentCountsQueryVariables,
    error: getCommentCountsQueryError,
  } = useQuery(getCommentCountsQuery, {
    variables: {
      groupId,
      filters,
    },
    skip: !isReady,
  });

  const countsMap = reduce(
    get(getCommentCountsQueryData, "counts"),
    (result, { threadId, count }) => ({ ...result, [threadId]: count }),
    {}
  );

  return {
    counts: countsMap,
    getCommentCountsQueryVariables,
    getCommentCountsQueryError,
    forceUpdateCommentCounts,
  };
};

const [
  CommentCountsProvider,
  useCommentCounts,
  useGetCommentCountsQueryVariables,
  useForceUpdateCommentCounts,
] = constate(
  useCommentCountsQuery,
  (value) => value.counts,
  (value) => value.getCommentCountsQueryVariables,
  (value) => value.forceUpdateCommentCounts
);

export {
  CommentCountsProvider,
  useCommentCounts,
  useGetCommentCountsQueryVariables,
  useForceUpdateCommentCounts,
};
