import { map } from "lodash";

import { getTeamMembersQuery } from "src/queries/group.graphql";

export const getSearchFilter = (
  sourceGroup,
  targetGroupId,
  isDemandTeam,
  person
) => {
  if (!sourceGroup) {
    return undefined;
  }

  const directTeams = isDemandTeam
    ? [targetGroupId]
    : map(person?.allocations, (allocation) => allocation.targetGroupId);

  return {
    teams: {
      teams: [sourceGroup.id],
      includeIndirectTeamMembers: true,
      directTeams,
      excludeIntersectingTeamMembers: true,
      excludeVacancies: true,
    },
  };
};

export const refetchMemberList = ({ client, teamId }) => {
  const currentResult = client.readQuery({
    query: getTeamMembersQuery,
    variables: {
      groupId: teamId,
    },
  });

  if (!currentResult) {
    return;
  }

  client.refetchQueries({
    include: [
      {
        query: getTeamMembersQuery,
        variables: {
          groupId: teamId,
        },
      },
    ],
  });
};
