/* eslint-disable no-param-reassign */
import React, { useState, useMemo, useCallback } from "react";
import { Flex, FlexItem, H3, Button, Box, Modal } from "orcs-design-system";
import PropTypes from "prop-types";
import { map } from "lodash";
import ErrorNotification from "src/components/ErrorNotification";
import TreeView from "src/allocation/components/TreeView";
import { useAllocationConfig } from "src/contexts/global/WorkspaceContext/WorkspaceContext";

import ChapterDescriptionPanel from "./ChapterDescriptionPanel";
import SelectedChaptersPanel from "./SelectedChaptersPanel";
import ChaptersTreePanel from "./ChaptersTreePanel";
import { getSupplyRootGroupForNode } from "./utils";
import useSearchTree from "./hooks/useSearchTree";
import useTreeActions from "./hooks/useTreeActions";
import { useSearchSupplyGroups } from "./utils/useSearchGroups";

const EnhancedAddNewRoleModal = ({
  supplyLabel,
  visible,
  loading,
  onHideModal,
  existingGroups,
  groupTypesLookup,
  isPlannerPage,
  rootGroups,
  handleOnConfirm,
  selectedChapterHeading,
  disableDecimal,
  ignoreDisableGroup = false,
  copywriting,
}) => {
  const { switchToLoadSupplyGroups } = useAllocationConfig();

  const [selectedGroupsDisplay, setSelectedGroupsDisplay] = useState({});
  const [selectedGroups, setSelectedGroups] = useState({});
  const [selectedGroupsFte, setSelectedGroupsFte] = useState({});
  const [checkedGroup, setCheckedGroup] = useState(null);
  const [errorSaving, setErrorSaving] = useState(false);
  const [savingMacroAllocations, setSavingMacroAllocations] = useState(false);
  const [refreshAfterNodeFound, setRefreshAfterNodeFound] = useState({});
  const [searchTerm, setSearchTerm] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);
  const [rootAndSupplyRootLookup, setRootAndSupplyRootLookup] = useState(null);
  const [hierarchyIds, setHierarchyIds] = useState(null);

  const selectedGroupsArray = useMemo(
    () =>
      map(Object.values(selectedGroupsDisplay), (group) => {
        return {
          ...group,
          checked: selectedGroups[group.id],
        };
      }).filter(({ checked }) => checked),
    [selectedGroups, selectedGroupsDisplay]
  );

  const { searchTree: fetchSearchGroups, searchInProgress } = useSearchTree({
    groupTypesLookup,
  });

  const { searchSupplyGroups } = useSearchSupplyGroups({
    rootGroups,
    switchToLoadSupplyGroups,
  });

  const onClearValuesAndHideModal = useCallback(() => {
    setSelectedGroupsDisplay({});
    setSelectedGroups({});
    setSelectedGroupsFte({});
    setCheckedGroup(null);
    setErrorSaving(false);
    setSavingMacroAllocations(false);
    setRefreshAfterNodeFound({});
    setSearchTerm(null);
    setActiveGroup(null);
    setRootAndSupplyRootLookup(null);
    onHideModal();
  }, [onHideModal]);

  const handleOnCancel = () => {
    onClearValuesAndHideModal();
  };

  const handleGroupJump = useCallback((groupId) => {
    setActiveGroup(groupId);
    setTimeout(() => {
      TreeView.scrollActiveNodeIntoView(
        "center",
        `[data-id="tree-node-${groupId}"]`
      );
    }, 300);
  }, []);

  const searchTree = useCallback(
    async (term) => {
      if (term && term.length > 2) {
        if (switchToLoadSupplyGroups) {
          searchSupplyGroups(term);
        } else {
          await fetchSearchGroups(term);
        }
      }
      setSearchTerm(term);
      setActiveGroup(null);
    },
    [fetchSearchGroups, switchToLoadSupplyGroups, searchSupplyGroups]
  );

  const toggleNodeChecked = ({ node, checked }) => {
    node.checked = checked;
  };

  const { handleNodeToggled } = useTreeActions({
    setActiveGroup,
    existingGroups,
    groupTypesLookup,
    refreshAfterNodeFound,
    groupsHierarchy: [],
    handleGroupJump,
  });

  const getSupplyRoot = useCallback(
    (node) => {
      return getSupplyRootGroupForNode({
        node,
        groupTypesLookup,
      });
    },
    [groupTypesLookup]
  );

  const expandToGroup = (groupIds) => {
    setHierarchyIds(groupIds);
  };

  const handleNodeChecked = useCallback(
    (node, checked, refreshTree) => {
      setActiveGroup(null);
      setCheckedGroup(node.id);
      setSelectedGroupsDisplay((g) => ({ ...g, [node.id]: node.group }));
      setSelectedGroups((g) => ({ ...g, [node.id]: checked }));
      setSelectedGroupsFte((g) => ({ ...g, [node.id]: 1 }));
      setRootAndSupplyRootLookup((g) => ({
        ...g,
        [node.id]: getSupplyRoot(node),
      }));

      toggleNodeChecked({ node, checked });
      setRefreshAfterNodeFound({ refreshTree });
      refreshTree();
    },
    [getSupplyRoot]
  );

  const handleFteInputChange = useCallback(({ id, fte }) => {
    setSelectedGroupsFte((g) => ({ ...g, [id]: fte }));
  }, []);

  const handleSubmit = () => {
    setSavingMacroAllocations(true);
    handleOnConfirm({
      selectedGroupsArray,
      selectedGroupsFte,
      onClearValuesAndHideModal,
      setErrorSaving,
      setSavingMacroAllocations,
      rootAndSupplyRootLookup,
    });
  };

  return (
    <Modal
      data-testid="cp-enhanced-add-new-role-modal"
      visible={visible}
      width="80vw"
      height="80vh"
      maxHeight="90vh"
      maxWidth="90vw"
      handleOnConfirm={handleSubmit}
      onClose={handleOnCancel}
      headerContent={<H3>{`Add ${supplyLabel}`}</H3>}
      footerContent={
        <Box width="100%">
          <Flex alignItems="center" justifyContent="flex-end" width="100%">
            <Button onClick={handleOnCancel} variant="ghost" mr="r">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              isLoading={savingMacroAllocations}
              variant={savingMacroAllocations ? "disabled" : "success"}
              disabled={false}
            >
              Add
            </Button>
          </Flex>
          {errorSaving && (
            <Box mt="s">
              <ErrorNotification closable={false} />
            </Box>
          )}
        </Box>
      }
    >
      <Flex alignItems="stretch" height="calc(100% - 50px)" mt="r">
        <FlexItem flex="1 1 50%" mr="s">
          <ChaptersTreePanel
            loading={loading || searchInProgress}
            onNodeToggled={handleNodeToggled}
            onNodeChecked={handleNodeChecked}
            selectedGroups={selectedGroups}
            treeComponent={TreeView}
            searchTree={searchTree}
            showHierarchyBreadcrumb={!!searchTerm && !switchToLoadSupplyGroups}
            activeGroup={activeGroup}
            ignoreDisableGroup={ignoreDisableGroup}
            copywriting={copywriting}
            existingGroups={existingGroups}
            hierarchyIds={hierarchyIds}
          />
        </FlexItem>
        <FlexItem flex="1 1 50%" ml="s" height="100%">
          <Box height="100%" maxHeight="100%">
            <ChapterDescriptionPanel
              group={selectedGroupsDisplay[checkedGroup]}
              copywriting={copywriting}
            />
            <SelectedChaptersPanel
              selectedChapterHeading={selectedChapterHeading}
              groups={selectedGroupsArray}
              handleInputChange={handleFteInputChange}
              isPlannerPage={isPlannerPage}
              expandToGroup={expandToGroup}
              disableDecimal={disableDecimal}
            />
          </Box>
        </FlexItem>
      </Flex>
    </Modal>
  );
};

EnhancedAddNewRoleModal.propTypes = {
  handleOnConfirm: PropTypes.func,
  supplyLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onHideModal: PropTypes.func,
  existingGroups: PropTypes.array,
  groupTypesLookup: PropTypes.object,
  isPlannerPage: PropTypes.bool,
  rootGroups: PropTypes.array,
  selectedChapterHeading: PropTypes.string,
  disableDecimal: PropTypes.bool,
  ignoreDisableGroup: PropTypes.bool,
  copywriting: PropTypes.object,
};

export default EnhancedAddNewRoleModal;
