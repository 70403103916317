import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon } from "orcs-design-system";

const AnimateIcon = styled(Icon)`
  svg {
    transition: all 200ms ease-in-out;
  }
`;

const AnimatedExpandIcon = ({ isOpen }) => {
  return (
    <AnimateIcon
      icon={["fas", "chevron-right"]}
      color="greyDarker"
      rotation={isOpen ? 90 : undefined}
    />
  );
};

AnimatedExpandIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
export default AnimatedExpandIcon;
