import { useQuery } from "@apollo/client";

import {
  useMainQuery,
  useParentGroup,
  useDispatch,
  useUserInteraction,
  ACTIONS,
} from "./context";

const useExecMainQuery = () => {
  const { variables, query } = useMainQuery();
  const {
    selectedSupplyGroupId,
    selectedSubSupplyGroupId,
    targetGroupSearch,
    personSearch,
    personFilter,
  } = useUserInteraction();
  const parentGroup = useParentGroup();
  const dispatch = useDispatch();

  useQuery(query, {
    variables,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      dispatch({
        type: ACTIONS.MAIN_QUERY_COMPLETE,
        data,
        variables,
        selectedSupplyGroupId,
        selectedSubSupplyGroupId,
        targetGroupSearch,
        personSearch,
        personFilter,
        parentGroup,
      });
    },
    onError: (error) => {
      dispatch({ type: ACTIONS.MAIN_QUERY_ERROR, error });
    },
  });
};

export default useExecMainQuery;
