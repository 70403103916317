import { map } from "lodash";
import React from "react";

import { MarkBtns, BlockBtns, LinkBtn } from "../RichTextEditor.config";
import * as rteUtils from "../RichTextEditor.util";

import RteButton from "./RteButton";
import RteToolBar from "./RteToolbar";

const renderMarkButton = ([format, icon]) => {
  return (
    <RteButton
      key={format}
      format={format}
      icon={icon}
      isActive={rteUtils.isMarkActive}
      formatText={rteUtils.toggleMark}
    />
  );
};

const renderLinkButton = ([format, icon]) => {
  return (
    <RteButton
      key={format}
      format={format}
      icon={icon}
      isActive={rteUtils.isLinkActive}
      formatText={rteUtils.onLinkClick}
    />
  );
};

const renderBlockButton = ([format, icon]) => {
  return (
    <RteButton
      key={format}
      format={format}
      icon={icon}
      isActive={rteUtils.isBlockActive}
      formatText={rteUtils.toggleBlock}
    />
  );
};

const EditorToolbar = () => {
  return (
    <RteToolBar>
      {map(MarkBtns, (m) => renderMarkButton(m))}
      {map(BlockBtns, (b) => renderBlockButton(b))}
      {renderLinkButton(LinkBtn)}
    </RteToolBar>
  );
};

export default EditorToolbar;
