const { useRef } = require("react");

const useCheckSearchTermDiff = (searchTerm) => {
  const searchTermRef = useRef(searchTerm);
  let termChanged = false;
  if (searchTerm !== searchTermRef.current) {
    termChanged = true;
    searchTermRef.current = searchTerm;
  }
  return termChanged;
};

export default useCheckSearchTermDiff;
