import * as ACTIONS from "./actionTypes";

const modalReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SHOW_COMMENT_MODAL: {
      const { allocationProjectId, targetGroup, roleGroup } = action;
      return {
        ...state,
        commentAllocationProjectId: allocationProjectId,
        commentTargetGroup: targetGroup,
        commentRoleGroup: roleGroup,
        isShowCommentModal: true,
      };
    }

    case ACTIONS.HIDE_COMMENT_MODAL: {
      return {
        ...state,
        commentAllocationProjectId: null,
        commentTargetGroup: null,
        commentRoleGroup: null,
        isShowCommentModal: false,
      };
    }

    case ACTIONS.SET_COMMENT_ALLOCATION_ID: {
      const { allocationProjectId } = action;
      return {
        ...state,
        commentAllocationProjectId: allocationProjectId,
      };
    }
    default:
      throw new Error("Comment context reducer action not supported");
  }
};

export default modalReducer;
