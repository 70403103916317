import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Text } from "orcs-design-system";
import { numberToLocaleString } from "src/util/toLocaleString";

const CurrentCell = ({ cell, isMembersExpanded }) => {
  const { value } = cell;

  return useMemo(() => {
    return (
      <Text
        className="guided-tour-allocation-planner-current-cell"
        fontWeight={isMembersExpanded ? "bold" : undefined}
      >
        {numberToLocaleString(value)}
      </Text>
    );
  }, [cell.hash, isMembersExpanded]); // eslint-disable-line
};

CurrentCell.propTypes = {
  cell: PropTypes.object.isRequired,
  isMembersExpanded: PropTypes.bool,
};

export default CurrentCell;
