import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Button, FlexItem } from "orcs-design-system";

const VerifyObjectivesButton = ({ objectiveIds, isVerifying, verify }) => {
  return (
    <FlexItem flex="0 0 auto">
      <Button
        onClick={verify}
        isLoading={isVerifying}
        variant="success"
        ml="auto"
      >
        {isEmpty(objectiveIds) ? "Link and close" : "Verify and close"}
      </Button>
    </FlexItem>
  );
};

VerifyObjectivesButton.propTypes = {
  objectiveIds: PropTypes.array,
  isVerifying: PropTypes.bool,
  verify: PropTypes.func,
};

export default React.memo(VerifyObjectivesButton);
