/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { get, includes } from "lodash";
import pluralize from "pluralize";

import { H3, Flex } from "orcs-design-system";
import FancyCard, {
  FancyCardHeader,
  FancyCardBody,
} from "src/components/FancyCard/FancyCard";
import TreeView from "src/allocation/components/TreeView";

import {
  useDispatch,
  useModel,
  useUserInteraction,
  useWorkspace,
  ACTIONS,
} from "../../context";

import ToggleButton from "../../../ToggleButton";
import { findTargetParentGroup } from "./SourceGroups.util";

const SourceGroups = ({ onButtonClick, isToggled }) => {
  const dispatch = useDispatch();
  const { supplyGroupTree } = useModel();
  const {
    selectedSupplyGroupId,
    selectedSubSupplyGroupId,
    personSearch,
    personFilter,
  } = useUserInteraction();
  const allocationConfig = get(useWorkspace(), "config.allocation");
  const supplyLabel = pluralize(get(allocationConfig, "supplyLabel", "Role"));
  const roleTypes = get(allocationConfig, "roleTypes");

  const onNodeSelected = (node) => {
    const { group, parent } = node;

    if (!group) {
      return;
    }

    // If selected node doesn't have parent => top level group, treat it as supply group
    // Or group type is in roleTypes (Chapter, COE etc) => This is a supply group
    if (!parent || includes(roleTypes, group.type)) {
      const isSameSupplyGroup = group.id === selectedSupplyGroupId;
      // If it's the same supply group, but sub supply group is not selected, then do nothing
      if (isSameSupplyGroup && !selectedSubSupplyGroupId) {
        return;
      }

      // If it's the same supply group, but sub supply group is selected, which means user clicked the parent group
      // Only need to clear the selected sub group
      if (isSameSupplyGroup && !!selectedSubSupplyGroupId) {
        dispatch({
          type: ACTIONS.SUB_SUPPLY_GROUP_CLEARED,
          selectedSupplyGroupId: group.id,
          selectedSubSupplyGroupId: null,
          personSearch,
          personFilter,
        });

        return;
      }

      // Supply group changed
      dispatch({
        type: ACTIONS.SUPPLY_GROUP_SELECTED,
        selectedSupplyGroupId: group.id,
        selectedSubSupplyGroupId: null,
      });

      return;
    }

    // Selected node group is not a supply group, check the current sub supply group first
    if (group.id === selectedSubSupplyGroupId) {
      return;
    }

    // Not the same sub supply group, get its nearest parent group that is in roleTypes
    const targetParentGroup = findTargetParentGroup(roleTypes, node);

    if (!targetParentGroup) {
      // If not found supply group from parent, treat current group as supply group
      dispatch({
        type: ACTIONS.SUPPLY_GROUP_SELECTED,
        selectedSupplyGroupId: group.id,
        selectedSubSupplyGroupId: null,
        personSearch,
        personFilter,
      });
      return;
    }

    const supplyGroupId = targetParentGroup.id;

    // If the supplyGroupId changed, need to trigger a reload
    // if not, only set the sub supply group (members list change in UI)
    const type =
      supplyGroupId === selectedSupplyGroupId
        ? ACTIONS.SUB_SUPPLY_GROUP_SELECTED
        : ACTIONS.SUPPLY_GROUP_SELECTED;

    dispatch({
      type,
      selectedSupplyGroupId: supplyGroupId,
      selectedSubSupplyGroupId: group.id,
      personSearch,
      personFilter,
    });
  };

  return (
    <FancyCard>
      <FancyCardHeader>
        <Flex alignItems="center" justifyContent="space-between">
          {!isToggled && <H3>{supplyLabel}</H3>}
          <ToggleButton onButtonClick={onButtonClick} isToggled={isToggled} />
        </Flex>
      </FancyCardHeader>
      <FancyCardBody>
        {!isToggled && (
          <TreeView data={supplyGroupTree} onNodeSelected={onNodeSelected} />
        )}
      </FancyCardBody>
    </FancyCard>
  );
};

SourceGroups.propTypes = {
  onButtonClick: PropTypes.func,
  isToggled: PropTypes.bool,
};

export default SourceGroups;
