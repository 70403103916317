import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

import {
  useDispatch,
  useExpandedGroupIds,
  useExpandedLineItemsGroupIds,
} from "../../../context/ForecastContext";
import { isGroupingHasNotifications } from "../../util/quickActivityViewFilters";
import TreeIndentWrapper from "../../Shared/TreeIndentWrapper";
import GroupingExpandHeader from "../GroupingExpandHeader";
import LineItems from "./LineItems";

const PlannerGrouping = ({ grouping }) => {
  const expandedGroupIds = useExpandedGroupIds();
  const expandedLineItemsGroupIds = useExpandedLineItemsGroupIds();
  const dispatch = useDispatch();

  const { id, nestedLevel, childGroupings, canToggleLineItems } = grouping;
  const isExpanded = expandedGroupIds[id];
  const isLineItemsExpanded =
    expandedLineItemsGroupIds[id] || !canToggleLineItems;
  const hasNotifications = isGroupingHasNotifications(grouping);

  return useMemo(
    () => {
      if (!hasNotifications) {
        return null;
      }
      return (
        <>
          <GroupingExpandHeader
            grouping={grouping}
            isExpanded={isExpanded}
            dispatch={dispatch}
            withNotification={false}
          />
          {isExpanded && (
            <TreeIndentWrapper indentLevel={nestedLevel}>
              <LineItems
                grouping={grouping}
                isExpanded={isLineItemsExpanded}
                dispatch={dispatch}
              />
              {map(childGroupings, (childGrouping) => (
                <PlannerGrouping
                  key={childGrouping.id}
                  grouping={childGrouping}
                />
              ))}
            </TreeIndentWrapper>
          )}
        </>
      );
    },
    // eslint-disable-next-line
    [grouping.hash, isExpanded, hasNotifications, isLineItemsExpanded]
  );
};

PlannerGrouping.propTypes = {
  grouping: PropTypes.object.isRequired,
};

export default PlannerGrouping;
