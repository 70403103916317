import React, { Fragment, useState } from "react";

import styled from "styled-components";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

import {
  Popover,
  ActionsMenu,
  ActionsMenuItem,
  Button,
  Icon,
} from "orcs-design-system";
import { useHistory } from "react-router-dom";
import DownloadButton from "src/dataExport/components/DownloadButton";
import GroupPropType from "src/custom-prop-types/group";
import urlPaths, { url } from "src/consts/urlPaths";
import {
  useApplyFilters,
  DEFAULT_FILTERS,
} from "src/contexts/filterAndFte/FilterAndFteContext";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING, EVENT_LOCATIONS } from "src/consts/eventTracking";

export const GLOBAL_ACTION_KEYS = { DOWNLOAD: "DOWNLOAD" };

const GLOBAL_ACTIONS = {
  [GLOBAL_ACTION_KEYS.DOWNLOAD]: DownloadButton,
};

/**
 * @typedef {{props?: object, name: string, Component: Component }} ActionModal
 * @typedef {{isHidden?: boolean, modal?: ActionModal, label?: string, className?: string, onClick?: function, globalAction?: string}} Action
 */

/**
 * @param {object} props
 * @param {Action[]} props.actions
 */
// TODO: Add jsdoc
export const ActionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    isHidden: PropTypes.bool,
    modal: PropTypes.shape({
      props: PropTypes.any,
      name: PropTypes.string,
      Component: PropTypes.func,
    }),
    label: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    globalAction: PropTypes.string,
  })
);

const StyledActionsMenu = styled(ActionsMenu)`
  z-index: 15;
`;

const ConfiguredActionMenu = ({
  actions,
  team,
  showInsightIcon = true,
  ...props
}) => {
  const [visibleModal, setVisibleModal] = useState(null);
  const applyFilters = useApplyFilters();
  const history = useHistory();

  const onInsightsClick = () => {
    applyFilters({
      teams: {
        ...cloneDeep(DEFAULT_FILTERS.teams),
        teams: [{ value: team.id, label: team.name }],
      },
    });
    trackEvent(EVENT_TRACKING.INSIGHTS_CLICKED, {
      location: EVENT_LOCATIONS.TEAM_PAGE,
    });
    history.push({
      pathname: url(urlPaths.INSIGHTS),
      state: {
        sidebar: { action: "openSidebar", selectedTab: "filter" },
      },
    });
  };

  return (
    <>
      {showInsightIcon && team && (
        <Popover direction="top" width="fit-content" text="Insights">
          <Button
            ariaLabel="Team Insights"
            variant="ghost"
            iconOnly
            onClick={onInsightsClick}
            height="30px"
            width="30px"
          >
            <Icon icon={["far", "chart-line"]} />
          </Button>
        </Popover>
      )}
      <StyledActionsMenu {...props}>
        {actions.map((action) => {
          if (action.isHidden) return null;

          if (action.globalAction && GLOBAL_ACTIONS[action.globalAction]) {
            const GlobalActionComponent = GLOBAL_ACTIONS[action.globalAction];

            return <GlobalActionComponent key={action.label} />;
          }

          if (action.modal) {
            const {
              Component,
              name,
              props: modalProps = {},
              onClose,
              onOpen,
            } = action.modal;

            return (
              <Fragment key={action.label}>
                <ActionsMenuItem
                  className={action.className}
                  data-testid={action.testId}
                  onClick={() => {
                    setVisibleModal(name);
                    onOpen?.();
                  }}
                >
                  {action.label}
                </ActionsMenuItem>

                <Component
                  {...modalProps}
                  isVisible={visibleModal === name}
                  onHideModal={() => {
                    setVisibleModal(false);
                    onClose?.();
                  }}
                />
              </Fragment>
            );
          }

          return (
            <ActionsMenuItem
              key={action.label}
              href={action.href}
              className={action.className}
              data-testid={action.testId}
              ariaLabel="actions-menu-item"
              onClick={
                action.onClick
                  ? (e) => {
                      e.preventDefault();
                      action.onClick?.();
                    }
                  : null
              }
            >
              {action.label}
            </ActionsMenuItem>
          );
        })}
      </StyledActionsMenu>
    </>
  );
};

ConfiguredActionMenu.propTypes = {
  actions: ActionsPropType.sh,
  team: GroupPropType.isRequired,
  showInsightIcon: PropTypes.bool,
};

export default ConfiguredActionMenu;
