import { useState, useEffect, useCallback, useMemo } from "react";

import {
  convertGroupingToTreeData,
  loadMembersForNode,
  toggleGroupingNode,
  toggleLineItemNode,
  togglePersonNode,
} from "./BulkAddingTagsModal.util";

const useGroupingMembersTree = ({
  isTreeActive,
  grouping,
  loadMembers,
  enableDeparturedPeopleTagEditing,
}) => {
  const [selectedGroups, setSelectedGroups] = useState({});
  const [selectedPeople, setSelectedPeople] = useState({});

  const treeData = useMemo(() => {
    const treeRoot = convertGroupingToTreeData({
      grouping,
      toggled: true,
      isRoot: true,
      isGrouping: true,
    });

    return treeRoot;
  }, [grouping]);

  useEffect(() => {
    if (!isTreeActive) {
      setSelectedGroups({});
      setSelectedPeople({});
    }
  }, [isTreeActive]);

  const handleNodeSelected = useCallback(
    (node, refreshTree) => {
      const { isLineItem } = node;
      if (isLineItem) {
        loadMembersForNode({
          node,
          refreshTree,
          loadMembers,
          enableDeparturedPeopleTagEditing,
        });
      }
    },
    [loadMembers, enableDeparturedPeopleTagEditing]
  );

  const handleChecked = useCallback(
    (node, checked, refreshTree) => {
      // Load members for group based on isLineItem flag
      const { isLineItem, isPerson } = node;
      if (isLineItem) {
        toggleLineItemNode({
          node,
          checked,
          setSelectedGroups,
          setSelectedPeople,
          refreshTree,
          loadMembers,
          loadMembersForNode,
          enableDeparturedPeopleTagEditing,
        });
      } else if (isPerson) {
        togglePersonNode({
          node,
          checked,
          setSelectedGroups,
          setSelectedPeople,
        });
      } else {
        toggleGroupingNode({
          node,
          checked,
          setSelectedGroups,
          setSelectedPeople,
          refreshTree,
          loadMembers,
          loadMembersForNode,
          enableDeparturedPeopleTagEditing,
        });
      }
    },
    [loadMembers, enableDeparturedPeopleTagEditing]
  );

  return {
    treeData,
    selectedGroups,
    selectedPeople,
    handleNodeSelected,
    handleChecked,
  };
};

export default useGroupingMembersTree;
