import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { map } from "lodash";
import {
  parseSlateMark,
  parseSlateBlock,
  parseSlateInline,
} from "./renderNodes";

/**
 *
 * Change from using immutable record. Reutrn a text node with object as string
 * @param {string} text
 */
function StringNode(text) {
  return {
    object: "string",
    text,
  };
}

/**
 * Add a unique key to a React `element`.
 *
 * @param {Element} element
 * @return {Element}
 */

let key = 0;

function addKey(element) {
  key += 1;
  return React.cloneElement(element, { key });
}

/**
 * A rule to (de)serialize text nodes. This is automatically added to the HTML
 * serializer so that users don't have to worry about text-level serialization.
 *
 * @type {Object}
 */

const TEXT_RULE = {
  serialize(obj, children) {
    if (obj.object === "string") {
      return children.split("\n").reduce((array, text, i) => {
        if (i !== 0) {
          // eslint-disable-next-line react/no-array-index-key
          array.push(<br key={i} />);
        }
        array.push(text);
        return array;
      }, []);
    }

    return JSON.stringify(obj);
  },
};

// This class is copied from slate-html-serializer
// https://github.com/ianstormtaylor/slate/blob/v0.47/packages/slate-html-serializer/src/index.js
// No longer need this for newer version slate >= 0.50
// Keep it here in case we need to re-render old notes
/**
 * HTML serializer for Slate version <= 0.47.
 *
 * @type {Html}
 */
class Html {
  /**
   * Create a new serializer with `rules`.
   *
   * @param {Object} options
   *   @property {Array} rules
   */

  constructor(options = {}) {
    const { rules = [] } = options;

    this.rules = [...rules, TEXT_RULE];
  }

  /**
   * Serialize a `value` object into an HTML string.
   *
   * @param {Value} value
   * @param {Object} options
   *   @property {Boolean} render
   * @return {String|Array}
   */

  serialize = (value, options = {}) => {
    const { document } = value;
    const elements = map(document.nodes, this.serializeNode).filter((el) => el);
    if (options.render === false) {
      return elements;
    }

    const html = renderToStaticMarkup(<body>{elements}</body>);
    const inner = html.slice(6, -7);
    return inner;
  };

  /**
   * Serialize a `node`.
   *
   * @param {Node} node
   * @return {String}
   */

  serializeNode = (node) => {
    if (node.object === "text") {
      const string = new StringNode(node.text);
      const text = this.serializeString(string);

      return node.marks.reduce((children, mark) => {
        for (let i = 0, j = this.rules.length; i < j; i += 1) {
          const rule = this.rules[i];
          if (rule.serialize) {
            const ret = rule.serialize(mark, children);
            if (ret === null) {
              return null;
            }
            if (ret) {
              return addKey(ret);
            }
          }
        }

        throw new Error(
          `No serializer defined for mark of type "${mark.type}".`
        );
      }, text);
    }

    const children = node.nodes.map(this.serializeNode);

    for (let i = 0, j = this.rules.length; i < j; i += 1) {
      const rule = this.rules[i];
      if (rule.serialize) {
        const ret = rule.serialize(node, children);
        if (ret === null) {
          return null;
        }
        if (ret) {
          return addKey(ret);
        }
      }
    }

    throw new Error(`No serializer defined for node of type "${node.type}".`);
  };

  /**
   * Serialize a `string`.
   *
   * @param {String} string
   * @return {String}
   */

  serializeString = (string) => {
    for (let i = 0, j = this.rules.length; i < j; i += 1) {
      const rule = this.rules[i];
      if (rule.serialize) {
        const ret = rule.serialize(string, string.text);
        if (ret) {
          return ret;
        }
      }
    }

    return string;
  };
}

const isBlock = ({ object }) => object === "block";
const isInline = ({ object }) => object === "inline";
const isMark = ({ object }) => object === "mark";

const RULES = [
  {
    serialize(obj, children) {
      if (isBlock(obj)) {
        return parseSlateBlock(obj, children);
      }
      if (isMark(obj)) {
        return parseSlateMark(obj, children);
      }
      if (isInline(obj)) {
        return parseSlateInline(obj, children);
      }
      return null;
    },
  },
];

let oldSerializer = null;

const serializeOldSlateObj = (msg) => {
  if (!oldSerializer) {
    oldSerializer = new Html({ rules: RULES });
  }

  return oldSerializer.serialize(msg);
};

export default serializeOldSlateObj;
