import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { isNil } from "lodash";
import styled from "styled-components";
import { Box, Popover, Button, Icon } from "orcs-design-system";
import { roundFte } from "src/util/roundingStrategy";
import { isContentEmpty } from "src/comments/components/RichTextEditor/serializer/slatePlainTextSerializer";

import PlannerRequestFteInput from "src/allocation/components/PlannerRequestFteInput";
import { ACTIONS, SAVE_STATUS } from "../../../context/ForecastContext";
import FailedIndicator from "../../Shared/FailedIndicator";
import useSaveFteForCell from "../../Shared/hooks/useSaveFteForCell";
import { InputDeltaLabel } from "../../Shared/InputDeltaLabel";

const InputWrapper = styled.div`
  position: relative;
`;

const Input = ({ cell, dispatch, updateMethodRef }) => {
  const {
    id,
    lineItem: { isSkillsMandatory },
    value,
    valueSaveStatus,
    defaultedValue,
    prepopulatedValue,
    delta,
    isEditable,
    skills,
    isRequirementInputEnabled,
  } = cell;

  const [hasPrepopulatedValue, resetPrepopulatedState] = useState(
    !isNil(prepopulatedValue)
  );

  const {
    onBlur,
    onValueChange,
    saveFte,
    updateRequestValue,
    lastSavedValue,
    isSaving,
  } = useSaveFteForCell(cell, dispatch);

  // prepopulatedValue will be set to null if user delete the value, but still need the status telling the value needs to be saved
  const isPrepopulatedState = hasPrepopulatedValue && !isNil(prepopulatedValue);

  // The isSkillsOpen flag is never cleared if set
  // When toggle the epxand header, this will trigger the skills Modal open again.
  // So, clear it when unmounting the cell
  useEffect(() => {
    return () => {
      if (cell.isSkillsOpen) {
        dispatch({
          type: ACTIONS.OPEN_SKILLS,
          cellId: cell.id,
          at: false,
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Save the update method for skills modal input field
  if (updateMethodRef) {
    // eslint-disable-next-line no-param-reassign
    updateMethodRef.current = updateRequestValue;
  }

  const onConfirm = useCallback(() => {
    if (
      isRequirementInputEnabled &&
      isSkillsMandatory &&
      isContentEmpty(skills)
    ) {
      dispatch({
        type: ACTIONS.OPEN_SKILLS,
        cellId: id,
        at: new Date().getTime(),
      });
    }
    if (isPrepopulatedState) {
      dispatch({
        type: ACTIONS.REQUEST_INPUT_UPDATE,
        cellId: id,
        value: prepopulatedValue,
      });

      saveFte(prepopulatedValue);
    } else {
      const adjustedValue = isNil(value) ? defaultedValue : value;
      // If value changed or still in prepopulated status => Need to save a value
      const valueChanged = adjustedValue !== value || hasPrepopulatedValue;

      // Disable the value changed check inside, as value may not be lastSavedValue
      saveFte(adjustedValue, !valueChanged);
    }

    // After a save, clear the prepopulated value state
    if (hasPrepopulatedValue) {
      resetPrepopulatedState(false);
    }
  }, [
    isRequirementInputEnabled,
    isSkillsMandatory,
    skills,
    isPrepopulatedState,
    hasPrepopulatedValue,
    dispatch,
    id,
    prepopulatedValue,
    saveFte,
    value,
    defaultedValue,
  ]);

  // Giving isValid and isInvalid a string value to remove warnings from React
  const isValid = valueSaveStatus === SAVE_STATUS.SUCCESS;
  const isInvalid = valueSaveStatus === SAVE_STATUS.FAILED;
  const prepopulatedDelta = isPrepopulatedState
    ? roundFte(prepopulatedValue - defaultedValue)
    : null;

  const enableSaveButton =
    !isSaving &&
    valueSaveStatus === SAVE_STATUS.PENDING &&
    (value !== lastSavedValue || hasPrepopulatedValue);

  const numberProps = {
    allowNegative: false,
    decimalScale: 2,
    onValueChange,
    onBlur,
  };

  return (
    <>
      <InputWrapper>
        <FailedIndicator isFailed={isInvalid} retry={onConfirm} />
        <PlannerRequestFteInput
          value={value}
          defaultedValue={defaultedValue}
          hasPrepopulatedValue={isPrepopulatedState}
          prepopulatedValue={prepopulatedValue}
          disabled={!isEditable}
          valid={isValid || undefined}
          invalid={isInvalid || undefined}
          numberProps={numberProps}
        />
        <InputDeltaLabel
          delta={isPrepopulatedState ? prepopulatedDelta : delta}
          inlineBlock
        />
      </InputWrapper>
      <Box ml="xs">
        <Popover
          direction="top"
          width="116px"
          textAlign="center"
          text="Confirm request"
        >
          <Button
            variant={enableSaveButton ? "success" : "disabled"}
            width="28px"
            height="28px"
            iconOnly
            ariaLabel="Confirm request"
            disabled={!enableSaveButton}
            onClick={onConfirm}
          >
            <Icon icon={["fas", "check"]} />
          </Button>
        </Popover>
      </Box>
    </>
  );
};

Input.propTypes = {
  cell: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  updateMethodRef: PropTypes.object,
};

export default Input;
