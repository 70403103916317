/* eslint-disable no-param-reassign */
import { reduce, keyBy, map, isNil, forEach, get, find } from "lodash";
import { createMacroAllocationId } from "src/allocation/util/macroAllocation";
import {
  getGroupMacroAllocations,
  requestMacroAllocationAbsolute,
} from "src/allocation/allocation.graphql";
import { peopleSearch as peopleSearchQuery } from "src/allocation/members.graphql";
import { roundFte } from "src/util/roundingStrategy";

export const countTotalSelected = (selectedPeople) => {
  let count = 0;
  forEach(selectedPeople, (node) => {
    if (!node || !node.target) {
      return;
    }
    count += 1;
  });

  return count;
};

const addToSupplyGroups = (supplyGroups, group, fte) => {
  if (group) {
    const groupingId = group.id;
    if (supplyGroups[groupingId]) {
      supplyGroups[groupingId].totalFte += fte;
    } else {
      supplyGroups[groupingId] = {
        group,
        totalFte: fte,
      };
    }
  }
};

const addToLineItems = (lineItems, lineItem, fte) => {
  const { id } = lineItem;

  if (lineItems[id]) {
    lineItems[id].totalFte += fte;
  } else {
    lineItems[id] = {
      lineItem,
      totalFte: fte,
    };
  }
};

const getCurrentFte = (allocations, fromGroupId, personFte) => {
  const targetAllocation = find(
    allocations,
    (a) => a.targetGroupId === fromGroupId
  );

  // If targetAllocation does not exist or fte does not exist, use person fte
  return get(targetAllocation, "fte", personFte);
};

/**
 *  Move the selected people to the target group.
 *  If toGroupId is not provided, it will be treated as deallocation.
 * @param {*} selectedPeople
 * @param {*} toGroupId
 * @returns
 */
export const createMutationList = (selectedPeople, toGroupId) => {
  const supplyGroups = {};
  const lineItems = {};

  const allocations = reduce(
    selectedPeople,
    (prev, node) => {
      if (!node || !node.target) {
        return prev;
      }

      const { target, parentNode } = node;
      const {
        aggregateId,
        fte: personFte,
        allocations: personAllocations,
      } = target;

      // Find lineItem and grouping
      if (!parentNode || !parentNode.isLineItem) {
        return prev;
      }

      const lineItem = parentNode.target;
      const { group: supplyGroup, grouping: parentGrouping } = lineItem;
      const { groupId: fromGroupId } = parentGrouping;

      const movedFte = getCurrentFte(personAllocations, fromGroupId, personFte);

      addToLineItems(lineItems, lineItem, movedFte);

      addToSupplyGroups(supplyGroups, supplyGroup, movedFte);

      prev.push({
        personId: aggregateId,
        requestedFte: movedFte,
        fromGroupId,
        toGroupId,
      });

      return prev;
    },
    []
  );

  return {
    allocations,
    supplyGroups,
    lineItems,
  };
};

const refetchLatestMembers = ({
  client,
  sourceGroupId,
  targetGroupId,
  getMembersQueryVariables,
}) => {
  const variables = getMembersQueryVariables({
    sourceGroupId,
    targetGroupId,
  });

  const currentResult = client.readQuery({
    query: peopleSearchQuery,
    variables,
  });

  // If not queried before
  if (!currentResult) {
    return;
  }

  client.refetchQueries({
    include: [
      {
        query: peopleSearchQuery,
        variables,
      },
    ],
  });
};

export const updateTargetGroupMacroAllocations = async ({
  client,
  allocationProjectId,
  toGroupId,
  supplyGroups,
  getMembersQueryVariables,
  enableBulkMoveWithUpdate,
}) => {
  // Fix the macro allocation records, load target group macro allocations
  const macroAllocations = await client.query({
    query: getGroupMacroAllocations,
    variables: {
      allocationProjectIds: [allocationProjectId],
      groupId: toGroupId,
    },
    fetchPolicy: "network-only",
  });

  // Compare supply group and create macro allocation records
  const maMap = keyBy(macroAllocations.data.results, "sourceGroupId");

  const promises = map(supplyGroups, (g) => {
    const { group, totalFte } = g;
    const { id: supplyGroupId } = group;

    let requestedFte = totalFte;
    const existingMa = maMap[supplyGroupId];
    if (existingMa) {
      refetchLatestMembers({
        client,
        sourceGroupId: supplyGroupId,
        targetGroupId: toGroupId,
        getMembersQueryVariables,
      });
      // For existing macro allocation, check flag to see whether to update
      if (!enableBulkMoveWithUpdate) {
        return Promise.resolve();
      }
      const { allocatedMemberFte, requested } = existingMa;
      if (isNil(requested)) {
        requestedFte = allocatedMemberFte + totalFte;
      } else {
        requestedFte = requested + totalFte;
      }
    }

    // Save macro allocation to back end
    return client.mutate({
      mutation: requestMacroAllocationAbsolute,
      variables: {
        id: createMacroAllocationId(allocationProjectId, group.id, toGroupId),
        allocationProjectId,
        sourceGroupId: group.id,
        targetGroupId: toGroupId,
        requested: roundFte(requestedFte),
      },
    });
  });

  await Promise.all(promises);
};

export const refreshChangedLineItems = async ({
  client,
  allocationProjectId,
  lineItems,
  getMembersQueryVariables,
  enableBulkMoveWithUpdate,
}) => {
  const promises = map(lineItems, (item) => {
    const { lineItem, totalFte } = item;
    const { group, grouping, cells } = lineItem;
    const sourceGroupId = group.id;
    const targetGroupId = grouping.id;

    refetchLatestMembers({
      client,
      sourceGroupId,
      targetGroupId,
      getMembersQueryVariables,
    });

    if (!enableBulkMoveWithUpdate) {
      return Promise.resolve();
    }

    const { value, defaultedValue } = cells[1] || {};
    const currentRequested = (isNil(value) ? defaultedValue : value) || 0;

    // Update macro allocation to back end
    return client.mutate({
      mutation: requestMacroAllocationAbsolute,
      variables: {
        id: createMacroAllocationId(
          allocationProjectId,
          sourceGroupId,
          targetGroupId
        ),
        allocationProjectId,
        sourceGroupId,
        targetGroupId,
        requested: roundFte(Math.max(currentRequested - totalFte, 0)),
      },
    });
  });

  await Promise.all(promises);
};
