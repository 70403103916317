import React from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";

const RemoveButton = ({ person, onRemovePerson }) => {
  const handleClicked = () => {
    onRemovePerson(person);
  };

  return (
    <ActionsMenuItem onClick={handleClicked}>Remove membership</ActionsMenuItem>
  );
};

RemoveButton.propTypes = {
  person: PropTypes.object.isRequired,
  onRemovePerson: PropTypes.func.isRequired,
};

export default RemoveButton;
