import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { get } from "lodash";
import {
  convertCommentsToHistory,
  updatePlanningHistoryRecords,
} from "src/components/History/history.util";
import { getPersonTagsVisibleInComments as getPersonTagsVisibleInCommentsQuery } from "src/comments/comments.graphql";
import { getPersonHistory as getPersonHistoryQuery } from "src/queries/person.graphql";

import LoadingPage from "src/components/LoadingPage";
import History from "src/components/History";
import { getAllCommentFilters } from "src/components/PersonDetailPage/PersonHistory.logic";

const HISTORY_PAGE_LIMIT = 200;

const PersonHistory = ({ person }) => {
  const { data: tagTypesData, loading: tagTypesLoading } = useQuery(
    getPersonTagsVisibleInCommentsQuery
  );

  const { aggregateId } = person;
  const commentFilters = useMemo(() => {
    return getAllCommentFilters({
      tagTypes: get(tagTypesData, "tagTypes", []),
      personId: aggregateId,
    });
  }, [aggregateId, tagTypesData]);

  // TODO: Add pagination for history
  const { data, loading } = useQuery(getPersonHistoryQuery, {
    variables: {
      input: {
        personId: aggregateId,
        commentFilters: [...commentFilters],
        limit: HISTORY_PAGE_LIMIT,
        offset: 0,
      },
    },
    fetchPolicy: "network-only",
    skip: tagTypesLoading,
  });

  const historyData = useMemo(() => {
    const comments = get(data, "result.comments");
    return convertCommentsToHistory({ comments, person });
  }, [data, person]);

  const historyRecords = useMemo(() => {
    return updatePlanningHistoryRecords({
      historyData,
      groupsMap: {},
      groupTypes: {},
    });
  }, [historyData]);

  if (loading || tagTypesLoading) {
    return <LoadingPage waitingFor="getCommentsQuery" />;
  }

  return <History historyRecords={historyRecords} />;
};

PersonHistory.propTypes = {
  person: PropTypes.object,
};

export default PersonHistory;
