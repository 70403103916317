import { merge } from "lodash";

export default (group, previousInitialState) => {
  const defaultInitialState = {
    selectedSupplyGroupId: group.id,
    selectedSubSupplyGroupId: null,
    targetGroupSearch: null,
    personSearch: null,
    personFilter: null,
    viewingAllocations: {
      isOpen: false,
      personId: null,
    },
  };
  const mergedState = merge(defaultInitialState, previousInitialState);
  return mergedState;
};
