import React from "react";
import PropTypes from "prop-types";
import { map, filter, isEmpty } from "lodash";
import {
  useForecastModel,
  useSearchMode,
  useGroupSearchMode,
  useViewMode,
  useSearchResult,
  VIEW_MODES,
} from "../../context/ForecastContext";
import { isGroupingHasNotifications } from "../util/quickActivityViewFilters";
import SearchErrorMessage from "../Shared/SearchErrorMessage";
import ExpandAllGroupButton from "../Shared/ExpandAllGroupButton";
import PlannerGrouping from "./PlannerGrouping";
import QuickActivityViewGrouping from "./QuickActivityView/PlannerGrouping";
import NoActivityMessage from "./QuickActivityView/NoActivityMessage";

const View = ({ groupings }) => {
  return (
    <>
      <ExpandAllGroupButton groupings={groupings} />
      {map(groupings, (grouping) => (
        <PlannerGrouping key={grouping.groupId} grouping={grouping} />
      ))}
    </>
  );
};

View.propTypes = {
  groupings: PropTypes.array,
};

const QuickActivityView = ({ groupings }) => {
  const filteredGroupings = filter(groupings, (g) =>
    isGroupingHasNotifications(g)
  );
  if (!filteredGroupings.length) {
    return <NoActivityMessage />;
  }

  return (
    <>
      {map(filteredGroupings, (grouping) => (
        <QuickActivityViewGrouping key={grouping.groupId} grouping={grouping} />
      ))}
    </>
  );
};

QuickActivityView.propTypes = {
  groupings: PropTypes.array,
};

const SearchModeView = ({ groupings }) => {
  const searchResult = useSearchResult();

  if (searchResult.error) {
    return <SearchErrorMessage message={searchResult.error.message} />;
  }

  if (searchResult && isEmpty(searchResult.expandedGroupIdsForSearch)) {
    return <SearchErrorMessage message="No matches found" />;
  }

  return (
    <>
      {map(groupings, (grouping) => (
        <PlannerGrouping key={grouping.groupId} grouping={grouping} />
      ))}
    </>
  );
};

SearchModeView.propTypes = {
  groupings: PropTypes.array,
};

const PlannerGroupings = () => {
  const {
    dynamicModel: { groupings },
  } = useForecastModel();
  const viewMode = useViewMode();
  const searchMode = useSearchMode();
  const groupSearchMode = useGroupSearchMode();

  if (searchMode || groupSearchMode) {
    return <SearchModeView groupings={groupings} />;
  }

  if (viewMode !== VIEW_MODES.QUICK_ACTIVITY_VIEW) {
    return <View groupings={groupings} />;
  }

  return <QuickActivityView groupings={groupings} />;
};

export default PlannerGroupings;
