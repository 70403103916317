import React from "react";
import PropTypes from "prop-types";
import { Box, Icon, Flex, P } from "orcs-design-system";

const SearchErrorMessage = ({ message }) => {
  return (
    <Box bg="greyLightest" borderRadius={2} m="r" p="r">
      <Flex alignItems="center">
        <Icon
          icon={["far", "search"]}
          size="lg"
          mr="s"
          color="greyDarker"
          transform="up-1"
        />
        <P color="greyDarker">{message}</P>
      </Flex>
    </Box>
  );
};

SearchErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default SearchErrorMessage;
