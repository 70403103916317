import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Icon, Button, Popover } from "orcs-design-system";

import CellPropType from "src/custom-prop-types/cell";
import { NotesCount } from "src/allocation/allocation.styled";
import { useCommentCounts } from "src/comments/context";
import { ALLOCATION_THREAD_ID } from "src/consts/comments";

import { ACTIONS } from "../../context/ForecastContext";

const Comment = ({ cell, dispatch, noteCount }) => {
  const { id } = cell;

  const onCommentClick = () => {
    dispatch({
      type: ACTIONS.OPEN_COMMENT,
      cellId: id,
    });
  };

  return (
    <Popover
      direction="topLeft"
      width="120px"
      textAlign="center"
      text="View/Add Notes"
    >
      <Button
        variant="ghost"
        width="30px"
        height="30px"
        iconOnly
        data-testid="notes-icon"
        ariaLabel="Add Note"
        onClick={onCommentClick}
        className="guided-tour-allocation-forecast-cell-comment"
      >
        <Icon
          size="lg"
          icon={
            noteCount ? ["fas", "comment-alt"] : ["far", "comment-alt-lines"]
          }
          color="primary"
        />
        {noteCount ? (
          <NotesCount data-testid="notes-count">{noteCount}</NotesCount>
        ) : null}
      </Button>
    </Popover>
  );
};

Comment.propTypes = {
  cell: CellPropType.isRequired,
  dispatch: PropTypes.func.isRequired,
  noteCount: PropTypes.number,
};

const CommentWithContext = ({ cell, dispatch }) => {
  const { targetGroupId, sourceGroupId, allocationProjectId } = cell;

  const counts = useCommentCounts();

  const noteCount = get(
    counts,
    ALLOCATION_THREAD_ID(allocationProjectId, sourceGroupId, targetGroupId)
  );

  // Only re-render when noteCount changes
  return useMemo(() => {
    return <Comment cell={cell} dispatch={dispatch} noteCount={noteCount} />;
  }, [noteCount]); // eslint-disable-line react-hooks/exhaustive-deps
};

CommentWithContext.propTypes = {
  cell: CellPropType.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default CommentWithContext;
