import { Box, Flex, P, Badge, Button } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { getGroupTypeBadgeVariant } from "src/util/group";

const FormatOptionLabel = ({ value }) => {
  if (!value) {
    return null;
  }

  return (
    <Box bg="transparent" width="100%">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          <P mr="s">Create as</P>
          <Badge
            data-testid="cp-teamlist-row-badge"
            variant={getGroupTypeBadgeVariant(value)}
            style={value.style}
          >
            {value.name}
          </Badge>
        </Flex>
        <Button small>Create</Button>
      </Flex>
    </Box>
  );
};

FormatOptionLabel.propTypes = {
  value: PropTypes.string,
};

export default FormatOptionLabel;
