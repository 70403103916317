import React from "react";
import { Flex } from "orcs-design-system";
import { isEmpty } from "lodash";
import GroupPropType from "src/custom-prop-types/group";

import { TeamHeadingPlaceholder } from "src/components/TeamDetailPage/PlaceholderComponents";
import TeamHeading from "../TeamHeading/indexV2";

const Heading = ({ team }) => {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      {isEmpty(team) ? <TeamHeadingPlaceholder /> : <TeamHeading team={team} />}
    </Flex>
  );
};

Heading.propTypes = {
  team: GroupPropType,
};

export default Heading;
