import { Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import GroupHierarchyBreadcrumb from "src/components/_DirectoryGroupHierarchyBreadcrumb";
import * as placeholder from "src/components/Placeholder/PlaceholderStyles";
import GroupPropType from "src/custom-prop-types/group";

const TeamLocation = ({ loading, team, workspace }) => {
  if (loading) {
    return (
      <Box mb="r">
        <placeholder.Line
          data-testid="cp-team-location-placeholder-line"
          width={180}
          height={20}
          mb={4}
        />
        <placeholder.Line
          data-testid="cp-team-location-placeholder-line"
          width={260}
          height={18}
        />
      </Box>
    );
  }

  return (
    <GroupHierarchyBreadcrumb
      isDarkTheme={true}
      group={team}
      workspace={workspace}
    />
  );
};

TeamLocation.propTypes = {
  loading: PropTypes.bool,
  team: GroupPropType,
  workspace: PropTypes.object,
};

export default TeamLocation;
