import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { get, map } from "lodash";
import styled from "styled-components";

import { Box, StyledLink } from "orcs-design-system";

import {
  attributeTypes,
  getAllAttributesByType,
  getDescriptionAttribute,
} from "src/util/groupAttributes";
import RichTextContent from "src/components/RichTextContent";
import { createNoDescriptionText } from "src/util/createNoDescriptionText";

const CustomUl = styled.ul`
  padding-inline-start: 20px;
  margin-top: 8px;
`;

const placeholderText =
  "Extra information will appear here as you make your selections.";

const GroupDescription = ({ group }) => {
  const effectiveGroupDetails = useMemo(() => {
    if (!group) {
      return {
        description: placeholderText,
        links: [],
      };
    }

    const attributes = get(group, "attributes");
    const teamDescription = getDescriptionAttribute(attributes);
    const links = getAllAttributesByType(attributes, attributeTypes.LINK);
    const description =
      links.length && !get(teamDescription, "value")
        ? ""
        : get(teamDescription, "value") || createNoDescriptionText(group);

    return {
      description,
      links,
    };
  }, [group]);

  return (
    <Box
      p="s"
      bg="greyLightest"
      borderRadius={2}
      boxBorder="default"
      maxHeight="400px"
      height="100%"
      overflow="auto"
    >
      <RichTextContent
        fontSize="14px"
        content={get(effectiveGroupDetails, "description")}
      />
      <CustomUl>
        {map(get(effectiveGroupDetails, "links"), (link) => (
          <li key={link.id}>
            <StyledLink href={link.value} target="_blank">
              <small>{link.label}</small>
            </StyledLink>
          </li>
        ))}
      </CustomUl>
    </Box>
  );
};

GroupDescription.propTypes = {
  group: PropTypes.object,
};

export default GroupDescription;
