import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Loading } from "orcs-design-system";

import ModalHeader from "src/components/ModalHeader";
import TeamDescription from "src/components/TeamDescription";

const TeamDescriptionModal = ({
  loading,
  isVisible,
  group,
  attributes,
  onClose,
}) => {
  return (
    <Modal
      visible={isVisible}
      handleOnConfirm={onClose}
      onClose={onClose}
      maxWidth="90vw"
      width="70vw"
      height="70vh"
      maxHeight="90vh"
      headerContent={<ModalHeader group={group} />}
      footerContent={<Button onClick={onClose}>Close</Button>}
    >
      {loading && <Loading large centered m="r" />}
      {!loading && <TeamDescription group={group} attributes={attributes} />}
    </Modal>
  );
};

TeamDescriptionModal.propTypes = {
  loading: PropTypes.bool,
  isVisible: PropTypes.bool,
  group: PropTypes.object,
  attributes: PropTypes.array,
  onClose: PropTypes.func,
};

export default React.memo(TeamDescriptionModal);
