import { useApolloClient } from "@apollo/client";
import { debounce, get, isEmpty, reduce, split, without } from "lodash";
import { Button, Flex, Icon, Divider } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useMemo } from "react";
import AllocationConfigPropType from "src/custom-prop-types/allocationConfig";
import ErrorNotification from "src/components/ErrorNotification";

import { listTeamsInAllocation } from "src/allocation/team.graphql";

import TeamDropdown from "src/allocation/components/TeamDropdown";

import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";
import { getGroupTypeFilters } from "../../util/search";

const createTeamSelectOptions = (groups, existingAllocations) =>
  reduce(
    groups,
    (result, group) => {
      // if group isn't marked as hidden and not already selected as an existing allocation
      if (!group.isHidden && !existingAllocations.includes(group.id)) {
        return [
          ...result,
          {
            label: group.name,
            value: group.id,
            hierarchy: group.hierarchy,
            type: group.type,
          },
        ];
      }
      return result;
    },
    []
  );

const getQueryVars = (keywordArray = "", typeFilters = []) => ({
  search: without(split(keywordArray, " "), "") || [keywordArray],
  typeFilters,
  limit: 10,
});

const IndividualAllocationTeamSearch = React.memo(
  ({
    testOnlyQuery = undefined,
    existingAllocations,
    isAddingToNewGroup,
    onAddToNewGroup,
    onTeamSelected,
    allocationConfig,
  }) => {
    const groupTypes = useGroupTypes();
    const client = useApolloClient();
    const [searchString, setSearchString] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [error, setError] = useState(null);
    const typeFilters = getGroupTypeFilters(allocationConfig, groupTypes);
    const skipFetchingTeams =
      !isEmpty(allocationConfig?.targetTypes) && isEmpty(typeFilters);

    const loadOptions = useMemo(() => {
      return debounce((input, callback) => {
        if (skipFetchingTeams) {
          callback([]);
          return;
        }
        setIsSearching(true);
        client
          .query({
            query: listTeamsInAllocation,
            variables: getQueryVars(input, typeFilters),
          })
          .then(({ data: newData, error: queryError }) => {
            if (queryError) {
              setError(queryError);
              return;
            }

            callback(
              newData
                ? createTeamSelectOptions(
                    get(newData, "result.groups", []),
                    existingAllocations
                  )
                : []
            );
            setIsSearching(false);
          });
      });
    }, [client, existingAllocations, skipFetchingTeams, typeFilters]);

    const onSelectTeam = (option) => {
      onTeamSelected(option ? option.value : null);
      setSearchString(option || "");
    };

    const onAllocateToTeam = () => {
      setSearchString("");
      onAddToNewGroup();
    };

    const isDisabled = !searchString ? "disabled" : "success";

    return (
      <>
        <Flex alignItems="flex-end" py="r" pr="s">
          <TeamDropdown
            id="team-select-dropdown"
            label="Allocate to"
            placeholder="Search and select team"
            isSearchable={true}
            isLoading={isSearching}
            options={true}
            loadOptions={debounce(loadOptions, DEBOUNCE_SEARCH_TIME)}
            onSelectTeam={onSelectTeam}
            value={testOnlyQuery || searchString}
          />
          <Button
            iconLeft
            variant={isDisabled}
            large
            ml="r"
            height="54px"
            onClick={onAllocateToTeam}
            isLoading={isAddingToNewGroup}
          >
            <Icon icon={["fas", "plus"]} />
            Add
          </Button>
        </Flex>
        {error && (
          <ErrorNotification
            message="Sorry, an error occurred, please try searching again."
            error={error}
          />
        )}
        <Divider light />
      </>
    );
  }
);

IndividualAllocationTeamSearch.propTypes = {
  testOnlyQuery: PropTypes.string,
  existingAllocations: PropTypes.arrayOf(PropTypes.string),
  isAddingToNewGroup: PropTypes.bool,
  onAddToNewGroup: PropTypes.func,
  onTeamSelected: PropTypes.func,
  allocationConfig: AllocationConfigPropType.isRequired,
};

export default IndividualAllocationTeamSearch;
