import { useEffect } from "react";
import { forEach, isEmpty, keys, keyBy } from "lodash";

import {
  BaseTeamParentFragment,
  getGroups as getGroupsQuery,
} from "src/allocation/team.graphql";
import { useAllocationConfig } from "src/contexts/global/WorkspaceContext/WorkspaceContext";

import {
  useMainQuery,
  useModelLookups,
  useLookupData,
  useDispatch,
  ACTIONS,
} from "./context/ForecastContext";
import useCachedGroup from "./components/Shared/hooks/useCachedGroup";

export const updateGroupsLookup = ({
  loading,
  switchToLoadSupplyGroups,
  lineItemLookup,
  groupLookup,
  getCachedGroup,
  dispatch,
  client,
}) => {
  if (loading || !switchToLoadSupplyGroups) {
    return;
  }

  const parentGroups = {};
  const missingGroupIds = {};

  forEach(lineItemLookup, (lineItem) => {
    const { group } = lineItem;
    const { parentIds } = group;

    forEach(parentIds, (parentId) => {
      // If group already exists in lookup data
      if (
        groupLookup[parentId] ||
        parentGroups[parentId] ||
        missingGroupIds[parentId]
      ) {
        return;
      }

      const cachedGroup = getCachedGroup(parentId);

      if (cachedGroup) {
        parentGroups[parentId] = cachedGroup;
      } else {
        missingGroupIds[parentId] = true;
      }
    });
  });

  if (!isEmpty(parentGroups)) {
    dispatch({
      type: ACTIONS.UPDATE_GROUPS_LOOKUP,
      groups: parentGroups,
    });
  }

  if (!isEmpty(missingGroupIds)) {
    // Load missing groups
    client
      .query({
        query: getGroupsQuery,
        variables: { groupIds: keys(missingGroupIds) },
      })
      .then((result) => {
        // If there is any error, skip update.. wait for the retry
        if (!result || result.error) {
          return;
        }

        const { data } = result;

        dispatch({
          type: ACTIONS.UPDATE_GROUPS_LOOKUP,
          groups: keyBy(data.groups, "id"),
        });
      });
  }
};

const useUpdateLineItemHierarchies = () => {
  const { loading } = useMainQuery();
  const dispatch = useDispatch();
  const { lineItemLookup } = useModelLookups();
  const { groupLookup } = useLookupData();
  const { getCachedGroup, client } = useCachedGroup(BaseTeamParentFragment);
  const { switchToLoadSupplyGroups } = useAllocationConfig();

  useEffect(() => {
    updateGroupsLookup({
      loading,
      switchToLoadSupplyGroups,
      lineItemLookup,
      groupLookup,
      getCachedGroup,
      dispatch,
      client,
    });
  }, [
    loading,
    lineItemLookup,
    groupLookup,
    getCachedGroup,
    dispatch,
    client,
    switchToLoadSupplyGroups,
  ]);
};

export default useUpdateLineItemHierarchies;
