import React from "react";
import PropTypes from "prop-types";

import {
  useWorkspace,
  useGroupTypes,
} from "src/contexts/global/WorkspaceContext";
import ObjectiveItems from "./ObjectiveItems";
import LinkObjectives from "./LinkObjectives";
import { getLinkObjectiveReadOnly } from "./utils/getLinkObjectiveReadOnly";

const ObjectivesBody = ({
  grouping,
  objectives,
  dispatch,
  removeObjective,
  isUpdating,
  activeAllocationProject,
  inlineStyle,
}) => {
  const workspace = useWorkspace();
  const groupTypes = useGroupTypes();

  const linkObjectiveReadOnly = getLinkObjectiveReadOnly({
    workspace,
    grouping,
    groupTypes,
    activeAllocationProject,
  });

  return (
    <>
      <ObjectiveItems
        grouping={grouping}
        objectives={objectives}
        dispatch={dispatch}
        removeObjective={removeObjective}
        isUpdating={isUpdating}
        linkObjectiveReadOnly={linkObjectiveReadOnly}
        inlineStyle={inlineStyle}
      />
      <LinkObjectives
        grouping={grouping}
        objectives={objectives}
        dispatch={dispatch}
        readOnly={false}
        linkObjectiveReadOnly={linkObjectiveReadOnly}
      />
    </>
  );
};

ObjectivesBody.propTypes = {
  grouping: PropTypes.object.isRequired,
  objectives: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  removeObjective: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  inlineStyle: PropTypes.bool,
  activeAllocationProject: PropTypes.object.isRequired,
};

export default ObjectivesBody;
