import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import personIcon from "../../../assets/fa-icons/fa-user.svg";
import teamIcon from "../../../assets/fa-icons/fa-users.svg";
import sourceIcon from "../../../assets/fa-icons/fa-users-supply.svg";
import divisionIcon from "../../../assets/fa-icons/fa-chart-pie.svg";
import companyIcon from "../../../assets/fa-icons/fa-building.svg";
import jobIcon from "../../../assets/fa-icons/fa-address-card.svg";
import locationIcon from "../../../assets/fa-icons/fa-map-marker-alt.svg";
import {
  NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION,
  NODE_RELATIONSHIP_TYPE_TEAM,
  NODE_RELATIONSHIP_TYPE_SOURCE_TEAM,
} from "../../../util/buildNodes";

export const COLORS_MAPPING = (theme) => {
  return {
    Division: `${themeGet("colors.primaryLight")({ theme })}`,
    Location: `${themeGet("colors.warningLight")({ theme })}`,
    Company: `${themeGet("colors.primary")({ theme })}`,
    Person: `${themeGet("colors.grey")({ theme })}`,
    Team: `${themeGet("colors.success")({ theme })}`,
    SourceTeam: `${themeGet("colors.secondaryLight")({ theme })}`,
    Job: `${themeGet("colors.dangerLight")({ theme })}`,
    [NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION]: `${themeGet("colors.success")({
      theme,
    })}`,
  };
};

export const BG_COLORS_MAPPING = (theme) => {
  return {
    Division: `${themeGet("colors.primaryLightest")({ theme })}`,
    Location: `${themeGet("colors.warningLightest")({ theme })}`,
    Company: `${themeGet("colors.primaryLighter")({ theme })}`,
    Person: `${themeGet("colors.greyLighter")({ theme })}`,
    Team: `${themeGet("colors.successLightest")({ theme })}`,
    SourceTeam: `${themeGet("colors.secondaryLighter")({ theme })}`,
    Job: `${themeGet("colors.dangerLightest")({ theme })}`,
    [NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION]: `${themeGet(
      "colors.successLightest"
    )({ theme })}`,
  };
};

export const ICONS_MAPPING = {
  Division: divisionIcon,
  Location: locationIcon,
  Company: companyIcon,
  Person: personIcon,
  Team: teamIcon,
  SourceTeam: sourceIcon,
  Job: jobIcon,
  [NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION]: teamIcon,
};

export const initialNode = (orcsTheme) => ({
  id: "InitialNode",
  type: "Team",
  label: "Loading",
  fill: BG_COLORS_MAPPING(orcsTheme).Team,
  borderColor: COLORS_MAPPING(orcsTheme).Team,
});

export const convertOriginalData = (data, typesToExclude) => {
  const rawNodes = [];
  const rawEdges = [];
  const nodes = [];
  const edges = [];
  const rejectedIds = [];

  Object.values(data).forEach((node) => {
    if (node.type === "node") {
      const newNode = {
        id: node.id,
        type: node.data.type,
        label: node.label.text,
        fill: node.color,
        borderColor: node.border.color,
        icon: ICONS_MAPPING[node.fontIcon.text],
        url: node.url,
        counter: node.counter,
      };

      rawNodes.push(newNode);
    }

    if (node.type === "link") {
      const newEdge = {
        id: node.id,
        type: node.type,
        label: node.relationshipType,
        target: node.id2,
        source: node.id1,
      };

      rawEdges.push(newEdge);
    }
  });

  rawNodes.forEach((node) => {
    if (typesToExclude.includes(node.type)) {
      rejectedIds.push(node.id);
    } else {
      nodes.push(node);
    }
  });

  rawEdges.forEach((edge) => {
    if (
      !rejectedIds.includes(edge.target) &&
      !rejectedIds.includes(edge.source)
    ) {
      edges.push(edge);
    }
  });

  return { nodes, edges };
};

export const convertData = (data, typesToExclude, orcsTheme, targetNode) => {
  const rawNodes = [];
  const rawEdges = [];
  const nodes = [];
  const edges = [];
  const rejectedIds = [];

  data.forEach((node) => {
    const newNode = {
      id: node.id,
      type: node.type,
      label: node.name,
      fill: BG_COLORS_MAPPING(orcsTheme)[node.type],
      borderColor: COLORS_MAPPING(orcsTheme)[node.type],
      icon: ICONS_MAPPING[node.type],
      // url : node.url,
      // counter : node&.glyphs&.value
    };

    if (
      (node.type === NODE_RELATIONSHIP_TYPE_TEAM ||
        node.type === NODE_RELATIONSHIP_TYPE_SOURCE_TEAM ||
        node.type === NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION) &&
      node.glyphs &&
      node.glyphs[0]
    ) {
      newNode.counter = node.glyphs[0].value;
    }

    rawNodes.push(newNode);

    node.relationships.forEach((edge) => {
      if (edge.ids) {
        edge.ids.forEach((id) => {
          const newEdge = {
            id: `${node.id}-${id}`,
            type: edge.type,
            label: edge.type,
            target: id,
            source: node.id,
          };

          rawEdges.push(newEdge);
        });
      }
    });
  });

  rawNodes.forEach((node) => {
    if (typesToExclude.includes(node.type) && targetNode?.id !== node?.id) {
      rejectedIds.push(node.id);
    } else {
      nodes.push(node);
    }
  });

  rawEdges.forEach((edge) => {
    if (
      !rejectedIds.includes(edge.target) &&
      !rejectedIds.includes(edge.source)
    ) {
      edges.push(edge);
    }
  });

  return { nodes, edges };
};

export const convertTypes = (settings) => {
  const types = [];

  if (!settings) {
    return types;
  }

  if (!settings.company) {
    types.push("Company");
  }
  if (!settings.division) {
    types.push("Division");
  }
  if (!settings.job) {
    types.push("Job");
  }
  if (!settings.location) {
    types.push("Location");
  }

  if (!settings.person) {
    types.push("Person");
    types.push("Company");
    types.push("Division");
    types.push("Job");
    types.push("Location");
  }

  if (!settings.groupAssociation) {
    types.push(NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION);
  }

  // showDescendants: false
  // includeEmptyTeams: true

  return types;
};

export const GraphWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const ControlsWrapper = styled.div`
  z-index: 9;
  position: absolute;
  top: ${themeGet("space.s")};
  right: ${themeGet("space.s")};
  background: ${themeGet("colors.black60")};
  padding: ${themeGet("space.s")};
  border-radius: ${themeGet("radii.2")};
  color: ${themeGet("colors.white")};
`;

export const getCanvasTheme = (theme) => {
  return {
    canvas: {
      background: themeGet("colors.white")({ theme }),
    },
    node: {
      label: {
        stroke: themeGet("colors.white")({ theme }),
        color: themeGet("colors.black")({ theme }),
        activeColor: themeGet("colors.black")({ theme }),
      },
    },
    ring: {
      fill: themeGet("colors.warning")({ theme }),
      activeFill: themeGet("colors.warning")({ theme }),
    },
    lasso: {
      border: `1px solid ${themeGet("colors.primary70")({ theme })}`,
      background: themeGet("colors.primary10")({ theme }),
    },
    edge: {
      fill: themeGet("colors.greyLight")({ theme }),
      activeFill: themeGet("colors.warning")({ theme }),
      opacity: 0.5,
      inactiveOpacity: 0.5,
      selectedOpacity: 0.8,
      label: {
        stroke: themeGet("colors.white")({ theme }),
        color: themeGet("colors.black")({ theme }),
        activeColor: themeGet("colors.black")({ theme }),
      },
    },
    arrow: {
      fill: themeGet("colors.greyLight")({ theme }),
      activeFill: themeGet("colors.warning")({ theme }),
    },
  };
};
