import React, { useMemo } from "react";
import { Flex, FlexItem } from "orcs-design-system";
import PropTypes from "prop-types";
import { Line as PlaceholderLine } from "src/components/Placeholder/PlaceholderStyles";
import { Theme, TargetHandle, SourceHandle } from "./node.styled";
import BaseNode from "./BaseNode";
import { getObjectiveTheme } from "./utils";

const LoadingNode = ({ data, sourcePosition, targetPosition, id }) => {
  const theme = useMemo(() => getObjectiveTheme(data), [data]);

  return (
    <BaseNode
      nodeId={id}
      expanded={true}
      loading={data.loading}
      theme={theme}
      visible={true}
    >
      {data.hasTarget && data.parentIds.length && (
        <TargetHandle
          id="left-handle"
          type="source"
          position={targetPosition}
          clickable
          isConnectable={false}
          $expanded={data.expanded}
          $color={Theme.company.light}
          $theme={theme}
        />
      )}

      <Flex alignContent="flex-start" mb="r">
        <PlaceholderLine height={40} width={40} animationSpeed="20s" />

        <FlexItem flexGrow="1">
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
            ml="r"
            mb="r"
          >
            <FlexItem flexGrow>
              <Flex flexDirection="column">
                <FlexItem mb="s">
                  <PlaceholderLine width={240} height={24} />
                </FlexItem>

                <PlaceholderLine width={120} height={24} />
              </Flex>
            </FlexItem>

            <FlexItem flex="0 0 auto">
              <PlaceholderLine width={100} height={24} />
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>

      {data.hasSource && data.hasChildren && (
        <SourceHandle
          type="target"
          position={sourcePosition}
          isConnectable={false}
          $theme={theme}
          $expanded={data.expanded}
        />
      )}
    </BaseNode>
  );
};

LoadingNode.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    expanded: PropTypes.bool,
    loading: PropTypes.bool,
    visible: PropTypes.bool,
    hasTarget: PropTypes.bool,
    hasSource: PropTypes.bool,
    domainCount: PropTypes.number,
    teamCount: PropTypes.number,
    memberCount: PropTypes.number,
    isLayouted: PropTypes.bool,
    hasChildren: PropTypes.bool,
    dataSource: PropTypes.string,
    attributes: PropTypes.arrayOf(PropTypes.object),
    parentIds: PropTypes.arrayOf(PropTypes.string),
  }),
  sourcePosition: PropTypes.string,
  targetPosition: PropTypes.string,
};

export default LoadingNode;
