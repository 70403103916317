import React from "react";
import { Flex, H2, Badge, Box, Avatar } from "orcs-design-system";
import PropTypes from "prop-types";
import { fullDisplayName, displayInitials } from "src/util/personName";
import { roundFte } from "src/util/roundingStrategy";

const Header = ({ person, supplySourceGroup }) => {
  const { avatar: image, jobTitle: subtitle } = person;
  const title = fullDisplayName(person);
  const initials = displayInitials(person);

  return (
    <Flex alignItems="center" data-testid="person-allocation-header">
      <H2 mr="s">Allocations for</H2>

      <Avatar
        sizing="small"
        title={title}
        subtitle={subtitle}
        initials={initials}
        image={image}
      />

      <Box ml="l">
        <Badge>{roundFte(person.fte)} FTE</Badge>
      </Box>
      {supplySourceGroup && (
        <Box ml="s">
          <Badge variant="secondary">{supplySourceGroup.name}</Badge>
        </Box>
      )}
    </Flex>
  );
};

Header.propTypes = {
  person: PropTypes.shape({
    firstName: PropTypes.string,
    surname: PropTypes.string,
    preferredName: PropTypes.string,
    fte: PropTypes.number,
    avatar: PropTypes.string,
    jobTitle: PropTypes.string,
  }),
  supplySourceGroup: PropTypes.object,
};

export default Header;
