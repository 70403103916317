import React from "react";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

import DeltaLabel from "./DeltaLabel";

const Wrapper = styled.div`
  position: absolute;
  right: 1px;
  top: 1px;
  font-size: 1.3rem;
  color: ${themeGet("colors.secondary")};
  background-color: #efefef;
  padding: 5px;
  border-radius: 0 6px 6px 0;
  border-left: solid 1px rgba(0, 0, 0, 0.1);
  height: calc(100% - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputDeltaLabel = (props) => {
  return (
    <Wrapper>
      <DeltaLabel {...props} />
    </Wrapper>
  );
};
