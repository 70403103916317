import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import SavedStatusIcon from "../SavedStatusIcon";

const StatusWrapper = styled.div`
  width: 16px;
`;

const Status = ({ status, isDirty = true }) => (
  <StatusWrapper>
    <SavedStatusIcon status={status} isDirty={isDirty} />
  </StatusWrapper>
);

Status.propTypes = {
  isDirty: PropTypes.bool,
  status: PropTypes.string,
};

export default Status;
