import React, { createContext, useCallback, useContext, useState } from "react";
import { PropTypes } from "prop-types";

const EdgeEventContext = createContext();

export const EDGE_EVENTS = {
  CLICK: "rf-edge-click",
  MOUSE_ENTER: "rf-edge-mouseenter",
  MOUSE_LEAVE: "rf-edge-mouseleave",
  MOUSE_MOVE: "rf-edge-mousemove",
};

const dispatchEdgeEvent = (eventName, e, edge) => {
  document.dispatchEvent(new CustomEvent(eventName, { detail: { edge, e } }));
};

export const EdgeEventProvider = ({ children }) => {
  const [hoveredEdge, setHoveredEdge] = useState();

  const onEdgeMouseEnter = useCallback((e, edge) => {
    setHoveredEdge(edge);

    dispatchEdgeEvent(EDGE_EVENTS.MOUSE_ENTER, e, edge);
  }, []);
  const onEdgeMouseLeave = useCallback((e, edge) => {
    setHoveredEdge(undefined);

    dispatchEdgeEvent(EDGE_EVENTS.MOUSE_LEAVE, e, edge);
  }, []);

  const onEdgeMouseMove = useCallback((e, edge) => {
    dispatchEdgeEvent(EDGE_EVENTS.MOUSE_MOVE, e, edge);
  }, []);

  const onEdgeClick = useCallback((e, edge) => {
    dispatchEdgeEvent(EDGE_EVENTS.CLICK, e, edge);
  }, []);

  return (
    <EdgeEventContext.Provider
      value={{
        hoveredEdge,
        setHoveredEdge,
        reactFlowProps: {
          onEdgeMouseEnter,
          onEdgeMouseLeave,
          onEdgeClick,
          onEdgeMouseMove,
        },
      }}
    >
      {children}
    </EdgeEventContext.Provider>
  );
};

EdgeEventProvider.propTypes = { children: PropTypes.node };

export const useEdgeEvents = () => {
  return useContext(EdgeEventContext);
};
