import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Flex, FlexItem, H3 } from "orcs-design-system";

import FancyCard, {
  FancyCardHeader,
  FancyCardBody,
  FancyCardFooter,
} from "src/components/FancyCard/FancyCard";

import IndividualPersonAllocationPage from "./IndividualPersonAllocationPage";
import SupplyGroupAllocation from "./SupplyGroupAllocation";

const StyledForm = styled(Box)`
  position: relative;
  z-index: 10;
`;

const IndividualPersonAllocationPageLayout = (...children) => {
  return (
    <StyledForm>
      <H3 bold>Group / Team allocation</H3>
      <Box>
        {children[0]}
        {children[1]}
      </Box>
      <FancyCardFooter>{children[2]}</FancyCardFooter>
    </StyledForm>
  );
};

const PersonAllocationPage = ({
  allocationProjectId,
  person,
  personId,
  personFte,
  onPersonAllocating,
  onPersonAllocated,
  onPersonUnallocated,
  onPersonAllocationUpdated,
  withSupplyGroupAllocation = false,
  onSupplyGroupChanged,
  withPageTitle = true,
}) => {
  if (!withSupplyGroupAllocation) {
    return (
      <IndividualPersonAllocationPage
        allocationProjectId={allocationProjectId}
        personId={personId}
        personFte={personFte}
        onPersonAllocating={onPersonAllocating}
        onPersonAllocated={onPersonAllocated}
        onPersonUnallocated={onPersonUnallocated}
        onPersonAllocationUpdated={onPersonAllocationUpdated}
      />
    );
  }

  return (
    <FancyCard>
      {withPageTitle && (
        <FancyCardHeader>
          <H3 bold>Allocations</H3>
        </FancyCardHeader>
      )}
      <FancyCardBody>
        <Flex
          width="100%"
          pt="r"
          flexDirection={[
            "column-reverse",
            "column-reverse",
            "column-reverse",
            "column-reverse",
            "row",
          ]}
        >
          <FlexItem flex="1 1 50%" mr={["0", "0", "0", "0", "s"]}>
            <IndividualPersonAllocationPage
              allocationProjectId={allocationProjectId}
              personId={personId}
              personFte={personFte}
              onPersonAllocating={onPersonAllocating}
              onPersonAllocated={onPersonAllocated}
              onPersonUnallocated={onPersonUnallocated}
              onPersonAllocationUpdated={onPersonAllocationUpdated}
              layoutFunc={IndividualPersonAllocationPageLayout}
            />
          </FlexItem>
          <FlexItem
            flex="1 1 50%"
            ml={["0", "0", "0", "0", "s"]}
            mb={["r", "r", "r", "r", "0"]}
          >
            <SupplyGroupAllocation
              person={person}
              onSupplyGroupChanged={onSupplyGroupChanged}
            />
          </FlexItem>
        </Flex>
      </FancyCardBody>
    </FancyCard>
  );
};

PersonAllocationPage.propTypes = {
  allocationProjectId: PropTypes.string,
  person: PropTypes.object,
  personId: PropTypes.string,
  personFte: PropTypes.number,
  onPersonAllocating: PropTypes.func,
  onPersonAllocated: PropTypes.func,
  onPersonUnallocated: PropTypes.func,
  onPersonAllocationUpdated: PropTypes.func,
  withSupplyGroupAllocation: PropTypes.bool,
  onSupplyGroupChanged: PropTypes.func,
  withPageTitle: PropTypes.bool,
};

export default PersonAllocationPage;
