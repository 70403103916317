import { Editable } from "slate-react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const StyledEditor = styled(Editable)`
  font-size: 1.4rem;
  line-height: 2rem;
  min-height: 100px !important;
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  background: ${(props) =>
    props.disabled
      ? themeGet("colors.greyLightest")(props)
      : themeGet("colors.white")(props)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};
  border-radius: ${(props) => themeGet("radii.2")(props)};
  margin: ${(props) => themeGet("space.s")(props)} 0;
  padding: 0;
  border: 8px solid white;
  transition: ${(props) => themeGet("transition.transitionDefault")(props)};
  box-shadow: 0 0 0 1px
    ${(props) =>
      props.invalid
        ? themeGet("colors.dangerLight")(props)
        : props.valid
        ? themeGet("colors.successLight")(props)
        : themeGet("colors.black20")(props)};
  &:hover {
    box-shadow: 0 0 0 1px ${(props) => themeGet("colors.primary")(props)};
  }
  &:focus {
    box-shadow: 0 0 0 1px
      ${(props) =>
        props.invalid
          ? themeGet("colors.danger")(props)
          : props.valid
          ? themeGet("colors.success")(props)
          : themeGet("colors.primary")(props)};
  }
  }
  h1,
  h2 {
    line-height: 3rem;
    margin-bottom: 1rem;
  }
`;

export const StyledToolbar = styled.div`
  position: relative;
  padding: ${(props) => themeGet("space.s")(props)};
  background: ${(props) => themeGet("colors.greyLightest")(props)};
  border-radius: ${(props) => themeGet("radii.2")(props)};
  display: flex;
  & > * + * {
    margin-left: ${(props) => themeGet("space.s")(props)};
  }
`;

export const StyledRteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  box-shadow: none;
  text-decoration: none;
  text-align: center;
  width: 26px;
  height: 25px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: ${(props) => themeGet("radii.2")(props)};
  border: 0;
  background: transparent;
  transition: ${(props) => themeGet("transition.transitionDefault")(props)};
  cursor: pointer;
  color: ${(props) =>
    props.isReversed && props.isActive
      ? themeGet("colors.white")(props)
      : props.isActive
      ? themeGet("colors.black")(props)
      : themeGet("colors.grey")(props)};
  &:hover {
    background: ${(props) => themeGet("colors.greyLighter")(props)};
  }
  svg {
    position: relative;
    top: 1px;
    left: 0;
  }
`;
