import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { map, isEmpty, maxBy, get } from "lodash";
import pluralize from "pluralize";
import styled from "styled-components";
import { Grid, H5, FlexItem, Flex } from "orcs-design-system";

import GroupTypeBadge from "src/components/GroupTypeBadge";
import { numberToLocaleString } from "src/util/toLocaleString";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";

import { ACTIONS, useAllocationProjects } from "../../context/ForecastContext";
import useGridConfig from "../util/gridConfig";

import CustomGrid from "../Shared/CustomGrid";
import TreeExpand from "../Shared/TreeExpand";
import NotificationDot from "../Shared/NotificationDot";
import FteQueryBadgeForSupply from "../Shared/FteQueryBadgeForSupply";

import ConstraintInput from "../Constraint/ConstraintInput";
import ConstraintAlert from "../Constraint/ConstraintAlert";
import TotalOverrideInput from "../Constraint/TotalOverrideInput";

const ConstraintGrid = styled(Grid)`
  align-items: center;
`;

const Title = ({ grouping }) => {
  const { group, isRoot, isVirtualDirectConstraintGrouping, notifications } =
    grouping;
  const { name, type, isHidden } = group;
  const supplyLabel = get(
    useWorkspace(),
    "config.allocation.supplyLabel",
    "Role"
  );

  if (isVirtualDirectConstraintGrouping) {
    return (
      <Flex alignItems="center">
        <H5>
          Direct {type} {pluralize(supplyLabel)}
        </H5>
      </Flex>
    );
  }

  const notification = maxBy(map(notifications, "notification", "status"));

  return (
    <Flex alignItems="center">
      <H5>{isHidden ? <strike>{name}</strike> : name}</H5>
      <FlexItem flex="0 0 auto" mx="xs">
        <GroupTypeBadge
          group={group}
          showToolTip
          toolTipPosition="bottomRight"
        />
      </FlexItem>
      <FlexItem flex="0 0 auto" mr="2">
        <FteQueryBadgeForSupply
          isSupply={true}
          group={group}
          queryDirectWorkforce={true}
        />
      </FlexItem>
      <FlexItem flex="0 0 auto" mx="xs">
        <NotificationDot
          notification={notification}
          isGrouping
          isRootGrouping={isRoot}
        />
      </FlexItem>
    </Flex>
  );
};

Title.propTypes = {
  grouping: PropTypes.object.isRequired,
};

const ConstraintGroupingExpandHeader = ({
  grouping,
  isExpanded,
  dispatch,
  enableMembersInForecast,
}) => {
  const {
    id,
    isVirtualDirectConstraintGrouping,
    group,
    nestedLevel,
    summedTotals,
    constraints,
    canAllocate,
  } = grouping;
  const allocationProjects = useAllocationProjects();
  const gridConfig = useGridConfig();

  const hasChildGroups = !isEmpty(grouping.childGroupings);
  const { templateColumns, gap, cellSubGrid } = gridConfig.constraint;

  const handleToggle = useCallback(() => {
    dispatch({
      type: ACTIONS.TOGGLE_GROUPING_TREE_EXPANSION,
      groupId: id,
    });
  }, [id, dispatch]);

  const cells = map(summedTotals, (totalItem, index) => {
    const { total, constraintTotal, isConstraintTotalAllDefaults, columnId } =
      totalItem;

    const constraintCell = constraints[index];
    const adjustedTotal = isConstraintTotalAllDefaults
      ? grouping.capacityFte
      : constraintTotal;
    const isBold = hasChildGroups && "bold";
    const { allocationProjectId } = constraintCell;
    const allocationProject = get(allocationProjects, allocationProjectId);
    const { enableApproverForecastConstraint } = allocationProject;

    return (
      <ConstraintGrid
        key={`${id}-${columnId}-${index}`}
        gridGap={cellSubGrid.gap}
        gridTemplateColumns={cellSubGrid.templateColumns}
      >
        {canAllocate ? (
          <H5 weight={isBold} textAlign="center">
            {numberToLocaleString(total)}
          </H5>
        ) : (
          <div />
        )}
        {enableApproverForecastConstraint && hasChildGroups ? (
          <>
            <TotalOverrideInput
              total={adjustedTotal}
              uniqueId={`to-${group.id}-${columnId}-${index}`}
              constraintCell={constraintCell}
              dispatch={dispatch}
            />
            <ConstraintAlert
              constraintCell={constraintCell}
              total={adjustedTotal}
            />
          </>
        ) : (
          <ConstraintInput
            constraintCell={constraintCell}
            dispatch={dispatch}
          />
        )}
      </ConstraintGrid>
    );
  });

  return (
    <TreeExpand
      isOpen={isExpanded}
      indentLevel={nestedLevel}
      hasToggle={hasChildGroups || enableMembersInForecast}
      onToggle={handleToggle}
      hasConnectedTreeExpand={hasChildGroups}
      controlsGroupRoles={isVirtualDirectConstraintGrouping}
    >
      <CustomGrid gridTemplateColumns={templateColumns} gridGap={gap}>
        <Title grouping={grouping} />
        {cells}
      </CustomGrid>
    </TreeExpand>
  );
};

ConstraintGroupingExpandHeader.propTypes = {
  grouping: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool,
  dispatch: PropTypes.func,
  enableMembersInForecast: PropTypes.bool,
};

export default ConstraintGroupingExpandHeader;
