/* eslint-disable import/prefer-default-export */
import { get, includes } from "lodash";

export const findTargetParentGroup = (roleTypes, node) => {
  let { parent } = node;
  while (parent && !includes(roleTypes, parent.group.type)) {
    parent = parent.parent;
  }

  return get(parent, "group", null);
};
