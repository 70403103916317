import React from "react";
import PropTypes from "prop-types";
import { Flex, Spacer, H5 } from "orcs-design-system";
import { numberToLocaleString } from "src/util/toLocaleString";
import { roundFte } from "src/util/roundingStrategy";
import DeltaLabel from "../../Shared/DeltaLabel";
import { useLookupData } from "../../../context/ForecastContext";
import RequestedFTEInput from "./RequestedFTEInput";

const RealTimeFTECell = ({ cell, dispatch }) => {
  const {
    currentMemberFteLabel,
    totalChangeLabel,
    macroAllocationDeltaFteLabel,
    desiredInputAsDelta,
    showMacroAllocationInput,
  } = useLookupData();

  const { realtimeCurrentMemberFte, defaultedValue, delta } = cell;
  const memberFteChange = realtimeCurrentMemberFte - defaultedValue;
  const isValid = !isNaN(memberFteChange);

  return (
    <Flex
      width="100%"
      className="guided-tour-allocation-planner-requestor-cell"
      justifyContent="center"
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="inherit"
        width="100%"
      >
        <small>{currentMemberFteLabel || "Direct change"}</small>
        <Flex
          alignItems="center"
          flexDirection="row"
          justifyContent="inherit"
          width="100%"
        >
          <Spacer mx="xs">
            <H5 fontWeight="bold">
              {numberToLocaleString(realtimeCurrentMemberFte)}
            </H5>
            <span>(</span>
            <DeltaLabel delta={isValid ? roundFte(memberFteChange) : ""} />
            <span>)</span>
          </Spacer>
        </Flex>
      </Flex>
      {showMacroAllocationInput && (
        <>
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="inherit"
            width="100%"
          >
            <small>{macroAllocationDeltaFteLabel || "Desired"}</small>
            <RequestedFTEInput
              cell={cell}
              dispatch={dispatch}
              desiredInputAsDelta={desiredInputAsDelta}
            />
          </Flex>
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="inherit"
            width="100%"
          >
            <small>{totalChangeLabel || "Total Change"}</small>
            <DeltaLabel delta={delta} />
          </Flex>
        </>
      )}
    </Flex>
  );
};

RealTimeFTECell.propTypes = {
  cell: PropTypes.object,
  dispatch: PropTypes.func,
};

export default RealTimeFTECell;
