import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";

import { ACTIONS } from "../../../context/ForecastContext";

const BulkMoveMembersButton = ({ grouping, dispatch }) => {
  const handleBulkMove = useCallback(
    (e) => {
      e.stopPropagation();

      dispatch({
        type: ACTIONS.SHOW_BULK_MOVE_MEMBERS_MODAL,
        grouping,
      });
    },
    [grouping, dispatch]
  );

  return (
    <ActionsMenuItem onClick={handleBulkMove}>Move members</ActionsMenuItem>
  );
};

BulkMoveMembersButton.propTypes = {
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default BulkMoveMembersButton;
