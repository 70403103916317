import { Badge } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { numberToLocaleString } from "src/util/toLocaleString";

const FteBadge = ({ fte }) => {
  return <Badge>{numberToLocaleString(fte)} FTE</Badge>;
};

FteBadge.propTypes = {
  fte: PropTypes.number,
};

export default FteBadge;
