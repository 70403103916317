import { find, map } from "lodash";

export const getOptionByValue = (options, value) => {
  return find(options, (o) => o.value === value) || null;
};

export const getSelectedOptions = (optionsByKey, values) => {
  return map(values, (v) => {
    return optionsByKey[v];
  }).filter((v) => !!v);
};

export const buildOptions = (options) => {
  return map(options, (key) => ({
    label: key,
    value: key,
  }));
};
