import { get, isEmpty } from "lodash";
import {
  usePositioner,
  useResizeObserver,
  useMasonry,
  useInfiniteLoader,
} from "masonic";
import { useSize, useScroller } from "mini-virtual-list";
import { Icon, H2, Small } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

import icons from "src/config/icons";

import { copywriting, sizing, searchConfig } from "../Unsplash.config";
import { MasonryStyled } from "../Unsplash.styled";
import { unsplashSearch } from "../Unsplash.util";

import useCheckSearchTermDiff from "./hooks/useCheckSearchTermDiff";
import MasonryCard from "./MasonryCard";

const Masonry = ({ items = [], isLoading, onSelect, searchTerm, unsplash }) => {
  /* masonry setup */
  // to derive window sizings inside a modal
  const hasSearchTermChanged = useCheckSearchTermDiff(searchTerm);
  const containerRef = useRef(null);
  const isCancelHook = useRef(false);
  const { width, height } = useSize(containerRef);
  const { scrollTop, isScrolling } = useScroller(containerRef);
  const positioner = usePositioner(
    {
      width,
      columnWidth: sizing.CARD_WIDTH,
      columnGutter: sizing.CARD_GUTTER,
    },
    // when positioner is re-evaluated, the masonic component is reset
    // https://codesandbox.io/s/masonic-w-react-router-and-advanced-config-example-8em42?file=/src/index.js:966-1308
    // Known issue of the masonic library, workaround to force re-render
    // https://github.com/jaredLunde/masonic/issues/12
    [searchTerm, hasSearchTermChanged && Math.random()]
    // [searchTerm, hasItemLengthChanged && Math.random()]
  );
  const resizeObserver = useResizeObserver(positioner);

  /* business logic */
  const isMoreFetching = useRef(false);
  const prevSearchTerm = useRef();
  const page = useRef(2); // start lazy loading from page 2
  const [currentImageId, setCurrentImageId] = useState();
  const [results, setResults] = useState(items);
  const [loadMoreError, setLoadMoreError] = useState();

  useEffect(() => {
    const shouldUpdateResults =
      !isLoading && prevSearchTerm.current !== searchTerm;

    if (shouldUpdateResults) {
      // reset the page
      page.current = 2;
      // update the grid result
      setResults(items);
      // store the previously searched term
      prevSearchTerm.current = searchTerm;
    }
  }, [isLoading, items, results, searchTerm]);

  useEffect(() => {
    return () => {
      isCancelHook.current = true;
    };
  }, []);

  const onLoadMore = useInfiniteLoader(
    async () => {
      const isDifferentSearch = prevSearchTerm.current !== searchTerm;

      if (!prevSearchTerm.current && isDifferentSearch) {
        return;
      }

      isMoreFetching.current = true;
      const { data, error } = await unsplashSearch(
        unsplash,
        searchTerm,
        page.current
      );
      isMoreFetching.current = false;
      // only set state if the component isn't unmounting to prevent memory leaks
      if (!isCancelHook.current && !isLoading) {
        if (!isEmpty(data)) {
          setLoadMoreError(null);
          setResults([...results, ...data]);
          page.current += 1;
        }
        if (error) {
          setLoadMoreError(error);
        }
      }
    },
    {
      isItemLoaded: (index, newItems) => {
        // do not process further lazy loads until the current fetch is complete
        if (isMoreFetching.current) return true;
        return !!newItems[index];
      },
      threshold: searchConfig.THRESHOLD,
      minimumBatchSize: searchConfig.LIMIT,
    }
  );

  const handleCardClick = useCallback((imgProps) => {
    onSelect(imgProps);
    setCurrentImageId(get(imgProps, "id"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // inject an onClick handler into masonic
  const MasonryCardWithClick = useCallback(
    (props) => (
      <MasonryCard
        {...props}
        selected={currentImageId}
        onClick={() => handleCardClick(get(props, "data"))}
      />
    ),
    // any time a selection changes, re-render the current flowed cards
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentImageId]
  );

  // important - no conditional statements in JSX render as this is a hook
  const MasonryComponent = useMasonry({
    positioner,
    resizeObserver,
    itemKey: (item) => item?.id,
    items: results,
    height,
    scrollTop,
    isScrolling,
    overscanBy: searchConfig.OVERSCANBY,
    render: MasonryCardWithClick,
    onRender: onLoadMore,
  });

  const isDisplayPlaceholder = isEmpty(results) && !isLoading;

  return (
    <>
      {isDisplayPlaceholder && (
        <H2
          data-testid="cp-unsplash-placeholder"
          color="greyDark"
          sizing="large"
          pt="l"
          textAlign="center"
        >
          <Icon icon={icons.search} mr="r" />
          {copywriting.RESULTS_PLACEHOLDER}
        </H2>
      )}
      <MasonryStyled
        data-testid="cp-unsplash-masonry-container"
        ref={containerRef}
      >
        {!isLoading && MasonryComponent}
      </MasonryStyled>
      {loadMoreError && (
        <Small
          color="danger"
          fontWeight="bold"
          display="block"
          p="s"
          textAlign="center"
        >
          {copywriting.LOADMORE_ERROR}
        </Small>
      )}
    </>
  );
};

Masonry.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  unsplash: PropTypes.object,
  searchTerm: PropTypes.string,
};

export default Masonry;
