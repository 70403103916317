import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import { isEmpty } from "lodash";
import urlPaths, { url, TEAM_TAB } from "src/consts/urlPaths";

const GroupFeaturePermitted = ({
  group,
  tabType,
  groupFeatureVisibility,
  children,
}) => {
  if (isEmpty(group)) {
    return null;
  }

  const isValidRoute =
    (tabType === TEAM_TAB.VISUALISATION &&
      groupFeatureVisibility.visualisation) ||
    (tabType === TEAM_TAB.PRINCIPLES && groupFeatureVisibility.principles) ||
    (tabType === TEAM_TAB.HISTORY && groupFeatureVisibility.history);

  if (!isValidRoute) {
    const pathname = url(urlPaths.TEAM_DETAILS, {
      id: group?.id,
      tabType: TEAM_TAB.DETAILS,
    });

    return <Redirect to={{ pathname }} />;
  }

  return children;
};

GroupFeaturePermitted.propTypes = {
  group: PropTypes.object,
  tabType: PropTypes.string.isRequired,
  groupFeatureVisibility: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default GroupFeaturePermitted;
