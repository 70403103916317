import { useCallback, useMemo, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { sortBy, map } from "lodash";
import moment from "moment";

import {
  useGroupTypes,
  useAllocationConfig,
} from "src/contexts/global/WorkspaceContext";
import { isDemandGroup, isSupplyGroup } from "src/util/customerConfig";
import { NOOP } from "src/util/consts";
import {
  getPersonMemberships,
  addMembership as addMembershipGql,
  mutateMemberships as mutateMembershipsGql,
  removeMembership as removeMembershipGql,
  updateMembership as updateMembershipGql,
} from "src/queries/memberships.graphql";
import { getLoadStartDate, getLoadEndDate } from "src/util/memberships";

import {
  toFloat,
  getLatestUnallocated,
  getCurrentFuturePastMemberships,
  buildNewMembership,
  buildMembershipsToUpdate,
  convertMembershipToRemove,
  convertMembershipToUpdate,
  formatDate,
} from "../util/membership";
import { VIEW_MODES } from "../components/ViewModeSwitcher";
import { validateMembership } from "../util/validateMembership";

const sortByGroupName = (memberships) => {
  return sortBy(memberships, (m) => m.group.name);
};

// Hopefully the API can return it sorted.
const byDates = (a, b) => {
  // Compare start dates
  if (a.startDate < b.startDate) return -1;
  if (a.startDate > b.startDate) return 1;

  // Handle cases where endDate is null
  if (a.endDate === null && b.endDate !== null) return 1;
  if (a.endDate !== null && b.endDate === null) return -1;

  // If start dates are equal, compare end dates
  if (a.endDate !== null && b.endDate !== null) {
    if (a.endDate < b.endDate) return -1;
    if (a.endDate > b.endDate) return 1;
  }

  return 0;
};

export const useMemberships = (
  {
    person,
    teamToAdd,
    teamToAddError,
    onMembershipUpdating = NOOP,
    onMembershipAdded = NOOP,
    onMembershipRemoved = NOOP,
    onMembershipFteUpdated = NOOP,
    onMembershipsUpdated = NOOP,
  },
  viewMode,
  fteConfig
) => {
  const groupTypes = useGroupTypes();
  const {
    enableAllocationFteCap: enableFteCap,
    enableSavingZeroFteAgainstIndividuals: isZeroFteAllowed,
  } = useAllocationConfig();
  const { aggregateId } = person;
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(teamToAdd);

  const isTimelineView = viewMode === VIEW_MODES.TIMELINE;
  const searchInput = useMemo(() => {
    return {
      personIds: [aggregateId],
      groupKinds: ["team"],
      statuses: ["ALLOCATED"],
      selectAncestorIds: true,
      selectGroup: true,
      startDate: getLoadStartDate(isTimelineView),
      endDate: getLoadEndDate(isTimelineView),
    };
  }, [aggregateId, isTimelineView]);

  const { data, loading: isLoading } = useQuery(getPersonMemberships, {
    variables: {
      aggregateId,
      input: searchInput,
    },
    fetchPolicy: "cache-and-network",
    skip: !aggregateId,
  });

  // All memberships
  const memberships = useMemo(() => {
    if (!data?.person?.memberships) {
      return [];
    }

    const withMoment = (membership) => {
      return {
        ...membership,
        startDate: moment(membership.startDate).startOf("day"),
        endDate: membership.endDate
          ? moment(membership.endDate).endOf("day")
          : null,
      };
    };

    // Standardise the dates to moment objects and sort by startDate, endDate
    return [...data.person.memberships].map(withMoment);
  }, [data]);

  // Supply memberships.
  const supplyMemberships = useMemo(() => {
    // Filter supply teams without endDate or endDate is future
    const startOfToday = moment().startOf("day");

    return sortByGroupName(
      memberships.filter(
        ({ group, endDate }) =>
          isSupplyGroup(groupTypes, group) &&
          (!endDate || endDate.isAfter(startOfToday))
      )
    ).sort(byDates);
  }, [groupTypes, memberships]);

  // Demand memberships
  const demandMemberships = useMemo(() => {
    return sortByGroupName(
      memberships.filter(({ group }) => isDemandGroup(groupTypes, group))
    ).sort(byDates);
  }, [groupTypes, memberships]);

  const [currentMemberships, futureMemberships, pastMemberships] =
    useMemo(() => {
      return getCurrentFuturePastMemberships(demandMemberships);
    }, [demandMemberships]);

  // Allocated total FTE
  const fteTotal = useMemo(() => {
    return toFloat(
      currentMemberships.reduce((sum, { fte }) => sum + fte, 0),
      fteConfig.decimal
    );
  }, [currentMemberships, fteConfig]);

  // Available FTE to fill
  const fteAvailable = useMemo(() => {
    if (!person) {
      return fteTotal;
    }

    return toFloat(person.fte - fteTotal, fteConfig.decimal);
  }, [person, fteTotal, fteConfig]);

  // Object to create a new membership.
  const newMembership = useMemo(() => {
    const membership = getLatestUnallocated({
      memberships: demandMemberships,
      capacityFte: person?.fte,
      fteConfig,
    });

    const fte = membership?.availableFte;

    return {
      key: `${person?.aggregateId}-${selectedGroup?.id}-${fte}`,
      personId: person?.aggregateId,
      groupId: selectedGroup?.id,
      startDate: membership?.startDate,
      endDate: membership?.endDate,
      fte,
    };
  }, [demandMemberships, fteConfig, person, selectedGroup]);

  // Create a new membership
  const [addMembershipMutation] = useMutation(addMembershipGql);

  const addMembership = useCallback(
    async (params, onUpdateStatus = NOOP) => {
      const {
        personId,
        groupId,
        startDate,
        endDate,
        fte,
        existingFte,
        existingMembership,
      } = params;

      try {
        setIsUpdating(true);
        onUpdateStatus({ isProcessing: true });
        onMembershipUpdating(true);

        validateMembership({
          person,
          updatedMembership: params,
          demandMemberships,
          existingFte,
          existingMembership,
          isZeroFteAllowed,
          enableFteCap,
          fteConfig,
        });

        const result = await addMembershipMutation({
          variables: {
            input: {
              personId,
              groupId,
              startDate: formatDate(startDate),
              endDate: formatDate(endDate),
              fte,
            },
            searchInput,
          },
        });

        setSelectedGroup(null);
        const newMemberships = result.data.results.person.memberships;
        onMembershipAdded(newMemberships, params);
      } catch (e) {
        onUpdateStatus({ error: e });
      } finally {
        setIsUpdating(false);
        onMembershipUpdating(false);
        onUpdateStatus({ isProcessing: false });
      }
    },
    [
      onMembershipUpdating,
      person,
      demandMemberships,
      isZeroFteAllowed,
      enableFteCap,
      addMembershipMutation,
      searchInput,
      setSelectedGroup,
      onMembershipAdded,
      fteConfig,
    ]
  );

  // Update a membership
  const [updateMembershipMutation] = useMutation(updateMembershipGql);

  const updateMembership = useCallback(
    async (mutatedMembership, onUpdateStatus = NOOP) => {
      const {
        currentCompositeId,
        startDate,
        endDate,
        fte,
        existingMembership,
        existingFte,
      } = mutatedMembership;

      try {
        setIsUpdating(true);
        onMembershipUpdating(true);
        onUpdateStatus({ isProcessing: true });

        validateMembership({
          person,
          updatedMembership: mutatedMembership,
          demandMemberships,
          existingMembership,
          existingFte,
          isZeroFteAllowed,
          enableFteCap,
          fteConfig,
        });

        const result = await updateMembershipMutation({
          variables: {
            input: {
              currentCompositeId,
              startDate: formatDate(startDate),
              endDate: formatDate(endDate),
              fte,
            },
            searchInput,
          },
        });

        const newMemberships = result.data.results.person.memberships;
        onMembershipFteUpdated(newMemberships, mutatedMembership);
      } catch (e) {
        onUpdateStatus({ error: e });
      } finally {
        setIsUpdating(false);
        onMembershipUpdating(false);
        onUpdateStatus({ isProcessing: false });
      }
    },
    [
      onMembershipUpdating,
      person,
      demandMemberships,
      isZeroFteAllowed,
      enableFteCap,
      updateMembershipMutation,
      searchInput,
      onMembershipFteUpdated,
      fteConfig,
    ]
  );

  // Destroy a membership
  const [removeMembershipMutation] = useMutation(removeMembershipGql);

  const removeMembership = useCallback(
    async (membership, onUpdateStatus = NOOP) => {
      const { currentCompositeId } = membership;

      try {
        setIsUpdating(true);
        onUpdateStatus({ isProcessing: true });

        const result = await removeMembershipMutation({
          variables: { input: { currentCompositeId }, searchInput },
        });

        const newMemberships = result.data.results.person.memberships;
        onMembershipRemoved(newMemberships, membership);
      } catch (e) {
        onUpdateStatus({ error: e });
      } finally {
        setIsUpdating(false);
        onUpdateStatus({ isProcessing: false });
        onMembershipUpdating(false);
      }
    },
    [
      onMembershipRemoved,
      onMembershipUpdating,
      removeMembershipMutation,
      searchInput,
    ]
  );

  // Update memberships with multiple mutations
  const [mutateMembershipsMutation] = useMutation(mutateMembershipsGql);

  // Change a membership from one group to another
  const updateMembershipGroup = useCallback(
    async (
      { membershipToAdd, membershipToRemove, membershipToUpdate },
      onUpdateStatus = NOOP
    ) => {
      try {
        setIsUpdating(true);
        onUpdateStatus({ isProcessing: true });

        const { personId, groupId } = membershipToAdd;

        await mutateMembershipsMutation({
          variables: {
            input: {
              newMemberships: buildNewMembership(membershipToAdd),
              updatedMemberships: convertMembershipToUpdate(membershipToUpdate),
              removedMemberships: convertMembershipToRemove(membershipToRemove),
            },
            searchInput,
          },
        });

        onMembershipsUpdated({
          person: { aggregateId: personId },
          existingGroups: [
            membershipToUpdate?.group,
            membershipToRemove?.group,
          ].filter((g) => !!g),
          targetGroups: [{ id: groupId }],
        });
      } catch (e) {
        onUpdateStatus({ error: e });
      } finally {
        setIsUpdating(false);
        onUpdateStatus({ isProcessing: false });
      }
    },
    [mutateMembershipsMutation, searchInput, onMembershipsUpdated]
  );

  // End all existing memberships and add person to new group
  const moveMembership = useCallback(
    async (params, onUpdateStatus = NOOP) => {
      try {
        setIsUpdating(true);
        onUpdateStatus({ isProcessing: true });

        await mutateMembershipsMutation({
          variables: {
            input: {
              newMemberships: buildNewMembership(params),
              updatedMemberships: buildMembershipsToUpdate(
                currentMemberships,
                params
              ),
            },
            searchInput,
          },
        });

        onMembershipsUpdated({
          person: { aggregateId: params.personId },
          existingGroups: map(currentMemberships, "group"),
          targetGroups: [{ id: params.groupId }],
        });
      } catch (e) {
        onUpdateStatus({ error: e });
      } finally {
        setIsUpdating(false);
        onUpdateStatus({ isProcessing: false });
      }
    },
    [
      currentMemberships,
      mutateMembershipsMutation,
      onMembershipsUpdated,
      searchInput,
    ]
  );

  return {
    person,
    data,
    isLoading,
    isLoaded: !!data,
    isUpdating,
    memberships,
    supplyMemberships,
    demandMemberships,
    currentMemberships,
    newMembership,
    selectedGroup,
    setSelectedGroup,
    futureMemberships,
    pastMemberships,
    fteTotal,
    fteAvailable,
    addMembership,
    moveMembership,
    removeMembership,
    updateMembership,
    updateMembershipGroup,
    teamToAddError,
  };
};
