import { map, get, isUndefined } from "lodash";
import { Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";

import CollapsedList from "src/components/CollapsedList";
import CollapseBtn from "src/components/CollapsedList/CollapseButton";
import ExpandButton from "src/components/CollapsedList/ExpandButton";
import FluidGrid from "src/components/FluidGrid";
import { Circle } from "src/components/Placeholder/PlaceholderStyles";
import TeamListItem from "src/components/Team/TeamListItem";
import { useReadonlyLayoutFlags } from "src/hooks/useLayoutFlags";
import useShortList from "src/hooks/useShortList";
import {
  getGroupInitials,
  sortGroupsByName,
  getGroupBgImg,
  sortGroupsByOptions,
} from "src/util/group";
import { getTeamsPath } from "src/util/paths";

const FullList = ({
  loading,
  supportedGroups,
  groupTypes,
  withSort,
  selectedSort,
  newChildId,
  tagsByGroupId,
  actionItem,
  cardView,
  successNotificationMessage,
  renderFteBadge,
  renderPrincipleStatus,
  workspace,
  showAssociationTagBadges,
}) => {
  let targetGroups = supportedGroups;

  if (withSort) {
    targetGroups = sortGroupsByOptions(
      supportedGroups,
      selectedSort,
      newChildId
    );
  }
  const groupsLength = targetGroups.length - 1;

  return map(targetGroups, (group, index) => (
    <Box data-testid="cp-personitem-row" key={group.id}>
      <TeamListItem
        successNotificationMessage={successNotificationMessage}
        loading={loading}
        group={group}
        groupTypes={groupTypes}
        isLastItem={index === groupsLength}
        added={!!newChildId && newChildId === group.id}
        tags={get(tagsByGroupId, group.id)}
        actionItem={actionItem}
        cardView={cardView}
        renderFteBadge={renderFteBadge}
        renderPrincipleStatus={renderPrincipleStatus}
        workspace={workspace}
        showAssociationTagBadges={showAssociationTagBadges}
      />
    </Box>
  ));
};

FullList.propTypes = {
  loading: PropTypes.bool,
  supportedGroups: PropTypes.array,
  groupTypes: PropTypes.object,
  actionItem: PropTypes.elementType,
  successNotificationMessage: PropTypes.string,
  renderFteBadge: PropTypes.func,
  renderPrincipleStatus: PropTypes.func,
  workspace: PropTypes.object,
  showAssociationTagBadges: PropTypes.bool,
};

const GroupsList = ({
  loading,
  supportedGroups,
  groupTypes,
  testListLength = 0,
  withSort = false,
  selectedSort,
  newChildId = null,
  tagsByGroupId,
  actionItem,
  largerColumns = false,
  successNotificationMessage,
  renderFteBadge,
  renderPrincipleStatus,
  workspace,
  showAssociationTagBadges = false,
  teamsListcontainerRef,
  toggleTeamsList,
  teamsFullListMode = undefined,
}) => {
  const [fullListMode, setFullListMode] = useState(false);
  const fullListModeLocal = !isUndefined(teamsFullListMode)
    ? teamsFullListMode
    : fullListMode;

  const [listLength, containerRef] = useShortList(
    fullListModeLocal,
    testListLength,
    teamsListcontainerRef
  );

  const toggleList = useCallback(() => {
    setFullListMode((mode) => !mode);
  }, []);

  let list = null;

  const {
    layoutFlags: { cardView },
  } = useReadonlyLayoutFlags();

  if (!fullListModeLocal && listLength > 0) {
    list = (
      <CollapsedList
        loading={loading}
        items={supportedGroups}
        sortItems={sortGroupsByName}
        getItemInitials={getGroupInitials}
        getAvatar={getGroupBgImg}
        getLinkPath={getTeamsPath}
        listLength={listLength}
        onListClick={toggleTeamsList || toggleList}
        shape="square"
        uppercase={false}
        idField="id"
        placeholder={
          <Circle
            width={48}
            height={48}
            data-testid="short-works-with-list-placeholder"
          />
        }
      />
    );
  } else {
    list = (
      <FullList
        loading={loading}
        supportedGroups={supportedGroups}
        groupTypes={groupTypes}
        withSort={withSort}
        selectedSort={selectedSort}
        newChildId={newChildId}
        tagsByGroupId={tagsByGroupId}
        actionItem={actionItem}
        cardView={cardView}
        successNotificationMessage={successNotificationMessage}
        renderFteBadge={renderFteBadge}
        renderPrincipleStatus={renderPrincipleStatus}
        workspace={workspace}
        showAssociationTagBadges={showAssociationTagBadges}
      />
    );
    if (cardView) {
      list = (
        <FluidGrid largerColumns={largerColumns} mt={largerColumns ? "s" : "r"}>
          {list}
        </FluidGrid>
      );
    }
  }

  const isExpandable = listLength > 0 && supportedGroups.length > 0;

  return (
    <div ref={containerRef}>
      {fullListModeLocal && isExpandable && (
        <CollapseBtn onClick={toggleTeamsList || toggleList} />
      )}
      {list}
      {!fullListModeLocal && isExpandable && (
        <ExpandButton onClick={toggleTeamsList || toggleList} />
      )}
    </div>
  );
};

GroupsList.propTypes = {
  loading: PropTypes.bool,
  largerColumns: PropTypes.bool,
  supportedGroups: PropTypes.array,
  testListLength: PropTypes.number,
  groupTypes: PropTypes.object,
  withSort: PropTypes.bool,
  selectedSort: PropTypes.object,
  newChildId: PropTypes.string,
  tagsByGroupId: PropTypes.object,
  actionItem: PropTypes.elementType,
  successNotificationMessage: PropTypes.string,
  renderFteBadge: PropTypes.func,
  renderPrincipleStatus: PropTypes.func,
  workspace: PropTypes.object,
  showAssociationTagBadges: PropTypes.bool,
  teamsListcontainerRef: PropTypes.object,
  toggleTeamsList: PropTypes.func,
  teamsFullListMode: PropTypes.bool,
};

export default GroupsList;
