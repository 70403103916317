import React, { useMemo } from "react";
import pluralize from "pluralize";
import {
  Badge,
  ButtonLink,
  Divider,
  Flex,
  FlexItem,
  H4,
  Icon,
  Small,
  Text,
} from "orcs-design-system";
import PropTypes from "prop-types";
import icons from "src/config/icons";
import { OBJECTIVE_ATTRIBUTE_TYPE } from "src/consts/objectives";
import ObjectiveProgressBar from "../ObjectiveProgressBar";
import { Theme, TargetHandle, SourceHandle, StyledBadge } from "./node.styled";
import BaseNode from "./BaseNode";
import { getObjectiveTheme } from "./utils";

const ObjectiveNode = ({ data, id, xPos, yPos }) => {
  const theme = useMemo(() => getObjectiveTheme(data), [data]);

  const count = useMemo(() => {
    return {
      domains:
        !!data.domainCount && pluralize("Domain", data.domainCount, true),
      teams: !!data.teamCount && pluralize("Team", data.teamCount, true),
      members:
        !!data.memberCount && pluralize("Member", data.memberCount, true),
    };
  }, [data.domainCount, data.teamCount, data.memberCount]);

  const externalUrl = data.attributes?.find(
    (attr) => attr.attributeType === OBJECTIVE_ATTRIBUTE_TYPE.EXTERNAL_URL
  )?.value;

  const isLayouted = xPos !== 0 && yPos !== 0;

  return (
    <BaseNode
      nodeId={id}
      loading={data.loading}
      theme={theme}
      visible={isLayouted}
      fadedNode={data.fadedNode}
      active={data.active}
    >
      {data.hasTarget && data.parentIds.length && (
        <TargetHandle
          id="left-handle"
          type="source"
          position="left"
          clickable
          isConnectable={false}
          $color={Theme.company.light}
          $theme={theme}
          hasParentsToLoad={data.hasParentsToLoad}
        />
      )}

      <Flex alignContent="flex-start" mb="r">
        <StyledBadge variant={theme?.targetColor || theme?.color}>
          {theme.Icon ? (
            <img
              src={theme.Icon}
              alt={`Brand logo for ${data?.dataSource} import type`}
            />
          ) : (
            <Icon
              size="lg"
              icon={["fas", "bullseye-arrow"]}
              color={theme?.targetColor || theme?.color}
            />
          )}
        </StyledBadge>

        <FlexItem flexGrow="1">
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
            ml="r"
            mb="r"
          >
            <FlexItem flexGrow>
              <H4 sizing="small" mb="xs">
                {data.label}
              </H4>
            </FlexItem>

            <FlexItem flex="0 0 auto">
              <Badge variant={theme?.color}>
                <Text>{data.name}</Text>
              </Badge>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
      <Divider light dash mb="s" />
      <Flex justifyContent="space-between" alignItems="center" px="xs">
        <Small>Shared by</Small>
        {["domains", "teams", "members"].map((key) => (
          <Small color="grey" key={`text-${key}`}>
            {count[key]}
          </Small>
        ))}

        {externalUrl && (
          <ButtonLink
            display="flex"
            iconOnly
            height="25px"
            width="25px"
            variant="ghost"
            href={externalUrl}
            target="_blank"
          >
            <Icon icon={icons.externalLink} />
          </ButtonLink>
        )}
      </Flex>
      {data.hasSource && data.hasChildren && (
        <SourceHandle
          type="target"
          position="right"
          isConnectable={false}
          $theme={theme}
          hasChildrenToLoad={data.hasChildrenToLoad}
        />
      )}
      <ObjectiveProgressBar mt="s" containerWidth={100} objective={data} />
    </BaseNode>
  );
};

ObjectiveNode.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    hasChildrenToLoad: PropTypes.bool,
    hasParentsToLoad: PropTypes.bool,
    loading: PropTypes.bool,
    visible: PropTypes.bool,
    hasTarget: PropTypes.bool,
    hasSource: PropTypes.bool,
    domainCount: PropTypes.number,
    teamCount: PropTypes.number,
    memberCount: PropTypes.number,
    isLayouted: PropTypes.bool,
    hasChildren: PropTypes.bool,
    dataSource: PropTypes.string,
    attributes: PropTypes.arrayOf(PropTypes.object),
    parentIds: PropTypes.arrayOf(PropTypes.string),
    fadedNode: PropTypes.bool,
    active: PropTypes.bool,
  }),
  xPos: PropTypes.number,
  yPos: PropTypes.number,
};

export default ObjectiveNode;
