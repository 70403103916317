import { useEffect } from "react";

import { saveExpandedIds } from "./context/expandedIds.util";
import {
  useMainQuery,
  useViewMode,
  useExpandedGroupIds,
  useExpandedLineItemsGroupIds,
  useSearchMode,
  useGroupSearchMode,
  usePageMode,
  useRootGroup,
} from "./context/ForecastContext";

const usePersistExpandedIds = () => {
  const { loading } = useMainQuery();
  const rootGroup = useRootGroup();
  const pageMode = usePageMode();
  const viewMode = useViewMode();
  const searchMode = useSearchMode();
  const groupSearchMode = useGroupSearchMode();
  const expandedGroupIds = useExpandedGroupIds();
  const expandedLineItemsGroupIds = useExpandedLineItemsGroupIds();

  useEffect(() => {
    if (loading || searchMode || groupSearchMode) {
      return;
    }

    saveExpandedIds({
      group: rootGroup,
      pageMode,
      viewMode,
      value: {
        previouslyExpandedGroupIds: expandedGroupIds,
        previouslyExpandedLineItemsGroupIds: expandedLineItemsGroupIds,
      },
    });
  }, [
    loading,
    pageMode,
    viewMode,
    searchMode,
    expandedGroupIds,
    expandedLineItemsGroupIds,
    groupSearchMode,
    rootGroup,
  ]);
};

export default usePersistExpandedIds;
