import { reduce, merge } from "lodash";
import themeGet from "@styled-system/theme-get";
import {
  NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION,
  NODE_RELATIONSHIP_TYPE_MANAGING,
} from "../../../util/buildNodes";
import { supportedNodes, linkStyle, supportedGlyphs } from "./nodeStyles";

const createLink = ({ relationship, id1, id2 }, theme) => {
  const link = {
    ...linkStyle(theme),
    id: `${id1}-${id2}`,
    type: "link",
    end1: {
      arrow: true,
    },
    id1: relationship.type === NODE_RELATIONSHIP_TYPE_MANAGING ? id2 : id1,
    id2: relationship.type === NODE_RELATIONSHIP_TYPE_MANAGING ? id1 : id2,
    relationshipType: relationship.type,
  };
  if (relationship.type === NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION) {
    link.label = { ...link.label };
    link.lineStyle = "dotted";
    link.end1.arrow = false;
  }
  return link;
};
/**
 * This function creates relationship between node1 identified by id
 * and node2 under the relationship.
 *
 * @param id
 * @param relationship
 * @returns {Array}
 */
const linksFor = ({ id: id1 }, relationship, theme) => {
  return reduce(
    relationship.ids,
    (result, id2) => ({
      ...result,
      [`${id1}-${id2}`]: createLink({ relationship, id1, id2 }, theme),
    }),
    {}
  );
};

/**
 * This function creates all the links of the entity.
 * e.g.
 * for an entity:
 *  { id: "id1"
 *    level: 2
 *    name: "Tiger"
 *    relationships: ["id21","id22","id23","id24","id25","id26", "id27", "id28","id29",]
 *  }
 * Then this function returns the 9 links ["id1", id"21]...["id1", "id29"].
 *
 * @param entity
 * @returns Object
 */
const createLinks = (entity, theme) => {
  const links = reduce(
    entity.relationships,
    (result, relationshipsOfType) => ({
      ...result,
      ...linksFor(entity, relationshipsOfType, theme),
    }),
    {}
  );
  return links;
};

const createGlyph = (theme) => (glyph) => ({
  ...supportedGlyphs(theme)[glyph.type],
  label: {
    text: glyph.value,
  },
});

/**
 * This function creates the node of an entity.
 * it also check whether this entity is the target. If it is, apply the target style.
 * @param entity
 * @param targetNodeId
 * @returns {{id, type: string, t, d: {collapsed: boolean, level, type}}}
 */
const createNode = (entity, targetNodeId, theme) => {
  const configuration = supportedNodes(theme)[entity.type];
  const isTarget = entity.id === targetNodeId;
  const hasStyle = !!entity.style;
  const style =
    configuration.style[
      isTarget ? "target" : hasStyle ? entity.style : "default"
    ];

  const baseNode = {
    id: entity.id,
    type: "node",
    label: {
      text: entity.name,
    },
    data: { collapsed: false, level: entity.level, type: entity.type },
    glyphs: entity.glyphs ? entity.glyphs.map(createGlyph(theme)) : undefined,
    fontIcon: configuration.icon
      ? {
          text: configuration.icon,
          color: themeGet("colors.black50")({ theme }),
        }
      : undefined,
  };
  return merge(baseNode, style);
};

/**
 * This function creates nodes and links according to the data array.
 * Also apply different style to the target node.
 * @param data
 * @param targetNodeId
 */
export default (data, targetNodeId, theme) => {
  return reduce(
    data,
    (result, i) => ({
      ...result,
      [i.id]: createNode(i, targetNodeId, theme),
      ...createLinks(i, theme),
    }),
    {}
  );
};
