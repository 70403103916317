const getPlaceholders = ({
  targetGroupId,
  selectedGroupsFte = {},
  vacantRoleLabel,
}) => {
  return Object.keys(selectedGroupsFte).reduce((acc, chapterId) => {
    const numberOfVacancies = selectedGroupsFte[chapterId];
    for (let i = 0; i < numberOfVacancies; i += 1) {
      acc.push({
        preferredName: vacantRoleLabel,
        fte: 1,
        directTeamIds: [targetGroupId, chapterId],
        isPlaceholder: true,
      });
    }
    return acc;
  }, []);
};

export default getPlaceholders;
