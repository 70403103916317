import React, { useMemo, useCallback } from "react";
import pluralize from "pluralize";
import {
  Avatar,
  ButtonLink,
  Flex,
  FlexItem,
  H4,
  Icon,
  Text,
} from "orcs-design-system";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import GroupTypeBadge from "src/components/GroupTypeBadge";

import icons from "src/config/icons";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import PATHS, { url, TEAM_TAB } from "src/consts/urlPaths";
import { Theme, TargetHandle } from "./node.styled";
import BaseNode from "./BaseNode";

const TeamNode = ({ id, data, xPos, yPos }) => {
  const groupTypes = useGroupTypes();
  const history = useHistory();

  const count = useMemo(() => {
    return data.memberCount && pluralize("Member", data.memberCount, true);
  }, [data.memberCount]);

  const isLayouted = xPos !== 0 && yPos !== 0;
  const teamUrl = url(PATHS.TEAM_DETAILS, {
    id,
    tabType: TEAM_TAB.STRATEGY,
  });

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      history.push(teamUrl);
    },
    [history, teamUrl]
  );

  return (
    <BaseNode
      expanded={data.expanded}
      theme={Theme.team}
      visible={isLayouted}
      fadedNode={data.fadedNode}
      active={data.active}
    >
      <TargetHandle
        type="source"
        position="left"
        isConnectable={false}
        $expanded={data.expanded}
        $color={Theme.group.light}
      />
      <Flex alignContent="flex-start">
        <Avatar
          sizing="small"
          shape="square"
          image={data.imageUrl}
          imageAlt={`Avatar of ${data.name}`}
        />
        <FlexItem flexGrow={1} ml="r">
          <Flex alignItems="center" justifyContent="space-between">
            <H4 sizing="small" mb="xs">
              {data.label}
            </H4>

            <GroupTypeBadge group={data.groupData} groupTypes={groupTypes} />
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Text color={Theme.team.color}>
              {data.name} • {count}
            </Text>
            <ButtonLink
              mt="xs"
              display="flex"
              iconOnly
              height="25px"
              width="25px"
              variant="ghost"
              onClick={onClick}
            >
              <Icon icon={icons.externalLink} />
            </ButtonLink>
          </Flex>
        </FlexItem>
      </Flex>
    </BaseNode>
  );
};

TeamNode.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    memberCount: PropTypes.number,
    expanded: PropTypes.bool,
    isLayouted: PropTypes.bool,
    visible: PropTypes.bool,
    fadedNode: PropTypes.bool,
    active: PropTypes.bool,
    groupData: PropTypes.object,
  }),
  id: PropTypes.string,
  xPos: PropTypes.number,
  yPos: PropTypes.number,
};

export default TeamNode;
