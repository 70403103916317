import { Box, H5 } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import SearchContainer from "src/components/Search/SearchContainer";
import WorkspacePropType from "src/custom-prop-types/workspace";

import { copywriting } from "../ReportTeamChange.config";
import { addSenderToMessage } from "../ReportTeamChange.util";

const MissingTeam = ({ onUpdateNewTeams, sender, workspace, featureFlags }) => {
  const onOptionSelect = (value) => {
    onUpdateNewTeams(value);
  };

  return (
    <Box>
      <H5 weight="bold" pb={4}>
        {addSenderToMessage(sender, copywriting.NOT_IN_TEAM)}
      </H5>
      <SearchContainer
        onOptionSelect={onOptionSelect}
        isMulti={true}
        isCloseOnSelect={false}
        isStandardSelect={true}
        includePeople={false}
        includeTags={false}
        workspace={workspace}
        featureFlags={featureFlags}
      />
    </Box>
  );
};

MissingTeam.propTypes = {
  onUpdateNewTeams: PropTypes.func,
  sender: PropTypes.string,
  workspace: WorkspacePropType,
  featureFlags: PropTypes.object,
};

export default MissingTeam;
