import React from "react";
import PropTypes from "prop-types";
import { personDetailsPath } from "src/util/routing";
import PersonPropType from "src/custom-prop-types/person";

const WithPersonDetailsPath = (WrappedComponent) => {
  const Wrapped = (props) => {
    const { person } = props;
    const to = personDetailsPath(person, null);

    return <WrappedComponent {...props} to={to} />;
  };

  Wrapped.propTypes = {
    person: PersonPropType.isRequired,
    target: PropTypes.string,
    isUnallocated: PropTypes.bool,
    isAllocated: PropTypes.bool,
  };

  return Wrapped;
};

WithPersonDetailsPath.propTypes = {
  WrappedComponent: PropTypes.element,
};

export default WithPersonDetailsPath;
