import { keyBy } from "lodash";

import buildSupplyGroupTree from "./buildSupplyGroupTree";
import buildMemberLookup from "./buildMemberLookup";
import buildTargetGroupsLookup from "./buildTargetGroupsLookup";
import { buildInitialDisplay } from "./display";

export const buildPartialModel = ({
  parentGroup,
  mainData,
  selectedSupplyGroupId,
  selectedSubSupplyGroupId,
}) => {
  const { descendantSupplyGroups } = mainData;

  const groupLookup = keyBy([parentGroup, ...descendantSupplyGroups], "id");
  return {
    groupLookup,
    supplyGroupTree: buildSupplyGroupTree({
      parentGroup,
      descendantSupplyGroups,
      selectedSupplyGroupId,
      selectedSubSupplyGroupId,
    }),
  };
};

export const buildFullModel = ({
  parentGroup,
  mainData,
  secondaryData,
  selectedSupplyGroupId,
  selectedSubSupplyGroupId,
  personFilter,
  personSearch,
  targetGroupSearch,
  rebuildSupplyGroupTree,
}) => {
  const {
    descendantSupplyGroups,
    macroAllocationSummaries,
    membersAndTargetGroups: { groups: targetGroups, members },
    individualAllocations,
    requestorMacroAllocationSkillTags,
  } = mainData;
  const { otherGroups, skillTagsForMembers } = secondaryData;

  const groupLookup = keyBy(
    [parentGroup, ...descendantSupplyGroups, ...otherGroups],
    "id"
  );
  const initialModel = {
    groupLookup,
    ...(rebuildSupplyGroupTree && {
      supplyGroupTree: buildSupplyGroupTree({
        parentGroup,
        descendantSupplyGroups,
        selectedSupplyGroupId,
        selectedSubSupplyGroupId,
      }),
    }),
    memberLookup: buildMemberLookup(
      parentGroup,
      members,
      individualAllocations,
      skillTagsForMembers,
      groupLookup
    ),
    targetGroupLookup: buildTargetGroupsLookup(
      targetGroups,
      macroAllocationSummaries,
      individualAllocations,
      requestorMacroAllocationSkillTags,
      groupLookup
    ),
  };

  const initialModelWithDisplay = {
    ...initialModel,
    display: buildInitialDisplay({
      model: initialModel,
      selectedSubSupplyGroupId,
      personFilter,
      personSearch,
      targetGroupSearch,
    }),
  };

  return initialModelWithDisplay;
};
