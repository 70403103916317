import { find } from "lodash";
import { OBJECTIVE_ATTRIBUTE_TYPE } from "src/consts/objectives";
import NameCell from "./TableCells/NameCell";
import ProgressCell from "./TableCells/ProgressCell";
import TeamsCell from "./TableCells/TeamsCell";
import StatusCell from "./TableCells/StatusCell";
import TypeCell from "./TableCells/TypeCell";

// PLEASE READ ColumnConfiguration.md BEFORE EDITING THIS CONFIG

const getColumnConfig = () => {
  return [
    {
      header: "Strategy",
      id: "name",
      accessorKey: "name",
      Cell: NameCell,
      grow: 10000,
      size: 250,
    },
    {
      header: "Type",
      id: "typeId",
      accessorKey: "typeId",
      Cell: TypeCell,
      grow: 0,
      size: 200,
    },
    {
      id: "teams",
      accessorKey: "teams",
      header: "Teams",
      Cell: TeamsCell,
      grow: 0,
      size: 250,
    },
    {
      header: "Completion",
      id: "id",
      accessorKey: "id",
      Cell: ProgressCell,
      grow: 0,
      size: 200,
    },
    {
      header: "Status",
      id: "status",
      accessorFn: (cellData) => {
        const attrs = cellData?.attributes || [];
        return find(attrs, { attributeType: OBJECTIVE_ATTRIBUTE_TYPE.STATUS })
          ?.value;
      },
      Cell: StatusCell,
      grow: 0,
      size: 110,
    },
  ];
};

export default getColumnConfig;
