import { find, get, isEmpty, filter } from "lodash";

// TODO: consolidate this file with customerConfig file.

const getTypeForGroupType = (groupTypes, typeId) => {
  const groupType = find(groupTypes, (type) => type.id === typeId);
  return groupType;
};

export const getTypeForGroup = (groupTypes, group) => {
  return getTypeForGroupType(groupTypes, group.type);
};

export const canCreateChildByConfig = (config) => {
  if (config && config.canCreateChild && !isEmpty(config.childTypes)) {
    return true;
  }

  return false;
};

export const canCreateChild = (groupTypes, group) => {
  const groupType = get(group, "type");

  if (!groupType) {
    return false;
  }

  const config = get(groupTypes, groupType);

  return canCreateChildByConfig(config);
};

export const canRename = (groupTypes, group) => {
  const groupType = get(group, "type");

  if (!groupType) {
    return false;
  }

  const config = get(groupTypes, groupType);

  return get(config, "canRename", false);
};

export const isDemand = (groupTypes, group) => {
  const groupType = get(group, "type");
  if (!groupType) {
    return false;
  }
  const config = get(groupTypes, groupType);
  return get(config, "isDemand", false);
};

export const isSupply = (groupTypes, group) => {
  const groupType = get(group, "type");
  if (!groupType) {
    return false;
  }
  const config = get(groupTypes, groupType);
  return get(config, "isSupply", false);
};

const filterIsSupply = (groupType) => {
  return (
    groupType.isSupply || groupType.isSupplyRoot || groupType.isSupplySource
  );
};

export const getSupplyGroupTypes = (groupTypes) => {
  const supplyTypes = filter(groupTypes, filterIsSupply);
  return supplyTypes;
};
