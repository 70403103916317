import React from "react";
import PropTypes from "prop-types";

import { Box, StatusDot, Icon } from "orcs-design-system";
import icons from "src/config/icons";

const InformationNotification = ({ variant = "success" }) => {
  return (
    <Box mx="s">
      <StatusDot icon variant={variant}>
        <Icon
          icon={icons.information}
          color="white"
          size="xs"
          transform="up-1 right-1"
        />
      </StatusDot>
    </Box>
  );
};

InformationNotification.propTypes = {
  variant: PropTypes.string,
};

export default React.memo(InformationNotification);
