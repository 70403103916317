import React from "react";
import { Box, Notification, StyledLink, Typography } from "orcs-design-system";

import urlPaths, { url } from "../../consts/urlPaths";

const PersonNotFound = () => {
  const personLink = (
    <StyledLink white bold href={url(urlPaths.PEOPLE)}>
      here
    </StyledLink>
  );

  return (
    <Box p={5}>
      <Notification
        top="20px"
        centered
        closable={false}
        colour="warning"
        icon={["fas", "exclamation-triangle"]}
      >
        <Typography.P color="white">This person does not exist.</Typography.P>
        <Typography.P color="white">
          Click {personLink} to search for another person.
        </Typography.P>
      </Notification>
    </Box>
  );
};

export default PersonNotFound;
