import React from "react";
import { map } from "lodash";

import {
  useForecastModel,
  useRootGroup,
  useLookupData,
  useFinancialForecasting,
} from "../../../context/ForecastContext";
import ColumnHeader from "./ColumnHeader";

const ColumnHeaders = () => {
  const { hideApproverColumn } = useLookupData();
  const { dynamicModel } = useForecastModel();
  const rootGroup = useRootGroup();
  const showTotalCost = useFinancialForecasting();

  return (
    <>
      {map(dynamicModel.columns, (column) => (
        <ColumnHeader
          key={column.id}
          column={column}
          rootGroup={rootGroup}
          showTotalCost={showTotalCost}
          hideApproverColumn={hideApproverColumn}
        />
      ))}
    </>
  );
};

export default ColumnHeaders;
