import * as ACTIONS from "../actionTypes";

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.SUB_SUPPLY_GROUP_SELECTED:
    case ACTIONS.SUPPLY_GROUP_SELECTED:
    case ACTIONS.SUB_SUPPLY_GROUP_CLEARED: {
      const { selectedSupplyGroupId, selectedSubSupplyGroupId } = action;
      return {
        ...state,
        selectedSupplyGroupId,
        selectedSubSupplyGroupId,
      };
    }
    case ACTIONS.TARGET_GROUP_SEARCHED: {
      const { search } = action;
      return {
        ...state,
        targetGroupSearch: search,
      };
    }
    case ACTIONS.PERSON_SEARCHED: {
      const { search } = action;
      return {
        ...state,
        personSearch: search,
      };
    }
    case ACTIONS.PERSON_FILTER_SELECTED: {
      const { filter } = action;
      return {
        ...state,
        personFilter: filter,
      };
    }
    case ACTIONS.OPEN_PERSON_ALLOCATIONS: {
      const { personId } = action;
      return {
        ...state,
        viewingAllocations: {
          isOpen: true,
          personId,
        },
      };
    }
    case ACTIONS.CLOSE_PERSON_ALLOCATIONS: {
      return {
        ...state,
        viewingAllocations: {
          isOpen: false,
          personId: null,
        },
      };
    }
    case ACTIONS.PERSON_ALLOCATIONS_UPDATED: {
      const { personId } = action;
      return {
        ...state,
        viewingAllocations: {
          isOpen: true,
          personId,
        },
      };
    }
    default:
      return state;
  }
};
