import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Header } from "orcs-design-system";

const Logo = styled.img`
  ${(props) => props.styles}
`;

const HeaderPreview = ({ config }) => {
  return (
    <Header logo={<Logo {...config.logoImage} />} appName={config.appName} />
  );
};

HeaderPreview.propTypes = {
  config: PropTypes.object,
};

export default HeaderPreview;
