import { get } from "lodash";

const getGroupFeatureVisibility = ({ group, config }) => {
  const isDeleted = get(group, "isDeleted") || get(group, "isHidden");
  const isDeniedAccess = !get(
    group,
    "permissions.areGroupsInAccessGroupIdsHierarchy"
  );

  if (isDeleted) {
    return {
      allocations: false,
      visualisation: false,
      principles: false,
      history: true,
      isDeleted,
      isDeniedAccess,
    };
  }

  const {
    disableGroupVisualisation,
    disableGroupPrinciples,
    disableGroupHistory,
    disableIndividualAllocation,
  } = get(config, "allocation", {});

  return {
    allocations: !disableIndividualAllocation,
    visualisation: !disableGroupVisualisation,
    principles: !disableGroupPrinciples,
    history: !isDeniedAccess && !disableGroupHistory,
    isDeleted,
    isDeniedAccess,
  };
};

export default getGroupFeatureVisibility;
