import { reduce, last, split, unionBy } from "lodash";

export default (tagsList) => {
  const keyedTagsList = reduce(
    tagsList,
    (prev, t) => {
      const { id, tags } = t;

      // Group ID doesn't contain "-", it's safe to get the last as target group id
      const targetGroupId = last(split(id, "-"));
      const existingTags = prev[targetGroupId];
      if (existingTags) {
        // Target group can request same skills to multiple source groups, so, need to merge new tags in
        // eslint-disable-next-line no-param-reassign
        prev[targetGroupId] = unionBy(tags, existingTags, "id");
      } else {
        // eslint-disable-next-line no-param-reassign
        prev[targetGroupId] = tags;
      }

      return prev;
    },
    {}
  );

  return keyedTagsList;
};
