import { NODE_TYPES } from "src/components/NodeVisualizer/consts";

const groupNodesByHierarchy = (nodes) => {
  const shouldFilter = (node) =>
    node.data.nodeType !== "team" &&
    node.type !== NODE_TYPES.OBJECTIVE_HEADER_NODE;

  // Separate nodes into filtered and removed
  const filteredNodes = nodes.filter(shouldFilter);
  const removedNodes = nodes.filter(
    (node) =>
      node.data.nodeType === "team" ||
      node.type === NODE_TYPES.OBJECTIVE_HEADER_NODE
  );

  // Create a map of nodes by ID for quick lookup
  const nodeMap = new Map(
    filteredNodes.flatMap((node) =>
      node.data.collapsedNodes
        ? [
            [node.id, node],
            ...node.data.collapsedNodes.map((cn) => [cn.id, node]),
          ]
        : [[node.id, node]]
    )
  );

  const groups = []; // To store grouped arrays
  const visited = new Set(); // Track visited nodes to avoid duplicates

  // Helper to sort nodes by stable properties
  const stableSort = (_nodes) =>
    _nodes.sort((a, b) => a.id.localeCompare(b.id));

  // Recursive function to build a group starting from a node
  const buildGroup = (node) => {
    const group = [];
    const stack = [node];

    while (stack.length > 0) {
      const currentNode = stack.pop();

      if (!visited.has(currentNode.id)) {
        visited.add(currentNode.id);
        group.push(currentNode);

        // Add parent nodes to the stack
        currentNode.data.hierarchyParentIds
          .map((parentId) => nodeMap.get(parentId))
          .filter(Boolean)
          .forEach((parentNode) => stack.push(parentNode));

        // Add child nodes to the stack
        const childNodes = filteredNodes.filter((n) =>
          n.data.hierarchyParentIds.includes(currentNode.id)
        );

        // Collect children from collapsed nodes
        if (currentNode.data?.collapsedNodes) {
          currentNode.data.collapsedNodes.forEach((cn) => {
            filteredNodes.forEach((n) => {
              if (n.data.hierarchyParentIds.includes(cn.id)) {
                if (!visited.has(n.id)) stack.push(n);
              }
            });
          });
        }

        stableSort(childNodes).forEach((childNode) => {
          if (!visited.has(childNode.id)) stack.push(childNode);
        });
      }
    }

    return stableSort(group);
  };

  // Iterate over each node and build groups
  stableSort(filteredNodes).forEach((node) => {
    if (!visited.has(node.id) && node.depth >= 1) {
      const group = buildGroup(node);
      groups.push(group);
    }
  });

  return [groups, removedNodes];
};

export default groupNodesByHierarchy;
