import React, { useCallback, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, Box } from "orcs-design-system";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { get, isNil } from "lodash";
import { useHistory, useParams } from "react-router-dom";

import { TEAM_TAB, url } from "src/consts/urlPaths";
import { getPersonInAllocation } from "src/allocation/person.graphql";
import ErrorNotification from "src/components/ErrorNotification";
import AllocationModalHeader from "src/allocation/components/AllocationModalHeader";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import PersonMemberships from "src/components/PersonDetailPage/PersonMemberships";

import IndividualPersonAllocationContainer from "src/allocation/pages/PersonAllocationPage/IndividualPersonAllocationContainer";

const IndividualAllocationModal = ({
  onPersonAllocating,
  onPersonAllocated,
  onPersonUnallocated,
  onPersonAllocationUpdated,
}) => {
  const { enableFutureAllocationsUI } = useWorkspaceFeatureFlags();
  const [isAllocating, updateIsAllocating] = useState(false);
  const history = useHistory();
  const params = useParams();

  const { id, personId, allocationProjectId } = params;

  const { data, loading } = useQuery(getPersonInAllocation, {
    variables: {
      aggregateId: personId,
    },
  });

  const onPersonAllocatingInModal = useCallback(
    async (isNewlyAllocating) => {
      // disable closing the modal while allocation in progress.
      updateIsAllocating(isNewlyAllocating);
      if (onPersonAllocating) {
        return onPersonAllocating(isNewlyAllocating);
      }
      return Promise.resolve(true);
    },
    [updateIsAllocating, onPersonAllocating]
  );

  const onCancel = useCallback(() => {
    if (isAllocating) {
      return;
    }
    history.push(
      url(`/:workspaceId?/teams/${id}/${TEAM_TAB.INDIVIDUAL_ALLOCATIONS}`),
      {
        needsRefresh: true,
      }
    );
  }, [isAllocating, history, id]);

  if (loading) {
    return null;
  }

  const person = get(data, "person");

  if (isNil(person)) {
    return (
      <ErrorNotification message="Sorry, person not found." report={false} />
    );
  }

  let modal;

  if (enableFutureAllocationsUI) {
    const layout = (modalBody, viewToggle) => (
      <Modal
        visible
        maxWidth="90vw"
        maxHeight="90vh"
        width="70vw"
        height="60vh"
        onConfirm={() => {}}
        onClose={onCancel}
        headerContent={
          <AllocationModalHeader person={person} viewToggle={viewToggle} />
        }
      >
        <Box p="xxs" height="100%">
          {modalBody}
        </Box>
      </Modal>
    );

    modal = (
      <PersonMemberships
        key="memberships"
        person={person}
        onMembershipUpdating={onPersonAllocatingInModal}
        onMembershipAdded={onPersonAllocated}
        onMembershipRemoved={onPersonUnallocated}
        onMembershipFteUpdated={onPersonAllocationUpdated}
        layoutFunction={layout}
      />
    );
  } else {
    const legacyLayout = (...children) => {
      return (
        <Modal
          visible
          maxWidth="90vw"
          maxHeight="90vh"
          width="70vw"
          height="60vh"
          onConfirm={() => {}}
          onClose={onCancel}
          headerContent={<AllocationModalHeader person={person} />}
          footerContent={children[2]}
        >
          <Box p="xxs">
            {children[0]}
            {children[1]}
          </Box>
        </Modal>
      );
    };

    modal = (
      <IndividualPersonAllocationContainer
        allocationProjectId={allocationProjectId}
        personId={personId}
        personFte={person.fte}
        onPersonAllocating={onPersonAllocatingInModal}
        onPersonAllocated={onPersonAllocated}
        onPersonUnallocated={onPersonUnallocated}
        onPersonAllocationUpdated={onPersonAllocationUpdated}
        layoutFunction={legacyLayout}
      />
    );
  }

  return ReactDOM.createPortal(modal, document.body);
};

IndividualAllocationModal.propTypes = {
  onPersonAllocating: PropTypes.func,
  onPersonAllocated: PropTypes.func.isRequired,
  onPersonUnallocated: PropTypes.func.isRequired,
  onPersonAllocationUpdated: PropTypes.func.isRequired,
};

export default IndividualAllocationModal;
