import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import styled, { css } from "styled-components";

import { themeGet } from "@styled-system/theme-get";
import useGridConfig from "../../util/gridConfig";
import { useFinancialForecasting } from "../../../context/ForecastContext";

import CustomGrid from "../../Shared/CustomGrid";
import Cell from "../ForecastCell";

import TotalCostCell from "../../Shared/TotalCostCell";
import LineItemMembers from "./LineItemMembers";
import LineItemLeftControls from "./LineItemLeftControls";

const CustomCustomGrid = styled(CustomGrid)`
  margin: ${(props) =>
    props.shouldAdjustIndent ? "5px 0 5px 32px" : "5px 0 5px 16px"};
  width: ${(props) =>
    props.shouldAdjustIndent ? "calc(100% - 32px)" : "calc(100% - 16px)"};
  ${(props) =>
    props.emphasisLineItem
      ? css`
          background-color: ${themeGet("colors.primary10")(props)};
          margin: -5px 0 0 0;
          padding: 5px 15px 5px 16px;
          width: 100%;
        `
      : css``};
`;

const LineItem = ({
  lineItem,
  dispatch,
  shouldAdjustIndent,
  emphasisLineItem,
  isMembersExpanded,
  showMemberTags,
  enableMembersInForecast,
  showIntersectingFteSum,
}) => {
  const shouldShowCost = useFinancialForecasting();
  const gridConfig = useGridConfig();

  const {
    forecast: { templateColumns, gap },
  } = gridConfig;

  return useMemo(
    () => (
      <>
        <CustomCustomGrid
          gridTemplateColumns={templateColumns}
          gridGap={gap}
          shouldAdjustIndent={shouldAdjustIndent}
          className="guided-tour-allocation-forecast-line-item"
          emphasisLineItem={emphasisLineItem}
        >
          <LineItemLeftControls
            lineItem={lineItem}
            isMembersExpanded={isMembersExpanded}
            dispatch={dispatch}
            withMembersExpandIcon={enableMembersInForecast}
          />
          {shouldShowCost && <TotalCostCell lineItem={lineItem} />}
          {map(lineItem.cells, (cell) => (
            <Cell
              cell={cell}
              key={cell.id}
              dispatch={dispatch}
              isMembersExpanded={isMembersExpanded}
              showIntersectingFteSum={showIntersectingFteSum}
            />
          ))}
        </CustomCustomGrid>
        {enableMembersInForecast && isMembersExpanded && (
          <LineItemMembers
            lineItem={lineItem}
            withExtraColumn={shouldShowCost}
            showMemberTags={showMemberTags}
            showIntersectingFteSum={showIntersectingFteSum}
            dispatch={dispatch}
          />
        )}
      </>
    ),
    [lineItem.hash, isMembersExpanded] // eslint-disable-line
  );
};

LineItem.propTypes = {
  lineItem: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  shouldAdjustIndent: PropTypes.bool,
  emphasisLineItem: PropTypes.bool,
  isMembersExpanded: PropTypes.bool,
  showMemberTags: PropTypes.bool,
  enableMembersInForecast: PropTypes.bool,
  showIntersectingFteSum: PropTypes.bool,
};

export default LineItem;
