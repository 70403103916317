import { filter, includes, map } from "lodash";
import { ENTITY_TYPES } from "src/consts/comments";
import { TAGS_VISIBLE_IN_TYPE } from "src/pages/Workspaces/consts";

export const getOptions = ({ tagConfig, entityType }) => {
  const allowedForEntity =
    entityType === ENTITY_TYPES.PERSON
      ? "allowPersonToHaveTag"
      : "allowGroupToHaveTag";

  return map(
    filter(tagConfig, (tagType) => {
      return (
        includes(tagType?.visibleIn, TAGS_VISIBLE_IN_TYPE.OrgChart) &&
        tagType?.[allowedForEntity]
      );
    }),
    ({ name, id }) => ({
      label: name,
      value: id,
    })
  );
};
