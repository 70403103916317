import React, { useContext } from "react";
import { Flex } from "orcs-design-system";

import { RequestAllocationContext } from "src/allocation/contexts/requestAllocationContext";
import HideTeamButton from "./HideTeamButton";
import ShowTeamButton from "./ShowTeamButton";

const TeamButtons = () => {
  const [{ selectedTargetGroup }, dispatch] = useContext(
    RequestAllocationContext
  );

  const onDisplayHideTeamModal = () => {
    dispatch({ type: "showHideTeamModal", isModalShown: true });
  };

  const { isHidden } = selectedTargetGroup;
  if (isHidden) {
    return (
      <Flex alignItems="center">
        <ShowTeamButton onShowModal={onDisplayHideTeamModal} />
      </Flex>
    );
  }

  return (
    <Flex alignItems="center">
      <HideTeamButton onShowModal={onDisplayHideTeamModal} />
    </Flex>
  );
};

export default TeamButtons;
