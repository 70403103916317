import { useApolloClient } from "@apollo/client";
import { find, get } from "lodash";
import React, { useCallback } from "react";

import { useModal } from "src/contexts/withModal";
import { isDemand } from "src/util/directory-customerConfig";
import FulfillVacantRoleModal from "src/components/FulfillVacantRole/FulfillVacantRoleModal";
import { refetchMemberList } from "src/components/FulfillVacantRole/FulfillVacantRoleModal.logic";
import { hasAdminOrPowerRole } from "src/services/auth";

const useFulfillVacantRole = ({
  person,
  group,
  workspace,
  user,
  groupTypes,
  allocationProject,
}) => {
  const [, dispatch] = useModal();
  const client = useApolloClient();
  const vacantRoleLabel = get(
    workspace,
    "config.allocation.vacantRoleLabel",
    "vacant role"
  );
  const enableAddMember = get(workspace, "config.allocation.enableAddMember");
  const isDemandTeam = isDemand(groupTypes, group);
  const canShowFulfillButton =
    group &&
    person.isPlaceholder &&
    hasAdminOrPowerRole(user) &&
    enableAddMember;

  const onHideModal = useCallback(() => {
    dispatch({ type: "setModalVisible", isModalVisible: false });
  }, [dispatch]);

  const onPersonSelect = useCallback(() => {
    refetchMemberList({
      client,
      teamId: group?.id,
    });
  }, [client, group?.id]);

  const handleAddMember = useCallback(() => {
    const sourceGroup = find(
      person.memberOf,
      (p) => p.id === person.sourceGroupIds[0]
    );
    const modalComponent = (
      <FulfillVacantRoleModal
        isOpen={true}
        person={person}
        isDemandTeam={isDemandTeam}
        targetGroupId={group?.id}
        sourceGroup={sourceGroup}
        workspace={workspace}
        allocationProjectId={allocationProject?.id}
        groupTypes={groupTypes}
        onHideModal={onHideModal}
        onPersonSelect={onPersonSelect}
        vacantRoleLabel={vacantRoleLabel}
      />
    );
    dispatch({ type: "setModalComponent", modalComponent });
  }, [
    person,
    isDemandTeam,
    group?.id,
    workspace,
    allocationProject?.id,
    groupTypes,
    onHideModal,
    onPersonSelect,
    vacantRoleLabel,
    dispatch,
  ]);
  return {
    handleAddMember,
    canShowFulfillButton,
    vacantRoleLabel,
  };
};

export default useFulfillVacantRole;
