import { useRef, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { SUCCESS, FAILED } from "../../context/reducer/saveStatus";

const useSaveMutation = (initialValue, mutation, updateStatus) => {
  const previous = useRef(initialValue);

  // Used to reset the previous value if save failed
  const temp = useRef(initialValue);

  const [runMutation] = useMutation(mutation, {
    onCompleted: (data) => {
      updateStatus(SUCCESS, data);
    },
    onError: (error) => {
      previous.current = temp.current;
      updateStatus(FAILED, error);
    },
  });

  const saveValue = useCallback(
    (value, variables, checkValueChange = true) => {
      if (checkValueChange && previous.current === value) {
        return Promise.resolve();
      }

      temp.current = previous.current;
      previous.current = value;

      // Call mutation to save
      return runMutation({
        variables,
      });
    },
    [runMutation]
  );

  return [saveValue, previous.current];
};

export default useSaveMutation;
