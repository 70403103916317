import { debounce, get } from "lodash";
import { Modal, Flex, Spacer, Button, Small, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { createApi as createUnsplashApi } from "unsplash-js";

import icon from "src/config/icons";
import { GroupAttribute } from "src/custom-prop-types/groupAttribute";
import { sanitiseString } from "src/util/search";
import { reportError } from "src/services/errorReporting";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";

import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";
import Masonry from "./sub-components/Masonry";
import SearchInput from "./sub-components/SearchInput";
import { sizing, copywriting } from "./Unsplash.config";
import { createOrAddImageAttribute, unsplashSearch } from "./Unsplash.util";

const Unsplash = ({
  attributes,
  isVisible,
  onHandleTeamUpdate,
  onHideModal,
}) => {
  const currentSearchTerm = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [selection, setSelection] = useState();
  const [searchError, setSearchError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [onSaveError, setOnSaveError] = useState();
  const [currentSearchResults, setCurrentSearchResults] = useState();

  useEffect(() => {
    // reset results when modal is toggled
    setCurrentSearchResults([]);
  }, [isVisible]);

  const unsplash = useMemo(() => {
    return createUnsplashApi({
      accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
    });
  }, []);

  const onConfirm = async () => {
    if (selection) {
      setOnSaveError(null);
      setIsSaving(true);

      try {
        const bgImgUrl = get(selection, "urls.regular");
        const altDescription = get(selection, "alt_description");
        const newAttributes = createOrAddImageAttribute(
          attributes,
          bgImgUrl,
          altDescription
        );
        await onHandleTeamUpdate(newAttributes);
        onHideModal();
        const unsplashDownloadEvent = `${get(
          selection,
          "links.download_location"
        )}/?client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`;
        // no need to wait for this to complete
        fetch(unsplashDownloadEvent);
      } catch (mutationError) {
        reportError(mutationError);
        setOnSaveError(copywriting.FORM_SAVE_ERROR);
      }
      setIsSaving(false);
      trackEvent(EVENT_TRACKING.TEAM_UPDATED, {
        unsplash_saved: true,
      });
    } else {
      setOnSaveError(copywriting.FORM_INVALID);
    }
  };
  const onCancel = () => {
    setOnSaveError(null);
    onHideModal();
    trackEvent(EVENT_TRACKING.TEAM_UPDATED, {
      cancelled: true,
    });
  };
  const onSelect = (selected) => {
    setSelection(selected);
    trackEvent(EVENT_TRACKING.TEAM_UPDATED, {
      unsplash_selected: true,
    });
  };

  const debouncedSearchQuery = useMemo(
    () =>
      debounce(async (inputValue, page = 1) => {
        setIsLoading(true);
        trackEvent(EVENT_TRACKING.SEARCH_QUERY, {
          search_entities: "unsplash",
          searchTerms: inputValue,
        });
        const sanitisedQuery = sanitiseString(inputValue);
        const { data, error } = await unsplashSearch(
          unsplash,
          sanitisedQuery,
          page
        );
        setIsLoading(false);
        currentSearchTerm.current = inputValue;
        if (data) {
          setSearchError(false);
          setCurrentSearchResults(data);
        }
        if (error) {
          setSearchError(true);
        }
      }, DEBOUNCE_SEARCH_TIME),
    [unsplash]
  );

  const modalHeader = (
    <SearchInput
      unsplash={unsplash}
      onSearch={debouncedSearchQuery}
      searchError={searchError}
    />
  );
  const modalFooter = (
    <Flex flexWrap="wrap" width="100%">
      <Spacer mr="s">
        <Button
          onClick={onConfirm}
          variant="success"
          iconLeft
          disabled={isSaving}
          isLoading={isSaving}
          data-testid="cp-unsplash-save-btn"
        >
          <Icon icon={["fas", "save"]} />
          Save
        </Button>
        <Button
          variant="ghost"
          onClick={onCancel}
          iconLeft
          data-testid="cp-unsplash-cancel-btn"
        >
          <Icon icon={["fas", "times"]} />
          Cancel
        </Button>
      </Spacer>
      {onSaveError && (
        <Small color="danger" fontWeight="bold" display="block" my="s">
          <Icon icon={icon.exclamation} mr="xs" />
          {onSaveError}
        </Small>
      )}
      <Small color="greyDark" display="block" my="s" ml="auto">
        <em>{copywriting.ATTRIBUTION_LABEL}</em>
      </Small>
    </Flex>
  );

  return (
    <Modal
      visible={isVisible}
      onConfirm={onConfirm}
      onClose={onCancel}
      height={sizing.MODAL_HEIGHT}
      width={sizing.MODAL_WIDTH}
      maxHeight={sizing.MODAL_MAXHEIGHT}
      maxWidth={sizing.MODAL_MAXWIDTH}
      footerContent={modalFooter}
      headerContent={modalHeader}
    >
      <Masonry
        searchTerm={currentSearchTerm.current}
        items={currentSearchResults}
        isLoading={isLoading}
        onSelect={onSelect}
        unsplash={unsplash}
      />
    </Modal>
  );
};

Unsplash.propTypes = {
  attributes: PropTypes.arrayOf(GroupAttribute),
  isVisible: PropTypes.bool,
  onHandleTeamUpdate: PropTypes.func,
  onHideModal: PropTypes.func,
};

export default React.memo(Unsplash);
