import { startsWith, split, chain } from "lodash";

import { TAGS } from "src/consts/comments";

export const getFilters = () => {
  return [
    [TAGS.SYSTEM_COMMENT],
    [
      TAGS.OBJECTIVE.COMMENT,
      TAGS.ALLOCATION.PLANNING,
      TAGS.ALLOCATION.FORECASTING,
      TAGS.SUPPLY_GROUP_UPDATE,
      TAGS.TAGGING.COMMENT,
      TAGS.DOWNLOAD,
    ],
  ];
};

const GROUPID_PREFIX = TAGS.GROUP_ID("");

export const getGroupIdsFromComments = (comments, existingGroups) => {
  return chain(comments)
    .map(({ tags }) => {
      return chain(tags)
        .filter((tag) => {
          const parts = split(tag, ":");
          return (
            startsWith(tag, GROUPID_PREFIX) &&
            parts[1] &&
            !existingGroups[parts[1]]
          );
        })
        .map((tag) => {
          const parts = split(tag, ":");
          return parts[1];
        })
        .value();
    })
    .flatten()
    .uniq()
    .value();
};
