import React from "react";
import PropTypes from "prop-types";
import { H2 } from "orcs-design-system";
import useAllGroupExpansion from "../hooks/useAllGroupExpansion";
import TreeExpand from "./TreeExpand";

export default function ExpandAllGroupButton({ groupings }) {
  const { isExpanded, handleClick } = useAllGroupExpansion(groupings);
  return (
    <TreeExpand
      expandButton
      indentLevel={0}
      onToggle={handleClick}
      isOpen={isExpanded}
    >
      <H2 fontSize={1}>{isExpanded ? "Collapse All" : "Expand All"}</H2>
    </TreeExpand>
  );
}

ExpandAllGroupButton.propTypes = {
  groupings: PropTypes.array,
};
