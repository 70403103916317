import React from "react";
import PropTypes from "prop-types";
import { Box, ButtonGroupContainer, ButtonGroupItem } from "orcs-design-system";

export const VIEW_MODES = {
  LIST: "list",
  TIMELINE: "timeline",
};

export const ViewModeSwitcher = ({ viewMode, handleViewModeChange }) => {
  return (
    <Box data-testid="view-mode-toggle">
      <ButtonGroupContainer controlLabel="View:" labelDark>
        <ButtonGroupItem
          name="viewMode"
          label="List"
          value={VIEW_MODES.LIST}
          checked={viewMode === VIEW_MODES.LIST}
          onChange={handleViewModeChange}
        />
        <ButtonGroupItem
          name="viewMode"
          label="Timeline"
          value={VIEW_MODES.TIMELINE}
          checked={viewMode === VIEW_MODES.TIMELINE}
          onChange={handleViewModeChange}
        />
      </ButtonGroupContainer>
    </Box>
  );
};

ViewModeSwitcher.propTypes = {
  viewMode: PropTypes.string,
  handleViewModeChange: PropTypes.func,
};
