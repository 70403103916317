import React from "react";
import PropTypes from "prop-types";
import { Flex, FlexItem, Modal } from "orcs-design-system";

import GroupsTreeView from "./GroupsTreeView";

const GroupingMembersTreeModal = ({
  visible,
  title,
  leftViewTitle,
  treeData,
  onHideModal,
  footerContent,
  onNodeSelected,
  onNodeChecked,
  selectedGroups,
  selectedPeople,
  children,
  width = 50,
}) => {
  return (
    <Modal
      data-testid="cp-link-objectives-modal"
      visible={visible}
      height="80vh"
      width="80vw"
      maxHeight="90vh"
      maxWidth="90vw"
      onClose={onHideModal}
      headerContent={title}
      footerContent={footerContent}
    >
      <Flex alignItems="stretch" height="calc(100% - 16px)" mt="r">
        <FlexItem flex={`1 1 ${width}%`} mr="s">
          <GroupsTreeView
            treeViewTitle={leftViewTitle}
            loading={false}
            treeData={treeData}
            onNodeSelected={onNodeSelected}
            onNodeChecked={onNodeChecked}
            selectedGroups={selectedGroups}
            selectedPeople={selectedPeople}
          />
        </FlexItem>
        {children && (
          <FlexItem flex={`1 1 ${100 - width}%`} ml="s">
            {children}
          </FlexItem>
        )}
      </Flex>
    </Modal>
  );
};

GroupingMembersTreeModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.node,
  leftViewTitle: PropTypes.string,
  treeData: PropTypes.object,
  onNodeSelected: PropTypes.func,
  onNodeChecked: PropTypes.func,
  selectedGroups: PropTypes.object,
  selectedPeople: PropTypes.object,
  onHideModal: PropTypes.func,
  children: PropTypes.node,
  footerContent: PropTypes.node,
  width: PropTypes.number,
};

export default GroupingMembersTreeModal;
