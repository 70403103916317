import React from "react";
import PropTypes from "prop-types";
import { Flex, FlexItem } from "orcs-design-system";

import GroupHierarchyQueryBreadcrumb from "src/components/GroupHierarchyBreadcrumb/GroupHierarchyQueryBreadcrumb";

const ModalHeader = ({ grouping, lineItem }) => {
  return (
    <Flex alignItems="center" data-testid="add-member-modal-header">
      <FlexItem mr="xs">Add member to </FlexItem>
      <GroupHierarchyQueryBreadcrumb
        group={grouping.group}
        showGroupTypeBadge
        showBreadcrumb={false}
      />
      {lineItem && (
        <>
          <FlexItem mr="xs">From </FlexItem>
          <GroupHierarchyQueryBreadcrumb
            group={lineItem.group}
            showGroupTypeBadge
            showBreadcrumb={false}
          />
        </>
      )}
    </Flex>
  );
};

ModalHeader.propTypes = {
  grouping: PropTypes.object,
  lineItem: PropTypes.object,
};

export default ModalHeader;
