import PropTypes from "prop-types";

export default PropTypes.shape({
  loading: PropTypes.bool,
  id: PropTypes.string,
  allocationName: PropTypes.string,
  baselineDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  startDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  allocationDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isActive: PropTypes.bool,
  stage: PropTypes.string,
});
