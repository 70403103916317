import { useMemo, useCallback, useState } from "react";
import { map } from "lodash";
import Fuse from "fuse.js";

const supplyGroupSearchOptions = {
  shouldSort: false,
  tokenize: true,
  matchAllTokens: true,
  findAllMatches: false,
  threshold: 0.0,
  ignoreLocation: true,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name", "parentGroups.name"],
};

// eslint-disable-next-line import/prefer-default-export
export const useSearchSupplyGroups = ({
  rootGroups,
  switchToLoadSupplyGroups,
}) => {
  const [searchResults, setSearchResults] = useState([]);

  const groupNameFuse = useMemo(() => {
    if (switchToLoadSupplyGroups) {
      return new Fuse(rootGroups, supplyGroupSearchOptions);
    }
    return null;
  }, [rootGroups, switchToLoadSupplyGroups]);

  const searchSupplyGroups = useCallback(
    (term) => {
      const results = groupNameFuse.search(term);

      const groups = map(results, "item");
      setSearchResults(groups);
    },
    [groupNameFuse]
  );

  return { searchResults, searchSupplyGroups };
};
