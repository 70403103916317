import { Box, H5, Spacer, RadioButton } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { copywriting, scenario } from "../ReportTeamChange.config";

const ScenarioPrompt = ({ teamCase, onChangeTeamCase }) => (
  <Box>
    <H5 weight="bold" mb={4}>
      {copywriting.SCENARIO}
    </H5>
    <Spacer mb="r">
      <RadioButton
        name="ScenarioRadioButton"
        label={copywriting.SCENARIO_NOT_IN_TEAM}
        variant="primary"
        value={scenario.incorrectTeam}
        onChange={onChangeTeamCase}
        checked={teamCase === scenario.incorrectTeam}
      />
      <RadioButton
        name="ScenarioRadioButton"
        label={copywriting.SCENARIO_MISSING_TEAM}
        variant="primary"
        value={scenario.missingTeam}
        onChange={onChangeTeamCase}
        checked={teamCase === scenario.missingTeam}
      />
      <RadioButton
        name="ScenarioRadioButton"
        label={copywriting.SCENARIO_INCORRECT_FTE}
        variant="primary"
        value={scenario.incorrectFte}
        onChange={onChangeTeamCase}
        checked={teamCase === scenario.incorrectFte}
      />
    </Spacer>
  </Box>
);

ScenarioPrompt.propTypes = {
  teamCase: PropTypes.string,
  onChangeTeamCase: PropTypes.func,
};

export default ScenarioPrompt;
