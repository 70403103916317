import { useMutation } from "@apollo/client";
import { get } from "lodash";
import {
  Modal,
  Flex,
  FlexItem,
  Box,
  H2,
  H5,
  Button,
  Loading,
  Notification,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import { getTeamChildTeamsQuery } from "src/queries/group.graphql";
import GroupTypeBadge from "src/components/Badge/GroupTypeBadge";
import { cloneHierarchy } from "src/queries/allocation.graphql";
import { getCloneHierarchyJobs } from "src/queries/team.graphql";
import TeamSearch from "src/components/TeamSearch";
import HierarchyOptions from "src/components/CloneHierarchyModal/sub-components/HierarchyOptions";
import HierarchySelector from "src/components/CloneHierarchyModal/sub-components/HierarchySelector";

export const TEAM_SEARCH_KIND_FILTERS = ["division"];

const CloneHierarchyModal = ({
  isVisible,
  onHideModal,
  cloneToTeam,
  groupTypes,
}) => {
  const [cloneHierarchyMutation] = useMutation(cloneHierarchy);
  const [rootGroup, setRootGroup] = useState(null);
  const [excludedGroupIds, setExcludedGroupIds] = useState(null);
  const [selectedGroupIds, setSelectedGroupIds] = useState(null);
  const [hierarchyGroupTypeIds, setHierarchyGroupTypeIds] = useState(null);
  const [mappings, setMappings] = useState(null);
  const [options, setOptions] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [error, setError] = useState(false);

  const resetState = useCallback(async () => {
    setRootGroup(null);
    setSelectedGroupIds(null);
    setExcludedGroupIds(null);
    setHierarchyGroupTypeIds(null);
    setMappings(null);
    setOptions(null);
    setIsValidating(false);
    setIsCloning(false);
    setError(null);
  }, [
    setRootGroup,
    setSelectedGroupIds,
    setExcludedGroupIds,
    setHierarchyGroupTypeIds,
    setMappings,
    setOptions,
    setIsValidating,
    setIsCloning,
    setError,
  ]);

  const onSelectRootGroup = useCallback(
    async (group) => {
      resetState();
      setRootGroup(group);
    },
    [resetState, setRootGroup]
  );

  const onCloneHierarchy = useCallback(async () => {
    if (isValidating || isCloning) {
      return;
    }

    setIsValidating(true);
    let cloneHierarchyResponse = null;
    try {
      cloneHierarchyResponse = await cloneHierarchyMutation({
        variables: {
          input: {
            cloneToParentGroupId: cloneToTeam.id,
            rootGroupId: rootGroup.value,
            excludedGroupIds,
            groupTypeMap: mappings,
            createPeopleSyncAssociations:
              options?.createPeopleSyncAssociations ?? false,
          },
        },
        refetchQueries: [getCloneHierarchyJobs, getTeamChildTeamsQuery],
      });
      onHideModal();
    } catch (cloneHierarchyError) {
      setError(cloneHierarchyError);
    }
    setIsValidating(false);

    const jobId = get(cloneHierarchyResponse, "data.cloneHierarchy.jobName");
    if (jobId) {
      setIsCloning(true);
      setError(null);
    }
  }, [
    rootGroup,
    excludedGroupIds,
    mappings,
    options,
    cloneHierarchyMutation,
    cloneToTeam,
    setIsValidating,
    isValidating,
    setIsCloning,
    isCloning,
    onHideModal,
  ]);

  const onCancel = useCallback(async () => {
    resetState();
    onHideModal();
  }, [onHideModal, resetState]);

  const onHierarchyChange = useCallback(
    (selectedIds, excludedIds, uniqueTypes) => {
      setSelectedGroupIds(selectedIds);
      setExcludedGroupIds(excludedIds);
      setHierarchyGroupTypeIds(uniqueTypes);
    },
    [setExcludedGroupIds, setSelectedGroupIds, setHierarchyGroupTypeIds]
  );

  const onOptionsChange = useCallback(
    (typeMappings, optionToggles) => {
      setMappings(typeMappings);
      setOptions(optionToggles);
    },
    [setMappings, setOptions]
  );

  const canClone =
    !isCloning && isValidating === false && excludedGroupIds !== null;
  const selectedGroupsCount = selectedGroupIds?.length ?? 0;
  const totalGroupsCount =
    selectedGroupsCount + (excludedGroupIds?.length ?? 0);
  let buttonText = "Clone selected";
  if (isValidating) {
    buttonText = "Validating";
  } else if (isCloning) {
    buttonText = "Clone job created";
  }

  return (
    <Modal
      visible={isVisible}
      onClose={onCancel}
      width="80vw"
      height="70vh"
      maxHeight="90vh"
      maxWidth="90vw"
      headerContent={
        <>
          <H5 weight="bold" color="greyDark">
            CLONING TO
          </H5>
          <Flex alignItems="center">
            <H2 mr="r">{cloneToTeam.name}</H2>
            <GroupTypeBadge group={cloneToTeam} groupTypes={groupTypes} />
          </Flex>
        </>
      }
      data-testid="cp-clone-hierarchy-modal"
      footerContent={
        <Flex alignItems="center" justifyContent="flex-end" width="100%">
          <Button
            p="s"
            mr="s"
            onClick={onCancel}
            variant="ghost"
            disabled={!canClone}
            data-testid="cp-cancel-clone-button"
          >
            Cancel
          </Button>
          <Button
            p="s"
            onClick={onCloneHierarchy}
            variant={canClone ? "success" : "disabled"}
            disabled={!canClone || isValidating}
            data-testid="cp-clone-selected-button"
          >
            {isValidating && <Loading mr="s" />}
            {buttonText}
          </Button>
          {error && (
            <Notification
              floating={true}
              closable={false}
              centered={true}
              colour="danger"
            >
              {error.message}
            </Notification>
          )}
        </Flex>
      }
    >
      <Flex justifyContent="space-evenly" mt="r">
        <FlexItem flex="10 5 0px">
          <H5 weight="bold" m="s">
            Select the items you would like to clone
          </H5>
          <Box borderRadius={2} boxBorder="default" p="r" m="s" height="100%">
            <Flex height="100%" flexDirection="column">
              <FlexItem>
                <TeamSearch
                  onTeamSelected={onSelectRootGroup}
                  showButton={false}
                  divider={null}
                  placeholder="Type to search..."
                  kindFilters={TEAM_SEARCH_KIND_FILTERS}
                  groupTypes={groupTypes}
                  label="Select a team or organisation hierarchy to clone from"
                />
              </FlexItem>
              <FlexItem>
                <HierarchySelector
                  rootGroupId={rootGroup?.group?.id}
                  onChange={onHierarchyChange}
                  groupTypes={groupTypes}
                />
              </FlexItem>
              {selectedGroupIds > 0 && (
                <>
                  <FlexItem flexGrow="1" />
                  <FlexItem alignSelf="flex-end">
                    <H5 weight="bold" color="greyDark">
                      {selectedGroupsCount} of {totalGroupsCount} selected
                    </H5>
                  </FlexItem>
                </>
              )}
            </Flex>
          </Box>
        </FlexItem>
        <FlexItem flex="7 3 0px">
          <H5 weight="bold" m="s">
            Cloning options
          </H5>
          <Box borderRadius={2} boxBorder="default" p="r" m="s" height="100%">
            <HierarchyOptions
              hierarchyGroupTypeIds={hierarchyGroupTypeIds}
              onChange={onOptionsChange}
              groupTypes={groupTypes}
            />
          </Box>
        </FlexItem>
      </Flex>
    </Modal>
  );
};
CloneHierarchyModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func.isRequired,
  cloneToTeam: PropTypes.object.isRequired,
  groupTypes: PropTypes.object.isRequired,
};

export default React.memo(CloneHierarchyModal);
