import filterGroups from "./filterGroups";
import createTreeStructure from "./createTreeStructure";

export default ({
  parentGroup,
  descendantSupplyGroups,
  selectedSupplyGroupId,
  selectedSubSupplyGroupId,
}) => {
  const groups = filterGroups(descendantSupplyGroups);
  const selectedGroupId = selectedSubSupplyGroupId || selectedSupplyGroupId;
  return createTreeStructure(parentGroup, groups, selectedGroupId);
};
