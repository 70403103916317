import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import GroupPropType from "src/custom-prop-types/group";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import { canCreateChild } from "src/util/customerConfig";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";

import { createTeamInAllocation as createTeamMutation } from "src/allocation/team.graphql";
import AddNewTeamModal from "src/allocation/containers/AddNewTeamModal";
import { updateCache } from "../../../Team/AddNewTeamModal/util";

const AddNewTeamModalContainer = ({
  group,
  isVisible,
  onHideModal,
  runGroupCacheUpdateOnAddition = true,
}) => {
  const groupTypes = useGroupTypes();

  const [createTeam] = useMutation(createTeamMutation);

  const handleCreateNewTeam = useCallback(
    async (newTeamVariables) => {
      await createTeam({
        variables: newTeamVariables,
        update: (proxy, result) => {
          if (runGroupCacheUpdateOnAddition) {
            updateCache(proxy, result, newTeamVariables);
          }
          // Notify new group added
          eventEmitter.emit(
            EVENTS.NEW_GROUP_ADDED,
            result.data.team,
            proxy,
            newTeamVariables
          );
        },
      });
    },
    [createTeam, runGroupCacheUpdateOnAddition]
  );

  if (!canCreateChild(groupTypes, group)) {
    return null;
  }

  return (
    <AddNewTeamModal
      visible={isVisible}
      parentGroup={group}
      onHideModal={onHideModal}
      onCreateTeam={handleCreateNewTeam}
    />
  );
};

AddNewTeamModalContainer.propTypes = {
  group: GroupPropType.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func.isRequired,
  runGroupCacheUpdateOnAddition: PropTypes.bool,
};

export default React.memo(AddNewTeamModalContainer);
