import React from "react";
import PropTypes from "prop-types";
import { Flex, H2, Badge, FlexItem } from "orcs-design-system";

import { roundFte } from "src/util/roundingStrategy";
import { fullDisplayName } from "src/util/personName";

const AllocationModalHeader = ({ person, viewToggle }) => {
  const { fte = 0 } = person;

  return (
    <Flex alignItems="center">
      <H2 mr="s">Allocation for {fullDisplayName(person)}</H2>
      <Badge>{roundFte(fte)} FTE</Badge>
      {viewToggle ? (
        <FlexItem ml="auto" mr="xl">
          {viewToggle}
        </FlexItem>
      ) : null}
    </Flex>
  );
};

AllocationModalHeader.propTypes = {
  person: PropTypes.object.isRequired,
  viewToggle: PropTypes.node,
};

export default AllocationModalHeader;
