import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon, Popover, Button } from "orcs-design-system";

const StatusIndicatorWrapper = styled.div`
  position: absolute;
  left: -22px;
  top: 4px;
  white-space: pre-wrap;
`;

const IconButton = styled(Button)`
  background: none;
  border: none;
  padding: 2px 3px;

  &:hover,
  &:focus {
    background: none;
    border: none;
  }
`;

const PopoverMessage = `Saving failed.\nPlease click here to try again.`;

// eslint-disable-next-line react/display-name
const FailedIndicator = React.memo(
  ({ isFailed, retry, className = "", popoverMessage = PopoverMessage }) => {
    if (!isFailed) {
      return null;
    }

    return (
      <StatusIndicatorWrapper className={className}>
        <Popover
          direction="top"
          textAlign="center"
          width="188px"
          text={popoverMessage}
        >
          <IconButton
            className="retryBtn"
            small
            iconOnly
            data-testid="retry-button"
            onClick={retry}
          >
            <Icon icon={["fas", "save"]} size="1x" color="danger" />
          </IconButton>
        </Popover>
      </StatusIndicatorWrapper>
    );
  }
);

FailedIndicator.propTypes = {
  isFailed: PropTypes.bool,
  retry: PropTypes.func,
  className: PropTypes.string,
  popoverMessage: PropTypes.string,
};

export default FailedIndicator;
