import PropTypes from "prop-types";
import React, { createContext, useContext } from "react";

// Create a context
const PersonNodeContext = createContext();

// Create a provider component
export const PersonNodeProvider = ({ children, onExpandManagingClick }) => {
  return (
    <PersonNodeContext.Provider value={{ onExpandManagingClick }}>
      {children}
    </PersonNodeContext.Provider>
  );
};

PersonNodeProvider.propTypes = {
  children: PropTypes.node,
  onExpandManagingClick: PropTypes.func,
};

// Custom hook to use the PersonNodeContext
export const usePersonNode = () => {
  return useContext(PersonNodeContext);
};
