import Fuse from "fuse.js";
import { forEach, reduce, values, isEmpty, slice } from "lodash";

import { TAGS_LABEL, getTagTypeName } from "../utils/tags";

const MAX_OPTIONS_LENGTH = 15;

const searchSuggestionOptions = {
  shouldSort: false,
  tokenize: true,
  matchAllTokens: true,
  findAllMatches: true,
  threshold: 0.0,
  location: 0,
  distance: 0,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  includeMatches: true,
  keys: ["group.name", TAGS_LABEL, "hierarchyTokens"],
};

const getSearchItemType = (searchKey, item, refIndex) => {
  switch (searchKey) {
    case "group.name":
      return "TEAM";
    case "hierarchyTokens":
      return "HIERARCHY";
    case TAGS_LABEL:
      return getTagTypeName(item, refIndex);
    default:
      return "";
  }
};

const search = (items, searchTerm, options) => {
  const fuse = new Fuse(items, options);
  return fuse.search(searchTerm);
};

const createOptionsFromMatches = (results) => {
  const options = reduce(
    slice(results, 0, MAX_OPTIONS_LENGTH),
    (prev, r) => {
      const { item, matches } = r;

      if (!isEmpty(matches)) {
        forEach(matches, (m) => {
          const { key, value, refIndex } = m;

          if (!prev[value]) {
            // eslint-disable-next-line no-param-reassign
            prev[value] = {
              label: value,
              value,
              key,
              type: getSearchItemType(key, item, refIndex),
            };
          }
        });
      }

      return prev;
    },
    {}
  );

  return options;
};

export default (groups) => {
  return (searchTerm) => {
    const results = search(groups, searchTerm, searchSuggestionOptions);
    if (!results || !results.length) {
      return [];
    }

    const options = createOptionsFromMatches(results);
    return values(options);
  };
};
