import { FloatingPanels } from "orcs-design-system";
import React, { useMemo } from "react";
import { Panel } from "reactflow";
import { filter, isEmpty } from "lodash";
import { useOrgChartContext } from "src/components/OrgChart/context/OrgChartContext";
import ViewOptions from "./components/ViewOptions";
import Properties from "./components/Properties";

const panels = [
  {
    id: "view-options",
    iconName: "cog",
    title: "View Options",
    defaultExpanded: true,
    content: <ViewOptions />,
    isVisible({ data }) {
      return data?.team?.members?.length;
    },
  },
  {
    id: "properties",
    iconName: "filter",
    title: "Properties",
    defaultExpanded: false,
    content: <Properties />,
    isVisible: ({ data }) => {
      return data?.team?.members?.length || data?.team?.childTeams?.length;
    },
  },
];

const OrgChartPanel = () => {
  const context = useOrgChartContext();
  const shouldPropertiesPanelExpanded =
    !isEmpty(
      filter(
        context?.state?.properties?.peopleData,
        (val) => val?.data?.isSelected
      )
    ) ||
    !isEmpty(
      filter(
        context?.state?.properties?.teamsData,
        (val) => val?.data?.isSelected
      )
    );

  const filteredPanels = useMemo(() => {
    return panels
      .filter((panel) => panel.isVisible(context))
      .map((panel) => {
        if (panel.id === "properties") {
          return {
            ...panel,
            defaultExpanded: shouldPropertiesPanelExpanded,
          };
        }
        return panel;
      });
  }, [context, shouldPropertiesPanelExpanded]);

  return (
    <Panel position="top-right">
      <FloatingPanels
        panels={filteredPanels}
        containerHeight={500}
        position={{
          right: 0,
          top: 0,
        }}
      />
    </Panel>
  );
};

export default OrgChartPanel;
