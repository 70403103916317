import { useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import debug from "debug";

import { markGroupAsHidden } from "src/allocation/team.graphql";
import { RequestAllocationContext } from "src/allocation/contexts/requestAllocationContext";
import { PageRefreshContext } from "src/contexts/pageRefresh/PageRefreshContext";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";

import HideTeamDialog from "./HideTeamDialog";
import HideTeamNotification from "./HideTeamNotification";

const debugLog = debug("orchestrated:hide-team");

const HideTeamModal = ({ selectedGroup, updateCacheOnMutation }) => {
  const [
    { requestorGroup, showHideTeamModal, isDisplayHiddenTeams },
    dispatch,
  ] = useContext(RequestAllocationContext);
  const [{ refreshFn }] = useContext(PageRefreshContext);
  const [notificationTeam, setNotificationTeam] = useState();
  // manage separate mutation error state, so we can clear it
  const [mutationError, setMutationError] = useState();
  const [onHideTeamMutation, { loading: mutationLoading }] = useMutation(
    markGroupAsHidden,
    {
      onCompleted: (data) => {
        // send feedback notification
        setNotificationTeam(data.team);
        setTimeout(() => {
          setNotificationTeam(null);
        }, 2500);
        dispatch({ type: "showHideTeamModal", isModalShown: false });
        if (refreshFn) {
          refreshFn();
        }
      },
      onError: (err) => {
        setMutationError(err);
      },
    }
  );

  const onDismissSuccessNotification = () => setNotificationTeam(null);
  const onDismissHideTeamModal = () => {
    setMutationError(null);
    dispatch({ type: "showHideTeamModal", isModalShown: false });
  };

  const updateTeamAsHidden = async (id, isHidden) => {
    try {
      await onHideTeamMutation({
        variables: {
          id,
          isHidden,
        },
        update: (proxy) => updateCacheOnMutation(proxy),
      });

      eventEmitter.emit(EVENTS.GROUP_REMOVED, selectedGroup);
    } catch (err) {
      debugLog(err); // intentionally swallowed, 'mutationError' handles error.
    }
  };

  const onHideTeam = (id, isHidden) => async () => {
    // Jump to the request group if the team is hidden, in other case still stay on the node.
    if (!isDisplayHiddenTeams) {
      dispatch({
        type: "selectTargetGroup",
        selectedTargetGroup: requestorGroup,
        isParent: true,
        isRoot: true,
      });
    }
    await updateTeamAsHidden(id, isHidden);
  };

  return (
    <>
      <HideTeamDialog
        selectedTargetGroup={selectedGroup}
        visible={showHideTeamModal}
        onDismissHideTeamModal={onDismissHideTeamModal}
        onHideTeam={onHideTeam}
        loading={mutationLoading}
        error={mutationError}
      />
      {notificationTeam && (
        <HideTeamNotification
          team={notificationTeam}
          onDismissNotification={onDismissSuccessNotification}
        />
      )}
    </>
  );
};

HideTeamModal.propTypes = {
  selectedGroup: PropTypes.object.isRequired,
  updateCacheOnMutation: PropTypes.func.isRequired,
};

export default HideTeamModal;
