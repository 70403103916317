import { useApolloClient } from "@apollo/client";
import { filter, map, find } from "lodash";
import { useCallback } from "react";

import { reloadAllocatedPersonAndGroups } from "src/queries/allocation.graphql";

const verifyMembership = (directGroups, targetGroup, person, checkExisting) => {
  const group = find(directGroups, (g) => g.id === targetGroup.id);
  if (!group) {
    return false;
  }

  const { members } = group;

  const targetPerson = find(
    members,
    (m) => m.aggregateId === person.aggregateId
  );

  return checkExisting ? !!targetPerson : !targetPerson;
};

// eslint-disable-next-line import/prefer-default-export
export const useUpdateTeamAndMemberDetails = () => {
  const client = useApolloClient();

  const triggerQuery = useCallback(
    ({ person, groups, targetGroup, checkExisting = true, fteSumInput }) => {
      return new Promise((resolve, reject) => {
        const directGroupIds = filter(map(groups, "id"));
        let retry = 0;

        // Use client to query again, apollo cache will be updated
        const queryData = () => {
          return client
            .query({
              query: reloadAllocatedPersonAndGroups,
              fetchPolicy: "network-only",
              variables: {
                personId: person.aggregateId,
                directGroupIds,
                fteSumInput,
              },
            })
            .then(({ data, error }) => {
              if (error) {
                reject(error);
                return;
              }

              if (targetGroup) {
                // Verify whether person is inside the response or not
                // It seems sometimes Elastic Search needs some time to update
                const result = verifyMembership(
                  data.directGroups,
                  targetGroup,
                  person,
                  checkExisting
                );

                if (!result) {
                  if (retry < 3) {
                    retry += 1;
                    // Trigger queryData again and return
                    setTimeout(queryData, 200);
                    return;
                  }
                  // If retried for 3 times, but target person still not in the targetGroup
                  // reject the promise and ask user to refresh
                  reject(new Error("Please refresh page"));
                }
              }

              resolve(data);
            })
            .catch((err) => reject(err));
        };

        queryData();
      });
    },
    [client]
  );

  return triggerQuery;
};
