import React from "react";
import PropTypes from "prop-types";
import { H2, Badge, Flex, FlexItem } from "orcs-design-system";

import { roundFte } from "src/util/roundingStrategy";
import { fullDisplayName } from "src/util/personName";

const ModalHeader = ({ person, viewToggle }) => {
  return (
    <Flex alignItems="center">
      <H2 mr="s">Allocation for {fullDisplayName(person)}</H2>
      <Badge>{roundFte(person.fte)} FTE</Badge>
      {viewToggle ? (
        <FlexItem ml="auto" mr="xl">
          {viewToggle}
        </FlexItem>
      ) : null}
    </Flex>
  );
};

ModalHeader.propTypes = {
  person: PropTypes.object,
  viewToggle: PropTypes.node,
};

export default ModalHeader;
