import { useContext, useEffect, useCallback } from "react";
import {
  PageRefreshContext,
  ACTIONS as PAGE_REFRESH_ACTIONS,
} from "src/contexts/pageRefresh/PageRefreshContext";

import { useModel, useDispatch, ACTIONS } from "./context";

const useRefreshPage = () => {
  const { loading } = useModel();
  const dispatch = useDispatch();
  const [, dispatchToPageRefreshContext] = useContext(PageRefreshContext);

  // refresh calllback
  const handleRefresh = useCallback(() => {
    dispatch({
      type: ACTIONS.MAIN_QUERY_REFRESH,
      backgroundRefresh: false,
    });
  }, [dispatch]);

  // connect and disconnect to refreshFn
  useEffect(() => {
    dispatchToPageRefreshContext({
      type: PAGE_REFRESH_ACTIONS.SET_REFRESH_FN,
      refreshFn: handleRefresh,
      showRefreshButton: false,
    });

    return () => {
      dispatchToPageRefreshContext({
        type: PAGE_REFRESH_ACTIONS.SET_REFRESH_FN,
        refreshFn: null,
      });
    };
  }, [handleRefresh, dispatchToPageRefreshContext]);

  // connect loading to isRefresh
  useEffect(() => {
    dispatchToPageRefreshContext({
      type: PAGE_REFRESH_ACTIONS.SET_IS_REFRESHING,
      isRefreshing: loading,
    });
  }, [loading, dispatchToPageRefreshContext]);
};

export default useRefreshPage;
