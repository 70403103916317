import { roundFte } from "src/util/roundingStrategy";

export const toState = (base, delta, decimalScale) => {
  const d = parseFloat(delta) || 0;
  const b = parseFloat(base) || 0;
  const total = roundFte(b + d, decimalScale);
  return {
    delta: d,
    base: b,
    total,
    text: total,
  };
};

export const updateState = (base, newTotal, decimalScale = 4) => {
  const b = parseFloat(base) || 0;
  const nt = parseFloat(newTotal) || 0;
  return {
    delta: roundFte(nt - b, decimalScale),
    base: b,
    total: nt,
    text: nt,
  };
};

export const getPopoverMessage = (doNotAllowZeroFte, doNotAllowExceededFte) => {
  let popoverMessage =
    "Press this button to save any changes you've made to this FTE value.";
  if (doNotAllowZeroFte) {
    popoverMessage =
      "Can't apply 0 FTE. If you want to remove this person from this team, press the remove button.";
  } else if (doNotAllowExceededFte) {
    popoverMessage = "Can't apply more FTE than the person's total FTE.";
  }

  return popoverMessage;
};
