import React from "react";
import PropTypes from "prop-types";
import { find } from "lodash";
import PopoverNotification from "./PopoverNotification";

const ProjectAnnouncement = ({ announcements, messageType }) => {
  const announcement = find(announcements, {
    messageType,
    isActive: true,
  });

  if (announcement) {
    const { content, description } = announcement;
    return <PopoverNotification text={content} popoverText={description} />;
  }

  return null;
};

ProjectAnnouncement.propTypes = {
  announcements: PropTypes.arrayOf(PropTypes.object),
  messageType: PropTypes.string,
};

export default ProjectAnnouncement;
