import React from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "orcs-design-system";
import PATHS from "src/consts/urlPaths";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import NodeGraph from "../Visualisations/Reagraph/NodeGraph";
import { visualisationPath } from "../../util/routing";
import { buildPersonGraph } from "../../util/buildNodes";

const handleNodeDoubleClick = () => (type, id) => {
  if (id) {
    window.location.href = visualisationPath(type, id);
  } else {
    window.location.href = PATHS.VISUALISATION;
  }
};

const PersonVisualisation = ({ person, history }) => {
  const groupTypes = useGroupTypes();
  const nodes = buildPersonGraph(person, groupTypes);
  return (
    <Box
      bg="white"
      boxBorder="default"
      shadow="default"
      borderRadius={2}
      overflow="hidden"
      height="100%"
    >
      <Flex
        justifyContent="flex-start"
        alignContent="stretch"
        alignItems="stretch"
        height="100%"
      >
        <NodeGraph
          nodes={nodes}
          targetNode={{ id: person.aggregateId, type: "person" }}
          handleNodeDoubleClick={handleNodeDoubleClick(history)}
          showNavigation={false}
        />
      </Flex>
    </Box>
  );
};

PersonVisualisation.propTypes = {
  person: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default PersonVisualisation;
