const copywriting = {
  name: "Tag type name",
  description: "Tag type description",
  allowGroupToHaveTag: "Allow groups to have this tag type",
  allowedGroupTypes: "Allowed group types",
  isGroupAssociation: "Allow tag type as group association",
  allowPersonToHaveTag: "Allow individual to have this tag type",
  allowMultipleOnEntity:
    "Allow individual/group to have multiple tags of this tag type",
  isEditableByUsers: "Allow users to add new tag values",
  allowedValues: "Allowed values",
  showTagTypeIn: "Show tag type in",
  isGroupBadge: "Highlight as group tag",
  isActive: "Allow user to select this tag",
  showPreviousAppliedTags: "Show removed tags",
  allowedToEditTagDisplayValue: "Allow users to edit the value of tag",
  isPersonBadge: "Highlight as person tag",
  readOnly: "Read only",
  allowEditingReadOnlyTagsByAdmin: "Allow Admin to edit read only tags",
  allowEditingReadOnlyTagsByPowerUser:
    "Allow Power User to edit read only tags",
  hideShowInfoIconInTagDisplay: "Hide show info icon in tag display",
  hideTagTypeInTagDisplay: "Hide tag type in tag display",
};

export default copywriting;
