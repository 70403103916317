import React from "react";
import { isEmpty, filter } from "lodash";
import { Flex } from "orcs-design-system";
import PropTypes from "prop-types";
import GroupPropType from "src/custom-prop-types/group";
import {
  useWorkspaceTagConfig,
  useTagTypes,
} from "src/contexts/global/WorkspaceContext";
import useHandleEntityTagClick from "src/hooks/useHandleEntityTagClick";
import TagsEditor from "src/components/TagsEditor/TagsEditor";

const HighlightedGroupTags = ({ team, small = false, mt = "0" }) => {
  const handleEntityTagClick = useHandleEntityTagClick();
  const { groupBadge } = useTagTypes();
  const tagTypesConfig = useWorkspaceTagConfig();

  const highlightedTags = filter(
    team.tags,
    (tag) => !!groupBadge.find((tagType) => tagType === tag.type)
  );

  if (isEmpty(highlightedTags)) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      flexWrap="wrap"
      role="list"
      data-testid="highlighted-group-badges"
      mt={mt}
    >
      <TagsEditor
        readOnly
        tagTypesConfig={tagTypesConfig}
        existingTags={highlightedTags}
        onEntityTagClick={handleEntityTagClick}
        highlighted
        inline
        small={small}
      />
    </Flex>
  );
};

HighlightedGroupTags.propTypes = {
  team: GroupPropType.isRequired,
  small: PropTypes.bool,
  mt: PropTypes.string,
};

export default HighlightedGroupTags;
