import PropTypes from "prop-types";

export default PropTypes.shape({
  allocationProjectId: PropTypes.string,
  allocationId: PropTypes.string,
  existingFte: PropTypes.number,
  fte: PropTypes.number,
  personId: PropTypes.string,
  status: PropTypes.string,
  targetGroupId: PropTypes.string,
});
