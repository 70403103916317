import { sortBy } from "lodash";
import themeGet from "@styled-system/theme-get";
import { NODE_TYPES } from "src/components/NodeVisualizer/consts";
import { getGroupBadgeVariant, isGroupActive } from "src/util/group";

import {
  isRealTimeAllocationsEnabled,
  isAddingMemberEnabled,
} from "src/util/allocations";
import { COLLECTION_NODE_DISPLAY_MODE } from "../../../../NodeVisualizer/Nodes/CollectionNode/index";
import { getTargetGroupAndFilter } from "../../../utils/getTargetGroupAndFilter";

import getMembersCollectionLabel from "./getMembersCollectionLabel";

export const createNode = (id, data, type, position = { x: 0, y: 0 }) => {
  const node = {
    id,
    data,
    type,
    position,
  };

  if (data?.isAnchor) {
    node.isAnchor = true;
  }

  return node;
};

export const createCollectionNode = ({ id, items, label, data = {} }) => {
  return createNode(id, { ...data, items, label }, NODE_TYPES.COLLECTION_NODE);
};

export const getCreatableNodeTypes = ({
  groupType,
  isGroupedBySupplyOrDemand,
  group,
  canAllocateMembers,
}) => {
  if (
    (groupType?.isSupply && !isGroupedBySupplyOrDemand) ||
    group.id === "unallocated"
  ) {
    return [];
  }

  return [
    { type: NODE_TYPES.CREATE_PERSON_NODE, canCreate: canAllocateMembers },
  ];
};

export const createEdge = (source, target, type = "smoothstep") => ({
  id: `${target}-${source}`,
  source,
  target,
  type,
});

export const createMembersCollectionNode = ({
  group,
  members = [],
  isGroupedBySupplyOrDemand,
  contextualData = {},
}) => {
  const {
    groupTypes,
    theme,
    state,
    user,
    workspace,
    allocationProject,
    viewGroup,
  } = contextualData;
  const groupType = groupTypes[group.type];
  const getColor = (color) => themeGet(`colors.${color}`)({ theme });
  const isUnallocatedCollection = group.id !== "unallocated";

  const variant = groupType ? getGroupBadgeVariant(group, groupTypes) : "grey";

  const borderColor = getColor(`${variant}40`);
  const labelColor = getColor(variant);

  const { filter, targetGroup, filterGroup } = getTargetGroupAndFilter({
    state,
    collectionGroup: group,
    viewGroup,
    groupTypes,
  });

  const allocationsEnabled =
    isRealTimeAllocationsEnabled({
      user,
      workspace,
      allocationProject,
    }) && isAddingMemberEnabled(workspace, targetGroup);

  const canAllocateMembers = allocationsEnabled && isGroupActive(targetGroup);
  const { id: groupId = "", name: groupName = "" } = group || {};

  const id = `${groupId}_${groupName.split(" ").join("_")}_members_collection`;

  const creatableNodeTypes = getCreatableNodeTypes({
    groupType,
    isGroupedBySupplyOrDemand,
    group,
    canAllocateMembers,
  });

  return createCollectionNode({
    id,
    items: members.map((member) =>
      createNode(member.aggregateId, member, NODE_TYPES.PERSON_NODE)
    ),
    label: getMembersCollectionLabel({
      group,
      variant,
      isGroupedBySupplyOrDemand,
    }),
    data: {
      id,
      groupId: group.id,
      borderStyle:
        isGroupedBySupplyOrDemand && isUnallocatedCollection
          ? `3px solid ${borderColor}`
          : `3px dashed ${getColor("greyLight")}`,
      labelColor: isGroupedBySupplyOrDemand ? labelColor : getColor("grey"),
      displayMode: isGroupedBySupplyOrDemand
        ? COLLECTION_NODE_DISPLAY_MODE.SINGLE_COLUMN
        : null,
      variant,
      creatableNodeTypes,
      contextForCreation:
        creatableNodeTypes.filter(({ canCreate }) => canCreate).length === 0
          ? null
          : { team: group, filter, targetGroup, filterGroup },
    },
  });
};

export const extractGroupNodesAndEdges = (data, team) => {
  if (!data || !team) return { nodes: [], edges: [] };

  const { childTeams } = data?.team || {};
  const groups = [
    {
      ...team,
      childTeams,
      directMemberCount: data?.team?.members?.length || 0,
      isAnchor: true,
    },
  ];

  const nodes = [];
  const edges = [];

  const expandButtonId = `${team.id}_expand_button`;

  if (team.directParentId) {
    nodes.push(
      createNode(
        expandButtonId,
        { ...team, isAnchor: true },
        NODE_TYPES.EXPAND_BUTTON_NODE
      )
    );

    edges.push(createEdge(expandButtonId, team.id));
  }

  if (childTeams.length <= 3) {
    groups.push(...childTeams);
  } else {
    const groupCollectionNode = createCollectionNode({
      id: `${team.id}_direct_teams`,
      items: childTeams.map((childTeam) =>
        createNode(childTeam.id, childTeam, NODE_TYPES.GROUP_NODE)
      ),
      label: "Teams",
    });

    nodes.push(groupCollectionNode);

    edges.push(createEdge(team.id, groupCollectionNode.id));
  }

  groups.forEach((group) => {
    nodes.push(createNode(group.id, group, NODE_TYPES.GROUP_NODE));

    group.parentIds?.forEach((parentId) => {
      if (groups.find((g) => g.id === parentId)) {
        edges.push(createEdge(parentId, group.id));
      }
    });
  });

  return { nodes, edges };
};

export const initializeNodesAndEdges = ({ data, team, contextualData }) => {
  if (!data || !team) return { nodes: [], edges: [] };

  const nodes = [];
  const edges = [];

  if (data?.team?.members) {
    const membersNode = createMembersCollectionNode({
      members: data?.team?.members,
      group: team,
      contextualData,
    });

    const membersEdge = createEdge(team.id, membersNode.id);

    nodes.push(membersNode);
    edges.push(membersEdge);
  }

  return {
    nodes,
    edges,
  };
};

export const getNodesAndEdgesGroupMembersByGroupType = ({
  type,
  contextualData,
  data,
  team,
}) => {
  const { groupTypes } = contextualData;
  if (!data || !team) return { nodes: [], edges: [] };

  const nodes = [];
  const edges = [];
  const memberCollections = {};

  const updateMemberCollections = (group, member) => {
    if (memberCollections[group.id]) {
      memberCollections[group.id].members.push(member);
    } else {
      memberCollections[group.id] = {
        name: group.name,
        group,
        members: [member],
      };
    }
  };

  if (!data?.team?.members.length) {
    const membersNode = createMembersCollectionNode({
      members: data?.team?.members,
      group: data?.team,
      isGroupedBySupplyOrDemand: false,
      contextualData,
    });

    const membersEdge = createEdge(data?.team.id, membersNode.id);

    nodes.push(membersNode);
    edges.push(membersEdge);
  }

  data?.team?.members.forEach((member) => {
    const groupableTeams = member.memberOf.filter((group) => {
      const groupType = groupTypes[group.type];

      if (!groupType) return false;

      if (type === "supply" && groupType.isSupply) {
        return true;
      }

      if (type === "demand" && groupType.isDemand) {
        return true;
      }

      return false;
    });

    groupableTeams.forEach((group) => {
      updateMemberCollections(group, member);
    });

    if (!groupableTeams.length) {
      updateMemberCollections(
        { id: "unallocated", name: "Unallocated" },
        member
      );
    }
  });

  sortBy(Object.values(memberCollections), "name").forEach((collection) => {
    const membersNode = createMembersCollectionNode({
      members: collection.members,
      group: collection.group,
      isGroupedBySupplyOrDemand: true,
      contextualData,
    });

    const membersEdge = createEdge(data?.team.id, membersNode.id);

    nodes.push(membersNode);
    edges.push(membersEdge);
  });

  return {
    nodes,
    edges,
  };
};
