import React, { useRef, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { GraphCanvas, useSelection } from "reagraph";

import { Button } from "orcs-design-system";
import { useTheme } from "styled-components";

import mapDataToNodes from "src/components/Visualisations/Reagraph/mapDataToNodes";
import * as nodeUtils from "./Node.utils";
import { pickItems } from "./Node.utils";
import {
  convertData,
  convertTypes,
  GraphWrapper,
  ControlsWrapper,
  getCanvasTheme,
  initialNode,
} from "./utils";
import { TFCustomNode } from "./TFCustomNode";

const explorePageTypesMap = (type) => {
  if (type === "sourceteam") {
    return "team";
  }
  return type;
};

const NodeGraph = ({
  nodes,
  targetNode,
  handleNodeDoubleClick,
  setDownloadFunctionCallback,
  setHighlightTargetFunctionCallback,
  layout = "organic",
  settings,
  showNavigation = true,
}) => {
  const chartData = useMemo(() => {
    const data = mapDataToNodes(
      nodeUtils.getFormattedNodes(targetNode, nodes),
      targetNode.id
    );
    return pickItems(data, settings);
  }, [targetNode, nodes, settings]);

  const chartRef = useRef(null);

  const graphRef = useRef();
  const [layoutType, setLayoutType] = useState("forceDirected2d");
  const orcsTheme = useTheme();
  const data = convertData(
    nodes,
    convertTypes(settings),
    orcsTheme,
    targetNode
  );

  const downloadImage = () => {
    const url = graphRef.current?.getControls()._domElement?.toDataURL();
    const fakeDownloader = document.createElement("a");
    fakeDownloader.href = url;
    fakeDownloader.download = "canvas";
    fakeDownloader.click();
    fakeDownloader.remove();
  };

  useEffect(() => {
    setLayoutType(
      layout === "hierarchy" ? "hierarchicalTd" : "forceDirected2d"
    );
  }, [layout]);

  useEffect(() => {
    setTimeout(() => graphRef.current?.centerGraph(), 50);
  }, [layout, settings]);

  useEffect(() => {
    if (setDownloadFunctionCallback) {
      setDownloadFunctionCallback(() => {
        downloadImage();
      });
    }
    if (setHighlightTargetFunctionCallback) {
      setHighlightTargetFunctionCallback(() => {
        if (chartRef.current) {
          chartRef.current.ping(targetNode.id, {
            color: "rgba(255, 204, 0, 0.5)",
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDoubleClick = ({ id }) => {
    const item = chartData[id];
    if (item && item.type === "node" && item.data) {
      const type = item.data.type.toLowerCase();
      if (["person", "team", "sourceteam"].includes(type) && id) {
        handleNodeDoubleClick(explorePageTypesMap(type), id);
      }
    }
  };

  const { selections, actives, onNodeClick, onCanvasClick } = useSelection({
    ref: graphRef,
    nodes: data.nodes,
    edges: data.edges,
    selections: [data.nodes[0]?.id],
    pathSelectionType: "all",
  });

  const ControlsButton = ({ onClickAction, text, last }) => {
    return (
      <Button
        mb={last ? "0" : "s"}
        small
        variant="ghost"
        width="100%"
        onClick={onClickAction}
      >
        {text}
      </Button>
    );
  };
  ControlsButton.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    onClickAction: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    last: PropTypes.bool,
  };

  if (data?.nodes?.length <= 0) {
    return (
      <GraphWrapper>
        <GraphCanvas
          theme={getCanvasTheme(orcsTheme)}
          nodes={[{ ...initialNode(orcsTheme) }]}
          edges={[]}
          animated={true}
          renderNode={({ node, ...rest }) => (
            <TFCustomNode
              {...rest}
              node={node}
              image={node.icon || ""}
              borderColor={node.borderColor}
              counter={node.counter}
            />
          )}
        />
      </GraphWrapper>
    );
  }

  return (
    <GraphWrapper>
      {showNavigation && (
        <ControlsWrapper>
          <ControlsButton
            onClickAction={() => graphRef.current?.centerGraph()}
            text="Centre"
          />
          <ControlsButton
            onClickAction={() => graphRef.current?.zoomIn()}
            text="Zoom in"
          />
          <ControlsButton
            onClickAction={() => graphRef.current?.zoomOut()}
            text="Zoom out"
          />
          <ControlsButton
            onClickAction={() => graphRef.current?.panDown()}
            text="Move down"
          />
          <ControlsButton
            onClickAction={() => graphRef.current?.panUp()}
            text="Move up"
          />
          <ControlsButton
            onClickAction={() => graphRef.current?.panLeft()}
            text="Move left"
          />
          <ControlsButton
            onClickAction={() => graphRef.current?.panRight()}
            text="Move right"
            last
          />
        </ControlsWrapper>
      )}
      <GraphCanvas
        ref={graphRef}
        theme={getCanvasTheme(orcsTheme)}
        selections={selections}
        actives={actives}
        layoutType={layoutType}
        onCanvasClick={onCanvasClick}
        onNodeClick={onNodeClick}
        onNodeDoubleClick={onDoubleClick}
        nodes={data?.nodes}
        edges={data?.edges}
        animated={true}
        draggable
        renderNode={({ node, ...rest }) => (
          <TFCustomNode
            {...rest}
            node={node}
            image={node.icon || ""}
            borderColor={node.borderColor}
            counter={node.counter}
          />
        )}
      />
    </GraphWrapper>
  );
};

NodeGraph.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      relationship: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          ids: PropTypes.arrayOf(PropTypes.string),
        })
      ),
    })
  ).isRequired,
  targetNode: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  handleNodeDoubleClick: PropTypes.func.isRequired,
  setDownloadFunctionCallback: PropTypes.func,
  setHighlightTargetFunctionCallback: PropTypes.func,
  settings: PropTypes.object,
  showNavigation: PropTypes.bool,
  layout: PropTypes.string,
};

export default NodeGraph;
