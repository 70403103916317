import {
  set,
  map,
  groupBy,
  reduce,
  entries,
  keyBy,
  mapValues,
  uniq,
  sumBy,
} from "lodash";
import { fullDisplayName } from "src/util/personName";
import { roundFte } from "src/util/roundingStrategy";

const getTotalAssignedFte = (allocations) => sumBy(allocations, "fte");

export default (
  group,
  members,
  individualAllocations,
  memberTags,
  groupLookup
) => {
  const tagsLookup = mapValues(keyBy(memberTags, "id"), "tags");
  const membersWithPreAllocations = reduce(
    members,
    (result, person) => {
      const { aggregateId: personId, allocations, fte } = person;
      const preallocations = map(allocations, (allocation) => ({
        ...allocation,
        personId, // add personId to allocation to be the same shape as newIndividualAllocations
        targetGroup: groupLookup[allocation.targetGroupId],
      }));
      set(result, personId, {
        person,
        fullName: fullDisplayName(person),
        tags: tagsLookup[personId] || [],
        isNewlyAdded: false,
        isRemoved: false,
        allocations: preallocations,
        teamNames: uniq(map(preallocations, "targetGroup.name")),
        remainingFte: roundFte(fte - getTotalAssignedFte(preallocations)),
      });
      return result;
    },
    {}
  );

  // merge the originalMembers with individualAllocations to create the final result
  const membersWithMergedAllocations = reduce(
    entries(groupBy(individualAllocations, "personId")),
    (result, [personId, newIndividualAllocationsForPerson]) => {
      const existing = result[personId];
      const userAllocations = map(
        newIndividualAllocationsForPerson,
        (allocation) => ({
          ...allocation,
          personId,
          targetGroup: groupLookup[allocation.targetGroupId],
        })
      );
      const teamNames = uniq(map(userAllocations, "targetGroup.name"));

      if (existing) {
        // user allocations existing person within the group
        set(result, personId, {
          ...existing,
          allocations: userAllocations, // overwrite preallocations, with user defined allocations
          teamNames,
          remainingFte: roundFte(
            existing.person.fte - getTotalAssignedFte(userAllocations)
          ),
        });
      } else {
        // user allocations for new person not originally within group
        set(result, personId, {
          person: { aggregateId: personId }, // TODO: load full persons details
          fullName: fullDisplayName({}),
          tags: tagsLookup[personId] || [],
          isNewlyAdded: true,
          isRemoved: false,
          allocations: userAllocations,
          teamNames,
          remainingFte: 0, // don't know the remaining FTE unless we load full person details
        });
      }
      return result;
    },
    membersWithPreAllocations
  );

  return membersWithMergedAllocations;
};
