import { useMemo, useRef } from "react";
import { useQuery } from "@apollo/client";
import { isEmpty, map } from "lodash";

import { getForecastAllocationProjects } from "src/allocation/util/allocationProject";
import { getAllocationProjects } from "src/allocation/allocation.graphql";

import {
  usePageMode,
  useMainQuery,
  useUserInteraction,
  useActiveAllocationProject,
  useDispatch,
  ACTIONS,
  PAGE_MODES,
} from "./context/ForecastContext";

const usePageMainQuery = () => {
  const pageMode = usePageMode();
  const { viewMode } = useUserInteraction();
  const { variables: queryVariables, query, firstLoad } = useMainQuery();
  const prevVariables = useRef(null);
  const activeAllocationProject = useActiveAllocationProject();
  const dispatch = useDispatch();

  const isPlannerPage = pageMode !== PAGE_MODES.FORECAST;

  const { data: result } = useQuery(getAllocationProjects, {
    skip: isPlannerPage,
  });

  const allocationProjects = useMemo(() => {
    return isPlannerPage
      ? [activeAllocationProject]
      : getForecastAllocationProjects(result?.allocationProjects);
  }, [activeAllocationProject, isPlannerPage, result]);

  const variables = useMemo(() => {
    return {
      ...queryVariables,
      allocationProjectIds: map(allocationProjects, "id"),
    };
  }, [queryVariables, allocationProjects]);

  useQuery(query, {
    variables,
    fetchPolicy: "network-only",
    skip: isEmpty(allocationProjects) || prevVariables.current === variables,
    onCompleted: (data) => {
      if (prevVariables.current === variables) {
        return;
      }
      prevVariables.current = variables;
      dispatch({
        type: ACTIONS.MAIN_QUERY_COMPLETE,
        data: {
          ...data,
          allocationProjects,
        },
        viewMode,
        pageMode,
        shouldHash: !firstLoad,
      });
    },
    onError: (error) => {
      dispatch({ type: ACTIONS.MAIN_QUERY_ERROR, error });
    },
  });
};

export default usePageMainQuery;
