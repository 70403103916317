import React from "react";
import { Box, Flex, Small, H3 } from "orcs-design-system";
import themeGet from "@styled-system/theme-get";
import styled from "styled-components";
import PropTypes from "prop-types";

import GroupTypeBadge from "src/components/GroupTypeBadge";

const BlockContainer = styled(Box)`
  border-radius: ${themeGet("radii.2")};
  box-shadow: 0 1px 4px 0px ${themeGet("colors.black30")};
  overflow: hidden;
  text-decoration: none;
`;

const Block = styled(Box)`
  position: relative;
  background-image: ${({ bgGradient }) => bgGradient};
  background-size: cover;
  background-position: center center;
  border-radius: ${(props) => (props.parent ? themeGet("radii.2") : "0")};
`;

const TeamBlock = ({ gradient }) => {
  return (
    <BlockContainer>
      <Block
        p="l"
        width="100%"
        height="100%"
        minHeight="200px"
        bgGradient={gradient}
      >
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Box p="r" display="inline-block" bg="black60">
            <>
              <Flex alignItems="center" flexWrap="wrap">
                <H3 mr="s" color="white" breakWord>
                  Preview: Agile Group name
                </H3>
                <Box my="2px">
                  <GroupTypeBadge
                    group={{ id: "1", type: "Agile Group" }}
                    groupTypes={{}}
                  />
                </Box>
              </Flex>
              <Small color="white">100 members</Small>
            </>
          </Box>
        </Flex>
      </Block>
    </BlockContainer>
  );
};

TeamBlock.propTypes = {
  gradient: PropTypes.string,
};

export default TeamBlock;
