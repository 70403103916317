import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";

import { ACTIONS } from "../../../context/ForecastContext";

const BulkAddingTagButton = ({ grouping, dispatch }) => {
  const handleAddingTags = useCallback(
    (e) => {
      e.stopPropagation();

      dispatch({
        type: ACTIONS.SHOW_BULK_ADDING_TAG_MODAL,
        grouping,
      });
    },
    [grouping, dispatch]
  );

  return (
    <ActionsMenuItem onClick={handleAddingTags}>Bulk add tags</ActionsMenuItem>
  );
};

BulkAddingTagButton.propTypes = {
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default BulkAddingTagButton;
