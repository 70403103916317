import React, { Suspense, useState, useMemo, useRef, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import { P } from "orcs-design-system";

import { useAllocationProject } from "src/contexts/global/GlobalContext";
import {
  useGroupTypes,
  useWorkspace,
} from "src/contexts/global/WorkspaceContext";
import { useModal } from "src/contexts/withModal";
import PersonAllocationsModal from "src/components/Person/AllocationsModal";
import GroupPropType from "src/custom-prop-types/group";
import { getMemberships } from "src/queries/memberships.graphql";
import { FullPageLoading } from "src/components/FullPageLoading";
import {
  getLoadStartDate,
  getLoadEndDate,
  timelineOptions,
} from "src/util/memberships";

import { convertMemberships, getPersonContainerId } from "./utils";
import TimelineGroupHeaders from "./TimelineGroupHeaders";
import { TabWrapper } from "./index.styles";

const Timeline = React.lazy(() => import("src/components/Timeline"));

const MembershipsTab = ({ team }) => {
  const workspace = useWorkspace();
  const groupTypes = useGroupTypes();
  const allocationProject = useAllocationProject();
  const [, dispatch] = useModal();
  const [isTimelineRendered, setIsTimelineRendered] = useState(false);
  const [isGroupHeadersRendered, setIsGroupHeadersRendered] = useState(false);
  const timelineRef = useRef(null);

  const { id: teamId } = team;
  const timeline = timelineRef.current?.timeline;

  const { data, refetch } = useQuery(getMemberships, {
    variables: {
      input: {
        groupIds: [teamId],
        groupKinds: ["team"],
        statuses: ["ALLOCATED"],
        selectGroup: false,
        selectAncestorIds: false,
        selectPerson: true,
        startDate: getLoadStartDate(true),
        endDate: getLoadEndDate(true),
      },
      includePerson: true,
      includeGroup: false,
    },
    fetchPolicy: "cache-and-network",
    skip: !teamId,
  });

  const { items, groups } = useMemo(() => {
    if (!data?.result?.memberships) {
      return { items: [], groups: [] };
    }

    const convertedData = convertMemberships(data.result.memberships);

    // Use below function to re-render the timeline
    if (timeline) {
      timeline.setData(convertedData);
    }

    return convertedData;
  }, [data, timeline]);

  const onTimelineRendered = useCallback(() => {
    setIsTimelineRendered(true);
  }, []);

  const onEditingAllocations = (person) => {
    const onHideModal = () => {
      refetch();
      dispatch({ type: "setModalVisible", isModalVisible: false });
    };

    // We need a person object that can be changed in the modal
    // When user change anything of the person, the modal is not re-rendered,
    // so we have to update the person and re-fresh the modal
    // TODO: Find a good way to do this...
    const personForUpdate = { ...person };
    const modalComponent = (
      <PersonAllocationsModal
        isVisible={true}
        person={personForUpdate}
        workspace={workspace}
        groupTypes={groupTypes}
        allocationProject={allocationProject}
        onHideModal={onHideModal}
        currentGroup={team}
        forceUpdatePerson
      />
    );
    dispatch({ type: "setModalComponent", modalComponent });
  };

  const options = {
    ...timelineOptions,
    groupTemplate: (group) => {
      if (!group) {
        return "";
      }

      if (!group.person) {
        return group.content;
      }

      const el = document.createElement("div");
      el.id = getPersonContainerId(group.person);

      return el;
    },
  };

  const hasMembers = !isEmpty(items);
  const isLoading = !data || !teamId || (hasMembers && !isGroupHeadersRendered);

  return (
    <TabWrapper
      borderRadius={2}
      boxBorder="default"
      shadow="default"
      p="r"
      bg="white"
      position="relative"
    >
      {isLoading && <FullPageLoading />}
      {!hasMembers && <P>Timeline unavailable for team with no members</P>}
      {hasMembers && (
        <Suspense>
          <Timeline
            ref={timelineRef}
            options={options}
            initialItems={items}
            initialGroups={groups}
            onTimelineRendered={onTimelineRendered}
          />
        </Suspense>
      )}
      <TimelineGroupHeaders
        isTimelineRendered={isTimelineRendered}
        groups={groups}
        team={team}
        groupTypes={groupTypes}
        onEditingAllocations={onEditingAllocations}
        setIsGroupHeadersRendered={setIsGroupHeadersRendered}
        workspace={workspace}
      />
    </TabWrapper>
  );
};

MembershipsTab.propTypes = {
  team: GroupPropType.isRequired,
};

export default MembershipsTab;
