import formatStringWithData from "./renderStringTemplate";

export const formatLabel = (label, group, groupTypesLookup, data = {}) => {
  const groupTypeConfig = groupTypesLookup[group.type] || {};
  const typeName = groupTypeConfig.name || group.type;

  if (label) {
    return formatStringWithData(label, {
      ...data,
      groupType: typeName,
    });
  }

  return "";
};

export const getAction = (isExpanded) => {
  return isExpanded ? "hide" : "show";
};
