import React from "react";
import PropTypes from "prop-types";
import { Flex, Small } from "orcs-design-system";

const formatOptionLabel = ({ label, type, value } /* { selectValue } */) => {
  // Use select value to customize the label
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      key={value}
      flexWrap="wrap"
    >
      <Small mr="s">{label}</Small>
      <Small ml="auto" color="greyDark">
        {type}
      </Small>
    </Flex>
  );
};

formatOptionLabel.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default formatOptionLabel;
