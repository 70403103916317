import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { map, filter, flatMap, includes, get, concat } from "lodash";
import { Divider } from "orcs-design-system";

import ObjectiveItem from "./ObjectiveItem";

const ObjectiveItems = ({
  grouping,
  objectives,
  dispatch,
  removeObjective,
  isUpdating,
  linkObjectiveReadOnly,
  inlineStyle,
}) => {
  const parentIds = flatMap(objectives, "parentIds");

  const filteredObjectives = filter(
    objectives,
    (objective) => !includes(parentIds, objective.id)
  );
  const group = get(grouping, "group", null);
  const allObjectiveIds = concat(
    group?.objectiveIds || [],
    map(group?.linkedStrategies, "id")
  );
  return (
    <>
      {map(filteredObjectives, (objective, index) => (
        <Fragment key={objective.id}>
          <ObjectiveItem
            objective={objective}
            dispatch={dispatch}
            readOnly={
              linkObjectiveReadOnly.readOnly ||
              includes(group?.objectiveIds, objective.id) ||
              !includes(allObjectiveIds, objective.id)
            }
            removeObjective={removeObjective}
            isUpdating={isUpdating}
            inlineStyle={inlineStyle}
            objectives={objectives}
          />
          {index !== objectives.length - 1 && <Divider light />}
        </Fragment>
      ))}
    </>
  );
};

ObjectiveItems.propTypes = {
  grouping: PropTypes.object.isRequired,
  objectives: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  removeObjective: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  inlineStyle: PropTypes.bool,
  linkObjectiveReadOnly: PropTypes.object,
};

export default ObjectiveItems;
