import React, { useCallback, useMemo, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { createTeamInAllocation as createTeamMutation } from "src/allocation/team.graphql";

import AddNewTeamModal from "src/allocation/containers/AddNewTeamModal";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";
import {
  useDispatch,
  useUserInteraction,
  useLookupData,
  ACTIONS,
} from "../../context/ForecastContext";

const AddNewTeamForecastModal = () => {
  const dispatch = useDispatch();
  const { addingTeam } = useUserInteraction();
  const { addTeamBtnLabel } = useLookupData();

  const [createTeam, { data }] = useMutation(createTeamMutation);

  const { isOpen, parentGroup } = addingTeam;

  useEffect(() => {
    if (!data || !data.team) {
      return;
    }

    dispatch({
      type: ACTIONS.USER_ADD_NEW_TEAM,
      team: data.team,
    });
  }, [data, dispatch]);

  const handleHideModal = useCallback(() => {
    dispatch({
      type: ACTIONS.CLOSE_ADD_NEW_TEAM_DIALOG,
    });
    dispatch({
      type: ACTIONS.MAIN_QUERY_REFRESH,
      backgroundRefresh: true,
    });
  }, [dispatch]);

  const handleCreateNewTeam = useCallback(
    async (newTeamVariables) => {
      await createTeam({
        variables: newTeamVariables,
        update: (proxy, result) => {
          // Notify new group added
          eventEmitter.emit(EVENTS.NEW_GROUP_ADDED, result.data.team);
        },
      });
    },
    [createTeam]
  );

  return useMemo(() => {
    if (!parentGroup) {
      return null;
    }

    return (
      <AddNewTeamModal
        visible={isOpen}
        parentGroup={parentGroup}
        onHideModal={handleHideModal}
        onCreateTeam={handleCreateNewTeam}
        defaultModalTitle={addTeamBtnLabel}
      />
    );
  }, [
    parentGroup,
    isOpen,
    handleHideModal,
    handleCreateNewTeam,
    addTeamBtnLabel,
  ]);
};

export default AddNewTeamForecastModal;
