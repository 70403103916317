import React from "react";

import useExecMainQuery from "./useExecMainQuery";
import useExecSecondaryQuery from "./useExecSecondaryQuery";
import usePersistUserInteraction from "./usePersistUserInteraction";
import useRefreshPage from "./useRefreshPage";

import { withMainContextProvider } from "./context";
import withDndProvider from "./withDndProvider";
import withCommentContextProvider from "./withCommentContextProvider";

import IndividualAllocationModal from "./components/IndividualAllocationModal";
import Page from "./components/TeamBuilderApproverPage";

const TeamBuilderApproverPage = () => {
  useExecMainQuery();
  useExecSecondaryQuery();
  usePersistUserInteraction();
  useRefreshPage();

  return (
    <>
      <IndividualAllocationModal />
      <Page />
    </>
  );
};

export default withMainContextProvider(
  withCommentContextProvider(withDndProvider(TeamBuilderApproverPage))
);
