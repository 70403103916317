import { initial } from "lodash";
import { Flex, Icon, Popover, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { getGroupNamesArrayFromHierarchy } from "src/allocation/util/group";
import TeamLink from "src/components/TeamLink";
import * as searchUtil from "src/util/search";
import GroupPropType from "../../custom-prop-types/group";

const HierarchyTooltip = ({
  className,
  selectedString,
  group,
  showFullHierarchyInBreadcrumb,
  bold,
  children,
}) => {
  const { hierarchy } = group;

  const hierarchyTeams = getGroupNamesArrayFromHierarchy(group.hierarchy);
  const parentGroupIds = group.parentIds || initial(group.hierarchyIds); // get all but last if using hierarchyIds

  const breadcrumbGroupIds = showFullHierarchyInBreadcrumb
    ? group.hierarchyIds
    : parentGroupIds;

  if (!hierarchy) {
    return children;
  }
  return (
    <Popover
      direction="top"
      width="min-content"
      text={
        <Flex alignItems="center" flexWrap="wrap">
          <Spacer mr="xs">
            {breadcrumbGroupIds.map((breadcrumbGroupId, index) => {
              return (
                <Fragment key={breadcrumbGroupId}>
                  <Flex alignItems="center" flexWrap="nowrap">
                    <TeamLink
                      className={className}
                      noWrap
                      small
                      bold={bold}
                      teamName={searchUtil.highlightSelectedString(
                        hierarchyTeams[index],
                        selectedString
                      )}
                      team={{ id: breadcrumbGroupId }}
                      data-testid={`breadcrumb-link-${breadcrumbGroupId}`}
                    />
                    <Icon
                      ml="xs"
                      icon={["fas", "chevron-right"]}
                      color="grey"
                      size="xs"
                    />
                  </Flex>
                </Fragment>
              );
            })}
          </Spacer>
        </Flex>
      }
    >
      {children}
    </Popover>
  );
};

HierarchyTooltip.propTypes = {
  group: GroupPropType.isRequired,
  className: PropTypes.string,
  bold: PropTypes.bool,
  showFullHierarchyInBreadcrumb: PropTypes.bool,
  children: PropTypes.element,
  selectedString: PropTypes.string,
};

export default HierarchyTooltip;
