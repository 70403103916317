import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { MarkBtns, BlockBtns, LinkBtn } from "../RichTextEditor.config";
import * as rteUtils from "../RichTextEditor.utils";

import RteButton from "./RteButton";
import RteToolBar from "./RteToolbar";

const EditorToolbar = ({ disabled }) => {
  return (
    <RteToolBar>
      {map(MarkBtns, ([format, icon]) => (
        <RteButton
          key={format}
          format={format}
          icon={icon}
          isActive={rteUtils.isMarkActive}
          formatText={rteUtils.toggleMark}
          disabled={disabled}
        />
      ))}
      {map(BlockBtns, ([format, icon]) => (
        <RteButton
          key={format}
          format={format}
          icon={icon}
          isActive={rteUtils.isBlockActive}
          formatText={rteUtils.toggleBlock}
          disabled={disabled}
        />
      ))}
      <RteButton
        key={LinkBtn[0]}
        format={LinkBtn[0]}
        icon={LinkBtn[1]}
        isActive={rteUtils.isLinkActive}
        formatText={rteUtils.onLinkClick}
        disabled={disabled}
      />
    </RteToolBar>
  );
};

EditorToolbar.propTypes = {
  disabled: PropTypes.bool,
};

export default EditorToolbar;
