import React from "react";
import PropTypes from "prop-types";
import { P, FlexItem } from "orcs-design-system";
import { fullDisplayName } from "src/util/personName";

import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import useTagsVisibleInFilter from "src/app/Restricted/useTagsVisibleInFilter";

import TagsEditorContainer from "src/components/TagsEditor/TagsEditorContainer";
import useHandleEntityTagClick from "src/hooks/useHandleEntityTagClick";

const MemberTags = ({
  person,
  showMemberTags = false,
  readOnly = false,
  comments,
  enableTagsAppliedCount,
}) => {
  const workspaceFeatureFlags = useWorkspaceFeatureFlags();
  const tagsVisibleIn = useTagsVisibleInFilter();
  const onEntityTagClick = useHandleEntityTagClick();
  if (!showMemberTags) {
    return null;
  }

  const fallback = (
    <P weight="light" fontSize="14px">
      <i>No tags currently recorded</i>
    </P>
  );

  const tagsView = (
    <TagsEditorContainer
      entityType="person"
      entityName={fullDisplayName(person)}
      entityId={person.aggregateId}
      manageEntityTags={false}
      presetEntityTags={person.tags || []}
      readOnly={readOnly}
      fallback={fallback}
      comments={comments}
      withAppliedCount={enableTagsAppliedCount}
      onEntityTagClick={onEntityTagClick}
      mt="0"
      tagsVisibleIn={tagsVisibleIn}
      featureFlags={workspaceFeatureFlags}
    />
  );

  return (
    <FlexItem width="100%" maxWidth="500px">
      {tagsView}
    </FlexItem>
  );
};

MemberTags.propTypes = {
  person: PropTypes.object.isRequired,
  showMemberTags: PropTypes.bool,
  readOnly: PropTypes.bool,
  comments: PropTypes.object,
  enableTagsAppliedCount: PropTypes.bool,
};

export default MemberTags;
