import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "orcs-design-system";

import {
  PageRefreshContext,
  ACTIONS,
} from "src/contexts/pageRefresh/PageRefreshContext";

const RefreshButton = ({ displayOnly, hiddenWhenNotRefreshing }) => {
  const [{ isRefreshing, refreshFn, showRefreshButton }, dispatch] =
    useContext(PageRefreshContext);
  const canRefresh = refreshFn && !isRefreshing && !displayOnly;
  const handleRefesh = useCallback(() => {
    if (canRefresh) {
      dispatch({
        type: ACTIONS.SET_IS_REFRESHING,
        isRefreshing: true,
      });
      refreshFn();
    }
  }, [canRefresh, refreshFn, dispatch]);

  const variant = canRefresh ? null : "disabled";

  if (!showRefreshButton || (hiddenWhenNotRefreshing && !isRefreshing)) {
    return null;
  }
  return (
    <Button
      variant={variant}
      disabled={!canRefresh}
      isLoading={isRefreshing}
      onClick={handleRefesh}
    >
      {!isRefreshing && <Icon size="sm" icon={["far", "sync-alt"]} />}
    </Button>
  );
};

RefreshButton.propTypes = {
  displayOnly: PropTypes.bool,
  hiddenWhenNotRefreshing: PropTypes.bool,
};

export default RefreshButton;
