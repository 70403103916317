import { useQuery } from "@apollo/client";
import { isNumber } from "lodash";

import { getPersonAddressImage } from "src/queries/person.graphql";

import {
  PERSON_ADDRESS_MAP_HEIGHT,
  PERSON_ADDRESS_MAP_ZOOM,
  PERSON_ADDRESS_MAP_WIDTH,
} from "src/util/DetailsHeaderUtils";

const usePersonAddressMapImage = (
  id,
  height = PERSON_ADDRESS_MAP_HEIGHT,
  width = isNumber(window?.innerWidth)
    ? window.innerWidth - 89
    : PERSON_ADDRESS_MAP_WIDTH,
  zoom = PERSON_ADDRESS_MAP_ZOOM
) => {
  return useQuery(getPersonAddressImage, {
    skip: !isNumber(window?.innerWidth),
    variables: {
      id,
      width,
      height,
      zoom,
    },
  });
};

export default usePersonAddressMapImage;
