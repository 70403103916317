import PropTypes from "prop-types";
import { get, map } from "lodash";
import React, { Fragment } from "react";
import { Flex, FlexItem, Icon, P, Small } from "orcs-design-system";

import { getGroupNamesArrayFromHierarchy } from "src/allocation/util/group";

const FormatOptionLabel = ({ label, hierarchy }, { selectValue }) => {
  const groups = getGroupNamesArrayFromHierarchy(hierarchy);
  const isSelected = get(selectValue, "[0].hierarchy", false) === hierarchy;

  const hierarchyLabel = map(
    groups,
    (group, index) => {
      const isLastItem = index + 1 === groups.length;

      return (
        <Fragment key={`${group}-${index}`}>
          {group}
          {!isLastItem && (
            <Icon icon={["fal", "chevron-right"]} size="xs" mx="xs" />
          )}
        </Fragment>
      );
    },
    []
  );

  return (
    <Flex alignItems="center" key={hierarchy}>
      <Icon
        color={isSelected ? "black" : "grey"}
        icon={["fas", "users"]}
        size="xs"
        mr="s"
      />
      <FlexItem>
        <P color="black">{label}</P>
        <Small isSelected={isSelected} color={isSelected ? "black" : "grey"}>
          {hierarchyLabel}
        </Small>
      </FlexItem>
    </Flex>
  );
};

FormatOptionLabel.propTypes = {
  label: PropTypes.string,
  hierarchy: PropTypes.string,
};

export default FormatOptionLabel;
