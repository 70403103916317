import { useMutation, useQuery } from "@apollo/client";
import { get, map, sortBy } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

import {
  getIndividualAllocations,
  allocateIndividualAllocation,
  updateFteIndividualAllocation,
  unallocateIndividualAllocation,
} from "src/queries/allocation.graphql";
import { getGroupName } from "src/util/group";
import { updateIndividualAllocationsQueryCache } from "src/util/updateIndividualAllocationCache";
import ErrorNotification from "src/components/ErrorNotification";

import IndividualPersonAllocation from "./IndividualPersonAllocation";

const PersonAllocationContainer = ({
  allocationProjectId,
  personId,
  personFte,
  onPersonAllocating,
  onPersonAllocated,
  onPersonUnallocated,
  onPersonAllocationUpdated,
  layoutFunction,
  groupTypes,
  workspace,
  infoMessage,
}) => {
  const {
    error,
    data,
    loading,
    variables: queryVariables,
  } = useQuery(getIndividualAllocations, {
    fetchPolicy: "cache-and-network",
    variables: {
      personId,
      allocationProjectId,
    },
    skip: !personId || !allocationProjectId,
  });

  const updateAllocateToNewGroupCache = useCallback(
    (cache, result, mutationVariables, targetGroup) => {
      updateIndividualAllocationsQueryCache(
        cache,
        result,
        mutationVariables,
        queryVariables
      );
      if (onPersonAllocated) {
        onPersonAllocated({
          cache,
          result,
          mutationVariables,
          queryVariables,
          targetGroup,
        });
      }
    },
    [onPersonAllocated, queryVariables]
  );

  const updateUnallocateCache = useCallback(
    (cache, result, mutationVariables, targetGroup) => {
      updateIndividualAllocationsQueryCache(
        cache,
        result,
        mutationVariables,
        queryVariables
      );
      if (onPersonUnallocated) {
        onPersonUnallocated({
          cache,
          result,
          mutationVariables,
          queryVariables,
          targetGroup,
        });
      }
    },
    [onPersonUnallocated, queryVariables]
  );

  const updateFteIndividualAllocationCache = useCallback(
    (cache, result, mutationVariables, targetGroup) => {
      updateIndividualAllocationsQueryCache(
        cache,
        result,
        mutationVariables,
        queryVariables
      );
      if (onPersonAllocationUpdated) {
        onPersonAllocationUpdated({
          cache,
          result,
          mutationVariables,
          queryVariables,
          targetGroup,
        });
      }
    },
    [onPersonAllocationUpdated, queryVariables]
  );

  const [allocateToNewGroupMutation, { error: allocateToGroupMutationError }] =
    useMutation(allocateIndividualAllocation);

  const [
    unallocateFromGroupMutation,
    { error: allocateFromGroupMutationError },
  ] = useMutation(unallocateIndividualAllocation);

  const [updateAllocationMutation, { error: updateAllocationMutationError }] =
    useMutation(updateFteIndividualAllocation);

  const onAllocateToNewGroup = useCallback(
    async ({ variables }, targetGroup) => {
      if (onPersonAllocating) {
        await onPersonAllocating(true);
      }
      try {
        await allocateToNewGroupMutation({
          variables,
          update: (cache, result) => {
            updateAllocateToNewGroupCache(
              cache,
              result,
              variables,
              targetGroup
            );
          },
        });
      } finally {
        // no catch, errors will be handled in state above
        if (onPersonAllocating) {
          await onPersonAllocating(false);
        }
      }
    },
    [
      onPersonAllocating,
      allocateToNewGroupMutation,
      updateAllocateToNewGroupCache,
    ]
  );

  const onUnallocateFromGroup = useCallback(
    async ({ variables }, targetGroup) => {
      if (onPersonAllocating) {
        await onPersonAllocating(true);
      }
      try {
        await unallocateFromGroupMutation({
          variables,
          update: (cache, result) => {
            updateUnallocateCache(cache, result, variables, targetGroup);
          },
        });
      } finally {
        // no catch, errors will be handled in state above
        if (onPersonAllocating) {
          await onPersonAllocating(false);
        }
      }
    },
    [onPersonAllocating, unallocateFromGroupMutation, updateUnallocateCache]
  );

  const onUpdateAllocation = useCallback(
    async ({ variables }, targetGroup) => {
      if (onPersonAllocating) {
        await onPersonAllocating(true);
      }
      try {
        await updateAllocationMutation({
          variables,
          update: (cache, result) => {
            updateFteIndividualAllocationCache(
              cache,
              result,
              variables,
              targetGroup
            );
          },
        });
      } finally {
        // no catch, errors will be handled in state above
        if (onPersonAllocating) {
          await onPersonAllocating(false);
        }
      }
    },
    [
      onPersonAllocating,
      updateAllocationMutation,
      updateFteIndividualAllocationCache,
    ]
  );

  const anyError =
    error || allocateFromGroupMutationError || updateAllocationMutationError;

  if (anyError) {
    return <ErrorNotification error={anyError} />;
  }

  const allocations = get(data, "individualAllocations", []);

  const sortedAllocations = sortBy(
    map(allocations, (allocation) => ({
      ...allocation,
      name: getGroupName(allocation.targetGroupHierarchy),
    })),
    "name"
  );

  return (
    <IndividualPersonAllocation
      allocationProjectId={allocationProjectId}
      isLoading={loading}
      onAllocateToNewGroup={onAllocateToNewGroup}
      onUpdateAllocation={onUpdateAllocation}
      onUnallocateFromGroup={onUnallocateFromGroup}
      allocations={sortedAllocations}
      personFte={personFte}
      personId={personId}
      layoutFunction={layoutFunction}
      groupTypes={groupTypes}
      workspace={workspace}
      infoMessage={infoMessage}
      allocateToGroupMutationError={allocateToGroupMutationError}
    />
  );
};

PersonAllocationContainer.propTypes = {
  allocationProjectId: PropTypes.string,
  personId: PropTypes.string,
  personFte: PropTypes.number,
  onPersonAllocating: PropTypes.func,
  onPersonAllocated: PropTypes.func,
  onPersonUnallocated: PropTypes.func,
  onPersonAllocationUpdated: PropTypes.func,
  layoutFunction: PropTypes.func,
  groupTypes: PropTypes.object,
  workspace: PropTypes.object,
  infoMessage: PropTypes.string,
};

export default PersonAllocationContainer;
