import { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { isEmpty, reduce } from "lodash";

import { TAGS } from "src/consts/comments";
import { approverPlannerSecondQuery } from "src/allocation/allocation.graphql";

import {
  usePageMode,
  useMainQuery,
  useViewMode,
  useDispatch,
  ACTIONS,
  useActiveAllocationProject,
} from "./context/ForecastContext";

import { PLANNER } from "./context/reducer/pageModes";
import { QUICK_ACTIVITY_VIEW } from "./context/reducer/viewModes";

const usePageSecondQuery = () => {
  const client = useApolloClient();

  const pageMode = usePageMode();
  const viewMode = useViewMode();
  const { loading, data } = useMainQuery();
  const activeAllocationProject = useActiveAllocationProject();
  const dispatch = useDispatch();

  useEffect(() => {
    const skip =
      pageMode !== PLANNER || viewMode !== QUICK_ACTIVITY_VIEW || loading;

    if (skip || !data || isEmpty(data.macroAllocations)) {
      return;
    }

    // In fact we can only load the macro allocations that needs confirmation
    const commentIds = reduce(
      data.macroAllocations,
      (prev, m) => {
        const { id, requestSubmittedAt } = m;

        if (!requestSubmittedAt) {
          return prev;
        }

        prev.push(TAGS.MACRO_ALLOCATION_ID(id));
        return prev;
      },
      []
    );

    if (isEmpty(commentIds)) {
      return;
    }

    client
      .query({
        query: approverPlannerSecondQuery,
        variables: {
          commentFilter: [
            [TAGS.SYSTEM_COMMENT],
            [TAGS.ALLOCATION.COMMENT],
            [TAGS.ALLOCATION.ACTION.REQUEST_SUBMITTED],
            commentIds,
          ],
          pageSize: commentIds.length,
        },
        fetchPolicy: "network-only",
      })
      .then((queryData) => {
        dispatch({
          type: ACTIONS.SECOND_QUERY_COMPLETE,
          data: queryData?.data?.result?.comments,
          activeAllocationProject,
          viewMode,
          pageMode,
          shouldHash: true,
        });
      })
      .catch(() => {
        // Do nothing here. This is not super important information
      });
  }, [
    data,
    pageMode,
    viewMode,
    loading,
    activeAllocationProject,
    client,
    dispatch,
  ]);
};

export default usePageSecondQuery;
