import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useLocation } from "react-router-dom";

import GroupPropType from "src/custom-prop-types/group";

export const RequestAllocationContext = React.createContext();

export const initialState = {
  requestorGroup: null, // the original requestor group, to track the parent node
  selectedTargetGroup: null, // the active selected group
  selectedRoleGroupId: null,
  showAddNewTeamModal: false,
  showHideTeamModal: false,
  showAddNewRoleModal: false,
  newRoles: [],
  hasMembers: false,
  isShowSummary: true,
  isParent: true,
  isRoot: true,
  isTreeRefreshed: false,
  isDisplayHiddenTeams: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "selectTargetGroup": {
      const { selectedTargetGroup, isParent, isRoot } = action;
      return {
        ...state,
        selectedTargetGroup,
        isParent,
        isRoot,
        newRoles: [],
      };
    }
    case "showNewTeamModal": {
      const { isShowModal } = action;
      return { ...state, showAddNewTeamModal: isShowModal };
    }
    case "showHideTeamModal": {
      const { isModalShown } = action;
      return {
        ...state,
        showHideTeamModal: isModalShown,
      };
    }
    case "showNewRoleModal": {
      const { isShowModal } = action;
      return { ...state, showAddNewRoleModal: isShowModal };
    }
    case "showSummary":
      return {
        ...state,
        isShowSummary: true,
      };
    case "showAllocations":
      return {
        ...state,
        isShowSummary: false,
      };
    case "addNewRole": {
      return {
        ...state,
        newRoles: [
          ...state.newRoles,
          {
            groupId: action.newRole.id,
            group: action.newRole,
            memberCount: 0,
            memberFte: 0,
            notesCount: 0,
          },
        ],
      };
    }
    case "setHasMembers": {
      const { hasMembers } = action;
      return {
        ...state,
        hasMembers,
      };
    }
    case "refreshTree": {
      // just toggle false true to trigger useEffect re-render
      return {
        ...state,
        isTreeRefreshed: !state.isTreeRefreshed,
      };
    }

    case "toggleDislayHiddenTeams": {
      return {
        ...state,
        isDisplayHiddenTeams: !state.isDisplayHiddenTeams,
      };
    }

    default:
      throw new Error();
  }
};

export const RequestAllocationProvider = ({
  children,
  isParent = true,
  isShowSummary = true,
  requestorGroup,
  selectedTargetGroup,
}) => {
  const { state: locationState } = useLocation();
  const selectedTargetGroupOverride =
    selectedTargetGroup || get(locationState, "activeNode", requestorGroup);

  return (
    <RequestAllocationContext.Provider
      value={React.useReducer(reducer, {
        ...initialState,
        isParent,
        isShowSummary,
        requestorGroup,
        selectedTargetGroup: selectedTargetGroupOverride,
      })}
    >
      {children}
    </RequestAllocationContext.Provider>
  );
};

RequestAllocationProvider.propTypes = {
  children: PropTypes.node,
  isParent: PropTypes.bool,
  isShowSummary: PropTypes.bool,
  requestorGroup: GroupPropType,
  selectedTargetGroup: GroupPropType,
};
