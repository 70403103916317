import {
  filter,
  trim,
  overEvery,
  includes,
  sortBy,
  map,
  values,
  reduce,
  sumBy,
  mapValues,
  keyBy,
  forEach,
} from "lodash";

import {
  OVER_ALLOCATED,
  UNDER_ALLOCATED,
  NEWLY_ADDED,
  REMOVED,
  MemberSearchKeys,
} from "src/allocation/consts";

import { fullDisplayName } from "src/util/personName";
import { roundFte } from "src/util/roundingStrategy";
import { search, searchOptions } from "../search";

import { getGroupName, isWithinHierarchy } from "../group";

const memberSearchOptions = {
  ...searchOptions,
  keys: MemberSearchKeys,
};

export const filterMembers = (subGroupId, selectedFilter, members) => {
  const filters = [];

  // remove any members that are newly added & removed (undo of an allocation)
  filters.push((member) => !(member.isNewlyAdded && member.isRemoved));

  if (subGroupId) {
    filters.push((member) => includes(member.directTeamIds, subGroupId));
  }

  if (selectedFilter === OVER_ALLOCATED) {
    filters.push((member) => member.remainingFte < 0);
  }

  if (selectedFilter === UNDER_ALLOCATED) {
    filters.push((member) => member.remainingFte > 0);
  }

  if (selectedFilter === NEWLY_ADDED) {
    filters.push((member) => member.isNewlyAdded);
  }

  if (selectedFilter === REMOVED) {
    filters.push((member) => member.isRemoved);
  } else {
    // by default don't show any removed members
    filters.push((member) => !member.isRemoved);
  }

  return filter(members, overEvery(filters));
};

const getFteAllocatedIntoThisGroup = (group, allocations) => {
  const allocationsForGroup = filter(allocations, ({ targetGroup }) =>
    isWithinHierarchy(group.hierarchyIds, targetGroup.hierarchyIds)
  );
  return sumBy(allocationsForGroup, "fte");
};

const getTotalAssignedFte = (allocations) =>
  reduce(allocations, (result, { fte }) => result + fte, 0);

export const getAugmentedMembers = (group, membersWithMergedAllocations) =>
  map(
    values(membersWithMergedAllocations),
    ({ person, isNewlyAdded, isRemoved, mergedAllocations }) => {
      const fullName = fullDisplayName(person);
      const teams = map(
        mergedAllocations,
        ({ targetGroup }) => getGroupName(targetGroup.hierarchy),
        []
      );
      const remainingFte = roundFte(
        person.fte - getTotalAssignedFte(mergedAllocations)
      );

      const fteAllocatedIntoThisGroup = roundFte(
        getFteAllocatedIntoThisGroup(group, mergedAllocations)
      );
      return {
        ...person,
        fullName,
        teams,
        remainingFte,
        fteAllocatedIntoThisGroup,
        isNewlyAdded,
        isRemoved,
        mergedAllocations,
      };
    }
  );

export const getAllFilteredMembers = (
  group,
  selectedSubSourceGroupId,
  membersWithMergedAllocations,
  searchTerm,
  selectedFilter
) => {
  const augmentedMembers = getAugmentedMembers(
    group,
    membersWithMergedAllocations
  );
  return filterMembers(
    selectedSubSourceGroupId,
    selectedFilter,
    augmentedMembers
  );
};

export const searchAndSortMembers = (members, option) => {
  let searchedMembers = members;
  if (option) {
    let optionsForSearch = memberSearchOptions;

    if (option.key) {
      optionsForSearch = { ...memberSearchOptions, keys: [option.key] };
    }

    const trimmedSearchTerm = trim(option.value);
    if (trimmedSearchTerm) {
      const results = search(members, trimmedSearchTerm, optionsForSearch);
      searchedMembers = map(results, "item");
    }
  }

  return sortBy(searchedMembers, ["fullName"]);
};

export const addTagsToMembers = (members, tags) => {
  const memberTagsMap = mapValues(keyBy(tags, "id"), "tags");

  // TODO: Need to find a good way to pass in tags information...
  // Currently, it's already quite slow to load allocation members
  // So I was tyring to lazy load the tags and then add tags into the member object
  forEach(members, (m) => {
    // eslint-disable-next-line no-param-reassign
    m.tags = memberTagsMap[m.aggregateId];
  });
};

export const searchAndFilterAndSortMembersWithoutSearchTerm = (
  group,
  selectedSubSourceGroupId,
  membersWithMergedAllocations,
  selectedFilter
) => {
  const filteredAugmentedMembers = getAllFilteredMembers(
    group,
    selectedSubSourceGroupId,
    membersWithMergedAllocations,
    "",
    selectedFilter
  );

  return sortBy(filteredAugmentedMembers, ["fullName"]);
};

export const searchAndFilterAndSortMembers = (
  group,
  selectedSubSourceGroupId,
  membersWithMergedAllocations,
  searchTerm,
  selectedFilter
) => {
  const filteredAugmentedMembers = getAllFilteredMembers(
    group,
    selectedSubSourceGroupId,
    membersWithMergedAllocations,
    searchTerm,
    selectedFilter
  );

  let searchedMembers = filteredAugmentedMembers;

  const trimmedSearchTerm = trim(searchTerm);
  if (trimmedSearchTerm) {
    const results = search(
      filteredAugmentedMembers,
      trimmedSearchTerm,
      memberSearchOptions
    );

    searchedMembers = map(results, "item");
  }

  return sortBy(searchedMembers, ["fullName"]);
};
