import { useCallback, useState, useMemo } from "react";

import { debounce, without, split, get, remove } from "lodash";
import { useApolloClient } from "@apollo/client";

import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";
import {
  listGroups,
  peopleSearch,
  getGroup,
  getPeopleByIds,
} from "./internal.graphql";
import { createGroupsOptions, createPersonOptions } from "./utils";

const GroupIdLength = 8;
const NoSpaceRegex = /^\S+$/;

export const useSearchPerson = ({ fetchPolicy = "no-cache" } = {}) => {
  const client = useApolloClient();
  const [isSearching, setIsSearching] = useState(false);

  const getPersonQueryVars = useCallback(
    (keywordArray = "") => ({
      searchTerms: without(split(keywordArray, " "), "") || [keywordArray],
      size: 10,
    }),
    []
  );

  const loadPersonOptions = useMemo(
    () =>
      debounce((input, callback) => {
        setIsSearching(true);
        client
          .query({
            query: peopleSearch,
            variables: getPersonQueryVars(input),
            fetchPolicy,
          })
          .then(({ data: newData, error: newError }) => {
            if (newError) {
              return;
            }

            callback(createPersonOptions(get(newData, "result.people", [])));
            setIsSearching(false);
          });
      }, DEBOUNCE_SEARCH_TIME),
    [client, getPersonQueryVars, fetchPolicy]
  );

  return {
    loadPersonOptions,
    isSearching,
    client,
  };
};

export const useSearchGroups = ({
  groupTypes,
  existingGroups = null,
  includeAllGroups = false,
  includeMembers = true,
  includeIndirect = false,
  fetchPolicy = "no-cache",
} = {}) => {
  const client = useApolloClient();
  const [isSearching, setIsSearching] = useState(false);

  const getQueryVars = useCallback(
    (keywordArray = "") => {
      const isLikeGroupId =
        !!keywordArray &&
        keywordArray.length === GroupIdLength &&
        NoSpaceRegex.test(keywordArray);

      return {
        search: without(split(keywordArray, " "), "") || [keywordArray],
        typeFilters: groupTypes,
        limit: 20,
        groupId: keywordArray,
        isLikeGroupId,
        includeMembers,
        includeIndirect,
      };
    },
    [groupTypes, includeMembers, includeIndirect]
  );

  const loadOptions = useMemo(
    () =>
      debounce((input, callback) => {
        setIsSearching(true);
        client
          .query({
            query: listGroups,
            variables: getQueryVars(input),
            fetchPolicy,
          })
          .then(({ data: newData, error: newError }) => {
            if (newError) {
              return;
            }

            const groups = get(newData, "result.groups", []);
            const groupWithExactId = get(newData, "group");

            if (groupWithExactId) {
              // remove the group if it already exists in the results
              remove(groups, (g) => g.id === groupWithExactId.id);
              // add group as 1st result
              groups.unshift(groupWithExactId);
            }

            callback(
              newData
                ? createGroupsOptions(groups, existingGroups, includeAllGroups)
                : []
            );
            setIsSearching(false);
          });
      }, DEBOUNCE_SEARCH_TIME),
    [client, getQueryVars, fetchPolicy, existingGroups, includeAllGroups]
  );

  return {
    loadOptions,
    isSearching,
    client,
  };
};

export const useLoadGroup = () => {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const loadGroup = useCallback(
    async (groupId) => {
      setIsLoading(true);
      const {
        data: { group },
      } = await client.query({
        query: getGroup,
        variables: {
          groupId,
        },
      });

      setIsLoading(false);
      return group;
    },
    [client]
  );

  return {
    loadGroup,
    isLoading,
    client,
  };
};

export const useLoadPeople = () => {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const loadPeople = useCallback(
    async (ids) => {
      setIsLoading(true);
      const {
        data: { people },
      } = await client.query({
        query: getPeopleByIds,
        variables: {
          ids,
        },
      });

      setIsLoading(false);
      return people;
    },
    [client]
  );

  return {
    loadPeople,
    isLoading,
    client,
  };
};
