import { get } from "lodash";
import { Small, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import icon from "src/config/icons";

import { copywriting } from "../Unsplash.config";

import { TextInputHiddenLabel } from "./SearchInput.styled";

const SearchInput = ({ onSearch, searchError }) => {
  const onHandleInputChange = (event) => {
    const value = get(event, "target.value");
    onSearch(value);
  };

  return (
    <>
      <TextInputHiddenLabel
        fullWidth
        label={copywriting.ATTRIBUTION_LABEL}
        id="cp-unsplash-search"
        data-testid="cp-unsplash-search"
        onChange={onHandleInputChange}
        type="text"
        placeholder={copywriting.SEARCH_PLACEHOLDER}
      />
      {searchError && (
        <Small color="danger" fontWeight="bold" display="block" mt="s">
          <Icon icon={icon.exclamation} mr="xs" />
          {copywriting.SEARCH_ERROR}
        </Small>
      )}
    </>
  );
};

SearchInput.propTypes = {
  onSearch: PropTypes.func,
  searchError: PropTypes.bool,
};

export default SearchInput;
