import PropTypes from "prop-types";
import React from "react";

import { StyledToolbar } from "src/components/RichTextEditor/RichTextEditor.styled";

const Toolbar = ({ className, ...props }, ref) => (
  <StyledToolbar {...props} ref={ref} />
);

Toolbar.displayName = `RteToolbar`;
const RteToolbar = React.forwardRef(Toolbar);

Toolbar.propTypes = {
  className: PropTypes.string,
  active: PropTypes.string,
  reversed: PropTypes.string,
};

export default RteToolbar;
