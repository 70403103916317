import ReactFlow, { Handle } from "reactflow";
import styled, { css } from "styled-components";
import themeGet from "@styled-system/theme-get";
import { Badge, Card } from "orcs-design-system";
import { NODE_TYPES } from "src/util/objectives";
import PlanViewLogo from "src/assets/brand-icons/PlanViewLogo.svg";
import JiraLogo from "src/assets/brand-icons/JiraLogo.svg";
import { OBJECTIVE_SOURCE, OBJECTIVE_THEME } from "src/consts/objectives";

export const StyledReactFlow = styled(ReactFlow)`
  .react-flow__pane.dragging {
    .node-card {
      cursor: grab;
    }
  }
`;

export const Theme = {
  [OBJECTIVE_SOURCE.StrategyFromFile]: {
    color: OBJECTIVE_THEME[OBJECTIVE_SOURCE.StrategyFromFile].variant,
    light: OBJECTIVE_THEME[OBJECTIVE_SOURCE.StrategyFromFile].variantLight,
    alt: "white",
  },

  [OBJECTIVE_SOURCE.Jira]: {
    color: OBJECTIVE_THEME[OBJECTIVE_SOURCE.Jira].variant,
    light: OBJECTIVE_THEME[OBJECTIVE_SOURCE.Jira].variantLight,
    alt: "white",
    Icon: JiraLogo,
    targetColor: "grey",
  },

  [OBJECTIVE_SOURCE.Planview]: {
    color: OBJECTIVE_THEME[OBJECTIVE_SOURCE.Planview].variant,
    light: OBJECTIVE_THEME[OBJECTIVE_SOURCE.Planview].variantLight,
    alt: "white",
    Icon: PlanViewLogo,
    targetColor: "grey",
  },

  [OBJECTIVE_SOURCE.Clarity]: {
    color: OBJECTIVE_THEME[OBJECTIVE_SOURCE.Clarity].variant,
    light: OBJECTIVE_THEME[OBJECTIVE_SOURCE.Clarity].variantLight,
    alt: "white",
    targetColor: "grey",
  },

  [NODE_TYPES.COMPANY]: {
    color: "primary",
    light: "primary70",
    alt: "white",
  },

  [NODE_TYPES.GROUP]: {
    color: "success",
    light: "success70",
    alt: "white",
  },

  [NODE_TYPES.TEAM]: {
    color: "grey",
    light: "grey",
    alt: "white",
  },

  [NODE_TYPES.NO_OBJECTIVE]: {
    color: "grey",
    light: "grey",
    alt: "white",
    targetColor: "grey",
  },
  [NODE_TYPES.LOADING]: {
    color: "grey",
    light: "grey",
    alt: "white",
    targetColor: "grey",
  },
};

export const StyledCard = styled(Card)(
  ({ $theme, $visible, loading, $fadedNode, $active }) => css`
    position: relative;

    opacity: ${$visible ? ($fadedNode ? 0.5 : 1) : 0};

    ${loading && "opacity: 0.7;"}
    width: 460px;

    cursor: pointer;
    border: solid 2px
      ${themeGet(`colors.${$active ? $theme?.light : $theme?.alt}`)};
  `
);

export const StyledBadge = styled(Badge)`
  display: flex;
  min-width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
`;

const getUnloadedHandleStyles = ({ $theme }) => {
  return css`
    display: flex;
    width: 20px;
    height: 20px;
    color: ${themeGet(`colors.${$theme?.alt}`)};
    border: solid 1px ${themeGet(`colors.${$theme?.light}`)};
    background-color: white;
    &:after {
      display: flex;
      justify-content: center;
      align-items: ${"center"};
      width: 100%;
      height: 100%;
      border-radius: 50%;
      font-weight: bold;
      line-height: 0.9;
      content: "+";
      font-size: ${themeGet("fontSizes.4")};
      background-color: ${themeGet(`colors.${$theme?.light}`)};
    }
  `;
};

const getLoadedHandleStyle = ({ position }) => {
  return css`
    border: 0;
    border-radius: 0;
    height: 6px;
    width: 3px;
    border-top-left-radius: 6px;
    border-top-right-radius: ${position === "left" ? "none" : "6px"};
    border-bottom-left-radius: ${position === "left" ? "6px" : "none"};
    background: ${themeGet("colors.grey")};
  `;
};

export const TargetHandle = styled(Handle)(
  ({ position, hasParentsToLoad, $theme }) => {
    if (hasParentsToLoad) {
      return css`
        &.react-flow__handle {
          ${getUnloadedHandleStyles({ $theme })}
          top: ${position === "left" ? "none" : "-6px"};
          left: ${position === "left" ? "-10px" : "232px"};
        }
      `;
    }

    return css`
      &.react-flow__handle {
        top: ${position === "left" ? "none" : "-6px"};
        left: ${position === "left" ? "-5px" : "232px"};
        ${getLoadedHandleStyle({ position })}
      }
    `;
  }
);

export const SourceHandle = styled(Handle)(
  ({ position, hasChildrenToLoad, $theme }) => {
    if (hasChildrenToLoad) {
      return css`
        &.react-flow__handle {
          ${getUnloadedHandleStyles({ $theme })}
          right: ${position === "right" ? "-9px" : 0};
          bottom: ${position === "bottom" ? "-9px" : 0};
        }
      `;
    }

    return css`
      &.react-flow__handle {
        right: ${position === "right" ? "-9px" : 0};
        bottom: ${position === "bottom" ? "-9px" : 0};
        ${getLoadedHandleStyle({ position })}
      }
    `;
  }
);
