import { useCallback, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import { map, filter, keyBy, get } from "lodash";
import moment from "moment";

import {
  peopleSearch as peopleSearchQuery,
  getPeopleByIds as getPeopleByIdsQuery,
} from "src/allocation/members.graphql";
import { useLookupData } from "../../../context/ForecastContext";
import { shouldLoadIndirectMembers } from "../../util/groupTypes";

import { getPeopleList } from "../../Shared/Members/utils/groupMembers.util";

const useLazyLoadMembers = () => {
  const { groupLookup, groupTypesLookup, activeAllocationProject } =
    useLookupData();

  const client = useApolloClient();

  const specifiedDate = useMemo(
    () => moment(activeAllocationProject.baselineDate),
    [activeAllocationProject]
  );

  const getMembersQueryVariables = useCallback(
    ({ sourceGroupId, targetGroupId }) => {
      const includeIndirectTeamMembers = shouldLoadIndirectMembers(
        sourceGroupId,
        groupLookup,
        groupTypesLookup
      );

      const variables = {
        sourceGroupId,
        targetGroupId,
        includeIndirectTeamMembers,
        size: 1000,
      };

      return variables;
    },
    [groupLookup, groupTypesLookup]
  );

  const loadMembers = useCallback(
    async (params) => {
      const variables = getMembersQueryVariables(params);

      // Load both baseline and latest members
      const [pitSearchResult, latestSearchResult] = await Promise.all([
        client.query({
          query: peopleSearchQuery,
          variables: {
            ...variables,
            specifiedDate,
          },
          fetchPolicy: "no-cache",
        }),
        client.query({
          query: peopleSearchQuery,
          variables,
        }),
      ]);

      const peopleList = getPeopleList(
        pitSearchResult.data,
        latestSearchResult.data,
        true
      );

      const peopleIds = filter(
        map(peopleList, "aggregateId").sort(),
        (id) => !!id
      );

      const { data: allPeopleData } = await client.query({
        query: getPeopleByIdsQuery,
        variables: {
          ids: peopleIds,
        },
        fetchPolicy: "network-only",
      });

      const peopleMap = keyBy(get(allPeopleData, "people"), "employeeId");

      const updatedPeopleList = map(peopleList, (person) => {
        const personById = peopleMap[person.aggregateId];

        if (!personById || personById.disabled) {
          return {
            ...person,
            tags: personById?.tags || [],
            disabled: true,
          };
        }

        return {
          ...person,
          tags: personById.tags,
        };
      });

      return updatedPeopleList;
    },
    [client, getMembersQueryVariables, specifiedDate]
  );

  const loadLatestMembers = useCallback(
    async (params) => {
      const variables = getMembersQueryVariables(params);

      // Load both baseline and latest members
      const latestSearchResult = await client.query({
        query: peopleSearchQuery,
        variables,
        fetchPolicy: "network-only",
      });

      return get(latestSearchResult.data, "result.people", []);
    },
    [client, getMembersQueryVariables]
  );

  const reloadMemberTags = useCallback(
    async (peopleIds) => {
      return client.query({
        query: getPeopleByIdsQuery,
        variables: {
          ids: peopleIds,
        },
        fetchPolicy: "network-only",
      });
    },
    [client]
  );

  return {
    client,
    loadMembers,
    loadLatestMembers,
    reloadMemberTags,
    getMembersQueryVariables,
  };
};

export default useLazyLoadMembers;
