import themeGet from "@styled-system/theme-get";
import { NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION } from "src/util/buildNodes";

const styles = (theme) => {
  return {
    node: {
      color: "rgba(156, 224, 248, 1)",
      border: {
        color: "rgba(38,141,177,1)",
        width: 2,
      },
      label: {
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        fontSize: 14,
        center: false,
      },
      size: 3,
    },
    sourceTeam: {
      color: `${themeGet("colors.greyLighter")({ theme })}`,
      border: {
        color: `${themeGet("colors.grey")({ theme })}`,
        width: 2,
      },
      fontIcon: {
        color: `${themeGet("colors.greyDarkest")({ theme })}`,
      },
    },
    team: {
      color: `${themeGet("colors.greyLighter")({ theme })}`,
      border: {
        color: `${themeGet("colors.grey")({ theme })}`,
        width: 2,
      },
      fontIcon: {
        color: `${themeGet("colors.greyDarkest")({ theme })}`,
      },
    },
    targetTeam: {
      color: `${themeGet("colors.greyLighter")({ theme })}`,
      border: {
        color: `${themeGet("colors.grey")({ theme })}`,
        width: 2,
      },
      fontIcon: {
        color: `${themeGet("colors.greyDarkest")({ theme })}`,
      },
    },
    bigTeam: {
      color: `${themeGet("colors.greyLighter")({ theme })}`,
      border: {
        color: `${themeGet("colors.grey")({ theme })}`,
        width: 2,
      },
      fontIcon: {
        color: `${themeGet("colors.greyDarker")({ theme })}`,
      },
      size: 4,
    },
    person: {
      color: `${themeGet("colors.successLightest")({ theme })}`,
      border: {
        color: `${themeGet("colors.success")({ theme })}`,
        width: 2,
      },
      fontIcon: {
        color: `${themeGet("colors.successDarker")({ theme })}`,
      },
    },
    vacantPerson: {
      color: "rgb(255, 184, 87)",
      border: {
        color: "rgb(255, 150, 7)",
        width: 2,
      },
      label: {
        backgroundColor: `${themeGet("colors.grey")({ theme })}`,
        fontSize: 14,
        center: false,
      },
    },
    manager: {
      color: `${themeGet("colors.successLightest")({ theme })}`,
      border: {
        color: `${themeGet("colors.success")({ theme })}`,
        width: 2,
      },
      fontIcon: {
        color: `${themeGet("colors.successDarker")({ theme })}`,
      },
    },
    location: {
      color: `${themeGet("colors.warningLightest")({ theme })}`,
      border: {
        color: `${themeGet("colors.warning")({ theme })}`,
        width: 2,
      },
      fontIcon: {
        color: `${themeGet("colors.warningDarker")({ theme })}`,
      },
    },
    role: {
      color: `${themeGet("colors.dangerLightest")({ theme })}`,
      border: {
        color: `${themeGet("colors.danger")({ theme })}`,
        width: 2,
      },
      fontIcon: {
        color: `${themeGet("colors.dangerDarker")({ theme })}`,
      },
    },
    division: {
      color: `${themeGet("colors.primaryLightest")({ theme })}`,
      border: {
        color: `${themeGet("colors.primary")({ theme })}`,
        width: 2,
      },
      fontIcon: {
        color: `${themeGet("colors.primaryDark")({ theme })}`,
      },
    },
    company: {
      color: `${themeGet("colors.primaryLighter")({ theme })}`,
      border: {
        color: `${themeGet("colors.primaryDark")({ theme })}`,
        width: 2,
      },
      fontIcon: {
        color: `${themeGet("colors.primaryDarker")({ theme })}`,
      },
    },
    diff: {
      remove: {
        color: `${themeGet("colors.dangerDark")({ theme })}`,
        border: {
          color: `${themeGet("colors.dangerDarker")({ theme })}`,
          width: 2,
        },
      },
      add: {
        color: `${themeGet("colors.successDark")({ theme })}`,
        border: {
          color: `${themeGet("colors.successDarker")({ theme })}`,
          width: 2,
        },
      },
      noChange: {
        color: `${themeGet("colors.greyLight")({ theme })}`,
        border: {
          color: `${themeGet("colors.greyDark")({ theme })}`,
          width: 2,
        },
      },
    },
    link: {
      color: `${themeGet("colors.greyLight")({ theme })}`,
      label: {
        backgroundColor: "rgba(255, 255, 255, 0.7)",
      },
    },
    selectedNode: {
      halos: [
        {
          color: "rgba(255, 204, 0, 0.5)",
          radius: 30,
          width: 20,
        },
      ],
    },
    selectedLink: {
      color: `${themeGet("colors.primary")({ theme })}`,
      label: {
        backgroundColor: "rgba(255, 255, 255, 1)",
        bold: true,
      },
    },
  };
};

const personStyle = (theme) => ({
  ...styles(theme).node,
  ...styles(theme).person,
});
const targetPersonStyle = (theme) => ({
  ...styles(theme).node,
  ...styles(theme).selectedNode,
  ...styles(theme).person,
});
const managerStyle = (theme) => ({
  ...styles(theme).node,
  ...styles(theme).manager,
});
const teamStyle = (theme) => ({ ...styles(theme).node, ...styles(theme).team });
const targetTeamStyle = (theme) => ({
  ...styles(theme).node,
  ...styles(theme).selectedNode,
  ...styles(theme).team,
});
const bigTeamStyle = (theme) => ({
  ...styles(theme).node,
  ...styles(theme).bigTeam,
});
const companyStyle = (theme) => ({
  ...styles(theme).node,
  ...styles(theme).company,
});
const roleStyle = (theme) => ({ ...styles(theme).node, ...styles(theme).role });
const locationStyle = (theme) => ({
  ...styles(theme).node,
  ...styles(theme).location,
});
const divisionStyle = (theme) => ({
  ...styles(theme).node,
  ...styles(theme).division,
});
const selectedNodeStyle = (theme) => styles(theme).selectedNode;
const linkStyle = (theme) => styles(theme).link;
const selectedLinkStyle = (theme) => ({
  ...styles(theme).link,
  ...styles(theme).selectedLink,
});
const removedStyle = (theme) => ({
  ...styles(theme).node,
  ...styles(theme).diff.remove,
});
const addedStyle = (theme) => ({
  ...styles(theme).node,
  ...styles(theme).diff.add,
});
const noChangeStyle = (theme) => ({
  ...styles(theme).node,
  ...styles(theme).diff.noChange,
});
const vacantPersonStyle = (theme) => ({
  ...styles(theme).node,
  ...styles(theme).vacantPerson,
});

const supportedNodes = (theme) => ({
  SourceTeam: {
    type: "node",
    labels: ["name"],
    icon: "fa-users",
    level: { default: 0 },
    style: {
      default: teamStyle(theme),
      target: targetTeamStyle(theme),
      remove: removedStyle(theme),
      add: addedStyle(theme),
      noChange: noChangeStyle(theme),
      bigTeam: bigTeamStyle(theme),
    },
  },
  Team: {
    type: "node",
    labels: ["name"],
    icon: "fa-users",
    level: { default: 0 },
    style: {
      default: teamStyle(theme),
      target: targetTeamStyle(theme),
      remove: removedStyle(theme),
      add: addedStyle(theme),
      noChange: noChangeStyle(theme),
      bigTeam: bigTeamStyle(theme),
    },
  },
  Person: {
    type: "node",
    labels: ["firstName", "surname", "jobTitle"],
    icon: "fa-user",
    level: { default: 2 },
    style: {
      default: personStyle(theme),
      target: targetPersonStyle(theme),
      managedBy: managerStyle(theme),
      remove: removedStyle(theme),
      add: addedStyle(theme),
      noChange: noChangeStyle(theme),
      vacant: vacantPersonStyle(theme),
    },
  },
  Company: {
    type: "node",
    labels: ["name"],
    icon: "fa-building",
    level: { default: 4 },
    style: { default: companyStyle(theme) },
  },
  Job: {
    type: "node",
    labels: ["jobKey"],
    icon: "fa-address-card",
    level: { default: 2 },
    style: { default: roleStyle(theme) },
  },
  Location: {
    type: "node",
    labels: ["name"],
    icon: "fa-map-marker-alt",
    level: { default: 5 },
    style: { default: locationStyle(theme) },
  },
  Division: {
    type: "node",
    labels: ["name"],
    icon: "fa-chart-pie",
    level: { default: 5 },
    style: { default: divisionStyle(theme) },
  },
  [NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION]: {
    type: "node",
    labels: ["name"],
    icon: "fa-users",
    level: { default: 0 },
    style: {
      default: teamStyle(theme),
      target: targetTeamStyle(theme),
      remove: removedStyle(theme),
      add: addedStyle(theme),
      noChange: noChangeStyle(theme),
      bigTeam: bigTeamStyle(theme),
    },
  },
});

const supportedGlyphs = (theme) => ({
  MemberCount: {
    color: `${themeGet("colors.successLight")({ theme })}`, // the glyph fill colour
    border: {
      color: `${themeGet("colors.successDark")({ theme })}`,
    }, // glyph border
    angle: 45, // glyph in NE corner
    size: 1.5, // enlargement factor
  },
  GroupCount: {
    color: `${themeGet("colors.greyLight")({ theme })}`, // the glyph fill colour
    border: {
      color: `${themeGet("colors.greyDark")({ theme })}`,
    }, // glyph border
    angle: 315, // glyph in NW corner
    size: 1.5, // enlargement factor
  },
});

export {
  styles,
  selectedNodeStyle,
  linkStyle,
  selectedLinkStyle,
  supportedNodes,
  supportedGlyphs,
};
