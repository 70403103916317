import React, { useState } from "react";
import { Grid } from "orcs-design-system";

import ErrorNotification from "src/components/ErrorNotification";

import { useMainQuery, useSecondaryQuery } from "../context";

import People from "./PeopleList";
import TargetGroups from "./TargetGroups";
import SourceGroups from "./SourceGroups";
import AllocationsRefreshNotification from "./AllocationsRefreshNotification";

const TeamBuilderApproverPage = () => {
  const { error: mainQueryError } = useMainQuery();
  const { error: secondaryQueryError } = useSecondaryQuery();

  // Toggle collapsed or expanded state for source groups panel based on button click inside SourceGroups component
  const [isToggled, setIsToggled] = useState(true);
  const handleButtonClick = () => {
    setIsToggled(!isToggled);
  };

  const error = mainQueryError || secondaryQueryError;
  if (error) {
    return <ErrorNotification error={error} />;
  }

  return (
    <Grid
      gridTemplateColumns={isToggled ? "auto 3fr 3fr" : "2fr 3fr 3fr"}
      gridAutoFlow="row"
      gridGap="r"
      minHeight="0"
      height="100%"
    >
      <SourceGroups isToggled={isToggled} onButtonClick={handleButtonClick} />
      <People />
      <TargetGroups />
      <AllocationsRefreshNotification />
    </Grid>
  );
};

export default TeamBuilderApproverPage;
