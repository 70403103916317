import React from "react";
import PropTypes from "prop-types";
import { map, get } from "lodash";
import { useQuery } from "@apollo/client";
import {
  Loading,
  Flex,
  Avatar,
  Divider,
  FlexItem,
  Badge,
  Box,
} from "orcs-design-system";

import { peopleSearch as peopleSearchQuery } from "src/allocation/members.graphql";

import PersonLink from "src/components/PersonLink";
import { displayInitials } from "src/util/personName";
import FteBadge from "src/components/TeamDetailPage/FteBadge";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import MemberTags from "../../Shared/Members/MemberTags";

const Members = ({ grouping, isMembersExpanded }) => {
  const workspace = useWorkspace();
  const { group, groupId, nestedLevel } = grouping;

  const { data: peopleData, loading } = useQuery(peopleSearchQuery, {
    variables: {
      sourceGroupId: groupId,
      targetGroupId: groupId,
    },
    fetchPolicy: "cache-and-network",
    skip: !isMembersExpanded,
  });

  const peopleList = get(peopleData, "result.people", []);
  const paddingLeft = nestedLevel ? 40 : 20;

  if (loading) {
    return <Loading p="s" ml="l" />;
  }

  return map(peopleList, (person) => {
    const personSubtitle = (
      <Flex alignItems="center" flexWrap="wrap">
        <Badge mt="xxs" mr="xs">
          {person.jobTitle}
        </Badge>
        <Box mt="xxs">
          <FteBadge member={person} team={group} workspace={workspace} />
        </Box>
      </Flex>
    );

    return (
      <React.Fragment key={`${person.employeeId}_person`}>
        <Flex
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          pl={`${paddingLeft}px`}
          mt="s"
        >
          <FlexItem flex="0 0 250px" mr="r">
            <Avatar
              sizing="small"
              image={person.avatar}
              title={
                <PersonLink
                  person={person}
                  target="_blank"
                  isUnallocated={person.isUnallocated || person.disabled}
                />
              }
              subtitle={personSubtitle}
              initials={displayInitials(person)}
            />
          </FlexItem>
          <Box ml="r" width="calc(100vw - 450px)">
            <MemberTags person={person} showMemberTags={true} />
          </Box>
        </Flex>
        <Divider my="s" />
      </React.Fragment>
    );
  });
};

Members.propTypes = {
  grouping: PropTypes.object,
  isMembersExpanded: PropTypes.bool,
};

export default Members;
