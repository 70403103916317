import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import React from "react";
import { Badge, Box, Flex, Small, Spacer } from "orcs-design-system";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const StyledPrincipleResult = styled(Box)`
  a:link {
    text-decoration: inherit;
    color: inherit;
  }

  a:visited {
    text-decoration: inherit;
    color: inherit;
  }

  .cp-principles-row-result p {
    margin-top: ${(props) => themeGet("space.xs")(props)};
  }
`;

const titleMarkup = (principle) => {
  return {
    __html: DOMPurify.sanitize(principle.title),
  };
};

const detailsMarkup = (principle) => {
  return {
    __html: DOMPurify.sanitize(principle.details),
  };
};

const badgeDetailsMap = {
  TRUE: {
    color: "success",
    text: "MET",
  },
  FALSE: {
    color: "danger",
    text: "NOT MET",
  },
  INSUFFICIENT_DATA: {
    color: "warning",
    text: "N/A",
  },
};

const badgeColour = (principle) => {
  if (principle.signal) {
    return principle.signal.color;
  }
  return badgeDetailsMap[principle.met].color;
};

const badgeText = (principle) => {
  if (principle.signal) {
    return principle.signal.text;
  }
  return badgeDetailsMap[principle.met].text;
};

const PrincipleResult = ({ principle }) => (
  <Box p="r">
    <Flex alignItems="center" justifyContent="space-between">
      <StyledPrincipleResult>
        <Spacer my="xs">
          <div
            data-testid="cp-principles-row-title"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={titleMarkup(principle)}
          />
          {principle.details && (
            <Small color="greyDark">
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={detailsMarkup(principle)}
                className="cp-principles-row-result"
              />
            </Small>
          )}
        </Spacer>
      </StyledPrincipleResult>
      <Badge variant={badgeColour(principle)}>{badgeText(principle)}</Badge>
    </Flex>
  </Box>
);

// TODO: shape object
PrincipleResult.propTypes = {
  principle: PropTypes.object,
};

export default PrincipleResult;
