import PropTypes from "prop-types";
import React from "react";

import GroupsList from "src/components/Team/GroupsList";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PersonPropType from "src/custom-prop-types/person";
import getTagsByGroupId from "src/util/getTagsByGroupId";

import FteBadge from "src/components/TeamDetailPage/FteBadge";
import NotAllocatedMsg from "./NotAllocatedMsg";

const Membership = ({
  person,
  supportedGroups,
  groupTypes,
  selectedSort,
  workspace,
}) => {
  if (supportedGroups.length === 0) {
    return <NotAllocatedMsg />;
  }

  const tagsByGroupId = getTagsByGroupId(supportedGroups);

  return (
    <GroupsList
      supportedGroups={supportedGroups}
      groupTypes={groupTypes}
      withSort
      selectedSort={selectedSort}
      workspace={workspace}
      tagsByGroupId={tagsByGroupId}
      renderFteBadge={(team) => (
        <FteBadge team={team} member={person} workspace={workspace} />
      )}
    />
  );
};

Membership.propTypes = {
  person: PersonPropType,
  supportedGroups: PropTypes.array,
  groupTypes: GroupTypesPropType,
  selectedSort: PropTypes.object,
  workspace: PropTypes.object,
};

export default Membership;
