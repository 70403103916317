import { merge } from "lodash";
import { getOrgChartPropertiesFromLocalStorage } from "../utils/storeInLocalStorage";
import { ACTION_TYPES } from "./actionTypes";

export const initialOrgChartState = {
  data: null,
  team: null,
  viewOptions: {
    groupMembersBySupplyOrDemand: false,
    showPeople: true,
  },
  properties: {
    peopleData: [],
    teamsData: [],
  },
};

export const getInitialOrgChartState = ({ tagConfig }) => {
  const storedOrgChartProperties = getOrgChartPropertiesFromLocalStorage({
    tagConfig,
  });
  return merge(initialOrgChartState, storedOrgChartProperties);
};

export const orgChartReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_VIEW_OPTIONS: {
      return {
        ...state,
        viewOptions: {
          ...state.viewOptions,
          ...action.payload,
        },
      };
    }

    case ACTION_TYPES.UPDATE_PROPERTIES: {
      return {
        ...state,
        properties: {
          ...state.properties,
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
};
