import React from "react";
import { useQuery } from "@apollo/client";
import { Popover } from "orcs-design-system";
import { get } from "lodash";
import { getFteSumV2 } from "src/components/FteQueryBadge/getFteSum.graphql";
import { FteInnerBadge } from "../FteBadge/FteBadge";
import GroupPropType from "../../custom-prop-types/group";

const FteQueryBadgeForDemandAndSupply = ({ team }) => {
  const { data, loading, error } = useQuery(getFteSumV2, {
    variables: {
      groupId: team?.id,
      capacityFteFromSupplyOnly: false,
    },
    fetchPolicy: "cache-and-network",
  });

  const allocatedFte = get(data, "results.allocatedFte", 0);
  const capacityFte = get(data, "results.capacityFte", 0);

  return (
    <Popover
      text="Allocated FTE / Capability FTE"
      direction="bottom"
      textAlign="center"
      width="180px"
    >
      <FteInnerBadge hasError={error} loading={loading}>
        {`${allocatedFte} FTE / ${capacityFte} FTE`}
      </FteInnerBadge>
    </Popover>
  );
};

FteQueryBadgeForDemandAndSupply.propTypes = {
  team: GroupPropType.isRequired,
};

export default FteQueryBadgeForDemandAndSupply;
