import React from "react";
import PropTypes from "prop-types";
import { get, filter } from "lodash";

import { useAllocationConfig } from "src/contexts/global/WorkspaceContext/WorkspaceContext";
import { useAllocationProject } from "src/contexts/global/GlobalContext";
import { getPerson as getPersonQuery } from "src/pages/PersonDetailPage/person.graphql";

import PersonAllocationPage from "src/allocation/pages/PersonAllocationPage";

const PersonAllocationTabPage = ({ person }) => {
  const allocationConfig = useAllocationConfig();
  const allocationProject = useAllocationProject();

  const canChangeSupplyGroup = get(
    allocationConfig,
    "enableChangeSupplyGroup",
    false
  );
  const { aggregateId } = person;

  const onSupplyGroupChanged = (newGroup, originalGroupId, cache, result) => {
    const cached = cache.readQuery({
      query: getPersonQuery,
      variables: { aggregateId },
    });

    if (!cached || !result) {
      return;
    }

    let newList = [...get(cached.person, "memberOf", [])];

    if (originalGroupId) {
      newList = filter(newList, (g) => g.id !== originalGroupId);
    }

    if (newGroup) {
      newList.unshift(newGroup);
    }

    cache.writeQuery({
      query: getPersonQuery,
      variables: { aggregateId },
      data: {
        person: {
          ...person,
          memberOf: newList,
        },
      },
    });
  };

  return (
    <PersonAllocationPage
      allocationProjectId={get(allocationProject, "id", null)}
      person={person}
      personId={person.aggregateId}
      personFte={person.fte}
      withSupplyGroupAllocation={canChangeSupplyGroup}
      onSupplyGroupChanged={onSupplyGroupChanged}
    />
  );
};

PersonAllocationTabPage.propTypes = {
  person: PropTypes.object,
};

export default PersonAllocationTabPage;
