import React from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";

const MoveButton = ({ person, onMovePerson, moveMemberBtnLabel }) => {
  const handleClicked = () => {
    onMovePerson(person);
  };

  return (
    <ActionsMenuItem onClick={handleClicked}>
      {moveMemberBtnLabel || "Move"}
    </ActionsMenuItem>
  );
};

MoveButton.propTypes = {
  person: PropTypes.object.isRequired,
  onMovePerson: PropTypes.func.isRequired,
  moveMemberBtnLabel: PropTypes.string,
};

export default MoveButton;
