import themeGet from "@styled-system/theme-get";
import styled, { keyframes } from "styled-components";

const upDown = keyframes`
  0% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(2px);
  }
`;

const StyledButton = styled.button`
  font-family: ${themeGet("fonts.main")};
  font-weight: ${themeGet("fontWeights.2")};
  font-size: ${themeGet("fontSizes.2")};
  appearance: none;
  display: flex;
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
  cursor: pointer;
  color: ${themeGet("colors.primary")};
  svg {
    transform: translateY(0);
  }
  &:hover,
  &:focus {
    outline: 0;
    background: transparent;
    border: none;
    color: ${themeGet("colors.primaryDark")};
    svg {
      animation: 500ms ${upDown} ease-in-out infinite;
    }
  }
`;

export default StyledButton;
