import getTimeDiffAndTimeZone from "city-country-timezone";
import { isEmpty, trim } from "lodash";

import countryCodeMapping from "./countryCodeMapping";

const DEFAULT_TIME_ZONE = "Africa/Conakry";

const getAddressAndCountry = (city, address, country) => {
  if (isEmpty(city) && isEmpty(address) && isEmpty(country)) return null;

  if (isEmpty(city)) {
    if (isEmpty(address)) return country;
    if (isEmpty(country)) return address;
    return `${address}, ${country}`;
  }

  if (isEmpty(address)) {
    if (isEmpty(city)) return country;
    if (isEmpty(country)) return city;
    return `${city}, ${country}`;
  }

  if (isEmpty(country)) {
    if (isEmpty(address)) return city;
    if (isEmpty(city)) return address;
    return `${address}, ${city}`;
  }

  return `${address} ${city}, ${country}`;
};

const getLocalTimeFromTimezone = (timezone) => {
  return new Date().toLocaleString("en-US", {
    timeZone: timezone,
    timeStyle: "short",
  });
};

const getCityFromAddress = (address) => {
  if (isEmpty(address)) return "";
  return address.split(/[, ]+/).pop();
};

const getTimeZoneWithCityAndCountry = (city, country) => {
  const { timezone } = getTimeDiffAndTimeZone(
    `${trim(city)}, ${trim(country)}`
  );
  return timezone;
};

export const getCountryFromCode = (country = "") => {
  const trimedStr = trim(country);
  if (trimedStr.length !== 2) return country;

  if (countryCodeMapping[trimedStr.toUpperCase()]) {
    return countryCodeMapping[trimedStr.toUpperCase()];
  }

  return country;
};

export const getUserTimezone = (user) => {
  if (!user) return null;
  const { city, address, country } = user;
  if (isEmpty(city) && isEmpty(address) && isEmpty(country)) return null;
  const localCity = !isEmpty(city) ? city : getCityFromAddress(address);
  return getTimeZoneWithCityAndCountry(localCity, getCountryFromCode(country));
};

const getLocalTime = (
  city,
  address,
  country,
  getLocalTimeFromTimezoneImpl = getLocalTimeFromTimezone,
  shouldGetFallbackLocalTime = false
) => {
  const timezone = getUserTimezone({ city, address, country });

  if (
    isEmpty(timezone) ||
    (timezone === DEFAULT_TIME_ZONE &&
      country !== "Africa" &&
      !shouldGetFallbackLocalTime)
  ) {
    return null;
  }

  return getLocalTimeFromTimezoneImpl(timezone);
};

const isLocalTimeSame = (first, second) => {
  const firstLocalTime = getLocalTime(
    first?.city,
    first?.address,
    first?.country
  );
  const secondLocalTime = getLocalTime(
    second?.city,
    second?.address,
    second?.country
  );
  if (isEmpty(firstLocalTime) || isEmpty(secondLocalTime)) return true;
  return firstLocalTime === secondLocalTime;
};

export { getCityFromAddress, isLocalTimeSame, getAddressAndCountry };
export default getLocalTime;
