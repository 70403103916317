import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { components } from "react-select";
import { withTheme } from "styled-components";
import { Icon, Select } from "orcs-design-system";
import formatOptionLabel from "./formatOptionLabel";
import useLoadSuggestions from "./hooks/useLoadSuggestions";

const HideFunction = () => null;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon mr="s" icon={["far", "search"]} />
    </components.DropdownIndicator>
  );
};

const CustomComponents = {
  DropdownIndicator,
  IndicatorSeparator: HideFunction,
};

const SuggestionBox = ({
  placeholder,
  value,
  loadSuggestions,
  loadSuggestionsPromise,
  onSearch,
}) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const [inputText, setInputText] = useState("");
  const loadSuggestion = useLoadSuggestions(
    loadSuggestions,
    loadSuggestionsPromise
  );

  const onInputChange = (str, operation) => {
    const { action } = operation;
    if (action === "input-blur" || action === "menu-close") {
      if (!selectedOption) {
        onSearch({
          value: inputText,
          isInput: true,
        });
      }
      return;
    }

    setInputText(str);
  };

  const onSelectChange = useCallback(
    (selected, operation) => {
      const { action } = operation;

      if (action === "select-option") {
        setSelectedOption(selected);
        onSearch(selected);
      } else if (action === "clear") {
        setSelectedOption(null);
        onSearch(null);
      }
    },
    [onSearch]
  );

  return (
    <Select
      data-testid="suggestion-box"
      ariaLabel={placeholder}
      placeholder={placeholder}
      isClearable
      focusDefaultOption={false}
      openMenuOnClick={false}
      loadOptions={loadSuggestion}
      value={selectedOption}
      inputValue={inputText}
      onChange={onSelectChange}
      onInputChange={onInputChange}
      formatOptionLabel={formatOptionLabel}
      noOptionsMessage={HideFunction}
      components={CustomComponents}
      selectType="async"
    />
  );
};

SuggestionBox.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.object,
  loadSuggestions: PropTypes.func,
  loadSuggestionsPromise: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
};

export default withTheme(SuggestionBox);
