import { Box, Button } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const AllocatePersonButton = ({ onShowAllocationModal }) => {
  return (
    <Box mt="r">
      <Button
        data-testid="cp-person-memberships-allocate-btn"
        variant="ghost"
        small
        onClick={onShowAllocationModal}
      >
        Edit allocations
      </Button>
    </Box>
  );
};

AllocatePersonButton.propTypes = {
  onShowAllocationModal: PropTypes.func.isRequired,
};

export default AllocatePersonButton;
