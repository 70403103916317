/* eslint-disable no-nested-ternary */
import { isKeyHotkey } from "is-hotkey";
import { Editor, Transforms, Range } from "slate";

import { isValidUrl } from "src/util/url";

import { BLOCK_TAGS, LIST_TYPES, MARK_TAGS } from "./RichTextEditor.config";

// Hotkeys
export const isBoldHotkey = isKeyHotkey("mod+b");
export const isItalicHotkey = isKeyHotkey("mod+i");
export const isUnderlineHotkey = isKeyHotkey("mod+u");
// export const isCodeHotkey = isKeyHotkey("mod+`");

export const getInitialValue = () => [
  {
    type: BLOCK_TAGS.p,
    children: [{ text: "" }],
  },
];

// Blocks
export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => {
      return n.type === format;
    },
  });
  return !!match;
};

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? BLOCK_TAGS.p : isList ? BLOCK_TAGS.li : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

// Marks
export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

// Links
export const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => n.type === BLOCK_TAGS.link,
  });
  return !!link;
};

export const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === BLOCK_TAGS.link });
};

export const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: BLOCK_TAGS.link,
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

export const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element) => {
    return element.type === BLOCK_TAGS.link ? true : isInline(element);
  };

  // eslint-disable-next-line no-param-reassign
  editor.insertText = (text) => {
    if (text && isValidUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  // eslint-disable-next-line no-param-reassign
  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isValidUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

export const onLinkClick = (editor) => {
  // eslint-disable-next-line no-alert
  const url = window.prompt("Enter the URL of the link:");
  if (!url) {
    return;
  }
  insertLink(editor, url);
};

export const getMarkFromHotkey = (evt) => {
  let mark = null;

  if (isBoldHotkey(evt)) {
    mark = MARK_TAGS.strong;
  } else if (isItalicHotkey(evt)) {
    mark = MARK_TAGS.em;
  } else if (isUnderlineHotkey(evt)) {
    mark = MARK_TAGS.u;
  }

  return mark;
};
