import { isKeyHotkey } from "is-hotkey";

const BOLD = "bold";
const ITALIC = "italic";
const UNDERLINE = "underline";
// const CODE = "code";

// Hotkeys
export const isBoldHotkey = isKeyHotkey("mod+b");
export const isItalicHotkey = isKeyHotkey("mod+i");
export const isUnderlineHotkey = isKeyHotkey("mod+u");
// export const isCodeHotkey = isKeyHotkey("mod+`");

export const getMarkFromHotkey = (evt) => {
  let mark = null;

  if (isBoldHotkey(evt)) {
    mark = BOLD;
  } else if (isItalicHotkey(evt)) {
    mark = ITALIC;
  } else if (isUnderlineHotkey(evt)) {
    mark = UNDERLINE;
  }
  // } else if (isCodeHotkey(evt)) {
  //   mark = CODE;
  // }

  return mark;
};

const BULLETED_LIST = "bulleted-list";
const NUMBERED_LIST = "numbered-list";

export const LIST_TYPES = [BULLETED_LIST, NUMBERED_LIST];

export const getInitialValue = () => [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

export const MarkBtns = [
  [BOLD, ["far", "bold"]],
  [ITALIC, ["far", "italic"]],
  [UNDERLINE, ["far", "underline"]],
];

export const BlockBtns = [
  [BULLETED_LIST, ["far", "list-ul"]],
  [NUMBERED_LIST, ["far", "list-ol"]],
];

export const LinkBtn = ["underline", ["far", "link"]];
