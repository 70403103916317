import React, { useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth } from "src/contexts/global/GlobalContext";
import { useSetAvailableInSidebarGuidedTour } from "src/contexts/global/GuidedTourContext";
import {
  saveGuidedTourToLocalStorage,
  getGuidedTourFromLocalStorage,
} from "src/services/localStorage";

import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import tours from "./tours";
import joyRideTourComplete from "./util/joyRideTourComplete";
import tourDependenciesComplete from "./util/tourDependenciesComplete";

const LOCALE_TEXT = {
  skip: "Close",
};

const GuidedTour = ({ tourName, run = true, data = {} }) => {
  const setAvailableInSidebarGuidedTour = useSetAvailableInSidebarGuidedTour();
  const auth = useAuth();
  const history = useHistory();
  const searchStr = useLocation().search;
  const queryParams = useMemo(
    () => new URLSearchParams(searchStr),
    [searchStr]
  );

  const tourPersistedState = getGuidedTourFromLocalStorage(
    auth.username,
    tourName
  );
  const forceStartFlag = queryParams.get("guided_tour") === tourName;

  const interceptStep = useCallback((state) => {
    const { type, step } = state;

    if (type === EVENTS.STEP_BEFORE && step.beforeAction) {
      step.beforeAction();
    }

    if (type === EVENTS.STEP_AFTER && step.afterAction) {
      step.afterAction();
    }
  }, []);

  const completeTour = useCallback(
    (state) => {
      if (!joyRideTourComplete(state)) {
        return;
      }

      saveGuidedTourToLocalStorage(auth.username, tourName, state);
      if (state.status === STATUS.FINISHED) {
        trackEvent(EVENT_TRACKING.GUIDED_TOUR_COMPLETED, {
          guided_tour: tourName,
        });
      }
      if (state.status === STATUS.SKIPPED) {
        trackEvent(EVENT_TRACKING.GUIDED_TOUR_SKIPPED, {
          guided_tour: tourName,
        });
      }
      const { onComplete } = tours[tourName](data);
      if (onComplete) {
        onComplete({ auth, tourName, state });
      }
      queryParams.delete("guided_tour");
      history.push({
        search: `?${queryParams.toString()}`,
      });
    },
    [auth, tourName, queryParams, history, data]
  );

  const handleJoyRideCallback = useCallback(
    (state) => {
      interceptStep(state);
      completeTour(state);
    },
    [interceptStep, completeTour]
  );

  // setting global sidebar availablity
  useEffect(() => {
    if (tours[tourName]) {
      const { triggerInSidebar } = tours[tourName]({});
      const available = triggerInSidebar && run;
      setAvailableInSidebarGuidedTour(tourName, available);
    }
  }, [tourName, run, setAvailableInSidebarGuidedTour]);

  // exit and do nothing if:
  // - no tour is found
  if (!tours[tourName]) {
    return null;
  }

  const tour = tours[tourName](data);
  // run if:
  // - consumer tells us it's ready and:
  //   - force start flag set, or
  //     - config tells us to autostart, and:
  //     - tour dependencies have run to completion, and:
  //     - tour has not run to completion
  const runState =
    run &&
    (forceStartFlag ||
      (tour.autoStart &&
        tourDependenciesComplete(auth.username, tour.dependsOn) &&
        !joyRideTourComplete(tourPersistedState)));

  return (
    <Joyride
      {...tours.defaults}
      {...tour}
      callback={handleJoyRideCallback}
      run={runState}
      locale={LOCALE_TEXT}
    />
  );
};

GuidedTour.propTypes = {
  tourName: PropTypes.string.isRequired,
  run: PropTypes.bool,
  data: PropTypes.object,
};

export default GuidedTour;
