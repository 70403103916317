import themeGet from "@styled-system/theme-get";
import { Box, Checkbox, Flex, FlexItem, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import GroupTypeBadge from "src/components/Badge/GroupTypeBadge";

const WrapperSelected = styled(Box)`
  width: 100%;
  font-weight: normal;
  position: relative;
  margin-right: ${themeGet("space.s")};
  &:hover {
    &:before {
      content: "";
      background-color: ${themeGet("colors.primary10")};
      width: 100%;
      position: absolute;
      height: calc(100% + 12px);
      width: 2000px;
      top: -6px;
      left: -1000px;
    }
  }
  ${(props) =>
    props.active
      ? css`
          &:hover {
            &:before {
              background-color: ${themeGet("colors.primary20")};
            }
          }
          &:before {
            content: "";
            background-color: ${themeGet("colors.primary20")};
            width: 100%;
            position: absolute;
            height: calc(100% + 12px);
            width: 2000px;
            top: -6px;
            left: -1000px;
          }
        `
      : css``};
`;

const WrapperDeselected = styled(WrapperSelected)`
  filter: opacity(0.5);
`;

const CustomTreeHeader = ({
  node,
  groupTypes,
  selectedTargets,
  onNodeChecked,
  refreshTree,
}) => {
  const { id, name, type, hierarchyIds, children, isRoot } = node;

  // Avoid the click on Checkbox triggering toggle in the tree
  const onWrapperClick = (e) => {
    e.stopPropagation();
  };

  const onSelectChange = (e) => {
    onNodeChecked(node, e.target.checked, refreshTree);
  };

  const hasChildren = !!children && children.length > 0;

  // whether we or any of our parents are deselected
  const isDeselected = hierarchyIds.some((hierarchyId) => {
    return selectedTargets[hierarchyId] === false;
  });

  // whether to render with half opacity or not
  const Wrapper = isDeselected ? WrapperDeselected : WrapperSelected;

  return (
    <Wrapper
      onClick={onWrapperClick}
      width="100%"
      data-id={`tree-node-${node.id}`}
      data-testid="cp-custom-tree-header"
    >
      <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap">
        {!hasChildren && !isRoot && (
          <FlexItem paddingX="8px">
            <Icon icon={["fal", "chevron-right"]} size="1x" color="white" />
          </FlexItem>
        )}
        <FlexItem flex="1 1 100px">
          <Checkbox
            label={name}
            checked={isRoot ? true : selectedTargets[id]}
            onChange={(target) => {
              // only allow change if not root
              if (!isRoot) {
                onSelectChange(target);
              }
            }}
          />
        </FlexItem>
        {type && (
          <FlexItem ml="auto">
            <GroupTypeBadge group={node} groupTypes={groupTypes} />
          </FlexItem>
        )}
      </Flex>
    </Wrapper>
  );
};

CustomTreeHeader.propTypes = {
  node: PropTypes.object,
  groupTypes: PropTypes.object,
  selectedTargets: PropTypes.object,
  onNodeChecked: PropTypes.func,
  refreshTree: PropTypes.func,
};

export default CustomTreeHeader;
