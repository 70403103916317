import React from "react";
import PropTypes from "prop-types";
import { isNil } from "lodash";
import { Flex, Icon, Small } from "orcs-design-system";
import { numberToLocaleString } from "src/util/toLocaleString";

const FteDirectWorkforceFlag = ({ fte, directWorkforceFte }) => {
  if (fte === 0 || directWorkforceFte === 0) {
    return null;
  }

  const allDirectWorkforce = fte === directWorkforceFte;
  return (
    <Flex alignItems="center">
      <Icon icon={["fas", "check-circle"]} mr="xs" />
      <Small>
        Direct Workforce{" "}
        {!allDirectWorkforce &&
          `(${numberToLocaleString((directWorkforceFte / fte) * 100, {
            decimalplaces: 0,
          })}%)`}
      </Small>
    </Flex>
  );
};

FteDirectWorkforceFlag.propTypes = {
  fte: PropTypes.number.isRequired,
  directWorkforceFte: PropTypes.number.isRequired,
};

const FteDirectWorkforceStats = ({ fte, directWorkforceFte }) => {
  if (fte === 0) {
    return null;
  }

  const allDirectWorkforce = directWorkforceFte === fte;
  const noDirectWorkforce = directWorkforceFte === 0;

  if (allDirectWorkforce) {
    return (
      <Flex alignItems="center">
        <Small>Direct Workforce: {numberToLocaleString(fte)} FTE (100%)</Small>
      </Flex>
    );
  }

  if (noDirectWorkforce) {
    return (
      <Flex alignItems="center">
        <Small>
          Indirect Workforce: {numberToLocaleString(fte)} FTE (100%)
        </Small>
      </Flex>
    );
  }

  const indirectWorkforceFte = fte - directWorkforceFte;
  return (
    <>
      <Flex alignItems="center">
        <Small>
          Direct Workforce: {numberToLocaleString(directWorkforceFte)} FTE (
          {numberToLocaleString((directWorkforceFte / fte) * 100, {
            decimalplaces: 0,
          })}
          %)
        </Small>
      </Flex>
      <Flex alignItems="center">
        <Small>
          Indirect Workforce: {numberToLocaleString(indirectWorkforceFte)} FTE (
          {numberToLocaleString((indirectWorkforceFte / fte) * 100, {
            decimalplaces: 0,
          })}
          %)
        </Small>
      </Flex>
    </>
  );
};

FteDirectWorkforceStats.propTypes = {
  fte: PropTypes.number.isRequired,
  directWorkforceFte: PropTypes.number.isRequired,
};

const FteBadgeToolTip = ({
  fte,
  directWorkforceFte = null,
  showToolTipDirectWorkforceFteStats = false,
  showToolTipDirectWorkforceFlag = false,
  isCapacity = false,
  isAllocated = false,
}) => {
  const title = isCapacity
    ? "Providing FTE"
    : isAllocated
    ? "Assigned FTE"
    : "Full-Time Equivalent";

  const fteDirectWorkforceStats =
    showToolTipDirectWorkforceFteStats && !isNil(directWorkforceFte) ? (
      <FteDirectWorkforceStats
        directWorkforceFte={directWorkforceFte}
        fte={fte}
      />
    ) : null;

  const fteDirectWorkforceFlag =
    showToolTipDirectWorkforceFlag && !isNil(directWorkforceFte) ? (
      <FteDirectWorkforceFlag
        directWorkforceFte={directWorkforceFte}
        fte={fte}
      />
    ) : null;

  return (
    <>
      <span>{title}</span>
      {fteDirectWorkforceFlag}
      {fteDirectWorkforceStats}
    </>
  );
};

FteBadgeToolTip.propTypes = {
  fte: PropTypes.number,
  directWorkforceFte: PropTypes.number,
  showToolTipDirectWorkforceFteStats: PropTypes.bool,
  showToolTipDirectWorkforceFlag: PropTypes.bool,
  isCapacity: PropTypes.bool,
  isAllocated: PropTypes.bool,
};

export default FteBadgeToolTip;
