import React from "react";
import PropTypes from "prop-types";

import { Select } from "orcs-design-system";
import getInputLabel from "src/util/getInputLabel";
import FormatOptionLabel from "./FormatOptionLabel";

const noOptions = () => "No options";

const TeamDropdown = ({
  id,
  label,
  placeholder,
  isSearchable,
  isLoading,
  isDisabled,
  options,
  loadOptions,
  onSelectTeam,
  value,
  height,
  noOptionsMessage = noOptions,
  cacheOptions = true,
  formatOptionLabel = FormatOptionLabel,
  invalid,
  mandatory,
  focus,
}) => {
  return (
    <Select
      ariaLabel={placeholder}
      inputId={id}
      label={getInputLabel(label, invalid)}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      cacheOptions={cacheOptions}
      backspaceRemovesValue={true}
      isClearable={true}
      isSearchable={isSearchable}
      isLoading={isLoading}
      isDisabled={isDisabled}
      defaultOptions={options}
      loadOptions={loadOptions}
      onChange={onSelectTeam}
      formatOptionLabel={formatOptionLabel}
      value={value}
      menuPlacement="auto"
      selectType="async"
      height={height || "54px"}
      invalid={invalid}
      mandatory={mandatory}
      focus={focus}
    />
  );
};

TeamDropdown.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  height: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  isSearchable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  options: PropTypes.array,
  loadOptions: PropTypes.func,
  onSelectTeam: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  cacheOptions: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  invalid: PropTypes.bool,
  mandatory: PropTypes.bool,
  focus: PropTypes.bool,
};

export default TeamDropdown;
