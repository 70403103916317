import { useQuery, useMutation } from "@apollo/client";
import { get, filter, map, isEmpty, reject, includes } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState, useMemo } from "react";

import { TAGS_UI_COMPONENTS } from "src/consts/tags";

import {
  getTagsForEntity as getTagsForEntityQuery,
  tagTypeConfigsForEntityType as tagTypeConfigsForEntityTypeQuery,
  addTagToEntity as addTagToEntityMutation,
  removeTagFromEntity as removeTagFromEntityMutation,
  addNewTagToEntity as addNewTagToEntityMutation,
} from "./queries/tags.graphql";
import TagSelectorContainer from "./sub-components/TagSelectorContainer";
import TagsEditor from "./TagsEditor";
import {
  modifyTags,
  makeComments,
  updateTagFragment,
  addTagToRemovedList,
  removeTagFromRemovedList,
  getTagTypesWithShowingHistory,
} from "./TagsEditor.util";

const TagsEditorContainer = ({
  entityId,
  entityName,
  updateCache,
  onEntityTagClick,
  readOnly,
  // Be explicit about whether parent manages entity tags or not until we have refactored as below
  manageEntityTags = true,
  presetEntityTags = [],
  tagTypesFilter,
  entityType,
  withAppliedCount,
  comments: commentsMetadata,
  groupType,
  mt,
  tagsVisibleIn = TAGS_UI_COMPONENTS.DIRECTORY,
  featureFlags,
}) => {
  // too many prop type warnings to see this as a prop type warning, so will have to be an exception
  if (!entityType) {
    throw new Error("TagsEditor entityType is missing");
  }

  const comments = useMemo(() => {
    return commentsMetadata
      ? {
          ...commentsMetadata,
          entity: {
            entityId,
            entityName,
            entityType,
          },
        }
      : undefined;
  }, [commentsMetadata, entityId, entityName, entityType]);

  // should this component manage tags or not (defaults to given value, set to true as soon as user hits edit button)
  const [currentManageEntityTags, setCurrentManageEntityTags] =
    useState(manageEntityTags);
  // Use one query to get tag types config, these should not change,
  // so the default cache-first should be fine
  const { data: tagTypesConfigData, loading: loadingTagConfig } = useQuery(
    tagTypeConfigsForEntityTypeQuery,
    {
      variables: {
        entityType,
        ...(tagsVisibleIn && { visibleIn: tagsVisibleIn }),
        ...(groupType && { groupType }),
      },
    }
  );

  const tagTypesConfig = useMemo(() => {
    const configs = reject(
      get(tagTypesConfigData, "tagTypesConfig"),
      "isGroupAssociation"
    );

    if (isEmpty(tagTypesFilter)) {
      return configs;
    }

    return filter(configs, (type) => {
      return includes(tagTypesFilter, type.id);
    });
  }, [tagTypesConfigData, tagTypesFilter]);

  const tagTypesWithShowingHistory =
    getTagTypesWithShowingHistory(tagTypesConfig);

  const { data: removedEntityData, loading: loadingRemovedEntityData } =
    useQuery(getTagsForEntityQuery, {
      variables: {
        entityId,
        tagTypes: tagTypesWithShowingHistory,
        tagStatus: "REMOVED",
      },
      skip:
        !currentManageEntityTags ||
        !entityId ||
        isEmpty(tagTypesWithShowingHistory),
    });

  const removedTags = get(removedEntityData, "tags", []);
  const tagTypes = map(tagTypesConfig, "id");

  // TODO : Replace this with a wrapper "ManagedTagsEditorContainer" that manages the entity for you
  // which can be optional
  // If there is presetTags passed in, it will be replaced by the response from the getTagsForEntities query
  // In this way, the update cache will work.
  // If outside of this editor, we need to update cache, then use the updateCache callback prop

  const { data: entityData, loading: loadingEntityTags } = useQuery(
    getTagsForEntityQuery,
    {
      variables: {
        entityId,
        tagTypes,
      },
      skip: !currentManageEntityTags || !entityId || !tagTypesConfig,
    }
  );

  // const allTags = get(tagsData, "tags", []);

  const loading =
    loadingEntityTags || loadingTagConfig || loadingRemovedEntityData;

  // TODO : Now don't allow additional filtering of tagtypes but we could if needed.
  // Otherwise we use the entityType
  // const tagTypesConfig = useMemo(() => {
  //   return chain(tagTypesConfigData)
  //     .filter(({ id }) => includes(tagTypes, id))
  //     .value();
  // }, [tagTypesConfigData, tagTypes]);

  const updateCacheAfterAddTag = (cache, { data: { result: entityTag } }) => {
    const cached = cache.readQuery({
      query: getTagsForEntityQuery,
      variables: { entityId, tagTypes },
    });

    // todo: in managed mode should always in the cache (see refactoring suggestion above)
    if (!cached) {
      return;
    }

    removeTagFromRemovedList({
      cache,
      entityId,
      tagTypesWithShowingHistory,
      tag: entityTag,
      addedTags: cached,
      replace: !!entityTag?.replace,
    });

    const { replace } = entityTag || {};
    const tags = modifyTags(cached.tags, entityTag, replace);
    cache.writeQuery({
      query: getTagsForEntityQuery,
      variables: { entityId, tagTypes },
      data: {
        tags,
      },
    });
  };
  const [addTagToEntity] = useMutation(addTagToEntityMutation, {
    update: updateCacheAfterAddTag,
  });
  const [addNewTagToEntity] = useMutation(addNewTagToEntityMutation, {
    update: updateCacheAfterAddTag,
  });

  const [removeTagFromEntity] = useMutation(removeTagFromEntityMutation, {
    update(cache, { data: { result } }) {
      const cached = cache.readQuery({
        query: getTagsForEntityQuery,
        variables: { entityId, tagTypes },
      });

      if (!cached) {
        return;
      }

      addTagToRemovedList({
        tag: cached.tags.find((t) => t.tagId === result.tagId),
        cache,
        tagTypesWithShowingHistory,
        entityId,
      });

      const tags = filter(cached.tags, (r) => r.tagId !== result.tagId);

      cache.writeQuery({
        query: getTagsForEntityQuery,
        variables: { entityId, tagTypes },
        data: {
          tags,
        },
      });

      // Update tag fragment
      updateTagFragment(cache, result);
    },
  });

  const setNewTag = useCallback(
    async (newValues, selectedTag) => {
      const {
        id: tagId,
        isUserCreatedTagValue,
        displayValue,
        attributes,
      } = newValues;

      const { id: tagType, allowMultipleOnEntity } = selectedTag;

      const tag = { tagId, tagType, displayValue };

      if (isUserCreatedTagValue) {
        await addNewTagToEntity({
          variables: {
            entityId,
            tagType,
            displayValue,
            attributes,
            replace: !allowMultipleOnEntity,
            ...makeComments(comments, tag),
          },
        });
      } else {
        await addTagToEntity({
          variables: {
            entityId,
            tagId,
            replace: !allowMultipleOnEntity,
            ...makeComments(comments, tag),
          },
        });
      }
    },
    [addNewTagToEntity, entityId, comments, addTagToEntity]
  );

  const removeTag = useCallback(
    async ({ tagId, type: tagType, displayValue }) => {
      const tag = { tagId, tagType, displayValue };

      if (!tagId) {
        return;
      }

      await removeTagFromEntity({
        variables: {
          entityId,
          tagId,
          ...makeComments(comments, tag),
        },
      });
    },
    [removeTagFromEntity, entityId, comments]
  );

  const existingTags = currentManageEntityTags
    ? get(entityData, "tags")
    : presetEntityTags;

  const onStartEdit = useCallback(() => {
    // as soon as we start editing, need to go back to managing the entity tags via our own queries rather than what is passed in.
    setCurrentManageEntityTags(true);
  }, []);

  const onFinishEdit = useCallback(() => {
    if (updateCache) {
      updateCache(existingTags);
    }
  }, [updateCache, existingTags]);

  const handleOnClickEntityTag = (value) => {
    onEntityTagClick(value);
  };

  const combinedTags = [...(existingTags || []), ...(removedTags || [])];

  return (
    <TagsEditor
      readOnly={readOnly}
      existingTags={combinedTags}
      setNewTag={setNewTag}
      removeTag={removeTag}
      onStartEdit={onStartEdit}
      onFinishEdit={onFinishEdit}
      loading={loading}
      tagTypesConfig={tagTypesConfig}
      tagTypes={tagTypes}
      onEntityTagClick={handleOnClickEntityTag}
      withAppliedCount={withAppliedCount}
      comments={comments}
      mt={mt}
      // todo: we need this for all our storybook stuff unless we stop using apollo's mock provider
      // Less need for it if we can use MSW.js or something
      components={{ TagSelector: TagSelectorContainer }}
      entityId={entityId}
      featureFlags={featureFlags}
      entityType={entityType}
    />
  );
};

TagsEditorContainer.propTypes = {
  entityId: PropTypes.string.isRequired,
  entityName: PropTypes.string,
  entityType: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  updateCache: PropTypes.func,
  onEntityTagClick: PropTypes.func,
  presetEntityTags: PropTypes.array,
  tagTypesFilter: PropTypes.array,
  manageEntityTags: PropTypes.bool,
  comments: PropTypes.object,
  groupType: PropTypes.string,
  withAppliedCount: PropTypes.bool,
  mt: PropTypes.string,
  tagsVisibleIn: PropTypes.string,
  featureFlags: PropTypes.object,
};

export default TagsEditorContainer;
