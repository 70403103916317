import { useCallback, useEffect } from "react";
import { find } from "lodash";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";
import { getDirectMembersAndChildTeamsQuery } from "src/queries/group.graphql";

const useUpdateChildTeamsCacheOnAddition = (rootTeam) => {
  const updateChildTeamsCache = useCallback(({ newGroup, proxy, team }) => {
    const directParentId = newGroup.parentIds[newGroup.parentIds.length - 1];
    const isAddingToRoot = directParentId === team.id;
    const targetGroupId = isAddingToRoot
      ? team.id
      : newGroup.parentIds[newGroup.parentIds.length - 2];

    try {
      const proxyData = proxy.readQuery({
        query: getDirectMembersAndChildTeamsQuery,
        variables: {
          groupId: targetGroupId,
        },
      });

      if (!proxyData) {
        return;
      }

      let updatedChildTeams = [];

      if (isAddingToRoot) {
        if (find(proxyData?.team?.childTeams, { id: newGroup.id })) {
          return;
        }
        updatedChildTeams = [...proxyData.team.childTeams, newGroup];
      } else {
        const directParent = find(proxyData?.team?.childTeams, {
          id: directParentId,
        });

        if (find(directParent?.childTeams, { id: newGroup.id })) {
          return;
        }

        updatedChildTeams = proxyData.team.childTeams.map((t) => {
          if (t.id === directParentId) {
            return {
              ...t,
              childTeams: [
                ...t.childTeams,
                {
                  id: newGroup.id,
                },
              ],
            };
          }
          return t;
        });
      }

      proxy.writeQuery({
        query: getDirectMembersAndChildTeamsQuery,
        variables: {
          groupId: targetGroupId,
        },
        data: {
          team: {
            ...proxyData.team,
            childTeams: updatedChildTeams,
          },
        },
      });
    } catch {
      // DO NOTHING
    }
  }, []);

  useEffect(() => {
    if (rootTeam?.id) {
      eventEmitter.on(EVENTS.NEW_GROUP_ADDED, (newGroup, proxy, variables) => {
        updateChildTeamsCache({ newGroup, proxy, variables, team: rootTeam });
      });
    }
  }, [rootTeam, updateChildTeamsCache]);
};

export default useUpdateChildTeamsCacheOnAddition;
