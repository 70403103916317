import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import { map, isFinite } from "lodash";
import { Small, Flex, Icon } from "orcs-design-system";

import { getForecastReadonlyMsg } from "src/allocation/util/allocationProject";
import { DEFAULT_DATE_FORMAT } from "src/consts/global";

const getPopoverTexts = (
  allocationProject,
  rootGroup,
  isColumnReadonly,
  isConstraintsMode,
  showTotalCost
) => {
  const {
    isActive,
    startDate,
    allocationDate,
    enableDecimalFTEInput,
    workingDays,
  } = allocationProject;
  const from = moment.utc(startDate).local().format(DEFAULT_DATE_FORMAT);
  const to = moment.utc(allocationDate).local().format(DEFAULT_DATE_FORMAT);

  const popoverTexts = [];
  popoverTexts.push(`From: ${from}`);
  popoverTexts.push(`To: ${to}`);

  if (!isActive || isConstraintsMode) {
    if (isColumnReadonly) {
      popoverTexts.push(getForecastReadonlyMsg(allocationProject, rootGroup));
    } else if (!enableDecimalFTEInput) {
      popoverTexts.push("No decimal in Forecast");
    }
  }

  if (
    showTotalCost &&
    !isActive &&
    (workingDays <= 0 || !isFinite(workingDays))
  ) {
    popoverTexts.push("No working days have been set for this project");
  }

  return popoverTexts;
};

const ActiveLogo = styled(Icon)`
  margin-right: 5px;
`;

const AllocationProjectContent = ({
  allocationProject,
  isColumnReadonly,
  isConstraintsMode,
  rootGroup,
  showTotalCost,
}) => {
  const { isActive } = allocationProject;
  const popoverTexts = getPopoverTexts(
    allocationProject,
    rootGroup,
    isColumnReadonly,
    isConstraintsMode,
    showTotalCost
  );

  return (
    <>
      {isActive && (
        <Flex alignItems="center">
          <ActiveLogo icon={["fas", "check-circle"]} />
          <Small weight="bold">ACTIVE</Small>
        </Flex>
      )}

      {map(popoverTexts, (text, index) => (
        <Flex key={index} alignItems="center">
          <Small>{text}</Small>
        </Flex>
      ))}
    </>
  );
};

AllocationProjectContent.propTypes = {
  allocationProject: PropTypes.object.isRequired,
  isColumnReadonly: PropTypes.bool.isRequired,
  isConstraintsMode: PropTypes.bool.isRequired,
  rootGroup: PropTypes.object.isRequired,
  showTotalCost: PropTypes.bool,
};

export default AllocationProjectContent;
