import { Icon, Box, P, Flex, Button, Popover } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import icon from "src/config/icons";

import { copywriting, testIds } from "../ReportTeamChange.config";

const DetailLineItem = ({ label, content }) => {
  const [isCopied, setIsCopied] = useState(false);

  const clickHandler = useCallback(() => {
    setIsCopied(false);
    navigator.clipboard.writeText(content || "");

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }, [content]);

  return (
    <>
      <P fontWeight="bold">{label}:</P>
      <Flex>
        <Box
          borderRadius={2}
          boxBorder="default"
          bg="greyLightest"
          p="r"
          mr="s"
          width="100%"
          minHeight="36px"
          dataTestId={`${label.toLowerCase()}-email`}
        >
          <P>{content}</P>
        </Box>

        <Popover
          text={
            isCopied
              ? copywriting.COPIED_TO_CLIPBOARD
              : copywriting.COPY_TO_CLIPBOARD
          }
          direction="topLeft"
          textAlign="center"
          width="130px"
        >
          <Flex>
            <Button
              iconOnly
              variant="ghost"
              height="36px"
              width="36px"
              ariaLabel={`Copy ${label} text to clipboard`}
              onClick={clickHandler}
              dataTestId={`${label.toLowerCase()}-${
                testIds.COPY_TO_CLIPBOARD_TEST_ID
              }`}
            >
              <Icon icon={icon.copy} />
            </Button>
          </Flex>
        </Popover>
      </Flex>
    </>
  );
};

DetailLineItem.propTypes = {
  content: PropTypes.string,
  label: PropTypes.string,
};

export default DetailLineItem;
