import { useLazyQuery } from "@apollo/client";
import { filter, uniq } from "lodash";
import { Box, Flex, Loading } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import TreeView from "src/components/TreeView/TreeView";
import { getDescendantGroups } from "src/queries/group.graphql";
import CustomTreeHeader from "./CustomTreeHeader";

/**
 * Recursively adds descendant groups to their parents beginning with the root group.
 *
 * @param {object} rootGroup - The root group from which all other groups will descend.
 * @param {Array} descendantGroups - An array of descendant groups to be added to their parents.
 * @returns {object} - The root parent object with all descendant groups added as children.
 */
function groupsToTree(rootGroup, descendantGroups) {
  const recursivelyAddDescendantsToParents = (parent, descendants) => {
    // find direct children for parent
    const directChildren = filter(descendants, (descendant) => {
      return descendant.directParentId === parent.id;
    });

    // add direct children to parent
    directChildren.forEach((child) => {
      if (parent.children === undefined) {
        // eslint-disable-next-line no-param-reassign
        parent.children = [];
      }
      parent.children.push({
        ...child,
        toggled: true,
      });
    });

    // recurse down tree
    if (parent.children !== undefined) {
      parent.children.forEach((child) => {
        recursivelyAddDescendantsToParents(child, descendants);
      });
    }
  };

  // the root group from which all other groups will descend
  const rootParent = {
    ...rootGroup,
    toggled: true,
    isRoot: true,
  };
  // begin recursion with root group
  recursivelyAddDescendantsToParents(rootParent, descendantGroups);

  return rootParent;
}

const HierarchySelector = ({ rootGroupId, onChange, groupTypes }) => {
  const [groupsMap, setGroupsMap] = useState(null);
  const [treeData, setTreeData] = useState(null);

  const [fetchDescendants, { data: groupsData, loading: fetchingGroups }] =
    useLazyQuery(getDescendantGroups, {
      fetchPolicy: "network-only",
    });

  // get group type ids (we use state so we can avoid re-rendering when groupTypeIds is a hook dependency)
  const resetState = useCallback(() => {
    setGroupsMap(null);
    setTreeData(null);
  }, [setGroupsMap, setTreeData]);

  // reset state on root group change or component unload
  useEffect(() => {
    if (!rootGroupId) {
      resetState();
    }
    return () => {
      resetState();
    };
  }, [rootGroupId, resetState]);

  // fetch descendant groups
  useMemo(() => {
    if (rootGroupId) {
      // fetch
      fetchDescendants({
        variables: {
          groupId: rootGroupId,
        },
      });
    }
  }, [fetchDescendants, rootGroupId]);
  // process groupsData into groupsMap
  useMemo(() => {
    const pluckGroup = (group) => {
      return {
        id: group.id,
        name: group.name,
        type: group.type,
        hierarchy: group.hierarchy,
        hierarchyIds: group.hierarchyIds,
        parentIds: group.parentIds,
        directParentId: group.directParentId,
      };
    };
    if (groupsData && groupsData.groups) {
      // push all groups into a map (id -> group)
      // root group is at root level of 'groups', all others are in 'descendantGroups'
      const groupDictionary = {};
      groupDictionary[groupsData.groups.id] = pluckGroup(groupsData.groups);
      groupsData.groups.descendantGroups.forEach((group) => {
        groupDictionary[group.id] = pluckGroup(group);
      });
      setGroupsMap(groupDictionary);
    }
  }, [setGroupsMap, groupsData]);

  // process groupsMap into treeData
  useMemo(() => {
    // rootGroup can change before we null groupsMap, so we need to check if it's still valid
    if (rootGroupId && groupsMap && groupsMap[rootGroupId]) {
      setTreeData(groupsToTree(groupsMap[rootGroupId], groupsMap));
    }
  }, [setTreeData, groupsMap, rootGroupId]);

  // update parent with selected groups
  useEffect(() => {
    // collect selected and excluded group ids from the hierarchy
    const allGroupTypes = [];
    const recurseGroups = (group) => {
      allGroupTypes.push(group.type);
      if (group.children) {
        group.children.forEach((child) => {
          recurseGroups(child);
        });
      }
    };

    if (treeData) {
      recurseGroups(treeData);
    }

    // update parent component
    if (onChange) {
      onChange(uniq(allGroupTypes));
    }
  }, [rootGroupId, treeData, onChange]);

  // callback for custom tree headers
  const customizeTreeHeader = useCallback(
    ({ node }, { refreshTree }) => {
      return (
        <CustomTreeHeader
          node={node}
          groupTypes={groupTypes}
          refreshTree={refreshTree}
        />
      );
    },
    [groupTypes]
  );

  // render message if no root group selected
  if (!rootGroupId) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  // render loader if fetching or not yet processed tree data
  if (fetchingGroups || !treeData) {
    return (
      <Flex mt="xl" justifyContent="center" alignItems="center">
        <Loading large centered />
      </Flex>
    );
  }
  // render tree
  return (
    // hide overflow to prevent horizontal scrollbars resulting from the selection highlight
    <Box overflow="hidden">
      <TreeView
        data={treeData}
        withGroupTypeBadge={true}
        customizeTreeHeader={customizeTreeHeader}
        data-testid="cp-hierarchy-selector-tree-view"
      />
    </Box>
  );
};

HierarchySelector.propTypes = {
  rootGroupId: PropTypes.string,
  onChange: PropTypes.func,
  groupTypes: PropTypes.object,
};

export default HierarchySelector;
