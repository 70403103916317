import React from "react";
import PropTypes from "prop-types";
import { map, isEmpty, get } from "lodash";
import { Box, Spacer, H6, P, Loading } from "orcs-design-system";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import { useObjectiveAttributeFilter } from "src/allocation/pages/ForecastPage/components/PlannerGroupings/Objectives/hooks/useObjectiveAttributeFilter";
import { getObjectiveIds } from "src/allocation/pages/ForecastPage/components/PlannerGroupings/Objectives/utils/getObjectiveIds";
import { useFetchObjectives } from "src/allocation/pages/ForecastPage/components/PlannerGroupings/Objectives/hooks/useFetchObjectives";
import ObjectiveCheckbox from "./ObjectiveCheckbox";

const getNoObjectiveHint = (selectedGroup) => {
  const objectives = get(selectedGroup, "objectives");

  if (!isEmpty(objectives)) {
    return `Please select objectives`;
  }

  const type = get(selectedGroup, "type", "function");
  if (!type) {
    return `There are no objectives for this level. Please select a group or sub-group on the left to show group objectives.`;
  }

  return `No ${type} level objectives, please select a group or sub-group on the left to show group objectives.`;
};

const ObjectiveSelectSection = ({
  targetGroup,
  isUpdating,
  selectedGroup,
  currentSelect,
  objectiveIds,
  handleObjectiveSelect,
  objectiveLabel,
  showStatusBadge,
}) => {
  const groupTypes = useGroupTypes();
  const attributeFilter = useObjectiveAttributeFilter({ group: targetGroup });

  const filteredObjectiveIds = getObjectiveIds({
    group: targetGroup,
    groupTypes,
  });

  const { sortedObjectives, loading } = useFetchObjectives({
    objectives: targetGroup.objectives,
    input: {
      objectiveIds: filteredObjectiveIds,
      options: {
        attributeFilter,
        includeDirectParentObjectives: true,
      },
    },
    skip: isEmpty(filteredObjectiveIds),
  });

  let hint = null;

  if (!loading && isEmpty(sortedObjectives)) {
    hint = (
      <P color="greyDark" p="s">
        <em>{getNoObjectiveHint(selectedGroup)}</em>
      </P>
    );
  }

  return (
    <>
      <H6 mb="s" weight="bold">
        {`${objectiveLabel} linked`}
      </H6>
      <Box
        borderRadius={2}
        boxBorder="default"
        p="s"
        pr="r"
        height="calc(100% - 48px)"
        maxHeight="calc(100% - 48px)"
        overflowY="auto"
      >
        {hint}
        {loading ? (
          <Loading mt="l" centered />
        ) : (
          <Spacer p="s">
            <Spacer p="s">
              {map(sortedObjectives, (option) => {
                return (
                  <ObjectiveCheckbox
                    key={option.id}
                    option={option}
                    currentSelect={currentSelect}
                    objectiveIds={objectiveIds}
                    isUpdating={isUpdating}
                    handleObjectiveSelect={handleObjectiveSelect}
                    targetGroup={targetGroup}
                    showStatusBadge={showStatusBadge}
                  />
                );
              })}
            </Spacer>
          </Spacer>
        )}
      </Box>
    </>
  );
};

ObjectiveSelectSection.propTypes = {
  targetGroup: PropTypes.object,
  isUpdating: PropTypes.bool,
  selectedGroup: PropTypes.object,
  currentSelect: PropTypes.array,
  objectiveIds: PropTypes.array,
  handleObjectiveSelect: PropTypes.func,
  objectiveLabel: PropTypes.string,
  showStatusBadge: PropTypes.bool,
};

export default ObjectiveSelectSection;
