import { get } from "lodash";

import {
  setAllocationDirectConstraint,
  setAllocationHierarchyConstraint,
  setAllocationHierarchyTarget,
} from "src/allocation/allocation.graphql";

export const pickMutationQuery = (isDirectConstraint, useTargetQuery) => {
  if (isDirectConstraint) {
    return setAllocationDirectConstraint;
  }
  if (useTargetQuery) {
    return setAllocationHierarchyTarget;
  }
  return setAllocationHierarchyConstraint;
};

export const getGroupId = (grouping) => {
  const { isVirtualDirectConstraintGrouping } = grouping;
  return isVirtualDirectConstraintGrouping
    ? get(grouping, "parent.groupId")
    : get(grouping, "groupId");
};
