import { get } from "lodash";
import { Box, H5 } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import PersonAvatarAndDetails from "src/components/Person/PersonItem/sub-components/PersonAvatarAndDetails";
import SearchContainer from "src/components/Search/SearchContainer";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PersonPropType from "src/custom-prop-types/person";
import WorkspacePropType from "src/custom-prop-types/workspace";
import { getSupplyBadgeGroups } from "src/util/group";

import { copywriting } from "../ReportTeamChange.config";

const NotifyList = ({
  groupTypes,
  managingPerson,
  onUpdateNotifyList,
  workspace,
  featureFlags,
}) => {
  const tagConfig = get(workspace, "config.tagConfig", {});
  const notifyLabel = managingPerson
    ? copywriting.NOTIFY_ADDITIONAL
    : copywriting.NOTIFY_ANYONE;
  return (
    <Box>
      {managingPerson && (
        <>
          <H5 weight="bold">{copywriting.NOTIFY}</H5>
          <Box py="r" width="320px">
            <PersonAvatarAndDetails
              tagConfig={tagConfig}
              person={managingPerson}
              badgeGroups={getSupplyBadgeGroups({
                person: managingPerson,
                groupTypes,
              })}
              groupTypes={groupTypes}
            />
          </Box>
        </>
      )}
      <H5 pb="r" weight="bold">
        {notifyLabel}
      </H5>
      <SearchContainer
        onOptionSelect={onUpdateNotifyList}
        isMulti={true}
        isCloseOnSelect={false}
        isStandardSelect={true}
        withGroups={false}
        includeTags={false}
        workspace={workspace}
        featureFlags={featureFlags}
      />
    </Box>
  );
};

NotifyList.propTypes = {
  managingPerson: PersonPropType,
  groupTypes: GroupTypesPropType,
  onUpdateNotifyList: PropTypes.func,
  workspace: WorkspacePropType,
  featureFlags: PropTypes.object,
};

export default NotifyList;
