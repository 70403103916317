import { roundFte } from "src/util/roundingStrategy";

export const toState = (base, delta, decimalScale) => {
  const d = parseFloat(delta) || 0;
  const b = parseFloat(base) || 0;
  const total = roundFte(b + d, decimalScale);
  return {
    delta: d,
    base: b,
    total: parseFloat(total),
    text: total,
  };
};

export const updateState = (base, newTotal, decimalScale = 4) => {
  const b = parseFloat(base) || 0;
  const nt = parseFloat(newTotal) || 0;
  return {
    delta: roundFte(nt - b, decimalScale),
    base: b,
    total: nt,
    text: nt,
  };
};
