import { useEffect } from "react";
import { gql, useApolloClient } from "@apollo/client";
import moment from "moment";
import { filter, get, keyBy, map, reduce, values } from "lodash";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";

import {
  useMainQuery,
  useDispatch,
  ACTIONS,
  useActiveAllocationProject,
  useViewMode,
  useModelLookups,
  usePageMode,
  useLookupData,
} from "../context/ForecastContext";

import { GROUPED_BY_DEMAND } from "../context/reducer/viewModes";
import { PLANNER } from "../context/reducer/pageModes";

import {
  getBaselineDescendantGroups,
  getParentsForMovedOutGroups,
  getBaselineParentsForMovedInGroups,
} from "./queries.graphql";

import buildMacroAllocationsQuery from "./buildMacroAllocationsQuery";

const macroAllocationsForMovedOutGroups = async ({
  client,
  baselineDescendantGroups,
  groupingLookup,
  allocationProjectLookup,
}) => {
  const movedGroups = filter(
    baselineDescendantGroups,
    (g) =>
      !groupingLookup[g.id] ||
      groupingLookup[g.id].group.directParentId !== g.directParentId
  );
  if (movedGroups.length > 0) {
    const query = gql(
      buildMacroAllocationsQuery(
        map(values(allocationProjectLookup), "id"),
        map(movedGroups, "id")
      )
    );

    return client
      .query({
        query,
        fetchPolicy: "network-only",
      })
      .then(({ data }) => data);
  }
  return null;
};

const parentsForMovedOutGroups = async ({
  client,
  baselineDescendantGroups,
  groupingLookup,
}) => {
  const movedOutGroups = filter(
    baselineDescendantGroups,
    (g) => !groupingLookup[g.id]
  );
  if (movedOutGroups.length > 0) {
    return client
      .query({
        query: getParentsForMovedOutGroups,
        variables: {
          groupIds: map(movedOutGroups, "id"),
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => data);
  }
  return null;
};

const baselineParentsForMovedInGroups = async ({
  client,
  baselineDate,
  rootGroupId,
  baselineDescendantGroups,
  groupingLookup,
}) => {
  const baselineLookup = keyBy(baselineDescendantGroups, "id");
  const movedInGroupings = filter(values(groupingLookup), (g) => {
    return g.id === rootGroupId || !baselineLookup[g.id];
  });

  if (movedInGroupings.length > 0) {
    return client
      .query({
        query: getBaselineParentsForMovedInGroups,
        fetchPolicy: "no-cache",
        variables: {
          groupIds: map(movedInGroupings, "group.id"),
        },
        context: {
          headers: {
            "x-pit-date": baselineDate.format("YYYY-MM-DD"),
          },
        },
      })
      .then(({ data }) => data);
  }
  return null;
};

const usePageBaselineDiffQuery = () => {
  const client = useApolloClient();
  const pageMode = usePageMode();
  const viewMode = useViewMode();
  const workspace = useWorkspace();
  const isToggledOn = get(
    workspace,
    "config.featureFlags.enablePlannerDiff",
    false
  );

  const {
    loading,
    variables: {
      groupId,
      isRequestorGroup,
      targetGroupTypes,
      supplyGroupTypes,
    },
  } = useMainQuery();
  const { groupingLookup, allocationProjectLookup } = useModelLookups();
  const activeAllocationProject = useActiveAllocationProject();
  const { enableMoveGroupByTag } = useLookupData();
  const dispatch = useDispatch();

  const baselineDate = moment.utc(activeAllocationProject.baselineDate);

  useEffect(
    () => {
      const skip =
        !isToggledOn ||
        loading ||
        viewMode !== GROUPED_BY_DEMAND ||
        pageMode !== PLANNER ||
        !isRequestorGroup ||
        enableMoveGroupByTag;

      if (skip) {
        return;
      }

      const goFetch = async () => {
        const baselineDescendantGroups = get(
          await client.query({
            query: getBaselineDescendantGroups,
            variables: {
              groupId,
              groupTypes: isRequestorGroup
                ? targetGroupTypes
                : supplyGroupTypes,
            },
            fetchPolicy: "network-only",
            context: {
              headers: {
                "x-pit-date": baselineDate.format("YYYY-MM-DD"),
              },
            },
          }),
          "data.groups"
        );

        const extraQueries = [];
        extraQueries.push(
          macroAllocationsForMovedOutGroups({
            client,
            baselineDescendantGroups,
            groupingLookup,
            allocationProjectLookup,
          })
        );
        extraQueries.push(
          parentsForMovedOutGroups({
            client,
            baselineDescendantGroups,
            groupingLookup,
          })
        );
        extraQueries.push(
          baselineParentsForMovedInGroups({
            client,
            baselineDate,
            rootGroupId: groupId,
            baselineDescendantGroups,
            groupingLookup,
          })
        );

        const extraQueriesResults = await Promise.all(extraQueries);
        const supportingData = reduce(
          extraQueriesResults,
          (acc, d) => ({
            ...acc,
            ...d,
          }),
          {}
        );

        dispatch({
          type: ACTIONS.BASELINE_DIFF_QUERY_COMPLETE,
          data: {
            baselineDescendantGroups,
            ...supportingData,
          },
          baselineDate,
        });
      };

      goFetch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewMode, loading, isToggledOn]
  );
};

export default usePageBaselineDiffQuery;
