import React from "react";
import { ReactFlowProvider } from "reactflow";
import { Box, H2 } from "orcs-design-system";
import PropTypes from "prop-types";
import styled from "styled-components";
import Panel from "src/components/Panel";

import "reactflow/dist/style.css";

import { PersonNodeProvider } from "./Nodes/PersonNode/PersonNodeProvider";

import { ObjectiveStateProvider } from "./contexts/ObjectiveProviderContext";
import { EdgeEventProvider } from "./contexts/EdgeEventProvider";

import ReactFlowLayout from "./ReactFlowLayout";
import { getLayout as getDefaultLayout } from "./layouts/getDefaultLayout";

const StyledTitleBox = styled(Box)`
  position: absolute;
  z-index: 4;
  left: 0;
  top: 0;
  background: radial-gradient(
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const StyledPanel = styled(Panel)`
  position: relative;
  box-shadow: none;
  padding: 2px;
`;

const NodeVisualizer = (props) => {
  const {
    title,
    onExpandManagingClick,
    getLayout = getDefaultLayout,
    children = null,
  } = props;

  return (
    <ReactFlowProvider>
      <EdgeEventProvider>
        <ObjectiveStateProvider
          nodes={props?.initialNodes}
          getLayout={getLayout}
        >
          <PersonNodeProvider onExpandManagingClick={onExpandManagingClick}>
            <StyledPanel height="100%" position="relative">
              <StyledTitleBox mr="r" p="s">
                <H2 sizing="small" weight="light">
                  {title}
                </H2>
              </StyledTitleBox>
              <ReactFlowLayout {...props} getLayout={getLayout}>
                {children}
              </ReactFlowLayout>
            </StyledPanel>
          </PersonNodeProvider>
        </ObjectiveStateProvider>
      </EdgeEventProvider>
    </ReactFlowProvider>
  );
};

NodeVisualizer.propTypes = {
  ...ReactFlowLayout.propTypes,
  loading: PropTypes.bool,
  noWheelZoom: PropTypes.bool,
  title: PropTypes.string,
  onExpandManagingClick: PropTypes.func,
};

export default NodeVisualizer;
