import React from "react";
import { Popover, StyledLink, Box, Icon } from "orcs-design-system";
import { PropTypes } from "prop-types";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { findURLAttributeFromAttributes } from "./NameWithExternalUrl";
/**
 * @typedef {{value?: string, attributeType: string}} TeamAttribute
 */

const ExternalUrl = ({ objective, children, showExternalUrl = true }) => {
  const externalUrl = findURLAttributeFromAttributes(
    objective?.attributes
  )?.value;

  return (
    (externalUrl && showExternalUrl && (
      <Box pr="xs" pl="xs">
        {children}
        <Popover
          direction="right"
          text="Open link in new window"
          width="152px"
          textAlign="center"
          inlineBlock
        >
          <StyledLink
            href={externalUrl}
            target="_blank"
            onClick={() =>
              trackEvent(EVENT_TRACKING.URL_CLICKED, {
                action: "objective_item_url_clicked",
              })
            }
            fontSize="inherit"
          >
            <Icon icon={["fas", "external-link-alt"]} ml="5px" />
          </StyledLink>
        </Popover>
      </Box>
    )) || (
      <Box pr="xs" pl="xs">
        {children}
      </Box>
    )
  );
};

ExternalUrl.propTypes = {
  objective: PropTypes.object,
  showExternalUrl: PropTypes.bool,
  children: PropTypes.node,
};

export { ExternalUrl };
