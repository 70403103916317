import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { Text, Popover, Small } from "orcs-design-system";
import { isFinite } from "lodash";
import themeGet from "@styled-system/theme-get";
import { numberToLocaleString } from "src/util/toLocaleString";
import { toReadableNumber } from "src/allocation/util/budgetAndCost";
import { VIEW_MODES } from "../../context/ForecastContext";
import useCurrencySymbol from "./hooks/useCurrencySymbol";

const expandWidth = keyframes`
  0% { width: 0; }
`;

const Container = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.hasBudget ? themeGet("colors.greyLight")(props) : "transparent"};
  padding: 3px;
  border-radius: 14px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    height: 21px;
    border-radius: 10px;
    animation: ${expandWidth} 1000ms ease-in-out 1;
    transition: ${themeGet("transition.transitionDefault")};
    width: ${(props) =>
      props.percentage === 100
        ? "calc(100% - 6px)"
        : props.percentage > 100
        ? "calc(100% - 3px)"
        : props.percentage
        ? `${props.percentage}%`
        : "transparent"};
    background-color: ${(props) =>
      props.backgroundColor === "warning"
        ? themeGet("colors.warningLighter")(props)
        : props.backgroundColor === "danger"
        ? themeGet("colors.dangerLighter")(props)
        : props.backgroundColor === "success"
        ? themeGet("colors.successLighter")(props)
        : "transparent"};
  }
`;

const BudgetText = styled(Text)`
  margin-left: 4px;
  position: relative;
  white-space: nowrap;
  z-index: 1;
  text-align: center;
  display: block;
`;

const NUMBER_OPTION = {
  decimalplaces: 0,
};

const CostAndBudgetCell = ({
  grouping,
  viewMode,
  rootGroup,
  hideBudgetColumn,
  hideBudgetTooltip,
}) => {
  const currencySymbol = useCurrencySymbol();

  const { totalCost, dailyRate, budget, budgetPercentage, budgetStatus } =
    grouping;
  const hasBudget = isFinite(budget);
  const hasTotalCost = totalCost > 0;

  let str;
  let background;
  let popoverText;

  str = toReadableNumber(totalCost, currencySymbol);

  if (hasTotalCost) {
    popoverText = `${currencySymbol}${numberToLocaleString(
      totalCost,
      NUMBER_OPTION
    )}`;
  } else {
    popoverText = isFinite(dailyRate)
      ? `${currencySymbol}-`
      : "No Rate provided";
  }

  if (hasBudget) {
    popoverText += ` / ${currencySymbol}${numberToLocaleString(
      budget,
      NUMBER_OPTION
    )}`;
    str += ` / ${toReadableNumber(budget, currencySymbol)}`;
    background = budgetStatus || "success";
  } else if (
    // demand side supply view or supply side
    !hideBudgetColumn &&
    ((rootGroup.isDemand && viewMode === VIEW_MODES.GROUPED_BY_SUPPLY) ||
      rootGroup.isSource)
  ) {
    str += ` / ${currencySymbol}-`;
    popoverText += ` / No budget provided`;
  }

  let child;
  if (hideBudgetTooltip) {
    child = <BudgetText>{str}</BudgetText>;
  } else {
    child = (
      <Popover
        direction="top"
        textAlign="center"
        width="175px"
        text={<Small>{popoverText}</Small>}
        data-testid="total-cost-and-budget-cell"
      >
        <BudgetText>{str}</BudgetText>
      </Popover>
    );
  }

  return (
    <Container
      hasBudget={hasBudget}
      percentage={budgetPercentage}
      backgroundColor={background}
    >
      {child}
    </Container>
  );
};

CostAndBudgetCell.propTypes = {
  grouping: PropTypes.object.isRequired,
  viewMode: PropTypes.string.isRequired,
  rootGroup: PropTypes.object.isRequired,
  hideBudgetColumn: PropTypes.bool,
  hideBudgetTooltip: PropTypes.bool,
};

export default CostAndBudgetCell;
