import { get } from "lodash";
import { Box, Divider, Notification } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import PersonAllocationsModal from "src/components/Person/AllocationsModal";
import { getPersonItemRowId } from "src/components/Person/PersonItem/PersonItem.util";
import ReportTeamChange from "src/components/Person/ReportTeamChange";
import { useModal } from "src/contexts/withModal";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PersonPropType from "src/custom-prop-types/person";
import { ADD_PERSON_TO_TEAM_SUCCESS_MESSAGE } from "src/consts/tags";
import { isRealTimeAllocationsEnabled } from "src/util/allocations";
import { isSuggestChangeEnabled } from "src/util/featureFlags";

import PersonAvatarAndDetails from "./sub-components/PersonAvatarAndDetails";

const PersonItem = ({
  isDisplayActionsButton = true,
  person,
  badgeGroups,
  groupTypes,
  group,
  highlighted,
  added,
  isLastItem,
  cardView,
  reportsTo,
  successNotificationMessage = ADD_PERSON_TO_TEAM_SUCCESS_MESSAGE,
  showLocalTime = false,
  user,
  featureFlags,
  workspace,
  allocationProject,
}) => {
  const tagConfig = get(workspace, "config.tagConfig", {});
  const [, dispatch] = useModal();
  const canChangeAllocations = isRealTimeAllocationsEnabled({
    user,
    workspace,
    allocationProject,
  });
  const canReportTeamChange = isSuggestChangeEnabled(workspace);

  const onDisplayReportTeamChange = () => {
    const onToggleVisibility = (isModalVisible) => () => {
      dispatch({ type: "setModalVisible", isModalVisible });
    };
    const modalComponent = (
      <ReportTeamChange
        person={person}
        groupTypes={groupTypes}
        isVisible={true}
        onToggleVisibility={onToggleVisibility}
        workspace={workspace}
        featureFlags={featureFlags}
      />
    );
    dispatch({ type: "setModalComponent", modalComponent });
  };

  const onEditingAllocations = () => {
    const onHideModal = () => {
      dispatch({ type: "setModalVisible", isModalVisible: false });
    };

    // We need a person object that can be changed in the modal
    // When user change anything of the person, the modal is not re-rendered,
    // so we have to update the person and re-fresh the modal
    // TODO: Find a good way to do this...
    const personForUpdate = { ...person };
    const modalComponent = (
      <PersonAllocationsModal
        isVisible={true}
        person={personForUpdate}
        workspace={workspace}
        groupTypes={groupTypes}
        allocationProject={allocationProject}
        onHideModal={onHideModal}
        currentGroup={group}
        forceUpdatePerson
      />
    );
    dispatch({ type: "setModalComponent", modalComponent });
  };

  let itemDivider = null;
  if (!isLastItem) {
    itemDivider = <Divider light />;
  }

  return (
    <>
      <Box
        data-testid="cp-personitem-row"
        bg={highlighted ? "primary10" : "default"}
        id={getPersonItemRowId(person)}
        py={cardView || reportsTo ? "0" : "r"}
        mt={reportsTo ? "s" : "0"}
        mb={reportsTo ? "r" : "0"}
      >
        {added && (
          <Notification mb="r" colour="success" icon={["fas", "check-circle"]}>
            {successNotificationMessage}
          </Notification>
        )}
        <PersonAvatarAndDetails
          tagConfig={tagConfig}
          showLocalTime={showLocalTime}
          person={person}
          badgeGroups={badgeGroups}
          groupTypes={groupTypes}
          group={group}
          cardView={cardView}
          isDisplayActionsButton={isDisplayActionsButton}
          canChangeAllocations={canChangeAllocations}
          canReportTeamChange={canReportTeamChange}
          onDisplayReportTeamChange={onDisplayReportTeamChange}
          onEditingAllocations={onEditingAllocations}
          workspace={workspace}
          user={user}
          allocationProject={allocationProject}
        />
      </Box>
      {!cardView && itemDivider}
    </>
  );
};

PersonItem.propTypes = {
  isDisplayActionsButton: PropTypes.bool,
  person: PersonPropType.isRequired,
  badgeGroups: PropTypes.arrayOf(GroupPropType),
  groupTypes: GroupTypesPropType,
  group: GroupPropType,
  highlighted: PropTypes.bool,
  cardView: PropTypes.bool,
  reportsTo: PropTypes.bool,
  added: PropTypes.bool,
  isLastItem: PropTypes.bool,
  successNotificationMessage: PropTypes.string,
  showLocalTime: PropTypes.bool,
  user: PropTypes.object,
  featureFlags: PropTypes.object,
  allocationProject: PropTypes.object,
  workspace: PropTypes.object,
};

export default PersonItem;
