import themeGet from "@styled-system/theme-get";
import { Small, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Icons from "src/config/icons";

import StyledButton from "./StyledButton";

const StyledExpandButton = styled(StyledButton)`
  margin-top: ${themeGet("space.r")};
`;

const CollapseBtn = ({ onClick }) => {
  return (
    <StyledExpandButton onClick={onClick}>
      <Small mr="xs">Collapse</Small>
      <Icon icon={Icons.chevronUp} />
    </StyledExpandButton>
  );
};

CollapseBtn.propTypes = {
  onClick: PropTypes.func,
};

export default CollapseBtn;
