import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid, GridItem, Small } from "orcs-design-system";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import themeGet from "@styled-system/theme-get";
import { map } from "lodash";
import { NODE_TYPES } from "src/components/NodeVisualizer/consts";
import {
  EDGE_COLOR,
  getVisibilityStyles,
  HIGHLIGHTED_ANIMATION,
  TargetHandle,
} from "../node.styled";
import PersonNode from "../PersonNode";
import GroupNode from "../GroupNode";
import CreatePersonNode from "../CreatePersonNode";
import { RoundIconButton } from "../RoundIconButton";

export const COLLECTION_NODE_DISPLAY_MODE = {
  SINGLE_COLUMN: "single-column",
};

const PositionedPlusButton = styled(RoundIconButton)`
  position: absolute;
  bottom: -15px;
`;

const StyledLabel = styled(Small)((props) => {
  const { $labelColor = EDGE_COLOR, $labelRects = {} } = props;
  const { height } = $labelRects;

  return css`
    position: absolute;
    z-index: 100;
    top: -${height / 2 + 5}px;
    background: white;
    color: ${$labelColor};
    text-align: center;
    max-width: 80%;
  `;
});

const StyledBox = styled(Box)((props) => {
  const { $borderStyle = `3px dashed ${EDGE_COLOR}` } = props;

  return css`
    border: ${$borderStyle};
    border-radius: calc(${themeGet("radii.2")} * 2) !important;
    ${getVisibilityStyles(props)}

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
  `;
});

const COLLECTION_TYPES = {
  [NODE_TYPES.PERSON_NODE]: PersonNode,
  [NODE_TYPES.GROUP_NODE]: GroupNode,
};

const CREATE_NODE_TYPE_COMPONENTS = {
  [NODE_TYPES.CREATE_PERSON_NODE]: CreatePersonNode,
};

const CollectionNode = ({ data, ...props }) => {
  const {
    label,
    displayMode,
    creatableNodeTypes = [],
    contextForCreation = {},
  } = data;

  const [showCreateNodeType, setShowCreateNodeType] = useState();
  const [labelRects, setLabelRects] = useState();
  const [highlightedNodeId, setHighlightedNodeId] = useState();

  const labelId = `label_${data.id}`;

  const [colCount, rowCount] = useMemo(() => {
    const itemCount = data.items?.length ?? 0;
    let cols = Math.ceil(Math.sqrt(itemCount));
    const rows = Math.ceil(itemCount / cols);

    if (cols > 6) {
      cols = Math.round(cols / 2);
    } else {
      cols = cols > 2 ? cols - 1 : cols;
    }

    if (displayMode === COLLECTION_NODE_DISPLAY_MODE.SINGLE_COLUMN) {
      cols = 1;
    }

    return [cols, rows];
  }, [displayMode, data.items.length]);

  useEffect(() => {
    setTimeout(() => {
      const rects = document.getElementById(labelId)?.getBoundingClientRect();

      setLabelRects(rects);
    }, 0);
  }, [data, labelId]);

  useEffect(() => {
    if (highlightedNodeId) {
      const timeout = setTimeout(() => {
        setHighlightedNodeId(null);
      }, HIGHLIGHTED_ANIMATION.DURATION * HIGHLIGHTED_ANIMATION.REPETITIONS);

      return () => clearTimeout(timeout);
    }

    return () => null;
  });

  const nodeElements = useMemo(() => {
    if (data?.items.length > 0) {
      return map(data?.items, (item) => {
        const NodeComponent = COLLECTION_TYPES[item.type];

        return (
          <GridItem key={item.id} minWidth="fit-content">
            <NodeComponent
              className={item.id === highlightedNodeId && "highlighted"}
              data={item.data}
              overrideHidden
              collectionGroupId={data.groupId}
            />
          </GridItem>
        );
      });
    }

    return (
      <GridItem gridColumn="1 / -1">No {label || "items"} to display</GridItem>
    );
  }, [data?.items, highlightedNodeId, label, data.groupId]);

  const createNodeElement = useMemo(() => {
    return creatableNodeTypes.map((createableNodeType) => {
      const { type } = createableNodeType;
      const Component = CREATE_NODE_TYPE_COMPONENTS[type];

      return (
        showCreateNodeType === type && (
          <GridItem key={type} minWidth="fit-content">
            <Component
              contextForCreation={contextForCreation}
              items={data.items}
              onCreate={(nodeIdToHighlight) => {
                setShowCreateNodeType(null);
                setHighlightedNodeId(nodeIdToHighlight);
              }}
              onCancel={() => setShowCreateNodeType(null)}
            />
          </GridItem>
        )
      );
    });
  }, [creatableNodeTypes, contextForCreation, data.items, showCreateNodeType]);

  const plusOnClick = useCallback(
    () => setShowCreateNodeType(creatableNodeTypes[0].type),
    [creatableNodeTypes]
  );

  return (
    <StyledBox
      {...props}
      padding="l"
      $hidden={data?.hidden}
      $labelRects={labelRects}
      $borderStyle={data.borderStyle}
      paddingBottom="xl"
    >
      {label && (
        <StyledLabel
          $labelRects={labelRects}
          px="s"
          id={labelId}
          className="label"
          $labelColor={data.labelColor}
          fontSize="1.4rem"
        >
          {label}
        </StyledLabel>
      )}
      <TargetHandle $hidden type="target" position="top" />

      <Grid
        gridGap="l"
        gridTemplateColumns={`repeat(${colCount}, 1fr)`}
        gridTemplateRows={`repeat(${rowCount}, auto, 1fr)`}
        columns={colCount}
        rows={rowCount}
      >
        {nodeElements}
        {createNodeElement}
      </Grid>

      {/* Left it open to create multiple node types in one collection  */}
      {!!creatableNodeTypes?.length && creatableNodeTypes[0]?.canCreate && (
        <PositionedPlusButton
          disabled={showCreateNodeType}
          onClick={plusOnClick}
          variant="primary"
          large
          icon={["fas", "plus"]}
        />
      )}
    </StyledBox>
  );
};

CollectionNode.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    groupId: PropTypes.string,
    label: PropTypes.string,
    borderStyle: PropTypes.string,
    labelColor: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        data: PropTypes.object,
        type: PropTypes.string,
      })
    ),
    hidden: PropTypes.bool,
    displayMode: PropTypes.string,
    variant: PropTypes.string,
    creatableNodeTypes: PropTypes.arrayOf(PropTypes.string),
    contextForCreation: PropTypes.object,
  }),
};

export default CollectionNode;
