import ReactFlow from "reactflow";
import styled from "styled-components";

export const StyledReactFlow = styled(ReactFlow)`
  .react-flow__pane.dragging {
    .node-card {
      cursor: grab;
    }
  }
`;
