import { Box, Small } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { GROUP_OBJECTIVE_TYPE } from "./consts";
import ObjectiveItems from "./ObjectiveItems";

const WithDummyParent = ({ objective, groupTypes, hasActualParents }) => {
  return (
    <Box key={objective.id} mb="xs">
      {hasActualParents && (
        <Box my="s" bg="greyLightest" p="s">
          <Small>{objective.name}</Small>
        </Box>
      )}

      <ObjectiveItems
        objectiveType={GROUP_OBJECTIVE_TYPE}
        objectives={objective.objectives}
        groupTypes={groupTypes}
        hasActualParents={hasActualParents}
      />
    </Box>
  );
};

WithDummyParent.propTypes = {
  objective: PropTypes.object,
  groupTypes: PropTypes.object,
  hasActualParents: PropTypes.bool,
};

export default WithDummyParent;
