import { filter } from "lodash";
import { STATUS_UNALLOCATED } from "src/allocation/consts";

/**
 * @typedef {import('./group').Group} Group
 * Individual allocation object
 * @typedef {Object} IndividualAllocation
 * @property {string} [allocationProjectId]
 * @property {string} [allocationId]
 * @property {string} [targetGroupId]
 * @property {Group} [targetGroup]
 * @property {string} [personId]
 * @property {number} [fte]
 * @property {string} [status]
 * @property {string} [approvalStatus]
 * @property {string} [approved]
 */

/**
 * getActiveAllocations
 * @param {IndividualAllocation[]} allocations
 * @returns {IndividualAllocation[]} macro allocations with active status
 */
// eslint-disable-next-line import/prefer-default-export
export const getActiveAllocations = (allocations) => {
  return filter(allocations, ({ status }) => {
    return !status || status !== STATUS_UNALLOCATED;
  });
};
