import { useApolloClient } from "@apollo/client";
import { get } from "lodash";
import { useCallback, useEffect, useState } from "react";

import { updateTeamAttributes } from "src/queries/mutations/updateTeamAttributes.graphql";
import { getCanUpdateGroupAttributes, isGroupActive } from "src/util/group";

const useUpdateTeamDetails = ({ team, refetch }) => {
  const client = useApolloClient();
  const [editDetailsMode, setEditDetailsMode] = useState(false);

  const canUpdateGroupAttributes =
    getCanUpdateGroupAttributes(team) && isGroupActive(team);

  const handleUpdateTeamDetails = useCallback(
    async (attributes) => {
      if (get(team, "id")) {
        const response = await client.mutate({
          mutation: updateTeamAttributes,
          variables: {
            groupId: team.id,
            attributes,
          },
        });
        if (refetch) {
          refetch();
        }
        return response.data.group;
      }
      return team;
    },
    [client, refetch, team]
  );

  const onEditTeamDetails = useCallback(() => {
    setEditDetailsMode(true);
  }, []);

  // fix for when team changes, edit mode should be closed
  useEffect(() => {
    if (team?.id) {
      setEditDetailsMode(false);
    }
  }, [team?.id]);

  return {
    handleUpdateTeamDetails,
    canUpdateGroupAttributes,
    onEditTeamDetails,
    editDetailsMode,
    setEditDetailsMode,
  };
};

export default useUpdateTeamDetails;
