import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Flex, Icon, Popover, FlexItem } from "orcs-design-system";
import GroupPropType from "src/custom-prop-types/group";
import ObjectiveStatusBadge from "src/pages/TeamPage/sub-components/TeamDetailsPanel/Objectives/ObjectiveStatusBadge";
import { NameWithExternalUrl } from "src/components/TeamStrategiesTable/components/NameWithExternalUrl";
import TreeExpand from "../../Shared/TreeExpand";
import ObjectiveAttributeBadges from "./ObjectiveAttributeBadges";

const HeaderContainer = styled(Flex)`
  padding-right: 15px;
  width: 100%;
`;

const DeleteObjectiveButton = ({ onDelete, isUpdating }) => {
  return (
    <Popover
      text="Remove objective"
      textAlign="center"
      direction="left"
      width="150px"
    >
      <Button
        dataTestId="delete-objective-btn"
        small
        ml="s"
        height="24px"
        width="24px"
        iconOnly
        variant="ghost"
        ariaLabel="Remove objective"
        onClick={onDelete}
        disabled={isUpdating}
      >
        <Icon icon={["fas", "times"]} />
      </Button>
    </Popover>
  );
};

DeleteObjectiveButton.propTypes = {
  onDelete: PropTypes.func,
  isUpdating: PropTypes.bool,
};

const ObjectiveItemHeader = ({
  hasToggle,
  isExpanded,
  toggleExpand,
  name,
  onDelete,
  readOnly,
  isUpdating,
  objective,
  inlineStyle,
}) => {
  return (
    <TreeExpand
      hasToggle={hasToggle}
      isOpen={isExpanded}
      indentLevel={0}
      onToggle={toggleExpand}
      controlObjectives
      inlineStyle={inlineStyle}
    >
      <HeaderContainer alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" width="100%">
          <FlexItem flex="0 0 auto">
            <Icon mr="s" size="lg" icon={["far", "bullseye"]} color="success" />
          </FlexItem>
          <NameWithExternalUrl objective={objective}>
            {name}
          </NameWithExternalUrl>
          <FlexItem flex="0 0 auto" ml="auto">
            <ObjectiveStatusBadge objective={objective} />
            <ObjectiveAttributeBadges objective={objective} />
          </FlexItem>
        </Flex>
        {!readOnly && (
          <DeleteObjectiveButton onDelete={onDelete} isUpdating={isUpdating} />
        )}
      </HeaderContainer>
    </TreeExpand>
  );
};

ObjectiveItemHeader.propTypes = {
  hasToggle: PropTypes.bool,
  isExpanded: PropTypes.bool,
  toggleExpand: PropTypes.func,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  readOnly: PropTypes.bool,
  isUpdating: PropTypes.bool,
  inlineStyle: PropTypes.bool,
  objective: GroupPropType,
};

export default ObjectiveItemHeader;
