import React from "react";
import PropTypes from "prop-types";
import { Popover, Icon, StatusDot } from "orcs-design-system";

const AllocationStatusIcon = ({
  remainingFte,
  popoverDirection = "topLeft",
}) => {
  if (remainingFte === 0) {
    return (
      <Popover
        direction={popoverDirection}
        width="120px"
        textAlign="center"
        text="Fully-allocated"
        data-testid="status-icon-fully-allocated"
      >
        <StatusDot variant="success" icon>
          <Icon size="xs" icon={["fas", "check"]} color="white" />
        </StatusDot>
      </Popover>
    );
  }

  if (remainingFte > 0) {
    return (
      <Popover
        direction={popoverDirection}
        width="120px"
        textAlign="center"
        text="Under-allocated"
        data-testid="status-icon-under-allocated"
      >
        <StatusDot variant="warning" icon>
          <Icon size="xs" icon={["fas", "exclamation"]} color="white" />
        </StatusDot>
      </Popover>
    );
  }

  // targetFteDiff < 0
  return (
    <Popover
      direction={popoverDirection}
      width="120px"
      textAlign="center"
      text="Over-allocated"
      data-testid="status-icon-over-allocated"
    >
      <StatusDot variant="danger" icon>
        <Icon size="xs" icon={["fas", "exclamation"]} color="white" />
      </StatusDot>
    </Popover>
  );
};

AllocationStatusIcon.propTypes = {
  remainingFte: PropTypes.number.isRequired,
  popoverDirection: PropTypes.string,
};

export default AllocationStatusIcon;
