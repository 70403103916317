import React from "react";
import { isNil } from "lodash";
import GroupPropType from "src/custom-prop-types/group";

import GroupHierarchyBreadcrumb from "../GroupHierarchyBreadcrumb";
import GroupHierarchyQueryBreadcrumb from "../GroupHierarchyBreadcrumb/GroupHierarchyQueryBreadcrumb";

const GroupTitle = ({ group }) => {
  if (!group) {
    return null;
  }

  const { hierarchy } = group;
  const GroupHierarchyComponent = isNil(hierarchy)
    ? GroupHierarchyQueryBreadcrumb
    : GroupHierarchyBreadcrumb;

  return (
    <GroupHierarchyComponent group={group} showBreadcrumb showGroupTypeBadge />
  );
};

GroupTitle.propTypes = {
  group: GroupPropType,
};

export default GroupTitle;
