import React from "react";
import { map } from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Icon, Button, Badge, Flex, Popover, Spacer } from "orcs-design-system";
import PersonPropType from "src/custom-prop-types/person";
import IndividualAllocationPropType from "src/custom-prop-types/individualAllocation";

import { roundFte } from "src/util/roundingStrategy";
import PersonLink from "src/components/PersonLink";
import AllocationStatusIcon from "src/allocation/components/AllocationStatusIcon";
import { getGroupName } from "src/allocation/util/group";

const CustomBadge = styled(Badge)`
  white-space: pre-line;
  word-break: break-word;
  text-align: left;
`;

const CustomPersonLink = styled(PersonLink)`
  font-weight: 600;
`;

const NewlyAddedBadge = () => {
  return (
    <Popover
      direction="right"
      width="110px"
      textAlign="center"
      text="Newly added"
    >
      <Icon size="sm" icon={["fas", "user-plus"]} color="success" />
    </Popover>
  );
};

const RemovedBadge = () => {
  return (
    <Popover direction="right" width="110px" textAlign="center" text="Removed">
      <Icon size="sm" icon={["fas", "user-times"]} color="danger" />
    </Popover>
  );
};

const PersonAllocationDetails = React.memo(
  ({ person, allocations, city, onAllocate, readOnly }) => {
    const { bands, fte, remainingFte, isNewlyAdded, isRemoved } = person;

    const renderTeamFteBadges = (teamAllocations) =>
      map(teamAllocations, ({ fte: teamFte, targetGroup }) => {
        const { id, hierarchy } = targetGroup;
        const team = getGroupName(hierarchy);
        return (
          <CustomBadge variant="success" key={id}>
            {team} ({roundFte(teamFte)} FTE)
          </CustomBadge>
        );
      });

    return (
      <>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mb="xs"
          data-testid="PersonAllocationDetails"
        >
          <Flex alignItems="center">
            <Spacer mr="xs">
              <CustomPersonLink person={person} />
              <AllocationStatusIcon
                remainingFte={remainingFte}
                popoverDirection="right"
              />
              {isNewlyAdded && <NewlyAddedBadge />}
              {isRemoved && <RemovedBadge />}
            </Spacer>
          </Flex>
          {!readOnly && (
            <Button iconLeft small onClick={onAllocate} mr="s">
              <Icon icon={["far", "edit"]} />
              Edit
            </Button>
          )}
        </Flex>
        <Flex flexWrap="wrap" alignItems="center">
          <Spacer mr="xs" mt="xs">
            <Badge>{fte} FTE</Badge>
            {bands &&
              map(bands, (band) => <Badge key={band}>{band}</Badge>, [])}
            {city ? <CustomBadge>{city}</CustomBadge> : null}
            {renderTeamFteBadges(allocations)}
          </Spacer>
        </Flex>
      </>
    );
  }
);

PersonAllocationDetails.displayName = "PersonAllocationDetails";

PersonAllocationDetails.propTypes = {
  person: PersonPropType.isRequired,
  allocations: PropTypes.arrayOf(IndividualAllocationPropType),
  city: PropTypes.string,
  onAllocate: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default PersonAllocationDetails;
