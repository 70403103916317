import React from "react";
import { Button, Icon } from "orcs-design-system";
import PropTypes from "prop-types";

export const ShowTeamButton = ({ onShowModal }) => (
  <Button
    data-testid="show-team-button"
    variant="ghost"
    small
    iconLeft
    onClick={onShowModal}
  >
    <Icon icon={["far", "eye"]} />
    Unhide Team
  </Button>
);

ShowTeamButton.propTypes = {
  onShowModal: PropTypes.func.isRequired,
};

export default ShowTeamButton;
