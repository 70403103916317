import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useMutation } from "@apollo/client";
import GroupPropType from "src/custom-prop-types/group";

import { updateTeamAttributes } from "src/pages/TeamDetailPage/team.graphql";
import TeamDescriptionEditModal from "src/components/TeamDescriptionEditModal";

const TeamDescriptionEditModalContainer = ({
  group,
  isVisible,
  onHideModal,
}) => {
  const [onUpdateGroupAttributes] = useMutation(updateTeamAttributes);

  const handleUpdateTeamDetails = useCallback(
    async (attributes) => {
      const groupId = get(group, "id");
      if (groupId) {
        const response = await onUpdateGroupAttributes({
          variables: {
            groupId,
            attributes,
          },
        });
        return response.data.group;
      }
      return group;
    },
    [group, onUpdateGroupAttributes]
  );

  const teamAttributes = get(group, "attributes");

  return (
    <TeamDescriptionEditModal
      group={group}
      attributes={teamAttributes}
      isVisible={isVisible}
      onHandleTeamUpdate={handleUpdateTeamDetails}
      onHideModal={onHideModal}
    />
  );
};

TeamDescriptionEditModalContainer.propTypes = {
  group: GroupPropType.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func.isRequired,
};

export default React.memo(TeamDescriptionEditModalContainer);
