import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get, values, map } from "lodash";
import {
  ActionsMenuHeading,
  ActionsMenuItem,
  Box,
  TextArea,
  Flex,
  Button,
  Icon,
} from "orcs-design-system";

import icons from "src/config/icons";

const OTHER_REASON = { id: "OTHER" };

const ReasonMenuItem = ({ selected, onClick, reason }) => {
  return (
    <ActionsMenuItem type="button" selected={selected} onClick={onClick}>
      {reason}
    </ActionsMenuItem>
  );
};

ReasonMenuItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  reason: PropTypes.string.isRequired,
};

const OtherReasonMenuItem = ({ selected, onClick, reason }) => {
  return (
    <ActionsMenuItem
      type="button"
      selected={selected}
      onClick={onClick}
      id="other"
    >
      <Flex alignItems="center" justifyContent="space-between">
        {reason}
        <Icon icon={icons.pen} />
      </Flex>
    </ActionsMenuItem>
  );
};

OtherReasonMenuItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  reason: PropTypes.string.isRequired,
};

const CustomReasonInput = ({ reasonText, onReject }) => {
  const [reason, setReason] = useState("");

  useEffect(() => {
    if (reasonText && reason !== reasonText) {
      setReason(reasonText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = (e) => {
    setReason(e.target.value);
  };

  const onRejectBtnClick = () => {
    onReject(reason);
  };

  return (
    <Box id="editableContent" p="s">
      <TextArea
        id="reject-reason-textarea"
        autoFocus
        label="Reason for rejection"
        cols="35"
        rows="3"
        defaultValue={reasonText}
        onChange={onInputChange}
      />
      <Button onClick={onRejectBtnClick} small variant="danger" mt="s">
        Reject request
      </Button>
    </Box>
  );
};

CustomReasonInput.propTypes = {
  reasonText: PropTypes.string,
  onReject: PropTypes.func.isRequired,
};

const RejectPopupMenu = ({
  approvalReasons,
  reasonId,
  reasonText,
  onReject,
  closeMenu,
  isHidden,
}) => {
  const otherReason = get(approvalReasons, OTHER_REASON.id, OTHER_REASON);

  const [isReasonInput, setIsReasonInput] = useState(false);

  const reset = () => {
    setIsReasonInput(false);
  };

  useEffect(() => {
    if (isHidden && isReasonInput) {
      reset();
    }
  }, [isHidden, isReasonInput]);

  const resetAndClose = () => {
    reset();
    closeMenu();
  };

  const setOtherReason = () => {
    setIsReasonInput(true);
  };

  const setReason = (id) => () => {
    onReject(id);
    resetAndClose();
  };

  const setCutomReason = (reason) => {
    onReject(otherReason.id, reason);
    resetAndClose();
  };

  return (
    <>
      <ActionsMenuHeading onClick={reset} canClick={isReasonInput}>
        {isReasonInput && <Icon icon={icons.back} mr="xs" />}
        Select reason
      </ActionsMenuHeading>
      {!isReasonInput && (
        <>
          {map(values(approvalReasons), ({ id, reason }) => {
            const selected = reasonId === id;

            if (id === otherReason.id) {
              return (
                <OtherReasonMenuItem
                  key={id}
                  selected={selected}
                  onClick={setOtherReason}
                  reason={reason}
                />
              );
            }
            return (
              <ReasonMenuItem
                key={id}
                selected={selected}
                onClick={setReason(id)}
                reason={reason}
              />
            );
          })}
        </>
      )}
      {isReasonInput && (
        <CustomReasonInput reasonText={reasonText} onReject={setCutomReason} />
      )}
    </>
  );
};

RejectPopupMenu.propTypes = {
  approvalReasons: PropTypes.object.isRequired,
  reasonId: PropTypes.string,
  reasonText: PropTypes.string,
  onReject: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired,
};

export default RejectPopupMenu;
