import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";
import { join, tail } from "lodash";

import { useLookupData, ACTIONS } from "../../../context/ForecastContext";

const MoveGroupButton = ({ grouping, dispatch }) => {
  const { addTeamBtnLabel } = useLookupData();

  const handleMoveGroup = useCallback(
    (e) => {
      e.stopPropagation();

      dispatch({
        type: ACTIONS.SHOW_MOVE_GROUP_MODAL,
        grouping,
      });
    },
    [grouping, dispatch]
  );

  const label = addTeamBtnLabel
    ? join(tail(addTeamBtnLabel.split(" ")), " ")
    : "team";

  return (
    <ActionsMenuItem
      onClick={handleMoveGroup}
    >{`Move ${label}`}</ActionsMenuItem>
  );
};

MoveGroupButton.propTypes = {
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default MoveGroupButton;
