import React from "react";
import PropTypes from "prop-types";
import { isNil, capitalize } from "lodash";
import { Icon, Popover, StatusDot } from "orcs-design-system";
import { roundFte } from "src/util/roundingStrategy";
import { DEFAULT_CONSTRAINT_LABEL } from "src/allocation/consts";
import { useLookupData } from "../../context/ForecastContext";

const ConstraintAlert = ({ constraintCell, total }) => {
  const { constraint, defaultedConstraint } = constraintCell;
  const { constraintLabel } = useLookupData();
  const label = constraintLabel || DEFAULT_CONSTRAINT_LABEL;
  const target = !isNil(constraint) ? constraint : defaultedConstraint;
  if (target === total) {
    return (
      <Popover
        direction="bottomLeft"
        width="175px"
        textAlign="center"
        text={`${capitalize(label)} met`}
      >
        <StatusDot icon variant="success">
          <Icon
            icon={["fas", "check"]}
            color="white"
            size="xs"
            transform="up-2"
          />
        </StatusDot>
      </Popover>
    );
  }

  const delta = roundFte(total - target);
  const content = (
    <>
      <strong>{Math.abs(delta)}</strong> FTE {delta > 0 ? "above" : "below"} the{" "}
      {label} FTE
    </>
  );

  return (
    <Popover
      direction="bottomLeft"
      width="175px"
      textAlign="center"
      text={content}
    >
      <StatusDot icon variant="danger">
        <Icon
          icon={["fas", `arrow-${target > total ? "down" : "up"}`]}
          color="white"
          size="xs"
          transform="up-2"
        />
      </StatusDot>
    </Popover>
  );
};

ConstraintAlert.propTypes = {
  constraintCell: PropTypes.object,
  total: PropTypes.number,
};

export default ConstraintAlert;
