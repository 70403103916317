import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import { useRouteMatch } from "react-router-dom";
import PrincipleStatusQuery from "src/components/PrincipleStatusQuery";
import GroupPropType from "src/custom-prop-types/group";
import GuidedTour from "src/guidedTour/GuidedTour";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";

import TeamInfo from "src/components/TeamInfo";
import { useAuth0 } from "src/contexts/auth0Provider";
import {
  useAllocationProject,
  useUserPerson,
} from "src/contexts/global/GlobalContext";
import FteBadge from "src/components/TeamDetailPage/FteBadge";

const Details = ({ team, refetch, loading }) => {
  const match = useRouteMatch();
  const workspace = useWorkspace();
  const userPerson = useUserPerson();
  const { user } = useAuth0();
  const allocationProject = useAllocationProject();
  const groupTypes = get(workspace, "config.groupTypes", {});
  const groupType = get(groupTypes, get(team, "type"), {});

  const renderTeamFteBadge = useMemo(() => {
    return (teamWithFte) => (
      <FteBadge workspace={workspace} team={teamWithFte} />
    );
  }, [workspace]);

  const renderMemberFteBadge = useMemo(() => {
    return (member, memberInTeam) => (
      <FteBadge workspace={workspace} member={member} team={memberInTeam} />
    );
  }, [workspace]);

  return (
    <>
      <GuidedTour
        run={!!groupType && !!groupType.name}
        tourName="teamDetailsTour"
        data={{ group: team, groupType }}
      />
      <TeamInfo
        teamId={match?.params?.id}
        team={team}
        loading={loading}
        groupTypes={groupTypes}
        user={user}
        userPerson={userPerson}
        workspace={workspace}
        allocationProject={allocationProject}
        renderTeamFteBadge={renderTeamFteBadge}
        renderMemberFteBadge={renderMemberFteBadge}
        renderPrincipleStatus={(teamWithPrincipleStatus) => (
          <PrincipleStatusQuery
            team={teamWithPrincipleStatus}
            textDirection="top"
          />
        )}
        refetch={refetch}
      />
    </>
  );
};

// TODO: shape object
Details.propTypes = {
  team: GroupPropType,
  refetch: PropTypes.func,
  loading: PropTypes.func,
};

export default Details;
