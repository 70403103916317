import React, { useCallback } from "react";
import styled from "styled-components";
import { Flex, Popover } from "orcs-design-system";
import { toLower } from "lodash";
import { objectiveNodeDataPropType } from "../propTypes";
import { DATASOURCES } from "./node.styled";

const StyledImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;

  & img {
    width: auto;
    height: 30px;
  }
`;

const StyledStrong = styled.strong`
  text-transform: capitalize;
`;

const DatasourceIcon = ({ data }) => {
  const { externalUrl } = data;

  const [isHovered, setIsHovered] = React.useState(false);

  const onClick = useCallback((e) => e.stopPropagation(), []);
  const onMouseEnter = useCallback(() => setIsHovered(true), [setIsHovered]);
  const onMouseLeave = useCallback(() => setIsHovered(false), [setIsHovered]);

  const DATASOURCE_NAME = toLower(
    data.datasource?.name || data.datasource?.type
  );
  const DATASOURCE_CONFIG = DATASOURCES[DATASOURCE_NAME];

  if (!DATASOURCE_CONFIG) {
    return null;
  }

  return (
    <Flex width="38px" justifyContent="center">
      <Popover
        width="fit-content"
        direction="top"
        text={
          <>
            Source data imported from{" "}
            <StyledStrong>{DATASOURCE_CONFIG.name}</StyledStrong>{" "}
            {externalUrl && "(click to go to the source)"}
          </>
        }
      >
        <a
          href={externalUrl}
          target="_blank"
          rel="noreferrer"
          onClick={onClick}
        >
          <StyledImgContainer
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <img
              src={
                isHovered
                  ? DATASOURCE_CONFIG.iconHovered
                  : DATASOURCE_CONFIG.icon
              }
              alt={`Brand logo for ${DATASOURCE_CONFIG.name} import type`}
            />
          </StyledImgContainer>
        </a>
      </Popover>
    </Flex>
  );
};

DatasourceIcon.propTypes = {
  data: objectiveNodeDataPropType,
};

export default DatasourceIcon;
