import React from "react";
import { Icon, Popover, Button } from "orcs-design-system";
import PropTypes from "prop-types";

// had to wrap styled div because Popover doesnt support className >_<
const TeamAllocationsLink = ({
  direction = "topRight",
  onLinkClick,
  title,
}) => (
  <Popover
    direction={direction}
    text={title}
    width="124px"
    textAlign="center"
    inlineBlock
  >
    <Button
      small
      iconOnly
      onClick={onLinkClick}
      ariaLabel={title}
      width="20px"
      height="20px"
    >
      <Icon icon={["fas", "expand-alt"]} />
    </Button>
  </Popover>
);

TeamAllocationsLink.propTypes = {
  direction: PropTypes.string,
  title: PropTypes.string,
  onLinkClick: PropTypes.func,
};

export default TeamAllocationsLink;
