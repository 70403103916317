import { nanoid } from "nanoid";
import { map } from "lodash";
import React, { useState } from "react";
import { Icon } from "orcs-design-system";
import PropTypes from "prop-types";

import { PaginationContainer, PaginationLink } from "./Pagination.styled";

const LEFT = "LEFT";
const RIGHT = "RIGHT";
const LEFT_PAGE_JUMP = "LEFT_JUMP";
const RIGHT_PAGE_JUMP = "RIGHT_JUMP";

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const getRange = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const Pagination = ({
  totalRecords = 0,
  pageLimit = 30,
  pageNeighbours = 0,
  onPageChanged,
}) => {
  const MAX_PAGE_NEIGHBOURS = 2;
  // pageNeighbours can be: 0, 1, 2 or 4
  const calcPageNeighbours = Math.max(
    0,
    Math.min(pageNeighbours, MAX_PAGE_NEIGHBOURS)
  );
  const totalPages = Math.ceil(totalRecords / pageLimit);

  const [currentPage, setCurrentPage] = useState(1);

  const gotoPage = (page) => {
    const cPage = Math.max(0, Math.min(page, totalPages));
    const paginationData = {
      currentPage: cPage,
      totalPages,
      pageLimit,
      totalRecords,
    };
    setCurrentPage(cPage);
    onPageChanged(paginationData);
  };

  const handleClick = (page) => (evt) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    const page = currentPage - calcPageNeighbours * 2 - 1;
    gotoPage(page > 0 ? page : 1);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage + calcPageNeighbours * 2 + 1);
  };

  const handleBump = (inc) => (evt) => {
    evt.preventDefault();
    gotoPage(currentPage + inc);
  };

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  const fetchPageNumbers = () => {
    const leftNav = currentPage > 1 ? LEFT : "";
    const rightNav = currentPage < totalPages ? RIGHT : "";

    const startPage = Math.max(2, currentPage - calcPageNeighbours);
    const endPage = Math.min(totalPages - 1, currentPage + calcPageNeighbours);

    /**
     * hasLeftSpill: has hidden pages to the left
     * hasRightSpill: has hidden pages to the right
     * spillOffset: number of hidden pages either to the left or to the right
     */
    const hasLeftSpill = startPage > MAX_PAGE_NEIGHBOURS;
    const hasRightSpill = totalPages - endPage > 1;

    const totalNavButtons =
      (hasLeftSpill ? 1 : 0) +
      (hasRightSpill ? 1 : 0) +
      (leftNav ? 1 : 0) +
      (rightNav ? 1 : 0);
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + left (<< <) and right(> >>) controls
     */
    const totalNumbers = calcPageNeighbours * 2 + totalNavButtons;
    const totalBlocks = totalNumbers + totalNavButtons;

    if (totalPages > totalBlocks) {
      let pages = getRange(startPage, endPage);
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = getRange(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE_JUMP, leftNav, ...extraPages, ...pages, rightNav];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = getRange(endPage + 1, endPage + spillOffset);
          pages = [leftNav, ...pages, ...extraPages, rightNav, RIGHT_PAGE_JUMP];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [
            LEFT_PAGE_JUMP,
            leftNav,
            ...pages,
            rightNav,
            RIGHT_PAGE_JUMP,
          ];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }
    return getRange(1, totalPages);
  };

  if (!totalRecords || totalPages === 1) return null;

  const pages = fetchPageNumbers();

  return (
    <PaginationContainer aria-label="Pagination" data-testid="paging-container">
      {map(
        pages,
        (page) => {
          if (page === LEFT_PAGE_JUMP)
            return (
              <PaginationLink
                key={nanoid()}
                small
                ariaLabel="Skip backwards"
                onClick={handleMoveLeft}
              >
                <Icon icon={["fas", "chevron-double-left"]} size="xs" />
              </PaginationLink>
            );

          if (page === LEFT)
            return (
              <PaginationLink
                key={nanoid()}
                small
                ariaLabel="Previous Page"
                onClick={handleBump(-1)}
              >
                <Icon icon={["fas", "chevron-left"]} size="xs" />
              </PaginationLink>
            );

          if (page === RIGHT)
            return (
              <PaginationLink
                key={nanoid()}
                small
                ariaLabel="Next Page"
                onClick={handleBump(1)}
              >
                <Icon icon={["fas", "chevron-right"]} size="xs" />
              </PaginationLink>
            );

          if (page === RIGHT_PAGE_JUMP)
            return (
              <PaginationLink
                key={nanoid()}
                small
                ariaLabel="Skip forwards"
                onClick={handleMoveRight}
              >
                <Icon icon={["fas", "chevron-double-right"]} size="xs" />
              </PaginationLink>
            );

          if (page) {
            return (
              <PaginationLink
                key={nanoid()}
                small
                ariaLabel={`Page ${page}`}
                isActive={currentPage === page}
                onClick={handleClick(page)}
              >
                {page}
              </PaginationLink>
            );
          }

          return null;
        },
        []
      )}
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
};

export default Pagination;
