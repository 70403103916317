import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { P, Flex, Spacer } from "orcs-design-system";

import GroupPropType from "src/custom-prop-types/group";
import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";
import AllocationStatusIcon from "src/allocation/components/AllocationStatusIcon";
import SkillsRequirementIcon from "src/allocation/components/SkillsRequirementIcon";
import { isContentEmpty } from "src/comments/components/RichTextEditor/serializer/slatePlainTextSerializer";
import { roundFte } from "src/util/roundingStrategy";

const GroupRow = ({ group, onRowClick }) => {
  const onLinkClick = useCallback(() => {
    onRowClick(group);
  }, [onRowClick, group]);

  return (
    <>
      <GroupHierarchyBreadcrumb
        data-testid="sourcegroup-row"
        group={group}
        showBreadcrumb={true}
        showFullHierarchyInBreadcrumb={true}
        onLinkClick={onLinkClick}
      />
      <P textAlign="center">{roundFte(group.allocatedFte)}</P>
      <P textAlign="center">{roundFte(group.targetFte)}</P>
      <Flex alignItems="center" justifyContent="right">
        <Spacer mx="xs">
          {!isEmpty(group.note) && !isContentEmpty(group.note[0]) && (
            <SkillsRequirementIcon skills={group.note} />
          )}
          <AllocationStatusIcon
            remainingFte={group.targetFte - group.allocatedFte}
          />
        </Spacer>
      </Flex>
    </>
  );
};

GroupRow.propTypes = {
  group: GroupPropType.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default GroupRow;
