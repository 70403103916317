import { themeGet } from "@styled-system/theme-get";
import { map, slice, get } from "lodash";
import { Flex, Avatar, Small, StyledLink } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { NOOP } from "src/consts/global";
import getLocalTime, { isLocalTimeSame } from "src/util/getLocalTime";
import { getActiveTheme } from "src/services/localStorage";

const StyledLinkWrapper = styled(StyledLink).attrs(
  ({ index, overlapSize }) => ({
    style: {
      left: `-${index * overlapSize}px`,
    },
  })
)`
  position: relative;
  cursor: pointer;
  border-radius: ${(props) => (props.isDefaultShape ? "50%" : "initial")};
  border: 2px solid white;
  &:hover,
  &:focus {
    transform: scale(1.2);
    text-decoration: none;
    z-index: 1;
  }
`;

const TotalNumber = styled(Flex).attrs(({ index, overlapSize }) => ({
  style: {
    left: `-${index * overlapSize}px`,
  },
}))`
  position: relative;
  cursor: pointer;
  min-width: ${(props) => props.totalNumberSize};
  min-height: ${(props) => props.totalNumberSize};
  max-width: ${(props) => props.totalNumberSize};
  max-height: ${(props) => props.totalNumberSize};
  background-color: ${(props) => themeGet("colors.primary")(props)};
  border-radius: ${(props) =>
    props.isDefaultShape ? "50%" : themeGet("radii.2")(props)};
  border: 2px solid ${(props) => themeGet("colors.white")(props)};
`;

const getAvatarDefault = (item) => get(item, "avatar");

const CollapsedList = ({
  loading,
  placeholder = null,
  items,
  sortItems,
  getItemInitials,
  getAvatar = getAvatarDefault,
  getLinkPath,
  listLength,
  shape = "default",
  overlapSize = 1,
  onListClick = NOOP,
  sizing = "default",
  uppercase = true,
  idField = "aggregateId",
  person,
  showLocalTime = false,
}) => {
  if (loading) {
    return placeholder;
  }

  const targets = slice(sortItems(items), 0, listLength);
  const isDefaultShape = shape === "default";
  const totalNumberSize = getActiveTheme() === "compact" ? "44px" : "50px";
  let total = null;
  if (items.length > listLength) {
    total = (
      <TotalNumber
        alignItems="center"
        justifyContent="center"
        overlapSize={overlapSize}
        index={listLength}
        onClick={onListClick}
        isDefaultShape={isDefaultShape}
        totalNumberSize={totalNumberSize}
      >
        <Small color="white">{`+${items.length - listLength}`}</Small>
      </TotalNumber>
    );
  }

  return (
    <Flex data-testid="collapsed-list-wrapper" mt="r">
      {map(targets, (item, index) => {
        return (
          <StyledLinkWrapper
            key={item[idField]}
            index={index}
            overlapSize={overlapSize}
            isDefaultShape={isDefaultShape}
            to={getLinkPath(item[idField])}
          >
            <Avatar
              localTime={
                showLocalTime && !isLocalTimeSame(item, person)
                  ? getLocalTime(item?.city, item?.address, item?.country)
                  : null
              }
              initials={getItemInitials(item)}
              image={getAvatar(item)}
              sizing={sizing}
              shape={shape}
              uppercase={uppercase}
            />
          </StyledLinkWrapper>
        );
      })}
      {total}
    </Flex>
  );
};

CollapsedList.propTypes = {
  loading: PropTypes.bool,
  placeholder: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object),
  sortItems: PropTypes.func.isRequired,
  getItemInitials: PropTypes.func.isRequired,
  getAvatar: PropTypes.func,
  getLinkPath: PropTypes.func.isRequired,
  listLength: PropTypes.number,
  shape: PropTypes.string,
  overlapSize: PropTypes.number,
  onListClick: PropTypes.func,
  sizing: PropTypes.string,
  uppercase: PropTypes.bool,
  idField: PropTypes.string,
  person: PropTypes.object,
  showLocalTime: PropTypes.bool,
};

export default CollapsedList;
