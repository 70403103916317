/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";
import { useApolloClient } from "@apollo/client";

import {
  removePlaceholderPerson,
  peopleSearch as peopleSearchQuery,
} from "src/allocation/members.graphql";

import {
  useLookupData,
  useDispatch,
} from "../../../../context/ForecastContext";
import useSaveMacroAllocation from "../../hooks/useSaveMacroAllocation";
import { findAllocation } from "../utils/person.util";

const RemoveVacantRoleButton = ({
  person,
  lineItem,
  vacantRoleLabel,
  searchVariables,
}) => {
  const client = useApolloClient();
  const {
    activeAllocationProject,
    desiredInputAsDelta,
    enableVacantRoleWithUpdate,
  } = useLookupData();
  const dispatch = useDispatch();
  const saveRequestedFte = useSaveMacroAllocation(client, dispatch);

  const handleRemove = async () => {
    await client.mutate({
      mutation: removePlaceholderPerson,
      variables: {
        personId: person.aggregateId,
      },
    });

    const targetGroupId = lineItem.grouping.groupId;

    const allocation = findAllocation(person.allocations, targetGroupId);
    const requestorCell = lineItem.cells[1];

    // only update the requested when the flag enableVacantRoleWithUpdate is on
    // and if fte delta enabled, there is no need to change requested
    let requested = requestorCell.value;

    if (enableVacantRoleWithUpdate && !desiredInputAsDelta) {
      requested = requestorCell.value - allocation.fte;
    }

    await saveRequestedFte({
      cellId: requestorCell.id,
      allocationProjectId: activeAllocationProject.id,
      sourceGroupId: lineItem.groupId,
      targetGroupId,
      requested,
      realtimeCurrentMemberFte:
        requestorCell.realtimeCurrentMemberFte - allocation.fte,
    });

    // Need to put some delay to allow the disabled flag processed in back end
    setTimeout(() => {
      // Refetch current member rows
      client.refetchQueries({
        include: [
          {
            query: peopleSearchQuery,
            variables: searchVariables,
          },
        ],
      });
    }, 500);
  };

  return (
    <ActionsMenuItem onClick={handleRemove}>{`Remove ${
      vacantRoleLabel || "vacant role"
    }`}</ActionsMenuItem>
  );
};

RemoveVacantRoleButton.propTypes = {
  person: PropTypes.object.isRequired,
  lineItem: PropTypes.object,
  vacantRoleLabel: PropTypes.string,
  searchVariables: PropTypes.object,
};

export default RemoveVacantRoleButton;
