import { getObjectiveLabel } from "src/util/objectives";

const { get, isEmpty } = require("lodash");
const { isRequestorPlannerReadonly } = require("../../../util/readonlyFlags");

const getPopoverText = ({
  strategyLabel,
  selectedTypes,
  showOnlyLinkedObjectives,
  canEditObjectives,
  isSupplyAndNotLineOfBusiness,
  requestorPlannerReadonly,
}) => {
  if (!canEditObjectives) {
    return `Link ${strategyLabel} is disabled for this group type, ${strategyLabel} can only be updated via import.`;
  }

  if (isSupplyAndNotLineOfBusiness) {
    return `Link ${strategyLabel} is disabled if the group is a Supply group and not Line of Business type.`;
  }

  if (requestorPlannerReadonly) {
    return `Link ${strategyLabel} is disabled for requestor planner`;
  }

  if (isEmpty(selectedTypes) && !showOnlyLinkedObjectives) {
    return `${strategyLabel} types and linkages are not enabled for this group type. `;
  }

  return "";
};

const getLinkObjectiveReadOnly = ({
  workspace,
  grouping,
  groupTypes,
  activeAllocationProject,
}) => {
  const strategyLabel = getObjectiveLabel(workspace);
  const selectedTypes = get(
    groupTypes[grouping?.group?.type],
    `planning.objectiveTypes`,
    []
  );

  const requestorPlannerReadonly = isRequestorPlannerReadonly(
    activeAllocationProject
  );

  const showOnlyLinkedObjectives = !!get(
    groupTypes[grouping?.group?.type],
    `planning.showOnlyLinkedObjectives`
  );

  return {
    readOnly:
      !grouping.canEditObjectives ||
      (grouping.root.isSupply && !grouping.root.isLineOfBusiness) ||
      requestorPlannerReadonly ||
      (isEmpty(selectedTypes) && !showOnlyLinkedObjectives),
    text: getPopoverText({
      strategyLabel,
      selectedTypes,
      showOnlyLinkedObjectives,
      canEditObjectives: grouping.canEditObjectives,
      isSupplyAndNotLineOfBusiness:
        grouping.root.isSupply && !grouping.root.isLineOfBusiness,
      requestorPlannerReadonly,
    }),
  };
};

export { getLinkObjectiveReadOnly };
