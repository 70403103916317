import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Avatar } from "orcs-design-system";

import { AvatarWithGradient } from "src/styles/gradient";
import { useGlobalConfig } from "src/contexts/global/GlobalContext";
import { getGroupBgImg } from "src/util/group";
import { getTeamGradient } from "src/util/gradient";

const GroupAvatar = ({ group, ...props }) => {
  const globalConfig = useGlobalConfig();

  const image = useMemo(() => {
    return getGroupBgImg(group);
  }, [group]);

  const bgGradient = useMemo(() => {
    return !image && getTeamGradient(group, globalConfig);
  }, [image, group, globalConfig]);

  const AvatarComponent = useMemo(() => {
    return image ? Avatar : AvatarWithGradient;
  }, [image]);

  return (
    <AvatarComponent
      shape="square"
      image={image}
      imageAlt={`Avatar of ${group.name}`}
      bgGradient={bgGradient}
      {...props}
    />
  );
};

GroupAvatar.propTypes = {
  group: PropTypes.object,
};

export default GroupAvatar;
