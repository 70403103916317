import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { map, size, filter, isNil } from "lodash";
import { Divider } from "orcs-design-system";

import { useLookupData } from "../../../context/ForecastContext";
import AddRoleLineItem from "../../Shared/AddRoleLineItem";
import { canHaveDirectReports } from "../../util/canHaveDirectReports";
import LineItemsExpandHeader from "./LineItemsExpandHeader";
import LineItem from "./LineItem";

const LineItems = ({
  grouping,
  isExpanded,
  expandedMemberListIds,
  dispatch,
  hideApproverColumn,
  shouldShowCost,
  searchMode,
  groupSearchMode,
  searchResult,
}) => {
  const {
    enableMembersInPlanner,
    enableAddNewRole,
    groupTypesLookup,
    enableMemberFteEdit,
    hideBudgetColumn,
  } = useLookupData();
  const { lineItems, canAddLineItem, canToggleLineItems } = grouping;
  const { shouldShowDirectReports, disableToAddMemberOrChapter } =
    canHaveDirectReports({
      groupTypesLookup,
      grouping,
    });

  let filteredLineItems = lineItems;
  if (
    (searchMode || groupSearchMode) &&
    searchResult &&
    !isNil(searchResult.targetLineItems)
  ) {
    filteredLineItems = filter(
      lineItems,
      (l) => searchResult.targetLineItems[l.id]
    );
  }

  return (
    <>
      {canToggleLineItems && shouldShowDirectReports && (
        <LineItemsExpandHeader
          grouping={grouping}
          isExpanded={isExpanded}
          dispatch={dispatch}
          hideApproverColumn={hideApproverColumn}
          enableMembersInPlanner={enableMembersInPlanner}
        />
      )}
      {isExpanded && (
        <>
          {map(filteredLineItems, (lineItem, index) => (
            <Fragment key={lineItem.groupId}>
              <LineItem
                enableMemberFteEdit={enableMemberFteEdit}
                isForecast={false}
                lineItem={lineItem}
                dispatch={dispatch}
                isMembersExpanded={expandedMemberListIds[lineItem.id]}
                shouldShowCost={shouldShowCost}
                hideApproverColumn={hideApproverColumn}
                hideBudgetColumn={hideBudgetColumn}
                enableMembersInPlanner={enableMembersInPlanner}
              />
              {index !== size(lineItems) - 1 && <Divider spanGrid />}
            </Fragment>
          ))}
          {enableAddNewRole &&
            canAddLineItem &&
            !disableToAddMemberOrChapter && (
              <>
                <Divider spanGrid />
                <AddRoleLineItem grouping={grouping} />
              </>
            )}
        </>
      )}
    </>
  );
};

LineItems.propTypes = {
  grouping: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  expandedMemberListIds: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  hideApproverColumn: PropTypes.bool,
  shouldShowCost: PropTypes.bool,
  searchMode: PropTypes.bool,
  groupSearchMode: PropTypes.bool,
  searchResult: PropTypes.object,
};

export default LineItems;
