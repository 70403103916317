import React from "react";
import { Box } from "orcs-design-system";
import PropTypes from "prop-types";

const Heading = ({ sectionHeading }) => {
  return (
    <Box mb="s">
      <strong>{sectionHeading}</strong>
    </Box>
  );
};

Heading.propTypes = {
  sectionHeading: PropTypes.string,
};

export default Heading;
