import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import PATHS, { TEAM_TAB } from "src/consts/urlPaths";
import AllocationsUnavailablePage from "src/allocation/pages/AllocationsUnavailablePage";
import TeamBuilderApproverPage from "src/allocation/pages/TeamBuilderPage/Approver";
import TeamBuilderRequestorPage from "src/allocation/pages/TeamBuilderPage/Requestor";
import ForecastPage from "src/allocation/pages/ForecastPage";
import {
  FORECAST,
  PLANNER,
} from "src/allocation/pages/ForecastPage/context/reducer/pageModes";
import getAllocationVisibility from "src/allocation/util/getAllocationVisibility";
import RestrictedRoute from "src/app/Restricted/Route";

const AllocationsPermitted = ({
  group,
  allocationProject,
  tabType,
  allocationVisibility,
  children,
}) => {
  const isValidRoute =
    (tabType === TEAM_TAB.REQUEST_ALLOCATION_NUMBERS &&
      allocationVisibility.requestNumbers2) ||
    (tabType === TEAM_TAB.APPROVE_ALLOCATION_NUMBERS &&
      allocationVisibility.approveNumbers2) ||
    (tabType === TEAM_TAB.INDIVIDUAL_ALLOCATIONS &&
      (allocationVisibility.individualAllocations ||
        allocationVisibility.requestPeople)) ||
    (tabType === TEAM_TAB.FORECAST && allocationVisibility.forecastNumbers);

  if (
    allocationVisibility.isDeniedAccess ||
    allocationVisibility.isAllocationsClosed ||
    !isValidRoute
  ) {
    return (
      <AllocationsUnavailablePage
        allocationProject={allocationProject}
        group={group}
        allocationVisibility={allocationVisibility}
        isInvalidRoute={!isValidRoute}
      />
    );
  }

  return children;
};

AllocationsPermitted.propTypes = {
  group: PropTypes.object.isRequired,
  allocationVisibility: PropTypes.object.isRequired,
  allocationProject: PropTypes.object.isRequired,
  tabType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const allocationTabRoutes = (
  group,
  allocationProject,
  featureFlags,
  workspace
) => {
  const config = get(workspace, "config", {});
  const allocationConfig = get(config, "allocation", {});
  const allocationVisibility = getAllocationVisibility({
    config,
    group,
    activeAllocationProject: allocationProject,
  });

  return [
    <RestrictedRoute
      path={[
        `${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.REQUEST_ALLOCATION_NUMBERS}`,
        `${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.PLANNER}`, // TODO: Temporarily added as per customer request. Happy to remove after 3-4 months.
      ]}
      key={TEAM_TAB.REQUEST_ALLOCATION_NUMBERS}
    >
      <AllocationsPermitted
        group={group}
        allocationProject={allocationProject}
        tabType={TEAM_TAB.REQUEST_ALLOCATION_NUMBERS}
        allocationVisibility={allocationVisibility}
      >
        <ForecastPage
          workspace={workspace}
          featureFlags={featureFlags}
          group={group}
          pageMode={PLANNER}
          allocationProject={allocationProject}
        />
      </AllocationsPermitted>
    </RestrictedRoute>,
    <RestrictedRoute
      path={`${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.APPROVE_ALLOCATION_NUMBERS}`}
      key={TEAM_TAB.APPROVE_ALLOCATION_NUMBERS}
    >
      <AllocationsPermitted
        group={group}
        allocationProject={allocationProject}
        tabType={TEAM_TAB.APPROVE_ALLOCATION_NUMBERS}
        allocationVisibility={allocationVisibility}
      >
        <ForecastPage
          workspace={workspace}
          featureFlags={featureFlags}
          group={group}
          pageMode={PLANNER}
          allocationProject={allocationProject}
        />
      </AllocationsPermitted>
    </RestrictedRoute>,
    <RestrictedRoute
      path={`${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.INDIVIDUAL_ALLOCATIONS}`}
      key={TEAM_TAB.INDIVIDUAL_ALLOCATIONS}
    >
      <AllocationsPermitted
        group={group}
        allocationProject={allocationProject}
        tabType={TEAM_TAB.INDIVIDUAL_ALLOCATIONS}
        allocationVisibility={allocationVisibility}
      >
        {allocationVisibility.requestPeople && (
          <TeamBuilderRequestorPage
            group={group}
            allocationProject={allocationProject}
            allocationConfig={allocationConfig}
          />
        )}
        {allocationVisibility.individualAllocations && (
          <TeamBuilderApproverPage
            group={group}
            allocationProject={allocationProject}
            workspace={workspace}
          />
        )}
      </AllocationsPermitted>
    </RestrictedRoute>,
    <RestrictedRoute
      path={`${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.FORECAST}`}
      key={TEAM_TAB.FORECAST}
    >
      <AllocationsPermitted
        group={group}
        allocationProject={allocationProject}
        tabType={TEAM_TAB.FORECAST}
        allocationVisibility={allocationVisibility}
      >
        <ForecastPage
          workspace={workspace}
          featureFlags={featureFlags}
          group={group}
          pageMode={FORECAST}
          allocationProject={allocationProject}
        />
      </AllocationsPermitted>
    </RestrictedRoute>,
    <RestrictedRoute
      path={`${PATHS.TEAM_DETAILS_BASE_PATH}/(${[
        TEAM_TAB.APPROVE_ALLOCATION_PEOPLE,
        TEAM_TAB.REQUEST_ALLOCATION_PEOPLE,
      ].join("|")})`}
      key="noAllocations"
    >
      {null}
    </RestrictedRoute>,
  ];
};

export default allocationTabRoutes;
