import React, { useState } from "react";
import PropTypes from "prop-types";
import { get, set } from "lodash";
import {
  TextInput,
  Button,
  P,
  Divider,
  Grid,
  Box,
  H2,
  Spacer,
  Small,
} from "orcs-design-system";
import { useMutation } from "@apollo/client";

import ErrorNotification from "src/components/ErrorNotification";
import FancyCard, {
  FancyCardBody,
  FancyCardFooter,
} from "src/components/FancyCard/FancyCard";
import { updateGlobalConfig } from "src/pages/AdminConfiguration/globalConfig.graphql";

import ImageDropZone from "./sub-components/ImageDropZone";
import HeaderPreview from "./sub-components/HeaderPreview";
import TeamBlock from "./sub-components/TeamBlock";

const getInitialValue = (globalConfig) => {
  return {
    appName: globalConfig.appName,
    // Support only one in edit first
    gradient: get(globalConfig, "gradients[0]", ""),
    logoImage: {
      src: get(globalConfig, "logoImage.src", ""),
      alt: get(globalConfig, "logoImage.alt", ""),
      styles: get(globalConfig, "logoImage.styles", ""),
    },
  };
};

const Branding = ({ globalConfig, globalConfigId }) => {
  const [inputValues, setInputValues] = useState(() =>
    getInitialValue(globalConfig)
  );
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const [saveAppConfig] = useMutation(updateGlobalConfig);

  const handleOnChange = (name) => (e) => {
    const newValues = set({ ...inputValues }, name, e.target.value);
    setInputValues(newValues);
  };

  const handleGradientChange = (e) => {
    setInputValues({
      ...inputValues,
      gradient: e.target.value,
    });
  };

  const handleImageFileUpload = (base64Str) => {
    setInputValues({
      ...inputValues,
      logoImage: {
        ...inputValues.logoImage,
        src: base64Str,
      },
    });
  };

  const handleOnSave = async () => {
    setError(null);
    setIsSaving(true);
    try {
      await saveAppConfig({
        variables: {
          input: {
            id: globalConfigId,
            appName: inputValues.appName,
            gradients: [inputValues.gradient],
            logoImage: inputValues.logoImage,
          },
        },
      });
    } catch (e) {
      setError(e);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <FancyCard height="100%">
      <FancyCardBody>
        <Box p="xs">
          <Box mb="r">
            <H2 mb="xs" fontSize="2rem">
              Here you can set some branding for your Directory.
            </H2>
            <Small>
              Note: After making changes and saving, refresh Directory page to
              see changes in action.
            </Small>
          </Box>
          <Divider />
          <Grid gridTemplateColumns="1fr 1fr" gridGap="l">
            <Box pt="r">
              <Spacer mb="r">
                <TextInput
                  name="directoryName"
                  type="text"
                  id="directoryName"
                  label="Directory name"
                  placeholder="Team Directory"
                  value={inputValues.appName}
                  onChange={handleOnChange("appName")}
                />
                <Box>
                  <P>You company logo (svg file only now)</P>
                  <Small>
                    Must be less than 200kb and less than 300px x 150px in size.
                  </Small>
                </Box>
                <ImageDropZone
                  onFileImported={handleImageFileUpload}
                  btnLabel="Upload"
                />
                <TextInput
                  name="directoryLogoAlt"
                  type="text"
                  id="logo-image-alt"
                  label="Directory logo image alt"
                  placeholder="Company name"
                  value={inputValues.logoImage.alt}
                  onChange={handleOnChange("logoImage.alt")}
                />
                <TextInput
                  name="directoryLogoStyles"
                  type="text"
                  id="logo-image-styles"
                  label="Directory logo image styles"
                  placeholder="CSS"
                  fullWidth
                  value={inputValues.logoImage.styles}
                  onChange={handleOnChange("logoImage.styles")}
                />
              </Spacer>
            </Box>
            <Box pt="r">
              <P mb="s">
                <strong>Directory header preview:</strong>
              </P>
              <HeaderPreview config={inputValues} />
            </Box>
          </Grid>

          <Divider />

          <Grid gridTemplateColumns="1fr 1fr" gridGap="l">
            <Box pt="r">
              <Spacer mb="r">
                <P>
                  Here you can set the default cover image background gradient
                </P>
                <TextInput
                  name="gradient"
                  type="text"
                  id="directoryGradient"
                  placeholder="linear-gradient(180deg, #46AFEF 0%, #0B6FAD 100%)"
                  fullWidth
                  value={inputValues.gradient}
                  onChange={handleGradientChange}
                />
              </Spacer>
            </Box>
            <Box pt="r">
              <P mb="s">
                <strong>Cover image gradient preview:</strong>
              </P>
              <TeamBlock gradient={inputValues.gradient} />
            </Box>
          </Grid>
        </Box>
      </FancyCardBody>
      <FancyCardFooter>
        <Button
          onClick={handleOnSave}
          isLoading={isSaving}
          variant={isSaving ? "disabled" : "success"}
        >
          Save changes
        </Button>
        {error && <ErrorNotification error={error} />}
      </FancyCardFooter>
    </FancyCard>
  );
};

Branding.propTypes = {
  globalConfig: PropTypes.object,
  globalConfigId: PropTypes.string,
};

export default Branding;
