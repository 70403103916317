import {
  Box,
  Flex,
  H2,
  Badge,
  Notification,
  FlexItem,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { roundFte } from "src/util/roundingStrategy";
import { getName } from "src/util/person";

const ModalHeader = ({
  person,
  showAlreadyAllocatedNotification,
  viewToggle,
}) => {
  return (
    <>
      {showAlreadyAllocatedNotification && (
        <Box mb="r">
          <Notification closable={false}>
            {getName(person)} has existing allocations, please adjust to your
            requirements.
          </Notification>
        </Box>
      )}
      <Flex alignItems="center">
        <H2 mr="s">Allocations for {getName(person)}</H2>
        <Badge>{roundFte(person.fte)} FTE</Badge>
        {viewToggle ? <FlexItem ml="auto">{viewToggle}</FlexItem> : null}
      </Flex>
    </>
  );
};

ModalHeader.propTypes = {
  person: PropTypes.object,
  showAlreadyAllocatedNotification: PropTypes.bool,
  viewToggle: PropTypes.node,
};

export default ModalHeader;
