/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { keyBy, find } from "lodash";

import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import GroupsSearch from "src/components/GroupsSearch";
import { getSupplyRoleGroupTypes } from "src/util/customerConfig";
import CustomTreeHeader from "./CustomTreeHeader";

const ChapterGroupsSearch = ({
  existingGroups,
  onNodeChecked,
  selectedGroups,
  showHierarchyBreadcrumb,
  activeGroup,
  ignoreDisableGroup,
  hierarchyIds,
}) => {
  const groupTypes = useGroupTypes();

  const targetGroupTypes = useMemo(() => {
    return keyBy(getSupplyRoleGroupTypes(groupTypes), "id");
  }, [groupTypes]);

  const handleOnGroupSelected = () => {};

  const customizeTreeHeader =
    (props = {}) =>
    ({ node }) => {
      if (!node.group) {
        return null;
      }

      const typeConfig = targetGroupTypes[node.group.type];
      const hideCheckbox =
        !typeConfig || typeConfig?.planning?.isExcludedFromNewRole;

      return (
        <CustomTreeHeader
          {...props}
          node={node}
          selectedTargets={selectedGroups}
          onNodeChecked={onNodeChecked}
          showHierarchyBreadcrumb={showHierarchyBreadcrumb}
          active={node.id === activeGroup}
          ignoreDisableGroup={ignoreDisableGroup}
          hideCheckbox={hideCheckbox}
          isDisabled={
            typeConfig && !!find(existingGroups, (eg) => eg.groupId === node.id)
          }
          withLeftSpace
        />
      );
    };

  return (
    <GroupsSearch
      placeholder="Click to search and select a supply team to add"
      actionLabel="Click to add"
      groupTypes={groupTypes}
      targetGroupTypes={targetGroupTypes}
      onGroupSelected={handleOnGroupSelected}
      withIndependentTreeView
      customizeTreeHeader={customizeTreeHeader}
      hierarchyIds={hierarchyIds}
    />
  );
};

ChapterGroupsSearch.propTypes = {
  onNodeChecked: PropTypes.func.isRequired,
  selectedGroups: PropTypes.object,
  showHierarchyBreadcrumb: PropTypes.bool,
  activeGroup: PropTypes.string,
  ignoreDisableGroup: PropTypes.bool,
  hierarchyIds: PropTypes.array,
};

export default ChapterGroupsSearch;
