import { useMemo, useRef } from "react";
import { debounce } from "lodash";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";

const useLoadSuggestions = (loadSuggestions, loadSuggestionsPromise) => {
  const getOptions = useMemo(() => {
    return async (str) => {
      if (loadSuggestions) {
        return Promise.resolve(loadSuggestions(str));
      }
      return loadSuggestionsPromise(str);
    };
  }, [loadSuggestions, loadSuggestionsPromise]);

  const loadOptions = useMemo(() => {
    return (str, cb) => {
      getOptions(str).then((options) => {
        cb(options);
      });
    };
  }, [getOptions]);

  const loadSuggestion = useRef(debounce(loadOptions, DEBOUNCE_SEARCH_TIME));
  return loadSuggestion.current;
};

export default useLoadSuggestions;
