import PropTypes from "prop-types";
import React from "react";
import { Select } from "orcs-design-system";
import { getOptionByValue } from "src/util/selectUtil";

const PrincipleSetSelector = ({
  mutating,
  team,
  setPrincipleSetId,
  principleSets,
}) => {
  const placeholder = "Select a principle set . . .";
  const principleOptions = principleSets.map((p) => ({
    value: p.id,
    label: p.name,
  }));
  return (
    <Select
      isClearable={true}
      disabled={mutating}
      options={principleOptions}
      onChange={(selectedOption, { action }) => {
        if (action === "clear") {
          setPrincipleSetId(team?.id, null);
        } else if (
          selectedOption &&
          selectedOption.value !== team?.principleSetId
        ) {
          setPrincipleSetId(team?.id, selectedOption.value);
        }
      }}
      value={getOptionByValue(principleOptions, team?.principleSetId)}
      placeholder={placeholder}
      ariaLabel={placeholder}
      inputId="principle-select"
    />
  );
};
// TODO: bool naming convention, object shape
PrincipleSetSelector.propTypes = {
  mutating: PropTypes.bool,
  team: PropTypes.object,
  setPrincipleSetId: PropTypes.func,
  principleSets: PropTypes.array,
};

export default PrincipleSetSelector;
