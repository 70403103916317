// Helper

// TODO: consolidate into localStorage service

// assumes local storage value is a JSON object
export const writeToLocalStorage = (localStorageKey, valueKey, value) => {
  const ls = window.localStorage;
  if (ls) {
    const localStorageItem = ls.getItem(localStorageKey);
    const localStorageValue = localStorageItem
      ? JSON.parse(localStorageItem)
      : {};
    const storedValue = JSON.stringify({
      ...localStorageValue,
      [valueKey]: value,
    });
    ls.setItem(localStorageKey, storedValue);
  }
};

// assumes local storage value is a JSON object
export const getLocalStorageItem = (localStorageKey, valueKey = null) => {
  const ls = window.localStorage;
  if (ls && ls.getItem(localStorageKey)) {
    const localStorageValue = JSON.parse(ls.getItem(localStorageKey));
    return valueKey ? localStorageValue[valueKey] : localStorageValue;
  }
  return null;
};

// Auth0

export const localStorageAuth0UserKey = (userEmail) => `auth0-${userEmail}`;

export const saveActiveTenant = (userEmail, tenant) => {
  const auth0UserStorageKey = localStorageAuth0UserKey(userEmail);
  writeToLocalStorage(auth0UserStorageKey, "activeTenant", tenant);
};

export const getActiveTenant = (userEmail) => {
  const auth0UserStorageKey = localStorageAuth0UserKey(userEmail);
  return getLocalStorageItem(auth0UserStorageKey, "activeTenant");
};

// Guided tour
const GUIDED_TOUR_PREFIX = "td.guided-tour-settings";

const localStorageTourKey = (username, tourName) =>
  `${GUIDED_TOUR_PREFIX}-${tourName}-${username}`;

export const saveGuidedTourToLocalStorage = (username, tourName, tourState) => {
  if (window.localStorage) {
    const storageKey = localStorageTourKey(username, tourName);
    // delete recursive, non-important part of tourState that does not stringify
    // eslint-disable-next-line
    delete tourState.step;
    window.localStorage.setItem(storageKey, JSON.stringify(tourState));
  }
};

export const getGuidedTourFromLocalStorage = (username, tourName) => {
  if (window.localStorage) {
    const storageKey = localStorageTourKey(username, tourName);
    try {
      return JSON.parse(window.localStorage.getItem(storageKey));
    } catch {
      return undefined;
    }
  }
  return undefined;
};

export const saveActiveTheme = (themeName) => {
  writeToLocalStorage("theme", "active", themeName);
};

export const getActiveTheme = () => {
  return getLocalStorageItem("theme", "active");
};

export const saveLocalSortOption = (component, value) => {
  writeToLocalStorage("sort", component, value);
};

export const getLocalSortOption = (component) => {
  return getLocalStorageItem("sort", component);
};

export const SORT_INDEX = {
  TAG_TEAMS_PANEL: "TAG_TEAMS_PANEL",
  TAG_MEMBERS_PANEL: "TAG_MEMBERS_PANEL",
  TEAMS_EXPLORE_PANEL: "TEAMS_EXPLORE_PANEL",
  TEAMS_MEMBER_PANEL: "TEAMS_MEMBER_PANEL",
  PERSON_MEMBERSHIP_PANEL: "PERSON_MEMBERSHIP_PANEL",
  TEAM_ASSOCIATION_PANEL: "TEAM_ASSOCIATION_PANEL",
};
