import React from "react";
import {
  Icon,
  Flex,
  FlexItem,
  Divider,
  P,
  StyledLink,
} from "orcs-design-system";

import ErrorPageWrapper from "src/components/ErrorPage/ErrorPageWrapper";
import urlPaths, { url } from "../../consts/urlPaths";

const TeamNotFound = () => {
  const teamLink = (
    <StyledLink bold href={url(urlPaths.TEAMS)}>
      Click here
    </StyledLink>
  );

  // TODO: Error page wrapper has icon - could we use that instead of the supplied one here?
  return (
    <ErrorPageWrapper showHeader={false} showIcon={false}>
      <Flex alignItems="center">
        <Icon
          icon={["fas", "exclamation-triangle"]}
          size="2x"
          mr="r"
          color="warning"
        />
        <FlexItem>
          <P>Sorry, this team does not exist.</P>
          <P>{teamLink} to return to the team search page.</P>
        </FlexItem>
      </Flex>
      <Divider />
    </ErrorPageWrapper>
  );
};

export default TeamNotFound;
