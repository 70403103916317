import { Flex } from "orcs-design-system";
import React from "react";
import { map } from "lodash";
import { ACTION_TYPES } from "src/components/OrgChart/context/actionTypes";
import { useOrgChartContext } from "src/components/OrgChart/context/OrgChartContext";
import { ENTITY_TYPES } from "src/consts/comments";
import {
  useTagTypes,
  useWorkspaceTagConfig,
} from "src/contexts/global/WorkspaceContext";
import { storeOrgChartProperties } from "../../../../utils/storeInLocalStorage";
import PropertySelector from "./components/PropertySelector";
import { getOptions } from "./utils/getOptions";

const Properties = () => {
  const tagConfig = useWorkspaceTagConfig();
  const tagTypes = useTagTypes();
  const { state, dispatch } = useOrgChartContext();

  const handleUpdate = (entityType, value) => {
    const stateKey =
      entityType === ENTITY_TYPES.PERSON ? "peopleData" : "teamsData";
    storeOrgChartProperties({ entityType, value });
    dispatch({
      type: ACTION_TYPES.UPDATE_PROPERTIES,
      payload: { [stateKey]: value },
    });
  };

  const onSelect = (entityType) => (value) => {
    const updatedValue = map(value, (item) => ({
      ...item,
      data: {
        ...item.data,
        isSelected: true,
      },
    }));
    handleUpdate(entityType, updatedValue);
  };

  const onRemove = (entityType) => (tagType) => {
    const stateKey =
      entityType === ENTITY_TYPES.PERSON ? "peopleData" : "teamsData";
    const updatedValue = map(state?.properties?.[stateKey], (item) => {
      if (item.value === tagType) {
        return {
          ...item,
          data: {
            ...item.data,
            isSelected: false,
          },
        };
      }
      return item;
    });
    handleUpdate(entityType, updatedValue);
  };

  return (
    <Flex flexDirection="column">
      <Flex>
        <small>
          Here you can select or remove different data points to show on person
          or team items
        </small>
      </Flex>
      <PropertySelector
        options={getOptions({
          tagTypes,
          tagConfig,
          entityType: ENTITY_TYPES.PERSON,
        })}
        value={state?.properties?.peopleData}
        onSelect={onSelect(ENTITY_TYPES.PERSON)}
        onRemove={onRemove(ENTITY_TYPES.PERSON)}
        entityType={ENTITY_TYPES.PERSON}
      />
      <PropertySelector
        options={getOptions({
          tagTypes,
          tagConfig,
          entityType: ENTITY_TYPES.GROUP,
        })}
        value={state?.properties?.teamsData}
        onSelect={onSelect(ENTITY_TYPES.GROUP)}
        onRemove={onRemove(ENTITY_TYPES.GROUP)}
        entityType={ENTITY_TYPES.GROUP}
      />
    </Flex>
  );
};

export default Properties;
