import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Popover, TextInput } from "orcs-design-system";
import { get } from "lodash";
import {
  setAllocationDirectConstraint,
  setAllocationHierarchyConstraint,
} from "src/allocation/allocation.graphql";

import {
  ACTIONS,
  SAVE_STATUS,
  useAllocationProjects,
} from "../../context/ForecastContext";

import FailedIndicator from "../Shared/FailedIndicator";
import useSaveMutation from "../Shared/useSaveMutation";
import getConstraintPopoverContent from "../util/getConstraintPopoverContent";
import { getGroupId } from "./constraintInput.util";

const ConstraintInputWrapper = styled.div`
  width: 60px;
  position: relative;
`;

const NumberInput = styled(TextInput)`
  width: 60px;
  padding: 5px 8px 6px 8px;
`;

const ConstraintInput = ({ constraintCell, dispatch }) => {
  const {
    id,
    constraint,
    defaultedConstraint,
    isDefaultFromInput,
    constraintSaveStatus,
    allocationProjectId,
    grouping,
    isDirectConstraint,
  } = constraintCell;

  const allocationProjects = useAllocationProjects();
  const allocationProject = get(allocationProjects, allocationProjectId);
  const { enableDecimalFTEInput } = allocationProject;

  const onConstraintInputChange = (newConstraint) => {
    dispatch({
      type: ACTIONS.USER_CONSTRAINT_INPUT_UPDATE,
      cellId: id,
      constraint: newConstraint,
    });
  };

  const onConstraintSaveStatusChange = (status) => {
    dispatch({
      type: ACTIONS.CONSTRAINT_SAVE_STATUS_UPDATE,
      cellId: id,
      status,
    });
  };

  const [updateConstraintValue] = useSaveMutation(
    constraint,
    isDirectConstraint
      ? setAllocationDirectConstraint
      : setAllocationHierarchyConstraint,
    onConstraintSaveStatusChange
  );

  const onConstraintInputClick = (e) => {
    e.stopPropagation();
  };

  const onValueChange = ({ floatValue = null }) => {
    if (constraint !== floatValue) {
      onConstraintInputChange(floatValue);
    }
  };

  const onInputBlur = () => {
    const baseVariables = {
      allocationProjectId,
      groupId: getGroupId(grouping),
    };
    const valueVariable = isDirectConstraint
      ? { directConstraint: constraint }
      : { hierarchyConstraint: constraint };
    updateConstraintValue(constraint, { ...baseVariables, ...valueVariable });
  };

  // Giving isValid and isInvalid a string value to remove warnings from React
  const isValid = constraintSaveStatus === SAVE_STATUS.SUCCESS;
  const isInvalid = constraintSaveStatus === SAVE_STATUS.FAILED;
  const popoverContent = getConstraintPopoverContent(
    constraint,
    isDefaultFromInput
  );

  const numberProps = {
    allowNegative: false,
    decimalScale: enableDecimalFTEInput ? 2 : 0,
    onValueChange,
  };

  return (
    <ConstraintInputWrapper onClick={onConstraintInputClick}>
      <FailedIndicator isFailed={isInvalid} retry={onInputBlur} />
      <Popover
        direction="bottomLeft"
        width="200px"
        textAlign="left"
        text={popoverContent}
      >
        <NumberInput
          height="32px"
          placeholder={`${defaultedConstraint}`}
          value={constraint}
          data-testid="constraint-input"
          valid={isValid || undefined}
          invalid={isInvalid || undefined}
          numberProps={numberProps}
          onBlur={onInputBlur}
        />
      </Popover>
    </ConstraintInputWrapper>
  );
};

ConstraintInput.propTypes = {
  constraintCell: PropTypes.object,
  dispatch: PropTypes.func,
};

export default ConstraintInput;
