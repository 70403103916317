import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Flex, FlexItem } from "orcs-design-system";

import { includes, isEmpty, filter } from "lodash";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import { useTagTypes } from "src/contexts/global/WorkspaceContext";

import HighlightedGroupTags from "src/components/HighlightedGroupTags";
import {
  ACTIONS,
  useFinancialForecasting,
} from "../../../context/ForecastContext";
import useGridConfig from "../../util/gridConfig";
import CustomGrid from "../../Shared/CustomGrid";
import TreeExpand from "../../Shared/TreeExpand";
import FteQueryBadgeForSupply from "../../Shared/FteQueryBadgeForSupply";
import GroupName from "../../Shared/GroupName";
import CostAndBudgetCell from "../../Shared/CostAndBudgetCell";
import GroupingActionMenu from "../../Shared/GroupingActionMenu";

import GroupingTotals from "./GroupingTotals";
import MovedGroupBadge from "./MovedGroupBadge";

const getPlannerConfig = (gridConfig, isLoB, isRoot) => {
  return isLoB && !isRoot
    ? [gridConfig.lineOfBusiness.templateColumns, gridConfig.lineOfBusiness.gap]
    : [gridConfig.planner.templateColumns.expandHeader, gridConfig.planner.gap];
};

const GroupingExpandHeader = ({
  grouping,
  isExpanded,
  viewMode,
  dispatch,
  withNotification = true,
  hideApproverColumn = false,
}) => {
  const { groupBadge: highlightedTagTypes } = useTagTypes();
  const shouldShowCost = useFinancialForecasting();

  const {
    id,
    group,
    nestedLevel,
    canToggleLineItems,
    isSupply,
    root,
    isMovedIn,
    isMovedOut,
    isMovedInternally,
    isLineOfBusiness,
    tags,
  } = grouping;
  const { isSupply: isRootGroupSupply, isDemand: isRootGroupDemand } = root;

  const [templateColumns, gap] = getPlannerConfig(
    useGridConfig(),
    isLineOfBusiness,
    isRootGroupDemand
  );

  const handleToggle = useCallback(() => {
    dispatch({
      type: ACTIONS.TOGGLE_GROUPING_TREE_EXPANSION,
      id,
    });
  }, [id, dispatch]);

  const highlightedTags = useMemo(
    () => filter(tags, (t) => includes(highlightedTagTypes, t.type)),
    [highlightedTagTypes, tags]
  );

  const maxWidthForGroupName = isEmpty(highlightedTags) ? "500px" : "700px";

  return (
    <TreeExpand
      hasConnectedTreeExpand={canToggleLineItems}
      isOpen={isExpanded}
      indentLevel={nestedLevel}
      onToggle={handleToggle}
      hasToggle={!isMovedOut}
    >
      <CustomGrid
        gridTemplateColumns={templateColumns}
        gridGap={gap}
        className="guided-tour-allocation-planner-grouping-header"
      >
        <Flex flexDirection="column" maxWidth={maxWidthForGroupName}>
          <Flex alignItems="center">
            <GroupName group={group} isMovedOut={isMovedOut} />
            <FlexItem flex="0 0 auto" ml="xs">
              <GroupTypeBadge
                group={group}
                supplyHasOwnColour
                showToolTip
                toolTipPosition="bottomRight"
              />
            </FlexItem>
            {isRootGroupSupply && isSupply && (
              <FlexItem flex="0 0 auto" ml="xs">
                <FteQueryBadgeForSupply isSupply={isSupply} group={group} />
              </FlexItem>
            )}
            {(isMovedOut || isMovedIn || isMovedInternally) && (
              <MovedGroupBadge grouping={grouping} />
            )}
          </Flex>
          {!isEmpty(highlightedTags) && (
            <HighlightedGroupTags team={{ ...group, tags }} small mt="xs" />
          )}
        </Flex>
        {shouldShowCost && (
          <CostAndBudgetCell
            grouping={grouping}
            rootGroup={root}
            viewMode={viewMode}
            hideBudgetTooltip={false}
          />
        )}
        <GroupingTotals
          grouping={grouping}
          withNotification={withNotification}
          hideApproverColumn={hideApproverColumn}
        />
        <GroupingActionMenu grouping={grouping} dispatch={dispatch} />
      </CustomGrid>
    </TreeExpand>
  );
};

GroupingExpandHeader.propTypes = {
  grouping: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool,
  viewMode: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  withNotification: PropTypes.bool,
  hideApproverColumn: PropTypes.bool,
};

export default GroupingExpandHeader;
