import { reduce } from "lodash";
import { useState } from "react";

const useSelectAll = (members = [], setSelectedMembers) => {
  const [allSelectMembers, setAllSelectMembers] = useState(false);
  const handleAllSelectMembersClick = () => {
    const prev = allSelectMembers;
    setAllSelectMembers(!prev);
    if (prev) {
      setSelectedMembers({});
    } else {
      setSelectedMembers(
        reduce(
          members,
          (acc, next) => {
            acc[next.aggregateId] = next;
            return acc;
          },
          {}
        )
      );
    }
  };

  return {
    handleAllSelectMembersClick,
    allSelectMembers,
  };
};

export default useSelectAll;
