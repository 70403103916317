import { H3 } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import FancyCard, {
  FancyCardHeader,
  FancyCardBody,
  FancyCardFooter,
} from "src/components/FancyCard/FancyCard";

import IndividualPersonAllocationContainer from "./IndividualPersonAllocationContainer";

const Layout = (...children) => {
  return (
    <FancyCard height="100%" maxHeight="90%">
      <FancyCardHeader>
        <H3 bold>Allocations</H3>
      </FancyCardHeader>
      <FancyCardBody>
        {children[0]}
        {children[1]}
      </FancyCardBody>
      <FancyCardFooter>{children[2]}</FancyCardFooter>
    </FancyCard>
  );
};

const IndividualPersonAllocationPage = ({
  allocationProjectId,
  personId,
  personFte,
  onPersonAllocating,
  onPersonAllocated,
  onPersonUnallocated,
  onPersonAllocationUpdated,
  layoutFunc = Layout,
  groupTypes,
  workspace,
  infoMessage,
}) => {
  return (
    <IndividualPersonAllocationContainer
      allocationProjectId={allocationProjectId}
      personId={personId}
      personFte={personFte}
      onPersonAllocating={onPersonAllocating}
      onPersonAllocated={onPersonAllocated}
      onPersonUnallocated={onPersonUnallocated}
      onPersonAllocationUpdated={onPersonAllocationUpdated}
      layoutFunction={layoutFunc}
      groupTypes={groupTypes}
      workspace={workspace}
      infoMessage={infoMessage}
    />
  );
};

IndividualPersonAllocationPage.propTypes = {
  allocationProjectId: PropTypes.string,
  personId: PropTypes.string,
  personFte: PropTypes.number,
  onPersonAllocating: PropTypes.func,
  onPersonAllocated: PropTypes.func,
  onPersonUnallocated: PropTypes.func,
  onPersonAllocationUpdated: PropTypes.func,
  layoutFunc: PropTypes.func,
  groupTypes: PropTypes.object,
  workspace: PropTypes.object,
  infoMessage: PropTypes.string,
};

export default IndividualPersonAllocationPage;
