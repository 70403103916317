import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon, Button } from "orcs-design-system";

import {
  usePageMode,
  useColumns,
  useLookupData,
  useDispatch,
  ACTIONS,
} from "../../context/ForecastContext";
import { hasEditableProjects } from "../util/readonlyFlags";

const AddRoleContainer = styled.div`
  margin: 16px;
`;

const AddRoleLineItem = ({ grouping, macroAllocationType = null }) => {
  const { groupId } = grouping;
  const pageMode = usePageMode();
  const columns = useColumns();
  const { supplyLabel, activeAllocationProject, groupLookup, parentGroupId } =
    useLookupData();
  const dispatch = useDispatch();

  const handleAddRole = useCallback(() => {
    dispatch({
      type: ACTIONS.SHOW_ADD_ROLE_DIALOG,
      grouping,
      parentSourceGroupId: groupLookup[parentGroupId].isSource
        ? parentGroupId
        : null,
      targetGroupId: groupId,
      macroAllocationType,
    });
  }, [
    parentGroupId,
    groupId,
    grouping,
    groupLookup,
    dispatch,
    macroAllocationType,
  ]);

  const isDisabled = useMemo(
    () => !hasEditableProjects(pageMode, activeAllocationProject, columns),
    [pageMode, activeAllocationProject, columns]
  );

  return (
    <AddRoleContainer>
      <Button
        small
        iconLeft
        data-testid="add-role-button"
        onClick={handleAddRole}
        className="guided-tour-allocation-forecast-add-role"
        disabled={isDisabled}
      >
        <Icon icon={["fas", "plus"]} />
        {`Add ${supplyLabel}`}
      </Button>
    </AddRoleContainer>
  );
};

AddRoleLineItem.propTypes = {
  grouping: PropTypes.object.isRequired,
  macroAllocationType: PropTypes.bool,
};

export default AddRoleLineItem;
