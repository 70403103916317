import React from "react";
import PropTypes from "prop-types";

import { DndProvider } from "react-dnd";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import {
  MultiBackend,
  Preview as DndTouchPreview,
} from "react-dnd-multi-backend";

import PersonDetailsTouchPreview from "./components/PeopleList/PersonDetailsTouchPreview";

const PersonDetailDndTouchPreview = ({ item, style }) => {
  return <PersonDetailsTouchPreview item={item} style={style} />;
};

PersonDetailDndTouchPreview.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
};

// a helpful simple HOC to easily provide the context
const withMainDnDProvider = (WrappedComponent) => {
  // eslint-disable-next-line react/prefer-stateless-function, react/display-name
  return class extends React.Component {
    render() {
      return (
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          <WrappedComponent />
          <DndTouchPreview generator={PersonDetailDndTouchPreview} />
        </DndProvider>
      );
    }
  };
};

export default withMainDnDProvider;
