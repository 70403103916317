import React from "react";
import PropTypes from "prop-types";
import { ActionsMenu } from "orcs-design-system";

import { get } from "lodash";
import { isRequestorPlannerReadonly } from "../../util/readonlyFlags";
import {
  useLookupData,
  useViewMode,
  usePageMode,
  VIEW_MODES,
  PAGE_MODES,
} from "../../../context/ForecastContext";
import TeamDetailsButton from "../../Shared/TeamDetailsButton";
import { canHaveDirectReports } from "../../util/canHaveDirectReports";
import AddVacantRoleRow from "./AddVacantRoleRow";
import OpenDetailsLink from "./OpenDetailsLink";
import OpenInTeamBuilderLink from "./OpenInTeamBuilderLink";
import RenameTeamButton from "./RenameTeamButton";
import HideTeamButton from "./HideTeamButton";
import AddMemberButton from "./AddMemberButton";

const LineItemActionMenu = ({ lineItem, dispatch }) => {
  const {
    disableGroupTeamBuilder,
    activeAllocationProject,
    enableMemberAllocation,
    enableAddVacantRole,
    enableAddMember,
    groupTypesLookup,
  } = useLookupData();
  const viewMode = useViewMode();
  const pageMode = usePageMode();

  const isPageEditable = !isRequestorPlannerReadonly(activeAllocationProject);
  const isPlannerDemandView =
    pageMode === PAGE_MODES.PLANNER &&
    viewMode === VIEW_MODES.GROUPED_BY_DEMAND &&
    enableMemberAllocation;

  const isMovedOut = get(lineItem, "grouping.isMovedOut", false);
  const { disableToAddMemberOrChapter } = canHaveDirectReports({
    groupTypesLookup,
    grouping: get(lineItem, "grouping", {}),
  });

  const canAddMember =
    isPageEditable &&
    enableAddMember &&
    isPlannerDemandView &&
    !disableToAddMemberOrChapter;
  const canAddVacantRole =
    isPageEditable && enableAddVacantRole && isPlannerDemandView;

  return (
    <ActionsMenu direction="left-start" ml="auto">
      <OpenDetailsLink lineItem={lineItem} />
      {!isMovedOut && !disableGroupTeamBuilder && (
        <OpenInTeamBuilderLink lineItem={lineItem} />
      )}
      {!isMovedOut && (
        <TeamDetailsButton
          group={lineItem.group}
          dispatch={dispatch}
          isSupply={lineItem.isSupply}
          isDemand={lineItem.isDemand}
          isPageEditable={isPageEditable}
        />
      )}
      {!isMovedOut && isPageEditable && (
        <RenameTeamButton lineItem={lineItem} dispatch={dispatch} />
      )}
      {!isMovedOut && isPageEditable && (
        <HideTeamButton lineItem={lineItem} dispatch={dispatch} />
      )}
      {!isMovedOut && canAddMember && (
        <AddMemberButton lineItem={lineItem} dispatch={dispatch} />
      )}
      {!isMovedOut && canAddVacantRole && (
        <AddVacantRoleRow lineItem={lineItem} dispatch={dispatch} />
      )}
    </ActionsMenu>
  );
};

LineItemActionMenu.propTypes = {
  lineItem: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default LineItemActionMenu;
