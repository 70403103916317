/* eslint-disable no-param-reassign */
import { forEach, has, isEmpty, uniqBy, values } from "lodash";
import {
  addToChildren,
  canHaveChildren,
  shouldIgnoreGroupByType,
} from "src/shared/TreeView/TreeView.util";

const { OBJECTIVE_GROUP_KIND } = require("src/consts/objectives");

export const shouldIgnoreOnToggle = (node) => {
  return node.group?.kind === OBJECTIVE_GROUP_KIND;
};

export const createTreeNode = (group, groupTypes, parentNode) => {
  const { id, name, type } = group;

  const isParentType =
    canHaveChildren(type, groupTypes) || !isEmpty(group.objectives);

  const isLoaded =
    group.kind === OBJECTIVE_GROUP_KIND
      ? true
      : isParentType
      ? has(group, "childTeams")
      : true;

  return {
    id,
    name,
    group,
    active: false,
    toggled: false,
    isParentType,
    loading: !isLoaded,
    children: null,
    parentNode,
  };
};

const shouldSetChildrenToObjectives = (parent) => {
  return (
    parent?.group?.kind === OBJECTIVE_GROUP_KIND ||
    (!parent.isParentType && isEmpty(parent.children))
  );
};

const setChildrenToObjectives = (parent, objectives) => {
  if (Object?.isExtensible(parent)) {
    parent.children = !isEmpty(objectives) ? objectives : null;
  }
};

const mergeChildrenAndObjectives = ({
  parent,
  objectives,
  convertChildrenToArray,
}) => {
  parent.children = uniqBy(
    [...values(parent.children), ...(objectives || [])],
    "id"
  );
  parent.children.forEach((child) => convertChildrenToArray(child));
};

export const convertChildrenToArray = (parent) => {
  const objectives = parent?.group?.objectives;
  if (shouldSetChildrenToObjectives(parent)) {
    setChildrenToObjectives(parent, objectives);
  } else {
    mergeChildrenAndObjectives({ parent, objectives, convertChildrenToArray });
  }
  return parent;
};

export const addGroupsToRoots = (
  root,
  groups,
  groupTypes,
  targetGroupTypes,
  createTreeNodeImpl
) => {
  forEach(groups, (group) => {
    const { type, kind } = group;
    if (
      kind !== OBJECTIVE_GROUP_KIND &&
      shouldIgnoreGroupByType(type, targetGroupTypes)
    ) {
      return;
    }
    const groupNode = addToChildren(
      root,
      group,
      groupTypes,
      createTreeNodeImpl
    );
    const { childTeams, objectives } = group;
    forEach(childTeams, (child) => {
      if (
        child.kind !== OBJECTIVE_GROUP_KIND &&
        shouldIgnoreGroupByType(child.type, targetGroupTypes)
      ) {
        return;
      }
      addToChildren(groupNode, child, groupTypes, createTreeNodeImpl);
    });
    forEach(objectives, (child) => {
      addToChildren(groupNode, child, groupTypes, createTreeNodeImpl);
    });
  });
};
