import React from "react";
import PropTypes from "prop-types";
import { isEmpty, isFinite } from "lodash";

import NotificationDot from "../../Shared/NotificationDot";
import ReadonlyNotification from "./ReadonlyNotification";
import InformationNotification from "./InformationNotification";

const ColumnNotification = ({
  isColumnReadonly,
  showInformationIcon,
  notification,
  allocationProject,
  showTotalCost,
}) => {
  if (isColumnReadonly) {
    return <ReadonlyNotification />;
  }

  if (showTotalCost) {
    const { workingDays, isActive } = allocationProject;
    if (!isActive && (workingDays <= 0 || !isFinite(workingDays))) {
      return <InformationNotification variant="danger" />;
    }
  }

  if (showInformationIcon && isEmpty(notification)) {
    return <InformationNotification />;
  }

  return (
    <NotificationDot
      notification={notification}
      isColumn
      showTooltip={false}
      direction="bottomRight"
    />
  );
};

ColumnNotification.propTypes = {
  isColumnReadonly: PropTypes.bool,
  showInformationIcon: PropTypes.bool,
  notification: PropTypes.array,
  allocationProject: PropTypes.object,
  showTotalCost: PropTypes.bool,
};

export default ColumnNotification;
