import { isEmpty, map } from "lodash";
import {
  Box,
  Button,
  Avatar,
  Flex,
  FlexItem,
  Badge,
  Spacer,
} from "orcs-design-system";
import React from "react";

import { OptionValuePropType } from "src/custom-prop-types/search";
import { getSupplyBadgeGroups, getDemandGroups } from "src/util/group";
import { displayInitials, fullDisplayName } from "src/util/personName";
import { copywriting } from "src/pages/PersonDetailPage/PersonDetailPage.config";
import GroupBadge from "../../Badge/GroupBadge";

const PersonFormatOptionLabel = (
  { value },
  groupTypes = {},
  allocatedOptionLabel,
  allocateOptionLabel,
  showDemandGroupBadge = false,
  hideBadgeForSelectedValue = false,
  context
) => {
  const buttonLabel = allocateOptionLabel || copywriting.ASSIGN;
  const optionLabel = allocatedOptionLabel || copywriting.ASSIGNED;
  if (value.message) {
    return (
      <Box p="s" bg="transparent" width="100%">
        {value.message}
      </Box>
    );
  }
  const { person, alreadyInGroup } = value;

  const title = fullDisplayName(person);
  const initials = displayInitials(person);
  const { avatar: image } = person;
  const badgeGroups = getSupplyBadgeGroups({ person, groupTypes });
  const demandGroups = getDemandGroups(value.memberOf, groupTypes);
  const groups = showDemandGroupBadge
    ? [...demandGroups, ...badgeGroups]
    : badgeGroups;
  const hideBadge = hideBadgeForSelectedValue && context === "value";

  const memberBadges = (
    <Flex flexWrap="wrap" alignItems="center">
      <Spacer mr="xs" mt="xs">
        {person.jobTitle && <Badge>{person.jobTitle}</Badge>}
        {!isEmpty(badgeGroups) &&
          map(groups, (badgeGroup) => (
            <GroupBadge
              group={badgeGroup}
              groupTypes={groupTypes}
              key={badgeGroup.id}
            />
          ))}
      </Spacer>
    </Flex>
  );
  const badge = alreadyInGroup ? (
    <Badge variant="success">{optionLabel}</Badge>
  ) : (
    <Button small>{buttonLabel}</Button>
  );
  return (
    <Box bg="transparent" width="100%">
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <FlexItem flex="1 1 auto">
          <Avatar
            sizing="small"
            title={title}
            subtitleContent={memberBadges}
            initials={initials}
            image={image}
          />
        </FlexItem>
        {!hideBadge && (
          <FlexItem flex="0 0 auto" ml="s">
            {badge}
          </FlexItem>
        )}
      </Flex>
    </Box>
  );
};

PersonFormatOptionLabel.propTypes = {
  value: OptionValuePropType,
};

export default PersonFormatOptionLabel;
