import { isEmpty } from "lodash";
import { useAllocationProject } from "src/contexts/global/GlobalContext";

const useHideTeam = (team) => {
  if (isEmpty(team)) throw new Error("team is required");
  const allocationProject = useAllocationProject();
  return (
    allocationProject?.enableRealtimeAllocation && !team?.directMemberCount
  );
  // If someone tries to hide a group with child groups, the backend will return an appropriate error.
};

export default useHideTeam;
