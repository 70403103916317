import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { H3, Small, Flex, Grid, Popover, Box } from "orcs-design-system";

import { getForecastReadonlyFlag } from "src/allocation/util/allocationProject";
import { COLUMN_TYPES } from "../../../context/ForecastContext";
import useGridConfig from "../../util/gridConfig";

import PopOverContent from "./PopOverContent";
import ColumnNotification from "./ColumnNotification";
import { getForecastConstraintEnableFlag, getColumnTitle } from "./Utils";

const ConstraintGrid = styled(Grid)`
  align-items: center;
  flex: 1 1 100%;
`;

const ColumnHeader = ({
  column,
  rootGroup,
  showTotalCost,
  hideApproverColumn,
}) => {
  const { allocationProject, columnType, notification } = column;
  const { isActive, enableDecimalFTEInput } = allocationProject;

  const gridConfig = useGridConfig();
  const isForecastConstraintEnabled = getForecastConstraintEnableFlag(
    rootGroup,
    allocationProject
  );

  const isConstraintsMode = columnType === COLUMN_TYPES.CONSTRAINT;
  const isForecast = columnType === COLUMN_TYPES.FORECAST;
  const isPlannerApprover = columnType === COLUMN_TYPES.PLANNER_APPROVED;

  let isColumnReadonly = false;
  let showInformationIcon = false;
  if (isForecast && !isActive) {
    isColumnReadonly = getForecastReadonlyFlag(allocationProject, rootGroup);
  }

  if (isConstraintsMode || (isForecast && !isActive)) {
    showInformationIcon =
      !isForecastConstraintEnabled || !enableDecimalFTEInput;
  }

  const popOverContent = (
    <PopOverContent
      column={column}
      notification={notification}
      isColumnReadonly={isColumnReadonly}
      isConstraintsMode={isConstraintsMode}
      rootGroup={rootGroup}
      showTotalCost={showTotalCost}
    />
  );

  return useMemo(
    () => {
      if (isPlannerApprover && hideApproverColumn) {
        return <Box />;
      }

      return (
        <Flex
          column={isConstraintsMode}
          alignItems="center"
          justifyContent="space-around"
          flexWrap="wrap"
          width="100%"
        >
          <Popover
            direction="bottomLeft"
            width={isColumnReadonly ? "200px" : "140px"}
            textAlign="left"
            text={popOverContent}
          >
            <Flex alignItems="center">
              <H3
                fontSize="1.4rem"
                weight="bold"
                className="guided-tour-allocation-forecast-allocation-project-columns"
              >
                {getColumnTitle(columnType, allocationProject)}
              </H3>
              <ColumnNotification
                showInformationIcon={showInformationIcon}
                isColumnReadonly={isColumnReadonly}
                notification={notification}
                allocationProject={allocationProject}
                showTotalCost={showTotalCost}
              />
            </Flex>
          </Popover>
          {isConstraintsMode && (
            <ConstraintGrid
              gridGap={gridConfig.constraint.headerSubGrid.gap}
              gridTemplateColumns={
                gridConfig.constraint.headerSubGrid.templateColumns
              }
            >
              <Small weight="bold" textAlign="center">
                Demand
              </Small>
              <Small weight="bold" textAlign="center">
                Target
              </Small>
            </ConstraintGrid>
          )}
        </Flex>
      );
    },
    [column.id, notification] // eslint-disable-line
  );
};

ColumnHeader.propTypes = {
  column: PropTypes.object.isRequired,
  rootGroup: PropTypes.object.isRequired,
  showTotalCost: PropTypes.bool,
  hideApproverColumn: PropTypes.bool,
};

export default ColumnHeader;
