import { useQuery } from "@apollo/client";
import { FlexItem } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import TeamListItem from "src/components/Team/TeamListItem";
import { hasAdminRole } from "src/services/auth";
import { getGroupBaseQuery } from "src/queries/group.graphql";

import { getPersistentlyMovedCopywriting } from "../../utils";

const DirectParentPropertyOverridesSection = ({
  previousDirectParentId,
  groupTypes,
  workspace,
}) => {
  const { loading, data } = useQuery(getGroupBaseQuery, {
    variables: {
      groupId: previousDirectParentId,
    },
  });

  if (loading) {
    return null;
  }

  return (
    <FlexItem flex="1 1 100%" mt="l">
      {getPersistentlyMovedCopywriting({ group: data?.group })}
      <TeamListItem
        groupTypes={groupTypes}
        group={data.group}
        workspace={workspace}
      />
    </FlexItem>
  );
};

DirectParentPropertyOverridesSection.propTypes = {
  previousDirectParentId: PropTypes.string,
  groupTypes: PropTypes.object,
  workspace: PropTypes.object,
};

const PropertyOverridesSection = ({
  propertyOverrides,
  groupTypes,
  user,
  workspace,
}) => {
  // only one type of property override so far
  const previousDirectParentId =
    propertyOverrides?.directParent?.previousDirectParentId;
  if (previousDirectParentId && hasAdminRole(user)) {
    return (
      <DirectParentPropertyOverridesSection
        previousDirectParentId={previousDirectParentId}
        groupTypes={groupTypes}
        workspace={workspace}
      />
    );
  }
  return null;
};

PropertyOverridesSection.propTypes = {
  propertyOverrides: PropTypes.object,
  groupTypes: PropTypes.object,
  user: PropTypes.object,
  workspace: PropTypes.object,
};

export default PropertyOverridesSection;
