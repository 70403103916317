/* eslint-disable import/prefer-default-export */
import { Transforms } from "slate";

export const insertMention = (editor, tag) => {
  const mention = {
    type: "mention",
    tag,
    children: [{ text: `#[${tag.label}]` }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};
