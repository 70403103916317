import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  get,
  size,
  sumBy,
  filter,
  some,
  includes,
  findIndex,
  values,
  isEqual,
} from "lodash";

import PersonPropType from "src/custom-prop-types/person";
import IndividualAllocationPropType from "src/custom-prop-types/individualAllocation";
import GroupPropType from "src/custom-prop-types/group";
import { RequestAllocationContext } from "src/allocation/contexts/requestAllocationContext";
import {
  mergeExistingAllocationsWithChanges,
  searchAndFilterAndSortMembers,
} from "src/allocation/util/PeopleContainer";
import { roundFte } from "src/util/roundingStrategy";

import People from "../components/People";

/**
 * This function sets the hasMembers which is to enable/disable the HideTeam button
 * The checking rules are as following:
 * 1. people completely empty. then hasMembers = false;
 * 2. people has members, but all of the isRemoved = true.
 * @typedef {Object} [people]
 * @param {function} dispatch
 */
const updateHasMembers = (people, dispatch) => {
  if (findIndex(people, (member) => !member.isRemoved) > -1) {
    dispatch({
      type: "setHasMembers",
      hasMembers: true,
    });
  } else {
    dispatch({
      type: "setHasMembers",
      hasMembers: false,
    });
  }
};

const PeopleContainer = ({
  requestorGroup,
  selectedGroup,
  selectedRoles,
  allocationProjectId,
  memberAllocationData,
  onPeopleSelected,
}) => {
  const [, dispatch] = useContext(RequestAllocationContext);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedFilter, setFilter] = useState();
  const [searchedMembers, setSearchedMembers] = useState([]);
  const [visibleMembers, setVisibleMembers] = useState([]);

  useEffect(() => {
    if (memberAllocationData) {
      const individualAllocationsApiQueryResult = get(
        memberAllocationData,
        "individualAllocations",
        []
      );

      const groupMembersApiQueryResult = get(
        memberAllocationData,
        "members.members",
        []
      );

      const membersWithMergedAllocations = mergeExistingAllocationsWithChanges(
        selectedGroup,
        groupMembersApiQueryResult,
        individualAllocationsApiQueryResult,
        { detectAndRemoveUnallocatedMembers: true }
      );

      updateHasMembers(values(membersWithMergedAllocations), dispatch);

      const newlyFilteredAndSearchedMembers = searchAndFilterAndSortMembers(
        selectedGroup,
        null,
        membersWithMergedAllocations,
        searchTerm,
        selectedFilter
      );

      if (!isEqual(searchedMembers, newlyFilteredAndSearchedMembers)) {
        setSearchedMembers(newlyFilteredAndSearchedMembers);
        if (searchTerm) {
          onPeopleSelected(newlyFilteredAndSearchedMembers);
        } else {
          onPeopleSelected(null);
        }
      }

      const newVisibleMembers = selectedRoles
        ? filter(newlyFilteredAndSearchedMembers, (member) =>
            some(selectedRoles, (r) => includes(member.sourceGroupIds, r.id))
          )
        : newlyFilteredAndSearchedMembers;

      if (!isEqual(visibleMembers, newVisibleMembers)) {
        setVisibleMembers(newVisibleMembers);
      }
    }
  }, [
    memberAllocationData,
    selectedGroup,
    selectedRoles,
    selectedFilter,
    searchTerm,
    searchedMembers,
    visibleMembers,
    onPeopleSelected,
    dispatch,
  ]);

  return (
    <People
      loading={!memberAllocationData}
      selectedGroup={selectedGroup}
      requestorGroup={requestorGroup}
      members={visibleMembers}
      allocationProjectId={allocationProjectId}
      selectedFilter={selectedFilter}
      totalCount={size(visibleMembers)}
      totalFte={roundFte(sumBy(visibleMembers, "fteAllocatedIntoThisGroup"))}
      onSearch={setSearchTerm}
      onFilter={setFilter}
    />
  );
};

PeopleContainer.propTypes = {
  requestorGroup: GroupPropType.isRequired,
  selectedGroup: GroupPropType.isRequired,
  selectedRoles: PropTypes.arrayOf(GroupPropType),
  allocationProjectId: PropTypes.string.isRequired,
  onPeopleSelected: PropTypes.func.isRequired,
  memberAllocationData: PropTypes.shape({
    individualAllocations: PropTypes.arrayOf(IndividualAllocationPropType),
    result: PropTypes.shape({
      members: PropTypes.arrayOf(PersonPropType),
    }),
  }),
};

export default PeopleContainer;
