import React from "react";
import PropTypes from "prop-types";
import { Box, H6, Loading } from "orcs-design-system";

import TreeView from "src/allocation/components/TreeView";
import SelectCheckbox from "./SelectCheckbox";

const GroupsTreeView = ({
  treeViewTitle,
  loading,
  treeData,
  onNodeSelected,
  onNodeChecked,
  selectedGroups,
  selectedPeople,
}) => {
  const customizeTreeHeader = ({ node }, { refreshTree }) => {
    const { isRoot, isPerson } = node;
    if (!isRoot) {
      return (
        <SelectCheckbox
          node={node}
          selectedTargets={isPerson ? selectedPeople : selectedGroups}
          onNodeChecked={onNodeChecked}
          refreshTree={refreshTree}
        />
      );
    }

    return undefined;
  };

  return (
    <>
      <H6 mb="r" weight="bold">
        {treeViewTitle}
      </H6>
      <Box
        borderRadius={2}
        boxBorder="default"
        p="s"
        height="calc(100% - 48px)"
        maxHeight="calc(100% - 48px)"
        overflowY="auto"
      >
        {loading && <Loading centered />}
        <TreeView
          data={treeData}
          onNodeSelected={onNodeSelected}
          noLink
          withGroupTypeBadge
          noTreeHeaderIcon
          customizeTreeHeader={customizeTreeHeader}
        />
      </Box>
    </>
  );
};

GroupsTreeView.propTypes = {
  treeViewTitle: PropTypes.string,
  loading: PropTypes.bool,
  treeData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onNodeSelected: PropTypes.func,
  onNodeChecked: PropTypes.func,
  selectedGroups: PropTypes.object,
  selectedPeople: PropTypes.object,
};

export default GroupsTreeView;
