/* eslint-disable import/prefer-default-export */
import { map } from "lodash";
import { TAGS } from "src/consts/comments";

export const getAllCommentFilters = ({ tagTypes, personId }) => {
  const tagTypesFilters = map(tagTypes, ({ id }) => TAGS.TAGGING_TYPE_ID(id));

  return [
    [TAGS.PERSON_ID(personId)],
    [
      TAGS.OBJECTIVE.COMMENT,
      TAGS.ALLOCATION.PLANNING,
      TAGS.SUPPLY_GROUP_UPDATE,
      TAGS.PERSON_UPDATE,
      ...tagTypesFilters,
    ],
  ];
};
