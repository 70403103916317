import { find, filter, isEmpty, get } from "lodash";
import React, { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";

import styled from "styled-components";
import { Popover, Button } from "orcs-design-system";
import themeGet from "@styled-system/theme-get";

import {
  submitMacroAllocationRequests,
  submitMacroAllocationApprovals,
} from "src/allocation/allocation.graphql";

import {
  useForecastModel,
  useMainQuery,
  useLookupData,
  useViewMode,
  useDispatch,
  ACTIONS,
  NOTIFICATION_STATUS,
  VIEW_MODES,
} from "../../context/ForecastContext";

import getNotificationMessage from "../util/notificationMessage";

const SubmitButton = styled(Button)`
  height: ${themeGet("appScale.inputHeightDefault")};
`;

const PlannerSubmitButton = () => {
  const {
    variables: { groupId, isRequestorGroup },
    loading,
  } = useMainQuery();
  const { activeAllocationProject, supplyLabel, demandLabel } = useLookupData();
  const {
    dynamicModel: { isPendingSubmit, groupings },
  } = useForecastModel();
  const viewMode = useViewMode();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const mutation = isRequestorGroup
    ? submitMacroAllocationRequests
    : submitMacroAllocationApprovals;

  const variables = isRequestorGroup
    ? {
        allocationProjectId: activeAllocationProject.id,
        requestorGroupId: groupId,
      }
    : {
        allocationProjectId: activeAllocationProject.id,
        approverGroupId: groupId,
      };

  const [submit] = useMutation(mutation, {
    variables,
    onCompleted: () => {
      setIsSubmitting(false);
      dispatch({
        type: ACTIONS.SUBMIT_ALL_COMPLETE,
      });
    },
    onError: (error) => {
      setIsSubmitting(false);
      dispatch({
        type: ACTIONS.SUBMIT_ALL_ERROR,
        error,
      });
    },
  });

  const handleSubmit = useCallback(() => {
    dispatch({
      type: ACTIONS.SUBMITTING_ALL,
    });
    setIsSubmitting(true);
    submit();
  }, [setIsSubmitting, submit, dispatch]);

  const rootGrouping = find(groupings, "isRoot") || {};
  const blockingNotifications = rootGrouping.canOnlySubmitWhenAllValid
    ? filter(
        rootGrouping.notifications,
        (n) => n.notification.status === NOTIFICATION_STATUS.DANGER
      )
    : [];

  const disabledSubmitingForDemandGroupedByChapter =
    viewMode === VIEW_MODES.GROUPED_BY_SUPPLY && rootGrouping.isDemand;
  const disableButton =
    loading ||
    !rootGrouping.canSubmit ||
    !isPendingSubmit ||
    isSubmitting ||
    !isEmpty(blockingNotifications) ||
    disabledSubmitingForDemandGroupedByChapter;

  const button = (
    <SubmitButton
      iconLeft
      disabled={disableButton}
      variant={disableButton ? "disabled" : "success"}
      data-testid="submit-button"
      onClick={handleSubmit}
      className="guided-tour-allocation-planner-submit-button"
    >
      {isSubmitting
        ? "Submitting..."
        : `Submit ${isRequestorGroup ? "requests" : "responses"}`}
    </SubmitButton>
  );

  if (!loading && disableButton) {
    const popoverText = !isEmpty(blockingNotifications)
      ? getNotificationMessage(
          blockingNotifications[0].notification,
          false,
          true,
          false
        )
      : !rootGrouping.canSubmit
      ? `Submiting disabled for ${get(rootGrouping, "group.type")}`
      : disabledSubmitingForDemandGroupedByChapter
      ? `Submitting disabled when viewing by ${supplyLabel}. Please switch to ${demandLabel} view mode to submit requests.`
      : isSubmitting
      ? "Submitting"
      : "Nothing to submit";
    return (
      <Popover
        direction="bottom"
        text={popoverText}
        textAlign="center"
        width="180px"
      >
        {button}
      </Popover>
    );
  }

  return button;
};

export default PlannerSubmitButton;
