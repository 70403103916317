import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { get } from "lodash";
import Visualisation from "../../components/TeamDetailPage/Visualisation";
import ErrorPage from "../../components/ErrorPage";
import { getTeamVisualisation as getTeamVisualisationQuery } from "./team.graphql";

const VisualisationTabPage = ({ history }) => {
  const match = useRouteMatch();
  const teamId = match.params.id;
  const { data, loading, error } = useQuery(getTeamVisualisationQuery, {
    fetchPolicy: "network-only",
    variables: { teamId },
    errorPolicy: "all",
    skip: !teamId,
  });

  if (error) {
    return <ErrorPage error={error} />;
  }

  const team = get(data, "team");
  const tooManyMembers = !loading && !team;

  return (
    <Visualisation
      team={team}
      history={history}
      tooManyMembers={tooManyMembers}
    />
  );
};

VisualisationTabPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default VisualisationTabPage;
