import { themeGet } from "@styled-system/theme-get";
import { isEmpty } from "lodash";
import {
  Small,
  Button,
  Icon,
  Flex,
  Loading,
  Spacer,
  TextInput,
  Popover,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useReducer, useEffect } from "react";
import styled from "styled-components";

import { roundFte } from "src/util/roundingStrategy";

import { updateState, toState } from "./MacroInput.util";
import Status from "./Status";

const MacroInputContainer = styled(Flex)`
  width: ${(props) => (props.autoWidth ? "auto" : "264px")};
`;
const NoMarginLoading = styled(Loading)`
  margin: 0;
`;
const PrefixButton = styled(Button)`
  z-index: 1;
  border-radius: ${(props) => themeGet("radii.2")(props)} 0 0
    ${(props) => themeGet("radii.2")(props)};
  &:focus {
    box-shadow: 0 0 0 2px
      ${(props) => themeGet("colors.primaryLightest")(props)};
  }
`;
const PostfixButton = styled(Button)`
  z-index: 1;
  border-radius: 0 ${(props) => themeGet("radii.2")(props)}
    ${(props) => themeGet("radii.2")(props)} 0;
  &:focus {
    box-shadow: 0 0 0 2px
      ${(props) => themeGet("colors.primaryLightest")(props)};
  }
`;

const CustomTextInput = styled(TextInput)`
  width: 60px;
  text-align: center;
  padding: 6px;
  border-radius: 0;
  border-left: 0 none transparent;
  border-right: 0 none transparent;

  &:hover {
    border-left: 0 none transparent;
    border-right: 0 none transparent;
  }
  &:focus {
    border-left: 0 none transparent;
    border-right: 0 none transparent;
    box-shadow: 0 0 0 2px
      ${(props) => themeGet("colors.primaryLightest")(props)};
  }
`;

const reducer = (currentValue, action) => {
  switch (action.type) {
    case "plus": {
      const { step, decimalScale } = action;
      const { base, total } = currentValue;
      const newTotal = roundFte(total + step, decimalScale);
      return updateState(base, newTotal, decimalScale);
    }
    case "minus": {
      const { step, min, decimalScale } = action;
      const { base, total } = currentValue;
      const newTotal = roundFte(total - step, decimalScale);
      return newTotal >= min
        ? updateState(base, newTotal, decimalScale)
        : currentValue;
    }
    case "manualUpdate": {
      const { floatValue, textValue, min, decimalScale } = action;
      const { base } = currentValue;
      return floatValue >= min
        ? updateState(base, textValue, decimalScale)
        : currentValue;
    }
    case "reset": {
      const { baseValue, deltaValue, decimalScale } = action;
      return toState(baseValue, deltaValue, decimalScale);
    }
    default:
      throw new Error();
  }
};

const MacroInput = ({
  baseValue = 0,
  deltaValue = 0,
  min = 0,
  max,
  step = 1,
  allowCancel = true,
  isPending = false,
  isDisplayDelta = true,
  isDisplayStatus = true,
  saveButtonTitle,
  onChange,
  onCancel,
  status,
  autoWidth = false,
  decimalScale = 2,
  enableSavingZeroFte = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(
    reducer,
    toState(baseValue, deltaValue, decimalScale)
  );
  const isDirty = state.delta !== deltaValue;

  useEffect(() => {
    dispatch({ type: "reset", baseValue, deltaValue, decimalScale });
  }, [baseValue, deltaValue, dispatch, decimalScale]);

  const onInputTextChange = (values) => {
    dispatch({
      type: "manualUpdate",
      textValue: values.value,
      floatValue: values.floatValue,
      min,
      decimalScale,
    });
  };
  const numberProps = {
    allowNegative: false,
    decimalScale,
    onValueChange: onInputTextChange,
  };

  const onSaveClick = () => {
    setLoading(true);
    onChange(state.delta, state.total, state.base)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        // TODO: feedback error to user
        setLoading(false);
      });
  };
  const onCancelClick = () => {
    dispatch({ type: "reset", baseValue, deltaValue });
    if (onCancel) {
      onCancel();
    }
  };
  const onClickMinus = () => {
    dispatch({ type: "minus", step, min, decimalScale });
  };
  const onClickPlus = () => {
    dispatch({ type: "plus", step, decimalScale });
  };

  const doNotAllowZeroFte = !enableSavingZeroFte && state.total === 0;
  const doNotAllowExceededFte = max && state.total > max;

  let popoverMessage =
    "Press this button to save any changes you've made to this FTE value.";
  if (doNotAllowZeroFte) {
    popoverMessage =
      "Can't apply 0 FTE. If you want to remove this person from this team, press the remove button.";
  } else if (doNotAllowExceededFte) {
    popoverMessage = "Can't apply more FTE than the person's total FTE.";
  }

  let okDisabled = "success";
  let cancelDisabled = "danger";
  if (loading || doNotAllowZeroFte || doNotAllowExceededFte) {
    okDisabled = "disabled";
    cancelDisabled = "disabled";
  } else {
    if (allowCancel && !isDirty && !isPending) {
      okDisabled = "disabled";
    }

    if (!isDirty) {
      cancelDisabled = "disabled";
    }
  }

  return (
    <MacroInputContainer alignItems="center" autoWidth={autoWidth}>
      <Spacer mx="xs">
        {loading ? (
          <NoMarginLoading centered />
        ) : (
          isDisplayStatus && <Status status={status} isDirty={isDirty} />
        )}
        <Flex alignItems="center">
          <PrefixButton
            small
            height="28px"
            iconOnly
            disabled={loading}
            onClick={onClickMinus}
            data-testid="cp-macro-input-minus-btn"
            ariaLabel="Decrease FTE value"
          >
            <Icon icon={["fas", "minus"]} />
          </PrefixButton>
          <CustomTextInput
            height="28px"
            numberProps={numberProps}
            value={state.text}
            disabled={loading}
            data-testid="cp-macro-input-custom-input"
            ariaLabel="FTE value"
          />
          <PostfixButton
            small
            height="28px"
            iconOnly
            disabled={loading}
            onClick={onClickPlus}
            data-testid="cp-macro-input-plus-btn"
            ariaLabel="Increase FTE value"
          >
            <Icon icon={["fas", "plus"]} />
          </PostfixButton>
        </Flex>
        <Popover direction="top" width="230px" text={popoverMessage}>
          <Button
            small
            iconOnly={isEmpty(saveButtonTitle)}
            width="29px"
            height="28px"
            variant={okDisabled}
            onClick={onSaveClick}
            data-testid="cp-macro-input-save-btn"
            ariaLabel="Save"
          >
            {saveButtonTitle || <Icon icon={["fas", "check"]} />}
          </Button>
        </Popover>
        {allowCancel && (
          <Popover
            direction="top"
            width="230px"
            text={
              doNotAllowZeroFte
                ? "Can't apply 0 FTE. If you want to remove this person from this team, press the remove button."
                : "Press this button to cancel any changes you've made to this FTE value."
            }
          >
            <Button
              small
              width="29px"
              height="28px"
              iconOnly
              variant={cancelDisabled}
              onClick={onCancelClick}
              data-testid="cp-macro-input-reset-btn"
              ariaLabel="Cancel"
            >
              <Icon icon={["fas", "times"]} />
            </Button>
          </Popover>
        )}

        {isDisplayDelta && state.delta !== 0 && (
          <Small color="greyDark" ml="xs">
            {state.delta > 0 && "+"}
            {state.delta}
          </Small>
        )}
      </Spacer>
    </MacroInputContainer>
  );
};

MacroInput.propTypes = {
  isPending: PropTypes.bool,
  allowCancel: PropTypes.bool,
  baseValue: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  deltaValue: PropTypes.number,
  isDisplayDelta: PropTypes.bool,
  isDisplayStatus: PropTypes.bool,
  saveButtonTitle: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  status: PropTypes.string,
  autoWidth: PropTypes.bool,
  decimalScale: PropTypes.number,
  enableSavingZeroFte: PropTypes.bool,
};

export default MacroInput;
