import PropTypes from "prop-types";
import React from "react";
import { Icon, Popover, StyledLink, Text } from "orcs-design-system";
import { fullDisplayName } from "../../util/personName";
import PersonPropType from "../../custom-prop-types/person";
import WithPersonDetailsPath from "../WorkspaceLinks/WithPersonDetailsPath";

const PersonLink = ({
  person,
  target,
  isUnallocated = false,
  isAllocated = false,
  to,
  ...props
}) => {
  const openInNewWindow = target === "_blank";
  let fullName;

  if (isUnallocated || person.disabled) {
    fullName = <strike>{fullDisplayName(person)}</strike>;
  } else if (isAllocated) {
    fullName = <Text color="success">{fullDisplayName(person)}</Text>;
  } else {
    fullName = fullDisplayName(person);
  }

  if (openInNewWindow) {
    return (
      <Popover
        direction="right"
        text="Open details in new window"
        width="180px"
        inlineBlock
      >
        <StyledLink to={to} target="_blank" {...props}>
          {fullName}
          <Icon
            icon={["fas", "external-link-alt"]}
            size="xs"
            ml="5px"
            transform="up-5"
          />
        </StyledLink>
      </Popover>
    );
  }

  return (
    <StyledLink to={to} {...props}>
      {fullName}
    </StyledLink>
  );
};

PersonLink.propTypes = {
  person: PersonPropType.isRequired,
  target: PropTypes.string,
  isUnallocated: PropTypes.bool,
  isAllocated: PropTypes.bool,
  to: PropTypes.string,
};

export default WithPersonDetailsPath(PersonLink);
