import { compact, map, uniq } from "lodash";
import React, { useCallback } from "react";
import { Box, H5, H6, Flex, Button } from "orcs-design-system";

import {
  useUserInteraction,
  useDispatch,
  useLookupData,
  ACTIONS,
} from "../../../context/ForecastContext";
import GroupingMembersTreeModal from "./GroupingMembersTreeModal";
import useLazyLoadMembers from "./useLazyLoadMembers";
import TagsSelector from "./TagsSelector";
import useGroupingMembersTree from "./useGroupingMembersTree";

const BulkAddingTagsModal = () => {
  const {
    bulkAddingTag: { isOpen, grouping },
  } = useUserInteraction();
  const dispatch = useDispatch();

  const { enableDeparturedPeopleTagEditing } = useLookupData();

  const handleCloseModal = useCallback(() => {
    dispatch({
      type: ACTIONS.CLOSE_BULK_ADDING_TAG_MODAL,
    });
  }, [dispatch]);

  const { loadMembers, reloadMemberTags } = useLazyLoadMembers();
  const {
    treeData,
    selectedGroups,
    selectedPeople,
    handleNodeSelected,
    handleChecked,
  } = useGroupingMembersTree({
    isTreeActive: isOpen,
    grouping,
    loadMembers,
    enableDeparturedPeopleTagEditing,
  });

  const reloadTags = useCallback(async () => {
    const peopleIds = uniq(map(compact(selectedPeople), (m) => m.id));
    await reloadMemberTags(peopleIds);
  }, [reloadMemberTags, selectedPeople]);

  if (!isOpen && !grouping) {
    return null;
  }

  const footerContent = (
    <Box width="100%">
      <Flex alignItems="center" justifyContent="flex-end" width="100%">
        <Button onClick={handleCloseModal} variant="ghost" mr="r">
          Close
        </Button>
      </Flex>
    </Box>
  );

  return (
    <GroupingMembersTreeModal
      visible={isOpen}
      grouping={grouping}
      treeData={treeData}
      onHideModal={handleCloseModal}
      onNodeSelected={handleNodeSelected}
      onNodeChecked={handleChecked}
      selectedGroups={selectedGroups}
      selectedPeople={selectedPeople}
      title={<H5>Selective bulk tag</H5>}
      footerContent={footerContent}
      leftViewTitle="Select individuals you would like to tag"
    >
      <>
        <H6 mb="r" weight="bold">
          Select tags to apply
        </H6>
        <Box
          borderRadius={2}
          boxBorder="default"
          p="s"
          height="calc(100% - 48px)"
          maxHeight="calc(100% - 48px)"
          overflowY="auto"
        >
          <TagsSelector
            selectedPeople={selectedPeople}
            reloadTags={reloadTags}
          />
        </Box>
      </>
    </GroupingMembersTreeModal>
  );
};

export default BulkAddingTagsModal;
