import React from "react";
import { Small, Box } from "orcs-design-system";
import { includes } from "lodash";

import CommentPropType from "src/custom-prop-types/comment";
import { TAGS } from "src/consts/comments";

import * as notesUtil from "../../utils";
import UserComment from "./UserComment";
import SystemComment from "./SystemComment";

const Title = ({ comment }) => {
  const { authorTitle, timestamp } = comment;
  return (
    <Small color="greyDark">
      {authorTitle} - <em>{notesUtil.getDateFromTimestamp(timestamp)}</em>
    </Small>
  );
};

Title.propTypes = {
  comment: CommentPropType,
};

const Body = ({ comment }) => {
  const { tags, content, contentMarkup } = comment;

  return includes(tags, TAGS.USER_COMMENT) ? (
    <UserComment content={content} contentMarkup={contentMarkup} />
  ) : (
    <SystemComment
      tags={tags}
      content={content}
      contentMarkup={contentMarkup}
    />
  );
};

Body.propTypes = {
  comment: CommentPropType,
};

const Comment = ({ comment }) => (
  <Box pt="r">
    <Title comment={comment} />
    <Body comment={comment} />
  </Box>
);

Comment.propTypes = {
  comment: CommentPropType,
};

export default Comment;
