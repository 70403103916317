import { get } from "lodash";
import { useUserPerson } from "src/contexts/global/GlobalContext";
import { getDefaultRoutePath } from "src/util/routing";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import useRestricted from "./useRestricted";

const useDefaultRoutePath = () => {
  const [isRestricted] = useRestricted();
  const userPerson = useUserPerson();
  const { disablePersonalisation } = useWorkspaceFeatureFlags();

  const userId = disablePersonalisation ? null : get(userPerson, "aggregateId");

  const defaultRoutePath = getDefaultRoutePath({ userId, isRestricted });

  return defaultRoutePath;
};

export default useDefaultRoutePath;
