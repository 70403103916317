import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { isEmpty } from "lodash";
import moment from "moment";
import { addMembershipOnly } from "src/queries/memberships.graphql";
import { useModal } from "src/contexts/withModal";
import PersonAllocationsModal from "src/components/Person/AllocationsModal";
import { useWorkspace } from "src/contexts/global/WorkspaceContext/WorkspaceContext";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import { changePersonSupplyGroup } from "src/allocation/person.graphql";
import { useUpdateTeamAndMemberDetails } from "src/hooks/useUpdateTeamAndMemberDetails";
import { useAllocationProject } from "src/contexts/global/GlobalContext";
import { getFteSumInput, getRemainingFte } from "src/util/fteUtil";
import { getGroupType } from "src/util/groupType";
import { getSupplyGroup } from "src/util/group";

// this duplicates code found in TeamMembersPanel/index.js
export const useAllocatePersonToGroup = ({ targetGroup, filter }) => {
  const groupTypes = useGroupTypes();
  const allocationProject = useAllocationProject();
  const workspace = useWorkspace();
  const [, dispatch] = useModal();

  const updateTeamAndMemberDetails = useUpdateTeamAndMemberDetails();
  // TODO: add error handling
  // eslint-disable-next-line no-unused-vars
  const [allocateToNewGroupMutation, { error: allocateToGroupMutationError }] =
    useMutation(addMembershipOnly);
  // TODO: add error handling
  // eslint-disable-next-line no-unused-vars
  const [changeSupplyGroupMutation, { error: _changeSupplyGroupError }] =
    useMutation(changePersonSupplyGroup);

  const groupType = getGroupType(targetGroup, groupTypes);

  const isAllocationAllowed = !groupType?.disableToHaveDirectReports;

  const updateSupplyGroup = useCallback(
    async (person) => {
      const variables = {
        personId: person.aggregateId,
        groupId: targetGroup.id,
      };

      const originalSupplyGroup = getSupplyGroup({ person, groupTypes });
      await changeSupplyGroupMutation({
        variables,
        update() {
          updateTeamAndMemberDetails({
            person,
            groups: [targetGroup, originalSupplyGroup],
            targetGroup,
            fteSumInput: getFteSumInput(targetGroup, groupTypes),
          });
        },
      });
    },
    [
      changeSupplyGroupMutation,
      groupTypes,
      targetGroup,
      updateTeamAndMemberDetails,
    ]
  );

  const onEditingPersonAllocations = useCallback(
    ({ person, teamToAdd, teamToAddError }) => {
      const onHidePersonModal = () => {
        dispatch({ type: "setModalVisible", isModalVisible: false });
      };
      const personForUpdate = { ...person };

      const modalComponent = (
        <PersonAllocationsModal
          isVisible={true}
          person={personForUpdate}
          workspace={workspace}
          groupTypes={groupTypes}
          allocationProject={allocationProject}
          onHideModal={onHidePersonModal}
          showAlreadyAllocatedNotification={true}
          forceUpdatePerson
          teamToAdd={teamToAdd}
          teamToAddError={teamToAddError}
        />
      );
      dispatch({ type: "setModalComponent", modalComponent });
    },
    [allocationProject, workspace, dispatch, groupTypes]
  );

  const allocateToGroup = useCallback(
    async (person) => {
      const variables = {
        input: {
          startDate: moment().format("YYYY-MM-DD"),
          groupId: targetGroup.id,
          personId: person.aggregateId,
          fte: getRemainingFte({
            allocations: person.allocations,
            totalFte: person.fte,
          }),
        },
      };

      await allocateToNewGroupMutation({
        variables,
        update: () => {
          updateTeamAndMemberDetails({
            person,
            groups: [targetGroup],
            targetGroup,
            fteSumInput: getFteSumInput(targetGroup, groupTypes),
          }).then(() => {
            const hasExistingAllocations = !isEmpty(person.allocations);
            if (hasExistingAllocations) {
              onEditingPersonAllocations({ person });
            }
          });
        },
        // if we get an error from the backend here just open the allocations modal and display the error there.
        // the user may be able to fix the error there by changing fte values etc.
        onError: (error) => {
          onEditingPersonAllocations({
            person,
            teamToAdd: targetGroup,
            teamToAddError: error,
          });
        },
      });
    },
    [
      allocateToNewGroupMutation,
      groupTypes,
      onEditingPersonAllocations,
      targetGroup,
      updateTeamAndMemberDetails,
    ]
  );

  const allocatePersonToGroup = useCallback(
    async (person) => {
      if (groupType && groupType.isSupply) {
        await updateSupplyGroup(person);
      } else {
        await allocateToGroup(person);
      }
    },
    [allocateToGroup, groupType, updateSupplyGroup]
  );

  return { allocatePersonToGroup, filter, isAllocationAllowed, targetGroup };
};
