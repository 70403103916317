import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty, keyBy } from "lodash";
import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import TeamStrategiesTable from "src/components/TeamStrategiesTable";
import { getValueGraph } from "src/queries/valueGraph.graphql";
import { getRowFilter } from "../utils/getRowFilter";
import getNestedObjectives from "./getNestedObjectives";

const StrategyTableView = ({ objectives = [], showChildTeams = true }) => {
  const [expanded, setExpanded] = useState({});
  const [objectivesMap, setObjectivesMap] = useState({});

  const rowFilters = useMemo(
    () => getRowFilter({ showChildTeams }),
    [showChildTeams]
  );

  useEffect(() => {
    if (!isEmpty(objectives)) {
      setObjectivesMap(keyBy(objectives, "id"));
      setExpanded(true);
    }
  }, [objectives, setObjectivesMap]);

  const [getMoreObjectives, lazy] = useLazyQuery(getValueGraph);

  const loadMore = useCallback(
    async (objectiveId) => {
      const res = await getMoreObjectives({
        variables: {
          input: {
            objectiveIds: [objectiveId],
            options: {
              includeDirectChildrenObjectives: true,
              includeNotAligned: false,
            },
          },
        },
      });

      setObjectivesMap((oldObjectivesMap) => {
        const newObjectives = res?.data?.valueGraph?.objectives || [];
        const mergedObjectives = {
          ...oldObjectivesMap,
          ...keyBy(newObjectives, "id"),
        };

        return mergedObjectives;
      });
    },
    [getMoreObjectives]
  );

  const tableData = useMemo(
    () => getNestedObjectives(Object.values(objectivesMap), loadMore),
    [objectivesMap, loadMore]
  );

  const tableConfig = useMemo(
    () => ({
      autoResetPageIndex: false,
      onExpandedChange: setExpanded,
      state: {
        expanded,
        isLoading: isEmpty(tableData),
        showProgressBars: lazy.loading,
      },
      muiTableBodyRowProps: ({ row }) => ({
        onClick: (e) => {
          e.stopPropagation();
          row.original?.loadMore?.();
        },
        sx: {
          cursor: row.original.loadMore ? "pointer" : undefined,
        },
      }),
    }),
    [tableData, expanded, lazy.loading]
  );

  return (
    <TeamStrategiesTable
      data={tableData}
      tableConfig={tableConfig}
      rowFilters={rowFilters}
    />
  );
};

StrategyTableView.propTypes = {
  objectives: PropTypes.array.isRequired,
  showChildTeams: PropTypes.bool,
};

export default StrategyTableView;
