import { reduce, map, find, orderBy, head, get, countBy } from "lodash";
import cronParser from "cron-parser";
import moment from "moment";
import { getTypeForGroup } from "src/util/customerConfig";
import { fullDisplayName } from "src/util/personName";

const groupFilter = (g, existingGroups) =>
  !g.isHidden && !find(existingGroups, ["id", g.id]);

export const createGroupsOptions = (
  groups,
  existingGroups,
  includeAllGroups = false
) => {
  return reduce(
    groups,
    (prev, group) => {
      if (includeAllGroups || groupFilter(group, existingGroups)) {
        prev.push({
          label: group.name,
          value: group.id,
          hierarchy: group.hierarchy,
          type: group.type,
          group,
        });
      }
      return prev;
    },
    []
  );
};

export const createPersonOptions = (people) => {
  return map(people, (person) => {
    return {
      label: fullDisplayName(person),
      value: person.aggregateId,
      person,
    };
  });
};

export const isGroupsSameType = (fromGroup, toGroup, groupTypes) => {
  if (!fromGroup || !toGroup) {
    return false;
  }

  const fromGroupType = getTypeForGroup(groupTypes, fromGroup);
  const toGroupType = getTypeForGroup(groupTypes, toGroup);

  if (!fromGroupType || !toGroupType) {
    return false;
  }

  return (
    fromGroupType.isSupply === toGroupType.isSupply ||
    fromGroupType.isDemand === toGroupType.isDemand
  );
};

export const canProcess = (targetPerson, targetGroup) => {
  if (!targetPerson || !targetGroup) {
    return false;
  }

  return true;
};

export const detectDuplicatedJobs = (integrations) => {
  const schedulers = countBy(integrations, (integration) => {
    const { schedulerType, cronExpression, schedulerFrequency } = integration;
    return schedulerType === "CRON_EXPRESSION"
      ? cronExpression
      : schedulerFrequency;
  });

  return reduce(
    integrations,
    (prev, int) => {
      const { id, schedulerType, cronExpression, schedulerFrequency } = int;
      const scheduleValue =
        schedulerType === "CRON_EXPRESSION"
          ? cronExpression
          : schedulerFrequency;

      // eslint-disable-next-line no-param-reassign
      prev[id] = {
        ...int,
        duplicatedSchedule: schedulers[scheduleValue] > 1,
      };

      return prev;
    },
    {}
  );
};

export const getDelayedMessage = (seconds) => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const remainingSeconds = seconds % 60;

  let importMessage = "Last import was delayed by";

  if (days > 0) {
    importMessage += ` ${days} day${days > 1 ? "s" : ""}`;
  }
  if (hours > 0) {
    importMessage += ` ${hours} hour${hours > 1 ? "s" : ""}`;
  }
  importMessage += ` ${remainingSeconds} second${
    remainingSeconds > 1 ? "s" : ""
  }`;

  return importMessage;
};

export const detectImportStatus = (integrations) => {
  const detectedIntegrations = reduce(
    integrations,
    (prev, integration) => {
      const { id, schedulerType, cronExpression, schedulerFrequency, imports } =
        integration;
      const sortedImports = orderBy(imports, ["created"], ["desc"]);
      const lastImport = head(sortedImports);
      const lastExecutionTime = moment(get(lastImport, "processed"));

      let importStatus = get(lastImport, "status");
      let importMessage = "";

      switch (get(lastImport, "status")) {
        case "PROCESSED": {
          importMessage = `Last import was successful on ${lastExecutionTime}`;
          if (schedulerType === "FIXED_RATE") {
            const now = moment();
            const diff = now.diff(lastExecutionTime, "seconds");
            const delayed = diff - schedulerFrequency;

            if (delayed > 5) {
              importStatus = "DELAYED";
              importMessage = getDelayedMessage(delayed);
            }
          }

          if (schedulerType === "CRON_EXPRESSION") {
            const lastExecutionDate = moment(lastExecutionTime);
            const options = {
              currentDate: new Date(),
              tz: "UTC",
            };

            const job = cronParser.parseExpression(cronExpression, options);
            const prevRun = moment(job.prev().getTime());

            if (lastExecutionDate.isBefore(prevRun)) {
              const delayed = prevRun.diff(lastExecutionDate, "seconds");

              if (delayed > 5) {
                importStatus = "DELAYED";
                importMessage = getDelayedMessage(delayed);
              }
            }
          }
          break;
        }
        case "PROCESSING":
        case "QUEUED": {
          importMessage = "The last import is still processing...";
          break;
        }
        case "FAILED": {
          importMessage = `Last import was failed on ${lastExecutionTime}`;
          break;
        }
        default: {
          importMessage = `Unknown import status`;
          break;
        }
      }

      // eslint-disable-next-line no-param-reassign
      prev[id] = {
        ...integration,
        importStatus,
        importMessage,
      };

      return prev;
    },
    {}
  );

  return detectedIntegrations;
};
