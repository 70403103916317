import { get, sortBy, toLower } from "lodash";

import { getAltBadgeGroups } from "src/util/group";
import { getName } from "src/util/person";

// eslint-disable-next-line import/prefer-default-export
export const sortMembers = (
  members,
  selectedSort,
  team,
  groupTypes,
  newMemberId,
  disableSort = false
) => {
  if (disableSort) {
    return members;
  }

  const sortTarget = [(o) => toLower(getName(o))];

  const getFirstBadgeGroup = (member) => {
    const badgeGroups = getAltBadgeGroups({
      group: team,
      person: member,
      groupTypes,
    });

    return get(badgeGroups, "[0].name", null);
  };

  if (get(selectedSort, "value") === "groupByType") {
    sortTarget.unshift(getFirstBadgeGroup);
  }

  if (newMemberId) {
    sortTarget.unshift((m) => m.aggregateId !== newMemberId);
  }

  return sortBy(members, sortTarget);
};
