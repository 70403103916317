import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useUserInteraction, useModel } from "./context";

const usePersistUserInteraction = () => {
  const { loading } = useModel();
  const userInteraction = useUserInteraction();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!loading) {
      history.replace({
        search: location.search,
        state: {
          userInteraction,
        },
      });
    }
  }, [loading, userInteraction]); // eslint-disable-line
};

export default usePersistUserInteraction;
