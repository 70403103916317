import React from "react";
import PropTypes from "prop-types";

import { useAllocationProjects } from "../../../context/ForecastContext";
import { usePlannerLineItemGridConfig } from "../../util/gridConfig";

import TotalCostCell from "../../Shared/TotalCostCell";
import LineItemActionMenu from "../LineItemActionMenu";
import CommentCell from "../CommentCell";
import LineItemLeftControls from "./LineItemLeftControls";
import CustomCustomGrid from "./LineItemCustomGrid";
import LineItemCells from "./LineItemCells";

const LineItemContent = ({
  lineItem,
  dispatch,
  isMembersExpanded = false,
  shouldShowCost = false,
  hideApproverColumn = false,
  hideBudgetColumn,
  withMembersExpandIcon = false,
  withRequestNotification = true,
  isForecast,
  enableMemberFteEdit,
}) => {
  const {
    isSupply,
    grouping: {
      root: { isSupply: isRootGroupSupply },
    },
  } = lineItem;

  const allocationProjects = useAllocationProjects();
  const [templateColumns, gap] = usePlannerLineItemGridConfig(
    lineItem,
    allocationProjects
  );

  return (
    <CustomCustomGrid
      gridTemplateColumns={templateColumns}
      gridGap={gap}
      className="guided-tour-allocation-planner-line-item"
    >
      <LineItemLeftControls
        lineItem={lineItem}
        isMembersExpanded={isMembersExpanded}
        isSupply={isRootGroupSupply && isSupply}
        isRootGroupSupply={isRootGroupSupply}
        dispatch={dispatch}
        withMembersExpandIcon={withMembersExpandIcon}
      />
      {shouldShowCost && <TotalCostCell lineItem={lineItem} />}
      <LineItemCells
        lineItem={lineItem}
        isMembersExpanded={isMembersExpanded}
        hideApproverColumn={hideApproverColumn}
        dispatch={dispatch}
        withRequestNotification={withRequestNotification}
      />
      {/** empty div here to pad out Grid Layout to the correct alignment */}
      {!isForecast &&
        !isRootGroupSupply &&
        enableMemberFteEdit &&
        !(hideApproverColumn && !hideBudgetColumn) && <div />}
      <CommentCell lineItem={lineItem} dispatch={dispatch} />
      <LineItemActionMenu lineItem={lineItem} dispatch={dispatch} />
    </CustomCustomGrid>
  );
};

LineItemContent.propTypes = {
  lineItem: PropTypes.object.isRequired,
  isMembersExpanded: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  shouldShowCost: PropTypes.bool,
  hideApproverColumn: PropTypes.bool,
  hideBudgetColumn: PropTypes.bool.isRequired,
  withMembersExpandIcon: PropTypes.bool,
  withRequestNotification: PropTypes.bool,
  isForecast: PropTypes.bool.isRequired,
  enableMemberFteEdit: PropTypes.bool.isRequired,
};

export default LineItemContent;
