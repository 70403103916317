import themeGet from "@styled-system/theme-get";
import {
  Button,
  Flex,
  FlexItem,
  Icon,
  ActionsMenuBody,
  Box,
  Checkbox,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import FilterInput from "src/pages/TeamPage/sub-components/BulkActionBar/FilterInput";
import { STATUS } from "src/components/BulkMoveMembersModal/consts";

import useSelectAll from "../hooks/useSelectAll";
import NotificationMessage from "../Notification";

const CustomActionsMenuBody = styled(ActionsMenuBody)`
  @media screen and (max-width: ${themeGet("breakpoints.1")}) {
    div[class*="ActionsMenu__Menu"] {
      width: calc(100vw - ${themeGet("space.r")}*5);
      left: -111px;
    }
  }
`;

const BulkActionBarWrapper = ({
  supplyLabel,
  members,
  selectedMembers,
  setSelectedMembers,
  setStatus,
  status,
  toggleState,
  onToggle,
  membersFilterOption,
  setMembersFilterOption,
  children,
  closeMenu,
}) => {
  const { handleAllSelectMembersClick, allSelectMembers } = useSelectAll(
    members,
    setSelectedMembers
  );

  const onCloseNotification = () => {
    setStatus(null);
  };

  const allLabel = allSelectMembers ? "Unselect all" : "Select all";

  return (
    <Box
      bg="greyLightest"
      borderRadius={2}
      width="100%"
      boxBorder="default"
      p="s"
    >
      <Flex justifyContent="flex-start" alignItems="center" flexWrap="wrap">
        <Checkbox
          disabled={status?.status === STATUS.PROGRESS}
          checked={allSelectMembers}
          onChange={handleAllSelectMembersClick}
          label={allLabel}
          colour="primary"
          mr="s"
        />
        <CustomActionsMenuBody
          toggleState={toggleState}
          onToggle={onToggle}
          closeMenu={closeMenu}
          menuTopPosition="30px"
          menuLeftPosition="0"
          fullWidthMobile
          customTriggerComponent={
            <Button className="bulk-edit-select-action-btn" small iconRight>
              Select action
              <Icon icon={["fas", "chevron-down"]} />
            </Button>
          }
        >
          {children}
        </CustomActionsMenuBody>
        <FlexItem
          flex={["1 1 100%", "1 1 100%", "1 1 auto"]}
          ml={["0", "0", "s"]}
          mt={["s", "s", "0"]}
        >
          <FilterInput
            supplyLabel={supplyLabel}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
            membersFilterOption={membersFilterOption}
            setMembersFilterOption={setMembersFilterOption}
          />
        </FlexItem>
      </Flex>
      <NotificationMessage
        onCloseNotification={onCloseNotification}
        status={status}
      />
    </Box>
  );
};

BulkActionBarWrapper.propTypes = {
  supplyLabel: PropTypes.string,
  members: PropTypes.object,
  selectedMembers: PropTypes.object,
  setSelectedMembers: PropTypes.func,
  children: PropTypes.node,
  status: PropTypes.object,
  setStatus: PropTypes.func,
  toggleState: PropTypes.bool,
  onToggle: PropTypes.func,
  closeMenu: PropTypes.func.isRequired,
  membersFilterOption: PropTypes.object,
  setMembersFilterOption: PropTypes.func,
};

export default BulkActionBarWrapper;
