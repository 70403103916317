import React from "react";
import { Box, Select } from "orcs-design-system";

import { getOptionByValue } from "src/util/selectUtil";
import {
  useDispatch,
  useLookupData,
  useMembersFilter,
  ACTIONS,
  FILTERS,
} from "../../context/ForecastContext";

const OPTIONS = [
  { label: "Baseline members", value: FILTERS.BASELINE_FILTER },
  { label: "Future members", value: FILTERS.FUTURE_FILTER },
];

const MemberFilters = () => {
  const { enableMemberAllocation } = useLookupData();
  const membersFilter = useMembersFilter();
  const dispatch = useDispatch();

  if (!enableMemberAllocation) {
    return null;
  }

  const handlerFilterChange = (option) => {
    dispatch({
      type: ACTIONS.APPLY_MEMBERS_FILTER,
      filter: option ? option.value : null,
    });
  };

  return (
    <Box width="185px">
      <Select
        inputId="members-filter"
        placeholder="Select members filter"
        ariaLabel="Select members filter"
        isClearable
        options={OPTIONS}
        value={getOptionByValue(OPTIONS, membersFilter)}
        onChange={handlerFilterChange}
      />
    </Box>
  );
};

export default MemberFilters;
