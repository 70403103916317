import { useEffect } from "react";

import { useShowCommentModal } from "src/comments/context";
import { useForecastContext } from "../../context/ForecastContext";

const CommentsModal = () => {
  const [
    {
      lookupData: { groupLookup },
      userInteraction: {
        viewingComment: { isOpen, cellId, pressedAt },
      },
      model: { dynamicModel },
    },
  ] = useForecastContext();

  const showCommentModal = useShowCommentModal();

  useEffect(() => {
    if (isOpen) {
      const cell = dynamicModel.lookups.cellLookup[cellId];
      const { allocationProjectId, targetGroupId, sourceGroupId } = cell;
      const targetGroup = groupLookup[targetGroupId];
      const roleGroup = groupLookup[sourceGroupId];

      showCommentModal({
        allocationProjectId,
        targetGroup,
        roleGroup,
      });
    }
  }, [isOpen, cellId, pressedAt]); // eslint-disable-line

  return null;
};

export default CommentsModal;
