/* eslint-disable react/no-array-index-key */

import React from "react";
import { Box, H2, Flex, FlexItem, P, Divider } from "orcs-design-system";
import PropTypes from "prop-types";
import FancyCard, {
  FancyCardHeader,
  FancyCardBody,
} from "src/components/FancyCard/FancyCard";
import LoadingPage from "../../LoadingPage";
import PrincipleResult from "./PrincipleResult";
import PrincipleSetSelector from "./PrincipleSetSelector";

const PrincipleResultList = ({
  mutating,
  team,
  principleSets = [],
  setPrincipleSetId,
  principles,
}) => (
  <FancyCard height="100%">
    <FancyCardHeader>
      <H2 sizing="small" weight="light" mb="r">
        Principles
      </H2>
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Box width={[1, 2 / 5]}>
          <PrincipleSetSelector
            mutating={mutating}
            team={team}
            principleSets={principleSets}
            setPrincipleSetId={setPrincipleSetId}
          />
        </Box>
        <FlexItem flexShrink="0">
          <P weight="bold" color="greyDark">
            {principles.filter((p) => p.met === "TRUE").length}/
            {principles.length} MET
          </P>
        </FlexItem>
      </Flex>
    </FancyCardHeader>
    <FancyCardBody>
      {mutating ? (
        <LoadingPage waitingFor="selectingPrincipleSet" />
      ) : (
        principles
          .map((p) => <PrincipleResult principle={p} key={p.title} />)
          .reduce(
            (prev, curr, i) => [prev, curr, <Divider light key={i} />],
            null
          )
      )}
    </FancyCardBody>
  </FancyCard>
);

PrincipleResultList.propTypes = {
  team: PropTypes.object,
  principleSets: PropTypes.array,
  setPrincipleSetId: PropTypes.func.isRequired,
  mutating: PropTypes.bool.isRequired,
  principles: PropTypes.array,
};

export default PrincipleResultList;
