import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import { useDrag } from "react-dnd";
import PropTypes from "prop-types";
import { Box, Divider } from "orcs-design-system";

import { getActiveAllocations } from "src/allocation/util/individualAllocation";
import { getCity } from "src/allocation/util/person";
import { DND_TYPE_PEOPLE } from "src/allocation/consts";
import { ACTIONS, useDispatch } from "../../context";
import PersonAllocationDetails from "./PersonAllocationDetails";

const Wrapper = styled.div`
  opacity: ${(props) => (props.isDragging ? "0.4" : "1")};
  cursor: ${(props) => (props.isDragging ? "grabbing" : "default")};
`;

const Row = ({ allocationProjectId, person, allocations }) => {
  const dispatch = useDispatch();
  const city = useMemo(() => getCity(person), [person]);
  const activeAllocations = useMemo(
    () => getActiveAllocations(allocations),
    [allocations]
  );

  const onAllocate = useCallback(() => {
    dispatch({
      type: ACTIONS.OPEN_PERSON_ALLOCATIONS,
      personId: person.aggregateId,
    });
  }, [dispatch, person.aggregateId]);

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DND_TYPE_PEOPLE,
      allocations: activeAllocations,
      person,
      allocationProjectId,
      city,
    },
    end: (_item, monitor) => {
      const target = monitor.getDropResult();
      if (!target || !target.groupId || !target.allocateToNewGroup) {
        return;
      }

      target.allocateToNewGroup.then(onAllocate);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <>
      <Wrapper isDragging={isDragging} ref={preview} data-testid="person-row">
        <Box py="s">
          <PersonAllocationDetails
            person={person}
            allocations={activeAllocations}
            city={city}
            onAllocate={onAllocate}
            isDragging={isDragging}
            drag={drag}
          />
        </Box>
      </Wrapper>
      <Divider />
    </>
  );
};

Row.propTypes = {
  allocationProjectId: PropTypes.string.isRequired,
  person: PropTypes.object.isRequired,
  allocations: PropTypes.arrayOf(PropTypes.object),
};

export default Row;
