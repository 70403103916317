/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Flex, FlexItem, H6 } from "orcs-design-system";

import GroupDescription from "../GroupDescription";

const SupplyGroupDescription = React.memo(({ supplyGroup }) => {
  return (
    <Flex flexDirection="column" height="100%">
      <H6 mb="s" weight="bold">{`${get(
        supplyGroup,
        "type",
        ""
      )} description`}</H6>
      <FlexItem flex="1 1 auto">
        <GroupDescription group={supplyGroup} />
      </FlexItem>
    </Flex>
  );
});

SupplyGroupDescription.propTypes = {
  supplyGroup: PropTypes.object,
};

export default SupplyGroupDescription;
