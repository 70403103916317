import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { throttle } from "lodash";
import styled, { css } from "styled-components";
import themeGet from "@styled-system/theme-get";
import { NOOP } from "src/allocation/consts";

import AnimatedExpandIcon from "./AnimatedExpandIcon";

const clickable = css`
  &:hover,
  &:focus {
    background-color: ${themeGet("colors.primaryLightest")};
  }
  cursor: pointer;
`;

const objectiveHeaderCss = css`
  &:hover,
  &:focus {
    background-color: ${themeGet("colors.white")};
  }
`;

const TreeExpandControl = styled.button`
  font-size: 1.2rem;
  appearance: none;
  border: 0;
  outline: 0;
  display: block;

  width: ${(props) =>
    props.controlsGroupRoles || props.indentLevel < 1
      ? "100%"
      : "calc(100% - 20px)"};
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: ${(props) =>
    props.expandButton
      ? themeGet("colors.greyLighter")
      : props.controlsGroupRoles
      ? "#f2f5f5"
      : props.controlObjectives
      ? themeGet("colors.white")
      : "#e6eaea"};
  border-radius: ${(props) =>
    props.controlsGroupRoles
      ? "0"
      : props.hasConnectedTreeExpand && props.isOpen
      ? `${themeGet("radii.2")(props)} ${themeGet("radii.2")(props)} 0 0`
      : themeGet("radii.2")};
  padding: 6px 0;
  transition: ${themeGet("transition.transitionDefault")};
  margin: ${(props) =>
    props.controlsGroupRoles
      ? `-4px 0 4px 0 !important`
      : props.indentLevel
      ? `4px 0 4px 20px`
      : "4px 0"};
  ${(props) => props.hasToggle && clickable}
  ${(props) => props.controlObjectives && objectiveHeaderCss}
  ${(props) =>
    props.inlineStyle
      ? css`
          background: transparent;
          padding: 3px 0 3px 23px;
          font-weight: ${themeGet("fontWeights.2")};
          color: ${themeGet("colors.greyDarker")};
          &:hover {
            background: transparent;
            color: ${themeGet("colors.primary")};
          }
          &:focus {
            background: transparent;
          }
        `
      : css``};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
`;

const IconWrapper = styled.div`
  margin: 0 10px 0 13px;
  ${(props) =>
    props.inlineStyle
      ? css`
          margin: 0 5px 0 13px;
        `
      : css``};
`;
const TreeExpand = ({
  isOpen,
  indentLevel,
  children,
  onToggle,
  hasToggle = true,
  controlsGroupRoles,
  hasConnectedTreeExpand,
  controlObjectives,
  inlineStyle,
  expandButton = false,
}) => {
  const throttleOnToggle = useMemo(
    () => throttle(onToggle, 500, { trailing: false }),
    [onToggle]
  );

  const handleControlOnClick = hasToggle ? throttleOnToggle : NOOP;

  return (
    <TreeExpandControl
      hasToggle={hasToggle}
      isOpen={isOpen}
      hasConnectedTreeExpand={hasConnectedTreeExpand}
      controlsGroupRoles={controlsGroupRoles}
      controlObjectives={controlObjectives}
      indentLevel={indentLevel}
      onClick={handleControlOnClick}
      inlineStyle={inlineStyle}
      expandButton={expandButton}
    >
      <Container>
        <IconWrapper inlineStyle={inlineStyle}>
          {hasToggle && <AnimatedExpandIcon isOpen={isOpen} />}
        </IconWrapper>
        {children}
      </Container>
    </TreeExpandControl>
  );
};

TreeExpand.propTypes = {
  isOpen: PropTypes.bool,
  inlineStyle: PropTypes.bool,
  hasConnectedTreeExpand: PropTypes.bool,
  hasToggle: PropTypes.bool,
  controlsGroupRoles: PropTypes.bool,
  controlObjectives: PropTypes.bool,
  indentLevel: PropTypes.number,
  onToggle: PropTypes.func,
  children: PropTypes.node,
  expandButton: PropTypes.bool,
};

export default TreeExpand;
