import PropTypes from "prop-types";
import React, { useMemo, useState, useCallback } from "react";
import { createEditor } from "slate";
import { Slate, withReact } from "slate-react";

import { MentionMenu } from "src/comments/components/RichTextEditor/plugins/mentions";
import { StyledEditor } from "../RichTextEditor.styled";
import * as rteUtils from "../RichTextEditor.util";
import { getSlateContent } from "../RichTextSerializer";

import Element from "./Element";
import Leaf from "./Leaf";
import Toolbar from "./Toolbar";

/**
 * Base editor using Slate
 *
 * @type {Component}
 */
const Editor = ({
  "data-testid": dataTestId,
  defaultValue,
  height = 160,
  placeholder,
  onTextChange,
  // optional attrs for Mentions
  withMentions = null,
  ChangeHandler = null,
  KeyDownHandler = null,
  autoFocus,
}) => {
  const [message, setMessage] = useState(() => getSlateContent(defaultValue));

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const editor = useMemo(() => {
    const editorWithLinks = rteUtils.withLinks(withReact(createEditor()));
    return withMentions ? withMentions(editorWithLinks) : editorWithLinks;
  }, [withMentions]);

  const onChange = useCallback(
    (value) => {
      if (ChangeHandler) {
        ChangeHandler(editor)(value);
      }
      setMessage(value);
      onTextChange(value);
    },
    [ChangeHandler, editor, onTextChange]
  );

  const onKeyDown = useCallback(
    (evt) => {
      const mark = rteUtils.getMarkFromHotkey(evt);
      if (KeyDownHandler) {
        KeyDownHandler(editor)(evt);
      }
      if (mark) {
        evt.preventDefault();
        rteUtils.toggleMark(editor, mark);
      }
    },
    [KeyDownHandler, editor]
  );

  return (
    <div id="cp-rte" data-testid={dataTestId}>
      <Slate editor={editor} value={message} onChange={onChange}>
        <Toolbar />
        <StyledEditor
          id="cp-base-editor"
          aria-label="Team description"
          data-testid="cp-base-editor"
          height={height}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          spellCheck
          autoFocus={autoFocus}
          focus={autoFocus}
        />
        {withMentions && <MentionMenu editor={editor} />}
      </Slate>
    </div>
  );
};

Editor.propTypes = {
  "data-testid": PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  height: PropTypes.number,
  onTextChange: PropTypes.func,
  placeholder: PropTypes.string,
  withMentions: PropTypes.func,
  ChangeHandler: PropTypes.func,
  KeyDownHandler: PropTypes.func,
  autoFocus: PropTypes.bool,
};

Editor.defaultProps = {
  autoFocus: true,
};

export default Editor;
