import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";
import { NOOP } from "src/allocation/consts";
import { getIndirectForecastFlg } from "src/allocation/util/allocationProject";

import {
  usePageMode,
  useDispatch,
  useLookupData,
  useColumns,
  useMainQuery,
  useRootGroup,
  useUserInteraction,
  useGroupTypesLookup,
  ACTIONS,
  MACRO_ALLOCATION_TYPES,
  PAGE_MODES,
  VIEW_MODES,
} from "../../../context/ForecastContext";
import { hasEditableProjects } from "../../util/readonlyFlags";

const AddNewChapterButton = ({ as, layout }) => {
  const pageMode = usePageMode();
  const columns = useColumns();
  const rootGroup = useRootGroup();
  const groupTypesLookup = useGroupTypesLookup();
  const { loading } = useMainQuery();
  const { supplyLabel, activeAllocationProject } = useLookupData();
  const { viewMode } = useUserInteraction();
  const dispatch = useDispatch();

  const handleAddRole = useCallback(() => {
    dispatch({
      type: ACTIONS.SHOW_ADD_ROLE_DIALOG,
      parentSourceGroupId: null,
      targetGroupId: null,
      macroAllocationType: MACRO_ALLOCATION_TYPES.INDIRECT_PLACEHOLDER,
      targetView: VIEW_MODES.GROUPED_BY_SUPPLY,
    });
  }, [dispatch]);

  if (loading || rootGroup.isSource) {
    return null;
  }

  const canHaveIndirect = getIndirectForecastFlg(groupTypesLookup, rootGroup);

  if (!canHaveIndirect) {
    return null;
  }

  const isForecastChapterView =
    pageMode === PAGE_MODES.FORECAST &&
    viewMode === VIEW_MODES.GROUPED_BY_SUPPLY;

  if (!isForecastChapterView) {
    return null;
  }

  const isDisabled = !hasEditableProjects(
    pageMode,
    activeAllocationProject,
    columns
  );

  if (isDisabled) {
    return null;
  }

  const Component = as || ActionsMenuItem;

  const menuItem = (
    <Component
      data-testid="add-role-action-button"
      onClick={loading ? NOOP : handleAddRole}
      className="guided-tour-allocation-forecast-add-chapter"
    >
      {`Add ${supplyLabel}`}
    </Component>
  );

  if (layout) {
    return layout(menuItem);
  }

  return menuItem;
};

AddNewChapterButton.propTypes = {
  as: PropTypes.elementType,
  layout: PropTypes.func,
};

export default AddNewChapterButton;
