import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { createEditor, Transforms } from "slate";
import { Slate, withReact } from "slate-react";
import { Button, Flex, Popover } from "orcs-design-system";

import { StyledEditor } from "src/components/RichTextEditor/RichTextEditor.styled";
import Toolbar from "./sub-components/Toolbar";
import Element from "./sub-components/Element";
import Leaf from "./sub-components/Leaf";

import * as cfg from "./RichTextEditor.config";
import * as rteUtils from "./RichTextEditor.utils";
import { isContentEmpty } from "./serializer/slatePlainTextSerializer";

const DISABLED_COMMENTS_TOOLTIP = "These comments are closed";
const ENABLED_COMMENTS_TOOLTIP = `Tip: Notes can be used to capture details of the specific skills,
band, funding linkages and mission the FTE is being requested or
approved for. Notes can also help provide context or capture
highlights from conversations along with other contacts that will
help in the FTE decision making.`;

/**
 * Rich Text Editor using Slate
 *
 * @type {Component}
 */
const RichTextEditor = ({
  placeholder,
  addNote,
  className,
  onCancelNote,
  disabled,
}) => {
  const [message, setMessage] = useState(cfg.getInitialValue());
  const [saving, setSaving] = useState(false);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => rteUtils.withLinks(withReact(createEditor())),
    []
  );

  const onChange = useCallback((value) => {
    setMessage(value);
  }, []);

  const onKeyDown = useCallback(
    (evt) => {
      if (disabled) {
        evt.preventDefault();
        return;
      }
      const mark = cfg.getMarkFromHotkey(evt);

      if (mark) {
        evt.preventDefault();
        rteUtils.toggleMark(editor, mark);
      }
    },
    [editor, disabled]
  );

  const onSaveNote = async () => {
    if (message) {
      const str = JSON.stringify(message);
      setSaving(true);
      try {
        await addNote(str);
        Transforms.deselect(editor);
        setMessage(cfg.getInitialValue());
      } finally {
        setSaving(false);
      }
    }
  };

  const onBlur = useCallback(() => {
    Transforms.deselect(editor);
  }, [editor]);

  const isEmptyInput = isContentEmpty(message);
  return (
    <div className={className}>
      <Slate editor={editor} value={message} onChange={onChange}>
        <Toolbar disabled={disabled} />
        <StyledEditor
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          disabled={disabled}
          spellCheck
          autoFocus
        />
      </Slate>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Button
            disabled={disabled || isEmptyInput || saving}
            onClick={onSaveNote}
            mr="r"
          >
            Add note
          </Button>
          <Popover
            direction="topRight"
            variant="tooltip"
            width="265px"
            text={
              disabled ? DISABLED_COMMENTS_TOOLTIP : ENABLED_COMMENTS_TOOLTIP
            }
          />
        </Flex>
        <Button onClick={onCancelNote} variant="ghost">
          Close
        </Button>
      </Flex>
    </div>
  );
};

RichTextEditor.propTypes = {
  addNote: PropTypes.func,
  onCancelNote: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RichTextEditor;
