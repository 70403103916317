import { useQuery } from "@apollo/client";

import {
  useSecondaryQuery,
  useParentGroup,
  useDispatch,
  useUserInteraction,
  ACTIONS,
} from "./context";

const useExecGroupsQuery = () => {
  const { variables, skip, query } = useSecondaryQuery();
  const {
    selectedSupplyGroupId,
    selectedSubSupplyGroupId,
    targetGroupSearch,
    personSearch,
    personFilter,
  } = useUserInteraction();
  const parentGroup = useParentGroup();
  const dispatch = useDispatch();

  useQuery(query, {
    skip,
    variables,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data) {
        dispatch({
          type: ACTIONS.SECONDARY_QUERY_COMPLETE,
          data,
          variables,
          selectedSupplyGroupId,
          selectedSubSupplyGroupId,
          targetGroupSearch,
          personSearch,
          personFilter,
          parentGroup,
        });
      }
    },
    onError: (error) => {
      dispatch({ type: ACTIONS.SECONDARY_QUERY_ERROR, error });
    },
  });
};

export default useExecGroupsQuery;
