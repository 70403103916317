// eslint-disable-next-line
import ELK from "elkjs/lib/elk.bundled.js";

const elk = new ELK();

const elkOptions = {
  "elk.algorithm": "layered",
  "elk.layered.spacing.nodeNodeBetweenLayers": "100",
  "elk.spacing.nodeNode": "20",
  "elk.direction": "LEFT",
  "elk.alignment": "CENTER",
  "elk.layered.nodePlacement.bk.fixedAlignment": "BALANCED",
  "elk.layered.considerModelOrder.strategy": "PREFER_NODES",
  "elk.separateConnectedComponents": false,
  "partitioning.activate": true,
  "elk.layered.crossingMinimization.forceNodeModelOrder": true,
};

const getLayoutWithElk = (nodes, edges) => {
  const graph = {
    id: "root",
    layoutOptions: elkOptions,
    children: nodes.map((node) => ({
      ...node,
      layoutOptions: {
        "partitioning.partition": node.depth,
      },
    })),
    edges,
  };

  return (
    elk
      .layout(graph)
      .then((layoutedGraph) => {
        return [
          layoutedGraph.children.map((node) => ({
            ...node,
            position: { x: node.x, y: node.y },
          })),
          layoutedGraph.edges,
        ];
      })
      // eslint-disable-next-line no-console
      .catch(console.error)
  );
};

export default getLayoutWithElk;
