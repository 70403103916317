import { Button, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

import icons from "src/config/icons";
import { useModal } from "src/contexts/withModal";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import AddAssociationModal from "src/components/AddTeamAssociationModal";

const AddAssociationButton = ({ team, groupTypes, refetch, tagsVisibleIn }) => {
  const [, dispatch] = useModal();

  const onDisplayAddAssociation = useCallback(() => {
    const onToggleVisibility = (isModalVisible) => () => {
      dispatch({ type: "setModalVisible", isModalVisible });
      refetch();
    };
    const modalComponent = (
      <AddAssociationModal
        group={team}
        groupTypes={groupTypes}
        isVisible={true}
        onHideModal={onToggleVisibility(false)}
        tagsVisibleIn={tagsVisibleIn}
      />
    );
    dispatch({ type: "setModalComponent", modalComponent });
  }, [team, groupTypes, tagsVisibleIn, dispatch, refetch]);

  return (
    <Button
      variant="ghost"
      small
      iconLeft
      data-testid="add-association-button"
      onClick={onDisplayAddAssociation}
      mt="r"
    >
      <Icon icon={icons.pen} />
      Add Association
    </Button>
  );
};

AddAssociationButton.propTypes = {
  team: GroupPropType,
  groupTypes: GroupTypesPropType,
  refetch: PropTypes.func,
  tagsVisibleIn: PropTypes.string,
};

export default AddAssociationButton;
