import React from "react";
import {
  Modal,
  H3,
  P,
  Flex,
  Spacer,
  Button,
  Loading,
} from "orcs-design-system";
import PropTypes from "prop-types";
import { get } from "lodash";

import ErrorNotification from "src/components/ErrorNotification";
import { useAllocationProject } from "src/contexts/global/GlobalContext";
import * as logic from "./HideTeamDialog.logic";

const getHideTeamDesc = (group, allocationProject) => {
  return group.isHidden ? (
    <>
      <P mt="r" mb="r">
        <strong>{group.name}</strong> is currently hidden.
      </P>
      <P mt="r" mb="r">
        This action will un-hide <strong>{group.name}</strong> and make it
        visible from all views.
      </P>
    </>
  ) : (
    <>
      <P mt="r" mb="r">
        <strong>{group.name}</strong> will be hidden from all views.
      </P>
      <P mt="r" mb="r">
        <ul>
          <li>
            Planning requests and approvals for{" "}
            {allocationProject.allocationName} will be set to zero for{" "}
            {group.name}
          </li>
          <li>
            All {group.name} members will be deallocated for{" "}
            {allocationProject.allocationName}
          </li>
          <li>Unhidding this team will not auto-reallocate members</li>
        </ul>
      </P>
    </>
  );
};

const HideTeamDialog = ({
  selectedTargetGroup,
  visible,
  loading,
  error,
  onDismissHideTeamModal,
  onHideTeam,
}) => {
  const { id, isHidden, type } = selectedTargetGroup;
  const allocationProject = useAllocationProject();
  const hideTeamDesc = getHideTeamDesc(selectedTargetGroup, allocationProject);

  const modalFooter = (
    <Flex>
      <Spacer mr="s">
        <Button
          px="l"
          onClick={onHideTeam(id, !isHidden)}
          disabled={loading || error}
          variant={(loading || error) && "disabled"}
        >
          OK
        </Button>
        <Button
          variant="ghost"
          onClick={onDismissHideTeamModal}
          disabled={loading}
        >
          Cancel
        </Button>
      </Spacer>
    </Flex>
  );

  const modalHeader = (
    <H3>
      {logic.getModalHeaderVerb(isHidden, loading)} <strong>{type}</strong>
    </H3>
  );

  return (
    <Modal
      headerContent={modalHeader}
      visible={visible}
      width="500px"
      height="330px"
      maxHeight="90vh"
      maxWidth="90vw"
      onClose={onDismissHideTeamModal}
      footerContent={modalFooter}
    >
      {loading && <Loading large centered mt="r" />}
      {error && (
        <>
          <P my="r">An error occurred</P>
          <ErrorNotification error={error} message={get(error, "message")} />
        </>
      )}
      {!loading && !error && (
        <>
          {hideTeamDesc}
          <P>Do you wish to continue?</P>
        </>
      )}
    </Modal>
  );
};

HideTeamDialog.propTypes = {
  selectedTargetGroup: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onDismissHideTeamModal: PropTypes.func,
  onHideTeam: PropTypes.func,
};

export default HideTeamDialog;
