import React from "react";
import PropTypes from "prop-types";
import { P } from "orcs-design-system";
import themeGet from "@styled-system/theme-get";
import styled from "styled-components";

import FormattedCommentContent from "./FormattedCommentContent";

const SytledUserComment = styled(P)`
  background: ${(props) =>
    props.isHistorical
      ? themeGet("colors.greyLight")
      : themeGet("colors.primaryDarker")};
  border-radius: ${themeGet("radii.2")};
  padding: 10px 14px;
  line-height: 2rem;
  margin: 6px 0 0 0;
  color: ${themeGet("colors.white")};
  font-size: 1.4rem;
  position: relative;
  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 6px;
    top: -6px;
    border-style: solid;
    border-width: 0 8px 7px 8px;
    border-color: ${(props) =>
      props.isHistorical
        ? `transparent transparent ${themeGet("colors.greyLight")(
            props
          )} transparent`
        : `transparent transparent ${themeGet("colors.primaryDarker")(
            props
          )} transparent`};
  }

  h1,
  h2 {
    line-height: 3rem;
    margin-bottom: 1rem;
  }
`;

const UserComment = ({ content, contentMarkup }) => (
  <SytledUserComment>
    <FormattedCommentContent content={content} contentMarkup={contentMarkup} />
  </SytledUserComment>
);

UserComment.propTypes = {
  content: PropTypes.string,
  contentMarkup: PropTypes.string,
};
export default UserComment;
