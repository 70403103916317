import hash from "object-hash";

import { map, forEach, isEmpty } from "lodash";

const hashCell = (cell, { rehashParents = false } = {}) => {
  const {
    id,
    isStatic,
    isEditable,
    value,
    defaultedValue,
    prepopulatedValue,
    delta,
    skills,
    noteCount,
    valueSaveStatus,
    skillsSaveStatus,
    notification,
    isSkillsOpen,
    approvalReasonId,
    approvalReasonCustomText,
    totalCost,
    realtimeCurrentMemberFte,
    currentFteSum,
  } = cell;

  const { status, message, groupMessage, columnMessage } = notification;

  // eslint-disable-next-line no-param-reassign
  cell.hash = hash({
    id,
    isStatic,
    isEditable,
    value,
    defaultedValue,
    prepopulatedValue,
    delta,
    skills,
    noteCount,
    valueSaveStatus,
    skillsSaveStatus,
    isSkillsOpen,
    approvalReasonId,
    approvalReasonCustomText,
    totalCost,
    status,
    message,
    groupMessage,
    columnMessage,
    realtimeCurrentMemberFte,
    currentFteSum,
  });

  if (rehashParents) {
    // eslint-disable-next-line
    hashLineItem(cell.lineItem, {
      rehashChildren: false,
      rehashParents: true,
    });
  }
};

const hashConstraintCell = (cell, { rehashParents = false } = {}) => {
  const {
    id,
    isStatic,
    isEditable,
    constraint,
    defaultedConstraint,
    constraintSaveStatus,
  } = cell;

  // eslint-disable-next-line no-param-reassign
  cell.hash = hash({
    id,
    isStatic,
    isEditable,
    constraint,
    defaultedConstraint,
    constraintSaveStatus,
  });

  if (rehashParents) {
    // eslint-disable-next-line
    hashLineItem(cell.lineItem, {
      rehashChildren: false,
      rehashParents: true,
    });
  }
};

const hashLineItem = (
  lineItem,
  { rehashChildren = true, rehashParents = false } = {}
) => {
  if (rehashChildren && !isEmpty(lineItem.cells)) {
    forEach(lineItem.cells, (cell) => {
      hashCell(cell);
    });
  }

  // eslint-disable-next-line no-param-reassign
  lineItem.hash = hash({
    name: lineItem.group.name,
    isHidden: lineItem.group.isHidden,
    groupingId: lineItem.groupId,
    memberCount: lineItem.memberCount,
    totalCost: lineItem.totalCost,
    cellHashes: map(lineItem.cells, "hash"),
  });

  if (rehashParents) {
    // eslint-disable-next-line
    hashGrouping(lineItem.grouping, {
      rehashChildren: false,
      rehashParents: true,
    });
  }
};

const hashGrouping = (
  grouping,
  { rehashChildren = true, rehashParents = false } = {}
) => {
  if (rehashChildren && !isEmpty(grouping.childGroupings)) {
    forEach(grouping.childGroupings, (childGrouping) => {
      hashGrouping(childGrouping);
    });
  }

  if (rehashChildren && !isEmpty(grouping.lineItems)) {
    forEach(grouping.lineItems, (lineItem) => {
      hashLineItem(lineItem, { rehashChildren });
    });
  }

  if (rehashChildren && !isEmpty(grouping.constraints)) {
    forEach(grouping.constraints, (constraint) => {
      if (constraint) {
        hashConstraintCell(constraint);
      }
    });
  }

  const extraProps = {};

  if (grouping.isMovedOut && grouping.newParent) {
    extraProps.newParentId = grouping.newParent.id;
  }

  if (!isEmpty(grouping.tags)) {
    extraProps.tags = map(grouping.tags, (t) => t.id);
  }

  // eslint-disable-next-line no-param-reassign
  grouping.hash = hash({
    groupingId: grouping.groupId,
    name: grouping.group.name,
    objectiveIds: grouping.group.objectiveIds,
    linkedStrategies: grouping.group.linkedStrategies,
    isHidden: grouping.group.isHidden,
    isObjectivesImported: grouping.isObjectivesImported,
    isObjectivesVerified: grouping.isObjectivesVerified,
    showObjectives: grouping.showObjectives,
    canRename: grouping.canRename,
    canHide: grouping.isObjectivesImported,
    canSubmit: grouping.canSubmit,
    canOnlySubmitWhenAllValid: grouping.canOnlySubmitWhenAllValid,
    canEditObjectives: grouping.canEditObjectives,
    isSkillsMandatory: grouping.isSkillsMandatory,
    totalCost: grouping.totalCost,
    isMovedOut: grouping.isMovedOut,
    isMovedIn: grouping.isMovedIn,
    isMovedInternally: grouping.isMovedInternally,
    childGroupingHashes: map(grouping.childGroupings, "hash"),
    lineItemHashes: map(grouping.lineItems, "hash"),
    constraintHashes: map(grouping.constraints, "hash"),
    ...extraProps,
  });

  if (rehashParents && grouping.parent) {
    hashGrouping(grouping.parent, {
      rehashChildren: false,
      rehashParents: true,
    });
  }
};

export { hashGrouping, hashLineItem, hashCell, hashConstraintCell };
