import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { Box, TextInput } from "orcs-design-system";

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 38px;
`;

export const StyledTextInput = styled(TextInput)`
  width: 55px;
  text-align: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const StyledLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-self: stretch;
  border-radius: 0 ${({ theme }) => theme.radii[2]}
    ${({ theme }) => theme.radii[2]} 0;
  border: solid 1px ${({ theme }) => theme.colors.greyLight};
  border-left: none;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0 8px;
  color: ${({ theme }) => theme.colors.greyDarker};
  background-color: ${({ theme }) => theme.colors.greyLightest};
`;

export const OptionWrapper = styled(Box)`
  position: absolute;
  top: 120%;
  z-index: 10;
  transform: translateX(-65%);

  &:before,
  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 70%;
    top: -7px;
    border-style: solid;
    border-width: 0 8px 7px 8px;
  }

  &:before {
    border-color: ${(props) =>
      `transparent transparent ${themeGet("colors.greyLighter")(
        props
      )} transparent`};
  }

  &:after {
    top: -5px;
    border-color: ${(props) =>
      `transparent transparent ${themeGet("colors.white")(props)} transparent`};
  }
`;
