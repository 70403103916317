import PropTypes from "prop-types";
import React from "react";

const Leaf = ({ attributes, children, leaf }) => {
  let component = children;

  if (leaf.bold) {
    component = <strong data-testid="cp-rte-leaf-bold">{component}</strong>;
  }

  // if (leaf.code) {
  //   children = <code>{children}</code>;
  // }

  if (leaf.italic) {
    component = <em data-testid="cp-rte-leaf-italic">{component}</em>;
  }

  if (leaf.underline) {
    component = <u data-testid="cp-rte-leaf-underline">{component}</u>;
  }

  return (
    <span data-testid="cp-rte-leaf" {...attributes}>
      {component}
    </span>
  );
};

Leaf.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  leaf: PropTypes.object,
};

export default Leaf;
