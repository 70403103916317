import React from "react";
import PropTypes from "prop-types";
import { map, orderBy, size } from "lodash";
import styled from "styled-components";
import { Loading } from "orcs-design-system";

import { fullDisplayName } from "src/util/personName";
import {
  useLookupData,
  useViewMode,
  useSearchMode,
  ACTIONS,
  VIEW_MODES,
  useSearchResult,
  useMembersFilter,
  useRootGroup,
} from "../../../context/ForecastContext";
import useGridConfig from "../../util/gridConfig";
import { isRequestorPlannerReadonly } from "../../util/readonlyFlags";
import CustomGrid from "../CustomGrid";

import { getColumns } from "./utils/groupMembers.util";
import MemberRow from "./MemberRow";
import useLoadingGroupMembers from "./useLoadingGroupMembers";
import { getNewMembersFte } from "./utils/person.util";

const CustomCustomGrid = styled(CustomGrid)`
  margin: 5px 0 5px 16px;
  width: calc(100% - 16px);
`;

const Members = ({
  lineItem,
  isForecast = false,
  withExtraColumn = false,
  dispatch,
}) => {
  const requestorCell = isForecast ? lineItem.cells[0] : lineItem.cells[1];
  const { sourceGroupId, targetGroupId } = requestorCell;

  const rootGroup = useRootGroup();
  const viewMode = useViewMode();
  const searchMode = useSearchMode();
  const searchResult = useSearchResult();
  const lookupData = useLookupData();

  const {
    showMemberTags,
    hideApproverColumn,
    hideBudgetColumn,
    enableMemberAllocation,
    enableMembersFilter,
    enableDeparturedPeopleTagEditing,
    activeAllocationProject,
    showMemberMoveBadge,
    enableMemberFteEdit,
    enableTagsAppliedCount,
  } = lookupData;

  const membersFilter = useMembersFilter();

  const { peopleList, variables, loading, getPeopleRefetch } =
    useLoadingGroupMembers({
      lineItem,
      sourceGroupId,
      targetGroupId,
      waitForLoadRequest: searchMode,
      presetData: searchResult,
      showMemberMoveBadge,
    });

  const updatePersonFte = (personId, newFte, currentFte) => {
    dispatch({
      type: ACTIONS.REALTIME_CURRENT_MEMBER_FTE_UPDATE,
      cellId: requestorCell.id,
      value: getNewMembersFte({
        requestorCell,
        newFte,
        currentFte,
      }),
    });
  };

  const isPageReadonly = isRequestorPlannerReadonly(activeAllocationProject);
  const isAllocationEnabled =
    enableMemberAllocation &&
    viewMode === VIEW_MODES.GROUPED_BY_DEMAND &&
    !isPageReadonly;

  const [templateColumns, gap] = getColumns(
    useGridConfig(),
    requestorCell,
    isForecast,
    enableMemberFteEdit && rootGroup.isDemand,
    hideApproverColumn,
    hideBudgetColumn
  );

  if (loading) {
    return <Loading p="s" ml="l" data-testid="planner-members" />;
  }

  const onAllocatePerson = (person) => {
    dispatch({
      type: ACTIONS.OPEN_ALLOCATION_MODAL,
      targetPerson: person,
      searchVariables: variables,
    });
  };

  const onMovePerson = (person) => {
    dispatch({
      type: ACTIONS.SHOW_MOVE_MEMBER_MODAL,
      grouping: lineItem.grouping,
      person,
      searchVariables: variables,
      getPeopleRefetch,
    });
  };

  const onRemovePerson = (person) => {
    dispatch({
      type: ACTIONS.SHOW_REMOVE_MEMBER_MODAL,
      grouping: lineItem.grouping,
      person,
      searchVariables: variables,
    });
  };

  // sort people alphabetically
  const members = orderBy(
    peopleList,
    (person) => {
      return fullDisplayName(person).toLowerCase();
    },
    ["asc"]
  );
  const lastMemberIndex = size(members) - 1;

  return (
    <CustomCustomGrid
      data-testid="planner-members"
      gridTemplateColumns={templateColumns}
      gridGap={gap}
      className="guided-tour-allocation-planner-members"
    >
      {map(members, (member, index) => {
        return (
          <MemberRow
            key={member.aggregateId}
            isForecast={isForecast}
            person={member}
            lineItem={lineItem}
            enableMemberFteEdit={enableMemberFteEdit && !isForecast}
            targetGroupId={targetGroupId}
            sourceGroupId={sourceGroupId}
            withExtraColumn={withExtraColumn}
            showMemberTags={showMemberTags}
            isAllocationEnabled={isAllocationEnabled}
            showMemberMoveBadge={showMemberMoveBadge}
            onAllocatePerson={onAllocatePerson}
            onMovePerson={onMovePerson}
            onRemovePerson={onRemovePerson}
            searchVariables={variables}
            isLast={index === lastMemberIndex}
            isPageReadonly={isPageReadonly}
            isTagsReadOnly={
              !enableDeparturedPeopleTagEditing && member.disabled
            }
            membersFilter={enableMembersFilter ? membersFilter : null}
            updatePersonFte={updatePersonFte}
            enableTagsAppliedCount={enableTagsAppliedCount}
            hideApproverColumn={hideApproverColumn}
            hideBudgetColumn={hideBudgetColumn}
          />
        );
      })}
    </CustomCustomGrid>
  );
};

Members.propTypes = {
  lineItem: PropTypes.object.isRequired,
  isForecast: PropTypes.bool,
  withExtraColumn: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

export default Members;
