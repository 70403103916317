import React from "react";
import PropTypes from "prop-types";
import { P } from "orcs-design-system";
import AuthorTitle from "../AuthorTitle";
import { TYPE_FORECAST } from "./consts";

const PlanningSubmissionContent = ({ authorTitle, demandGroup, type }) => {
  const demandGroupName = demandGroup ? demandGroup.name : "";
  const typeDisplay = type === TYPE_FORECAST ? "Forecast" : "Planner";
  const content = `${demandGroupName} made ${typeDisplay} submission`;

  return (
    <>
      <P>{content}</P>
      <AuthorTitle action="Made" authorTitle={authorTitle} />
    </>
  );
};

PlanningSubmissionContent.propTypes = {
  authorTitle: PropTypes.string,
  demandGroup: PropTypes.object,
  type: PropTypes.string,
};

export default PlanningSubmissionContent;
