import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import { map, get } from "lodash";
import { Flex, Text, Small, Spacer } from "orcs-design-system";
import { numberToLocaleString } from "src/util/toLocaleString";

import {
  ACTIONS,
  COLUMN_TYPES,
  useFinancialForecasting,
  useLookupData,
} from "../../../context/ForecastContext";

import useGridConfig from "../../util/gridConfig";
import TotalCostCell from "../../Shared/TotalCostCell";
import CustomGrid from "../../Shared/CustomGrid";
import TreeExpand from "../../Shared/TreeExpand";
import DeltaLabel from "../../Shared/DeltaLabel";
import EmptyColumn from "../../Shared/EmptyColumn";
import { formatLabel, getAction } from "../../util/label";
import DirectReportNotification from "../DirectReportNotification";

const LineItemsExpandHeader = ({
  grouping,
  isExpanded,
  dispatch,
  hideApproverColumn,
}) => {
  const shouldShowCost = useFinancialForecasting();
  const { groupingLineItemsLabel, groupTypesLookup } = useLookupData();
  const {
    group,
    id,
    groupId,
    nestedLevel,
    lineItemTotals,
    lineItemNotifications,
  } = grouping;

  const handleRoleToggle = useCallback(() => {
    dispatch({
      type: ACTIONS.TOGGLE_GROUPING_ROLE_TREE_EXPANSION,
      id,
    });
  }, [id, dispatch]);

  const action = getAction(isExpanded);
  const label = formatLabel(
    groupingLineItemsLabel || "direct {groupType} requests",
    group,
    groupTypesLookup
  );

  const gridConfig = useGridConfig();
  const [templateColumns, gap] = [
    gridConfig.planner.templateColumns.expandHeader,
    gridConfig.planner.gap,
  ];

  return (
    <TreeExpand
      isOpen={isExpanded}
      indentLevel={nestedLevel}
      onToggle={handleRoleToggle}
      controlsGroupRoles={true}
    >
      <CustomGrid gridTemplateColumns={templateColumns} gridGap={gap}>
        <Small
          fontSize={0}
          mr="auto"
          data-testid={`${groupId}-lineItem-toggle`}
        >
          {`Click to ${action} ${label}`}
        </Small>
        {shouldShowCost && (
          <TotalCostCell
            lineItem={{ totalCost: lineItemTotals[1].totalCost }}
          />
        )}
        {map(
          lineItemTotals,
          ({ columnId, columnType, total, deltaTotal }, index) => {
            if (columnType === COLUMN_TYPES.PLANNER_CURRENT) {
              return (
                <Text weight="bold" key={columnId}>
                  {numberToLocaleString(total)}
                </Text>
              );
            }

            if (
              columnType === COLUMN_TYPES.PLANNER_APPROVED &&
              hideApproverColumn
            ) {
              return <EmptyColumn key={columnId} />;
            }

            return (
              <Fragment key={columnId}>
                <Flex
                  alignItems="center"
                  justifyContent={hideApproverColumn ? "center" : "flex-end"}
                  width="100%"
                >
                  <Spacer mx="xs">
                    <Text bold>{numberToLocaleString(total)}</Text>
                    <DeltaLabel delta={deltaTotal} />
                  </Spacer>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <DirectReportNotification
                    grouping={grouping}
                    groupTypesLookup={groupTypesLookup}
                    existingNotification={get(
                      lineItemNotifications[index],
                      "notification"
                    )}
                  />
                </Flex>
              </Fragment>
            );
          }
        )}
        <div />
      </CustomGrid>
    </TreeExpand>
  );
};

LineItemsExpandHeader.propTypes = {
  grouping: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  hideApproverColumn: PropTypes.bool,
};

export default LineItemsExpandHeader;
