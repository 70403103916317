import React from "react";
import PropTypes from "prop-types";
import { isEmpty, reduce, get } from "lodash";
import { Popover, Icon } from "orcs-design-system";
import { SUBMITTED, SAVED } from "src/allocation/consts";

const GroupStatus = (props) => {
  const { macroAllocations = [], statusField, directionRight } = props;

  if (isEmpty(macroAllocations)) {
    return null;
  }

  const stats = reduce(
    macroAllocations,
    (result, macroAllocation) => {
      const status = get(macroAllocation, statusField);

      if (status === SAVED) {
        return { ...result, saved: result.saved + 1 };
      }

      if (status === SUBMITTED) {
        return { ...result, submitted: result.submitted + 1 };
      }

      return { ...result, unsaved: result.unsaved + 1 };
    },
    { unsaved: 0, saved: 0, submitted: 0 }
  );

  const direction = !directionRight ? "top" : "right";

  if (stats.saved > 0) {
    return (
      <Popover
        direction={direction}
        width="147px"
        textAlign="center"
        text="You have unsubmitted changes"
      >
        <Icon icon={["fas", "cloud-upload"]} color="warningDark" />
      </Popover>
    );
  }

  if (stats.submitted > 0) {
    return (
      <Popover
        direction={direction}
        width="147px"
        textAlign="center"
        text="You have submitted changes"
      >
        <Icon icon={["fas", "cloud-upload"]} color="successDark" />
      </Popover>
    );
  }
  return null;
};

GroupStatus.propTypes = {
  macroAllocations: PropTypes.array,
  statusField: PropTypes.string,
  directionRight: PropTypes.bool,
};

export default GroupStatus;
