import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { map, values } from "lodash";
import { Text } from "slate";
import {
  BLOCK_TAGS,
  INLINE_TAGS,
  parseSlateBlock,
  parseSlateInline,
  parseLeaf,
} from "./renderNodes";

const BLOCK_TAG_TYPES = values(BLOCK_TAGS);
const INLINE_TAG_TYPES = values(INLINE_TAGS);

const isBlock = (node) => BLOCK_TAG_TYPES.indexOf(node.type) > -1;
const isInline = (node) => INLINE_TAG_TYPES.indexOf(node.type) > -1;

const parseNode = (node, children, reverse) => {
  if (isBlock(node)) {
    return parseSlateBlock(node, children, reverse);
  }

  if (isInline(node)) {
    return parseSlateInline(node, children, reverse);
  }

  return null;
};

const serializeNode = (node, reverse) => {
  if (Text.isText(node)) {
    return parseLeaf(node);
  }

  const children = map(node.children, (n) => serializeNode(n, reverse));

  return parseNode(node, children, reverse);
};

const serializeToHtml = (msg, reverse) => {
  const elements = map(msg, (m) => serializeNode(m, reverse)).filter(
    (el) => el
  );

  const html = renderToStaticMarkup(<body>{elements}</body>);

  const inner = html.slice(6, -7);
  return inner;
};

export default serializeToHtml;
