import React from "react";
import { get } from "lodash";
import { H1 } from "orcs-design-system";
import GroupPropType from "src/custom-prop-types/group";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";

import TeamNameEditor from "./TeamNameEditorContainer";

const TeamName = ({ team }) => {
  const {
    config: { groupTypes },
  } = useWorkspace();

  const { type, name, isDeleted, isHidden } = team;
  const canRename =
    get(groupTypes[type], "canRename", false) && !isDeleted && !isHidden;

  if (!canRename) {
    if (isDeleted || isHidden) {
      return (
        <H1 className="guided-tour-team-details-team-name">
          <strike>{name}</strike>
        </H1>
      );
    }
    return <H1 className="guided-tour-team-details-team-name">{name}</H1>;
  }

  return <TeamNameEditor team={team} />;
};

TeamName.propTypes = {
  team: GroupPropType.isRequired,
};

export default TeamName;
