import React, { useMemo } from "react";

import { useHistory } from "react-router-dom";
import { filter, get, includes } from "lodash";
import PropTypes from "prop-types";
import GroupPropType from "src/custom-prop-types/group";

import { visualisationTeamPath } from "src/util/routing";
import { canCreateChild } from "src/util/customerConfig";
import {
  useGroupTypes,
  useWorkspace,
} from "src/contexts/global/WorkspaceContext";
import { useAuth } from "src/contexts/global/GlobalContext";
import { hasAdminRole } from "src/services/auth";

import MoveGroupEnhancedModal from "src/components/MoveGroupEnhancedModal";
import CloneHierarchyModal from "src/components/CloneHierarchyModal";
import MoveGroupModal from "src/components/MoveGroupModal";
import ConfiguredActionMenu, {
  GLOBAL_ACTION_KEYS,
} from "src/components/ConfiguredActionMenu";
import useUpdateTeamDetails from "src/hooks/useUpdateTeamDetails";
import Unsplash from "src/components/Unsplash";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import { groupTypeAsText } from "src/util/groupType";
import HideTeamModal from "../../HideTeamModal";
import useHideTeam from "./hooks/useHideTeam";
import AddNewTeamModal from "./Modals/AddNewTeamModal";
import TeamDescriptionEditModal from "./Modals/TeamDescriptionEditModal";

const TEAM_ACTION_MENU_TYPE = {
  EXPLORE_IN_ORG: "explore_in_org",
  CHANGE_TEAM_DETAILS: "change_team_details",
  ADD_TEAM: "add_team",
  DOWNLOAD: "download",
  MOVE_TEAM: "move_team",
  ADVANCED_MOVE: "advanced_move",
  REMOVE_TEAM: "remove_team",
  CLONE_HIERARCHY: "clone_hierarchy",
  SELECT_COVER_IMAGE: "select_cover_image",
};

const defaultActionMenuTypes = [
  TEAM_ACTION_MENU_TYPE.EXPLORE_IN_ORG,
  TEAM_ACTION_MENU_TYPE.CHANGE_TEAM_DETAILS,
  TEAM_ACTION_MENU_TYPE.ADD_TEAM,
  TEAM_ACTION_MENU_TYPE.DOWNLOAD,
  TEAM_ACTION_MENU_TYPE.MOVE_TEAM,
  TEAM_ACTION_MENU_TYPE.ADVANCED_MOVE,
  TEAM_ACTION_MENU_TYPE.REMOVE_TEAM,
  TEAM_ACTION_MENU_TYPE.CLONE_HIERARCHY,
  TEAM_ACTION_MENU_TYPE.SELECT_COVER_IMAGE,
];

const TeamDetailsActionMenu = ({
  team,
  showInsightIcon = true,
  actionMenuTypes = defaultActionMenuTypes,
  direction = "left-start",
  runGroupCacheUpdateOnAddition = true,
}) => {
  const canHide = useHideTeam(team);
  const history = useHistory();
  const workspace = useWorkspace();
  const auth = useAuth();
  const { handleUpdateTeamDetails, canUpdateGroupAttributes } =
    useUpdateTeamDetails({ team });

  const groupTypes = useGroupTypes();

  const actions = useMemo(() => {
    const config = get(groupTypes, team.type, {});
    const moveGroupEnabled = get(
      workspace,
      "config.allocation.enableMoveGroups",
      false
    );
    const moveGroupEnhancedEnabledFlag = get(
      workspace,
      "config.featureFlags.enableEnhancedMove",
      false
    );
    const moveGroupEnhancedEnabled =
      hasAdminRole(auth) && moveGroupEnhancedEnabledFlag;

    const enableCloneHierarchyFlag = get(
      workspace,
      "config.featureFlags.enableCloneHierarchy",
      false
    );
    const cloneHierarchyEnabled =
      hasAdminRole(auth) && enableCloneHierarchyFlag;
    const teamType = get(groupTypes, team.type, {
      name: team.type,
      noConfig: true,
    });

    const defaultActions = [
      {
        type: TEAM_ACTION_MENU_TYPE.EXPLORE_IN_ORG,
        className: "guided-tour-team-details-explore-in-org",
        label: "Explore in organisation",
        onClick: () => {
          trackEvent(EVENT_TRACKING.TEAM_CLICKED, {
            menu: "team_details_action_menu",
            menu_item: "explore_in_org",
          });
          history.push(visualisationTeamPath(team));
        },
      },
      {
        type: TEAM_ACTION_MENU_TYPE.CHANGE_TEAM_DETAILS,
        isHidden: !team.type,
        label: `Change ${groupTypeAsText(teamType)} details`,
        modal: {
          Component: TeamDescriptionEditModal,
          name: "TeamDescriptionEditModal",
          props: { group: team },
        },
      },
      {
        type: TEAM_ACTION_MENU_TYPE.ADD_TEAM,
        isHidden: !canCreateChild(groupTypes, team),
        label: `Add ${config.isDemand ? "team" : "group"}`,
        modal: {
          Component: AddNewTeamModal,
          name: "AddNewTeamModal",
          props: { group: team, runGroupCacheUpdateOnAddition },
        },
      },
      {
        type: TEAM_ACTION_MENU_TYPE.DOWNLOAD,
        globalAction: GLOBAL_ACTION_KEYS.DOWNLOAD,
      },
      {
        type: TEAM_ACTION_MENU_TYPE.MOVE_TEAM,
        isHidden: !moveGroupEnabled,
        label: `Move ${config.isDemand ? "team" : "group"}`,
        modal: {
          Component: MoveGroupModal,
          name: "MoveGroupModal",
          props: { group: team, groupTypes },
        },
      },
      {
        // isHidden: !canMoveGroupEnhanced(auth, workspace),
        type: TEAM_ACTION_MENU_TYPE.ADVANCED_MOVE,
        isHidden: !moveGroupEnhancedEnabled,
        label: "Advanced Move",
        modal: {
          Component: MoveGroupEnhancedModal,
          name: "MoveGroupEnhancedModal",
          props: { rootGroup: team, groupTypes },
        },
      },
      {
        type: TEAM_ACTION_MENU_TYPE.REMOVE_TEAM,
        isHidden: !canHide,
        label: "Remove team",
        modal: {
          Component: HideTeamModal,
          name: "HideTeamModal",
          props: { group: team },
        },
      },
      {
        type: TEAM_ACTION_MENU_TYPE.CLONE_HIERARCHY,
        isHidden: !cloneHierarchyEnabled,
        label: "Clone hierarchy",
        modal: {
          Component: CloneHierarchyModal,
          name: "CloneHierarchyModal",
          props: { cloneToTeam: team, groupTypes },
        },
      },
      {
        type: TEAM_ACTION_MENU_TYPE.SELECT_COVER_IMAGE,
        isHidden: !canUpdateGroupAttributes,
        label: "Select cover image",
        modal: {
          // eslint-disable-next-line react/prop-types
          Component: ({ onHideModal, isVisible }) => (
            <Unsplash
              attributes={get(team, "attributes")}
              onHandleTeamUpdate={handleUpdateTeamDetails}
              isVisible={isVisible}
              onHideModal={onHideModal}
            />
          ),
          name: "UpdateBackgroundModal",
        },
      },
    ];

    const filteredActions = filter(defaultActions, (action) => {
      return includes(actionMenuTypes, action.type);
    });

    return filteredActions;
  }, [
    groupTypes,
    team,
    workspace,
    auth,
    canHide,
    canUpdateGroupAttributes,
    history,
    handleUpdateTeamDetails,
    actionMenuTypes,
    runGroupCacheUpdateOnAddition,
  ]);

  return (
    <ConfiguredActionMenu
      direction={direction}
      ml="r"
      className="guided-tour-team-details-view-actions-menu"
      data-testid="cp-team-details-action-menu"
      actions={actions}
      team={team}
      showInsightIcon={showInsightIcon}
    />
  );
};

TeamDetailsActionMenu.propTypes = {
  team: GroupPropType.isRequired,
  showInsightIcon: PropTypes.bool,
  actionMenuTypes: PropTypes.arrayOf(PropTypes.string),
  direction: PropTypes.string,
  runGroupCacheUpdateOnAddition: PropTypes.bool,
};

export default TeamDetailsActionMenu;
