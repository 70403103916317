import React from "react";
import { Switch } from "react-router-dom";

import PropTypes from "prop-types";
import { get } from "lodash";
import PATHS, { TEAM_TAB } from "src/consts/urlPaths";
import Details from "src/components/TeamDetailPage/Details";
import TeamHistory from "src/components/TeamDetailPage/TeamHistory";
import GroupPropType from "src/custom-prop-types/group";
import getGroupFeatureVisibility from "src/util/getGroupFeatureVisibility";
import { useAllocationProject } from "src/contexts/global/GlobalContext";
import {
  useWorkspace,
  useWorkspaceFeatureFlags,
} from "src/contexts/global/WorkspaceContext";
import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import { useAuth0 } from "src/contexts/auth0Provider";
import RestrictedRoute from "src/app/Restricted/Route";
import allocationTabRoutes from "./allocationTabRoutes";
import PrinciplesTabPage from "./PrinciplesTabPage";
import VisualisationTabPage from "./VisualisationTabPage";
import TabPermitted from "./TabPermitted";
import AssociationsTabPage from "./AssociationsTabPage";
import StrategyTab from "./tabs/StrategyTab";
import StrategyTabV2 from "./tabs/TeamStrategyTab";
import MembershipsTab from "./tabs/MembershipsTab";

const Routes = ({ teamId, team, loading, refetch }) => {
  const { user } = useAuth0();
  const featureFlags = useFeatureFlags();
  const allocationProject = useAllocationProject();
  const workspace = useWorkspace();

  const {
    enableTeamStrategyTab,
    enableFutureAllocationsUI,
    enableFutureAllocationsTimeline,
  } = useWorkspaceFeatureFlags();
  const { config } = workspace;

  const disableAssociations = get(config, "featureFlags.disableAssociations");

  const groupFeatureVisibility = getGroupFeatureVisibility({
    group: team,
    config,
    featureFlags,
  });
  const allocationTabRoutesLoaded = !loading && team;

  return (
    <>
      {team && (
        <RestrictedRoute
          path={`${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.DETAILS}`}
        />
      )}
      <Switch>
        <RestrictedRoute
          exact
          path={`${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.DETAILS}`}
        >
          <Details
            team={team}
            featureFlags={featureFlags}
            refetch={refetch}
            loading={loading}
          />
        </RestrictedRoute>
        {enableFutureAllocationsUI && enableFutureAllocationsTimeline && (
          <RestrictedRoute
            exact
            path={`${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.MEMBERSHIPS}`}
          >
            <MembershipsTab key={team.id} team={team} isLoadingTeam={loading} />
          </RestrictedRoute>
        )}
        {enableTeamStrategyTab && (
          <RestrictedRoute
            exact
            path={`${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.STRATEGY}`}
          >
            {featureFlags["strategy-visualiser-v2"] ? (
              <StrategyTabV2 team={team} isLoadingTeam={loading} />
            ) : (
              <StrategyTab team={team} isLoadingTeam={loading} />
            )}
          </RestrictedRoute>
        )}

        {!disableAssociations && (
          <RestrictedRoute
            path={`${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.ASSOCIATIONS}`}
          >
            <AssociationsTabPage teamId={teamId} team={team} user={user} />
          </RestrictedRoute>
        )}
        <RestrictedRoute
          path={`${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.VISUALISATION}`}
        >
          <TabPermitted
            group={team}
            tabType={TEAM_TAB.VISUALISATION}
            groupFeatureVisibility={groupFeatureVisibility}
          >
            <VisualisationTabPage history={history} />
          </TabPermitted>
        </RestrictedRoute>
        <RestrictedRoute
          path={`${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.PRINCIPLES}`}
        >
          <TabPermitted
            group={team}
            tabType={TEAM_TAB.PRINCIPLES}
            groupFeatureVisibility={groupFeatureVisibility}
          >
            <PrinciplesTabPage
              history={history}
              team={team}
              featureFlags={featureFlags}
              refetchTeamDetail={refetch}
            />
          </TabPermitted>
        </RestrictedRoute>
        {allocationTabRoutesLoaded &&
          allocationTabRoutes(team, allocationProject, featureFlags, workspace)}
        <RestrictedRoute
          path={`${PATHS.TEAM_DETAILS_BASE_PATH}/${TEAM_TAB.HISTORY}`}
          key={TEAM_TAB.HISTORY}
        >
          <TabPermitted
            group={team}
            tabType={TEAM_TAB.HISTORY}
            groupFeatureVisibility={groupFeatureVisibility}
          >
            <TeamHistory team={team} />
          </TabPermitted>
        </RestrictedRoute>
      </Switch>
    </>
  );
};

Routes.propTypes = {
  loading: PropTypes.bool.isRequired,
  team: GroupPropType.isRequired,
  refetch: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
};

export default Routes;
