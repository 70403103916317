import React from "react";
import PropTypes from "prop-types";
import { Small, Popover } from "orcs-design-system";
import formatDelta from "../util/formatDelta";

// eslint-disable-next-line react/display-name
const DeltaLabel = React.memo(({ delta, ...others }) => {
  if (delta !== 0) {
    return (
      <Small
        color="secondary"
        display="block"
        fontWeight="600"
        fontSize="1.3rem"
        {...others}
      >
        {formatDelta(delta)}
      </Small>
    );
  }

  return (
    <Popover
      text="NO CHANGE"
      direction="topRight"
      width="92px"
      textAlign="center"
      {...others}
    >
      <Small
        color="secondary"
        display="block"
        fontWeight="600"
        fontSize="1.3rem"
      >
        N/C
      </Small>
    </Popover>
  );
});

DeltaLabel.propTypes = {
  delta: PropTypes.number,
};

export default DeltaLabel;
