import { OPEN_EXPORT_DIALOG, CLOSE_EXPORT_DIALOG } from "./actionTypes";
import initialState from "./initialState";

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case OPEN_EXPORT_DIALOG: {
      return { ...state, dialogOpen: true };
    }
    case CLOSE_EXPORT_DIALOG: {
      return { ...state, dialogOpen: false };
    }
    default: {
      return state;
    }
  }
};
