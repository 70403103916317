import { useEffect, useState, useCallback } from "react";

import { isRealTimeAllocationsEnabled } from "src/util/allocations";

import { canBulkMoveMembers } from "../utils";

const useBulkAction = ({
  user,
  workspace,
  allocationProject,
  groupTypes,
  team,
  setSelectedMembers,
  setStatus,
}) => {
  const [membersFilterOption, setMembersFilterOption] = useState(null);
  const canChangeAllocations = isRealTimeAllocationsEnabled({
    user,
    workspace,
    allocationProject,
  });

  const canBulkMove = canBulkMoveMembers({
    canChangeAllocations,
    workspace,
    groupTypes,
    team,
  });

  const [showBulkAction, setShowBulkAction] = useState(false);

  const reset = useCallback(() => {
    setStatus(null);
    setSelectedMembers({});
    setShowBulkAction(false);
  }, [setSelectedMembers, setStatus]);

  const toggleBulkAction = () => {
    setSelectedMembers({});
    setShowBulkAction(!showBulkAction);
  };

  useEffect(() => {
    return () => {
      if (team?.id) {
        reset();
      }
    };
  }, [team?.id, reset]);

  return {
    showBulkAction,
    toggleBulkAction,
    showBulkEditButton: canBulkMove,
    membersFilterOption,
    setMembersFilterOption,
  };
};

export default useBulkAction;
