import { P, Small } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const PersonButton = styled.button`
  appearance: none;
  display: flex;
  background: transparent;
  border: none;
  padding: 0;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const formatOptionLabel = ({ label, type, value }) => {
  return (
    <PersonButton role="listitem" key={value} tabIndex="-1">
      <P mr="2px">{label}</P>
      <Small ml="auto">{type}</Small>
    </PersonButton>
  );
};

formatOptionLabel.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default formatOptionLabel;
