import React from "react";
import StrategyTableView from "src/pages/TeamDetailPage/tabs/StrategyTab/views/StrategyListView";
import { useObjectivesVisualizer } from "src/pages/TeamDetailPage/tabs/StrategyTab/views/useObjectivesVisualizer";

const TeamDetailsStrategyTable = (team) => {
  const { objectives } = useObjectivesVisualizer(team, {
    queryOptions: {
      includeDescendantTeamObjectives: false,
      includeDirectChildrenObjectives: false,
      includeNotAligned: false,
    },
  });

  return <StrategyTableView objectives={objectives} showChildTeams={false} />;
};

export default TeamDetailsStrategyTable;
