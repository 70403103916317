import { toLower } from "lodash";
import { Flex, FlexItem, P } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const ModalHeader = ({ person, vacantRoleLabel }) => {
  const hasPreferredNameSet =
    toLower(person?.preferredName) !== toLower(vacantRoleLabel);
  return (
    <Flex alignItems="center" data-testid="add-member-modal-header">
      {hasPreferredNameSet ? (
        <FlexItem mr="xs">
          <P>
            Fulfil <span>{person?.preferredName}</span>{" "}
            <span>{vacantRoleLabel}</span>
          </P>
        </FlexItem>
      ) : (
        <FlexItem mr="xs">
          <P>
            Fulfil <span>{vacantRoleLabel}</span>
          </P>
        </FlexItem>
      )}
    </Flex>
  );
};

ModalHeader.propTypes = {
  person: PropTypes.object,
  vacantRoleLabel: PropTypes.string,
};

export default ModalHeader;
