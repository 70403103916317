import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useMainQuery, useViewMode } from "./context/ForecastContext";

const useViewModeRouteUpdater = () => {
  const { loading } = useMainQuery();
  const viewMode = useViewMode();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (loading) {
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const queryParamViewMode = queryParams.get("view");
    if (queryParamViewMode !== viewMode) {
      queryParams.set("view", viewMode);
      history.replace({
        search: `?${queryParams.toString()}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode, loading, location]);
};

export default useViewModeRouteUpdater;
