import {
  get,
  isEmpty,
  map,
  filter,
  find,
  chain,
  has,
  includes,
  forEach,
  some,
  last,
  toLower,
} from "lodash";
import { peopleSearch as peopleSearchQuery } from "src/allocation/members.graphql";
import { shouldLoadIndirectMembers } from "src/allocation/pages/ForecastPage/components/util/groupTypes";

import { convertChildrenToArray } from "src/allocation/util/treeView";

export const getAllRootSupplyGroupTypes = (groupTypes) => {
  const rootTypes = filter(groupTypes, "isRoot");
  const supplyRootTypes = filter(groupTypes, "isSupplyRoot");
  const supplySourceTypes = filter(groupTypes, "isSupplySource");

  if (
    !isEmpty(rootTypes) &&
    !isEmpty(supplyRootTypes) &&
    !isEmpty(supplySourceTypes)
  ) {
    return {
      rootTypes: map(rootTypes, "id"),
      supplyRootTypes: map(supplyRootTypes, "id"),
      supplySourceTypes: map(supplySourceTypes, "id"),
    };
  }

  // not sure if all workspaces have config set up correctly. Going to
  // leave these defaults here in case not
  const defaultGroupTypes = {
    rootTypes: ["Function"],
    supplyRootTypes: ["Chapter Area"],
    supplySourceTypes: ["Chapter", "COE"],
  };
  return defaultGroupTypes;
};

const isExcludedFromNewRole = (group, groupTypes) => {
  if (!group) {
    return false;
  }
  const targetType = get(groupTypes, group.type, {});
  return targetType?.planning?.isExcludedFromNewRole;
};

const canHaveChildren = (type, groupTypes) => {
  const groupType = get(groupTypes, type);

  // If a type doesn't exist in group types... suppose it has children
  if (!groupType || !has(groupType, "childTypes")) {
    return true;
  }

  return !isEmpty(get(groupType, "childTypes"));
};

const isHybridType = (groupTypeConfig) => {
  return (
    groupTypeConfig.isHybrid ||
    (!groupTypeConfig.isDemand && !groupTypeConfig.isSupply)
  );
};

export const createTreeFromGroups = ({
  groups,
  existingGroups,
  expansionLevel,
  groupTypesLookup,
  ignoreDisableGroup,
}) => {
  const root = {};

  forEach(groups, (group) => {
    const { id, name, parentGroups = [], type } = group;

    let parentNode = root;
    forEach(parentGroups, (p, index) => {
      const { id: parentId, name: parentName, type: parentType } = p;

      // Already added in the tree
      if (parentNode[parentId]) {
        parentNode = parentNode[parentId].children;
        return;
      }

      const groupTypeConfig = groupTypesLookup[parentType] || {};
      const isHybrid = isHybridType(groupTypeConfig);
      const isExcluded = groupTypeConfig.planning?.isExcludedFromNewRole;

      const node = {
        id: parentId,
        name: parentName,
        active: false,
        toggled: index + 1 <= expansionLevel,
        checked: false,
        loading: false,
        children: {},
        isDisabled: false,
        hideCheckbox: isHybrid || groupTypeConfig.isDemand || isExcluded,
        group: p,
        config: {
          ...groupTypeConfig,
          isHybrid,
        },
        isParentType: true,
      };

      parentNode[parentId] = node;
      parentNode = node.children;
    });

    const isDisabled = ignoreDisableGroup
      ? false
      : !!find(existingGroups, (eg) => eg.groupId === id);

    const groupTypeConfig = groupTypesLookup[type] || {};

    const existingNode = parentNode[id] || {};

    parentNode[id] = {
      id,
      name,
      active: false,
      toggled: parentGroups.length <= expansionLevel,
      checked: false,
      loading: false,
      children: {},
      isDisabled,
      hideCheckbox: false,
      group,
      config: {
        ...groupTypeConfig,
        isHybrid: false,
      },
      isParentType: true,
      ...existingNode,
    };
  });

  const tree = convertChildrenToArray({ children: root });

  return tree.children || [];
};

export const makeGroupsHierarchy = ({
  groups,
  groupTypesLookup,
  existingGroups,
  expansionLevel,
  searchTerm,
  level = 0,
  filterChildren = false,
  ignoreDisableGroup,
}) => {
  return chain(groups)
    .filter((group) => !isExcludedFromNewRole(group, groupTypesLookup))
    .sortBy((g) => toLower(g.name))
    .map((g) => {
      const groupTypeConfig = groupTypesLookup[g.type] || {};
      const toggled = level + 1 <= expansionLevel;

      const isHybrid = isHybridType(groupTypeConfig);

      const isDisabled = ignoreDisableGroup
        ? false
        : !!find(existingGroups, (eg) => eg.groupId === g.id);
      const isParentType =
        !searchTerm && canHaveChildren(g.type, groupTypesLookup);
      const isLoaded = isParentType ? has(g, "descendantGroups") : true;
      const descendantGroups = filterChildren
        ? filter(g.descendantGroups || [], (dg) => {
            const childTypes = groupTypeConfig?.childTypes;
            return includes(childTypes, dg.type);
          })
        : filter(g.descendantGroups || [], (dg) => {
            // if parent of the group already under descendant groups, do not show the child.
            // This solves the issue with duplicate shown, one under parent and other at the same level of parent
            const parentGroupId = last(dg.parentIds);
            return !some(g.descendantGroups, { id: parentGroupId });
          });

      const childGroups = makeGroupsHierarchy({
        groups: descendantGroups,
        groupTypesLookup,
        existingGroups,
        expansionLevel,
        searchTerm,
        level: level + 1,
        filterChildren,
        ignoreDisableGroup,
      });

      return {
        id: g.id,
        name: g.name,
        active: false,
        toggled,
        checked: false,
        loading: !isLoaded,
        children: !isEmpty(childGroups) ? childGroups : null,
        isDisabled,
        hideCheckbox: isHybrid,
        group: g,
        config: {
          ...groupTypeConfig,
          isHybrid,
        },
        isParentType,
      };
    })
    .value();
};

export const getSupplyRootGroupForNode = ({ node, groupTypesLookup }) => {
  let { parentNode } = node;

  while (parentNode) {
    const { group } = parentNode;

    if (group && get(groupTypesLookup, [group.type, "isSupplyRoot"])) {
      return {
        supplyRoot: group,
      };
    }

    parentNode = parentNode.parentNode;
  }

  return {};
};

export const getFilteredSelectedGroups = (
  existingGroups,
  selectedGroupsFte
) => {
  return existingGroups
    .filter((group) => !!selectedGroupsFte[group.groupId])
    .reduce((acc, group) => {
      const cell = group.cells && group.cells[1];
      acc[group.groupId] = {
        realtimeCurrentMemberFte: (cell && cell.realtimeCurrentMemberFte) || 0,
        value: (cell && cell.value) || 0,
      };
      return acc;
    }, {});
};

export const refetchPeople = ({
  selectedGroupsFte,
  targetGroupId,
  client,
  groupLookup,
  groupTypesLookup,
}) => {
  forEach(Object.keys(selectedGroupsFte), (groupId) => {
    // If group not in the current view, do nothing
    if (!groupLookup[groupId]) {
      return;
    }

    const variables = {
      sourceGroupId: groupId,
      targetGroupId,
      includeIndirectTeamMembers: shouldLoadIndirectMembers(
        groupId,
        groupLookup,
        groupTypesLookup
      ),
      size: 1000,
    };

    // Try to get people search result for the group
    const currentResult = client.readQuery({
      query: peopleSearchQuery,
      variables,
    });

    // If not queried before
    if (!currentResult) {
      return;
    }

    client.refetchQueries({
      include: [
        {
          query: peopleSearchQuery,
          variables,
        },
      ],
    });
  });
};
