export default ({
  lineItem,
  column,
  columnType = null,
  id,
  macroAllocationId,
  targetGroupId,
  sourceGroupId,
  allocationProjectId,
  isStatic,
  isEditable,
  value = null,
  defaultedValue = null,
  prepopulatedValue = null,
  delta = 0,
  skills = null,
  valueSaveStatus = null,
  notification = {},
  approvalReasonId,
  approvalReasonCustomText,
  requestSubmittedAt,
  approvalSavedAt,
  isRequirementInputEnabled,
  totalCost,
  workingDays,
  dailyRate,
  realtimeCurrentMemberFte,
  currentFteSum = null,
}) => ({
  lineItem,
  column,
  columnType,
  id,
  macroAllocationId,
  targetGroupId,
  sourceGroupId,
  allocationProjectId,
  isStatic,
  isEditable,
  value,
  defaultedValue,
  prepopulatedValue,
  delta,
  skills,
  valueSaveStatus,
  skillsSaveStatus: null,
  notification,
  isSkillsOpen: false,
  approvalReasonId,
  approvalReasonCustomText,
  requestSubmittedAt,
  approvalSavedAt,
  isRequirementInputEnabled,
  totalCost,
  workingDays,
  dailyRate,
  realtimeCurrentMemberFte,
  currentFteSum,
});
