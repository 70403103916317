/* eslint-disable import/prefer-default-export */
import { get, isEmpty } from "lodash";

const canHaveDirectReports = ({ groupTypesLookup, grouping }) => {
  const { group, lineItems } = grouping;
  const groupType = get(groupTypesLookup, [group.type]);
  const disableToHaveDirectReports = get(
    groupType,
    "disableToHaveDirectReports",
    false
  );
  if (!disableToHaveDirectReports) {
    return {
      disableToAddMemberOrChapter: false,
      shouldShowDirectReports: true,
    };
  }
  return {
    disableToAddMemberOrChapter: true,
    shouldShowDirectReports: !isEmpty(lineItems),
  };
};

export { canHaveDirectReports };
