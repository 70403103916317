import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { Badge, Card } from "orcs-design-system";
import JiraLogoColor from "src/assets/brand-icons/JiraLogo.svg";
import JiraLogoGrey from "src/assets/brand-icons/JiraLogoGreyscale.svg";
import PlanViewLogo from "src/assets/brand-icons/PlanViewLogo.svg";
import PlanViewLogoGrey from "src/assets/brand-icons/PlanViewLogoGreyscale.svg";
import FileLogo from "src/assets/brand-icons/FileLogo.svg";
import FileLogoGrey from "src/assets/brand-icons/FileLogoGreyscale.svg";
import ClarityLogo from "src/assets/brand-icons/ClarityLogo.svg";
import ClarityLogoGrey from "src/assets/brand-icons/ClarityLogoGreyscale.svg";
import { NODE_THEMES } from "../../consts";

export const THEME = {
  [NODE_THEMES.OBJECTIVE]: {
    color: "success",
    secondaryColor: "successLightest",
    icon: "bullseye-arrow",
  },
  [NODE_THEMES.MEASURE]: {
    color: "success",
    secondaryColor: "successLightest",
    icon: "bullseye",
  },
  [NODE_THEMES.WORK]: {
    color: "secondary",
    secondaryColor: "secondaryEvenLighter",
    icon: "tasks",
  },
};

export const DATASOURCES = {
  jira: {
    name: "Jira",
    iconHovered: JiraLogoColor,
    icon: JiraLogoGrey,
  },
  planview: {
    name: "Planview",
    icon: PlanViewLogoGrey,
    iconHovered: PlanViewLogo,
  },
  strategyfromfile: {
    name: "File",
    icon: FileLogoGrey,
    iconHovered: FileLogo,
  },
  clarityteamsandobjectives: {
    name: "Clarity",
    icon: ClarityLogoGrey,
    iconHovered: ClarityLogo,
  },
};

export const StyledCard = styled(Card)`
  position: relative;
  width: 460px;
  border: solid 2px none;
`;

export const StyledBadge = styled(Badge)`
  display: flex;
  min-width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  background: ${(props) => themeGet(`colors.${props.color}`)};
`;
