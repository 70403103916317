import * as ACTIONS from "../actionTypes";

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.SUPPLY_GROUP_SELECTED: {
      const { selectedSupplyGroupId } = action;
      return {
        ...state,
        variables: {
          ...state.variables,
          approverGroupId: selectedSupplyGroupId,
          skipSupplyGroups: true,
        },
      };
    }
    case ACTIONS.MAIN_QUERY_COMPLETE: {
      const { data } = action;
      return {
        ...state,
        data,
        loading: false,
      };
    }
    case ACTIONS.MAIN_QUERY_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        loading: false,
      };
    }
    case ACTIONS.MAIN_QUERY_REFRESH: {
      return {
        ...state,
        loading: true,
        variables: {
          ...state.variables,
          refreshKey: new Date().getTime(),
        },
      };
    }
    default:
      return state;
  }
};
