import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import useOnclickOutside from "react-cool-onclickoutside";

import BulkActionBarContainer from "./BulkActionBarWrapper";
import MenuItems from "./MenuItems";

const BulkActionBar = ({
  team,
  supplyLabel,
  teamId,
  members,
  selectedMembers,
  setSelectedMembers,
  status,
  setStatus,
  handleBulkMove,
  setTargetGroupOption,
  groupTypes,
  targetGroupOption,
  membersFilterOption,
  setMembersFilterOption,
}) => {
  const [toggleState, setToggle] = useState(false);

  const closeMenu = useCallback(() => setToggle(false), []);

  const menuRef = useOnclickOutside(closeMenu, {
    disabled: !toggleState,
    ignoreClass: "bulk-edit-select-action-btn",
  });

  const onToggle = (e) => {
    e.stopPropagation();
    setToggle(!toggleState);
  };

  return (
    <BulkActionBarContainer
      supplyLabel={supplyLabel}
      members={members}
      selectedMembers={selectedMembers}
      setSelectedMembers={setSelectedMembers}
      setStatus={setStatus}
      status={status}
      toggleState={toggleState}
      onToggle={onToggle}
      closeMenu={closeMenu}
      membersFilterOption={membersFilterOption}
      setMembersFilterOption={setMembersFilterOption}
    >
      <div id="bulk-action-bar-wrapper" ref={menuRef}>
        <MenuItems
          team={team}
          teamId={teamId}
          closeMenu={closeMenu}
          status={status}
          selectedMembers={selectedMembers}
          handleBulkMove={handleBulkMove}
          setTargetGroupOption={setTargetGroupOption}
          groupTypes={groupTypes}
          targetGroupOption={targetGroupOption}
        />
      </div>
    </BulkActionBarContainer>
  );
};

BulkActionBar.propTypes = {
  supplyLabel: PropTypes.string,
  teamId: PropTypes.string,
  members: PropTypes.array,
  status: PropTypes.object,
  setStatus: PropTypes.func,
  groupTypes: PropTypes.object,
  selectedMembers: PropTypes.object,
  setSelectedMembers: PropTypes.func,
  setTargetGroupOption: PropTypes.func,
  handleBulkMove: PropTypes.func,
  targetGroupOption: PropTypes.object,
  membersFilterOption: PropTypes.object,
  setMembersFilterOption: PropTypes.func,
  team: PropTypes.object,
};

export default BulkActionBar;
