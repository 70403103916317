import moment from "moment";

// eslint-disable-next-line import/prefer-default-export
export const isModifyRejectionMode = ({
  approvalReasonId,
  requestSubmittedAt,
  approvalSavedAt,
}) => {
  if (approvalReasonId) {
    if (requestSubmittedAt) {
      return moment(approvalSavedAt).isAfter(moment(requestSubmittedAt));
    }
    return true;
  }
  return false;
};
