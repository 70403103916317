import React from "react";
import { Box, Notification } from "orcs-design-system";

const TeamsWithTooManyMembersNotification = () => (
  <Box p="l">
    <Notification
      centered
      floating
      bottom="40px"
      icon={["far", "bell"]}
      colour="primary"
    >
      Note: The darker groups in this visualisation have too many members to
      display
    </Notification>
  </Box>
);

export default TeamsWithTooManyMembersNotification;
