import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { FTE_SUM_TYPE, FTE_SUM_TARGET } from "src/consts/team";
import FteQueryBadge from "src/components/FteQueryBadge";
import GroupPropType from "src/custom-prop-types/group";
import isSupplyDemandGroupType from "src/util/isSupplyDemandGroupType";
import { findAllocatedFteOrZero } from "src/util/fteUtil";
import FteMemberBadge from "src/components/FteBadge";

const FteBadge = ({ member = {}, team, workspace }) => {
  const [isSupply, isDemand] = isSupplyDemandGroupType(team, workspace);
  const allocatedFte = isSupply
    ? member.fte
    : findAllocatedFteOrZero(member, team);
  const isAllocated = isDemand && allocatedFte > 0;

  return !isEmpty(member) ? (
    (isDemand || isSupply) && (
      <FteMemberBadge
        fte={allocatedFte}
        isAllocated={isAllocated}
        isCapacity={isSupply}
        showToolTip
        toolTipPosition="top"
      />
    )
  ) : (
    <FteQueryBadge
      groupId={team.id}
      fteSumTarget={FTE_SUM_TARGET.ALL_MEMBERS}
      fteSumType={
        isSupply ? FTE_SUM_TYPE.CAPACITY_FTE : FTE_SUM_TYPE.ALLOCATED_FTE
      }
      showToolTip
      toolTipPosition="top"
    />
  );
};

FteBadge.propTypes = {
  team: GroupPropType.isRequired,
  workspace: PropTypes.object,
  member: PropTypes.object,
};

export default FteBadge;
