import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { map } from "lodash";

import { PersonAllocationsFragment } from "src/allocation/members.graphql";

const useUpdateAllocationsCache = () => {
  const client = useApolloClient();

  const updateAllocations = useCallback(
    (personId, targetGroupId, requestedFte) => {
      // Update current allocation FTE in apollo cache
      const target = client.readFragment({
        id: `Person:${personId}`,
        fragment: PersonAllocationsFragment,
        fragmentName: "PersonAllocationsFragment",
      });

      if (!target) {
        return;
      }

      const newAllocations = map(target.allocations, (a) => {
        if (a.targetGroupId === targetGroupId) {
          return { ...a, fte: requestedFte };
        }
        return a;
      });

      client.writeFragment({
        id: `Person:${personId}`,
        fragment: PersonAllocationsFragment,
        data: {
          aggregateId: personId,
          allocations: newAllocations,
        },
      });
    },
    [client]
  );

  return updateAllocations;
};

export default useUpdateAllocationsCache;
