import { get } from "lodash";

import {
  canCreateChild,
  isSupply,
  isDemand,
} from "src/util/directory-customerConfig";
import { hasAdminOrPowerRole } from "src/services/auth";

export const isRealTimeAllocationsEnabled = ({
  user,
  workspace,
  allocationProject,
}) => {
  return (
    hasAdminOrPowerRole(user) &&
    !get(workspace, "config.allocation.disableIndividualAllocation") &&
    (!!get(allocationProject, "enableRealtimeAllocation") ||
      !!get(workspace, "config.featureFlags.enableFutureAllocationsUI"))
  );
};

export const canAddNewTeam = (user, groupTypes, team) => {
  return hasAdminOrPowerRole(user) && canCreateChild(groupTypes, team);
};

export const canChangeSupplyGroup = (workspace) =>
  get(workspace, "config.allocation.enableChangeSupplyGroup", false);

export const isAddingMemberEnabled = (workspace, team) => {
  return (
    isDemand(get(workspace, "config.groupTypes"), team) ||
    (canChangeSupplyGroup(workspace) &&
      isSupply(get(workspace, "config.groupTypes"), team))
  );
};
