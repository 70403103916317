import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { isNil, isEmpty } from "lodash";
import styled from "styled-components";
import { Flex, Popover, Button, Icon, TextInput } from "orcs-design-system";
import { approveMacroAllocationAbsolute } from "src/allocation/allocation.graphql";
import { roundFte } from "src/util/roundingStrategy";
import { isMacroAllocationRejected } from "src/allocation/util/macroAllocation";

import {
  useApprovalReasons,
  ACTIONS,
  SAVE_STATUS,
} from "../../../context/ForecastContext";
import FailedIndicator from "../../Shared/FailedIndicator";
import useSaveMutation from "../../Shared/useSaveMutation";
import { InputDeltaLabel } from "../../Shared/InputDeltaLabel";
import RejectButton from "./RejectButton/RejectButton";

const InputWrapper = styled.div`
  position: relative;
`;

const NumberInput = styled(TextInput)`
  padding: 5px 8px 6px 8px;
`;

const ApproveInput = ({ cell, dispatch }) => {
  const approvalReasons = useApprovalReasons();

  const {
    id,
    value,
    valueSaveStatus,
    defaultedValue,
    prepopulatedValue,
    delta,
    isEditable,
    macroAllocationId,
    allocationProjectId,
    targetGroupId,
    sourceGroupId,
    approvalReasonId,
    approvalReasonText,
    requestSubmittedAt,
    approvalSavedAt,
  } = cell;

  const [hasPrepopulatedValue, resetPrepopulatedState] = useState(
    !isNil(prepopulatedValue)
  );
  const [isSaving, setIsSaving] = useState(false);

  // prepopulatedValue will be set to null if user delete the value, but still need the status telling the value needs to be saved
  const isPrepopulatedState = hasPrepopulatedValue && !isNil(prepopulatedValue);

  const onApproveInputChange = useCallback(
    (newValue) => {
      dispatch({
        type: ACTIONS.APPROVE_INPUT_UPDATE,
        cellId: id,
        value: newValue,
      });
    },
    [dispatch, id]
  );

  const onApproveSaveStatusChange = useCallback(
    (status, data) => {
      setIsSaving(false);
      if (!data) {
        return;
      }

      const { macroAllocation } = data;
      // If rejected, need to reset approved input value
      dispatch({
        type: ACTIONS.CELL_VALUE_SAVE_STATUS_UPDATE,
        cellId: id,
        status,
        macroAllocation,
      });
    },
    [setIsSaving, dispatch, id]
  );

  const onValueChange = useCallback(
    ({ floatValue = null }) => {
      if (value !== floatValue) {
        onApproveInputChange(floatValue);
      }
    },
    [onApproveInputChange, value]
  );

  const [updateApproveValue, lastSavedValue] = useSaveMutation(
    value,
    approveMacroAllocationAbsolute,
    onApproveSaveStatusChange
  );

  const onConfirm = useCallback(() => {
    setIsSaving(true);
    if (isPrepopulatedState) {
      dispatch({
        type: ACTIONS.APPROVE_INPUT_UPDATE,
        cellId: id,
        value: prepopulatedValue,
      });
      updateApproveValue(
        prepopulatedValue,
        {
          id: macroAllocationId,
          allocationProjectId,
          sourceGroupId,
          targetGroupId,
          approved: prepopulatedValue,
        },
        false
      );
    } else {
      const adjustedValue = isNil(value) ? defaultedValue : value;
      // If value changed or still in prepopulated status => Need to save a value
      const valueChanged = adjustedValue !== value || hasPrepopulatedValue;

      updateApproveValue(
        adjustedValue,
        {
          id: macroAllocationId,
          allocationProjectId,
          sourceGroupId,
          targetGroupId,
          approved: adjustedValue,
        },
        !valueChanged // Disable the value changed check inside, as value may not be lastSavedValue
      );
    }

    if (hasPrepopulatedValue) {
      resetPrepopulatedState(false);
    }
  }, [
    dispatch,
    id,
    updateApproveValue,
    macroAllocationId,
    allocationProjectId,
    sourceGroupId,
    targetGroupId,
    hasPrepopulatedValue,
    prepopulatedValue,
    isPrepopulatedState,
    defaultedValue,
    value,
  ]);

  const onReject = useCallback(
    (reasonId, reasonText) => {
      setIsSaving(true);

      // When rejecting macro allocation, use original defaultedValue as value
      // As we'll reset the cell value to allocatedMemberFte when the call comes back
      updateApproveValue(
        defaultedValue,
        {
          id: macroAllocationId,
          allocationProjectId,
          sourceGroupId,
          targetGroupId,
          approvalReasonId: reasonId,
          approvalReasonCustomText: reasonText,
        },
        false
      );
    },
    [
      updateApproveValue,
      macroAllocationId,
      allocationProjectId,
      sourceGroupId,
      targetGroupId,
      defaultedValue,
    ]
  );

  const onInputBlur = useCallback(() => {
    if (isNil(value)) {
      onApproveInputChange(defaultedValue);
    }
  }, [value, defaultedValue, onApproveInputChange]);

  // Giving isValid and isInvalid a string value to remove warnings from React
  const isValid = valueSaveStatus === SAVE_STATUS.SUCCESS;
  const isInvalid = valueSaveStatus === SAVE_STATUS.FAILED;
  const prepopulatedDelta = isPrepopulatedState
    ? roundFte(prepopulatedValue - defaultedValue)
    : null;

  const enableOperation = !isSaving && valueSaveStatus === SAVE_STATUS.PENDING;

  // For save button, enable it when value changed or has prepopulated value
  const enableSaveButton =
    enableOperation && (value !== lastSavedValue || hasPrepopulatedValue);

  // For reject button, enable when there is request or user has rejected it or user approved it
  const isRejected = isMacroAllocationRejected(cell, approvalReasons);
  const enableRejectButton = enableOperation || isRejected || isValid;
  const showRejectButton = !isEmpty(approvalReasons);

  const numberProps = {
    allowNegative: false,
    decimalScale: 2,
    onValueChange,
    onBlur: onInputBlur,
  };

  const placeholder = `${defaultedValue}`;
  const prepopulatedMessage = isPrepopulatedState
    ? "This number was pre-populated from the request"
    : null;

  return (
    <>
      <InputWrapper>
        <FailedIndicator isFailed={isInvalid} retry={onConfirm} />
        <Popover direction="top" text={prepopulatedMessage}>
          <NumberInput
            height="32px"
            fullWidth
            placeholder={placeholder}
            value={isPrepopulatedState ? prepopulatedValue : value}
            disabled={!isEditable}
            valid={isValid || undefined}
            invalid={isInvalid || undefined}
            numberProps={numberProps}
          />
        </Popover>
        <InputDeltaLabel
          delta={isPrepopulatedState ? prepopulatedDelta : delta}
        />
      </InputWrapper>
      <Flex alignItems="center" ml="xs">
        <Popover
          direction="top"
          width="118px"
          textAlign="center"
          text="Confirm approval"
        >
          <Button
            variant={enableSaveButton ? "success" : "disabled"}
            width="32px"
            height="32px"
            iconOnly
            disabled={!enableSaveButton}
            onClick={onConfirm}
            data-testid="approve-button"
          >
            <Icon icon={["fas", "check"]} />
          </Button>
        </Popover>
        {showRejectButton && (
          <Popover
            ml="xs"
            direction="top"
            width="118px"
            textAlign="center"
            text="Reject request"
          >
            <RejectButton
              approvalReasons={approvalReasons}
              enabled={enableRejectButton}
              onReject={onReject}
              approvalReasonId={approvalReasonId}
              approvalReasonText={approvalReasonText}
              requestSubmittedAt={requestSubmittedAt}
              approvalSavedAt={approvalSavedAt}
            />
          </Popover>
        )}
      </Flex>
    </>
  );
};

ApproveInput.propTypes = {
  cell: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default ApproveInput;
