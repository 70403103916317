import React from "react";
import { P } from "orcs-design-system";
import PropTypes from "prop-types";

const AuthorTitle = ({ action, authorTitle }) => {
  return (
    <P fontSize="1.4rem">
      <i>
        {action} by {authorTitle}
      </i>
    </P>
  );
};

AuthorTitle.propTypes = {
  action: PropTypes.string.isRequired,
  authorTitle: PropTypes.string.isRequired,
};

export default AuthorTitle;
