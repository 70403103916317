import { Small } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

// custom react-select Group heading component
const FormatGroupHeading = ({ label }) => {
  return (
    <Small display="block" pb="s" color="greyDark">
      {label}
    </Small>
  );
};

FormatGroupHeading.propTypes = {
  label: PropTypes.string,
};

export default FormatGroupHeading;
