import { every, isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

const TRANSITION_DURATION = 300;

export const isValidNodePosition = (position) => {
  return position && (position.x !== 0 || position.y !== 0);
};

// TODO: Refactor and add tests for this.
export const useNodeLayout = ({
  getLayout,
  nodes,
  edges,
  setNodes,
  setEdges,
  setIsLayouted,
  animatedNodesEnabled,
  initialNodes,
}) => {
  const readyForLayout = useMemo(
    () =>
      every(
        nodes,
        (node) => node.width !== undefined && node.height !== undefined
      ),
    [nodes]
  );

  const [layoutPositions, setLayoutPositions] = useState({});
  const [isInitialLayout, setIsInitialLayout] = useState(true);

  const handleNonAnimatedLayout = useCallback(() => {
    const [layoutedNodes, layoutedEdges] = getLayout(nodes, edges);

    if (!readyForLayout || isEqual(nodes, layoutedNodes)) return;

    setIsLayouted(true);
    setNodes(
      layoutedNodes.map((node) => ({
        ...node,
        data: { ...node.data, isLayouted: true },
      }))
    );
    setEdges(layoutedEdges);
  }, [
    getLayout,
    nodes,
    edges,
    readyForLayout,
    setNodes,
    setEdges,
    setIsLayouted,
  ]);

  const handleAnimatedNodesLayout = useCallback(() => {
    if (!readyForLayout || !initialNodes?.length) return;

    // Get new layout
    const [layoutedNodes, layoutedEdges] = getLayout(nodes, edges);
    if (isEqual(nodes, layoutedNodes)) return;

    // Process new layout positions, using previous valid positions for 0,0 cases
    const newLayoutPositions = layoutedNodes.reduce((acc, node) => {
      if (!isValidNodePosition(node.position) && layoutPositions[node.id]) {
        acc[node.id] = layoutPositions[node.id];
      } else {
        acc[node.id] = node.position;
      }
      return acc;
    }, {});

    // Apply animations between layout positions
    const nodesWithTransition = layoutedNodes.map((node) => {
      const incomingEdge = layoutedEdges.find((e) => e.target === node.id);
      const parentNode = nodes.find((n) => n.id === incomingEdge?.source);
      const prevLayoutPosition =
        layoutPositions[node.id] || parentNode?.position;
      const newPosition = newLayoutPositions[node.id];

      if (!prevLayoutPosition || isInitialLayout) {
        return {
          ...node,
          data: { ...node.data, isLayouted: true },
        };
      }

      if (isEqual(prevLayoutPosition, newPosition)) {
        return {
          ...node,
          data: { ...node.data, isLayouted: true },
        };
      }

      return {
        ...node,
        style: {
          ...node.style,
          transitionProperty: "transform",
          transitionDuration: `${TRANSITION_DURATION}ms`,
          transitionTimingFunction: "ease-in-out",
          transform: `translate(${newPosition.x}px, ${newPosition.y}px)`,
        },
        position: prevLayoutPosition,
        data: {
          ...node.data,
          isLayouted: true,
          onMount: () => {
            requestAnimationFrame(() => {
              setNodes((nds) =>
                nds.map((n) => {
                  if (n.id !== node.id) return n;
                  return {
                    ...n,
                    position: newPosition,
                    style: {
                      ...n.style,
                      transform: "none",
                    },
                  };
                })
              );
            });
          },
        },
      };
    });

    setLayoutPositions(newLayoutPositions);
    setIsInitialLayout(false);
    setIsLayouted(true);
    setNodes(nodesWithTransition);
    setEdges(layoutedEdges);
  }, [
    nodes,
    edges,
    setNodes,
    setEdges,
    readyForLayout,
    getLayout,
    setIsLayouted,
    initialNodes,
    layoutPositions,
    isInitialLayout,
  ]);

  useEffect(() => {
    // Strategy view does not support animated nodes right now so we revert to original layout
    if (!animatedNodesEnabled) {
      handleNonAnimatedLayout();
    } else {
      handleAnimatedNodesLayout();
    }
  }, [
    handleNonAnimatedLayout,
    animatedNodesEnabled,
    handleAnimatedNodesLayout,
  ]);

  return {
    readyForLayout,
    layoutPositions,
  };
};
