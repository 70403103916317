import { useMutation } from "@apollo/client";
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { chunk } from "lodash";
import {
  Box,
  H5,
  H6,
  Flex,
  Button,
  Notification,
  Small,
} from "orcs-design-system";

import { useAllocationConfig } from "src/contexts/global/WorkspaceContext/WorkspaceContext";
import { bulkMoveIndividuals } from "src/allocation/allocation.graphql";
import TeamDropdown from "src/allocation/components/TeamDropdown";
import { useSearchGroups } from "src/pages/InternalDashboard/useSearchHooks";
import ErrorNotification from "src/components/ErrorNotification";
import {
  useUserInteraction,
  useActiveAllocationProject,
  useDispatch,
  ACTIONS,
  useLookupData,
} from "../../../context/ForecastContext";
import GroupingMembersTreeModal from "../BulkAddingTagsModal/GroupingMembersTreeModal";
import useLazyLoadMembers from "../BulkAddingTagsModal/useLazyLoadMembers";
import useGroupingMembersTree from "../BulkAddingTagsModal/useGroupingMembersTree";
import {
  createMutationList,
  updateTargetGroupMacroAllocations,
  refreshChangedLineItems,
  countTotalSelected,
} from "./BulkMoveMembers.logic";

const MAX_MOVE_LIMIT = 100;

const BulkMoveMembersModal = () => {
  const allocationConfig = useAllocationConfig();
  const {
    bulkMoveMembers: { isOpen, grouping },
  } = useUserInteraction();
  const allocationProject = useActiveAllocationProject();
  const {
    enableBulkMoveWithUpdate,
    enableMemberAllocation,
    enableMemberFteEdit,
  } = useLookupData();
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [targetGroupOption, setTargetGroupOption] = useState(null);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setTargetGroupOption(null);
      setIsProcessing(false);
      setError(null);
      setProgress(null);
    }
  }, [isOpen]);

  const handleCloseModal = useCallback(() => {
    if (isProcessing) {
      return;
    }

    dispatch({
      type: ACTIONS.CLOSE_BULK_MOVE_MEMBERS_MODAL,
    });
  }, [dispatch, isProcessing]);

  const {
    client,
    loadLatestMembers: loadMembers,
    getMembersQueryVariables,
  } = useLazyLoadMembers();

  // TODO: make a proper grouping tree including these methods inside...
  const {
    treeData,
    selectedGroups,
    selectedPeople,
    handleNodeSelected,
    handleChecked,
  } = useGroupingMembersTree({
    isTreeActive: isOpen,
    grouping,
    loadMembers,
  });

  const [bulkMoveMembers] = useMutation(bulkMoveIndividuals);

  const existingGroups = useMemo(
    () => (grouping ? [grouping.group] : []),
    [grouping]
  );

  const { loadOptions, isSearching } = useSearchGroups({
    groupTypes: allocationConfig.targetTypes,
    existingGroups,
    includeMembers: false,
  });

  const runBulkMove = useCallback(async () => {
    if (!targetGroupOption || !targetGroupOption.group) {
      return;
    }

    const { group: targetGroup } = targetGroupOption;
    const toGroupId = targetGroup.id;
    const { allocations, supplyGroups, lineItems } = createMutationList(
      selectedPeople,
      toGroupId
    );

    if (allocations.length > MAX_MOVE_LIMIT) {
      setError(
        `Too many members to move (selected ${allocations.length} limit: ${MAX_MOVE_LIMIT}).`
      );
      return;
    }

    try {
      setIsProcessing(true);
      const allocationProjectId = allocationProject.id;

      if (enableMemberAllocation && enableMemberFteEdit) {
        // When enabling member fte edit, the individual allocation is triggering
        // a lot of commands, including adding macro allocations and updating
        // realtime current total fte in macro allocations, chunk the allocations array,
        // so that each time processing a relatively safe number to avoid back end timeout
        const chunks = chunk(allocations, 10);

        for (let i = 0, j = chunks.length; i < j; i += 1) {
          const current = chunks[i];
          setProgress(`${i + 1}/${j}`);
          // eslint-disable-next-line no-await-in-loop
          await bulkMoveMembers({
            variables: {
              input: {
                allocations: current,
                allocationProjectId,
              },
            },
          });
        }

        setProgress("");
      } else {
        // Bulk move members
        await bulkMoveMembers({
          variables: {
            input: {
              allocations,
              allocationProjectId,
            },
          },
        });

        // Add missing macro allocations
        await updateTargetGroupMacroAllocations({
          client,
          allocationProjectId,
          toGroupId,
          supplyGroups,
          getMembersQueryVariables,
          enableBulkMoveWithUpdate,
        });
      }

      // Refetch line item members list
      await refreshChangedLineItems({
        client,
        allocationProjectId,
        lineItems,
        getMembersQueryVariables,
        enableBulkMoveWithUpdate,
      });

      // Refresh the planner page
      dispatch({
        type: ACTIONS.MAIN_QUERY_REFRESH,
        backgroundRefresh: true,
      });
      handleCloseModal();
    } catch (e) {
      setError(e);
    } finally {
      setIsProcessing(false);
    }
  }, [
    targetGroupOption,
    selectedPeople,
    allocationProject,
    bulkMoveMembers,
    client,
    getMembersQueryVariables,
    dispatch,
    handleCloseModal,
    enableMemberAllocation,
    enableMemberFteEdit,
    enableBulkMoveWithUpdate,
  ]);

  if (!isOpen && !grouping) {
    return null;
  }

  // Set move and cancel button to disabled if processing or have any error
  const isBusy = isProcessing || !!error;

  const footerContent = (
    <Box width="100%">
      <Flex alignItems="center" justifyContent="flex-end" width="100%">
        <Button
          onClick={handleCloseModal}
          variant="ghost"
          mr="r"
          disabled={isBusy}
        >
          Cancel
        </Button>
        <Button
          onClick={runBulkMove}
          variant={isBusy ? "disabled" : "success"}
          disabled={isBusy}
        >
          Move members
        </Button>
      </Flex>
    </Box>
  );

  const totalSelected = countTotalSelected(selectedPeople);

  return (
    <GroupingMembersTreeModal
      visible={isOpen}
      grouping={grouping}
      treeData={treeData}
      onHideModal={handleCloseModal}
      onNodeSelected={handleNodeSelected}
      onNodeChecked={handleChecked}
      selectedGroups={selectedGroups}
      selectedPeople={selectedPeople}
      title={<H5>Move members</H5>}
      footerContent={footerContent}
      leftViewTitle="Select members you would like to move"
    >
      <>
        <H6 mb="r" weight="bold">
          Where would you like to move to?
        </H6>
        <Box
          borderRadius={2}
          boxBorder="default"
          p="s"
          height="calc(100% - 48px)"
          maxHeight="calc(100% - 48px)"
          overflowY="auto"
        >
          <TeamDropdown
            id="move-members-target-group"
            placeholder="Search and select target team"
            isSearchable
            isLoading={isSearching}
            options={[]}
            loadOptions={loadOptions}
            onSelectTeam={setTargetGroupOption}
            value={targetGroupOption}
            cacheOptions={false}
          />
          <Box mt="r">
            <Small>{`Total ${totalSelected} selected. Max ${MAX_MOVE_LIMIT}.`}</Small>
            {isProcessing && !error && (
              <Notification loading closable={false}>
                {`Moving members in progress (${progress || ""}) ...`}
              </Notification>
            )}
            {error && (
              <ErrorNotification
                message={`Sorry, an error occurred, please refresh and try moving again. (${progress} - ${error.message})`}
                error={error}
                closable={false}
              />
            )}
          </Box>
        </Box>
      </>
    </GroupingMembersTreeModal>
  );
};

export default BulkMoveMembersModal;
