import { Small, Icon } from "orcs-design-system";
import styled from "styled-components";

export const BreadcrumbItem = styled.div`
  margin: 2px;
  &:nth-of-type(1) {
    margin-left: 0;
  }
`;
export const BreadcrumbIcon = styled(Icon)`
  line-height: 0;
`;
export const BreadcrumbText = styled(Small)`
  font-size: 76%;
  word-break: break-word;
`;
