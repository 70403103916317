import { useMutation } from "@apollo/client";
import { filter, map } from "lodash";
import { useCallback } from "react";

import { updateTagsOnEntities as updateTagsOnEntitiesQuery } from "./queries/tags.graphql";
import { makeBulkComment } from "./TagsEditor.util";

const useBulkRemoveTag = ({
  entityType,
  entities,
  commentsMetadata,
  onTagRemove,
}) => {
  const [updateTagsOnEntities] = useMutation(updateTagsOnEntitiesQuery);

  const bulkRemoveTagFromEntity = useCallback(
    async ({ tagId, tagType, displayValue }) => {
      const entityTagUpdates = map(
        filter(entities, (entity) => entity.id && entity.name),
        ({ id: entityId, name: entityName }) => {
          return {
            entityId,
            tagId,
            operation: "REMOVED",
            comments: makeBulkComment(
              tagId,
              tagType,
              displayValue,
              entityId,
              entityName,
              entityType,
              commentsMetadata
            ),
          };
        }
      );

      await updateTagsOnEntities({
        variables: {
          input: {
            list: entityTagUpdates,
          },
        },
        // needed to update tags on people that are visible on the planner page
        refetchQueries: ["getPeopleByIds"],
        awaitRefetchQueries: false,
        /*
          We used to have an update method here to update the cache for getTagsForEntityQuery after updating tags
          but the readQuery call requires the original query object to work and we don't have access to it here,
          thus we *always* failed to get the query from the cache and thus the cache was never updated, so I removed it.

          Excerpt from the docs here: https://www.apollographql.com/docs/react/caching/cache-interaction/#readquery
            > If the cache is missing data for any of the query's fields, readQuery returns null.
        */
      });
    },
    [commentsMetadata, entities, entityType, updateTagsOnEntities]
  );

  const removeTag = useCallback(
    async (removedTag, { id: tagType }) => {
      const { id: tagId, displayValue } = removedTag;

      if (!tagId) {
        return;
      }

      await bulkRemoveTagFromEntity({
        tagId,
        tagType,
        displayValue,
      });

      onTagRemove(removedTag);
    },
    [bulkRemoveTagFromEntity, onTagRemove]
  );

  return removeTag;
};

export default useBulkRemoveTag;
