import PropTypes from "prop-types";
import React from "react";

import ExpandableContent from "src/components/ExpandableContent";
import RichTextContent from "src/components/RichTextContent";

const DescriptionSection = ({ description, showDescription }) => {
  if (!showDescription) {
    return null;
  }

  return (
    <ExpandableContent>
      <RichTextContent content={description} />
    </ExpandableContent>
  );
};

DescriptionSection.propTypes = {
  description: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  showDescription: PropTypes.bool,
};

export default DescriptionSection;
