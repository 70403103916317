import React from "react";
import { ActionsMenu } from "orcs-design-system";

import DefaultViewButton from "./DefaultViewButton";
import AddNewTeamButton from "./AddNewTeamButton";
import AddNewChapterButton from "./AddNewChapterButton";
import DownloadButton from "./DownloadButton";
import DownloadMovedGroupsButton from "./DownloadMovedGroupsButton";

const ActionMenu = () => {
  return (
    <ActionsMenu
      direction="left-start"
      ml="auto"
      className="guided-tour-allocation-forecast-actions-menu"
      data-testid="forecast-page-actions"
    >
      <DefaultViewButton />
      <AddNewTeamButton />
      <AddNewChapterButton />
      <DownloadButton />
      <DownloadMovedGroupsButton />
    </ActionsMenu>
  );
};

// ActionMenu.propTypes = {
// };

export default ActionMenu;
