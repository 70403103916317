import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Tabs } from "orcs-design-system";
import { PERSON_TAB } from "src/consts/urlPaths";
import { useAllocationProject } from "src/contexts/global/GlobalContext";
import {
  useAllocationConfig,
  useWorkspaceFeatureFlags,
} from "src/contexts/global/WorkspaceContext";

const PersonTabs = ({ userHomeMode, person }) => {
  const detailsTabName = userHomeMode ? "My Details" : "Person Details";
  const allocationsTabName = userHomeMode ? "My Allocations" : "Allocations";
  const { disableIndividualAllocation } = useAllocationConfig();
  const activeAllocationProject = useAllocationProject();
  const { enableFutureAllocationsUI } = useWorkspaceFeatureFlags();
  const allocationPermission = get(
    person,
    "permissions.isInAccessGroupIdsHierarchy"
  );

  const isIndividualAllocationEnabled =
    activeAllocationProject &&
    allocationPermission &&
    !disableIndividualAllocation;

  const isMembershipsEnabled =
    allocationPermission &&
    !disableIndividualAllocation &&
    enableFutureAllocationsUI;

  const personTabsList = [
    {
      label: "Dashboard",
      path: PERSON_TAB.USER_DASHBOARD,
      isVisible: !!userHomeMode,
    },
    {
      label: detailsTabName,
      path: PERSON_TAB.DETAILS,
    },
    {
      label: "Visualisation",
      path: PERSON_TAB.VISUALISATION,
    },
    {
      label: allocationsTabName,
      path: PERSON_TAB.ALLOCATIONS,
      isVisible: !!isIndividualAllocationEnabled && !isMembershipsEnabled,
    },
    {
      label: allocationsTabName,
      path: PERSON_TAB.ALLOCATIONS,
      isVisible: !!isMembershipsEnabled,
    },
    {
      label: "History",
      path: PERSON_TAB.HISTORY,
    },
  ];

  return <Tabs tabsList={personTabsList} />;
};

PersonTabs.propTypes = {
  userHomeMode: PropTypes.bool,
  person: PropTypes.object,
};

export default PersonTabs;
