import { isEmpty, reduce } from "lodash";
import { useEffect, useRef } from "react";
import {
  ACTIONS,
  useDispatch,
  useExpandAllGroups,
} from "../../context/ForecastContext";

const getAllGroupIds = (groupings) => {
  return reduce(
    groupings,
    (ids, grouping) => {
      ids.push(grouping.id);
      if (!isEmpty(grouping.childGroupings)) {
        ids.push(...getAllGroupIds(grouping.childGroupings));
      }
      return ids;
    },
    []
  );
};

const useAllGroupExpansion = (groupings) => {
  const dispatch = useDispatch();
  const isExpanded = useExpandAllGroups();
  const ids = useRef(getAllGroupIds(groupings));

  useEffect(() => {
    ids.current = getAllGroupIds(groupings);
  }, [groupings]);

  const handleClick = () => {
    dispatch({
      type: ACTIONS.TOGGLE_ALL_GROUPING_TREE_EXPANSION,
      ids: ids.current,
    });
  };

  return {
    isExpanded,
    handleClick,
  };
};

export default useAllGroupExpansion;
