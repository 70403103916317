import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, Spacer, Loading } from "orcs-design-system";

import TeamLink from "src/components/TeamLink";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import TeamHierarchyBreadcrumb from "src/components/TeamHierarchyBreadcrumb";

import { useLookupData } from "../../context/ForecastContext";

const GroupNameWithHierarchy = ({ group, extra }) => {
  const { groupLookup } = useLookupData();
  const { parentIds = [] } = group;

  let isParentMissing = false;
  const parentGroups = [];

  for (let i = 0, j = parentIds.length; i < j; i += 1) {
    const parentId = parentIds[i];
    const parentGroup = groupLookup[parentId];
    if (parentGroup) {
      parentGroups.push(parentGroup);
    } else {
      isParentMissing = true;
      break;
    }
  }

  return (
    <Box>
      {isParentMissing ? (
        <Loading data-testid="loading" />
      ) : (
        <TeamHierarchyBreadcrumb
          muted
          team={group}
          id={group.id}
          parentGroups={parentGroups}
        />
      )}
      <Flex alignItems="center">
        <Spacer mr="xs">
          <TeamLink
            bold
            teamName={group.name}
            team={group}
            isHidden={group.isHidden}
          />
          <GroupTypeBadge group={group} showToolTip toolTipPosition="right" />
          {extra}
        </Spacer>
      </Flex>
    </Box>
  );
};

GroupNameWithHierarchy.propTypes = {
  group: PropTypes.object.isRequired,
  extra: PropTypes.node,
};

export default GroupNameWithHierarchy;
