import { values, isEmpty } from "lodash";
import { Select, Spacer, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { components } from "react-select";
import styled from "styled-components";

import { getFilteredMemberList } from "src/pages/TeamPage/utils";

import formatOptionLabel from "./FormatOptionLabel";
import { getOptions } from "./options";

const StyledSingleValue = styled(components.SingleValue)`
  width: 100%;
`;

const HideFunction = () => null;

const SingleValue = ({ children, ...props }) => {
  return (
    <StyledSingleValue className="single-value-wrapper" {...props}>
      {children}
    </StyledSingleValue>
  );
};

SingleValue.propTypes = {
  children: PropTypes.node,
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon mr="s" icon={["far", "search"]} />
    </components.DropdownIndicator>
  );
};

const CustomComponents = {
  DropdownIndicator,
  IndicatorSeparator: HideFunction,
  SingleValue,
};

export const filterSelectedMembersList = (selectedMembers, selected) => {
  const selectedFilteredList = getFilteredMemberList(
    values(selectedMembers),
    selected
  );
  return selectedFilteredList.reduce((acc, next) => {
    acc[next.aggregateId] = next;
    return acc;
  }, {});
};

const FilterInput = ({
  membersFilterOption,
  setMembersFilterOption,
  selectedMembers,
  setSelectedMembers,
  supplyLabel,
}) => {
  const [options, setOptions] = useState([]);
  const onSelectChange = (selected, operation) => {
    const { action } = operation;
    if (action === "select-option") {
      setMembersFilterOption(selected);
      if (!isEmpty(selectedMembers)) {
        setSelectedMembers(
          filterSelectedMembersList(selectedMembers, selected)
        );
      }
    } else {
      setMembersFilterOption(null);
    }
  };

  const onInputChange = (str) => {
    if (str) {
      setOptions(getOptions(str, supplyLabel));
    } else {
      setOptions([]);
    }
  };

  return (
    <Spacer>
      <Select
        value={membersFilterOption}
        options={options}
        isClearable
        onChange={onSelectChange}
        onInputChange={onInputChange}
        ariaLabel="Search to filter people by. Type search query to display options below and then select category you'd like to filter on."
        placeholder="Search to filter people by..."
        noOptionsMessage={HideFunction}
        formatOptionLabel={formatOptionLabel}
        components={CustomComponents}
      />
    </Spacer>
  );
};

FilterInput.propTypes = {
  supplyLabel: PropTypes.string,
  selectedMembers: PropTypes.object,
  setSelectedMembers: PropTypes.func,
  membersFilterOption: PropTypes.object,
  setMembersFilterOption: PropTypes.func,
};

export default FilterInput;
