import { size } from "lodash";

import {
  useAllocationProjects,
  useFinancialForecasting,
  useLookupData,
} from "./context/ForecastContext";
import { setGridConfig } from "./components/util/gridConfig";

const usePageGridConfig = () => {
  const numberOfAllocationProjects = size(useAllocationProjects());
  const withBudget = useFinancialForecasting();
  const { hideApproverColumn, enableMemberFteEdit } = useLookupData();

  setGridConfig(
    numberOfAllocationProjects,
    withBudget,
    hideApproverColumn,
    enableMemberFteEdit
  );
};

export default usePageGridConfig;
