import React, { useCallback } from "react";
import { useQuery } from "@apollo/client";
import { get } from "lodash";

import { getGroupAttributes } from "src/allocation/team.graphql";
import TeamDescriptionModal from "src/components/TeamDescriptionModal";
import {
  useUserInteraction,
  useDispatch,
  ACTIONS,
} from "../../context/ForecastContext";

const TeamDetailsModal = () => {
  const {
    viewingTeamDetails: { isOpen, targetGroup },
  } = useUserInteraction();
  const dispatch = useDispatch();

  const teamAttributes = get(targetGroup, "attributes");
  const groupId = get(targetGroup, "id");

  const { data, loading } = useQuery(getGroupAttributes, {
    variables: {
      groupId,
    },
    fetchPolicy: "network-only",
    skip: !groupId,
  });

  const handleHideModal = useCallback(() => {
    dispatch({
      type: ACTIONS.CLOSE_TEAM_DETAILS_DIALOG,
    });
  }, [dispatch]);

  if (!isOpen) {
    return null;
  }

  const targetAttributes = get(data, "result.attributes", teamAttributes);

  return (
    <TeamDescriptionModal
      loading={loading}
      isVisible={isOpen}
      group={targetGroup}
      attributes={targetAttributes}
      onClose={handleHideModal}
    />
  );
};

export default TeamDetailsModal;
