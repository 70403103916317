import React from "react";
import { PropTypes } from "prop-types";

import TypeBadge from "src/components/TeamStrategiesTable/TableCells/TypeBadge";

const TypeCell = ({ row }) => {
  const { loadMore } = row.original;

  if (!loadMore) {
    return <TypeBadge row={row} />;
  }

  return null;
};

TypeCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      parentIds: PropTypes.arrayOf(PropTypes.string),
      loadMore: PropTypes.bool,
      name: PropTypes.string,
      kind: PropTypes.string,
      attributes: PropTypes.arrayOf(PropTypes.object),
      dataSource: PropTypes.shape({
        type: PropTypes.string,
      }),
    }),
  }),
};

export default TypeCell;
