import React from "react";
import PropTypes from "prop-types";

const LeafNode = ({ attributes, children }) => {
  return <span {...attributes}>{children}</span>;
};

LeafNode.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
};

export default LeafNode;
