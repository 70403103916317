import { filter, get, map, uniq } from "lodash";
import { Box, Flex, Icon, Select, Toggle } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import GroupTypeBadge from "src/components/Badge/GroupTypeBadge";

// dropdown label
const GroupTypeOptionLabel = ({ value, groupTypes }) => {
  const groupType = get(groupTypes, value);
  if (!value || !groupType) {
    return null;
  }

  return (
    <GroupTypeBadge group={{ type: groupType.id }} groupTypes={groupTypes}>
      {value.name}
    </GroupTypeBadge>
  );
};

GroupTypeOptionLabel.propTypes = {
  value: PropTypes.string,
  groupTypes: PropTypes.array,
};

const HierarchyOptions = ({ hierarchyGroupTypeIds, onChange, groupTypes }) => {
  const [groupTypeOptions, setGroupTypeOptions] = useState(null);

  const [groupTypeMappings, setGroupTypeMappings] = useState({});
  const [options, setOptions] = useState({});

  // get group type ids (we use state so we can avoid re-rendering when groupTypeIds is a hook dependency)
  const resetState = useCallback(() => {
    setGroupTypeMappings({});
    setOptions({});
  }, [setGroupTypeMappings, setOptions]);

  // reset state on root group change or component unload
  useEffect(() => {
    if (!hierarchyGroupTypeIds) {
      resetState();
    }
    return () => {
      resetState();
    };
  }, [hierarchyGroupTypeIds, resetState]);

  // construct dropdown options from grouptypes
  useMemo(() => {
    const groupedOptions = [];

    // get isDemand types
    const isDemandGroupTypes = map(
      filter(groupTypes, (groupType) => {
        return groupType.isDemand === true;
      }),
      (groupType) => ({ label: groupType.name, value: groupType.id })
    );
    // get isSupply types
    const isSupplyGroupTypes = map(
      filter(groupTypes, (groupType) => {
        return groupType.isSupply === true;
      }),
      (groupType) => ({ label: groupType.name, value: groupType.id })
    );
    // get remaining types
    const otherGroupTypes = map(
      filter(groupTypes, (groupType) => {
        return groupType.isDemand !== true && groupType.isSupply !== true;
      }),
      (groupType) => ({ label: groupType.name, value: groupType.id })
    );

    // add each category of type
    if (isDemandGroupTypes.length > 0) {
      groupedOptions.push({
        label: "Demand Types",
        options: isDemandGroupTypes,
      });
    }
    if (isSupplyGroupTypes.length > 0) {
      groupedOptions.push({
        label: "Supply Types",
        options: isSupplyGroupTypes,
      });
    }
    if (otherGroupTypes.length > 0) {
      groupedOptions.push({ label: "Other Types", options: otherGroupTypes });
    }

    setGroupTypeOptions(groupedOptions);
  }, [setGroupTypeOptions, groupTypes]);

  // update parent with selected options
  useEffect(() => {
    if (onChange) {
      onChange(groupTypeMappings, options);
    }
  }, [groupTypeMappings, options, onChange]);

  // render message if no hierarchy supplied
  if (!hierarchyGroupTypeIds) {
    return <span>Select a team to clone from to see options</span>;
  }

  return (
    <Box my="s">
      <Box my="s">
        If you would like to modify the group types of cloned items, you can
        select new types for each:
      </Box>
      <Flex flexDirection="column" data-testid="cp-hierarchy-options">
        {hierarchyGroupTypeIds &&
          map(uniq(hierarchyGroupTypeIds), (groupTypeId) => {
            return (
              <Flex
                key={groupTypeId}
                alignItems="center"
                justifyContent="flex-start"
                mb="s"
              >
                <Flex flex="4 5 100px">
                  <GroupTypeBadge
                    group={{ type: groupTypeId }}
                    groupTypes={groupTypes}
                    showToolTip={true}
                  />
                </Flex>
                <Flex flex="10 10 10px">
                  <Icon
                    icon={["fal", "chevron-right"]}
                    size="1x"
                    color="grey"
                    mx="s"
                  />
                </Flex>
                <Flex flex="10 1 150px">
                  <Select
                    ariaLabel="Select type to clone to"
                    options={groupTypeOptions}
                    formatOptionLabel={(data, event) => {
                      return GroupTypeOptionLabel(
                        {
                          ...data,
                          groupTypes,
                        },
                        event
                      );
                    }}
                    onChange={(option) => {
                      setGroupTypeMappings({
                        ...groupTypeMappings,
                        [groupTypeId]: option.value,
                      });
                    }}
                  />
                </Flex>
              </Flex>
            );
          })}
      </Flex>
      <Box my="s">
        If you would like people&rsquo;s memberships to the selected teams to
        also be replicated in the cloned teams, enable this option:
      </Box>
      <Toggle
        id="createPeopleSyncAssociations"
        label="Sync people memberships"
        onChange={({ target }) => {
          const checked = target?.checked;
          setOptions({
            ...options,
            [target.id]: checked,
          });
        }}
      />
    </Box>
  );
};

HierarchyOptions.propTypes = {
  hierarchyGroupTypeIds: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  groupTypes: PropTypes.object,
};

export default HierarchyOptions;
