import { Icon } from "orcs-design-system";
import React from "react";

import GroupPropType from "src/custom-prop-types/group";

const ObjectiveTypeIcon = ({ group }) => {
  if (!group?.type) {
    return null;
  }

  return (
    <Icon
      mr="s"
      icon={
        group.type === "company"
          ? ["far", "bullseye-arrow"]
          : ["far", "bullseye"]
      }
      color="success"
    />
  );
};

ObjectiveTypeIcon.propTypes = {
  group: GroupPropType.isRequired,
};

export default ObjectiveTypeIcon;
