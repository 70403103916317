import { join, map, reduce } from "lodash";

import { getPeoplePath } from "src/util/paths";

import { copywriting } from "./ReportTeamChange.config";

export const addSenderToMessage = (sender, message) =>
  message.replace(/{sender}/, sender);

export const getSubject = (sender) =>
  copywriting.EMAIL_SUBJECT.replace(/{sender}/, sender);

export const getPersonAllocationUrl = (personId, appVanityURL) =>
  `${appVanityURL || process.env.REACT_APP_PEOPLE_MAPPER_URL}${getPeoplePath(
    personId
  )}/allocations?cid=nlm`;

export const notifyIncorrectTeam = (
  managingPersonLabel,
  personAllocationsUrl,
  sender,
  email,
  teams,
  additionalContacts
) => {
  const subject = getSubject(sender);
  const invalidTeams = reduce(
    teams,
    (result, team) => {
      if (team.isRemove) {
        return [...result, team.name];
      }
      return result;
    },
    []
  );

  const content = `Hi ${managingPersonLabel},\nThe user ${sender} is incorrectly allocated to the following teams:\n${join(
    invalidTeams,
    ", "
  )}\n\nTo update this, you can visit ${personAllocationsUrl} to make the appropriate changes.
  `;
  return {
    email,
    subject,
    additionalContacts,
    content,
  };
};

export const notifyMissingTeam = (
  managingPersonLabel,
  personAllocationsUrl,
  sender,
  email,
  newTeams,
  additionalContacts
) => {
  const subject = getSubject(sender);
  const addToTeams = map(newTeams, ({ label }) => label);

  const content = `Hi ${managingPersonLabel},\nThe user ${sender} should be in these additional teams:\n${join(
    addToTeams,
    ", "
  )}\n\nTo update this, you can visit ${personAllocationsUrl} to make the appropriate changes.
  `;
  return {
    email,
    subject,
    additionalContacts,
    content,
  };
};

export const notifyIncorrectFte = (
  managingPersonLabel,
  personAllocationsUrl,
  sender,
  email,
  additionalContacts
) => {
  const subject = getSubject(sender);

  const content = `Hi ${managingPersonLabel},\nThe user ${sender} has an incorrect fte.\n\nTo update this, you can visit ${personAllocationsUrl} to make the appropriate changes.`;
  return {
    email,
    subject,
    additionalContacts,
    content,
  };
};
