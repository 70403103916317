export const createPropertyOption =
  (isSelected = true) =>
  (tagType) => {
    return {
      label: tagType.name,
      value: tagType.id,
      data: {
        isSelected,
      },
    };
  };
