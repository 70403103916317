import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Divider, Flex, H4, Small, Spacer } from "orcs-design-system";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import GroupAvatar from "src/components/PersonDetailPage/PersonMemberships/components/GroupAvatar";
import TeamDetailsActionMenu from "src/components/TeamDetailPage/ActionsMenu";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { teamDetailsPath } from "src/util/routing";
import { useOrgChartContext } from "src/components/OrgChart/context/OrgChartContext";
import ReadOnlyTags from "src/components/ReadOnlyTags";
import { ENTITY_TYPES } from "src/consts/comments";
import { SourceHandle, StyledCard, TargetHandle } from "../node.styled";
import { TEAM_ACTION_MENU_TYPE } from "../../../TeamDetailPage/ActionsMenu/const";
import { RoundIconButton } from "../RoundIconButton";
import GroupTypeBadge from "../../../Badge/GroupTypeBadge";
import { useNodeProperties } from "../../hooks/useNodeProperties";

const StyledButton = styled(RoundIconButton)`
  position: absolute;
  left: calc(50% - 10px);
  bottom: -10px;
`;

const GroupNode = ({ data, ...props }) => {
  const { team } = useOrgChartContext() || {};
  const workspace = useWorkspace();
  const tagsToBeDisplayed = useNodeProperties({
    tags: data?.tags,
    entityType: ENTITY_TYPES.GROUP,
  });

  const history = useHistory();
  const isCurrentTeam = team?.id === data.id;

  const handleIconClick = useCallback(() => {
    if (isCurrentTeam) return;
    const teamPath = teamDetailsPath(data.id);
    history.push(teamPath);
  }, [data.id, history, isCurrentTeam]);

  return (
    <StyledCard width="400px" $hidden={data?.hidden} cursor="grab" {...props}>
      <TargetHandle $hidden type="target" position="top" />
      <Spacer mb="r">
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Flex alignItems="center">
            <GroupAvatar group={data} sizing="large" mr="r" />
            <Flex alignItems="flex-start" flexDirection="column" gap="4px">
              <H4>{data?.name}</H4>
              <GroupTypeBadge
                altStyle={true}
                group={data}
                groupTypes={workspace?.config?.groupTypes}
              />
            </Flex>
          </Flex>
          <TeamDetailsActionMenu
            team={data}
            showInsightIcon={false}
            actionMenuTypes={[
              TEAM_ACTION_MENU_TYPE.EXPLORE_IN_ORG,
              TEAM_ACTION_MENU_TYPE.ADD_TEAM,
              TEAM_ACTION_MENU_TYPE.DOWNLOAD,
            ]}
            direction="right-start"
            runGroupCacheUpdateOnAddition={false}
          />
        </Flex>
      </Spacer>
      <Flex justifyContent="space-between">
        <Small color="grey" fontSize="0" display="block">
          {data?.childTeams?.length} teams
        </Small>
        <Small color="grey" fontSize="0" display="block">
          {data?.directMemberCount} members
        </Small>
      </Flex>
      {!isEmpty(tagsToBeDisplayed) && (
        <Spacer mb="s">
          <Divider light />
          <ReadOnlyTags tags={tagsToBeDisplayed} />
        </Spacer>
      )}
      <SourceHandle $hidden type="source" position="bottom" />
      <StyledButton
        className="tree-navigator"
        icon={["fas", "chevron-down"]}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleIconClick();
        }}
      />
    </StyledCard>
  );
};

GroupNode.propTypes = {
  data: PropTypes.object,
};

export default React.memo(GroupNode);
