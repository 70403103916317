import React, { useMemo, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { getParentGroupsForNewTeam as getParentGroupsQuery } from "src/allocation/team.graphql";
import { getSubGroupTypes } from "src/allocation/util/group";
import { createParentGroupOptions } from "src/util/group";
import AddNewTeamModal from "src/allocation/components/AddNewTeamModal";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";

const AddNewTeamContainer = ({
  parentGroup,
  visible,
  onCreateTeam,
  onUpdateSelectedGroup,
  onHideModal,
  defaultModalTitle,
}) => {
  const {
    config: { groupTypes },
  } = useWorkspace();

  const subGroupTypes = useMemo(() => {
    return getSubGroupTypes(groupTypes, parentGroup);
  }, [groupTypes, parentGroup]);

  const [loadParentGroups, { data: groupsData, loading: groupsLoading }] =
    useLazyQuery(getParentGroupsQuery, {
      variables: {
        groupId: parentGroup.id,
        groupTypes: subGroupTypes,
      },
      skip: !parentGroup.id,
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (visible && !groupsData && !groupsLoading && subGroupTypes.length > 0) {
      loadParentGroups();
    }
  }, [visible, groupsData, groupsLoading, subGroupTypes, loadParentGroups]);

  const parentGroupOptions = useMemo(() => {
    return createParentGroupOptions(groupsData, parentGroup);
  }, [groupsData, parentGroup]);

  // Get all sub, sub-sub groups
  const createTeamAndReload = useCallback(
    async (team) => {
      const createdTeam = await onCreateTeam(team);

      const { type: t } = team;
      if (groupTypes[t] && Boolean(groupTypes[t].childTypes)) {
        // The createdTeam is undefined (based on the implementation),
        // so if user created a new group that can have child,
        // need to reload all the sub type groups again.
        // TODO: Find a good way to do this...
        loadParentGroups();
      }

      return createdTeam;
    },
    [onCreateTeam, groupTypes, loadParentGroups]
  );

  return (
    <AddNewTeamModal
      parentGroup={parentGroup}
      visible={visible}
      onHideModal={onHideModal}
      onCreateTeam={createTeamAndReload}
      onUpdateSelectedGroup={onUpdateSelectedGroup}
      loading={groupsLoading}
      parentGroupOptions={parentGroupOptions}
      groupTypes={groupTypes}
      defaultModalTitle={defaultModalTitle}
    />
  );
};

AddNewTeamContainer.propTypes = {
  parentGroup: PropTypes.object,
  visible: PropTypes.bool,
  onHideModal: PropTypes.func,
  onCreateTeam: PropTypes.func,
  onUpdateSelectedGroup: PropTypes.func,
  defaultModalTitle: PropTypes.string,
};

export default AddNewTeamContainer;
