import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { filter, get, orderBy, head } from "lodash";
import { Badge } from "orcs-design-system";
import styled from "styled-components";

const TreeBadge = styled(Badge)`
  padding: 2px 10px 1px 10px;
`;

const GroupResponse = (props) => {
  const { macroAllocations = [], timeStampField, operation } = props;
  const filtered = filter(macroAllocations, timeStampField);
  const ordered = orderBy(filtered, timeStampField, "desc");
  const mostRecentMacroAllocation = head(ordered);
  const mostRecentDate = get(mostRecentMacroAllocation, timeStampField);
  if (mostRecentDate) {
    return (
      <TreeBadge variant="warning">
        {operation} {moment(mostRecentDate).locale("en-short").fromNow()}
      </TreeBadge>
    );
  }
  return null;
};

GroupResponse.propTypes = {
  macroAllocations: PropTypes.array,
  timeStampField: PropTypes.string,
  operation: PropTypes.string,
};

export default GroupResponse;
