import { Badge, Icon, StyledLink } from "orcs-design-system";
import React from "react";
import styled from "styled-components";

import PropTypes from "prop-types";
import icons from "src/config/icons";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import { getGroupBadgeVariant } from "src/util/group";
import urlPaths, { url } from "src/consts/urlPaths";

import GroupHierarchyBreadcrumb from "../GroupHierarchyBreadcrumb";

const CustomStyledLink = styled(StyledLink)`
  text-decoration: none !important;
  cursor: pointer;
  &:hover,
  &:focus {
    color: rgba(0, 0, 0, 0.4);
    text-decoration: underline !important;
  }
`;

const StyledBadge = styled(Badge)`
  cursor: pointer;
`;
const CustomIcon = styled(Icon)`
  line-height: 1;

  svg {
    opacity: 0.5;
    transition: opacity 300ms ease-in-out;
    transition-delay: 250ms;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

const GroupBadge = ({ group, groupTypes, altStyle = false }) => {
  return (
    <GroupHierarchyBreadcrumb group={group} useChildren>
      <CustomStyledLink
        to={url(urlPaths.TEAM_DETAILS_BASE_PATH, { id: group.id })}
        display="block"
      >
        <StyledBadge
          display="block"
          variant={getGroupBadgeVariant(group, groupTypes)}
          data-testid={`group-badge-${group.id}`}
          altStyle={altStyle}
        >
          <CustomIcon icon={icons.groupBreadcrumb} size="s" mr="xs" />
          {group.name}
        </StyledBadge>
      </CustomStyledLink>
    </GroupHierarchyBreadcrumb>
  );
};

GroupBadge.propTypes = {
  group: GroupPropType.isRequired,
  groupTypes: GroupTypesPropType.isRequired,
  altStyle: PropTypes.bool,
};

export default GroupBadge;
