import React from "react";
import { Icon, StyledLink } from "orcs-design-system";
import themeGet from "@styled-system/theme-get";
import styled, { css } from "styled-components";
import { teamDetailsPath } from "src/util/routing";

const StyledStyledLink = styled(StyledLink)((props) => {
  return css`
    color: ${themeGet(`colors.${props.color}`)};
    &:visited {
      color: ${themeGet(`colors.${props.color}`)};
    }
  `;
});

const getMembersCollectionLabel = ({
  group,
  variant,
  isGroupedBySupplyOrDemand,
}) => {
  const labelMarkup = (
    <span>
      {group.name} <Icon icon={["far", "eye"]} />
    </span>
  );

  const teamPath = teamDetailsPath(group.id);

  const Label =
    group.id === "unallocated" ? (
      labelMarkup
    ) : (
      <StyledStyledLink to={teamPath} color={variant}>
        {labelMarkup}
      </StyledStyledLink>
    );

  return isGroupedBySupplyOrDemand ? Label : "Members";
};

export default getMembersCollectionLabel;
