import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";
import { useApolloClient } from "@apollo/client";
import { isEmpty, map } from "lodash";

import { TAG_OPERATIONS } from "src/consts/tags";
import {
  getPeopleByIds as getPeopleByIdsQuery,
  removePlaceholderPerson,
  updateTagsOnEntities as updateTagsOnEntitiesMutation,
} from "src/allocation/members.graphql";
import { ACTIONS, useDispatch } from "../../../../context/ForecastContext";
import { findAllocation } from "../utils/person.util";

const FulfilVacantRoleButton = ({ person, lineItem, vacantRoleLabel }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const label = vacantRoleLabel || "vacant role";

  const beforeAllocate = useCallback(
    async (newMember) => {
      // Load vacant role again, in case there are some changes somewhere else
      const { data } = await client.query({
        query: getPeopleByIdsQuery,
        variables: {
          ids: [person.aggregateId],
        },
        fetchPolicy: "network-only",
      });

      const [targetPerson] = data.people;

      if (!targetPerson || targetPerson.disabled) {
        throw new Error(`${label} is removed or fulfilled`);
      }

      // Move tags to the new person
      if (!isEmpty(targetPerson.tags)) {
        const { aggregateId } = newMember;

        const mutationList = map(targetPerson.tags, (tag) => {
          return {
            entityId: aggregateId,
            tagId: tag.tagId,
            operation: TAG_OPERATIONS.ADDED,
          };
        });
        await client.mutate({
          mutation: updateTagsOnEntitiesMutation,
          variables: {
            input: {
              list: mutationList,
            },
          },
        });
      }

      // Last step to remove placeholder
      await client.mutate({
        mutation: removePlaceholderPerson,
        variables: {
          personId: person.aggregateId,
        },
      });
    },
    [client, label, person]
  );

  const handleAddMember = useCallback(
    (e) => {
      e.stopPropagation();

      const targetGroupId = lineItem.grouping.groupId;
      const allocation = findAllocation(person.allocations, targetGroupId);

      dispatch({
        type: ACTIONS.SHOW_ADD_MEMBER_MODAL,
        grouping: lineItem.grouping,
        lineItem,
        targetFte: allocation.fte,
        beforeAllocate,
      });
    },
    [lineItem, person, dispatch, beforeAllocate]
  );

  return (
    <ActionsMenuItem
      onClick={handleAddMember}
    >{`Fulfil ${label}`}</ActionsMenuItem>
  );
};

FulfilVacantRoleButton.propTypes = {
  person: PropTypes.object.isRequired,
  lineItem: PropTypes.object.isRequired,
  vacantRoleLabel: PropTypes.string,
};

export default FulfilVacantRoleButton;
