import PropTypes from "prop-types";
import React, { useState } from "react";

import { STATUS } from "src/components/BulkMoveMembersModal/consts";
import { isDemand } from "src/util/directory-customerConfig";
import {
  getGroupDemandTypeFilters,
  getGroupSupplyTypeFilters,
} from "src/util/group";

import TeamSearchAction from "../TeamSearchAction";

const MenuItems = ({
  team,
  teamId,
  status,
  handleBulkMove,
  selectedMembers = {},
  setTargetGroupOption,
  groupTypes,
  closeMenu,
  targetGroupOption,
}) => {
  const [showTeamSearch, setShowTeamSearch] = useState(false);

  const reset = () => {
    setShowTeamSearch(false);
  };

  const onClickMoveTeam = () => {
    setShowTeamSearch(true);
  };

  const groupFilter = isDemand(groupTypes, team)
    ? getGroupDemandTypeFilters(groupTypes)
    : getGroupSupplyTypeFilters(groupTypes);

  return (
    <TeamSearchAction
      groupFilter={groupFilter}
      teamId={teamId}
      closeMenu={closeMenu}
      selectedMembers={selectedMembers}
      groupTypes={groupTypes}
      isProcessing={status?.status === STATUS.PROGRESS}
      onClickMoveTeam={onClickMoveTeam}
      showTeamSearch={showTeamSearch}
      onTeamSelected={setTargetGroupOption}
      handleBulkMove={handleBulkMove}
      reset={reset}
      targetGroupOption={targetGroupOption}
    />
  );
};

MenuItems.propTypes = {
  teamId: PropTypes.string,
  status: PropTypes.object,
  groupTypes: PropTypes.object,
  selectedMembers: PropTypes.object,
  setTargetGroupOption: PropTypes.func,
  handleBulkMove: PropTypes.func,
  closeMenu: PropTypes.func,
  targetGroupOption: PropTypes.object,
  team: PropTypes.object,
};

export default MenuItems;
