import { useReducer, useCallback } from "react";
import { get, union, sortBy } from "lodash";
import constate from "constate";
import { useQuery, useMutation } from "@apollo/client";
import {
  getComments as getCommentsQuery,
  saveComment as saveCommentMutation,
  getAllocationProjectsWithCommentCounts as getAllocationProjectsWithCommentCountsQuery,
} from "src/comments/comments.graphql";
import { TAGS, CONTENT_MARKUP } from "src/consts/comments";
import {
  useGetCommentCountsQueryVariables,
  useForceUpdateCommentCounts,
} from "./CommentCountsContext";
import * as ACTIONS from "./actionTypes";
import modalReducer from "./modalReducer";
import * as util from "./contextUtil";

const initialState = () => ({
  isShowCommentModal: false,
  commentTargetGroup: null,
  commentRoleGroup: null,
  commentAllocationProjectId: null,
});

const useCommentModalReducer = ({
  saveTags,
  queryTags,
  showHistoricAllocationProjects,
  showActiveAllocationProjects,
  showForecastAllocationProjects,
}) => {
  const [state, dispatch] = useReducer(modalReducer, initialState());
  const getCommentCountsQueryVariables = useGetCommentCountsQueryVariables();
  const forceUpdateCommentCounts = useForceUpdateCommentCounts();

  const { isShowCommentModal } = state;

  const {
    data: getCommentsQueryData,
    error: getCommentsQueryError,
    variables: getCommentsQueryVariables,
    networkStatus: getCommentsNetworkStatus,
  } = useQuery(getCommentsQuery, {
    variables: util.getCommentQueryVariablesFromState(state, queryTags),
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: !isShowCommentModal,
  });

  const {
    data: allocationProjectsWithCountsData,
    variables: getCommentCountsByAllocationProjectQueryVariables,
    error: getCommentCountsByAllocationProjectQueryError,
    networkStatus: getCommentCountsByAllocationProjectNetworkStatus,
  } = useQuery(getAllocationProjectsWithCommentCountsQuery, {
    variables: util.getAllocationProjectQueryVariablesFromState(
      state,
      queryTags,
      showHistoricAllocationProjects,
      showActiveAllocationProjects,
      showForecastAllocationProjects
    ),
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip:
      !isShowCommentModal &&
      (showHistoricAllocationProjects ||
        showActiveAllocationProjects ||
        showForecastAllocationProjects),
  });

  const showCommentModal = useCallback(
    ({ allocationProjectId, targetGroup, roleGroup }) => {
      dispatch({
        type: ACTIONS.SHOW_COMMENT_MODAL,
        allocationProjectId,
        targetGroup,
        roleGroup,
      });
    },
    [dispatch]
  );

  const hideCommentModal = useCallback(() => {
    dispatch({
      type: ACTIONS.HIDE_COMMENT_MODAL,
    });
  }, [dispatch]);

  const setCommentAllocationProjectId = useCallback(
    (allocationProjectId) => {
      dispatch({
        type: ACTIONS.SET_COMMENT_ALLOCATION_ID,
        allocationProjectId,
      });
    },
    [dispatch]
  );

  const [saveComment, { error: saveError }] = useMutation(saveCommentMutation);

  // save the comment with the active allocation project id only
  const handleSaveComment = useCallback(
    async (targetAllocationProjectId, content) => {
      await saveComment({
        variables: {
          threadId: util.getThreadId(targetAllocationProjectId, state),
          tags: union(
            [TAGS.USER_COMMENT],
            util.getSaveTags(targetAllocationProjectId, state, saveTags)
          ),
          content,
          contentMarkup: CONTENT_MARKUP.SLATE,
        },
        update: (proxy, { data: { comment: newComment } }) => {
          util.saveCommentMutationUpdater(
            proxy,
            getCommentsQueryVariables,
            newComment
          );
          util.commentCountUpdater(
            proxy,
            getCommentCountsQueryVariables,
            newComment,
            forceUpdateCommentCounts
          );
          util.commentCountsByAllocationProjectUpdater(
            proxy,
            getCommentCountsByAllocationProjectQueryVariables,
            newComment
          );
        },
      });
    },
    [
      saveComment,
      state,
      saveTags,
      getCommentCountsQueryVariables,
      forceUpdateCommentCounts,
      getCommentCountsByAllocationProjectQueryVariables,
      getCommentsQueryVariables,
    ]
  );

  const allocationProjectsWithCounts = sortBy(
    get(allocationProjectsWithCountsData, "counts"),
    "allocationProject.startDate"
  );

  const comments = get(getCommentsQueryData, "result.comments");

  return {
    state,
    showCommentModal,
    hideCommentModal,
    setCommentAllocationProjectId,
    saveComment: handleSaveComment,
    allocationProjectsWithCounts,
    comments,
    loading:
      getCommentsNetworkStatus < 7 ||
      getCommentCountsByAllocationProjectNetworkStatus < 7,
    saveError,
    queryError:
      getCommentsQueryError || getCommentCountsByAllocationProjectQueryError,
  };
};

const selectModalStates = (value) => {
  const {
    saveComment,
    saveError,
    comments,
    loading,
    showCommentModal,
    hideCommentModal,
    setCommentAllocationProjectId,
    state,
    allocationProjectsWithCounts,
  } = value;

  const {
    isShowCommentModal,
    commentTargetGroup,
    commentRoleGroup,
    commentAllocationProjectId,
  } = state;

  return {
    saveComment,
    saveError,
    comments,
    loading,
    isShowCommentModal,
    commentTargetGroup,
    commentRoleGroup,
    commentAllocationProjectId,
    allocationProjectsWithCounts,
    showCommentModal,
    hideCommentModal,
    setCommentAllocationProjectId,
  };
};

const [CommentModalContextProvider, useCommentModal, useShowCommentModal] =
  constate(
    useCommentModalReducer,
    selectModalStates,
    (value) => value.showCommentModal
  );

export { CommentModalContextProvider, useCommentModal, useShowCommentModal };
