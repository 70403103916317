import React from "react";
import PropTypes from "prop-types";
import { Box, Checkbox, Text } from "orcs-design-system";

const PersonLabel = ({ person, name }) => {
  const { isUnallocated, isAllocated, disabled } = person;

  if (isUnallocated || disabled) {
    return <strike>{name}</strike>;
  }
  if (isAllocated) {
    return <Text color="success">{name}</Text>;
  }

  return <Text color="primary">{name}</Text>;
};

PersonLabel.propTypes = {
  person: PropTypes.object,
  name: PropTypes.node,
};

const SelectCheckbox = ({
  node,
  selectedTargets,
  onNodeChecked,
  refreshTree,
}) => {
  const { id, isPerson, name, target } = node;

  // Avoid the click on Checkbox triggering toggle in the tree
  const onWrapperClick = (e) => {
    e.stopPropagation();
  };

  const onSelectChange = (e) => {
    onNodeChecked(node, e.target.checked, refreshTree);
  };

  return (
    <Box onClick={onWrapperClick}>
      <Checkbox
        label={isPerson ? <PersonLabel person={target} name={name} /> : name}
        checked={selectedTargets[id]}
        onChange={onSelectChange}
        colour={isPerson ? "primary" : undefined}
        ml={isPerson ? "xl" : undefined}
      />
    </Box>
  );
};

SelectCheckbox.propTypes = {
  node: PropTypes.object,
  selectedTargets: PropTypes.object,
  onNodeChecked: PropTypes.func,
  refreshTree: PropTypes.func,
};

export default SelectCheckbox;
