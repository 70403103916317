import React from "react";

import PropTypes from "prop-types";
import { Button, Icon, Popover } from "orcs-design-system";

const ToggleButton = ({ onButtonClick, isToggled }) => {
  const handleClick = () => {
    onButtonClick();
  };

  const label = isToggled ? "Expand panel" : "Collapse panel";

  return (
    <Popover text={label} width="fit-content">
      <Button
        iconOnly
        variant="ghost"
        width="26px"
        height="26px"
        onClick={handleClick}
        data-testid="groups-toggle-button"
        ariaLabel={label}
      >
        {isToggled ? (
          <Icon icon={["fas", "arrow-right"]} />
        ) : (
          <Icon icon={["fas", "arrow-left"]} />
        )}
      </Button>
    </Popover>
  );
};

ToggleButton.propTypes = {
  onButtonClick: PropTypes.func,
  isToggled: PropTypes.bool,
};

export default ToggleButton;
