import React from "react";
import PropTypes from "prop-types";
import { Flex, FlexItem, Badge, Box } from "orcs-design-system";
import { get } from "lodash";
import { useGlobalConfig } from "src/contexts/global/GlobalContext";

import GroupPropType from "src/custom-prop-types/group";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import HighlightedGroupTags from "src/components/HighlightedGroupTags";
import GroupMemberCountBadge from "src/components/GroupMemberCountBadge";
import isSupplyDemandGroupType from "src/util/isSupplyDemandGroupType";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { getBgImage, attributeTypes } from "src/util/group";

import { getTeamGradient } from "src/util/gradient";
import { getGroupExternalId } from "src/util/groupAttributes";

import TeamLocation from "src/pages/TeamPage/sub-components/TeamDetailsPanel/TeamLocation";
import {
  getTeamHeaderHeight,
  TeamHeaderBox,
} from "src/util/DetailsHeaderUtils";
import TeamName from "../TeamName/indexV2";
import ActionsMenu from "../TeamDetailPage/ActionsMenu";
import GroupDeletedBadge from "./GroupDeletedBadge";
import FteQueryBadgeContainer from "./FteQueryBadgeContainer";

const Heading = ({ team, refetch, loading }) => {
  const workspace = useWorkspace();
  const globalConfig = useGlobalConfig();
  const [isSupply, isDemand] = isSupplyDemandGroupType(team, workspace);
  const { isDeleted, isHidden, deletedAt, hiddenAt } = team;
  const displayFteBadge = !isDeleted && !isHidden && (isSupply || isDemand);
  const bgImg = getBgImage(get(team, "attributes"), attributeTypes.IMAGE);
  const bgGradient = getTeamGradient(team, globalConfig);

  const memberCount = team?.memberCount;
  const workspaceFeatureFlags = get(workspace, "config.featureFlags", {});
  const externalId = getGroupExternalId(team);
  const showExternalIdBadge =
    workspaceFeatureFlags?.showTeamExternalIdAsBadge && externalId;
  const isGroupActive = !team?.isHidden && !team?.isDeleted;

  return (
    <TeamHeaderBox
      bgImg={bgImg}
      bgGradient={bgGradient}
      loading={loading}
      boxBorder="default"
      borderRadius={2}
      borderBottomLeftRadius={0}
      borderBottomRightRadius={0}
      p="r"
      width="100%"
      minHeight={getTeamHeaderHeight(bgImg)}
    >
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Box p="r" bg="black70" data-testid="cp-team-header-container">
          <TeamLocation team={team} loading={loading} workspace={workspace} />
          <Flex alignItems="center" flexWrap="wrap">
            <TeamName
              team={team}
              className="guided-tour-team-details-team-name"
            />
            {(isDeleted || isHidden) && (
              <FlexItem flex="0 0 auto" ml="xs">
                <GroupDeletedBadge removedAt={deletedAt || hiddenAt} />
              </FlexItem>
            )}
            <FlexItem flex="0 0 auto" ml="xs">
              <GroupTypeBadge
                group={team}
                showToolTip
                className="guided-tour-team-details-team-type"
              />
            </FlexItem>
            {showExternalIdBadge && (
              <FlexItem flex="0 0 auto" ml="xs">
                <Badge role="listitem" display="block" noWrap>
                  {externalId}
                </Badge>
              </FlexItem>
            )}
            <FlexItem flex="0 0 auto" ml="xs">
              {!isNaN(memberCount) && (
                <GroupMemberCountBadge
                  count={team.memberCount}
                  isTotalMemberCount
                  hideWhenZero
                  showToolTip
                  className="guided-tour-team-details-member-count"
                />
              )}
            </FlexItem>
            {displayFteBadge && (
              <FlexItem flex="0 0 auto" ml="xs">
                <FteQueryBadgeContainer team={team} workspace={workspace} />
              </FlexItem>
            )}
          </Flex>
          <HighlightedGroupTags team={team} small />
        </Box>
        <Flex>
          {isGroupActive && <ActionsMenu team={team} refetch={refetch} />}
        </Flex>
      </Flex>
    </TeamHeaderBox>
  );
};

Heading.propTypes = {
  team: GroupPropType.isRequired,
  refetch: PropTypes.func,
  loading: PropTypes.bool,
};

export default Heading;
