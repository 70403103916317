import React from "react";
import PropTypes from "prop-types";
import { get, isNil, isEmpty } from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import { Box, H3 } from "orcs-design-system";
import ErrorNotification from "src/components/ErrorNotification";

import { getSourceGroups } from "src/allocation/team.graphql";
import { changePersonSupplyGroup } from "src/allocation/person.graphql";

import SupplyGroupSearch from "./SupplyGroupSearch";

const SupplyGroupAllocation = ({ person, onSupplyGroupChanged }) => {
  const [changeSupplyGroup, { error, loading: isChanging }] = useMutation(
    changePersonSupplyGroup
  );

  const { aggregateId, sourceGroupIds, memberOf } = person;

  const {
    data,
    loading: isLoading,
    error: loadError,
  } = useQuery(getSourceGroups, {
    variables: {
      groupIds: sourceGroupIds,
    },
    skip: !isNil(memberOf) || isEmpty(sourceGroupIds),
  });

  const onAllocationChange = (newGroup, originalGroupId) => {
    changeSupplyGroup({
      variables: {
        personId: aggregateId,
        groupId: newGroup ? newGroup.id : null,
      },
      update(cache, { data: { result } }) {
        if (onSupplyGroupChanged) {
          onSupplyGroupChanged(newGroup, originalGroupId, cache, result);
        }
      },
    });
  };

  return (
    <Box>
      <H3 bold>Chapter allocation</H3>
      <SupplyGroupSearch
        person={person}
        isLoading={isLoading || isChanging}
        groups={memberOf || get(data, "sourceGroups", [])}
        onAllocationChange={onAllocationChange}
      />
      {(error || loadError) && (
        <ErrorNotification
          message="Sorry, an error occurred, please try again."
          error={error || loadError}
        />
      )}
    </Box>
  );
};

SupplyGroupAllocation.propTypes = {
  person: PropTypes.object,
  onSupplyGroupChanged: PropTypes.func,
};

export default SupplyGroupAllocation;
