import React from "react";
import PropTypes from "prop-types";
import { forEach, keyBy } from "lodash";
import {
  ACTIONS,
  useDispatch,
  useUserInteraction,
} from "../../pages/ForecastPage/context/ForecastContext";
import useRequestAllocation from "./hooks/useRequestAllocation";
import EnhancedAddNewRoleModal from ".";

const ChapterModal = ({
  supplyLabel,
  visible,
  loading,
  onHideModal,
  existingGroups,
  groupTypesLookup,
  targetGroupId,
  isPlannerPage,
  expansionLevel,
  rootGroups,
  pageMode,
  copywriting,
}) => {
  const dispatch = useDispatch();
  const {
    addingRole: { macroAllocationType, targetView },
  } = useUserInteraction();

  const saveAllocation = useRequestAllocation({
    isPlannerPage,
    targetGroupId,
  });
  const selectedChapterHeading = isPlannerPage
    ? copywriting.SELECTED_CHAPTERS_PANEL_PLANNER
    : copywriting.SELECTED_CHAPTERS_PANEL_FORECAST;

  const handleSubmit = async ({
    selectedGroupsArray,
    selectedGroupsFte,
    onClearValuesAndHideModal,
    setErrorSaving,
    setSavingMacroAllocations,
    rootAndSupplyRootLookup,
  }) => {
    try {
      if (isPlannerPage) {
        await saveAllocation({
          selectedGroupsArray,
          selectedGroupsFte,
        });
      } else {
        forEach(selectedGroupsArray, (group) => {
          const { supplyRoot } = rootAndSupplyRootLookup[group.id];
          const sourceGroupLookup = keyBy([group, supplyRoot], "id");

          dispatch({
            type: ACTIONS.USER_ADD_ROLE,
            pageMode,
            targetGroupId,
            role: group,
            supplyRoot,
            sourceGroupLookup,
            macroAllocationType,
            targetView,
          });
        });
      }
      onClearValuesAndHideModal();
    } catch (error) {
      setErrorSaving(true);
    }
    setSavingMacroAllocations(false);
  };

  return (
    <EnhancedAddNewRoleModal
      selectedChapterHeading={selectedChapterHeading}
      handleOnConfirm={handleSubmit}
      supplyLabel={supplyLabel}
      visible={visible}
      loading={loading}
      onHideModal={onHideModal}
      existingGroups={existingGroups}
      groupTypesLookup={groupTypesLookup}
      targetGroupId={targetGroupId}
      isPlannerPage={isPlannerPage}
      expansionLevel={expansionLevel}
      rootGroups={rootGroups}
      copywriting={copywriting}
    />
  );
};

ChapterModal.propTypes = {
  supplyLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onHideModal: PropTypes.func,
  existingGroups: PropTypes.array,
  groupTypesLookup: PropTypes.object,
  isPlannerPage: PropTypes.bool,
  expansionLevel: PropTypes.number,
  rootGroups: PropTypes.array,
  targetGroupId: PropTypes.string,
  pageMode: PropTypes.string,
  copywriting: PropTypes.object,
};

export default ChapterModal;
