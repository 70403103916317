import React from "react";
import PropTypes from "prop-types";
import { ActionsMenu } from "orcs-design-system";

import { isRequestorPlannerReadonly } from "../../../util/readonlyFlags";
import { useLookupData } from "../../../../context/ForecastContext";
import AllocateButton from "./AllocateButton";
import MoveButton from "./MoveButton";
import RemoveButton from "./RemoveButton";
import FulfilVacantRoleButton from "./FulfilVacantRoleButton";
import RemoveVacantRoleButton from "./RemoveVacantRoleButton";

const MemberRowActionMenu = ({
  person,
  lineItem,
  isAllocationEnabled,
  onAllocatePerson,
  searchVariables,
  onMovePerson,
  onRemovePerson,
}) => {
  const {
    activeAllocationProject,
    enableAddVacantRole,
    allocateBtnLabel,
    hideAllocateButton,
    moveMemberBtnLabel,
    vacantRoleLabel,
  } = useLookupData();

  if (isRequestorPlannerReadonly(activeAllocationProject)) {
    return null;
  }

  const buttons = [];

  if (isAllocationEnabled && !person.disabled) {
    if (!hideAllocateButton) {
      buttons.push(
        <AllocateButton
          key="allocate"
          person={person}
          onAllocatePerson={onAllocatePerson}
          allocateBtnLabel={allocateBtnLabel}
        />
      );
    }

    if (!person.isUnallocated) {
      buttons.push(
        <MoveButton
          key="move"
          person={person}
          onMovePerson={onMovePerson}
          moveMemberBtnLabel={moveMemberBtnLabel}
        />
      );

      if (hideAllocateButton) {
        buttons.push(
          <RemoveButton
            key="remove"
            person={person}
            onRemovePerson={onRemovePerson}
          />
        );
      }
    }
  }

  if (enableAddVacantRole && person.isPlaceholder && !person.disabled) {
    buttons.push(
      <FulfilVacantRoleButton
        key="fulfil-vacant-role"
        person={person}
        lineItem={lineItem}
        vacantRoleLabel={vacantRoleLabel}
        searchVariables={searchVariables}
      />
    );

    buttons.push(
      <RemoveVacantRoleButton
        key="remove"
        person={person}
        lineItem={lineItem}
        vacantRoleLabel={vacantRoleLabel}
        searchVariables={searchVariables}
      />
    );
  }

  if (!buttons.length) {
    return null;
  }

  return (
    <ActionsMenu direction="left-start" ml="auto">
      {buttons}
    </ActionsMenu>
  );
};

MemberRowActionMenu.propTypes = {
  person: PropTypes.object,
  lineItem: PropTypes.object,
  isAllocationEnabled: PropTypes.bool,
  onAllocatePerson: PropTypes.func,
  searchVariables: PropTypes.object,
  onMovePerson: PropTypes.func,
  onRemovePerson: PropTypes.func,
};

export default MemberRowActionMenu;
