import React from "react";
import PropTypes from "prop-types";
import {
  FORECAST_REQUESTOR_MSG_TYPE,
  FORECAST_APPROVER_MSG_TYPE,
} from "src/consts/announcement";

import PopoverNotification from "./PopoverNotification";
import ProjectAnnouncement from "./ProjectAnnouncement";

const ForecastNotification = ({ activeAllocationProject, rootGroup }) => {
  const { isDemand, isSource } = rootGroup;

  if (!isDemand && !isSource) {
    return null;
  }

  const {
    isRequestorForecastReadonly,
    requestorForecastReadonlyBanner,
    requestorForecastReadonlyHover,
    isApproverForecastReadonly,
    approverForecastReadonlyBanner,
    approverForecasteadonlyHover,
    announcements,
  } = activeAllocationProject;

  if (isDemand && isRequestorForecastReadonly) {
    return (
      <PopoverNotification
        text={requestorForecastReadonlyBanner}
        popoverText={requestorForecastReadonlyHover}
        colour="warning"
      />
    );
  }

  if (isSource && isApproverForecastReadonly) {
    return (
      <PopoverNotification
        text={approverForecastReadonlyBanner}
        popoverText={approverForecasteadonlyHover}
        colour="warning"
      />
    );
  }

  return (
    <ProjectAnnouncement
      announcements={announcements}
      messageType={
        isDemand ? FORECAST_REQUESTOR_MSG_TYPE : FORECAST_APPROVER_MSG_TYPE
      }
    />
  );
};

ForecastNotification.propTypes = {
  activeAllocationProject: PropTypes.object.isRequired,
  rootGroup: PropTypes.object.isRequired,
};

export default ForecastNotification;
