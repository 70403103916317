import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import GroupHierarchyQueryBreadcrumb from "src/components/GroupHierarchyBreadcrumb/GroupHierarchyQueryBreadcrumb";

import { getGroupContainerId } from "./utils";

const TimelineGroupHeader = ({ group }) => {
  return ReactDOM.createPortal(
    <GroupHierarchyQueryBreadcrumb group={group} showGroupTypeBadge />,
    document.querySelector(`#${getGroupContainerId(group)}`)
  );
};

TimelineGroupHeader.propTypes = {
  group: PropTypes.object.isRequired,
};

export default TimelineGroupHeader;
