import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { themeGet } from "@styled-system/theme-get";

const StyledHeader = styled.div`
  height: ${(props) => themeGet("appScale.navBarSize")(props)};
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${(props) => themeGet("space.r")(props)};
  padding-right: ${(props) => themeGet("space.r")(props)};
  background-color: #000;
  color: #fff;
  font-weight: 600;
`;

const Logo = styled.img`
  ${(props) => props.styles}
`;

const HeaderPreview = ({ config }) => {
  return (
    <StyledHeader>
      <Logo {...config.logoImage} />
      <div>{config.appName}</div>
    </StyledHeader>
  );
};

HeaderPreview.propTypes = {
  config: PropTypes.object,
};

export default HeaderPreview;
