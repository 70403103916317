import { map } from "lodash";

const getMacroAllocationTemplate = (
  allocationProjectIds,
  groupId
) => `macroAllocationsForGroup_${groupId}: getMacroAllocationsAbsoluteValues(
    allocationProjectIds: ${JSON.stringify(allocationProjectIds)}
    targetGroupId: ${JSON.stringify(groupId)}
  ) {
    id
    allocationProjectId
    requestorGroupId
    approverGroupId
    targetGroupId
    targetGroup {
      id
      isHidden
      isObjectiveImported
      name
      hierarchyIds
      parentIds
      type
      kind
    }
    sourceGroupId
    sourceGroup {
      id
      isHidden
      isObjectiveImported
      name
      hierarchyIds
      parentIds
      type
      kind
    }
    allocatedMemberFte
    requested
    realtimeCurrentMemberFte
    requestSubmitted
    approved
    approvalSubmitted
    requestStatus
    approvalStatus
    requestSavedAt
    approvalSavedAt
    requestSubmittedAt
    approvalSubmittedAt
    forecast
    forecastedAt
    note
    approvalReasonId
    approvalReasonCustomText
    type
  }`;

const buildMacroAllocationsQuery = (allocationProjectIds, groupIds) => {
  return ` query getMacroAllocationsForMovedOutGroups {
      ${map(groupIds, (groupId) =>
        getMacroAllocationTemplate(allocationProjectIds, groupId)
      )}
    }`;
};

export default buildMacroAllocationsQuery;
