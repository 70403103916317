import { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { forEach, isEmpty, map } from "lodash";

import { ASSOCIATION_PROVIDER, ASSOCIATION_CONSUMER } from "src/consts/tags";
import { getGroupExtraInfo } from "src/allocation/team.graphql";
import {
  usePageMode,
  useMainQuery,
  useViewMode,
  useModelLookups,
  useLookupData,
  useRootGroup,
  useDispatch,
  ACTIONS,
} from "./context/ForecastContext";
import { PLANNER } from "./context/reducer/pageModes";
import { GROUPED_BY_DEMAND } from "./context/reducer/viewModes";

const convertAssociations = (groups, groupingLookup) => {
  const movedGroups = [];
  const newGroups = [];
  const groupTags = {};

  forEach(groups, (group) => {
    const { id, groupAssociations, tags } = group;
    const grouping = groupingLookup[id];

    if (!grouping) {
      return;
    }

    // Add tags to the map, default value is []
    groupTags[id] = tags;

    if (isEmpty(groupAssociations)) {
      return;
    }

    forEach(groupAssociations, (association) => {
      const { associationDirection, group: associatedGroup } = association;

      if (!associationDirection) {
        return;
      }

      const { mode } = associationDirection;

      // Current group is moved to another group
      if (mode === ASSOCIATION_PROVIDER) {
        movedGroups.push({
          id,
          group,
          newParent: associatedGroup,
        });
      } else if (mode === ASSOCIATION_CONSUMER) {
        // Current group is the new parent of another group
        newGroups.push({
          id: associatedGroup.id,
          newGroup: associatedGroup,
          parentGroup: group,
        });
      }
    });
  });

  return {
    movedGroups,
    newGroups,
    groupTags,
  };
};

const useFetchGroupExtraInfo = () => {
  const client = useApolloClient();
  const pageMode = usePageMode();
  const viewMode = useViewMode();
  const rootGroup = useRootGroup();
  const { loading } = useMainQuery();
  const { enableMoveGroupByTag, enableSetGroupTags } = useLookupData();
  const { groupingLookup } = useModelLookups();
  const dispatch = useDispatch();

  const isToggledOn = enableMoveGroupByTag || enableSetGroupTags;

  useEffect(() => {
    const groupIds = map(groupingLookup, (g) => g.groupId);
    const skip =
      !isToggledOn ||
      pageMode !== PLANNER ||
      viewMode !== GROUPED_BY_DEMAND ||
      loading ||
      isEmpty(groupIds);

    if (skip) {
      return;
    }

    client
      .query({
        query: getGroupExtraInfo,
        variables: {
          groupIds,
          enableMoveGroupByTag: !!enableMoveGroupByTag,
          enableSetGroupTags: !!enableSetGroupTags,
          tagTypes: [], // pass in empty tagTypes to load all types of tags
        },
        fetchPolicy: "network-only",
      })
      .then((queryData) => {
        const { groups } = queryData.data;
        const results = convertAssociations(groups, groupingLookup);

        dispatch({
          type: ACTIONS.UPDATE_GROUP_EXTRA_INFO,
          ...results,
          rootGroup,
        });
      })
      .catch(() => {
        // Do nothing here. This is not super important information
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode, loading, isToggledOn]);
};

export default useFetchGroupExtraInfo;
