/* eslint-disable import/prefer-default-export */
import { lowerCase, upperFirst } from "lodash";
import pluralize from "pluralize";

export const getCopywriting = (supplyLabel = "chapter") => {
  const pluralizedSupplyLabel = pluralize(lowerCase(supplyLabel));
  return {
    CHAPTERS_TREE_PANEL: `Select what ${pluralizedSupplyLabel} you'd like to add`,
    CHAPTER_DESCRIPTION_PANEL: `${upperFirst(supplyLabel)} Description`,
    SELECTED_CHAPTERS_PANEL_PLANNER: `Specify FTE to add for the selected ${pluralizedSupplyLabel}`,
    SELECTED_CHAPTERS_PANEL_FORECAST: `Selected ${pluralizedSupplyLabel}`,
    PLACEHOLDER_DESCRIPTION: `Specify how many vacant roles of each ${lowerCase(
      supplyLabel
    )} you'd like to add`,
  };
};
