/* eslint-disable no-param-reassign */
import { useCallback, useMemo } from "react";
import { map, filter, isEmpty } from "lodash";
import { useApolloClient } from "@apollo/client";
import { getDescendantGroups as getDescendantGroupsQuery } from "src/allocation/team.graphql";
import { makeGroupsHierarchy, getAllRootSupplyGroupTypes } from "../utils";

const useNodeToggled = ({
  setActiveGroup,
  existingGroups,
  groupTypesLookup,
  refreshAfterNodeFound,
  groupsHierarchy,
  handleGroupJump,
}) => {
  const client = useApolloClient();

  const supplySourceGroupTypes = useMemo(() => {
    const { supplySourceTypes = [] } =
      getAllRootSupplyGroupTypes(groupTypesLookup);
    return supplySourceTypes;
  }, [groupTypesLookup]);

  const loadData = useCallback(
    async ({ query, variables }) => {
      const { data } = await client.query({
        query,
        variables,
      });

      return { data };
    },
    [client]
  );

  const handleNodeToggled = useCallback(
    async (node, refreshTree) => {
      setActiveGroup(null);
      node.active = false;
      const { toggled, isParentType, children, loading: nodeLoading } = node;

      if (!toggled) {
        return;
      }

      const targetNodes =
        isParentType && nodeLoading ? [node] : isParentType ? children : null;

      const notLoadedNodes = filter(targetNodes, "loading");
      const groupIds = map(notLoadedNodes, "id");

      if (isEmpty(groupIds)) {
        return;
      }

      const { data } = await loadData({
        query: getDescendantGroupsQuery,
        variables: { groupIds, supplySourceTypes: supplySourceGroupTypes },
      });

      const descendantGroups = makeGroupsHierarchy({
        groups: data.groups,
        groupTypesLookup,
        existingGroups,
        filterChildren: true,
      });

      node.children = !isEmpty(descendantGroups) ? descendantGroups : null;
      node.loading = false;

      refreshTree();
    },
    [
      setActiveGroup,
      loadData,
      supplySourceGroupTypes,
      groupTypesLookup,
      existingGroups,
    ]
  );

  const findNodesToQuery = useCallback(
    async ({ hierarchyIds, tree, refreshTree }) => {
      const idsArray = [...hierarchyIds];
      const id = idsArray.shift();
      if (id) {
        const node =
          (!isEmpty(tree) && tree.find((treeNode) => treeNode.id === id)) ||
          null;

        if (node) {
          node.toggled = true;
          await handleNodeToggled(node, refreshTree);
        }

        await findNodesToQuery({
          hierarchyIds: idsArray,
          tree: node ? node.children : tree,
          refreshTree,
        });
      }
    },
    [handleNodeToggled]
  );

  const expandToGroup = useCallback(
    async (group) => {
      const { id: groupId, hierarchyIds } = group;
      const { refreshTree } = refreshAfterNodeFound;

      await findNodesToQuery({
        hierarchyIds,
        tree: groupsHierarchy,
        refreshTree,
        groupId,
      });

      handleGroupJump(groupId);
    },
    [findNodesToQuery, groupsHierarchy, handleGroupJump, refreshAfterNodeFound]
  );

  return { handleNodeToggled, expandToGroup };
};

export default useNodeToggled;
