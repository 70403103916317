import { COLUMN_TYPES } from "../../../context/ForecastContext";
import render from "../../util/renderStringTemplate";

export const getForecastConstraintEnableFlag = (
  rootGroup,
  allocationProject
) => {
  const {
    enableRequestorForecastConstraint,
    enableApproverForecastConstraint,
  } = allocationProject;
  const { isDemand } = rootGroup;

  return isDemand
    ? enableRequestorForecastConstraint
    : enableApproverForecastConstraint;
};

export const getColumnTitle = (columnType, allocationProject) => {
  const {
    allocationName,
    baselineColumnName,
    requestorColumnName,
    approverColumnName,
  } = allocationProject;

  const templateData = {
    AllocationProjectName: allocationName,
  };

  const templates = {
    baselineColumnName:
      baselineColumnName || "{AllocationProjectName} Baseline",
    requestorColumnName:
      requestorColumnName || "Requested for {AllocationProjectName}",
    approverColumnName:
      approverColumnName || "Approved for {AllocationProjectName}",
  };

  switch (columnType) {
    case COLUMN_TYPES.PLANNER_CURRENT:
      return render(templates.baselineColumnName, templateData);
    case COLUMN_TYPES.PLANNER_REQUESTED:
      return render(templates.requestorColumnName, templateData);
    case COLUMN_TYPES.PLANNER_APPROVED:
      return render(templates.approverColumnName, templateData);
    case COLUMN_TYPES.FORECAST:
      return allocationName;
    case COLUMN_TYPES.CONSTRAINT:
      return allocationName;
    default:
      return "";
  }
};

export const getPopoverTitle = (allocationProject, columnType) => {
  const {
    allocationName,
    isActive,
    baselineColumnTooltip,
    requestorColumnTooltip,
    approverColumnTooltip,
  } = allocationProject;

  const templates = {
    baselineColumnTooltip:
      baselineColumnTooltip || "{AllocationProjectName} Baseline",
    requestorColumnTooltip:
      requestorColumnTooltip || "{AllocationProjectName} Requested",
    approverColumnTooltip:
      approverColumnTooltip || "{AllocationProjectName} Approved",
  };

  const templateData = {
    AllocationProjectName: allocationName,
  };

  switch (columnType) {
    case COLUMN_TYPES.PLANNER_CURRENT:
      return render(templates.baselineColumnTooltip, templateData);

    case COLUMN_TYPES.PLANNER_REQUESTED:
      return render(templates.requestorColumnTooltip, templateData);

    case COLUMN_TYPES.PLANNER_APPROVED:
      return render(templates.approverColumnTooltip, templateData);

    case COLUMN_TYPES.FORECAST:
      if (isActive) {
        return `${allocationName} Requested`;
      }
      return `${allocationName} Forecast`;

    case COLUMN_TYPES.CONSTRAINT:
      return `${allocationName} Constraints`;
    default:
      return allocationName;
  }
};
