import { get, some } from "lodash";
import { PAGE_MODES } from "../../context/ForecastContext";

export const hasEditableProjects = (
  pageMode,
  activeAllocationProject,
  columns
) => {
  if (pageMode === PAGE_MODES.PLANNER) {
    if (
      !activeAllocationProject ||
      activeAllocationProject.isRequestorPlannerReadonly
    ) {
      return false;
    }
    return true;
  }

  if (pageMode === PAGE_MODES.FORECAST) {
    const hasEditableProject = some(
      columns,
      (c) =>
        get(c.allocationProject, "forecast", false) &&
        !get(c.allocationProject, "isRequestorForecastReadonly", false)
    );

    return hasEditableProject;
  }

  return false;
};

export const isRequestorPlannerReadonly = (allocationProject) => {
  return allocationProject && allocationProject.isRequestorPlannerReadonly;
};
