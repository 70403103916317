import React from "react";
import PropTypes from "prop-types";
import { P } from "orcs-design-system";
import AuthorTitle from "../AuthorTitle";

const SetGroupManagerHistoryContent = ({ authorTitle, content }) => {
  return (
    <>
      <P>{content}</P>
      <AuthorTitle action="Made" authorTitle={authorTitle} />
    </>
  );
};

SetGroupManagerHistoryContent.propTypes = {
  content: PropTypes.string,
  authorTitle: PropTypes.string,
};

export default SetGroupManagerHistoryContent;
