import { useCallback } from "react";
import { requestMacroAllocationAbsolute } from "src/allocation/allocation.graphql";
import { createMacroAllocationId } from "src/allocation/util/macroAllocation";

import { ACTIONS, SAVE_STATUS } from "../../../context/ForecastContext";

const useSaveMacroAllocation = (client, dispatch) => {
  const saveRequestedFte = useCallback(
    async ({
      cellId,
      allocationProjectId,
      sourceGroupId,
      targetGroupId,
      requested,
      realtimeCurrentMemberFte,
    }) => {
      dispatch({
        type: ACTIONS.REALTIME_CURRENT_MEMBER_FTE_UPDATE,
        cellId,
        value: realtimeCurrentMemberFte,
      });

      dispatch({
        type: ACTIONS.REQUEST_INPUT_UPDATE,
        cellId,
        value: requested,
        valueSaveStatus: SAVE_STATUS.SUCCESS,
      });

      const { data } = await client.mutate({
        mutation: requestMacroAllocationAbsolute,
        variables: {
          id: createMacroAllocationId(
            allocationProjectId,
            sourceGroupId,
            targetGroupId
          ),
          allocationProjectId,
          sourceGroupId,
          targetGroupId,
          requested,
          realtimeCurrentMemberFte,
        },
      });

      return data;
    },
    [client, dispatch]
  );

  return saveRequestedFte;
};

export default useSaveMacroAllocation;
