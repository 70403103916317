import { get, forEach, keyBy, values, reduce, find, map } from "lodash";

export const getColumns = (
  gridConfig,
  requestorCell,
  isForecast,
  isMemberFteEdit,
  hideApproverColumn,
  hideBudgetColumn
) => {
  if (isForecast) {
    return [gridConfig.forecast.templateColumns, gridConfig.forecast.gap];
  }

  // Planner page
  if (hideApproverColumn && !hideBudgetColumn) {
    return [
      gridConfig.planner.templateColumns.requestorLineItem,
      gridConfig.planner.gap,
    ];
  }

  if (isMemberFteEdit) {
    return [
      gridConfig.planner.templateColumns.editorLineItem,
      gridConfig.planner.lineItemGap,
    ];
  }

  return [
    requestorCell.isEditable
      ? gridConfig.planner.templateColumns.requestorLineItem // TODO: Looks like we should be returning templateColumns here
      : gridConfig.planner.templateColumns.approverLineItem, // TODO: Looks like we should be returning templateColumns here
    gridConfig.planner.gap,
  ];
};

export const mergePeople = (pitPeopleList, latestPeopleList) => {
  const pitMap = keyBy(pitPeopleList, "aggregateId");
  const latestMap = keyBy(latestPeopleList, "aggregateId");

  const fullMap = {};

  forEach(latestPeopleList, (person) => {
    if (!pitMap[person.aggregateId]) {
      fullMap[person.aggregateId] = {
        ...person,
        isAllocated: true,
      };
    }
  });

  forEach(pitPeopleList, (person) => {
    const latestPerson = latestMap[person.aggregateId];
    if (latestPerson) {
      fullMap[person.aggregateId] = {
        ...person,
        currentAllocations: latestPerson.allocations,
      };
    } else {
      fullMap[person.aggregateId] = {
        ...person,
        isUnallocated: true,
      };
    }
  });

  return values(fullMap);
};

export const getPeopleList = (pitData, latestData, enableMemberAllocation) => {
  const pitPeopleList = get(pitData, "result.people", []);
  if (!pitData || !latestData) {
    return pitPeopleList;
  }

  if (enableMemberAllocation) {
    return mergePeople(pitPeopleList, get(latestData, "result.people", []));
  }
  return pitPeopleList;
};

export const filterChangedAllocations = (prevAllocations, allocations) => {
  return reduce(
    allocations,
    (prev, a) => {
      if (find(prevAllocations, (o) => o.targetGroupId === a.targetGroupId)) {
        return prev;
      }

      prev.push({
        id: a.targetGroupId,
      });

      return prev;
    },
    []
  );
};

export const updateAllocationGroups = (allocationGroups, groupsData) => {
  if (!groupsData) {
    return;
  }
  const { groups } = groupsData;
  const groupsMap = keyBy(groups, (g) => g.id);

  forEach(allocationGroups, (ag) => {
    const group = groupsMap[ag.id];
    if (!group) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    ag.name = group.name;
  });
};

export const processSearchResults = (members, targetGroupId) => {
  return map(members, (member) => {
    const { allocations, prevAllocations } = member;

    const isAllocated = !find(
      prevAllocations,
      (a) => a.targetGroupId === targetGroupId
    );

    if (!isAllocated) {
      return member;
    }

    const changedAllocations = filterChangedAllocations(
      allocations,
      prevAllocations
    );

    return {
      ...member,
      changedAllocations,
      isAllocated,
    };
  });
};

export const getUpdatedGroupIds = (peopleList) => {
  return reduce(
    peopleList,
    (prev, p) => {
      const { changedAllocations } = p;
      prev.push(...(changedAllocations || []));

      return prev;
    },
    []
  );
};
