import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ActionsMenuItem } from "orcs-design-system";
import { TEAM_TAB, url } from "src/consts/urlPaths";

const OpenInTeamBuilderLink = ({ grouping }) => {
  const { root, group, canViewInTeamBuilder } = grouping;

  if (!canViewInTeamBuilder) {
    return null;
  }

  const { id, name, hierarchy, hierarchyIds, type } = group;
  const { groupId: rootGroupId } = root;

  return (
    <ActionsMenuItem
      as={Link}
      to={{
        pathname: url(
          `/:workspaceId?/teams/${rootGroupId}/${TEAM_TAB.INDIVIDUAL_ALLOCATIONS}`
        ),
        state: {
          activeNode: {
            id,
            name,
            hierarchy,
            hierarchyIds,
            type,
          },
          searchText: null,
        },
      }}
    >
      Open in team builder
    </ActionsMenuItem>
  );
};

OpenInTeamBuilderLink.propTypes = {
  grouping: PropTypes.object.isRequired,
};

export default OpenInTeamBuilderLink;
