export const JIRA_TEAMS_EXPORT_PHRASES = ["atlassian", "teams"];
export const JIRA_CSV_LINK_PHRASES = ["atlassian", "csv", "link"];
export const JIRA_STATUS_COLOR_TO_HEX = {
  "blue-gray": "#6B778C",
  green: "#36B37E",
  yellow: "#FFAB00",
  blue: "#0052CC",
  red: "#FF5630",
  purple: "#6554C0",
  teal: "#00B8D9",
};
export const JIRA_STATUS_COLOR_TO_BADGE_VARIANT = {
  "blue-gray": "default",
  green: "success",
  yellow: "warning",
  blue: "primaryDark",
  red: "danger",
  purple: "secondary",
  teal: "primary",
};
