// TODO: Fix.
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/**
 * prepares raw node data for pretty visualisation
 */
import { cloneDeep, pull } from "lodash";

/**
 * This function moves the node to array[index].
 * It only moves the node to the front (when the index is smaller than the current location).
 * If the current position is larger than the position we want to move
 * to, then we must be in a cycle (as we processing the positions starting from 0)
 *
 * @param array
 * @param node
 * @param index
 * @returns {boolean}
 */
function switchPlacesTo(array, node, index) {
  const currentIndexOfNodeId = array.findIndex((p) => p === node);

  if (currentIndexOfNodeId < index) {
    return false;
  }
  const currentValueAtIndex = array[index];
  array[index] = array[currentIndexOfNodeId];
  array[currentIndexOfNodeId] = currentValueAtIndex;
  return true;
}

/**
 * This function moves the the children of the parent in the relationship
 * to the particular location.
 *
 * @param array
 * @param parent
 * @param childIndex
 * @param relationship
 */
function switchPlacesOf(array, parent, childIndex, relationship) {
  const childIds = relationshipIdsOf(parent, relationship);
  childIds.forEach((childId) => {
    const child = array.find((p) => p.id === childId);
    const switched = switchPlacesTo(array, child, childIndex);
    // breaks cycles
    if (switched) {
      switchPlacesOf(array, child, childIndex + 1, relationship);
    }
  });
}

/**
 * This function returns the array of IDs of the relationship of the entity.
 * e.g. the IDs of the people managed by the manager.
 *
 * the relationship ids may contain itself. So it is removed.
 * e.g. manager's manager is himself/herself.
 * @param entity
 * @param relationshipType
 * @returns {Array}
 */
function relationshipIdsOf(entity, relationshipType) {
  const relationships = entity.relationships || [];
  const relationship =
    relationships.find((r) => r.type === relationshipType) || {};

  return pull(relationship.ids, entity.id) || [];
}

/**
 * This function updates the nodes under the relationshipType of the
 * rootEntity to the specific level recursively.
 * e.g. If rootEntity is a Person, update all the managing nodes' level
 * to 2. Then for every person under the rootEntity, update their managing
 * node's level to 3 and so on.
 *
 * @param dataset
 * @param rootEntity
 * @param level
 * @returns {*}
 */
function applyPersonLevel(nodes, rootEntity, level) {
  if (rootEntity.level) {
    // we've already traversed this entity (there must be a loop)
    return level;
  }
  let maxLevel = level;
  let candidateMax = level;
  rootEntity.level = level;
  const ids = relationshipIdsOf(rootEntity, "MANAGING");

  ids.forEach((id) => {
    const entry = nodes.find((e) => e.id === id);
    entry.level = level + 1;
    candidateMax = applyPersonLevel(nodes, entry, level + 1);
    if (candidateMax > maxLevel) {
      maxLevel = candidateMax;
    }
  });
  return maxLevel;
}

/**
 * This function sets level and order of the nodes above the target.
 *
 *    ppppppp           level = 1
 *          |
 *          p(target)   level = 2

 *
 * Before this function is called, the level of other nodes are already
 * set. So this function first finds the nodes which don't have level and
 * set their level to 1. Then sort the top nodes in a right order.
 *
 *
 * @param nodes
 */
function prepareTopPeople(nodes, relationship) {
  const abovePersonOfInterest = nodes.filter((p) => !p.level);
  const candidateTopPersonIds = abovePersonOfInterest.reduce((accum, p) => {
    accum.set(p.id, p);
    return accum;
  }, new Map());

  abovePersonOfInterest.forEach((p) => {
    p.level = 1;
    // if a candidate is on the receiving end of MANAGING, then they are not
    // a top person(s).
    relationshipIdsOf(p, relationship).forEach((id) =>
      candidateTopPersonIds.delete(id)
    );
  });

  // move the top people to the start of the array, and the people they directly manage to the next
  candidateTopPersonIds.forEach((person) => {
    switchPlacesTo(nodes, person, 0);
    switchPlacesOf(nodes, person, 1, relationship);
  });
}

/**
 * @param entites
 * @param level
 */
function setLevelForEntities(entites, level) {
  entites.forEach((entity) => {
    entity.level = level;
  });
}

/**
 * This function updates the nodes under the relationshipType of the
 * rootEntity to the specific level recursively.
 * e.g. If rootEntity is a Person, update all the managing nodes' level
 * to 2. Then for every person under the rootEntity, update their managing
 * node's level to 3 and so on.
 *
 *
 * @param nodes
 * @param rootEntity
 * @param level
 * @returns {*}
 */
function applyTeamLevel(nodes, rootEntity, level) {
  if (rootEntity.level) {
    // we've already traversed this entity (there must be a loop)
    return level;
  }
  let maxLevel = level;
  let candidateMax = level;
  rootEntity.level = level;

  // update the team members's level. (it can be team or person)
  const managingIds = relationshipIdsOf(rootEntity, "MANAGING");
  managingIds.forEach((id) => {
    const member = nodes.find((e) => e.id === id);
    if (member.type === "Team") {
      candidateMax = applyTeamLevel(nodes, member, level + 1);
    } else {
      member.level = level + 1;
      candidateMax = level + 1;
    }

    if (candidateMax > maxLevel) {
      maxLevel = candidateMax;
    }
  });

  return maxLevel;
}

/**
 * This function sets the levels of the nodes with person as the target.
 *
 *    ppppppp        level = 1
 *       |
 *       p(target)   level = 2
 *      /  \
 *     p   p         level = 3
 *    / \   \
 *   p   p   p       maxlevel
 *   .....
 *
 *   team            maxlevel++
 *   company         maxlevel++
 *   division        maxlevel++
 *   job             maxlevel++
 *   location        maxlevel++
 *
 *
 * It first builds the level of the managing relationship and get the maxlevel
 * Then put the other layers of node to the next level.
 *
 * Finally it calls prepareTopPeople to arrange the nodes at the top level.
 *
 * @param orginalNodes
 * @param targetNodeId
 * @returns {*}
 */
export function preparePersonData(orginalNodes, targetNodeId) {
  const nodes = cloneDeep(orginalNodes);
  const target = nodes.find((p) => p.id === targetNodeId);
  let maxLevel = applyPersonLevel(nodes, target, 2);

  // now we need to set the levels
  setLevelForEntities(
    nodes.filter((e) => e.type === "Team"),
    ++maxLevel
  );

  setLevelForEntities(
    nodes.filter((e) => e.type === "Company"),
    ++maxLevel
  );
  setLevelForEntities(
    nodes.filter((e) => e.type === "Division"),
    ++maxLevel
  );
  setLevelForEntities(
    nodes.filter((e) => e.type === "Job"),
    ++maxLevel
  );
  setLevelForEntities(
    nodes.filter((e) => e.type === "Location"),
    ++maxLevel
  );

  prepareTopPeople(nodes, "MANAGING");

  return nodes;
}

/*
 * This function sets the levels of the nodes with team as the target
 *                      ttttt           --level1
 *                       |
 *                   t(target team)     --level2
 *                   /       \
 *                  p         t         --level3
 *                 /         /  \
 *           (c,l,d,e,m)    p   t       --level4
 *                         /
 *                   (c,l,d,e,m)        --level5
 *
 *
 *
 * @param originalNodes
 * @param targetNodeId
 * @returns {*}
 */
export function prepareTeamData(originalNodes, targetNodeId) {
  // node of interest is level 2, sub-ords increase from there
  const nodes = cloneDeep(originalNodes);
  const target = nodes.find((p) => p.id === targetNodeId);

  let maxLevel = applyTeamLevel(nodes, target, 2);

  setLevelForEntities(
    nodes.filter((e) => e.type === "Company"),
    ++maxLevel
  );
  setLevelForEntities(
    nodes.filter((e) => e.type === "Division"),
    ++maxLevel
  );
  setLevelForEntities(
    nodes.filter((e) => e.type === "Job"),
    ++maxLevel
  );
  setLevelForEntities(
    nodes.filter((e) => e.type === "Location"),
    ++maxLevel
  );

  prepareTopPeople(nodes, "MANAGING");
  return nodes;
}
