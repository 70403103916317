import React, { useCallback, useState, useMemo } from "react";
import { map, filter } from "lodash";
import PropTypes from "prop-types";

import { Box } from "orcs-design-system";
import BulkTagsEditorContainer from "src/components/TagsEditor/BulkTagsEditorContainer";
import useTagsVisibleInFilter from "src/app/Restricted/useTagsVisibleInFilter";

const TagsSelector = ({ selectedPeople, reloadTags, onError }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const tagsVisibleIn = useTagsVisibleInFilter();

  const onTagSelect = useCallback(
    (tag) => {
      setSelectedTags([...selectedTags, tag]);
      reloadTags();
    },
    [reloadTags, selectedTags]
  );

  const onTagRemove = useCallback(
    (tag) => {
      const tags = filter(selectedTags, (t) => t.id !== tag.id);
      setSelectedTags(tags);
      reloadTags();
    },
    [reloadTags, selectedTags]
  );

  const entities = useMemo(() => {
    return map(selectedPeople, (person) => {
      return {
        id: person?.id,
        name: person?.name,
      };
    });
  }, [selectedPeople]);

  return entities && entities.length > 0 ? (
    <BulkTagsEditorContainer
      tagsVisibleIn={tagsVisibleIn}
      entityType="person"
      entities={entities}
      onTagSelect={onTagSelect}
      onTagRemove={onTagRemove}
      existingTags={selectedTags}
      onError={onError}
    />
  ) : (
    <Box>No individuals selected</Box>
  );
};

TagsSelector.propTypes = {
  selectedPeople: PropTypes.array,
  reloadTags: PropTypes.func,
  onError: PropTypes.func,
};

export default TagsSelector;
