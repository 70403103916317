import { filter, get, find } from "lodash";

export const attributeTypes = {
  DESCRIPTION: "description",
  LINK: "link",
};

export const getAllAttributesByType = (attributes, type) =>
  filter(attributes, { attributeType: type });

export const getAllAttributesBySubtype = (attributes, subtype) =>
  filter(attributes, { subtype });

export const getDescriptionAttribute = (attributes) => {
  const descriptions = getAllAttributesByType(
    attributes,
    attributeTypes.DESCRIPTION
  );
  // there should only ever be 1 description. If there is more than one just take the first
  // one rather than erroring
  if (descriptions && descriptions.length > 0) {
    return descriptions[0];
  }
  return null;
};

export const getExternalIdAttribute = (attributes) => {
  return find(attributes, { attributeType: "id", subtype: "import" });
};

export const getGroupExternalId = (team) => {
  const teamAttributes = get(team, "attributes");
  const externalIdAttribute = getExternalIdAttribute(teamAttributes);
  const externalId = externalIdAttribute
    ? get(externalIdAttribute, "value")
    : null;
  return externalId;
};
