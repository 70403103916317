import { useMutation } from "@apollo/client";
import {
  Modal,
  Flex,
  FlexItem,
  Box,
  H2,
  H5,
  Button,
  Loading,
  Notification,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";

import TeamSearch from "src/components/TeamSearch";
import GroupTypeBadge from "src/components/Badge/GroupTypeBadge";
import { moveGroupRealTime } from "src/queries/mutations/group.graphql";
import HierarchyOptions from "./sub-components/HierarchyOptions";
import HierarchySelector from "./sub-components/HierarchySelector";

const MoveGroupEnhancedModal = ({
  isVisible,
  onHideModal,
  rootGroup,
  groupTypes,
}) => {
  const [moveGroupMutation] = useMutation(moveGroupRealTime);
  const [moveToTeam, setMoveToTeam] = useState(null);
  const [hierarchyGroupTypeIds, setHierarchyGroupTypeIds] = useState(null);
  const [mappings, setMappings] = useState(null);
  const [options, setOptions] = useState(null);
  const [isExecuting, setIsExecuting] = useState(false);
  const [error, setError] = useState(false);

  const resetState = useCallback(async () => {
    setMoveToTeam(null);
    setHierarchyGroupTypeIds(null);
    setMappings(null);
    setOptions(null);
    setIsExecuting(false);
    setError(null);
  }, [
    setMoveToTeam,
    setHierarchyGroupTypeIds,
    setMappings,
    setOptions,
    setIsExecuting,
    setError,
  ]);

  const onSelectMoveToTeam = useCallback(
    async (groupWrapper) => {
      setMoveToTeam(groupWrapper?.group);
    },
    [setMoveToTeam]
  );

  const onMoveGroup = useCallback(async () => {
    if (isExecuting) {
      return;
    }
    setIsExecuting(true);
    trackEvent(EVENT_TRACKING.TEAM_MOVED, {
      advanced_move: true,
    });
    let moveGroupResponse = null;
    try {
      moveGroupResponse = await moveGroupMutation({
        variables: {
          input: {
            parentGroupId: moveToTeam.id,
            targetGroupId: rootGroup.id,
            groupTypeMap: mappings,
            overridesDirectParent: options?.overridesDirectParent ?? false,
          },
        },
      });
      const validationErrorMessage =
        moveGroupResponse?.data?.result?.validationError;
      if (validationErrorMessage) {
        setError(new Error(validationErrorMessage));
      } else {
        onHideModal();
      }
    } catch (moveGroupError) {
      setError(moveGroupError);
    }
    setIsExecuting(false);
  }, [
    rootGroup,
    mappings,
    options,
    moveToTeam,
    setIsExecuting,
    isExecuting,
    onHideModal,
    moveGroupMutation,
  ]);

  const onCancel = useCallback(async () => {
    resetState();
    onHideModal();
  }, [onHideModal, resetState]);

  const onHierarchyChange = useCallback(
    (uniqueTypes) => {
      setHierarchyGroupTypeIds(uniqueTypes);
    },
    [setHierarchyGroupTypeIds]
  );

  const onOptionsChange = useCallback(
    (typeMappings, optionToggles) => {
      setMappings(typeMappings);
      setOptions(optionToggles);
    },
    [setMappings, setOptions]
  );

  const canMove = !isExecuting && moveToTeam;
  let buttonText = "Move";
  if (isExecuting) {
    buttonText = "Moving";
  }

  return (
    <Modal
      visible={isVisible}
      onClose={onCancel}
      width="80vw"
      height="70vh"
      maxHeight="90vh"
      maxWidth="90vw"
      headerContent={
        <>
          <H5 weight="bold" color="greyDark">
            MOVING
          </H5>
          <Flex alignItems="center">
            <H2 mr="r">{rootGroup.name}</H2>
            <GroupTypeBadge group={rootGroup} groupTypes={groupTypes} />
          </Flex>
        </>
      }
      data-testid="cp-move-group-enhanced-modal"
      footerContent={
        <Flex alignItems="center" justifyContent="flex-end" width="100%">
          <Button
            p="s"
            mr="s"
            onClick={onCancel}
            variant="ghost"
            data-testid="cp-move-cancelled-button"
            disabled={isExecuting}
          >
            Cancel
          </Button>
          <Button
            p="s"
            onClick={onMoveGroup}
            variant={canMove ? "success" : "disabled"}
            disabled={!canMove || isExecuting}
            data-testid="cp-move-submit-button"
          >
            {isExecuting && <Loading mr="s" />}
            {buttonText}
          </Button>
          {error && (
            <Notification
              floating={true}
              closable={false}
              centered={true}
              colour="danger"
            >
              {error.message}
            </Notification>
          )}
        </Flex>
      }
    >
      <Flex justifyContent="space-evenly" mt="r">
        <FlexItem flex="10 5 0px">
          <H5 weight="bold" m="s">
            Select where you wish to move your team
          </H5>
          <Box borderRadius={2} boxBorder="default" p="r" m="s" height="100%">
            <Flex height="100%" flexDirection="column">
              <FlexItem>
                <TeamSearch
                  onTeamSelected={onSelectMoveToTeam}
                  showButton={false}
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  divider={<></>}
                  placeholder="Search and select a new parent for your team"
                  kindFilters={["team"]}
                  groupTypes={groupTypes}
                />
              </FlexItem>
              <FlexItem>
                <HierarchySelector
                  rootGroupId={rootGroup?.id}
                  onChange={onHierarchyChange}
                  groupTypes={groupTypes}
                />
              </FlexItem>
            </Flex>
          </Box>
        </FlexItem>
        <FlexItem flex="7 3 0px">
          <H5 weight="bold" m="s">
            Moving options
          </H5>
          <Box borderRadius={2} boxBorder="default" p="r" m="s" height="100%">
            <HierarchyOptions
              hierarchyGroupTypeIds={hierarchyGroupTypeIds}
              onChange={onOptionsChange}
              groupTypes={groupTypes}
            />
          </Box>
        </FlexItem>
      </Flex>
    </Modal>
  );
};
MoveGroupEnhancedModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func.isRequired,
  rootGroup: PropTypes.object.isRequired,
  groupTypes: PropTypes.object.isRequired,
};

export default React.memo(MoveGroupEnhancedModal);
