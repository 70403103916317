import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Flex,
  RadioButton,
  Spacer,
  Button,
  DatePicker,
  P,
} from "orcs-design-system";
import { DEFAULT_DATE_FORMAT, READABLE_DATE_FORMAT } from "src/consts/global";

import { OptionWrapper } from "./InputBadge.styled";
import { getOutsideRange, getNextStartDate, DATE_OPTIONS } from "./utils";

export const StartDateOptions = ({
  startDate,
  endDate,
  onConfirm,
  onCancel,
  disabled,
  isAdmin,
  isPastEditEnabled,
}) => {
  const today = moment().startOf("day");
  const isFutureMembership = startDate.isAfter(today);
  const isCurrentMembership = !endDate || endDate.isSameOrAfter(today);
  const getInitDate = () => {
    if (isFutureMembership) {
      return startDate;
    }

    if (isCurrentMembership) {
      return today;
    }

    return startDate;
  };

  const [option, setOption] = useState(
    isCurrentMembership ? DATE_OPTIONS.TODAY : DATE_OPTIONS.START_DAY
  );
  const [focused, setFocused] = useState(false);
  const [targetDate, setTargetDate] = useState(getInitDate);
  const isMissingDate = option === DATE_OPTIONS.CHOSEN_DAY && !targetDate;

  const handleOptionChange = ({ target }) => {
    const { value } = target;

    setOption(value);
  };

  const handleDateChange = (selectedDate) => {
    setTargetDate(selectedDate);
  };

  const setDatePickerFocused = (input) => {
    setFocused(input.focused);
  };

  const handleSave = () => {
    const nextStartDate = getNextStartDate({
      option,
      today,
      targetDate,
      startDate,
    });

    onConfirm(nextStartDate);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <OptionWrapper
      bg="white"
      borderRadius={2}
      boxBorder="default"
      p="r"
      shadow="default"
      width="372px"
    >
      <Spacer mt="r">
        <P>Change FTE and select a start date</P>
        {isCurrentMembership && (
          <RadioButton
            name="radio-start-day"
            label="Apply from today"
            value={DATE_OPTIONS.TODAY}
            checked={option === DATE_OPTIONS.TODAY}
            disabled={disabled}
            onChange={handleOptionChange}
          />
        )}
        {isAdmin && !startDate.isSame(today, "day") && (
          <RadioButton
            name="radio-start-day"
            label={`Backdate to allocation start date, ${startDate.format(
              READABLE_DATE_FORMAT
            )}`}
            value={DATE_OPTIONS.START_DAY}
            checked={option === DATE_OPTIONS.START_DAY}
            disabled={disabled}
            onChange={handleOptionChange}
          />
        )}
        <RadioButton
          name="radio-start-day"
          label="Apply from custom date"
          value={DATE_OPTIONS.CHOSEN_DAY}
          checked={option === DATE_OPTIONS.CHOSEN_DAY}
          disabled={disabled}
          onChange={handleOptionChange}
        />
        {option === DATE_OPTIONS.CHOSEN_DAY && (
          <DatePicker
            single
            openDirection="up"
            id="fte-start-date"
            date={targetDate}
            focused={focused}
            initialVisibleMonth={getInitDate}
            onFocusChange={setDatePickerFocused}
            onDateChange={handleDateChange}
            numberOfMonths={1}
            displayFormat={DEFAULT_DATE_FORMAT}
            isOutsideRange={getOutsideRange({
              startDate,
              endDate,
              today,
              isAdmin,
              isPastEditEnabled,
            })}
          />
        )}
        <Flex justifyContent="flex-end">
          <Button
            variant="success"
            onClick={handleSave}
            disabled={disabled || isMissingDate}
          >
            Save
          </Button>
          <Button variant="ghost" onClick={handleCancel} ml="s">
            Cancel
          </Button>
        </Flex>
      </Spacer>
    </OptionWrapper>
  );
};

StartDateOptions.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isPastEditEnabled: PropTypes.bool,
};
