import { useCallback, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { searchTeams as searchTeamsQuery } from "src/allocation/team.graphql";
import { getAllRootSupplyGroupTypes } from "../utils";

const useSearchTree = ({ groupTypesLookup }) => {
  const client = useApolloClient();
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [searchInProgress, setSearchInProgress] = useState(false);

  const searchGroupTypes = useMemo(() => {
    const {
      rootTypes = [],
      supplyRootTypes = [],
      supplySourceTypes = [],
    } = getAllRootSupplyGroupTypes(groupTypesLookup);
    return [...rootTypes, ...supplyRootTypes, ...supplySourceTypes];
  }, [groupTypesLookup]);

  const fetchData = useCallback(
    async (searchTerm) => {
      setSearchInProgress(true);
      const { data } = await client.query({
        query: searchTeamsQuery,
        variables: { search: searchTerm, groupTypes: searchGroupTypes },
      });
      setSearchInProgress(false);
      return data?.filteredGroups?.groups || [];
    },
    [client, searchGroupTypes]
  );

  const searchTree = useCallback(
    async (searchTerm) => {
      const data =
        searchTerm && searchTerm.length > 2 ? await fetchData(searchTerm) : [];
      setFilteredGroups(data);
    },
    [fetchData]
  );

  return { searchTree, filteredGroups, searchInProgress };
};

export default useSearchTree;
