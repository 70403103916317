import { get, map, join } from "lodash";
import { Divider, Spacer, Modal, Box, H3, Flex } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

import PersonAvatarAndDetails from "src/components/Person/PersonItem/sub-components/PersonAvatarAndDetails";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PersonPropType from "src/custom-prop-types/person";
import WorkspacePropType from "src/custom-prop-types/workspace";
import { getSupportedGroups } from "src/util/group";
import { isWindows } from "src/util/os";
import {
  getName,
  getEmailsFromPersonAttributes,
  getPersonEmailAttribute,
} from "src/util/person";

import { scenario } from "./ReportTeamChange.config";
import {
  getPersonAllocationUrl,
  notifyIncorrectFte,
  notifyIncorrectTeam,
  notifyMissingTeam,
} from "./ReportTeamChange.util";
import EmailDetails from "./sub-components/EmailDetails";
import MissingTeam from "./sub-components/MissingTeam";
import NotifyList from "./sub-components/NotifyList";
import NotInTeam from "./sub-components/NotInTeam";
import ScenarioPrompt from "./sub-components/ScenarioPrompt";

const ReportTeamChange = ({
  currentScenario = scenario.incorrectTeam,
  groupTypes,
  person,
  isVisible,
  onToggleVisibility,
  workspace,
  featureFlags,
}) => {
  const tagConfig = get(workspace, "config.tagConfig", {});
  const appVanityURL = get(workspace, "config.tenantConfig.appVanityURL");
  const managingPerson = get(person, "managedBy");
  const managingPersonLabel = get(managingPerson, "firstName");
  const supportedGroups = getSupportedGroups(
    get(person, "memberOf"),
    groupTypes
  );
  const [teams, setTeams] = useState(supportedGroups);
  const [newTeams, setNewTeams] = useState([]);
  const [teamCase, setTeamCase] = useState(currentScenario);
  const [additionalContacts, setAdditionalContacts] = useState([]);

  const sender = getName(person);

  const personAllocationsUrl = useMemo(
    () => getPersonAllocationUrl(person.aggregateId, appVanityURL),
    [appVanityURL, person.aggregateId]
  );

  const managerEmail = useMemo(() => {
    const managerEmailAttr = getPersonEmailAttribute(managingPerson);
    return get(managerEmailAttr, "value", "");
  }, [managingPerson]);

  const emailDetails = useMemo(() => {
    if (teamCase === scenario.incorrectTeam) {
      return notifyIncorrectTeam(
        managingPersonLabel,
        personAllocationsUrl,
        sender,
        managerEmail,
        teams,
        additionalContacts
      );
    }
    if (teamCase === scenario.missingTeam) {
      return notifyMissingTeam(
        managingPersonLabel,
        personAllocationsUrl,
        sender,
        managerEmail,
        newTeams,
        additionalContacts
      );
    }
    if (teamCase === scenario.incorrectFte) {
      return notifyIncorrectFte(
        managingPersonLabel,
        personAllocationsUrl,
        sender,
        managerEmail,
        additionalContacts
      );
    }
    return {};
  }, [
    additionalContacts,
    managerEmail,
    managingPersonLabel,
    newTeams,
    personAllocationsUrl,
    sender,
    teamCase,
    teams,
  ]);

  const separatorForOS = isWindows() ? ";" : ",";
  const ccEmails = join(emailDetails.additionalContacts, separatorForOS);

  const onCancel = () => onToggleVisibility(false)();
  const onChangeTeamCase = (event) => {
    setTeamCase(get(event, "target.value"));
  };
  const onSelectTeam = (group) => () => {
    setTeams(
      map(teams, (team) => {
        if (group.id !== team.id) return team;
        return {
          ...team,
          isRemove: !team.isRemove,
        };
      })
    );
  };
  const onUpdateNewTeams = (value) => {
    setNewTeams(value);
  };
  const onUpdateNotifyList = (selectedValues) => {
    const emails = getEmailsFromPersonAttributes(selectedValues);
    setAdditionalContacts(emails);
  };
  const modalHeader = (
    <Flex alignItems="center">
      <H3>Suggested change for:</H3>
      <Box ml="r">
        <PersonAvatarAndDetails
          tagConfig={tagConfig}
          person={person}
          showBadges={false}
        />
      </Box>
    </Flex>
  );

  return (
    <Modal
      visible={isVisible}
      onClose={onCancel}
      width={["90vw", "80vw", "70vw"]}
      maxWidth="90vw"
      height="90vh"
      maxHeight="90vh"
      headerContent={modalHeader}
    >
      <Box data-testid="cp-reportteamchange-container" p="xs" pt="r">
        <Spacer mb="r">
          <ScenarioPrompt
            teamCase={teamCase}
            onChangeTeamCase={onChangeTeamCase}
          />
          {teamCase === scenario.incorrectTeam && (
            <>
              <Divider my="r" />
              <NotInTeam
                teams={teams}
                onSelectTeam={onSelectTeam}
                sender={sender}
              />
            </>
          )}
          {teamCase === scenario.missingTeam && (
            <>
              <Divider my="r" />
              <MissingTeam
                onUpdateNewTeams={onUpdateNewTeams}
                sender={sender}
                workspace={workspace}
                featureFlags={featureFlags}
              />
            </>
          )}
          <Divider my="r" />
          <NotifyList
            managingPerson={managingPerson}
            groupTypes={groupTypes}
            onUpdateNotifyList={onUpdateNotifyList}
            workspace={workspace}
            featureFlags={featureFlags}
          />

          <Divider my="r" />
          <EmailDetails {...emailDetails} ccEmails={ccEmails} />
        </Spacer>
      </Box>
    </Modal>
  );
};

ReportTeamChange.propTypes = {
  currentScenario: PropTypes.string,
  isVisible: PropTypes.bool,
  groupTypes: GroupTypesPropType,
  onToggleVisibility: PropTypes.func,
  person: PersonPropType,
  workspace: WorkspacePropType,
  featureFlags: PropTypes.object,
};

export default React.memo(ReportTeamChange);
