import React from "react";
import PropTypes from "prop-types";
import { Text } from "orcs-design-system";
import styled from "styled-components";
import { toReadableNumber } from "src/allocation/util/budgetAndCost";
import useCurrencySymbol from "./hooks/useCurrencySymbol";

const BudgetText = styled(Text)`
  margin-left: 4px;
  position: relative;
  white-space: nowrap;
  z-index: 1;
  text-align: center;
  display: block;
`;

const TotalCostCell = ({ lineItem, totalCost }) => {
  const currencySymbol = useCurrencySymbol();
  const formattedTotalCost = toReadableNumber(
    lineItem?.totalCost || totalCost,
    currencySymbol
  );

  return <BudgetText textAlign="center">{formattedTotalCost}</BudgetText>;
};

TotalCostCell.propTypes = {
  lineItem: PropTypes.object,
  totalCost: PropTypes.number,
};

export default TotalCostCell;
