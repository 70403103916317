import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

const TreeIndentWrapper = styled.div`
  margin: ${(props) =>
    props.indentLevel ? `4px 0 4px 20px !important` : "4px 0 !important"};
  border-left: solid 1px #e6eaea;
  border-bottom: solid 1px #e6eaea;
  border-radius: 0 0 0 ${themeGet("radii.2")};
`;

export default TreeIndentWrapper;
