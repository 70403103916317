import themeGet from "@styled-system/theme-get";
import { useState, useRef, useCallback, useEffect } from "react";

import useWindowResizeListener from "src/hooks/useWindowResizeListener";
import { getBreakPointAsNumber } from "src/util/format";
import useSidebarListener from "src/hooks/useSidebarListener";

const SmallSizeScreen = getBreakPointAsNumber(themeGet("breakpoints.2"), 1000);

const useShortList = (fullListMode, testListLength = 0, wrapperRef) => {
  const [listLength, setListLength] = useState(testListLength);
  const [sidebarStatus, setSidebarStatus] = useState({ isClosed: true });

  useEffect(() => {
    // This useEffect will run once and used to set the initial value of sidebarStatus
    // In some pages, we don't have useShortList in it (/explore page, /search-result)
    // So, need to get the sidebar status when component is mounting
    const sideBarOpened = document.querySelector(".sidebar-opened");
    setSidebarStatus({
      isClosed: !sideBarOpened,
    });
  }, []);

  const ref = useRef(null);
  const containerRef = wrapperRef || ref;

  const resizeHandler = useCallback(
    (windowWidth) => {
      const isMobileSize = windowWidth <= SmallSizeScreen;
      const appContainer = document.querySelector("#appContent");

      const appWidth =
        !isMobileSize && !sidebarStatus?.isClosed && appContainer
          ? appContainer.offsetWidth - 48
          : windowWidth;

      if (appWidth > SmallSizeScreen) {
        setListLength(0);
      } else if (appWidth >= 0) {
        const container = containerRef.current;
        if (!container) {
          return;
        }
        const containerWidth = container.offsetWidth - 52;
        if (containerWidth >= 0) {
          setListLength(Math.floor(containerWidth / 49));
        }
      }
    },
    [containerRef, sidebarStatus]
  );

  const sidebarStatusChangeHandler = useCallback((params) => {
    setSidebarStatus(params);
  }, []);

  useWindowResizeListener(resizeHandler, fullListMode, null, containerRef);
  useSidebarListener(sidebarStatusChangeHandler);

  return [listLength, containerRef];
};

export default useShortList;
