import React, { useMemo } from "react";
import { TAGS } from "src/consts/comments";
import CommentContextProvider from "src/comments/context";

import { useUserInteraction, useAllocationProject } from "./context";

const commentsSaveTags = [
  TAGS.ALLOCATION.COMMENT,
  TAGS.ALLOCATION.INDIVIDUAL_ALLOCATION,
  TAGS.ALLOCATION.SUPPLY,
];

const createCommentsCountTags = (allocationProjectId) => ({
  filters: [
    [TAGS.USER_COMMENT],
    [TAGS.ALLOCATION.COMMENT],
    [TAGS.ALLOCATION_PROJECT_ID(allocationProjectId)],
    [TAGS.ALLOCATION.INDIVIDUAL_ALLOCATION, TAGS.ALLOCATION.PLANNING],
  ],
});

const commentsQueryTags = {
  filters: [
    [TAGS.ALLOCATION.COMMENT],
    [
      TAGS.USER_COMMENT,
      TAGS.ALLOCATION.ACTION.REQUEST_SUBMITTED,
      TAGS.ALLOCATION.ACTION.APPROVAL_SUBMITTED,
      TAGS.ALLOCATION.INDIVIDUAL_ALLOCATION,
    ],
  ],
};

const withCommentContextProvider = (BaseComponent) => (props) => {
  const allocationProject = useAllocationProject();
  const { selectedSupplyGroupId } = useUserInteraction();

  const commentsCountTags = useMemo(
    () => createCommentsCountTags(allocationProject.id),
    [allocationProject]
  );

  return useMemo(
    () => {
      return (
        <CommentContextProvider
          showHistoricAllocationProjects
          showActiveAllocationProjects
          groupId={selectedSupplyGroupId}
          saveTags={commentsSaveTags}
          countTags={commentsCountTags}
          queryTags={commentsQueryTags}
        >
          <BaseComponent {...props} />
        </CommentContextProvider>
      );
    },
    [allocationProject, selectedSupplyGroupId] // eslint-disable-line
  );
};

export default withCommentContextProvider;
