import React from "react";
import PropTypes from "prop-types";
import { Popover, Text } from "orcs-design-system";

import { numberToLocaleString } from "src/util/toLocaleString";
import { FORECAST_DEFAULT_DECIMAL } from "src/allocation/consts";

const TotalFteSum = ({ fteSum }) => {
  return (
    <Popover direction="top" width="172px" text="Scheduled FTE for this period">
      <Text color="warningDarker" mr="r">
        {numberToLocaleString(fteSum || 0, FORECAST_DEFAULT_DECIMAL)}
      </Text>
    </Popover>
  );
};

TotalFteSum.propTypes = {
  fteSum: PropTypes.number,
};

export default TotalFteSum;
