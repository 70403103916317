/* eslint-disable import/prefer-default-export */

export const MembersListFilterOption = {
  GROUP_NAME: "Group name",
  JOB_TITLE: "Job title",
  PERSON_NAME: "Person name",
  SPECIALIST: "Specialist role",
};

export const getOptions = (value, supplyLabel) => [
  {
    id: MembersListFilterOption.PERSON_NAME,
    label: value,
    type: MembersListFilterOption.PERSON_NAME,
    value,
  },
  {
    id: MembersListFilterOption.JOB_TITLE,
    label: value,
    type: MembersListFilterOption.JOB_TITLE,
    value,
  },
  {
    id: MembersListFilterOption.SPECIALIST,
    label: value,
    type: MembersListFilterOption.SPECIALIST,
    value,
  },
  {
    id: MembersListFilterOption.GROUP_NAME,
    label: value,
    type: supplyLabel,
    value,
  },
];
