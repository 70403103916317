import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { map, isNil } from "lodash";

import GroupPropType from "src/custom-prop-types/group";
import { getHierarchy as getHierarchyQuery } from "src/allocation/team.graphql";
import { HIERARCHY_SEPARATOR, NOOP } from "src/allocation/consts";
import GroupHierarchyBreadcrumb from ".";

const GroupHierarchyQueryBreadcrumb = (props) => {
  const { group, showTeamLinkWithIcon } = props;
  const { parentIds, hierarchy } = group;

  const [loadHierarchy, { data, called }] = useLazyQuery(getHierarchyQuery, {
    variables: {
      groupId: group.id,
      parentIds,
    },
  });

  const onIconMouseOver = useCallback(() => {
    if (called) {
      return;
    }

    loadHierarchy();
  }, [called, loadHierarchy]);

  const placeholder = useMemo(() => {
    return map(parentIds, () => "...").join(HIERARCHY_SEPARATOR);
  }, [parentIds]);

  const groupWithHierarchy = {
    ...group,
    hierarchy: (data && data.result.hierarchy) || hierarchy || placeholder,
  };

  const handler = isNil(hierarchy) && !called ? onIconMouseOver : NOOP;

  return (
    <GroupHierarchyBreadcrumb
      {...props}
      group={groupWithHierarchy}
      onTooltipIconMouseOver={handler}
      showTeamLinkWithIcon={showTeamLinkWithIcon}
    />
  );
};

GroupHierarchyQueryBreadcrumb.propTypes = {
  group: GroupPropType.isRequired,
  className: PropTypes.string,
  showBreadcrumb: PropTypes.bool,
  showGroupTypeBadge: PropTypes.bool,
  showGroupTypeBadgeShowToolTip: PropTypes.bool,
  groupTypeBadgeToolTipPosition: PropTypes.string,
  showFullHierarchyInBreadcrumb: PropTypes.bool,
  titleOnly: PropTypes.bool,
  extra: PropTypes.element,
  onLinkClick: PropTypes.func,
  showTeamLinkWithIcon: PropTypes.bool,
  showTeamName: PropTypes.bool,
};

export default GroupHierarchyQueryBreadcrumb;
