import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import React from "react";
import { Flex, StatusDot, Popover, Small, Spacer } from "orcs-design-system";
import { withRouter } from "react-router";
import { get, isEmpty } from "lodash";
import { getPrincipleStatus } from "src/pages/TeamDetailPage/team.graphql";
import { teamDetailsPath } from "../../util/routing";

const titleMarkup = (principle) => {
  return {
    __html: DOMPurify.sanitize(principle.title),
  };
};

const badgeDetailsMap = {
  TRUE: {
    colour: "success",
    text: "MET",
  },
  FALSE: {
    colour: "danger",
    text: "NOT MET",
  },
  INSUFFICIENT_DATA: {
    colour: "warning",
    text: "N/A",
  },
};

const principleStatusText = (principle) => {
  return (
    <>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={titleMarkup(principle)}
      />
      <Small fontWeight="bold">
        STATUS :{" "}
        {principle.signal
          ? principle.signal.text
          : badgeDetailsMap[principle.met].text}
      </Small>
    </>
  );
};

const PrincipleStatusQuery = ({ team, history, textDirection, marginTop }) => {
  const { data } = useQuery(getPrincipleStatus, {
    variables: { teamId: team?.id },
    skip: !team?.id,
  });

  const principles = get(data, "team.principles", []);

  const statusDots = [];
  if (principles && !isEmpty(team)) {
    principles.forEach((principle) => {
      if (principle.signal) {
        statusDots.push(
          <Popover
            key={team.id + principle.title}
            direction={textDirection || "bottomRight"}
            text={principleStatusText(principle)}
            textAlign="left"
            width="300px"
          >
            <StatusDot
              data-testId={`${principle.signal.color}-dot`}
              variant={principle.signal.color}
              onClick={() => history.push(teamDetailsPath(team, "principles"))}
            />
          </Popover>
        );
      } else {
        switch (principle.met) {
          case "TRUE":
            statusDots.push(
              <Popover
                key={team.id + principle.title}
                direction={textDirection || "bottomRight"}
                text={principleStatusText(principle)}
                textAlign="left"
                width="300px"
              >
                <StatusDot
                  data-testId="success-dot"
                  variant="success"
                  onClick={() =>
                    history.push(teamDetailsPath(team, "principles"))
                  }
                />
              </Popover>
            );
            break;

          case "FALSE":
            statusDots.push(
              <Popover
                key={team.id + principle.title}
                direction={textDirection || "bottomRight"}
                text={principleStatusText(principle)}
                textAlign="left"
                width="300px"
              >
                <StatusDot
                  data-testId="failure-dot"
                  variant="danger"
                  onClick={() =>
                    history.push(teamDetailsPath(team, "principles"))
                  }
                />
              </Popover>
            );
            break;

          default:
            statusDots.push(
              <Popover
                key={team.id + principle.title}
                direction={textDirection || "bottomRight"}
                text={principleStatusText(principle)}
                textAlign="left"
                width="300px"
              >
                <StatusDot
                  data-testId="warning-dot"
                  variant="warning"
                  onClick={() =>
                    history.push(teamDetailsPath(team, "principles"))
                  }
                />
              </Popover>
            );
        }
      }
    });
  }

  return statusDots.length > 0 ? (
    <Flex alignItems="center" mt={marginTop}>
      <Spacer mx="xxs">{statusDots}</Spacer>
    </Flex>
  ) : null;
};

// TODO: Fix team def, object
PrincipleStatusQuery.propTypes = {
  team: PropTypes.object,
  history: PropTypes.object,
  textDirection: PropTypes.string,
  marginTop: PropTypes.string,
};

export default withRouter(PrincipleStatusQuery);
