/* eslint-disable no-param-reassign */
import { forEach, isNil } from "lodash";
import { calculateTotalCost } from "../../../../../../../util/budgetAndCost";

import getDefaultValueFromPreviousCell from "../getDefaultValueFromPreviousCell";
import { newDefaultedCell } from "../newCell";

const getDefaultedValue = (cell, targetCell) => {
  if (!isNil(cell.currentFteSum)) {
    return cell.currentFteSum;
  }

  return getDefaultValueFromPreviousCell(
    targetCell,
    cell.column.allocationProject
  );
};

const setLineItemWithForecastDefaultCells = ({
  lineItem,
  targetGroupId,
  sourceGroupId,
  columns,
  rootGroup,
}) => {
  forEach(lineItem.cells, (cell, index) => {
    const previousCell = lineItem.cells[index - 1];

    if (!cell) {
      const defaultCell = newDefaultedCell(
        lineItem,
        previousCell,
        targetGroupId,
        sourceGroupId,
        columns[index],
        rootGroup
      );
      lineItem.cells[index] = defaultCell;
    } else {
      const targetCell = previousCell || cell;
      cell.defaultedValue = getDefaultedValue(cell, targetCell);

      if (!cell.isStatic && isNil(cell.value) && !isNil(cell.defaultedValue)) {
        cell.totalCost = calculateTotalCost({
          value: cell.defaultedValue,
          dailyRate: cell.dailyRate,
          workingDays: cell.workingDays,
        });
      }
    }
  });
};

export default setLineItemWithForecastDefaultCells;
