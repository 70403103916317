import React from "react";

import PropTypes from "prop-types";
import { Badge } from "orcs-design-system";
import { find } from "lodash";
import { OBJECTIVE_ATTRIBUTE_TYPE } from "src/consts/objectives";
import { mapStatusColorToBadgeVariant } from "src/util/objectives";

const ObjectiveStatusBadge = ({ objective }) => {
  const status = find(objective.attributes, {
    attributeType: OBJECTIVE_ATTRIBUTE_TYPE.STATUS,
  });

  if (!status) {
    return null;
  }

  const statusColor = find(objective.attributes, {
    attributeType: OBJECTIVE_ATTRIBUTE_TYPE.STATUS_COLOR,
  });

  const color = mapStatusColorToBadgeVariant({
    statusColor: statusColor?.value,
    datasource: objective?.datasource?.type,
  });

  return (
    <Badge mr="s" variant={color}>
      {status.value}
    </Badge>
  );
};

ObjectiveStatusBadge.propTypes = {
  objective: PropTypes.object,
};

export default React.memo(ObjectiveStatusBadge);
