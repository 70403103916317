import { Notification, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import icon from "src/config/icons";

import { copywriting } from "../ReportTeamChange.config";

import DetailLineItem from "./DetailLineItem";

const EmailDetails = ({ email: to, ccEmails: cc, subject, content: body }) => {
  return (
    <Spacer mb="r">
      <Notification colour="success" closable={false} icon={icon.exclamation}>
        {copywriting.NOTE_TO_COPY_DETAILS}
      </Notification>
      <DetailLineItem label="To" content={to} />
      <DetailLineItem label="CC" content={cc} />
      <DetailLineItem label="Subject" content={subject} />
      <DetailLineItem label="Body" content={body} />
    </Spacer>
  );
};

EmailDetails.propTypes = {
  email: PropTypes.string,
  ccEmails: PropTypes.string,
  subject: PropTypes.string,
  content: PropTypes.string,
};

export default EmailDetails;
