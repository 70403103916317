import React from "react";
import { map } from "lodash";

import { useForecastContext } from "../../context/ForecastContext";
import ConstraintGrouping from "./ConstraintGrouping";

const ConstraintGroupings = () => {
  const [
    {
      model: {
        dynamicModel: { groupings },
      },
    },
  ] = useForecastContext();

  return (
    <>
      {map(groupings, (grouping) => (
        <ConstraintGrouping key={grouping.id} grouping={grouping} />
      ))}
    </>
  );
};

export default ConstraintGroupings;
