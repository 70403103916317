import { Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { COMPANY_OBJECTIVE_TYPE, GROUP_OBJECTIVE_TYPE } from "./consts";
import ObjectiveItems from "./ObjectiveItems";

const WithActualParent = ({ objective, groupTypes }) => {
  return (
    <Box key={objective.id} mb="xs">
      {/* parent objective */}
      <ObjectiveItems
        objectiveType={COMPANY_OBJECTIVE_TYPE}
        objectives={[objective]}
        groupTypes={groupTypes}
        hasActualParents
        mb="xs"
      />

      {/* children objectives */}
      <ObjectiveItems
        objectiveType={GROUP_OBJECTIVE_TYPE}
        objectives={objective.objectives}
        groupTypes={groupTypes}
        ml="l"
        hasActualParents
      />
    </Box>
  );
};
WithActualParent.propTypes = {
  objective: PropTypes.object,
  groupTypes: PropTypes.object,
};

export default WithActualParent;
