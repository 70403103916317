import React, { useCallback } from "react";
import { get, isNil, isEqual } from "lodash";

import AddSkillsModal from "src/allocation/components/AddSkillsModal";
import { setMacroAllocationNote } from "src/allocation/allocation.graphql";
import {
  useForecastContext,
  ACTIONS,
  SAVE_STATUS,
  PAGE_MODES,
} from "../../context/ForecastContext";
import useSaveMutation from "../Shared/useSaveMutation";

const AddSkillsModalContainer = () => {
  const [
    {
      pageMode,
      lookupData: { groupLookup },
      model: {
        rootGroup: { isSource },
      },
      userInteraction: {
        addingSkills: { cell, isOpen, updateInputValue },
      },
    },
    dispatch,
  ] = useForecastContext();

  const requestorGroup = get(groupLookup, get(cell, "targetGroupId"), null);
  const supplyGroup = get(groupLookup, get(cell, "sourceGroupId"), null);
  const skills = get(cell, "skills", "");
  const value = get(cell, "value");
  const isPlannerPage = pageMode === PAGE_MODES.PLANNER;
  const prepopulatedValue = get(cell, "prepopulatedValue");
  const hasPrepopulatedValue = !isNil(prepopulatedValue);

  const handleHideModal = useCallback(() => {
    dispatch({
      type: ACTIONS.CLOSE_ADD_SKILLS_DIALOG,
    });
    dispatch({
      type: ACTIONS.MAIN_QUERY_REFRESH,
      backgroundRefresh: true,
    });
  }, [dispatch]);

  const onSkillsSaveStatusChange = useCallback(
    (status, data) => {
      if (!data) {
        return;
      }
      dispatch({
        type: ACTIONS.SKILLS_SAVE_STATUS_UPDATE,
        cellId: cell.id,
        status,
        macroAllocation: data.macroAllocation,
      });
    },
    [cell, dispatch]
  );

  const [updateSkillsValue] = useSaveMutation(
    skills,
    setMacroAllocationNote,
    onSkillsSaveStatusChange
  );

  const saveSkillsValue = useCallback(
    async (newSkills) => {
      const {
        id,
        macroAllocationId,
        allocationProjectId,
        targetGroupId,
        sourceGroupId,
        lineItem,
      } = cell;

      dispatch({
        type: ACTIONS.USER_SKILLS_INPUT_UPDATE,
        cellId: id,
        skills: newSkills,
      });

      await updateSkillsValue(newSkills, {
        id: macroAllocationId,
        allocationProjectId,
        sourceGroupId,
        targetGroupId,
        note: newSkills,
        type: lineItem.type,
      });
    },
    [cell, dispatch, updateSkillsValue]
  );

  const saveRequestFteValue = useCallback(
    async (newValue) => {
      const {
        id,
        macroAllocationId,
        allocationProjectId,
        targetGroupId,
        sourceGroupId,
      } = cell;

      // Update cell value first, then save
      dispatch({
        type: ACTIONS.REQUEST_INPUT_UPDATE,
        cellId: id,
        value: newValue,
      });

      const valuePropertyName = isPlannerPage ? "requested" : "forecast";

      if (!updateInputValue) {
        return;
      }

      await updateInputValue(
        newValue,
        {
          id: isPlannerPage ? macroAllocationId : id,
          allocationProjectId,
          sourceGroupId,
          targetGroupId,
          [valuePropertyName]: newValue,
        },
        !isPlannerPage // For planner page only, Disable the value check inside - check already done outside
      );
    },
    [cell, dispatch, isPlannerPage, updateInputValue]
  );

  const saveRequest = useCallback(
    async (newFteValue, newSkills) => {
      if (!isEqual(newSkills, skills)) {
        await saveSkillsValue(newSkills);
      }

      const shouldSaveFte =
        newFteValue !== value ||
        cell.valueSaveStatus === SAVE_STATUS.PENDING ||
        (isPlannerPage && hasPrepopulatedValue);

      if (shouldSaveFte) {
        await saveRequestFteValue(newFteValue);
      }
    },
    [
      skills,
      value,
      cell,
      isPlannerPage,
      hasPrepopulatedValue,
      saveSkillsValue,
      saveRequestFteValue,
    ]
  );

  if (!cell || (isPlannerPage && isSource)) {
    return null;
  }

  const defaultedValue = get(cell, "defaultedValue");

  return (
    <AddSkillsModal
      visible={isOpen}
      requestorGroup={requestorGroup}
      supplyGroup={supplyGroup}
      skills={skills}
      value={value}
      defaultedValue={defaultedValue}
      prepopulatedValue={prepopulatedValue}
      hasPrepopulatedValue={hasPrepopulatedValue}
      saveRequest={saveRequest}
      onHideModal={handleHideModal}
      isPlannerPage={isPlannerPage}
    />
  );
};

export default AddSkillsModalContainer;
