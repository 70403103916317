import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Sidebar from "src/sidebar/Sidebar";

import Loading from "src/components/LoadingPage";
import Cosmos from "../app/Cosmos";
import AppNavSimple from "../components/Nav/AppNavSimple";
import OutdatedBrowserMessage from "../components/OutdatedBrowserMessage";

const AppLayout = ({ emptyMode = false, children, themeSwitcher }) => {
  return (
    <>
      <OutdatedBrowserMessage />
      <Cosmos mod="BodyStyles" />
      <AppNavSimple emptyMode={emptyMode} themeSwitcher={themeSwitcher} />
      <main>
        <Sidebar themeSwitcher={themeSwitcher} />

        <Cosmos mod="AppContent" id="appContent">
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </Cosmos>
      </main>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  emptyMode: PropTypes.bool,
  themeSwitcher: PropTypes.func,
};

export default withRouter(AppLayout); // TODO: move to react hooks
