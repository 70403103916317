import { get, isEmpty } from "lodash";
import React, { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import { Popover, Button, Icon } from "orcs-design-system";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

import { submitGroupForecasts as submitGroupForecastsMutation } from "src/allocation/allocation.graphql";

import {
  useForecastModel,
  useMainQuery,
  useLookupData,
  useDispatch,
  ACTIONS,
  VIEW_MODES,
  useViewMode,
  useRootGroup,
  useGroupTypesLookup,
  useFinancialForecasting,
} from "../../context/ForecastContext";

const SubmitButton = styled(Button)`
  height: ${themeGet("appScale.inputHeightDefault")};
`;

const isMeetingBudgets = (groupings) => {
  if (isEmpty(groupings)) {
    return true;
  }

  for (let i = 0, j = groupings.length; i < j; i += 1) {
    const { budgetStatus, childGroupings } = groupings[i];
    if (budgetStatus) {
      return false;
    }

    if (!isMeetingBudgets(childGroupings)) {
      return false;
    }
  }

  return true;
};

const wrongViewModeMessage = (rootGroup, supplyLabel, demandLabel) => {
  return `Submitting disabled when viewing by ${
    rootGroup.isDemand ? demandLabel : supplyLabel
  }. Please switch to ${
    rootGroup.isDemand ? supplyLabel : demandLabel
  } view mode to submit requests.`;
};

const ForecastSubmitButton = () => {
  const rootGroup = useRootGroup();
  const groupTypes = useGroupTypesLookup();
  const {
    variables: { groupId, isRequestorGroup },
    loading,
  } = useMainQuery();
  const { financialYear, supplyLabel, demandLabel } = useLookupData();
  const {
    dynamicModel: { isPendingSubmit, groupings },
  } = useForecastModel();
  const shouldCheckBudgets = useFinancialForecasting();
  const viewMode = useViewMode();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submit] = useMutation(submitGroupForecastsMutation, {
    variables: {
      input: {
        groupId,
        financialYear,
      },
    },
    onCompleted: (data) => {
      setIsSubmitting(false);
      dispatch({
        type: ACTIONS.SUBMIT_FORECAST_COMPLETE,
        forecastSubmittedFor: financialYear,
        forecastSubmittedAt: data?.result?.forecastSubmittedAt,
      });
    },
    onError: (error) => {
      setIsSubmitting(false);
      dispatch({
        type: ACTIONS.SUBMIT_FORECAST_ERROR,
        error,
      });
    },
  });

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
    submit();
  }, [setIsSubmitting, submit]);

  const groupType = get(groupTypes, rootGroup.type, {});

  // Hide the submit button for supply side for now.
  if (
    rootGroup.isSource ||
    !rootGroup.enableForecastSubmitting ||
    !groupType.planning?.canSubmitForecast
  ) {
    return null;
  }

  const wrongViewMode =
    viewMode === VIEW_MODES.GROUPED_BY_SUPPLY && rootGroup.isSource;

  const hasUnmetBudgets = shouldCheckBudgets && !isMeetingBudgets(groupings);

  const disableButton =
    loading ||
    isSubmitting ||
    wrongViewMode ||
    hasUnmetBudgets ||
    !isPendingSubmit;

  const button = (
    <SubmitButton
      iconLeft
      disabled={disableButton}
      variant={disableButton ? "disabled" : "success"}
      data-testid="submit-button"
      onClick={handleSubmit}
      className="guided-tour-allocation-forecast-submit-button"
    >
      <Icon icon={["fas", "cloud-upload"]} />
      {isSubmitting
        ? "Submitting..."
        : `${isRequestorGroup ? "Submit" : "Approve"} Forecast`}
    </SubmitButton>
  );

  if (!loading && disableButton) {
    const popoverText = wrongViewMode
      ? wrongViewModeMessage(rootGroup, supplyLabel, demandLabel)
      : hasUnmetBudgets
      ? "Not meeting budgets"
      : isSubmitting
      ? "Submitting"
      : "Nothing to submit";

    return (
      <Popover
        direction="bottom"
        text={popoverText}
        textAlign="center"
        width="180px"
      >
        {button}
      </Popover>
    );
  }

  return button;
};

export default ForecastSubmitButton;
