import { find } from "lodash";

import { peopleSearch as peopleSearchQuery } from "src/allocation/members.graphql";
import {
  requestMacroAllocationAbsolute,
  getGroupMacroAllocations,
} from "src/allocation/allocation.graphql";
import { createMacroAllocationId } from "src/allocation/util/macroAllocation";

export const refetchQuery = (client, newTargetId, searchVariables) => {
  const variables = {
    ...searchVariables,
    targetGroupId: newTargetId,
  };

  const currentResult = client.readQuery({
    query: peopleSearchQuery,
    variables,
  });

  // If not queried before
  if (!currentResult) {
    return;
  }

  client.refetchQueries({
    include: [
      {
        query: peopleSearchQuery,
        variables,
      },
    ],
  });
};

export const updateGroupMacroAllocation = async ({
  client,
  allocationProjectId,
  newTargetId,
  sourceGroupId,
  allocation,
}) => {
  const macroAllocations = await client.query({
    query: getGroupMacroAllocations,
    variables: {
      allocationProjectIds: [allocationProjectId],
      groupId: newTargetId,
    },
    fetchPolicy: "network-only",
  });

  const targetMa = find(
    macroAllocations.data.results,
    (ma) => ma.sourceGroupId === sourceGroupId
  );

  const individualAllocationFte = allocation ? allocation.fte : 0;

  if (individualAllocationFte === 0) {
    return;
  }

  const requested = targetMa
    ? targetMa.requested + individualAllocationFte
    : individualAllocationFte;

  // Save macro allocation to back end
  await client.mutate({
    mutation: requestMacroAllocationAbsolute,
    variables: {
      id: createMacroAllocationId(
        allocationProjectId,
        sourceGroupId,
        newTargetId
      ),
      allocationProjectId,
      sourceGroupId,
      targetGroupId: newTargetId,
      requested,
    },
  });
};
