import { map, filter, get, isEmpty } from "lodash";
import { LINK_OBJECTIVE_DATASOURCE } from "src/consts/objectives";

const getObjectiveIds = ({ group, groupTypes }) => {
  const selectedTypes = get(
    groupTypes?.[group?.type],
    `planning.objectiveTypes`,
    []
  );

  const showOnlyLinkedObjectives = !!get(
    groupTypes?.[group?.type],
    `planning.showOnlyLinkedObjectives`
  );

  if (isEmpty(selectedTypes) && !showOnlyLinkedObjectives) {
    return [];
  }

  const linkedObjectiveIds = map(
    filter(group?.linkedStrategies, {
      datasource: LINK_OBJECTIVE_DATASOURCE.PLANNER,
    }),
    "id"
  );

  const objectiveIds = group?.objectiveIds || [];

  return showOnlyLinkedObjectives
    ? linkedObjectiveIds
    : [...linkedObjectiveIds, ...objectiveIds];
};

export { getObjectiveIds };
