import { useMutation } from "@apollo/client";
import { filter, map } from "lodash";
import { useCallback } from "react";

import {
  updateTagsOnEntities as updateTagsOnEntitiesQuery,
  addNewTag as addNewTagQuery,
} from "./queries/tags.graphql";
import { makeBulkComment } from "./TagsEditor.util";

const useBulkAddTag = ({
  entityType,
  entities,
  commentsMetadata,
  onTagSelect,
  onTagRemove,
}) => {
  const [updateTagsOnEntities] = useMutation(updateTagsOnEntitiesQuery);
  const [addNewTag] = useMutation(addNewTagQuery);

  const bulkAddTagToEntities = useCallback(
    async ({
      tagId,
      tagType,
      displayValue,
      attributes,
      isUserCreatedTagValue,
    }) => {
      let tagIdForEntity = tagId;
      if (isUserCreatedTagValue) {
        const response = await addNewTag({
          variables: {
            input: {
              type: tagType,
              displayValue,
              value: displayValue,
              attributes,
              isActive: true,
            },
          },
        });
        tagIdForEntity = response.data.tag.id;
      }

      const entityTagUpdates = map(
        filter(entities, (entity) => entity.id && entity.name),
        ({ id: entityId, name: entityName }) => {
          return {
            entityId,
            tagId: tagIdForEntity,
            operation: "ADDED",
            comments: makeBulkComment(
              tagId,
              tagType,
              displayValue,
              entityId,
              entityName,
              entityType,
              commentsMetadata
            ),
          };
        }
      );

      await updateTagsOnEntities({
        variables: {
          input: {
            list: entityTagUpdates,
          },
        },
        // needed to update tags on people that are visible on the planner page
        refetchQueries: ["getPeopleByIds"],
        awaitRefetchQueries: false,
        /*
          We used to have an update method here to update the cache for getTagsForEntityQuery after updating tags
          but the readQuery call requires the original query object to work and we don't have access to it here,
          thus we *always* failed to get the query from the cache and thus the cache was never updated, so I removed it.

          Excerpt from the docs here: https://www.apollographql.com/docs/react/caching/cache-interaction/#readquery
            > If the cache is missing data for any of the query's fields, readQuery returns null.
        */
      });
    },
    [addNewTag, updateTagsOnEntities, entities, entityType, commentsMetadata]
  );

  const addTag = useCallback(
    async (selectedTag, { id: tagType }) => {
      const {
        id: tagId,
        isUserCreatedTagValue,
        displayValue,
        attributes,
      } = selectedTag;

      onTagSelect(selectedTag);

      try {
        await bulkAddTagToEntities({
          tagId,
          tagType,
          displayValue,
          attributes,
          isUserCreatedTagValue,
        });
      } catch (error) {
        onTagRemove(selectedTag);
        throw error;
      }
    },
    [bulkAddTagToEntities, onTagRemove, onTagSelect]
  );

  return addTag;
};

export default useBulkAddTag;
