import { useEffect } from "react";
import {
  useForecastModel,
  ACTIONS,
  useDispatch,
  useMainQuery,
  useFinancialForecasting,
  useLookupData,
} from "./context/ForecastContext";

const useSetupInitialGroupingExpansions = () => {
  const { staticModel } = useForecastModel();
  const { loading } = useMainQuery();
  const showBudgets = useFinancialForecasting();
  const { initalExpansionLevel } = useLookupData();
  const dispatch = useDispatch();

  const groupings = staticModel?.groupings;

  useEffect(() => {
    if (!loading && groupings) {
      dispatch({
        type: ACTIONS.APPLY_GROUPINGS_TREE_EXPANSION_AFTER_LOAD,
        groupings,
        showBudgets,
        initalExpansionLevel,
      });
    }
  }, [dispatch, groupings, loading, showBudgets, initalExpansionLevel]);
};

export default useSetupInitialGroupingExpansions;
