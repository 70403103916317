/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */

import React from "react";
import { Icon, Label } from "reagraph";
import { a } from "@react-spring/three";
import { RoundedBox } from "@react-three/drei";
import { TFSphereWithBorder } from "./TFSphereWithBorder";

export const TFCustomNode = ({
  color,
  borderColor,
  id,
  size,
  opacity,
  node,
  active,
  animated,
  image,
  counter,
}) => {
  return (
    <>
      <TFSphereWithBorder
        id={id}
        size={size}
        opacity={opacity}
        animated={animated}
        color={node.fill}
        borderColor={borderColor}
        node={node}
        active={active}
      />
      <Icon
        id={id}
        image={image}
        size={size}
        opacity={opacity}
        animated={animated}
        color={color}
        node={node}
        active={active}
      />
      {counter && (
        <a.group position={[size, size, 10]}>
          <RoundedBox args={[counter.length * 5 + 5, 10, 0]} radius={3}>
            <meshPhongMaterial color="#2d3031" />
          </RoundedBox>
          <Label text={counter} opacity={opacity} color="#ffffff" />
        </a.group>
      )}
    </>
  );
};

TFCustomNode.defaultProps = {
  opacity: 1,
  active: false,
  selected: false,
};
