/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { isEmpty, map } from "lodash";

import useLazyLoad from "src/hooks/useLazyLoad";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import { DefaultTreeHeader } from "src/shared/TreeView/TreeView";
import GroupsSearch from "src/components/GroupsSearch";
import {
  OBJECTIVE_GROUP_KIND,
  LINK_OBJECTIVE_DATASOURCE,
} from "src/consts/objectives";

import { attributeFilterTypes } from "src/allocation/pages/ForecastPage/components/PlannerGroupings/Objectives/hooks/useObjectiveAttributeFilter";
import { canHaveChildren } from "src/shared/TreeView/TreeView.util";
import ObjectiveCheckbox from "./ObjectiveCheckbox";
import { getGroupTreeObjectivesQuery } from "./objectives.graphql";
import {
  createTreeNode,
  convertChildrenToArray,
  addGroupsToRoots,
  shouldIgnoreOnToggle,
} from "./ObjectiveSearchTree.utils";

const ObjectiveSearchTree = ({
  currentSelect,
  objectiveIds,
  handleObjectiveSelect,
  isUpdating,
  rootGroup,
  targetGroup,
  hideExternalUrl,
}) => {
  const groupTypes = useGroupTypes();
  const handleOnGroupSelected = () => {};

  const loadTeamsWithObjectives = useLazyLoad({
    query: getGroupTreeObjectivesQuery,
  });

  const valueGraphQueryOptions = useMemo(
    () => ({
      datasources: [LINK_OBJECTIVE_DATASOURCE.PLANNER],
      includeDirectParentObjectives: true,
      attributeFilter: {
        typeFilter: {
          filters: map(attributeFilterTypes, (type) => ({ type })),
          deriveTypesFromGroupType: true,
        },
      },
    }),
    []
  );

  const loadChildNodes = useCallback(
    async ({ groupIds, node }) => {
      const canThisGroupHaveChildren = canHaveChildren(
        node?.group?.type,
        groupTypes
      );
      if (!canThisGroupHaveChildren) {
        const objectives = node?.group?.objectives;
        if (!isEmpty(objectives)) {
          const selectedGroup = {
            ...node.group,
            childTeams: objectives,
          };
          return {
            groups: [selectedGroup],
          };
        }
      }
      const data = await loadTeamsWithObjectives({
        groupIds,
        valueGraphQueryOptions,
      });
      const groups = map(data.groups, (group) => {
        const { childTeams, objectives } = group;
        return {
          ...group,
          childTeams: [...childTeams, ...objectives],
        };
      });
      return { groups };
    },
    [groupTypes, loadTeamsWithObjectives, valueGraphQueryOptions]
  );

  const customizeTreeHeader =
    (props = {}) =>
    ({ node }) => {
      if (
        node?.group?.kind === OBJECTIVE_GROUP_KIND ||
        node?.kind === OBJECTIVE_GROUP_KIND
      ) {
        return (
          <ObjectiveCheckbox
            targetGroup={targetGroup}
            groupTypes={groupTypes}
            option={node?.group || node}
            currentSelect={currentSelect}
            objectiveIds={objectiveIds}
            isUpdating={isUpdating}
            handleObjectiveSelect={handleObjectiveSelect}
            hideExternalUrl={hideExternalUrl}
          />
        );
      }
      return <DefaultTreeHeader {...props} node={node} />;
    };

  return (
    <GroupsSearch
      placeholder="Click to search a team"
      actionLabel="Click to add"
      targetGroupTypes={groupTypes}
      groupTypes={groupTypes}
      onGroupSelected={handleOnGroupSelected}
      withIndependentTreeView
      customizeTreeHeader={customizeTreeHeader}
      loadChildNodes={loadChildNodes}
      createTreeNodeImpl={createTreeNode}
      convertChildrenToArrayImpl={convertChildrenToArray}
      addGroupsToRootsImpl={addGroupsToRoots}
      shouldIgnoreOnToggle={shouldIgnoreOnToggle}
      hierarchyIds={rootGroup?.group?.hierarchyIds || []}
      rootGroupVariables={{
        includeObjectives: true,
        valueGraphQueryOptions,
      }}
    />
  );
};

ObjectiveSearchTree.propTypes = {
  currentSelect: PropTypes.array,
  objectiveIds: PropTypes.array,
  handleObjectiveSelect: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
};

export default ObjectiveSearchTree;
