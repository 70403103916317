/* eslint-disable no-unused-vars */
const { repeat } = require("lodash");

let globalGridConfig = null;

const buildPlannerColumns = (
  withBudget,
  hideApproverColumn,
  showLineItemEditing = false
) => {
  const actionBarBudgetColumn = withBudget ? "200px " : "";
  const lineItemBudgetColumn = withBudget ? "175px " : "";

  const actionBar = showLineItemEditing
    ? `auto ${actionBarBudgetColumn}128px 264px 14px 75px`
    : `auto ${actionBarBudgetColumn}100px 199px 32px 75px`;

  const expandHeader = showLineItemEditing
    ? `auto ${withBudget ? "134px " : "200px "}${
        withBudget ? "153px " : "130px "
      }${withBudget ? "165px " : "0px "}25px 30px 30px 30px`
    : `auto ${lineItemBudgetColumn}108px 110px 72px 0px 0px 75px`;

  const approverLineItem = showLineItemEditing
    ? `auto ${lineItemBudgetColumn} 234px 131px 39px 0px 0px 30px 30px`
    : `auto ${lineItemBudgetColumn} 46px 151px 35px 24px 2px 30px 30px`;

  const requestorLineItem = showLineItemEditing
    ? `auto ${lineItemBudgetColumn} 59px 264px 0px 0px 30px 30px`
    : `auto ${lineItemBudgetColumn} 112px 164px 18px 0px 0px 30px 30px`;

  if (hideApproverColumn) {
    return {
      actionBar,
      expandHeader,
      requestorLineItem,
      approverLineItem,
      lineItemEditing: `auto ${lineItemBudgetColumn}118px 225px 36px 45px 34px 0px 30px`,
    };
  }

  return {
    actionBar: `auto ${actionBarBudgetColumn}100px 199px 199px 75px`,
    expandHeader: `auto ${lineItemBudgetColumn}108px 110px 72px 110px 72px 75px`,
    requestorLineItem: `auto ${lineItemBudgetColumn}112px 164px 18px 109px 72px 30px 30px`,
    approverLineItem: `auto ${lineItemBudgetColumn}121px 131px 39px 170px 18px 30px 30px`,
    editorLineItem: `auto ${lineItemBudgetColumn}118px 225px 140px 36px 45px 34px 0px 30px`,
  };
};

export const setGridConfig = (
  numberOfAllocationProjects,
  withBudget = false,
  hideApproverColumn = false,
  showLineItemEditing = false
) => {
  globalGridConfig = {
    constraint: {
      templateColumns: `auto ${repeat(
        "210px ",
        numberOfAllocationProjects
      )}30px`,
      minWidth: `${800 + 210 * numberOfAllocationProjects}px`,
      gap: "5px",
      headerSubGrid: {
        templateColumns: "59px 59px 18px",
        gap: "5px",
      },
      cellSubGrid: {
        templateColumns: "59px 120px 18px",
        gap: "5px",
      },
    },
    planner: {
      templateColumns: buildPlannerColumns(
        withBudget,
        hideApproverColumn,
        showLineItemEditing
      ),
      minWidth: `1300px`,
      gap: "5px 15px",
      lineItemGap: "5px 0px",
    },
    lineOfBusiness: {
      templateColumns: {
        expandHeader: "auto 35px 35px",
        objectivesHeader: "auto 35px 35px",
      },
      gap: "5px 15px",
    },
    forecast: {
      templateColumns: `auto ${withBudget ? "175px " : ""}${repeat(
        "160px ",
        numberOfAllocationProjects
      )}30px`,
      minWidth: `${
        900 + 153 * numberOfAllocationProjects + (withBudget ? 175 : 0)
      }px`,
      gap: "5px 28px",
    },
  };
};

const useGridConfig = () => {
  return globalGridConfig;
};

export const usePlannerLineItemGridConfig = (lineItem) => {
  const requestorCell = lineItem.cells[1];
  const gridConfig = useGridConfig();

  // TODO: Looks like we should be returning templateColumns here
  const templateColumns = requestorCell.isEditable
    ? gridConfig.planner.templateColumns.requestorLineItem
    : gridConfig.planner.templateColumns.approverLineItem;

  return [templateColumns, gridConfig.planner.gap];
};

export default useGridConfig;
