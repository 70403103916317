/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import themeGet from "@styled-system/theme-get";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { get, isUndefined, isArray } from "lodash";
import { Link } from "react-router-dom";
import { Treebeard, decorators } from "react-treebeard";
import { Icon, Flex, Loading, P } from "orcs-design-system";
import styled from "styled-components";
import GroupTypeBadge from "src/components/GroupTypeBadge";

import { getActiveTheme } from "src/services/localStorage";

import WithTeamDetailsPath from "../../../components/WorkspaceLinks/WithTeamDetailsPath";
import StaticTreeViewStyle from "./TreeView.json";

const isCompactTheme = getActiveTheme() === "compact";

const NewWindowIcon = styled(Icon)`
  bottom: 2px;
  position: relative;
  z-index: 2;
  opacity: 0.5;
  transition: opacity 300ms ease-in-out;
  &:hover {
    opacity: 1;
  }
`;

const TreeGroupExpandIconFlexWrapper = styled.button`
  border: none;
  background: none;
  appearance: none;
  z-index: 2;
  cursor: pointer;
  padding: ${themeGet("space.xs")};
`;

export const TreeHeaderTitle = styled.button`
  text-align: left;
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  appearance: none;
  font-family: inherit;
  z-index: 2;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  text-align: left;
  text-decoration: ${({ isHidden }) => (isHidden ? "line-through" : "none")};
  margin-left: ${({ hasChildren }) =>
    hasChildren ? "0px" : isCompactTheme ? "11px" : "14px"};
  line-height: 1.8rem;
  padding: 0 ${themeGet("space.s")};
`;

const TreeHeaderIcon = styled(Icon)`
  margin-right: 5px;
`;
export const TreeHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    background: transparent;
    height: 100%;
    width: calc(100% + 1000px);
    top: 0;
    left: -1000px;
    transition: background 200ms ease-in-out;
  }

  &:hover {
    &:before {
      background: rgba(156, 224, 248, 0.3);
    }
  }

  &:hover ${TreeHeaderIcon} {
    color: black;
  }
`;
const TreeText = styled(P)`
  word-break: break-word;
  z-index: 2;
`;
const TreeStatus = styled.div`
  display: inline-block;
  margin-left: 6px;
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 5px;
  }
`;

const Toggle = () => (
  <TreeGroupExpandIconFlexWrapper aria-label="Expand/collapse node">
    <Icon icon={["fal", "chevron-right"]} size="1x" color="grey" />
  </TreeGroupExpandIconFlexWrapper>
);

const LocalLink = ({ to }) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link title="Open details" to={to}>
    <NewWindowIcon
      ml="xs"
      color="black"
      icon={["fas", "external-link-alt"]}
      size="xs"
    />
  </Link>
);

const LocalLinkWithTeamDetailsPath = WithTeamDetailsPath(LocalLink);

const buildTeamLink = (hasChildren, treeNodeGroup, noLink) => {
  if (!hasChildren || !treeNodeGroup || noLink) {
    return null;
  }

  const teamId =
    treeNodeGroup.id || treeNodeGroup.groupId || get(treeNodeGroup, "group.id");

  return <LocalLinkWithTeamDetailsPath team={teamId} />;
};

const buildHeader =
  ({
    nodeStatus,
    onActivate,
    onToggle,
    refreshTree,
    cursor,
    noLink,
    withGroupTypeBadge,
    noTreeHeaderIcon,
    customizeTreeHeader,
    flipOnClickHeader,
  }) =>
  (treeNode) => {
    const { node } = treeNode;
    const hasChildren = Boolean(node.children);

    useEffect(() => {
      if (node.active) {
        cursor.current = node;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (customizeTreeHeader) {
      const result = customizeTreeHeader(treeNode, {
        onActivate,
        refreshTree,
      });
      if (!isUndefined(result)) {
        return result;
      }
    }

    const NodeStatus = nodeStatus || (() => null);
    const { group: treeNodeGroup } = node;
    const activeNodeProps = node.active
      ? {
          id: "active-node",
          "data-testid": "active-node-test",
        }
      : {};

    const onClickHeader = (e) => {
      e.stopPropagation();
      if (flipOnClickHeader && hasChildren) {
        onToggle(node, !node.toggled);
      } else {
        onActivate(node);
      }
    };

    return (
      <TreeHeader hasChildren={hasChildren} onClick={onClickHeader}>
        <TreeHeaderTitle
          data-testid={node.active ? "treeheader-node-bold" : "treeheader-node"}
          isActive={node.active}
          isHidden={get(node, "group.isHidden")}
          hasChildren={hasChildren}
        >
          <TreeText
            data-testid="treeheader-node-name"
            fontWeight={node.active ? "bold" : "normal"}
            color="black"
            fontSize="1"
            {...activeNodeProps}
          >
            {node.name}
          </TreeText>
          {buildTeamLink(hasChildren, treeNodeGroup, noLink)}

          <TreeStatus>
            <NodeStatus node={node} />
          </TreeStatus>
        </TreeHeaderTitle>
        <Flex alignItems="center">
          {withGroupTypeBadge && treeNodeGroup && (
            <GroupTypeBadge group={treeNodeGroup} ml="s" />
          )}
          {!hasChildren && !noTreeHeaderIcon && (
            <TreeHeaderIcon
              color={node.active ? "black" : "grey"}
              icon={
                node.active ? ["fas", "angle-right"] : ["far", "angle-right"]
              }
              size="1x"
              ml="s"
            />
          )}
        </Flex>
      </TreeHeader>
    );
  };

const updateTreeObject = (tree) => {
  if (isArray(tree)) {
    return [...tree];
  }

  return { ...tree };
};

const TreeView = ({
  data,
  "data-testid": testId,
  className,
  onNodeSelected,
  nodeStatus,
  noLink = false,
  withGroupTypeBadge = false,
  noTreeHeaderIcon = false,
  customizeTreeHeader,
  flipOnClickHeader = false,
  treeViewStyle = StaticTreeViewStyle,
}) => {
  const [treeData, setTreeData] = useState(data);
  const cursor = useRef();

  useEffect(() => {
    setTreeData(data);
  }, [data]);

  useEffect(() => {
    TreeView.scrollActiveNodeIntoView();
  }, []);

  const refreshTree = () => {
    setTreeData((td) => updateTreeObject(td));
  };

  // onToggle is used for expanding nodes
  const onToggle = (node, toggled) => {
    // If node doesn't want to be mutated
    if (node.noActivation) {
      return;
    }

    // most of this code has been pinched from the https://github.com/storybookjs/react-treebeard README.md
    // I've added comments on what I think it's doing
    if (cursor.current) {
      // reset the old node to inactive
      cursor.current.active = false;
    }

    // set the new node to active
    node.active = true;
    if (node.children) {
      // toggle it if it has children
      node.toggled = toggled;
    }

    // track the cursor/active node
    cursor.current = node;

    // refresh the render
    refreshTree();

    // notify our callbacks something new has been selected
    if (onNodeSelected) {
      onNodeSelected(node, refreshTree);
    }
  };

  // onActivate is used on leaf node clicks
  const onActivate = (node) => {
    if (cursor.current) {
      // reset the old node to inactive
      cursor.current.active = false;
    }

    if (node.noActivation) {
      return;
    }

    // set the new node to active
    node.active = true;

    // track the cursor/active node
    cursor.current = node;

    // refresh the render
    refreshTree();

    if (onNodeSelected) {
      onNodeSelected(node, refreshTree);
    }
  };

  const Header = buildHeader({
    nodeStatus,
    onActivate,
    onToggle,
    refreshTree,
    cursor,
    noLink,
    withGroupTypeBadge,
    noTreeHeaderIcon,
    customizeTreeHeader,
    flipOnClickHeader,
  });

  return (
    <div data-testid={testId}>
      <Treebeard
        animations={false}
        className={className}
        data={treeData}
        style={treeViewStyle}
        onToggle={onToggle}
        decorators={{ ...decorators, Header, Toggle, Loading }}
      />
    </div>
  );
};

TreeView.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  "data-testid": PropTypes.string,
  className: PropTypes.string,
  onNodeSelected: PropTypes.func,
  nodeStatus: PropTypes.func,
  customizeTreeHeader: PropTypes.func,
  flipOnClickHeader: PropTypes.bool,
};

TreeView.scrollActiveNodeIntoView = (
  block = "end",
  selector = "#active-node"
) => {
  const elem = document.querySelector(selector);
  if (elem) {
    elem.scrollIntoView({ behavior: "smooth", block });
  }
};

export default TreeView;
