import React from "react";
import PropTypes from "prop-types";
import { useSlate } from "slate-react";
import { StyledRteButton } from "src/components/RichTextEditor/RichTextEditor.styled";
import Icon from "./RteIcon";

const Button = (
  { className, format, icon, isActive, formatText, disabled, ...props },
  ref
) => {
  const editor = useSlate();

  const onMouseDown = (evt) => {
    evt.preventDefault();
    if (!disabled) {
      formatText(editor, format);
    }
  };

  return (
    <StyledRteButton
      isActive={isActive(editor, format)}
      onMouseDown={onMouseDown}
      disabled={disabled}
      {...props}
      ref={ref}
    >
      <Icon icon={icon} />
    </StyledRteButton>
  );
};

Button.displayName = `RteButton`;
const RteButton = React.forwardRef(Button);

Button.propTypes = {
  className: PropTypes.string,
  format: PropTypes.string,
  isActive: PropTypes.func,
  icon: PropTypes.array,
  formatText: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RteButton;
