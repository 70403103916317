import React, { useState, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { get, trim, findIndex, toLower, map, isEmpty } from "lodash";
import {
  Box,
  Spacer,
  TextInput,
  Modal,
  Select,
  Flex,
  Button,
  Icon,
  H4,
} from "orcs-design-system";
import Fuse from "fuse.js";
import ErrorNotification from "src/components/ErrorNotification";

import {
  ILLEGAL_TEAM_NAME_CHARACTERS,
  MAX_TEAM_NAME_LENGTH,
  MIN_TEAM_NAME_LENGTH,
} from "src/consts/global";
import TeamDropdown from "../TeamDropdown";
import ProgressNotification from "./ProgressNotification";

const FuseSearchOptions = {
  includeMatches: true,
  shouldSort: false,
  tokenize: true,
  matchAllTokens: true,
  findAllMatches: true,
  threshold: 0.0,
  location: 0,
  distance: 0,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ["label"],
};

/**
 * Validate the team name to make sure:
 * 1. Team name length is at least 3 characters (excluding leading and trailing spaces).
 * 2. The new team name should not be duplicated as the sibling teams (case insensitive).
 *
 * @param {*} team
 * @param {*} parentGroup
 * @param {*} setErrorMessage
 */
const validateTeamName = (team, parentGroup, setErrorMessage) => {
  if (team.name.length < MIN_TEAM_NAME_LENGTH) {
    setErrorMessage(
      "Minimum team name length is 3 characters excluding leading and trailing spaces."
    );
    return false;
  }

  const siblingTeams = get(parentGroup, "childTeams", []);
  if (
    findIndex(
      siblingTeams,
      (sibling) => toLower(sibling.name) === toLower(team.name)
    ) >= 0
  ) {
    setErrorMessage(
      `Duplicated team name ${team.name} found in the same parent group.`
    );
    return false;
  }

  setErrorMessage(null);
  return true;
};

/*
 * Validate team: name, parent and type
 *
 */
const validateTeam = (team, parentGroup, setErrorMessage) => {
  if (!validateTeamName(team, parentGroup, setErrorMessage)) {
    return false;
  }

  if (!team.parentId) {
    setErrorMessage("Please select a parent group");
    return false;
  }

  if (!team.type) {
    setErrorMessage("Please select a type");
    return false;
  }

  setErrorMessage(null);
  return true;
};

const resetDefaults = () => ({
  parentId: "",
  type: "",
  rawName: "",
  name: "",
});

const getTypeName = (option, groupTypes) => {
  if (!option) {
    return null;
  }

  const type = get(groupTypes, option.value);

  if (!type) {
    return null;
  }

  return type.shortName || type.name || "Team";
};

const createTeamTypesOptions = (groupTypes, selectedParentOption) => {
  if (!selectedParentOption) {
    return [];
  }

  const type = get(selectedParentOption, "type");
  const childTypes = get(groupTypes[type], "childTypes", []);

  return map(childTypes, (t) => ({
    value: t,
    label: get(groupTypes[t], "name", t),
  }));
};

const AddNewTeamModal = ({
  parentGroup,
  visible,
  onCreateTeam,
  onUpdateSelectedGroup,
  onHideModal,
  loading,
  parentGroupOptions,
  groupTypes,
  defaultModalTitle,
}) => {
  const [newTeam, updateNewTeam] = useState(resetDefaults());
  const [selectedParentOption, setSelectedParentGroup] = useState(null);
  const [selectedTeamType, setSelectedTeamType] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [invalidSubmit, setInvalidSubmit] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const typeName = useMemo(
    () => getTypeName(selectedTeamType, groupTypes),
    [selectedTeamType, groupTypes]
  );

  const teamTypeOptions = useMemo(
    () => createTeamTypesOptions(groupTypes, selectedParentOption),
    [groupTypes, selectedParentOption]
  );

  useEffect(() => {
    if (!newTeam.name) {
      return;
    }

    validateTeamName(newTeam, parentGroup, setErrorMessage);
  }, [newTeam, parentGroup, setErrorMessage]);

  const clearAll = useCallback(() => {
    updateNewTeam(resetDefaults());
    setSelectedParentGroup(null);
    setSelectedTeamType(null);
    setErrorMessage(null);
    setInvalidSubmit(false);
    setInProgress(false);
  }, []);

  const onChangeNewTeamName = useCallback((e) => {
    const proposedName = e.target.value.replace(
      ILLEGAL_TEAM_NAME_CHARACTERS,
      ""
    );

    setInvalidSubmit(false);
    updateNewTeam((t) => ({
      ...t,
      name: trim(proposedName),
      rawName: proposedName,
    }));
  }, []);

  const onCreateNewTeam = useCallback(async () => {
    if (!validateTeam(newTeam, parentGroup, setErrorMessage)) {
      setInvalidSubmit(true);
      return false;
    }
    try {
      setInProgress(true);
      const createdTeam = await onCreateTeam(newTeam);
      if (createdTeam) {
        const team = get(createdTeam, "data.team");
        onUpdateSelectedGroup(team);
      }
      onHideModal();
      clearAll();
      return true;
    } catch (err) {
      const errorMsg = err.message.includes("document already exists")
        ? `${typeName} name already exists`
        : `Error creating ${typeName}: ${err.message}`;
      setErrorMessage(errorMsg);
      setInvalidSubmit(true);
      setInProgress(false);
      return false;
    }
  }, [
    newTeam,
    clearAll,
    onCreateTeam,
    onUpdateSelectedGroup,
    onHideModal,
    parentGroup,
    typeName,
  ]);

  const onCancelModal = useCallback(() => {
    onHideModal();
    clearAll();
  }, [clearAll, onHideModal]);

  const onSelectParentGroup = useCallback((option) => {
    setSelectedParentGroup(option);
    const parentId = get(option, "value.id");
    updateNewTeam((t) => ({
      ...t,
      parentId,
    }));
    setSelectedTeamType(null);
    setInvalidSubmit(false);
  }, []);

  const onSelectTeamType = useCallback((option) => {
    setSelectedTeamType(option);
    updateNewTeam((t) => ({
      ...t,
      type: option.value,
    }));
    setInvalidSubmit(false);
  }, []);

  useEffect(() => {
    if (visible && !selectedParentOption && !isEmpty(parentGroupOptions)) {
      onSelectParentGroup(parentGroupOptions[0]);
    }
  }, [visible, parentGroupOptions, onSelectParentGroup, selectedParentOption]);

  const fuse = useMemo(
    () => new Fuse(parentGroupOptions, FuseSearchOptions),
    [parentGroupOptions]
  );

  const searchTeam = useCallback(
    (term, cb) => {
      const result = fuse.search(term);
      const targetOptions = map(result, (r) => r.item);
      cb(targetOptions);
    },
    [fuse]
  );

  const typeNameLabel = typeName || "Team";
  const shouldUseDefaultTitle = !typeName && defaultModalTitle;
  const headerContent = shouldUseDefaultTitle
    ? defaultModalTitle
    : `Create new ${typeNameLabel}`;

  const modalFooter = (
    <Flex>
      <Spacer mr="s">
        <Button onClick={onCreateNewTeam} variant="success" iconLeft>
          <Icon icon={["fas", "plus"]} />
          {shouldUseDefaultTitle
            ? defaultModalTitle
            : `Create ${typeNameLabel}`}
        </Button>
        <Button variant="ghost" onClick={onCancelModal} iconLeft>
          <Icon icon={["fas", "times"]} />
          Cancel
        </Button>
      </Spacer>
    </Flex>
  );

  return (
    <Modal
      visible={visible}
      width="800px"
      height="530px"
      maxHeight="90vh"
      maxWidth="90vw"
      onConfirm={onCreateNewTeam}
      onClose={onCancelModal}
      footerContent={modalFooter}
      headerContent={<H4>{headerContent}</H4>}
    >
      <Box p="s" pt="r">
        <Spacer mb="r">
          <TeamDropdown
            id="add-new-team-select-team"
            label="Select parent team"
            placeholder="Select parent team"
            isSearchable
            isLoading={loading}
            options={parentGroupOptions}
            loadOptions={searchTeam}
            value={selectedParentOption}
            onSelectTeam={onSelectParentGroup}
          />
          <Select
            inputId="add-new-team-select-team-type"
            label="Select team type"
            options={teamTypeOptions}
            value={selectedTeamType}
            onChange={onSelectTeamType}
          />
          <TextInput
            fullWidth
            id="add-new-team-name-input"
            type="text"
            label={`${typeNameLabel} name`}
            placeholder={`E.g. ${typeNameLabel} ABC`}
            maxLength={MAX_TEAM_NAME_LENGTH}
            invalid={!!errorMessage}
            value={newTeam.rawName}
            onChange={onChangeNewTeamName}
          />
          {invalidSubmit && (
            <ErrorNotification message={errorMessage} report={false} />
          )}
          {inProgress && <ProgressNotification typeName={typeName} />}
        </Spacer>
      </Box>
    </Modal>
  );
};

AddNewTeamModal.propTypes = {
  parentGroup: PropTypes.object,
  visible: PropTypes.bool,
  onHideModal: PropTypes.func,
  onCreateTeam: PropTypes.func,
  onUpdateSelectedGroup: PropTypes.func,
  loading: PropTypes.bool,
  parentGroupOptions: PropTypes.array,
  groupTypes: PropTypes.object,
  defaultModalTitle: PropTypes.string,
};

export default AddNewTeamModal;
