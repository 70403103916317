import React from "react";
import { Box, Button, Flex, Icon } from "orcs-design-system";
import { PropTypes } from "prop-types";
import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";
import ConfiguredActionMenu from "src/components/ConfiguredActionMenu";
import { teamDetailsPath } from "src/util/routing";

const TeamsCell = ({ cell }) => {
  const cellValue = cell.getValue();

  if (!cellValue) return null;

  if (cellValue?.length > 1) {
    const [firstGroup, ...rest] = cellValue;

    return (
      <Box>
        <Flex
          display="inline-flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Box mr="s" mb="s">
            <GroupHierarchyBreadcrumb group={firstGroup} />
          </Box>
          <ConfiguredActionMenu
            ml="s"
            actions={rest.map((group) => ({
              label: group.name,
              href: teamDetailsPath(group),
            }))}
            customTriggerComponent={
              <Button small variant="ghost" iconRight>
                + {rest.length} more
                <Icon icon={["fas", "chevron-down"]} />
              </Button>
            }
          />
        </Flex>
      </Box>
    );
  }

  return (
    <Box>
      <Flex
        display="inline-flex"
        justifyContent="center"
        flexDirection="column"
      >
        {cellValue.map((group, i) => {
          return (
            <Box
              mr="s"
              mb={i >= cellValue.length - 1 ? "" : "l"}
              key={group.id}
            >
              <GroupHierarchyBreadcrumb group={group} />
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};

TeamsCell.propTypes = {
  cell: PropTypes.object,
  row: PropTypes.shape({
    original: PropTypes.shape({
      loadMore: PropTypes.bool,
      kind: PropTypes.string,
      dataSource: PropTypes.shape({
        type: PropTypes.string,
      }),
    }),
  }),
};

export default TeamsCell;
