import { map } from "lodash";
import PropTypes, { arrayOf } from "prop-types";
import React, { useMemo } from "react";

import PersonListItem from "src/components/Person/PersonItem/PersonListItem";
import AvatarPlaceholder from "src/components/Placeholder/AvatarPlaceholder";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PersonPropType from "src/custom-prop-types/person";
import WorkspacePropType from "src/custom-prop-types/workspace";
import { getAllBadgeGroups, getAltBadgeGroups } from "src/util/group";

import { sortMembers } from "./FullMembersList.util";

const FullMembersList = ({
  disableSort = false,
  selectedSort,
  loading,
  team,
  groupTypes,
  members,
  newMemberId,
  selectedPersonId,
  isAddingToGroup,
  withAllGroups = false,
  cardView,
  successNotificationMessage,
  isDisplayActionsButton = true,
  user,
  userPerson,
  featureFlags,
  workspace,
  allocationProject,
  renderFteBadge,
  handleSelectPerson,
  selectedMembers,
  showPersonCheckbox,
  bulkEditStatus,
}) => {
  const sortedMembers = useMemo(
    () =>
      sortMembers(
        members,
        selectedSort,
        team,
        groupTypes,
        newMemberId,
        disableSort
      ),
    [members, selectedSort, team, groupTypes, newMemberId, disableSort]
  );

  const peopleLength = sortedMembers.length - 1;

  return loading ? (
    <AvatarPlaceholder rows={6} />
  ) : (
    <>
      {isAddingToGroup && <AvatarPlaceholder rows={1} />}
      {map(sortedMembers, (person, index) => {
        const { aggregateId } = person;
        const added = aggregateId === newMemberId;
        const highlighted = !added && aggregateId === selectedPersonId;

        let badgeGroups = null;
        if (withAllGroups) {
          badgeGroups = getAllBadgeGroups({ person, groupTypes });
        } else if (team) {
          badgeGroups = getAltBadgeGroups({
            group: team,
            person,
            groupTypes,
          });
        }

        return (
          <PersonListItem
            bulkEditStatus={bulkEditStatus}
            showPersonCheckbox={showPersonCheckbox}
            handleSelectPerson={handleSelectPerson}
            selectedMembers={selectedMembers}
            person={person}
            key={person.aggregateId}
            badgeGroups={badgeGroups}
            groupTypes={groupTypes}
            group={team}
            highlighted={highlighted}
            added={added}
            isLastItem={index === peopleLength}
            isDisplayActionsButton={isDisplayActionsButton && !person.disabled}
            cardView={cardView}
            successNotificationMessage={successNotificationMessage}
            user={user}
            userPerson={userPerson}
            featureFlags={featureFlags}
            workspace={workspace}
            allocationProject={allocationProject}
            renderFteBadge={renderFteBadge}
          />
        );
      })}
    </>
  );
};

FullMembersList.propTypes = {
  loading: PropTypes.bool,
  team: GroupPropType,
  groupTypes: GroupTypesPropType,
  selectedPersonId: PropTypes.string,
  members: arrayOf(PersonPropType),
  newMemberId: arrayOf(PersonPropType),
  isAddingToGroup: PropTypes.bool,
  selectedSort: PropTypes.object,
  cardView: PropTypes.bool,
  withAllGroups: PropTypes.bool,
  isDisplayActionsButton: PropTypes.bool,
  successNotificationMessage: PropTypes.string,
  user: PropTypes.object,
  userPerson: PersonPropType,
  workspace: WorkspacePropType,
  featureFlags: PropTypes.object,
  allocationProject: PropTypes.object,
  renderFteBadge: PropTypes.func,
  handleSelectPerson: PropTypes.func,
  selectedMembers: PropTypes.object,
  showPersonCheckbox: PropTypes.bool,
  bulkEditStatus: PropTypes.object,
  disableSort: PropTypes.bool,
};

export default FullMembersList;
