import { find, orderBy, partition, sortBy } from "lodash";
import {
  OBJECTIVE_ATTRIBUTE_TYPE,
  OBJECTIVE_SOURCE,
} from "src/consts/objectives";

const getSortedJiraObjectives = (jiraObjectives) => {
  const sortedJiraObjectives = orderBy(
    jiraObjectives,
    (objective) => {
      const issue = find(objective.attributes, {
        attributeType: OBJECTIVE_ATTRIBUTE_TYPE.STATUS,
      });
      return issue?.value;
    },
    "desc"
  );
  return sortedJiraObjectives;
};

const sortObjectives = (objectives) => {
  const [jiraObjectives, others] = partition(objectives, (objective) => {
    return objective.datasource?.type === OBJECTIVE_SOURCE.Jira;
  });

  const sortedJiraObjectives = getSortedJiraObjectives(jiraObjectives);

  return [...sortedJiraObjectives, ...sortBy(others, "name")];
};

export default sortObjectives;
