import React from "react";
import PropTypes from "prop-types";
import { isEmpty, map } from "lodash";
import styled from "styled-components";

import LinksListItem from "./LinksListItem";

const LinksListWrapper = styled.ul`
  padding: 0 0 0 20px;
  margin: 0;
`;

const LinksList = ({ links }) => {
  if (isEmpty(links)) {
    return null;
  }

  return (
    <LinksListWrapper>
      {map(links, (link) => (
        <LinksListItem
          key={`cp-teamdetails-heading-link-${link.label}`}
          link={link}
        />
      ))}
    </LinksListWrapper>
  );
};

LinksList.propTypes = {
  links: PropTypes.array,
};

export default LinksList;
