import { forEach, values, sortBy } from "lodash";
import { systemtheme } from "orcs-design-system";
import { getStartDate, getEndDate, toDateRanges } from "src/util/memberships";
import { numberToLocaleString } from "src/util/toLocaleString";

const itemStyles = {
  style: `
    background-color: ${systemtheme.colors.primary};
    color: ${systemtheme.colors.white};
    border-radius: ${systemtheme.radii[2]};
    border: solid 1px ${systemtheme.colors.white};
    font-size: ${systemtheme.fontSizes[0]};
    font-weight: ${systemtheme.fontWeights[2]};
    padding: ${systemtheme.space.xs} ${systemtheme.space.s};
  `,
};

const statusItemStyles = (bg, color) => {
  return {
    style: `
      background-color: ${bg};
      color: ${color};
      border-radius: ${systemtheme.radii[2]};
      border: solid 1px ${systemtheme.colors.white};
      font-size: ${systemtheme.fontSizes[0]};
      font-weight: ${systemtheme.fontWeights[2]};
      padding: ${systemtheme.space.xs} ${systemtheme.space.s};
    `,
  };
};

const getItemVariants = (totalFte, personFte) => {
  if (totalFte < personFte) {
    return {
      bg: systemtheme.colors.warningLighter,
      color: systemtheme.colors.warningDarkest,
      suffix: "- Under Allocated",
    };
  }

  if (totalFte > personFte) {
    return {
      bg: systemtheme.colors.dangerLightest,
      color: systemtheme.colors.dangerDarkest,
      suffix: "- Over Allocated",
    };
  }

  return {
    bg: systemtheme.colors.successLightest,
    color: systemtheme.colors.successDarkest,
    suffix: "- Fully Allocated",
  };
};

const addItemStatus = ({ groups, allDates, items, personFte, fteConfig }) => {
  const groupStatusId = "status";

  groups.unshift({
    id: groupStatusId,
    content: "<small>Total Allocated FTE:</small>",
  });

  const dateRanges = toDateRanges(allDates);

  forEach(dateRanges, (dateRange) => {
    let totalFte = 0;

    forEach(items, (item) => {
      const { start, end, fte } = item;

      if (dateRange.start >= start && dateRange.end <= end) {
        totalFte += fte;
      }
    });

    const { bg, color, suffix } = getItemVariants(totalFte, personFte);
    const content = `${numberToLocaleString(totalFte, {
      decimalplaces: fteConfig.decimal,
    })} FTE`;

    items.push({
      ...dateRange,
      content,
      group: groupStatusId,
      title: `${content} ${suffix}`,
      align: "auto",
      ...statusItemStyles(bg, color),
    });
  });
};

export const convertMemberships = (memberships, person, fteConfig) => {
  const items = [];
  const allGroups = {};
  const allDates = [];

  forEach(memberships, (membership) => {
    const { groupId, group, startDate, endDate, attributes } = membership;
    const { fte = 1 } = attributes || {};

    allGroups[groupId] = {
      id: groupId,
      content: group.name,
      group,
    };

    const start = getStartDate(startDate);
    const end = getEndDate(endDate);

    items.push({
      start,
      end,
      content: `${fte} FTE`,
      group: groupId,
      fte,
      align: "auto",
      ...itemStyles,
    });

    allDates.push(start, end);
  });

  const groups = sortBy(values(allGroups), "content");

  addItemStatus({ groups, allDates, items, personFte: person.fte, fteConfig });

  return { items, groups };
};

export const getGroupContainerId = (group) => {
  return `group-container-${group.id}`;
};
