/* eslint-disable consistent-return, no-param-reassign, no-continue, no-restricted-syntax, no-shadow */
import { Position } from "reactflow";
import { chain, find, get, isEmpty, reduce, trim, uniq, uniqBy } from "lodash";

import { getBgImage, attributeTypes } from "src/util/group";
import {
  BUSINESS_OUTCOME_FIELD,
  OBJECTIVE_ATTRIBUTE_TYPE,
  OBJECTIVE_SOURCE,
  OBJECTIVE_THEME,
} from "src/consts/objectives";
import { DEFAULT_OUTCOME_LABEL } from "src/allocation/consts";
import { JIRA_STATUS_COLOR_TO_BADGE_VARIANT } from "src/consts/jira";

export const NODE_TYPES = {
  TEAM: "team",
  COMPANY: "company",
  DOMAIN: "domain",
  GROUP: "group",
  NO_OBJECTIVE: "no_objective",
  OBJECTIVE: "objective",
  LOADING: "loading",
};

/**
 * Get the count of teams and members associated with the given objective.
 *
 * @param {Array} nodes - The array of nodes.
 * @param {Object} objective - The objective object.
 * @returns {Object} An object containing the count of teams and members.
 */
const getTeamAndMemberCount = (nodes, objective) => {
  const relatedTeams = uniqBy(
    [
      ...nodes
        .filter((node) => node.parentIds.includes(objective.id))
        .map((node) => node.teams)
        .flat(),
      ...objective.teams,
    ],
    "id"
  );

  return {
    teamCount: relatedTeams.length,
    memberCount: reduce(
      relatedTeams,
      (count, { memberCount }) => (count += memberCount),
      0
    ),
  };
};

/**
 * Convert the given objectives and their related nodes into a flat array.
 *
 * @param {Array} objectives - The array of objectives.
 * @returns {Function} A function that takes nodes and an objective and returns a flat array.
 */
export const toFlatArray = (objectives, selectedTeamId) => {
  let allTeamIds = [];
  let unassignedSortedIds = [];
  const allLoadedObjectiveIds = objectives.map(({ id }) => id);

  const objectivesMap = objectives.reduce((objectivesById, objective) => {
    const {
      id,
      name,
      teams,
      objectiveType,
      parentIds,
      objectiveTypeDisplayName,
      dataSource,
      childObjectiveIds = [],
    } = objective || {};

    const objTeamIds = [];

    teams.forEach((team) => {
      const { id: teamId, name, attributes, memberCount } = team;

      objTeamIds.push(teamId);

      const imageUrl = getBgImage(attributes, attributeTypes.IMAGE);

      // TODO: cleanup logic
      const teamNode = {
        groupData: team,
        id: teamId,
        label: name,
        position: { x: 0, y: 0 },
        hasTarget: true,
        hasSource: false,
        type: NODE_TYPES.TEAM,
        imageUrl,
        memberCount,
        parentIds,
        childrenIds: [],
        depth: 0,
        ...(selectedTeamId && { fadedNode: selectedTeamId !== teamId }),
      };

      objectivesById[teamId] = {
        ...teamNode,
        parentIds: [id, ...(objectivesById[teamId]?.parentIds || [])],
      };
    });

    const { teamCount, memberCount } = getTeamAndMemberCount(
      objectives,
      objective
    );

    const objectiveTypeFromAttr = objective?.attributes?.find(
      (attr) => attr.attributeType === "objectiveType"
    )?.value;

    const childrenIds = [...objTeamIds, ...(childObjectiveIds || [])];

    if (!id.startsWith("UNASSIGNED")) {
      allTeamIds = [...allTeamIds, ...objTeamIds];
    } else {
      unassignedSortedIds = [id, ...objTeamIds];
    }

    const idIsNotPresentInObjectives = (id) =>
      !allLoadedObjectiveIds.includes(id);
    const hasChildrenToLoad = childObjectiveIds?.filter(
      idIsNotPresentInObjectives
    ).length;
    const hasParentsToLoad = parentIds?.filter(
      idIsNotPresentInObjectives
    ).length;

    objectivesById[id] = {
      id,
      label: name,
      name: objectiveTypeDisplayName || objectiveType || objectiveTypeFromAttr,
      objectiveType,
      hasTarget:
        !isEmpty(parentIds) && objectiveType !== NODE_TYPES.NO_OBJECTIVE,
      hasSource: true,
      type: objective.type || NODE_TYPES.OBJECTIVE,
      dataSource: dataSource?.type,
      teamCount,
      memberCount,
      parentIds,
      hasChildren: !!childrenIds.length,
      attributes: objective.attributes,
      expanded: objective.expanded ?? false,
      hasChildrenToLoad,
      hasParentsToLoad,
      loaded: !!teams.length,
      hasTeamLink: !!teams.length,
      depth: teams.length ? 1 : undefined,
      childrenIds,
      ...(selectedTeamId && { fadedNode: true }),
    };

    return objectivesById;
  }, {});

  const getParentAndChildIds = (childId) => {
    const node = objectivesMap[childId];

    const parentIds = node?.parentIds || [];
    const childrenIds = (node?.childrenIds || []).filter(
      (id) => !allTeamIds.includes(id)
    );

    return [
      ...parentIds,
      ...parentIds.map(getParentAndChildIds),
      ...childrenIds,
    ].flat();
  };

  const sortedIds = allTeamIds.sort().reduce((ids, teamId) => {
    return [...ids, teamId, ...getParentAndChildIds(teamId)].flat();
  }, []);

  return uniq([...sortedIds, ...unassignedSortedIds])
    .filter((id) => objectivesMap[id])
    .map((id) => objectivesMap[id]);
};

/**
 * Map the ObjectiveNode to a react-flow Node
 * @typedef {Object} PositionData
 * @property {number} x
 * @property {number} y
 *
 * @typedef {Object} Node
 * @property {string} id
 * @property {PositionData} position
 * @property {Position} sourcePosition
 * @property {"team"|"objective"} type
 * @property {ObjectiveNode & { expanded: boolean }} data
 *
 * @param {ObjectiveNode} objective
 * @returns {Node}
 */
export function toNode({ id, depth, ...node }) {
  const sharedAttrs = {
    id,
    type: node.type,
    position: { x: 0, y: 0 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    depth,
  };

  if (node.type === NODE_TYPES.LOADING) {
    return {
      ...sharedAttrs,
      data: {
        ...node,
        expanded: true,
        visible: true,
      },
    };
  }

  return {
    ...sharedAttrs,
    data: { ...node, visible: true, expanded: true },
  };
}

const getEdge = (source, target) => {
  return {
    id: `${Math.random()}`,
    source_target: `${source}_${target}`,
    source,
    target,
    style: { strokeWidth: 1 },
  };
};

/**
 * Map a Node to a react-flow Edge
 * @typedef {Object} PositionData
 * @property {number} x
 * @property {number} y
 *
 * @typedef {Object} Edge
 * @property {string} id
 * @property {Node.data.parentId} source
 * @property {Node.id} target
 * @property {React.CSSProperties} style
 *
 * @param {Node} node
 * @returns {Edge}
 */
export function toEdge({ id, parentIds = [], teams = [] }, objectives = []) {
  const parents = parentIds
    .filter((parentId) => objectives.find(({ id }) => id === parentId))
    .map((parentId) => getEdge(id, parentId));

  const children = teams.map(({ id: teamId }) => getEdge(teamId, id));

  return [...parents, ...children];
}

export function toEdgeFromNode(node = {}) {
  const { id, data } = node;
  const parents = (data?.parentIds || []).map((parentId) =>
    getEdge(id, parentId)
  );
  const children = (data?.childrenIds || []).map((childId) =>
    getEdge(childId, id)
  );

  return [...parents, ...children];
}

export const getObjectiveVariant = (objective) => {
  return OBJECTIVE_THEME[objective?.datasource?.type]?.variant || "default";
};

export const shouldShowGroupTypeBadgeAndLabel = (objective) => {
  const datasourceType = objective?.datasource?.type;
  const issueType = find(objective?.attributes, {
    attributeType: OBJECTIVE_ATTRIBUTE_TYPE.TYPE,
  });
  if (!issueType || datasourceType === OBJECTIVE_SOURCE.Clarity) {
    return true;
  }
  return false;
};

export const isDisplayableAttribute = ({ label, value }) =>
  !isEmpty(trim(value)) && !isEmpty(trim(label));

export const getBadgeAttributes = ({ objective }) => {
  const isClarity = objective?.datasource?.type === OBJECTIVE_SOURCE.Clarity;
  const attributeType = isClarity
    ? BUSINESS_OUTCOME_FIELD.CLARITY_PPM
    : OBJECTIVE_ATTRIBUTE_TYPE.TYPE;

  let attributes = chain(objective)
    .get("attributes", [])
    .filter({ attributeType });

  if (isClarity) {
    attributes = attributes.filter(isDisplayableAttribute);
  }

  return attributes.value();
};

export const getObjectiveLabel = (workspace) => {
  const label = get(workspace, "config.allocation.strategyButtonLabel");
  return isEmpty(label) ? DEFAULT_OUTCOME_LABEL : label;
};

const getJiraStatusVariant = (statusColor) => {
  const mappedColor = JIRA_STATUS_COLOR_TO_BADGE_VARIANT[statusColor];
  return mappedColor || "primaryLight";
};

export const mapStatusColorToBadgeVariant = ({ statusColor, datasource }) => {
  switch (datasource) {
    case OBJECTIVE_SOURCE.Jira:
      return getJiraStatusVariant(statusColor);
    default:
      return "primaryLight";
  }
};
