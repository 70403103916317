import React, { useCallback } from "react";
import { ActionsMenuItem } from "orcs-design-system";
import { get, isEmpty } from "lodash";
import { NOOP } from "src/allocation/consts";

import {
  usePageMode,
  useColumns,
  useGroupTypesLookup,
  useMainQuery,
  useRootGroup,
  useLookupData,
  useViewMode,
  useDispatch,
  ACTIONS,
  VIEW_MODES,
} from "../../../context/ForecastContext";
import { hasEditableProjects } from "../../util/readonlyFlags";

const AddNewTeamButton = () => {
  const pageMode = usePageMode();
  const columns = useColumns();
  const groupTypesLookup = useGroupTypesLookup();
  const { loading } = useMainQuery();
  const rootGroup = useRootGroup();
  const { parentGroupType, activeAllocationProject, addTeamBtnLabel } =
    useLookupData();
  const viewMode = useViewMode();
  const dispatch = useDispatch();

  const showAddNewTeamModal = useCallback(() => {
    dispatch({
      type: ACTIONS.SHOW_ADD_NEW_TEAM_DIALOG,
      parentGroup: rootGroup,
    });
  }, [dispatch, rootGroup]);

  const typeConfig = get(groupTypesLookup, parentGroupType, {});

  // If view mode is not by demand, can not create new team
  if (viewMode !== VIEW_MODES.GROUPED_BY_DEMAND) {
    return null;
  }

  if (!typeConfig.canCreateChild || isEmpty(typeConfig.childTypes)) {
    return null;
  }

  const isDisabled = !hasEditableProjects(
    pageMode,
    activeAllocationProject,
    columns
  );

  // If disabled, cannot create new team
  if (isDisabled) {
    return null;
  }

  return (
    <ActionsMenuItem
      data-testid="add-team-button"
      onClick={loading ? NOOP : showAddNewTeamModal}
      className="guided-tour-allocation-forecast-add-team"
    >
      {addTeamBtnLabel || "Add team"}
    </ActionsMenuItem>
  );
};

export default AddNewTeamButton;
