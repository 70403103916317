import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
} from "react";
import { createPortal } from "react-dom";
import { useNodes } from "reactflow";
import { Box, Divider, Flex, Icon, Small } from "orcs-design-system";
import PropTypes from "prop-types";
import styled from "styled-components";
import { isNil, map } from "lodash";
import { NODE_THEMES } from "src/components/NodeVisualizer/consts";
import { useObjectiveState } from "../../contexts/ObjectiveProviderContext";
import { objectiveNodeDataPropType } from "../propTypes";
import { RoundIconButton } from "../RoundIconButton";
import { StyledCard } from "./node.styled";
import NodeTeamInfo from "./NodeTeamInfo";
import ObjectiveProgressBar from "./ObjectiveProgressBar";

export const EXPANDING_IDS = {
  MEASURES: "measures",
  TEAMS: "teams",
};

const StyledRow = styled(Flex)`
  cursor: pointer;
`;

const StyledAndPositionedCard = styled(StyledCard)`
  background: #f8f9f9;
  border: solid 1px rgba(0, 0, 0, 0.1);
  z-index: 0;

  transition: all 0.5s;
  opacity: 0;
  pointer-events: all;

  max-height: 260px;
  overflow-y: auto;

  position: absolute;
  margin-top: 0;
  top: 0;

  &.expanded {
    pointer-events: all;
    top: ${(props) => props.parentHeight}px;
    opacity: 1;
  }
`;

const getExpandableCardId = (rowId, nodeId) =>
  `expandable-card-${nodeId}-${rowId}`;

const NodeExpandables = ({ nodeCardId, nodeContainerId, data, nodeId }) => {
  const nodes = useNodes();
  const { setCurrentlyExpandedNode, currentlyExpandedNode } =
    useObjectiveState();
  const [initialOpenDetermined, setInitialOpenDetermined] = useState(false);
  const [cardHeight, setCardHeight] = useState(0);
  const expandablesRef = useRef(null);
  const [nodeElement, setNodeElement] = useState(null);
  const [containerElement, setContainerElement] = useState(null);

  // store ref to node DOM element on 1st render
  useEffect(() => {
    setNodeElement(document.getElementById(nodeCardId));
  }, [nodeCardId]);

  // store ref to container DOM element on 1st render
  useEffect(() => {
    setContainerElement(document.getElementById(nodeContainerId));
  }, [nodeContainerId]);

  // update card size on height change (needed for re-expansion when onlyRenderVisibleElements is on)
  useEffect(() => {
    if (nodeElement && nodeElement.offsetHeight !== cardHeight) {
      setCardHeight(nodeElement.offsetHeight);
    }
  }, [cardHeight, nodeElement]);

  const makeExpandingId = useCallback(
    (rowType) => `${nodeId}-${rowType}`,
    [nodeId]
  );

  const expandingRows = useMemo(() => {
    const expandables = [];

    if (data.measures?.length || data.progress) {
      expandables.push({
        rowId: makeExpandingId(EXPANDING_IDS.MEASURES),
        hideButton: !data.measures?.length,
        buttonTheme: "measuresExpander",
        rowMarkup: (
          <div style={{ width: "100%" }}>
            <ObjectiveProgressBar progress={data.progress} />
          </div>
        ),
        expandedMarkup: (
          <div>
            {data.measures?.map((measure, index) => {
              const isLastElement = index === data.measures.length - 1;

              return (
                <Fragment key={measure.id}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center" width="78%">
                      <Icon
                        size="2x"
                        icon={["far", "bullseye"]}
                        color="successLighter"
                        mr="s"
                      />
                      <Small>{measure.name}</Small>
                    </Flex>
                    <Small weight="bold">{measure.progress}%</Small>
                  </Flex>
                  {!isLastElement && <Divider light dash mb="s" mt="s" />}
                </Fragment>
              );
            })}
          </div>
        ),
      });
    }

    const teams = [...(data.childTeams ?? []), ...(data.teams ?? [])];

    if (teams?.length) {
      const teamCount = teams.length;
      const membersCount = data.memberCount;
      const isTeamNode = data.nodeType === NODE_THEMES.TEAM;

      const shareByItems = [
        { label: "teams", count: teamCount },
        { label: "members", count: membersCount },
      ];

      expandables.push({
        rowId: makeExpandingId(EXPANDING_IDS.TEAMS),
        hideButton: false,
        buttonTheme: "teamsExpander",
        buttonProps: { variant: "success" },
        rowMarkup: (
          <Flex alignItems="center">
            {isTeamNode ? (
              <Small mr="r">Child teams</Small>
            ) : (
              <Small mr="r">Shared by</Small>
            )}
            {shareByItems.map(
              ({ count, label }) =>
                !!count && (
                  <Small key={label} color="greyDark" mr="r">
                    {count} {label}
                  </Small>
                )
            )}
          </Flex>
        ),
        expandedMarkup: (
          <div>
            {teams.map((team, index) => {
              const isLastElement = index === teams.length - 1;

              return (
                <Fragment key={team.id}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <NodeTeamInfo team={team} />

                    <Small color="greyDark">{team.memberCount} members</Small>
                  </Flex>
                  {!isLastElement && <Divider light dash mb="s" mt="s" />}
                </Fragment>
              );
            })}
          </div>
        ),
      });
    }

    return expandables;
  }, [data, makeExpandingId]);

  const updateExpandedNode = useCallback(
    (rowId) => {
      const expandingCardHeight = document.getElementById(
        getExpandableCardId(rowId, data.id)
      )?.offsetHeight;

      const node = nodes.find((n) => n.id === nodeId);

      setCurrentlyExpandedNode({
        ...node,
        data: {
          ...node.data,
          expandedCardId: rowId,
          expandingCardHeight: rowId ? expandingCardHeight : null,
        },
      });
    },
    [nodes, nodeId, data, setCurrentlyExpandedNode]
  );

  const isExpanded = useCallback(
    (rowId) => {
      return currentlyExpandedNode?.data?.expandedCardId === rowId;
    },
    [currentlyExpandedNode]
  );

  const onExpanderToggle = useCallback(
    (rowId, disabled) => {
      if (disabled || isNil(nodeElement)) {
        return;
      }

      const newCardHeight = nodeElement?.offsetHeight;

      if (newCardHeight !== cardHeight) {
        setCardHeight(newCardHeight);
      }

      updateExpandedNode(isExpanded(rowId) ? null : rowId);
    },
    [cardHeight, updateExpandedNode, isExpanded, nodeElement]
  );

  useEffect(() => {
    if (initialOpenDetermined) {
      return;
    }

    if (nodes.length === 1) {
      updateExpandedNode(EXPANDING_IDS.TEAMS);
    }

    setInitialOpenDetermined(true);
  }, [initialOpenDetermined, updateExpandedNode, nodes]);

  const rows = useMemo(() => {
    return map(
      expandingRows,
      ({ rowId, rowMarkup, buttonTheme, hideButton }) => (
        <Fragment key={`${rowId}_row`}>
          <Divider light dash mb="s" mt="s" />
          <StyledRow
            justifyContent="space-between"
            align-items="center"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              onExpanderToggle(rowId, hideButton);
            }}
            onScroll={(e) => e.stopPropagation()}
          >
            <Box width="90%">{rowMarkup}</Box>
            {!hideButton && (
              <RoundIconButton
                className={`${
                  isExpanded(rowId) ? "expanded" : ""
                } ${buttonTheme}`}
                icon={["fas", "chevron-down"]}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  onExpanderToggle(rowId, hideButton);
                }}
              />
            )}
          </StyledRow>
        </Fragment>
      )
    );
  }, [expandingRows, isExpanded, onExpanderToggle]);

  const portals = useMemo(() => {
    if (!containerElement) {
      return null;
    }

    return createPortal(
      map(expandingRows, ({ rowId, expandedMarkup }) => (
        <StyledAndPositionedCard
          data-testid="expandable-card"
          data-is-scrollable="true"
          id={getExpandableCardId(rowId, data.id)}
          className={isExpanded(rowId) ? "expanded" : ""}
          key={`${rowId}_content`}
          parentHeight={cardHeight}
        >
          {expandedMarkup}
        </StyledAndPositionedCard>
      )),
      containerElement
    );
  }, [cardHeight, containerElement, data.id, expandingRows, isExpanded]);

  return (
    <div ref={expandablesRef}>
      {rows}
      {portals}
    </div>
  );
};

NodeExpandables.propTypes = {
  data: objectiveNodeDataPropType,
  nodeCardId: PropTypes.string,
  nodeContainerId: PropTypes.string,
  nodeId: PropTypes.string,
};

export default NodeExpandables;
