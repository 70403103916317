import React from "react";
import {
  Line,
  TabsLine,
} from "src/components/TeamDetailPage/PlaceholderStyles";

export const TabsPlaceholder = () => {
  return (
    <>
      <TabsLine data-testid="tab-placeholder-line" />
      <TabsLine data-testid="tab-placeholder-line" />
      <TabsLine data-testid="tab-placeholder-line" />
      <TabsLine data-testid="tab-placeholder-line" />
      <TabsLine data-testid="tab-placeholder-line" />
    </>
  );
};

export const TeamHeadingPlaceholder = () => {
  return (
    <Line
      data-testid="team-heading-placeholder-line"
      width={350}
      height={70}
      mb={4}
    />
  );
};
