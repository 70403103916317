import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Loading, Small } from "orcs-design-system";
import { filter } from "lodash";

import { ACTIONS, useLookupData } from "../../../context/ForecastContext";
import CustomGrid from "../../Shared/CustomGrid";
import TreeExpand from "../../Shared/TreeExpand";
import useGridConfig from "../../util/gridConfig";
import { formatLabel, getAction } from "../../util/label";

const getPlannerConfig = (gridConfig, isLoB) => {
  return isLoB
    ? [gridConfig.lineOfBusiness.templateColumns, gridConfig.lineOfBusiness.gap]
    : [gridConfig.planner.templateColumns.expandHeader, gridConfig.planner.gap];
};

const TagsExpandHeader = ({
  loading,
  isTagsExpanded,
  grouping,
  dispatch,
  inlineStyle,
}) => {
  const { groupingTagsLabel, groupTypesLookup } = useLookupData();
  const { id, groupId, nestedLevel, group, tags } = grouping;

  const filteredTags = filter(tags, (tag) => tag.type !== "NEW_PARENT");

  const handleToggle = useCallback(() => {
    dispatch({
      type: ACTIONS.TOGGLE_TAGS_EXPANSION,
      id,
    });
  }, [id, dispatch]);

  const action = getAction(isTagsExpanded);
  const label = formatLabel(
    groupingTagsLabel || "Tags",
    group,
    groupTypesLookup,
    {
      tagCounts: filteredTags.length || "",
    }
  );

  const [templateColumns, gap] = getPlannerConfig(
    useGridConfig(),
    grouping.isLineOfBusiness
  );

  return (
    <TreeExpand
      isOpen={isTagsExpanded}
      indentLevel={nestedLevel}
      onToggle={handleToggle}
      inlineStyle={inlineStyle}
      controlsGroupRoles={true}
    >
      {loading && <Loading mr={3} />}
      <CustomGrid
        gridTemplateColumns={templateColumns}
        gridGap={gap}
        className="guided-tour-allocation-planner-tags-header"
      >
        <Small
          fontSize={0}
          mr="auto"
          data-testid={`${groupId}-lineItem-toggle`}
        >
          {`Click to ${action} ${label}`}
        </Small>
        {
          // column juggling for line of business, this could be improved ...
          !grouping.isLineOfBusiness && (
            <>
              <div />
              <div />
            </>
          )
        }
      </CustomGrid>
    </TreeExpand>
  );
};

TagsExpandHeader.propTypes = {
  loading: PropTypes.bool,
  isTagsExpanded: PropTypes.bool,
  inlineStyle: PropTypes.bool,
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default TagsExpandHeader;
