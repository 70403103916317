import { Grid } from "orcs-design-system";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

// General
export const EmptyColumn = styled.div``;
export const Adjustment = styled.div``;
export const CustomGrid = styled(Grid)`
  align-items: center;
  padding-right: ${(props) => (props.scrollbarFix ? "8px" : "0")};
`;

// Notes

export const NotesCount = styled.small`
  position: absolute;
  font-weight: 600;
  font-size: ${themeGet("fontSizes.0")};
  color: ${themeGet("colors.white")};
  top: 15%;
  margin: auto;
`;
