import Fuse from "fuse.js";
import { trim, map } from "lodash";

const searchOptions = {
  shouldSort: false,
  tokenize: true,
  matchAllTokens: true,
  findAllMatches: true,
  threshold: 0.0,
  location: 0,
  distance: 0,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["fullName", "teams", "tags.label"],
};

const search = (items, searchTerm, options) => {
  const fuse = new Fuse(items, options);
  return fuse.search(searchTerm);
};

export default (members, personSearch) => {
  if (personSearch) {
    const searchTerm = trim(personSearch.value);
    const options = {
      ...searchOptions,
      // override key if user has selected option within search (person/team/skill etc)
      keys: personSearch.key ? [personSearch.key] : searchOptions.keys,
    };

    const results = search(members, searchTerm, options);
    return map(results, "item");
  }

  return members;
};
