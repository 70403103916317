import React from "react";
import { get, isEmpty, map } from "lodash";
import { Flex, Icon, Small } from "orcs-design-system";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import icon from "src/config/icons";

const getMessage = ({ group, groupTypes }) => {
  const selectedTypes = get(
    groupTypes[group?.type],
    `planning.objectiveTypes`,
    []
  );

  if (isEmpty(selectedTypes)) {
    return "";
  }

  const pluralisedTypes = map(selectedTypes, (type) => pluralize(type));

  return `${pluralisedTypes.join(", ")}`;
};

const SelectedTypes = ({ targetGroup, groupTypes }) => {
  const warningMessage = getMessage({ group: targetGroup, groupTypes });
  return (
    <Flex width="100%" alignItems="center" justifyContent="end">
      <Icon icon={icon.infoCircle} mr="s" />
      <Small display="block" my="s">
        {"This team type can be linked to "}
        <strong>{warningMessage}</strong>
      </Small>
    </Flex>
  );
};

SelectedTypes.propTypes = {
  targetGroup: PropTypes.object,
  groupTypes: PropTypes.object,
};

export default SelectedTypes;
