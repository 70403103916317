import React from "react";
import { find, map, reduce } from "lodash";
import { Flex } from "orcs-design-system";

import * as s from "./HierarchySearchResult.styled";

// eslint-disable-next-line import/prefer-default-export
export const highlighter = (highlighted) => (part) => {
  const highlightedPart = find(highlighted, { value: part });
  if (highlightedPart) {
    const { indices } = highlightedPart;
    return map(indices, ({ text, isHighlighted }, index) => {
      const key = `${text}${index}`;
      return isHighlighted ? (
        <mark key={key}>{text}</mark>
      ) : (
        <React.Fragment key={key}>{text}</React.Fragment>
      );
    });
  }
  return part;
};

export const getGroupLabel = (parentPaths, highlight) =>
  reduce(
    parentPaths,
    (result, parentPath, index) => {
      const appendIcon = index > 0;
      result.push(
        <s.BreadcrumbItem key={parentPath}>
          <Flex alignItems="center">
            {appendIcon && (
              <s.BreadcrumbIcon
                key={`${parentPath}-seperator`}
                icon={["fal", "chevron-right"]}
                size="xs"
                mr="4px"
                color="grey"
              />
            )}
            <s.BreadcrumbText color="greyDark">
              {highlight(parentPath)}
            </s.BreadcrumbText>
          </Flex>
        </s.BreadcrumbItem>
      );

      return result;
    },
    []
  );
