import React from "react";
import PropTypes from "prop-types";
import { size, map } from "lodash";
import { Flex, FlexItem, Box, Select, Small } from "orcs-design-system";
import { Trans, useTranslation } from "react-i18next";

import AllocationProjectPropType from "src/custom-prop-types/allocationProject";
import GroupPropType from "src/custom-prop-types/group";
import GroupTitle from "src/components/GroupTitle";
import { getOptionByValue } from "src/util/selectUtil";

const ProjectSwitcher = ({
  commentAllocationProjectId,
  allocationProjectsWithCounts,
  onChange,
}) => {
  const { t } = useTranslation("CommentModalTitle");
  const hasMoreThanOneOptions = size(allocationProjectsWithCounts) > 1;

  const allocationProjectOptions = map(
    allocationProjectsWithCounts,
    ({ allocationProject, count }) => {
      const label = (
        <span>
          <Small>{allocationProject.allocationName}&nbsp;</Small>
          <Small fontSize="10px" color="greyDark">
            <Trans ns="CommentModalTitle" i18nKey="comments" count={count}>
              {{ count }} comment{count !== 1 && "s"}
            </Trans>
          </Small>
        </span>
      );
      return {
        label,
        value: allocationProject.id,
      };
    }
  );

  const defaultValue = getOptionByValue(
    allocationProjectOptions,
    commentAllocationProjectId
  );

  return (
    <Box width="175px">
      <Select
        ariaLabel={t("selectAllocationProject", {
          allocationProject: t("allocationProject", { ns: "glossary" }),
        })}
        isDisabled={!hasMoreThanOneOptions || !defaultValue}
        isLoading={!defaultValue}
        options={allocationProjectOptions}
        isClearable={false}
        value={defaultValue}
        onChange={(e) => {
          onChange(e.value);
        }}
      />
    </Box>
  );
};

ProjectSwitcher.propTypes = {
  onChange: PropTypes.func.isRequired,
  allocationProjectsWithCounts: PropTypes.arrayOf(PropTypes.object),
  commentAllocationProjectId: AllocationProjectPropType,
};

const CommentModalTitle = ({
  roleGroup,
  targetGroup,
  commentAllocationProjectId,
  allocationProjectsWithCounts,
  onChange,
}) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" flexWrap="nowrap">
      <FlexItem>
        <Box mb="xs">
          <GroupTitle group={roleGroup} />
        </Box>
        <GroupTitle group={targetGroup} />
      </FlexItem>
      <ProjectSwitcher
        commentAllocationProjectId={commentAllocationProjectId}
        allocationProjectsWithCounts={allocationProjectsWithCounts}
        onChange={onChange}
      />
    </Flex>
  );
};

CommentModalTitle.propTypes = {
  roleGroup: GroupPropType.isRequired,
  targetGroup: GroupPropType.isRequired,
  commentAllocationProjectId: PropTypes.string,
  allocationProjectsWithCounts: PropTypes.arrayOf(
    PropTypes.shape({
      allocationProject: AllocationProjectPropType,
      count: PropTypes.number,
    })
  ),
  onChange: PropTypes.func.isRequired,
};
export default CommentModalTitle;
