import { sumBy, get, find } from "lodash";
import { FTE_SUM_TYPE, FTE_SUM_TARGET } from "src/consts/team";
import { roundFte } from "src/util/roundingStrategy";

export const findConsumedFteOrZero = (person) => {
  return sumBy(person.allocations, "fte");
};

export const findAllocatedFteOrZero = (member, team) => {
  return get(
    find(
      member.allocations,
      (allocation) => allocation.targetGroupId === team?.id
    ),
    "fte",
    0
  );
};

export const getFteSumInput = (group, groupTypes) => {
  const groupType = groupTypes[group?.type] || {};

  return {
    fteSumTarget: FTE_SUM_TARGET.ALL_MEMBERS,
    fteSumType: groupType?.isSupply
      ? FTE_SUM_TYPE.CAPACITY_FTE
      : FTE_SUM_TYPE.ALLOCATED_FTE,
  };
};

export const getRemainingFte = ({ allocations, totalFte }) => {
  const allocatedFte = sumBy(allocations, ({ fte }) => roundFte(fte));
  const remainingFte = roundFte(totalFte - allocatedFte);
  return remainingFte < 0 ? 0 : remainingFte;
};
