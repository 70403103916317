/* eslint-disable import/prefer-default-export */

export const scenario = {
  incorrectTeam: "1",
  missingTeam: "2",
  incorrectFte: "3",
};

export const copywriting = {
  OK_BUTTON: "Notify",
  CANCEL_BUTTON: "Cancel",
  NOTIFY: "This notification will be sent to",
  NOTIFY_ADDITIONAL: "Would you like to notify anyone else?",
  NOTIFY_ANYONE: "Who would you like to notify",
  NOT_IN_TEAM: "Which team is {sender} supposed to be in?",
  NOT_VALID_TEAM: "Which team is {sender} not supposed to be in?",
  SCENARIO: "Select your proposed change",
  SCENARIO_NOT_IN_TEAM: "No longer in team",
  SCENARIO_MISSING_TEAM: "Should be in another team",
  SCENARIO_INCORRECT_FTE: "Incorrect FTE allocation to a team",
  COPY_TO_CLIPBOARD: "Copy to clipboard",
  COPIED_TO_CLIPBOARD: "Copied to clipboard",
  NOTE_TO_COPY_DETAILS: `Please note: Copy and paste the fields below into your internal email service to send notification of suggested change.`,
  EMAIL_SUBJECT: "Suggested TeamForm change for {sender}",
};

export const testIds = {
  COPY_TO_CLIPBOARD_TEST_ID: "copy-to-clipboard",
};
