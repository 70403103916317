import { uniqBy } from "lodash";
import { OBJECTIVE_ATTRIBUTE_TYPE } from "src/consts/objectives";
import { getAllAttributesByType, attributeTypes } from "src/util/group";

const getLinks = (attributes) => {
  const links = getAllAttributesByType(attributes, attributeTypes.LINK);
  const externalUrls = getAllAttributesByType(
    attributes,
    OBJECTIVE_ATTRIBUTE_TYPE.EXTERNAL_URL
  );
  return uniqBy([...links, ...externalUrls], "value");
};

export default getLinks;
