import React from "react";
import PropTypes from "prop-types";
import { Flex } from "orcs-design-system";

import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";
import GroupAvatar from "./GroupAvatar";

const MembershipGroup = ({ membership }) => {
  return (
    <Flex>
      {membership.group && (
        <GroupAvatar group={membership.group} mr="s" sizing="small" />
      )}
      <GroupHierarchyBreadcrumb group={membership.group} showGroupTypeBadge />
    </Flex>
  );
};

MembershipGroup.propTypes = {
  membership: PropTypes.object,
};

export default MembershipGroup;
