import PropTypes from "prop-types";

const CellPropType = PropTypes.shape({
  id: PropTypes.string,
  lineItem: PropTypes.object,
  targetGroupId: PropTypes.string,
  sourceGroupId: PropTypes.string,
  allocationProjectId: PropTypes.string,
  isStatic: PropTypes.bool,
  isEditable: PropTypes.bool,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
  skills: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  hash: PropTypes.string,
});

export default CellPropType;
