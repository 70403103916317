import { get } from "lodash";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";

import PersonPropType from "src/custom-prop-types/person";
import { useUpdateTeamAndMemberDetails } from "src/hooks/useUpdateTeamAndMemberDetails";
import PersonAllocationPage from "src/components/PersonAllocationPage";
import { canChangeSupplyGroup } from "src/util/allocations";
import { getFteSumInput } from "src/util/fteUtil";

import { updatePersonMemberOf } from "./allocations.util";

const LegacyAllocationsPanel = ({
  person,
  groupTypes,
  allocationProject,
  workspace,
  currentGroup,
  forceUpdatePerson = false,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const updateTeamAndMemberDetails = useUpdateTeamAndMemberDetails();

  const withSupplyGroupAllocation = canChangeSupplyGroup(workspace);

  const onPersonAllocated = useCallback(
    ({ targetGroup }) => {
      setIsUpdating(true);

      updateTeamAndMemberDetails({
        person,
        groups: [targetGroup, currentGroup],
        targetGroup,
        fteSumInput: getFteSumInput(targetGroup, groupTypes),
      }).then((data) => {
        updatePersonMemberOf(forceUpdatePerson, person, get(data, "person"));
        setIsUpdating(false);
      });
    },
    [
      person,
      currentGroup,
      forceUpdatePerson,
      groupTypes,
      updateTeamAndMemberDetails,
    ]
  );

  const onPersonUnallocated = useCallback(
    ({ targetGroup }) => {
      setIsUpdating(true);
      updateTeamAndMemberDetails({
        person,
        groups: [targetGroup, currentGroup],
        targetGroup,
        checkExisting: false,
        fteSumInput: getFteSumInput(targetGroup, groupTypes),
      }).then((data) => {
        updatePersonMemberOf(forceUpdatePerson, person, get(data, "person"));
        setIsUpdating(false);
      });
    },
    [
      person,
      currentGroup,
      forceUpdatePerson,
      groupTypes,
      updateTeamAndMemberDetails,
    ]
  );

  const onPersonAllocationUpdated = useCallback(
    ({ targetGroup }) => {
      updateTeamAndMemberDetails({
        person,
        groups: [targetGroup, currentGroup],
        targetGroup,
        fteSumInput: getFteSumInput(targetGroup, groupTypes),
      }).then((data) => {
        updatePersonMemberOf(forceUpdatePerson, person, get(data, "person"));
        setIsUpdating(false);
      });
    },
    [
      person,
      currentGroup,
      forceUpdatePerson,
      groupTypes,
      updateTeamAndMemberDetails,
    ]
  );

  const onSupplyGroupChanged = useCallback(
    ({ newGroup, originalGroup }) => {
      setIsUpdating(true);
      // Trigger refresh query next
      updateTeamAndMemberDetails({
        person,
        groups: [newGroup, originalGroup, currentGroup],
        targetGroup: newGroup,
        fteSumInput: getFteSumInput(newGroup, groupTypes),
      }).then((data) => {
        updatePersonMemberOf(forceUpdatePerson, person, get(data, "person"));
        setIsUpdating(false);
      });
    },
    [
      person,
      currentGroup,
      forceUpdatePerson,
      groupTypes,
      updateTeamAndMemberDetails,
    ]
  );

  return (
    <PersonAllocationPage
      allocationProjectId={get(allocationProject, "id")}
      person={person}
      personId={person.aggregateId}
      personFte={person.fte}
      onPersonAllocated={onPersonAllocated}
      onPersonAllocationUpdated={onPersonAllocationUpdated}
      onPersonUnallocated={onPersonUnallocated}
      withSupplyGroupAllocation={withSupplyGroupAllocation}
      onSupplyGroupChanged={onSupplyGroupChanged}
      gridTemplateColumns="repeat(auto-fit,minmax(450px,1fr))"
      withPageTitle={false}
      workspace={workspace}
      groupTypes={groupTypes}
      isUpdating={isUpdating}
    />
  );
};

LegacyAllocationsPanel.propTypes = {
  person: PersonPropType.isRequired,
  groupTypes: PropTypes.object.isRequired,
  allocationProject: PropTypes.object.isRequired,
  workspace: PropTypes.object.isRequired,
  currentGroup: PropTypes.object,
  forceUpdatePerson: PropTypes.bool,
};

export default LegacyAllocationsPanel;
