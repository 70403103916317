import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";

const useCachedGroup = (fragment) => {
  const client = useApolloClient();

  const getCachedGroup = useCallback(
    (groupId) => {
      const group = client.readFragment({
        id: `Group:${groupId}`,
        fragment,
      });

      return group;
    },
    [client, fragment]
  );

  return {
    getCachedGroup,
    client,
  };
};

export default useCachedGroup;
