import React from "react";
import PropTypes from "prop-types";
import ObjectiveAttributeBadges from "src/allocation/pages/ForecastPage/components/PlannerGroupings/Objectives/ObjectiveAttributeBadges";
import { OBJECTIVE_SOURCE } from "src/consts/objectives";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import { getObjectiveVariant } from "src/util/objectives";
import ObjectiveStatusBadge from "../ObjectiveStatusBadge";

const ObjectiveBadgeRenderer = ({ objective }) => {
  const dataSourceType = objective?.datasource?.type;

  if (dataSourceType === OBJECTIVE_SOURCE.Clarity) {
    return (
      <GroupTypeBadge
        group={objective}
        variant={getObjectiveVariant(objective)}
      />
    );
  }

  return (
    <ObjectiveAttributeBadges objective={objective}>
      <ObjectiveStatusBadge objective={objective} />
    </ObjectiveAttributeBadges>
  );
};

ObjectiveBadgeRenderer.propTypes = {
  objective: PropTypes.object,
};

export default ObjectiveBadgeRenderer;
