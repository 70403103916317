import { StyledLink } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { fixUrlProtocol } from "src/util/url";

const LinkElement = ({ attributes, children, element }) => {
  return (
    <StyledLink
      data-testid="cp-element-link"
      bold
      {...attributes}
      href={fixUrlProtocol(element.url)}
      target="_blank"
    >
      {children}
    </StyledLink>
  );
};

LinkElement.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  element: PropTypes.object,
};

export default LinkElement;
