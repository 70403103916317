import React from "react";
import PropTypes from "prop-types";
import { H6, Spacer, Flex, Icon, Box } from "orcs-design-system";

import AllocationProjectContent from "./ColumnHeaders/AllocationProjectContent";

const BudgetPopOverContent = ({ title, allocationProject, rootGroup }) => {
  return (
    <Spacer my="s">
      <Flex alignItems="center">
        <Icon size="lg" icon={["fas", "search-dollar"]} />
        <Box m="s">
          <H6 weight="bold">{title}</H6>
        </Box>
      </Flex>

      {allocationProject && (
        <AllocationProjectContent
          allocationProject={allocationProject}
          rootGroup={rootGroup}
          isColumnReadonly={false}
          isConstraintsMode={false}
          showTotalCost={false}
        />
      )}
    </Spacer>
  );
};

BudgetPopOverContent.propTypes = {
  title: PropTypes.string.isRequired,
  allocationProject: PropTypes.object.isRequired,
  rootGroup: PropTypes.object.isRequired,
};

export default BudgetPopOverContent;
