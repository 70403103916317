import {
  get,
  map,
  keyBy,
  reduce,
  filter,
  union,
  keys,
  difference,
  groupBy,
  split,
  dropRight,
} from "lodash";

import { HIERARCHY_SEPARATOR } from "src/allocation/consts";
import { roundFte } from "src/util/roundingStrategy";
import keyTagsByGroupId from "./keyTagsByGroupId";

const individualChanges = (individualAllocations = []) => {
  return reduce(
    individualAllocations,
    (totalChanges, { fte, existingFte }) => {
      return totalChanges + (fte - existingFte);
    },
    0
  );
};

const findNewTargetGroups = (
  existingTargetGroups,
  macroAllocationSummaries,
  macroAllocationSummariesByTargetGroupId,
  individualAllocations
) => {
  const newGroupIds = difference(
    union(
      keys(macroAllocationSummariesByTargetGroupId),
      map(
        filter(individualAllocations, (ia) => ia.status !== "unallocated"),
        "targetGroupId"
      )
    ),
    map(existingTargetGroups, "groupId")
  );

  return map(newGroupIds, (groupId) => ({
    groupId,
    memberCount: 0,
    memberFte: 0,
    currentMemberFte: 0,
  }));
};

export default (
  existingTargetGroups,
  macroAllocationSummaries,
  individualAllocations,
  groupTags,
  groupLookup
) => {
  const macroAllocationSummariesByTargetGroupId = keyBy(
    macroAllocationSummaries,
    "groupId"
  );
  const newTargetGroups = findNewTargetGroups(
    existingTargetGroups,
    macroAllocationSummaries,
    macroAllocationSummariesByTargetGroupId,
    individualAllocations,
    groupLookup
  );
  const allTargetGroups = [...existingTargetGroups, ...newTargetGroups];

  const individualAllocationsLookup = groupBy(
    individualAllocations,
    "targetGroupId"
  );
  const tagsLookup = keyTagsByGroupId(groupTags);
  return keyBy(
    map(allTargetGroups, ({ groupId, memberFte, currentMemberFte }) => ({
      groupId,
      group: groupLookup[groupId],
      tags: tagsLookup[groupId],
      hierarchyTokens: dropRight(
        split(groupLookup[groupId].hierarchy, HIERARCHY_SEPARATOR),
        1
      ),
      currentlyAllocatedFte: roundFte(
        currentMemberFte +
          individualChanges(individualAllocationsLookup[groupId])
      ), // TODO: calculate current only using indiv allocations, not summary + indiv allocations
      targetAllocationFte: roundFte(
        get(
          macroAllocationSummariesByTargetGroupId[groupId],
          "totalApproved",
          memberFte
        ) // TODO: calculate targets using macro allocation, not macro allocation summary
      ),
    })),
    "groupId"
  );
};
