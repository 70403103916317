import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import GroupHeader from "./GroupHeader";

const TimelineGroupHeaders = ({
  isTimelineRendered,
  groups,
  setIsGroupHeadersRendered,
}) => {
  useEffect(() => {
    if (isTimelineRendered) {
      setIsGroupHeadersRendered(true);
    }
  }, [isTimelineRendered, setIsGroupHeadersRendered]);

  if (!isTimelineRendered) {
    return null;
  }

  return (
    <>
      {map(groups, (g) => {
        if (!g.group) {
          return null;
        }

        return <GroupHeader key={g.id} group={g.group} />;
      })}
    </>
  );
};

TimelineGroupHeaders.propTypes = {
  isTimelineRendered: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  setIsGroupHeadersRendered: PropTypes.func.isRequired,
};

export default TimelineGroupHeaders;
