import React, { createContext, useReducer, useContext } from "react";
import PropTypes from "prop-types";

import reducer from "./reducer";
import initialState from "./initialState";

export const DataExportContext = createContext();
export const useDataExportContext = () => useContext(DataExportContext);

export const DataExportProvider = ({
  children,
  initialState: initialStateOverride,
  value: valueOverride,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...initialStateOverride,
  });

  const value = { state, dispatch };

  return (
    <DataExportContext.Provider value={{ ...value, ...valueOverride }}>
      {children}
    </DataExportContext.Provider>
  );
};

DataExportProvider.propTypes = {
  children: PropTypes.node,
  initialState: PropTypes.object,
  value: PropTypes.object,
};
