import React from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";

const AllocateButton = ({ person, onAllocatePerson, allocateBtnLabel }) => {
  const handleClicked = () => {
    onAllocatePerson(person);
  };

  return (
    <ActionsMenuItem onClick={handleClicked}>
      {allocateBtnLabel || "Allocate"}
    </ActionsMenuItem>
  );
};

AllocateButton.propTypes = {
  person: PropTypes.object.isRequired,
  onAllocatePerson: PropTypes.func.isRequired,
  allocateBtnLabel: PropTypes.string,
};

export default AllocateButton;
