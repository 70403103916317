import { find, isFinite } from "lodash";
import { roundFte } from "src/util/roundingStrategy";

export const findAllocation = (allocations, targetGroupId) => {
  return find(allocations, (a) => a.targetGroupId === targetGroupId);
};

export const getCurrentFte = (person, targetGroupId) => {
  // use currentAllocations if it's there, or fallback to normal allocations
  const allocation = findAllocation(
    person.currentAllocations || person.allocations,
    targetGroupId
  );

  if (allocation) {
    return allocation.fte;
  }

  return 0;
};

export const getNewMembersFte = ({ requestorCell, newFte, currentFte }) => {
  const { realtimeCurrentMemberFte } = requestorCell;

  // If realtimeCurrentMemberFte is not legal... use 0
  if (!isFinite(realtimeCurrentMemberFte)) {
    return 0;
  }

  return roundFte(realtimeCurrentMemberFte + newFte - currentFte);
};
