import React, { useCallback } from "react";
import { useQuery } from "@apollo/client";
import {
  Button,
  Spacer,
  Icon,
  Modal,
  H3,
  P,
  Flex,
  Loading,
} from "orcs-design-system";
import PropTypes from "prop-types";
import { get, isEmpty } from "lodash";

import { useDataExportContext } from "../context";
import { CLOSE_EXPORT_DIALOG } from "../context/actionTypes";
import saveCsvExport from "../util/saveCsvExport";

const DataExportModal = ({ query, variables, prefixFilename, renderError }) => {
  const {
    state: { dialogOpen = false },
    dispatch,
  } = useDataExportContext();

  const { data, loading, error } = useQuery(query, {
    variables,
    fetchPolicy: "network-only",
    skip: isEmpty(variables) || !dialogOpen,
  });

  const content = get(data, "csvExport.content");

  const handleCancel = useCallback(() => {
    dispatch({
      type: CLOSE_EXPORT_DIALOG,
    });
  }, [dispatch]);

  const handleConfirm = useCallback(() => {
    saveCsvExport(prefixFilename, content);
  }, [content, prefixFilename]);

  const modalFooter = (
    <Flex>
      <Spacer mr="s">
        <Button
          onClick={handleConfirm}
          variant="success"
          iconLeft
          disabled={loading || error}
        >
          <Icon icon={["fas", "cloud-download"]} />
          {loading ? "Processing..." : "Save"}
        </Button>
        <Button
          variant="ghost"
          onClick={handleCancel}
          iconLeft
          disabled={loading || error}
        >
          <Icon icon={["fas", "times"]} />
          Cancel
        </Button>
      </Spacer>
    </Flex>
  );

  return (
    <Modal
      visible={dialogOpen}
      wWidth="420px"
      height="250px"
      maxHeight="90vh"
      maxWidth="90vw"
      onClose={handleCancel}
      footerContent={modalFooter}
      data-testid="data-export-modal"
    >
      <Spacer mb="l">
        <Flex alignItems="center">
          <Icon icon={["fas", "download"]} size="1x" mr="s" />
          <H3>CSV Export</H3>
        </Flex>
        {loading && (
          <Flex alignItems="center">
            <Loading />
            <P ml="s" data-testid="processing-data">
              Processing...
            </P>
          </Flex>
        )}
        {!loading &&
          error &&
          renderError &&
          renderError({ error, message: error.message })}
        {!loading && !error && content && (
          <Flex alignItems="center">
            <Icon
              icon={["fas", "check-circle"]}
              size="1.5x"
              mr="s"
              color="success"
            />
            <P data-testid="processing-data-completed">Processing complete</P>
          </Flex>
        )}
      </Spacer>
    </Modal>
  );
};

DataExportModal.propTypes = {
  query: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  prefixFilename: PropTypes.string.isRequired,
  renderError: PropTypes.func.isRequired,
};

export default DataExportModal;
