import themeGet from "@styled-system/theme-get";
import { Box, Flex, FlexItem, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import GroupTypeBadge from "src/components/Badge/GroupTypeBadge";

const Wrapper = styled(Box)`
  width: 100%;
  font-weight: normal;
  position: relative;
  margin-right: ${themeGet("space.s")};
  &:hover {
    &:before {
      content: "";
      background-color: ${themeGet("colors.primary10")};
      width: 100%;
      position: absolute;
      height: calc(100% + 12px);
      width: 2000px;
      top: -6px;
      left: -1000px;
    }
  }
  ${(props) =>
    props.active
      ? css`
          &:hover {
            &:before {
              background-color: ${themeGet("colors.primary20")};
            }
          }
          &:before {
            content: "";
            background-color: ${themeGet("colors.primary20")};
            width: 100%;
            position: absolute;
            height: calc(100% + 12px);
            width: 2000px;
            top: -6px;
            left: -1000px;
          }
        `
      : css``};
`;

const CustomTreeHeader = ({ node, groupTypes }) => {
  const { name, type, children, isRoot } = node;

  // Avoid the click on Checkbox triggering toggle in the tree
  const onWrapperClick = (e) => {
    e.stopPropagation();
  };

  const hasChildren = !!children && children.length > 0;

  return (
    <Wrapper
      onClick={onWrapperClick}
      width="100%"
      data-id={`tree-node-${node.id}`}
      data-testid="cp-custom-tree-header"
    >
      <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap">
        {!hasChildren && !isRoot && (
          <FlexItem paddingX="8px">
            <Icon icon={["fal", "chevron-right"]} size="1x" color="white" />
          </FlexItem>
        )}
        <FlexItem flex="1 1 100px">{name}</FlexItem>
        {type && (
          <FlexItem ml="auto">
            <GroupTypeBadge group={node} groupTypes={groupTypes} />
          </FlexItem>
        )}
      </Flex>
    </Wrapper>
  );
};

CustomTreeHeader.propTypes = {
  node: PropTypes.object,
  groupTypes: PropTypes.object,
};

export default CustomTreeHeader;
