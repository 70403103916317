import { Badge } from "orcs-design-system";
import React from "react";
import PropTypes from "prop-types";

import GroupPropType from "src/custom-prop-types/group";
import {
  GroupTypePropType,
  GroupTypesPropType,
} from "src/custom-prop-types/groupTypes";
import { getGroupTypeBadgeVariant } from "src/util/group";
import { getGroupType } from "src/util/groupType";
import * as searchUtil from "src/util/search";

const GroupTypeInnerBadge = ({
  groupType,
  selectedString,
  altStyle = false,
}) => (
  <Badge
    data-testid="cp-teamlist-row-badge"
    variant={getGroupTypeBadgeVariant(groupType)}
    style={groupType.style}
    display="block"
    altStyle={altStyle}
  >
    {searchUtil.highlightSelectedString(groupType.name, selectedString)}
  </Badge>
);

GroupTypeInnerBadge.propTypes = {
  groupType: GroupTypePropType.isRequired,
  selectedString: PropTypes.string,
  altStyle: PropTypes.bool,
};

const GroupTypeBadge = ({ group, groupTypes, selectedString, altStyle }) => {
  if (!group.type) {
    return null;
  }

  const groupType = getGroupType(group, groupTypes);
  return (
    <GroupTypeInnerBadge
      groupType={groupType}
      selectedString={selectedString}
      altStyle={altStyle}
    />
  );
};

GroupTypeBadge.propTypes = {
  group: GroupPropType,
  groupTypes: GroupTypesPropType,
  selectedString: PropTypes.string,
  altStyle: PropTypes.bool,
};

export default GroupTypeBadge;
