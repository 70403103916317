import { useQuery } from "@apollo/client";
import { isEmpty, get } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import Panel from "src/components/Panel";
import MembersListWrapper from "src/components/Person/MembersListWrapper";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PersonPropType from "src/custom-prop-types/person";
import { getWorksWithGroupsAndMembersQuery } from "src/queries/group.graphql";
import { getDemandGroupIds, getUniqueWorkmates } from "src/util/person";

import { copywriting } from "../../PersonDetailPage.config";

const PersonWorksWithPanel = ({
  loading,
  person,
  showLocalTime,
  groupTypes,
  user,
  userPerson,
  featureFlags,
  workspace,
  allocationProject,
}) => {
  const hideWorksWith = get(
    workspace,
    "config.allocation.hideWorksWith",
    false
  );

  const demandGroupIds = useMemo(
    () => getDemandGroupIds(person, groupTypes),
    [person, groupTypes]
  );

  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(getWorksWithGroupsAndMembersQuery, {
    variables: { groupIds: demandGroupIds },
    skip: loading || isEmpty(demandGroupIds), // don't run query if parent is still loading or demandGroupIds is empty
    errorPolicy: "all",
  });

  const uniqueWorkmates = useMemo(
    () => (data && person ? getUniqueWorkmates(person, data.getGroups) : []),
    [data, person]
  );

  if (
    hideWorksWith ||
    (!loading && !queryLoading && isEmpty(uniqueWorkmates))
  ) {
    return null;
  }

  return (
    <Panel
      title={copywriting.WORKS_WITH}
      data-testid="cp-people-workswith-panel"
      wrap="wrap"
    >
      <MembersListWrapper
        person={person}
        showLocalTime={showLocalTime}
        loading={loading || queryLoading}
        peopleList={uniqueWorkmates}
        groupTypes={groupTypes}
        error={error}
        user={user}
        userPerson={userPerson}
        featureFlags={featureFlags}
        workspace={workspace}
        allocationProject={allocationProject}
      />
    </Panel>
  );
};

PersonWorksWithPanel.propTypes = {
  loading: PropTypes.bool,
  showLocalTime: PropTypes.bool,
  person: PersonPropType,
  groupTypes: GroupTypesPropType,
  user: PropTypes.object,
  featureFlags: PropTypes.object,
  allocationProject: PropTypes.object,
  userPerson: PropTypes.object,
  workspace: PropTypes.object,
};

export default PersonWorksWithPanel;
