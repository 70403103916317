import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Button, Flex, FlexItem, Modal, H6 } from "orcs-design-system";
import { isNil } from "lodash"; // Adjust this import path as needed
import { roundFte } from "src/util/roundingStrategy";

import { InputDeltaLabel } from "src/allocation/pages/ForecastPage/components/Shared/InputDeltaLabel";
import { NOOP } from "src/allocation/consts";
import { TagMentionContextProvider } from "src/comments/components/RichTextEditor/plugins/mentions";
import TextEditorWithMention from "../TextEditorWithMention";
import PlannerRequestFteInput from "../PlannerRequestFteInput";
import ModalHeader from "./ModalHeader";
import SkillRequirementsLabel from "./SkillRequirementsLabel";
import SupplyGroupDescription from "./SupplyGroupDescription";

import useSkillModalState from "./useSkillModalState";

const FTEValueWrapper = styled(Flex)`
  position: relative;
  max-width: 150px;
`;

const PLACE_HOLDER = (
  <span>
    Describe the skills and capabilities you are seeking from this chapter
    <br />
    and their contribution to your objectives and key results.
    <br />
    TIP: Tag specific skills by typing # to search. E.g #javascript
  </span>
);

const AddSkillsModal = ({
  visible,
  requestorGroup,
  supplyGroup,
  skills,
  value,
  defaultedValue,
  prepopulatedValue,
  hasPrepopulatedValue,
  saveRequest,
  onHideModal,
  isPlannerPage,
}) => {
  const skillsInputRef = useRef();

  const {
    fteValue,
    isSaving,
    isFormSubmittable,
    updateFormStatus,
    handleSave,
    onFteValueChange,
    onFteInputBlur,
  } = useSkillModalState({ skills, value, defaultedValue, skillsInputRef });

  useEffect(() => {
    if (visible && skillsInputRef.current) {
      skillsInputRef.current.focus();
    }
  }, [visible]);

  const onSkillInputChange = (newSkills) => {
    updateFormStatus(newSkills, fteValue);
  };

  const handleOnConfirm = async () => {
    await handleSave(saveRequest, isPlannerPage, onHideModal);
  };

  const modalHeader = (
    <ModalHeader supplyGroup={supplyGroup} requestorGroup={requestorGroup} />
  );

  const modalFooter = (
    <Button
      isLoading={isSaving}
      disabled={!isFormSubmittable || isSaving}
      onClick={handleOnConfirm}
      variant={isFormSubmittable ? "success" : "disabled"}
    >
      Save and close
    </Button>
  );

  const numberProps = {
    allowNegative: false,
    decimalScale: 2,
    onValueChange: onFteValueChange,
    onBlur: isPlannerPage ? onFteInputBlur : NOOP,
  };

  let fteDelta = 0;
  if (hasPrepopulatedValue && !isFormSubmittable) {
    fteDelta = roundFte(prepopulatedValue - defaultedValue);
  } else if (!isNil(fteValue)) {
    fteDelta = roundFte(fteValue - defaultedValue);
  }

  return (
    <Modal
      data-testid="cp-add-skills-modal"
      visible={visible}
      width="80vw"
      maxHeight="90vh"
      maxWidth="90vw"
      onClose={isSaving ? NOOP : onHideModal}
      headerContent={modalHeader}
      footerContent={modalFooter}
    >
      <Flex alignItems="flex-start" height="100%" py="r" px="xs">
        <FlexItem flex="1 1 55%" pr="r">
          <Box mb="r">
            <H6 pb="s" weight="bold">
              Requested FTE
            </H6>
            <FTEValueWrapper alignItems="center">
              <PlannerRequestFteInput
                value={fteValue}
                defaultedValue={defaultedValue}
                prepopulatedValue={prepopulatedValue}
                hasPrepopulatedValue={
                  hasPrepopulatedValue && !isFormSubmittable
                }
                numberProps={numberProps}
              />
              <InputDeltaLabel delta={fteDelta} />
            </FTEValueWrapper>
          </Box>
          <SkillRequirementsLabel popoverText={PLACE_HOLDER} />
          <TagMentionContextProvider>
            <TextEditorWithMention
              ref={skillsInputRef}
              fullWidth
              placeholder={PLACE_HOLDER}
              value={skills}
              defaultValue={skills}
              data-testid="forecast-skills"
              onSkillInputChange={onSkillInputChange}
            />
          </TagMentionContextProvider>
        </FlexItem>
        <FlexItem flex="1 1 45%" height="100%">
          <SupplyGroupDescription supplyGroup={supplyGroup} />
        </FlexItem>
      </Flex>
    </Modal>
  );
};

AddSkillsModal.propTypes = {
  visible: PropTypes.bool,
  requestorGroup: PropTypes.object,
  supplyGroup: PropTypes.object,
  skills: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.number,
  defaultedValue: PropTypes.number,
  prepopulatedValue: PropTypes.number,
  hasPrepopulatedValue: PropTypes.bool,
  saveRequest: PropTypes.func,
  onHideModal: PropTypes.func,
  isPlannerPage: PropTypes.bool,
};

export default AddSkillsModal;
