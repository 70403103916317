import { get, isEmpty, map } from "lodash";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";

export const attributeFilterTypes = ["type"];

const useObjectiveAttributeFilter = ({ group }) => {
  const workspace = useWorkspace();
  const objectiveTypes = get(
    workspace,
    `config.groupTypes[${group?.type}].planning.objectiveTypes`,
    []
  );

  if (isEmpty(objectiveTypes)) {
    return null;
  }

  return {
    typeFilter: {
      filters: map(attributeFilterTypes, (type) => ({
        type,
        values: objectiveTypes,
      })),
    },
  };
};

export { useObjectiveAttributeFilter };
