import { Box, Button, Flex } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const getButtonVariant = (allocatingPerson) => {
  if (allocatingPerson) {
    return "disabled";
  }
  return "success";
};

const ModalFooter = ({
  allocatingPerson,
  selectedPerson,
  onClickFulfill,
  handleHideModal,
}) => {
  return (
    <Box width="100%">
      <Flex alignItems="center" justifyContent="flex-start" width="100%">
        <Button
          isLoading={allocatingPerson}
          disabled={!selectedPerson}
          onClick={onClickFulfill}
          mr="r"
          variant={getButtonVariant(allocatingPerson)}
        >
          Fulfil
        </Button>
        <Button onClick={handleHideModal} mr="r" disabled={allocatingPerson}>
          Cancel
        </Button>
      </Flex>
    </Box>
  );
};

ModalFooter.propTypes = {
  allocatingPerson: PropTypes.bool,
  selectedPerson: PropTypes.object,
  onClickFulfill: PropTypes.func,
  handleHideModal: PropTypes.func,
};

export default ModalFooter;
