import { filter, keyBy } from "lodash";
import { useOrgChartContext } from "src/components/OrgChart/context/OrgChartContext";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { ENTITY_TYPES } from "src/consts/comments";
import { getHighlightedTags } from "src/components/HighlightedTags/utils";

/**
 * If the hooks is used in the context of the OrgChart, it returns the tags that are allowed to be displayed
 * otherwise it returns highlighted tags
 */
export const useNodeProperties = ({ tags, entityType }) => {
  const { state } = useOrgChartContext() || {};
  const workspace = useWorkspace();
  const stateProperty =
    entityType === ENTITY_TYPES.GROUP ? "teamsData" : "peopleData";
  return !state
    ? getHighlightedTags(
        tags,
        workspace?.config?.tagConfig,
        entityType === ENTITY_TYPES.GROUP ? "isGroupBadge" : "isPersonBadge"
      )
    : filter(tags, (tag) => {
        const allowedTypes = keyBy(state?.properties?.[stateProperty], "value");
        return (
          allowedTypes[tag.type] && allowedTypes[tag.type]?.data?.isSelected
        );
      });
};
