import { getTeamsPath } from "./paths";

export const createNoDescriptionText = (group) => {
  return [
    {
      type: "paragraph",
      children: [
        {
          text: `Description for this ${group?.type} has not yet been entered, The area lead can add it by clicking `,
          italic: true,
          color: "grey",
        },
        {
          type: "link",
          url: `${getTeamsPath(group?.id)}`,
          children: [
            {
              text: "here",
              italic: true,
            },
          ],
        },
        {
          text: ".",
          italic: true,
          color: "grey",
        },
      ],
    },
  ];
};
