import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Popover, Flex, FlexItem, Icon } from "orcs-design-system";

import { ACTIONS } from "../../context/ForecastContext";
import SkillsDisplay from "./SkillsDisplay";

const SkillsInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SkillsInput = ({ cell, dispatch, updateMethodRef }) => {
  const { isSkillsOpen } = cell;

  const openSkills = useCallback(() => {
    dispatch({
      type: ACTIONS.SHOW_ADD_SKILLS_DIALOG,
      cell,
      updateInputValue: updateMethodRef ? updateMethodRef.current : null,
    });
  }, [dispatch, cell, updateMethodRef]);

  useEffect(() => {
    if (isSkillsOpen) {
      openSkills();
    }
  }, [isSkillsOpen, openSkills]);

  return (
    <SkillsInputWrapper className="guided-tour-allocation-forecast-cell-skills">
      <Flex alignItems="center" justifyContent="space-between">
        <FlexItem flex="1 1 auto">
          <SkillsDisplay cell={cell} />
        </FlexItem>
        <FlexItem flex="0 0 auto" ml="xs">
          <Popover
            direction="bottomLeft"
            text="Add/Edit requirements"
            width="152px"
            textAlign="center"
          >
            <Button
              small
              iconOnly
              width="28px"
              height="19px"
              py="0"
              px="8px"
              tabIndex="-1"
              ariaLabel="Add/Edit requirements"
              onClick={openSkills}
            >
              <Icon icon={["fas", "pencil"]} />
            </Button>
          </Popover>
        </FlexItem>
      </Flex>
    </SkillsInputWrapper>
  );
};

SkillsInput.propTypes = {
  cell: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  updateMethodRef: PropTypes.object,
};

export default SkillsInput;
