import React from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import { Box } from "orcs-design-system";

import { StyledCard } from "./node.styled";

const StyledBox = styled(Box)`
  padding-right: 8px;
  cursor: pointer;
`;

const BaseNode = ({
  children,
  nodeId,
  expanded,
  theme,
  visible,
  loading,
  fadedNode,
  active,
}) => {
  const card = (
    <StyledCard
      className="node-card"
      $expanded={expanded}
      $theme={theme}
      $visible={visible}
      loading={loading}
      $fadedNode={fadedNode}
      $active={active}
    >
      {children}
    </StyledCard>
  );

  if (nodeId) {
    return <StyledBox id={`node-${nodeId}`}>{card}</StyledBox>;
  }

  return card;
};

BaseNode.propTypes = {
  expanded: PropTypes.bool,
  theme: PropTypes.any,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  nodeId: PropTypes.string,
  children: PropTypes.node,
  fadedNode: PropTypes.bool,
  active: PropTypes.bool,
};

export default BaseNode;
