import { findIndex, map, set, union } from "lodash";

import { getDescendantGroups } from "src/allocation/allocation.graphql";

// eslint-disable-next-line import/prefer-default-export
export const updateDescendantGroupsQueryCache = (
  allocationProject,
  allocationConfig,
  requestorGroup,
  selectedTargetGroupId,
  dispatch,
  payload, // this can be an array of fields with key value pairs of what's to be updated in the group object
  proxy
) => {
  const teamBuilderPageQueryVariables = {
    groupId: requestorGroup.id,
    groupTypes: union(
      allocationConfig.requestorTypes,
      allocationConfig.targetTypes
    ),
  };

  const proxyDataTeamBuilderPage = proxy.readQuery({
    query: getDescendantGroups,
    variables: teamBuilderPageQueryVariables,
  });

  const updatedData = {
    ...proxyDataTeamBuilderPage,
    groups: [...(proxyDataTeamBuilderPage.groups || [])],
  };

  const index = findIndex(updatedData.groups, {
    id: selectedTargetGroupId,
  });

  if (index > -1) {
    const targetGroup = { ...updatedData.groups[index] };
    map(payload, (action) => {
      set(targetGroup, action.key, action.value);
    });

    updatedData.groups[index] = targetGroup;
    proxy.writeQuery({
      query: getDescendantGroups,
      variables: teamBuilderPageQueryVariables,
      data: { ...updatedData },
    });
    dispatch({ type: "refreshTree" });
  }
};
