import React, { useCallback } from "react";

import { useHistory } from "react-router-dom";
import PersonPropType from "src/custom-prop-types/person";
import { teamDetailsPath } from "src/util/routing";

import { SourceHandle, TargetHandle } from "./node.styled";
import { RoundIconButton } from "./RoundIconButton";

const ExpandButtonNode = ({ data }) => {
  const history = useHistory();

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const teamPath = teamDetailsPath(data.directParentId);
      history.push(teamPath);
    },
    [data.directParentId, history]
  );

  return (
    <div>
      <TargetHandle $hidden type="target" position="top" />
      <RoundIconButton
        className="tree-navigator light"
        icon={["fas", "chevron-up"]}
        onClick={onClick}
      />
      <SourceHandle $hidden type="source" position="bottom" />
    </div>
  );
};

ExpandButtonNode.propTypes = {
  data: PersonPropType,
};

export default ExpandButtonNode;
