import React, { useCallback, useContext, useState, useEffect } from "react";
import { Grid, Box } from "orcs-design-system";
import { useQuery } from "@apollo/client";
import { get, isEqual, union } from "lodash";

import {
  PageRefreshContext,
  ACTIONS as PAGE_REFRESH_ACTIONS,
} from "src/contexts/pageRefresh/PageRefreshContext";

import { requestorTeamBuilderMainQuery } from "src/allocation/allocation.graphql";
import HideTeamModal from "src/allocation/components/HideTeamModal";
import AllocationModal from "src/allocation/containers/IndividualAllocationModal";
import {
  RequestAllocationContext,
  RequestAllocationProvider,
} from "src/allocation/contexts/requestAllocationContext";
import { TEAM_TAB } from "src/consts/urlPaths";
import AllocationProjectPropType from "src/custom-prop-types/allocationProject";
import GroupPropType from "src/custom-prop-types/group";
import ErrorNotification from "src/components/ErrorNotification";
import FancyCard from "src/components/FancyCard/FancyCard";

import RestrictedRoute from "src/app/Restricted/Route";
import TargetGroups from "./containers/TargetGroupsContainer";
import SourceGroups from "./containers/SourceGroupsContainer";
import People from "./containers/PeopleContainer";
import Footer from "./components/Footer";

import { updateDescendantGroupsQueryCache } from "./TeamBuilderRequestorPage.utils";

const TeamBuilderRequestorPage = ({
  group,
  allocationProject,
  allocationConfig,
}) => {
  const [{ selectedTargetGroup }, dispatch] = useContext(
    RequestAllocationContext
  );
  const [, dispatchToPageRefreshContext] = useContext(PageRefreshContext);

  const [selectedSourceGroups, setSelectedSourceGroups] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState(null);

  // Toggle collapsed or expanded state for target groups panel based on button click inside TargetGroups component
  const [isToggled, setIsToggled] = useState(true);
  const handleButtonClick = () => {
    setIsToggled(!isToggled);
  };

  const { data, error, refetch, loading } = useQuery(
    requestorTeamBuilderMainQuery,
    {
      variables: {
        allocationProjectId: allocationProject.id,
        requestorGroupId: group.id,
        inDirectGroupIds: [selectedTargetGroup.id],
        groupTypes: union(
          allocationConfig.requestorTypes,
          allocationConfig.targetTypes
        ),
      },
      fetchPolicy: "cache-and-network",
    }
  );

  // connect and disconnect to refreshFn
  useEffect(() => {
    dispatchToPageRefreshContext({
      type: PAGE_REFRESH_ACTIONS.SET_REFRESH_FN,
      refreshFn: refetch,
    });

    return () => {
      dispatchToPageRefreshContext({
        type: PAGE_REFRESH_ACTIONS.SET_REFRESH_FN,
        refreshFn: null,
      });
    };
  }, [refetch, dispatchToPageRefreshContext]);

  // connect loading to isRefresh
  useEffect(() => {
    dispatchToPageRefreshContext({
      type: PAGE_REFRESH_ACTIONS.SET_IS_REFRESHING,
      isRefreshing: loading && data, // loading, but currently using cached data
    });
  }, [loading, data, dispatchToPageRefreshContext]);

  const handlePersonAllocationChange = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const handleGroupSelected = useCallback(
    (newlySelectedGroup, isParent, isRoot = false) => {
      if (newlySelectedGroup.id !== selectedTargetGroup.id) {
        dispatch({
          type: "selectTargetGroup",
          selectedTargetGroup: newlySelectedGroup,
          isParent,
          isRoot,
        });
        // setSelectedGroup(newlySelectedGroup);
      }
    },
    [selectedTargetGroup.id, dispatch]
  );

  const handleSourceGroupsSelected = useCallback(
    (newlySelectedSourceGroups) => {
      if (
        newlySelectedSourceGroups === null ||
        selectedSourceGroups === null ||
        !isEqual(newlySelectedSourceGroups, selectedSourceGroups)
      ) {
        setSelectedSourceGroups(newlySelectedSourceGroups);
      }
    },
    [selectedSourceGroups, setSelectedSourceGroups]
  );

  const handleMembersSelected = useCallback(
    (newlySelectedMembers) => {
      if (
        newlySelectedMembers === null ||
        selectedMembers === null ||
        !isEqual(newlySelectedMembers, selectedMembers)
      ) {
        setSelectedMembers(newlySelectedMembers);
      }
    },
    [selectedMembers, setSelectedMembers]
  );

  if (error) {
    return <ErrorNotification error={error} />;
  }

  const updateCacheOnMutation = (targetGroup) => (proxy) => {
    const currStatus = get(targetGroup, "isHidden");
    const fieldsToUpdate = [
      {
        key: "isHidden",
        value: !currStatus,
      },
    ];
    updateDescendantGroupsQueryCache(
      allocationProject,
      allocationConfig,
      group,
      selectedTargetGroup.id,
      dispatch,
      fieldsToUpdate,
      proxy
    );
  };

  return (
    <Box
      overflow="hidden"
      className="guided-tour-allocation-team-builder-demand-welcome"
    >
      <RestrictedRoute
        path={`/:workspaceId?/teams/:id/${TEAM_TAB.INDIVIDUAL_ALLOCATIONS}/:allocationProjectId/:personId`}
        key={TEAM_TAB.INDIVIDUAL_ALLOCATIONS_PERSON}
        render={() => (
          <AllocationModal
            onPersonAllocated={handlePersonAllocationChange}
            onPersonUnallocated={handlePersonAllocationChange}
            onPersonAllocationUpdated={handlePersonAllocationChange}
          />
        )}
      />
      <Grid
        gridTemplateColumns={isToggled ? "auto 3fr 3fr" : "2fr 3fr 3fr"}
        gridAutoFlow="row"
        gridGap="r"
        minHeight="0"
        height="100%"
      >
        <TargetGroups
          requestorGroup={group}
          allocationProject={allocationProject}
          allocationConfig={allocationConfig}
          onGroupSelected={handleGroupSelected}
          isToggled={isToggled}
          onButtonClick={handleButtonClick}
        />
        <FancyCard>
          <SourceGroups
            selectedGroup={selectedTargetGroup}
            requestorGroup={group}
            selectedMembers={selectedMembers}
            allocationProject={allocationProject}
            allocationConfig={allocationConfig}
            data={data}
            onSourceGroupsSelected={handleSourceGroupsSelected}
          />
          {data && (
            <Footer selectedTargetGroup={selectedTargetGroup} data={data} />
          )}
        </FancyCard>
        <People
          requestorGroup={group}
          selectedGroup={selectedTargetGroup}
          selectedRoles={selectedSourceGroups}
          allocationProjectId={allocationProject.id}
          allocationConfig={allocationConfig}
          memberAllocationData={data}
          onPeopleSelected={handleMembersSelected}
        />
      </Grid>
      <HideTeamModal
        selectedGroup={selectedTargetGroup}
        updateCacheOnMutation={updateCacheOnMutation(selectedTargetGroup)}
      />
    </Box>
  );
};

TeamBuilderRequestorPage.propTypes = {
  group: GroupPropType.isRequired,
  allocationProject: AllocationProjectPropType.isRequired,
  allocationConfig: AllocationProjectPropType.isRequired,
};

const RequestorIndividualAllocationPageWithProvider = (props) => (
  <RequestAllocationProvider
    // eslint-disable-next-line react/destructuring-assignment
    requestorGroup={props.group}
  >
    <TeamBuilderRequestorPage {...props} />
  </RequestAllocationProvider>
);

RequestorIndividualAllocationPageWithProvider.propTypes = {
  group: GroupPropType,
  allocationProject: AllocationProjectPropType.isRequired,
};

export default RequestorIndividualAllocationPageWithProvider;
