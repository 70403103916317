import React from "react";
import { Box, Icon, Flex, P } from "orcs-design-system";

const NoActivityMessage = () => {
  return (
    <Box bg="greyLightest" borderRadius={2} m="r" p="r">
      <Flex alignItems="center">
        <Icon
          icon={["far", "clipboard-check"]}
          size="lg"
          mr="s"
          color="greyDarker"
          transform="up-1"
        />
        <P color="greyDarker">The are no new changes that require action.</P>
      </Flex>
    </Box>
  );
};

export default NoActivityMessage;
