import React from "react";
import PropTypes from "prop-types";
import { words, first, find, last, split, startsWith } from "lodash";
import { P, StyledLink } from "orcs-design-system";
import { TAGS, ENTITY_TYPES } from "src/consts/comments";
import { personDetailsPath, teamDetailsPath } from "src/util/routing";
import AuthorTitle from "../AuthorTitle";

const ENTITY_TYPE_PREFIX = TAGS.ENTITY_TYPE("");
const PERSON_ID_PREFIX = TAGS.PERSON_ID("");
const GROUP_ID_PREFIX = TAGS.GROUP_ID("");
const ASSOCIATED_ENTITY_ID_PREFIX = TAGS.ASSOCIATED_ENTITY_ID("");

const createPersonPath = (id) => {
  return personDetailsPath({ aggregateId: id });
};

const createTeamPath = (id) => {
  return teamDetailsPath({ id });
};

const NormalHistoryContent = ({ content, authorTitle }) => {
  return (
    <>
      <P data-testid="tagging-history">{content}</P>
      <AuthorTitle action="Made" authorTitle={authorTitle} />
    </>
  );
};

NormalHistoryContent.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  authorTitle: PropTypes.string,
};

const renderTagContentWithLink = ({
  entityName,
  entityType,
  content,
  authorTitle,
  tags,
  prefix,
  createPath,
}) => {
  const idTag = find(tags, (t) => startsWith(t, prefix));
  const id = last(split(idTag, ":"));
  const [preLinkContent, postLinkContent] = split(content, /{.*?}/);

  // If id not found, just render the history
  if (!id) {
    return <NormalHistoryContent content={content} authorTitle={authorTitle} />;
  }

  return (
    <>
      <P data-testid={`${entityType}-tagging-history`}>
        {preLinkContent}
        <StyledLink role="link" to={createPath(id)}>
          {entityName}
        </StyledLink>
        {postLinkContent}
      </P>
      <AuthorTitle action="Made" authorTitle={authorTitle} />
    </>
  );
};

const TaggingHistoryContent = ({ content, authorTitle, supplyGroup, tags }) => {
  // The old comments didn't have entity type set, and they are all for person
  const entityType =
    last(
      split(
        find(tags, (t) => startsWith(t, ENTITY_TYPE_PREFIX)),
        ":"
      )
    ) || "person";

  const entityName = first(words(content, /[^{}]+(?=\})/));
  let textContent = content;
  let prefix = PERSON_ID_PREFIX;
  let createPath = createPersonPath;

  if (entityType === ENTITY_TYPES.PERSON) {
    const supplyGroupName = supplyGroup ? supplyGroup.name : "";
    textContent = supplyGroupName
      ? `${content} in ${supplyGroupName}`
      : content;
  } else if (entityType === ENTITY_TYPES.GROUP) {
    if (find(tags, (t) => startsWith(t, ASSOCIATED_ENTITY_ID_PREFIX))) {
      prefix = ASSOCIATED_ENTITY_ID_PREFIX;
    } else {
      prefix = GROUP_ID_PREFIX;
    }
    createPath = createTeamPath;
  }

  if (entityName) {
    return renderTagContentWithLink({
      entityName,
      entityType,
      content: textContent,
      authorTitle,
      supplyGroup,
      tags,
      prefix,
      createPath,
    });
  }

  return <NormalHistoryContent content={content} authorTitle={authorTitle} />;
};

TaggingHistoryContent.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  authorTitle: PropTypes.string,
  supplyGroup: PropTypes.object,
  tags: PropTypes.array,
};

export default TaggingHistoryContent;
