import { approverTeamBuilderSecondaryQuery } from "./secondaryQuery.gql";

export default (requiredTagTypes) => {
  return {
    skip: true,
    loading: true,
    error: null,
    data: null,
    query: approverTeamBuilderSecondaryQuery,
    variables: {
      otherGroupIds: [], // to come later, after main query is complete
      memberIds: [], // same as above
      tagTypes: requiredTagTypes,
      skipMemberTags: !requiredTagTypes.length,
    },
  };
};
