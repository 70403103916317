import { map } from "lodash";
import { Flex, Tag, H5 } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import GroupPropType from "src/custom-prop-types/group";

import { copywriting } from "../ReportTeamChange.config";
import { addSenderToMessage } from "../ReportTeamChange.util";

const NotInTeam = ({ teams, onSelectTeam, sender }) => (
  <>
    <H5 weight="bold" mb={2}>
      {addSenderToMessage(sender, copywriting.NOT_VALID_TEAM)}
    </H5>
    <Flex alignItems="center" flexWrap="wrap" mt="r">
      {map(teams, (group) => {
        return (
          <Tag
            m="xs"
            key={group.id}
            data-testid="cp-reportteamchange-notinteam-btn"
            onClick={onSelectTeam(group)}
            selected={group.isRemove}
            mr="xs"
            mb="xs"
          >
            {group.name}
          </Tag>
        );
      })}
    </Flex>
  </>
);

NotInTeam.propTypes = {
  teams: PropTypes.arrayOf(GroupPropType),
  onSelectTeam: PropTypes.func,
  sender: PropTypes.string,
};

export default NotInTeam;
