import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { map, get } from "lodash";
import { Text, Flex, Spacer } from "orcs-design-system";
import { numberToLocaleString } from "src/util/toLocaleString";

import { COLUMN_TYPES, useLookupData } from "../../../context/ForecastContext";
import DeltaLabel from "../../Shared/DeltaLabel";
import EmptyColumn from "../../Shared/EmptyColumn";
import DirectReportNotification from "../DirectReportNotification";

const GroupingTotals = ({
  grouping,
  withNotification = true,
  hideApproverColumn = false,
}) => {
  const { groupTypesLookup } = useLookupData();
  const {
    summedTotals,
    notifications,
    root: { isLineOfBusiness: isRootGroupLineOfBusiness },
  } = grouping;

  return map(
    summedTotals,
    ({ columnId, columnType, total, deltaTotal }, index) => {
      if (columnType === COLUMN_TYPES.PLANNER_APPROVED && hideApproverColumn) {
        return <EmptyColumn key={columnId} />;
      }

      if (columnType === COLUMN_TYPES.PLANNER_CURRENT) {
        return (
          <Text weight="bold" key={columnId}>
            {numberToLocaleString(total)}
          </Text>
        );
      }

      return (
        <Fragment key={columnId}>
          {
            // don't show the total for lineOfBusiness, we only want the notification
            !isRootGroupLineOfBusiness && (
              <Flex
                alignItems="center"
                justifyContent={hideApproverColumn ? "center" : "flex-end"}
                width="100%"
              >
                <Spacer
                  mx="xs"
                  className="guided-tour-allocation-planner-grouping-totals"
                >
                  <Text weight="bold">{numberToLocaleString(total)}</Text>
                  <DeltaLabel delta={deltaTotal} />
                </Spacer>
              </Flex>
            )
          }
          <Flex alignItems="center" justifyContent="flex-start" width="100%">
            {withNotification && (
              <DirectReportNotification
                grouping={grouping}
                groupTypesLookup={groupTypesLookup}
                existingNotification={get(notifications[index], "notification")}
              />
            )}
          </Flex>
        </Fragment>
      );
    }
  );
};

GroupingTotals.propTypes = {
  grouping: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  withNotification: PropTypes.bool,
  hideApproverColumn: PropTypes.bool,
};

export default GroupingTotals;
