import { filter } from "lodash";

import { getIndividualAllocations } from "src/queries/allocation.graphql";

// eslint-disable-next-line import/prefer-default-export
export const updateIndividualAllocationsQueryCache = (
  proxy,
  { data: { individualAllocations } },
  mutationVariables,
  variables
) => {
  try {
    const proxyData = proxy.readQuery({
      query: getIndividualAllocations,
      variables,
    });

    const filteredAllocations = filter(
      individualAllocations,
      (allocation) => allocation.status !== "unallocated"
    );

    proxy.writeQuery({
      query: getIndividualAllocations,
      variables,
      data: {
        ...proxyData,
        individualAllocations: filteredAllocations,
      },
    });
  } catch {
    // DO NOTHING
  }
};

export const getIndividualAllocationsQueryUpdater =
  (variables) =>
  (proxy, { data: { individualAllocations } }) => {
    try {
      const proxyData = proxy.readQuery({
        query: getIndividualAllocations,
        variables,
      });

      const filteredAllocations = filter(
        individualAllocations,
        (allocation) => allocation.status !== "unallocated"
      );

      proxy.writeQuery({
        query: getIndividualAllocations,
        variables,
        data: {
          ...proxyData,
          individualAllocations: filteredAllocations,
        },
      });
    } catch {
      // DO NOTHING
    }
  };
