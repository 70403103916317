import { filter } from "lodash";

export default (groups) =>
  filter(groups, (g) => {
    if (g.memberCount !== 0) {
      return true;
    }

    return false;
  });
