import React from "react";
import { map, get } from "lodash";
import { Badge } from "orcs-design-system";
import PropTypes from "prop-types";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import {
  getBadgeAttributes,
  getObjectiveVariant,
  shouldShowGroupTypeBadgeAndLabel,
} from "src/util/objectives";

const ObjectiveAttributeBadges = ({ objective, children }) => {
  const badgeAttributes = getBadgeAttributes({ objective });

  if (badgeAttributes.length === 0 && get(objective, "type") == null) {
    return null;
  }

  const badgeVariant = getObjectiveVariant(objective);

  const showGroupTypeBadgeAndLabel =
    shouldShowGroupTypeBadgeAndLabel(objective);

  return (
    <>
      {children}
      {showGroupTypeBadgeAndLabel && (
        <GroupTypeBadge group={objective} variant="primaryLight" />
      )}
      {map(badgeAttributes, (badgeAttribute) => (
        <Badge variant={badgeVariant} key={badgeAttribute.id} ml="xs" mt="xs">
          {showGroupTypeBadgeAndLabel
            ? `${badgeAttribute.label}: ${badgeAttribute.value}`
            : badgeAttribute.value}
        </Badge>
      ))}
    </>
  );
};

ObjectiveAttributeBadges.propTypes = {
  children: PropTypes.node,
  objective: PropTypes.shape({
    type: PropTypes.string,
    dataSource: PropTypes.shape({
      type: PropTypes.string,
    }),
    attributes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        attributeType: PropTypes.string,
        subtype: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
};

export default ObjectiveAttributeBadges;
