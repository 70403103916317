import { useMutation } from "@apollo/client";
import { addNewVacantRole as addNewVacantRoleMutation } from "src/allocation/members.graphql";
import { useLookupData } from "../../../pages/ForecastPage/context/ForecastContext";
import getPlaceholders from "../utils/getPlaceholders";

const useMultiplePlaceholderMutation = (targetGroupId) => {
  const [addNewVacantRole] = useMutation(addNewVacantRoleMutation);
  const { vacantRoleLabel = "vacant role" } = useLookupData();
  const saveVacantRoles = async (selectedGroupsFte) => {
    const people = getPlaceholders({
      targetGroupId,
      selectedGroupsFte,
      vacantRoleLabel,
    });
    await addNewVacantRole({
      variables: {
        input: {
          people,
        },
      },
    });
  };
  return saveVacantRoles;
};

export default useMultiplePlaceholderMutation;
