import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Flex, Box } from "orcs-design-system";

import PersonAllocationDetails from "./PersonAllocationDetails";

const FlexWrapper = styled(Flex)`
  width: 34.5%;
`;

const CustomLayout = styled(Box)`
  transform: translate(calc(-100% + 50px), -10px);
  background: rgba(255, 255, 255, 0.85);
`;

const PersonDetailsTouchPreview = ({ style, item }) => {
  if (!style || !item) {
    return null;
  }

  return (
    <FlexWrapper
      alignItems="center"
      justifyContent="space-between"
      style={style}
    >
      <CustomLayout p="s">
        <PersonAllocationDetails {...item} />
      </CustomLayout>
    </FlexWrapper>
  );
};

PersonDetailsTouchPreview.propTypes = {
  style: PropTypes.object,
  item: PropTypes.object,
};

export default PersonDetailsTouchPreview;
