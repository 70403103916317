import { Grid, FlexItem, Select, Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { getActiveTheme } from "src/services/localStorage";

import { SortByOptionLabel } from "../../pages/TeamPage/utils/index";

import BulkActionBar from "./sub-components/BulkActionBar";
import BulkEditButton from "./sub-components/BulkEditButton";

const MemberActions = ({
  team,
  memberCount,
  options,
  selectedSort,
  onSelectSortType,
  showBulkEditButton,
  showBulkAction,
  children,
  handleBulkMove,
  setTargetGroupOption,
  setSelectedMembers,
  setStatus,
  status,
  toggleBulkAction,
  teamId,
  members,
  selectedMembers,
  groupTypes,
  targetGroupOption,
  supplyLabel,
  setMembersFilterOption,
}) => {
  const columnSize = getActiveTheme() === "compact" ? "32px" : "38px";
  const gridColumns =
    memberCount < 1
      ? "minmax(280px,1fr)"
      : `minmax(280px,1fr) minmax(140px,140px) minmax(${columnSize}, ${columnSize})`;
  return children ? (
    <>
      {Boolean(memberCount) && showBulkEditButton && (
        <Box display={["block", "block", "none"]}>
          <BulkEditButton
            status={status}
            showBulkAction={showBulkAction}
            onClick={toggleBulkAction}
          />
        </Box>
      )}
      <FlexItem flex={["1 1 100%", "1 1 100%", "1 1 auto"]}>
        <Grid
          gridGap={["s", "s", "r"]}
          gridTemplateColumns={["1fr", "1fr", gridColumns]}
        >
          {children}
          {Boolean(memberCount) && (
            <Box display={["none", "none", "block"]}>
              <Select
                data-testid="cp-team-members-select"
                inputId="cp-team-members-select"
                options={options}
                value={selectedSort}
                isSearchable={false}
                onChange={onSelectSortType}
                formatOptionLabel={SortByOptionLabel}
                ariaLabel="Sort people by"
                my="s"
              />
            </Box>
          )}
          {Boolean(memberCount) && showBulkEditButton && (
            <Box display={["none", "none", "block"]}>
              <BulkEditButton
                status={status}
                showBulkAction={showBulkAction}
                onClick={toggleBulkAction}
              />
            </Box>
          )}
        </Grid>
      </FlexItem>
      {showBulkAction && (
        <FlexItem flex="1 1 100%" mt="s">
          <BulkActionBar
            team={team}
            teamId={teamId}
            members={members}
            status={status}
            setStatus={setStatus}
            handleBulkMove={handleBulkMove}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
            setTargetGroupOption={setTargetGroupOption}
            groupTypes={groupTypes}
            targetGroupOption={targetGroupOption}
            supplyLabel={supplyLabel}
            setMembersFilterOption={setMembersFilterOption}
          />
        </FlexItem>
      )}
    </>
  ) : (
    Boolean(memberCount) && (
      <FlexItem flex="0 0 140px">
        <Select
          data-testid="cp-team-members-select"
          inputId="cp-team-members-select"
          options={options}
          value={selectedSort}
          isSearchable={false}
          onChange={onSelectSortType}
          formatOptionLabel={SortByOptionLabel}
          ariaLabel="Sort people by"
          my="s"
        />
      </FlexItem>
    )
  );
};

MemberActions.propTypes = {
  memberCount: PropTypes.number,
  team: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  selectedSort: PropTypes.object,
  onSelectSortType: PropTypes.func,
  showBulkEditButton: PropTypes.bool,
  showBulkAction: PropTypes.bool,
  children: PropTypes.node,
  teamId: PropTypes.string,
  handleBulkMove: PropTypes.func,
  setTargetGroupOption: PropTypes.func,
  setSelectedMembers: PropTypes.func,
  setStatus: PropTypes.func,
  status: PropTypes.object,
  toggleBulkAction: PropTypes.func,
  members: PropTypes.array,
  selectedMembers: PropTypes.object,
  groupTypes: PropTypes.object,
  targetGroupOption: PropTypes.object,
  supplyLabel: PropTypes.string,
  setMembersFilterOption: PropTypes.func,
};

export default MemberActions;
