/* eslint-disable no-param-reassign */
import { map, get, reduce } from "lodash";
import { nanoid } from "nanoid";
import { Loading, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { Fragment, useState, useCallback } from "react";

import TeamSearch from "src/components/TeamSearch";
import { roundFte } from "src/util/roundingStrategy";
import ErrorNotification from "src/components/ErrorNotification";
import { getGroupDemandTypeFilters } from "src/util/group";

import AllocationResult from "./AllocationResult";
import IndividualAllocationRow from "./IndividualAllocationRow";

const calculateRemainingWithAllocatedFte = (
  updatedAllocs,
  personFte,
  decimalScale
) => {
  const tem = reduce(
    updatedAllocs,
    (sum, updatedAlloc) => {
      sum += updatedAlloc.fte;
      return sum;
    },
    0
  );
  const totalFteCalculated = roundFte(tem, decimalScale);

  const remainingFte = roundFte(personFte - totalFteCalculated, decimalScale);
  return {
    remainingFte: remainingFte > 0 ? remainingFte : 0,
    allocatedFte: totalFteCalculated > 0 ? totalFteCalculated : 0,
  };
};

const IndividualPersonAllocation = ({
  allocationProjectId,
  allocations,
  isLoading,
  onAllocateToNewGroup,
  onUnallocateFromGroup,
  onUpdateAllocation,
  personId,
  personFte,
  layoutFunction,
  groupTypes,
  workspace,
  allocateToGroupMutationError,
}) => {
  const decimalScale = get(workspace, "config.fteConfig.decimal", 2);
  const allocationConfig = get(workspace, "config.allocation", {});
  const [isAddingToNewGroup, setIsAddingToNewGroup] = useState(false);
  const [isRemovingFromGroup, setIsRemovingFromGroup] = useState({
    targetGroupId: "",
    isRemoving: false,
  });
  const [selectedGroup, setSelectedGroup] = useState(null);
  const enableSavingZeroFte =
    !!allocationConfig.enableSavingZeroFteAgainstIndividuals;
  const enableFteCap = !!allocationConfig.enableAllocationFteCap;

  const { allocatedFte = 0, remainingFte = 0 } =
    calculateRemainingWithAllocatedFte(allocations, personFte, decimalScale);

  const onTeamSelected = (option) => {
    setSelectedGroup(option);
  };

  const onAddToNewGroup = useCallback(async () => {
    if (!selectedGroup) {
      return;
    }

    const { group } = selectedGroup;
    const queryParam = {
      variables: {
        allocationId: nanoid(),
        allocationProjectId,
        targetGroupId: group.id,
        personId,
        requestedFte: remainingFte,
      },
    };
    setIsAddingToNewGroup(true);
    await onAllocateToNewGroup(queryParam, group);
    setIsAddingToNewGroup(false);
  }, [
    allocationProjectId,
    selectedGroup,
    personId,
    remainingFte,
    onAllocateToNewGroup,
  ]);

  const onUpdateGroupFte = useCallback(
    async (allocationId, targetGroupId, requestedFte, targetGroup) => {
      const queryParam = {
        variables: {
          allocationId,
          allocationProjectId,
          targetGroupId,
          personId,
          requestedFte,
        },
      };
      await onUpdateAllocation(queryParam, targetGroup);
    },
    [allocationProjectId, personId, onUpdateAllocation]
  );

  const onRemoveFromGroup = useCallback(
    (allocationId, targetGroupId, targetGroup) => async () => {
      const queryParam = {
        variables: {
          allocationId,
          allocationProjectId,
          targetGroupId,
          personId,
        },
      };
      setIsRemovingFromGroup({
        targetGroupId,
        isRemoving: true,
      });
      await onUnallocateFromGroup(queryParam, targetGroup);
      setIsRemovingFromGroup({
        targetGroupId,
        isRemoving: false,
      });
    },
    [
      allocationProjectId,
      personId,
      setIsRemovingFromGroup,
      onUnallocateFromGroup,
    ]
  );

  const allocatedTargetGroupIds = map(allocations, "targetGroupId");

  if (isLoading) {
    return layoutFunction(<Loading large centered />);
  }
  const typeFilters = getGroupDemandTypeFilters(groupTypes);
  return layoutFunction(
    <TeamSearch
      existingGroupIds={allocatedTargetGroupIds}
      onTeamSelected={onTeamSelected}
      isProcessing={!allocateToGroupMutationError && isAddingToNewGroup}
      onProcess={onAddToNewGroup}
      typeFilters={typeFilters}
      remainingFte={remainingFte}
    />,
    map(allocations, (allocation) => (
      <Fragment key={allocation.targetGroupId}>
        <IndividualAllocationRow
          allocation={allocation}
          isRemovingFromGroup={isRemovingFromGroup}
          onRemoveFromGroup={onRemoveFromGroup}
          onUpdateGroupFte={onUpdateGroupFte}
          decimalScale={decimalScale}
          groupTypes={groupTypes}
          enableSavingZeroFte={enableSavingZeroFte}
          personFte={personFte}
          enableFteCap={enableFteCap}
        />
      </Fragment>
    )),
    <>
      <AllocationResult
        isLoading={isLoading}
        allocatedFte={allocatedFte}
        personFte={personFte}
        remainingFte={remainingFte}
      />
      <Spacer mt="s">
        {allocateToGroupMutationError && (
          <ErrorNotification
            error={allocateToGroupMutationError}
            message={allocateToGroupMutationError?.message}
            closable
          />
        )}
      </Spacer>
    </>
  );
};

IndividualPersonAllocation.propTypes = {
  allocationProjectId: PropTypes.string,
  allocations: PropTypes.arrayOf(
    PropTypes.shape({
      targetGroupId: PropTypes.string,
      fte: PropTypes.number,
      status: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  onAllocateToNewGroup: PropTypes.func,
  onUnallocateFromGroup: PropTypes.func,
  onUpdateAllocation: PropTypes.func,
  personId: PropTypes.string,
  personFte: PropTypes.number,
  allocationConfig: PropTypes.object.isRequired,
  layoutFunction: PropTypes.func.isRequired,
  workspace: PropTypes.object,
  allocateToGroupMutationError: PropTypes.object,
};

export default IndividualPersonAllocation;
