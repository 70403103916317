import { map } from "lodash";

export default (members) =>
  map(
    members,
    ({
      person,
      fullName,
      teamNames,
      isNewlyAdded,
      isRemoved,
      allocations,
      tags,
      remainingFte,
    }) => {
      return {
        ...person,
        fullName,
        teams: teamNames,
        remainingFte,
        isNewlyAdded,
        isRemoved,
        allocations,
        tags,
      };
    }
  );
