import { get } from "lodash";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";

import PersonPropType from "src/custom-prop-types/person";
import { useUpdateTeamAndMemberDetails } from "src/hooks/useUpdateTeamAndMemberDetails";
import { useCachedGroup } from "src/hooks/useSearchGroups";
import PersonMemberships from "src/components/PersonDetailPage/PersonMemberships";
import { getFteSumInput } from "src/util/fteUtil";

import { updatePersonMemberOf } from "./allocations.util";

const PersonMembershipsPanel = ({
  person,
  groupTypes,
  currentGroup,
  layoutFunction,
  forceUpdatePerson = false,
  teamToAdd,
  teamToAddError,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const updateTeamAndMemberDetails = useUpdateTeamAndMemberDetails();
  const { getCachedGroup } = useCachedGroup();

  const onMembershipAdded = useCallback(
    (_, { groupId }) => {
      setIsUpdating(true);
      const targetGroup = getCachedGroup(groupId);
      updateTeamAndMemberDetails({
        person,
        groups: [currentGroup],
        fteSumInput: getFteSumInput(targetGroup, groupTypes),
      }).then((data) => {
        updatePersonMemberOf(forceUpdatePerson, person, get(data, "person"));
        setIsUpdating(false);
      });
    },
    [
      getCachedGroup,
      groupTypes,
      updateTeamAndMemberDetails,
      person,
      currentGroup,
      forceUpdatePerson,
    ]
  );

  const onMembershipRemoved = useCallback(
    (_, { groupId }) => {
      setIsUpdating(true);
      const targetGroup = getCachedGroup(groupId);
      updateTeamAndMemberDetails({
        person,
        groups: [currentGroup],
        fteSumInput: getFteSumInput(targetGroup, groupTypes),
      }).then((data) => {
        updatePersonMemberOf(forceUpdatePerson, person, get(data, "person"));
        setIsUpdating(false);
      });
    },
    [
      getCachedGroup,
      groupTypes,
      updateTeamAndMemberDetails,
      person,
      currentGroup,
      forceUpdatePerson,
    ]
  );

  const onMembershipUpdated = useCallback(
    (_, { groupId }) => {
      const targetGroup = getCachedGroup(groupId);

      updateTeamAndMemberDetails({
        person,
        groups: [currentGroup],
        fteSumInput: getFteSumInput(targetGroup, groupTypes),
      }).then((data) => {
        updatePersonMemberOf(forceUpdatePerson, person, get(data, "person"));
        setIsUpdating(false);
      });
    },
    [
      getCachedGroup,
      groupTypes,
      updateTeamAndMemberDetails,
      person,
      currentGroup,
      forceUpdatePerson,
    ]
  );

  const onMembershipsUpdated = useCallback(
    ({ existingGroups }) => {
      updateTeamAndMemberDetails({
        person,
        groups: [currentGroup],
        fteSumInput: getFteSumInput(existingGroups[0], groupTypes),
      }).then((data) => {
        updatePersonMemberOf(forceUpdatePerson, person, get(data, "person"));
        setIsUpdating(false);
      });
    },
    [
      currentGroup,
      updateTeamAndMemberDetails,
      person,
      groupTypes,
      forceUpdatePerson,
    ]
  );

  return (
    <PersonMemberships
      person={person}
      layoutFunction={layoutFunction}
      onMembershipAdded={onMembershipAdded}
      onMembershipRemoved={onMembershipRemoved}
      onMembershipFteUpdated={onMembershipUpdated}
      onMembershipsUpdated={onMembershipsUpdated}
      isUpdating={isUpdating}
      teamToAdd={teamToAdd}
      teamToAddError={teamToAddError}
    />
  );
};

PersonMembershipsPanel.propTypes = {
  person: PersonPropType.isRequired,
  groupTypes: PropTypes.object,
  layoutFunction: PropTypes.func.isRequired,
  currentGroup: PropTypes.object,
  forceUpdatePerson: PropTypes.bool,
  teamToAdd: PropTypes.object,
  teamToAddError: PropTypes.object,
};

export default PersonMembershipsPanel;
