import PropTypes from "prop-types";

const ComemntPropType = PropTypes.shape({
  id: PropTypes.string,
  threadId: PropTypes.string,
  authorId: PropTypes.string,
  authorTitle: PropTypes.string,
  timestamp: PropTypes.string,
  content: PropTypes.string,
  contentMarkup: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
});

export default ComemntPropType;
