import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { Transforms } from "slate";
import { isEmpty, isNil } from "lodash";
import { ReactEditor } from "slate-react";
import { Box, Tag, Flex, Loading } from "orcs-design-system";
import useMentionMenu from "./useMentionMenu";
import { insertMention } from "./helper";

const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

const MentionMenu = (props) => {
  const ref = useRef();
  const { editor } = props;
  const { target, index, search, loading, tags = [] } = useMentionMenu();

  useEffect(() => {
    if (target && tags.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.scrollY + 24}px`;
      el.style.left = `${rect.left + window.scrollX}px`;
    }
  }, [tags.length, editor, index, search, target]);

  if (isNil(target)) {
    return null;
  }

  const handleSelect = (selectedIndex) => (event) => {
    event.preventDefault();
    Transforms.select(editor, target);
    insertMention(editor, tags[selectedIndex]);
  };

  return (
    <Portal>
      <div
        ref={ref}
        className="ignore-onclickoutside"
        style={{
          top: "-9999px",
          left: "-9999px",
          position: "fixed",
          zIndex: 9900,
        }}
      >
        {loading && <Loading />}
        {!isEmpty(tags) && (
          <Box bg="greyDarker" borderRadius={2} shadow="default" p="s">
            <Flex flexDirection="column" alignItems="flex-start">
              {tags.map((tag, i) => (
                <Tag
                  m="xxs"
                  key={tag.id}
                  selected={i === index}
                  tagType={tag.tagTypeName || tag.type}
                  onMouseDown={handleSelect(i)}
                >
                  {tag.label}
                </Tag>
              ))}
            </Flex>
          </Box>
        )}
      </div>
    </Portal>
  );
};

MentionMenu.propTypes = {
  editor: PropTypes.object,
};

export default MentionMenu;
