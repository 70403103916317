import React from "react";
import { useMutation } from "@apollo/client";
import GroupPropType from "src/custom-prop-types/group";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";

import { updateGroupName as updateGroupNameMutation } from "src/pages/TeamDetailPage/team.graphql";

import TeamNameEditor from "./TeamNameEditor";

const TeamNameEditorContainer = ({ team }) => {
  // TODO: Display error message using notification
  const [updateGroupName] = useMutation(updateGroupNameMutation);

  const updateName = async (newName) => {
    await updateGroupName({
      variables: {
        groupId: team.id,
        name: newName,
      },
      update: (proxy, result) => {
        eventEmitter.emit(EVENTS.GROUP_RENAMED, result.data.result);
      },
    });
  };

  return <TeamNameEditor team={team} updateName={updateName} />;
};

TeamNameEditorContainer.propTypes = {
  team: GroupPropType.isRequired,
};

export default TeamNameEditorContainer;
