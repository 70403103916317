import React, { useCallback } from "react";
import { Box, Button, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

const ImageDropZone = ({ onFileImported, btnLabel }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        // Check if file size over 200kb
        if (file.size > 204800) {
          throw new Error(`File size too big ${file.size}`);
        }
        const reader = new FileReader();

        reader.onload = () => {
          const str = reader.result;
          onFileImported(str);
        };

        reader.readAsDataURL(file);
      });
    },
    [onFileImported]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Box {...getRootProps()} mt="r">
      <input {...getInputProps()} />
      <Button>
        <Icon icon={["fas", "cloud-upload"]} mr="s" />
        {btnLabel}
      </Button>
    </Box>
  );
};

ImageDropZone.propTypes = {
  onFileImported: PropTypes.func,
  btnLabel: PropTypes.string,
};

export default ImageDropZone;
