import { Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const BaseIcon = ({ icon, ...props }, ref) => (
  <Icon icon={icon} {...props} size="sm" ref={ref} />
);

BaseIcon.displayName = `RteIcon`;
const RteIcon = React.forwardRef(BaseIcon);

// TODO: Shape object
BaseIcon.propTypes = {
  icon: PropTypes.object,
  name: PropTypes.string,
};

export default RteIcon;
