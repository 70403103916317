import { get, some } from "lodash";
import { INDIRECT_PLACEHOLDER } from "src/allocation/pages/ForecastPage/context/reducer/macroAllocationTypes";
import { GROUPED_BY_DEMAND } from "src/allocation/pages/ForecastPage/context/reducer/viewModes";

export const showIndirectPlaceholderForecasts = ({
  groupTypesLookup,
  grouping,
  viewMode,
}) => {
  const hasPlaceholderForecasts = some(
    grouping.lineItems,
    (lineItem) => lineItem.type === INDIRECT_PLACEHOLDER
  );

  const groupType = groupTypesLookup[get(grouping, "group.type")];
  const groupTypeAllowsPlaceholderForecasts =
    groupType && get(groupType, "planning.canHaveIndirectPlaceholderForecasts");

  return (
    hasPlaceholderForecasts ||
    (groupTypeAllowsPlaceholderForecasts && viewMode === GROUPED_BY_DEMAND)
  );
};
