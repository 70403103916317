import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StyledLink, Popover, Icon } from "orcs-design-system";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";

const LinkWrapper = styled.li`
  padding: 0 0 10px 0;
  &:last-child {
    padding-bottom: 0;
  }
`;

const LinksListItem = ({ link }) => {
  const { label, value } = link;

  return (
    <LinkWrapper>
      <Popover
        inlineBlock
        width="150px"
        textAlign="center"
        text="Opens in new window"
      >
        <StyledLink
          display="inline-block"
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            trackEvent(EVENT_TRACKING.URL_CLICKED, {
              action: "team_links_link_clicked",
            });
          }}
        >
          {label}
          <Icon
            icon={["fas", "external-link-alt"]}
            size="xs"
            transform="up-8 right-6"
          />
        </StyledLink>
      </Popover>
    </LinkWrapper>
  );
};

LinksListItem.propTypes = {
  link: PropTypes.object,
};

export default LinksListItem;
