import { map, get } from "lodash";

import { findGroupTypes } from "src/util/customerConfig";
import { approverTeamBuilderMainQuery } from "./mainQuery.gql";

export default (
  group,
  workspace,
  allocationProject,
  previousInitialState,
  requiredTagTypes
) => {
  const supplyGroupTypes = map(
    findGroupTypes(workspace.config.groupTypes, "isSupply"),
    "id"
  );

  const approverGroupId = get(
    previousInitialState,
    "selectedSupplyGroupId",
    group.id
  );

  return {
    loading: true,
    error: null,
    data: null,
    query: approverTeamBuilderMainQuery,
    variables: {
      allocationProjectId: allocationProject.id,
      approverGroupId,
      parentGroupId: group.id,
      supplyGroupTypes,
      skipSupplyGroups: false,
      refreshKey: null,
      tagTypes: requiredTagTypes,
      skipAllocationTags: !requiredTagTypes.length,
    },
  };
};
