import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Badge, Flex, Small, Text, Icon, Loading } from "orcs-design-system";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

import MembershipForm from "./MembershipForm";
import SupplyMembershipForm from "./SupplyMembershipForm";
import AllocationStatusBadge from "./AllocationStatusBadge";

const CustomExpandable = styled.button`
  font-family: ${themeGet("fonts.main")},
  appearance: none;
  background-color: ${themeGet("colors.greyLightest")};
  padding: ${themeGet("space.s")};
  margin-top: ${themeGet("space.s")};
  width: 100%;
  cursor: pointer;
  border: none;
  border-radius: ${themeGet("radii.2")};
  transition: background-color 200ms ease-in-out;
  &:hover,
  &:focus {
    background-color: ${themeGet("colors.greyLighter")};
  }
  span, small {
    font-family: ${themeGet("fonts.main")}
  }
`;

const MembershipList = ({
  title,
  memberships,
  newMembership,
  fteTotal,
  fteAvailable,
  onCreate,
  onMove,
  onDestroy,
  onUpdate,
  onUpdateGroup,
  onReplace,
  collapseExpandMode,
  person,
  isSupply,
  isLoading,
  withDateOption = true,
  enableChangeSupplyGroup,
  isAdmin,
  isPastEditEnabled,
  enableFteCap,
  demandMemberships,
  selectedGroup,
  setSelectedGroup,
  fteConfig,
  teamToAddError,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(
    collapseExpandMode === "COLLAPSE"
  );
  const hasAllocation = useMemo(() => !isNaN(fteTotal), [fteTotal]);

  return (
    <>
      <CustomExpandable
        aria-label={isCollapsed ? "Expand" : "Collapse"}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Flex alignItems="center">
            <Text fontWeight="600" fontSize="1.4rem" color="greyDarkest">
              {title}
            </Text>
            {isLoading && <Loading ml="r" />}
          </Flex>
          {hasAllocation && (
            <Flex alignItems="center" ml="auto" mr="r">
              <Small display="block">Allocated Total:</Small>
              <Badge ml="s">{fteTotal} FTE</Badge>
              <AllocationStatusBadge fteAvailable={fteAvailable} />
            </Flex>
          )}
          {isCollapsed && (
            <Icon icon={["fas", "plus"]} color="greyDarker" mr="xs" />
          )}
          {!isCollapsed && (
            <Icon icon={["fas", "minus"]} color="greyDarker" mr="xs" />
          )}
        </Flex>
      </CustomExpandable>
      {!isCollapsed && (
        <>
          {memberships.map((membership) => (
            <MembershipForm
              key={membership.currentCompositeId}
              person={person}
              membership={membership}
              isLoading={isLoading}
              onUpdate={onUpdate}
              onDestroy={onDestroy}
              onReplace={onReplace}
              isSupply={isSupply}
              withDateOption={withDateOption}
              enableChangeSupplyGroup={enableChangeSupplyGroup}
              isAdmin={isAdmin}
              isPastEditEnabled={isPastEditEnabled}
              fteConfig={fteConfig}
            />
          ))}
          {onCreate && newMembership?.startDate && (
            <MembershipForm
              key={newMembership.key}
              membership={newMembership}
              person={person}
              onCreate={onCreate}
              onMove={onMove}
              isLoading={isLoading}
              isAdmin={isAdmin}
              isPastEditEnabled={isPastEditEnabled}
              enableFteCap={enableFteCap}
              demandMemberships={demandMemberships}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              fteConfig={fteConfig}
              teamToAddError={teamToAddError}
            />
          )}
          {enableChangeSupplyGroup && isSupply && (
            <SupplyMembershipForm
              person={person}
              membership={memberships[0]}
              onUpdateGroup={onUpdateGroup}
              isLoading={isLoading}
            />
          )}
        </>
      )}
    </>
  );
};

MembershipList.propTypes = {
  title: PropTypes.string,
  fteTotal: PropTypes.number,
  fteAvailable: PropTypes.number,
  memberships: PropTypes.array,
  newMembership: PropTypes.object,
  onCreate: PropTypes.func,
  onMove: PropTypes.func,
  onDestroy: PropTypes.func,
  onUpdate: PropTypes.func,
  onUpdateGroup: PropTypes.func,
  onReplace: PropTypes.func,
  collapseExpandMode: PropTypes.string,
  person: PropTypes.object,
  isLoading: PropTypes.bool,
  isSupply: PropTypes.bool,
  withDateOption: PropTypes.bool,
  enableChangeSupplyGroup: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isPastEditEnabled: PropTypes.bool,
  enableFteCap: PropTypes.bool,
  demandMemberships: PropTypes.array,
  selectedGroup: PropTypes.object,
  setSelectedGroup: PropTypes.func,
  fteConfig: PropTypes.object,
  teamToAddError: PropTypes.object,
};

export default React.memo(MembershipList);
