/* eslint-disable import/prefer-default-export */
import { map, toNumber } from "lodash";
import { createMacroAllocationId } from "src/allocation/util/macroAllocation";

export const getMacroAllocationPromises = ({
  requestMacroAllocation,
  allocationProjectId,
  selectedGroupsArray,
  isPlannerPage,
  selectedGroupsFte,
  targetGroupId,
  selectedExistingGroups,
  shouldUpdateRealtimeMemberFTE = false,
  desiredInputAsDelta = false,
  enableVacantRoleWithUpdate = false,
}) => {
  return map(selectedGroupsArray, ({ id: sourceGroupId }) => {
    const macroAllocationId = createMacroAllocationId(
      allocationProjectId,
      sourceGroupId,
      targetGroupId
    );

    const existingGroup = selectedExistingGroups
      ? selectedExistingGroups[sourceGroupId]
      : null;
    const fte = toNumber(selectedGroupsFte[sourceGroupId]);

    let requested = fte;
    if (desiredInputAsDelta) {
      requested = existingGroup ? existingGroup.value : 0;
    } else if (existingGroup && enableVacantRoleWithUpdate) {
      requested = toNumber(existingGroup.value) + fte;
    }

    const realTimeCurrentMemberFte = existingGroup
      ? toNumber(existingGroup.realtimeCurrentMemberFte) + fte
      : fte;

    return requestMacroAllocation({
      variables: {
        id: macroAllocationId,
        allocationProjectId,
        sourceGroupId,
        targetGroupId,
        ...(isPlannerPage && {
          requested,
        }),
        ...(isPlannerPage &&
          shouldUpdateRealtimeMemberFTE && {
            realtimeCurrentMemberFte: realTimeCurrentMemberFte,
          }),
      },
    });
  });
};
