import React from "react";

import PanelContainer from "./sub-components/PanelContainerV2";

const Panel = ({
  actions,
  className,
  loading,
  title,
  subtitle,
  wrap,
  "data-testid": dataTestId,
  children,
  mt,
  height,
}) => {
  return (
    <PanelContainer
      actions={actions}
      className={className}
      data-testid={dataTestId}
      title={title}
      subtitle={subtitle}
      loading={loading}
      wrap={wrap}
      mt={mt}
      height={height}
    >
      {children}
    </PanelContainer>
  );
};

Panel.propTypes = {
  ...PanelContainer.propTypes,
};

export default Panel;
