import React from "react";
import { Flex, Select, Tag } from "orcs-design-system";
import PropTypes from "prop-types";
import { filter } from "lodash";
import { ENTITY_TYPES } from "src/consts/comments";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";

const MultiValueLabel = (props) => {
  return props.children;
};

const MultiValueRemove = () => {
  return null;
};

const PropertySelector = ({
  options,
  value,
  onSelect,
  onRemove,
  entityType,
}) => {
  const workspace = useWorkspace();
  const label =
    entityType === ENTITY_TYPES.PERSON ? "People Data" : "Teams Data";

  const handleRemove = (tagType) => (e) => {
    e.stopPropagation();
    onRemove(tagType);
  };

  const filteredValues = filter(value, (val) => val?.data?.isSelected);

  return (
    <Flex mt="r">
      <Select
        inputId={`org-chart-properties-${entityType}-data`}
        label={<strong>{label}</strong>}
        options={options}
        value={filteredValues}
        isMulti
        isClearable={false}
        name="org-chart-properties"
        onChange={onSelect}
        menuPlacement="auto"
        menuPosition="fixed"
        formatOptionLabel={(option, context) => {
          const isValueLabel = context.context !== "menu";
          const shouldHighlight =
            (entityType === ENTITY_TYPES.PERSON &&
              workspace?.config?.tagConfig?.[option.value]?.isPersonBadge) ||
            (entityType === ENTITY_TYPES.GROUP &&
              workspace?.config?.tagConfig?.[option.value]?.isGroupBadge);
          return (
            <Flex>
              <Tag
                small
                selected={isValueLabel}
                showRemove={isValueLabel}
                onRemove={handleRemove(option.value)}
                highlighted={shouldHighlight}
                role="listitem"
              >
                {option.label}
              </Tag>
            </Flex>
          );
        }}
        components={{ MultiValueLabel, MultiValueRemove }}
        menuPortalTarget={document.body}
      />
    </Flex>
  );
};

PropertySelector.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
};

export default PropertySelector;
