import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { useLookupData } from "src/allocation/pages/ForecastPage/context/ForecastContext";
import { requestMacroAllocationAbsolute } from "src/allocation/allocation.graphql";
import { getMacroAllocationPromises } from "../utils/getMacroAllocationPromises";

const useRequestAllocation = ({
  isPlannerPage,
  targetGroupId,
  shouldUpdateRealtimeMemberFTE = false,
}) => {
  const {
    activeAllocationProject,
    desiredInputAsDelta,
    enableVacantRoleWithUpdate,
  } = useLookupData();
  const [requestMacroAllocation] = useMutation(requestMacroAllocationAbsolute);
  const saveAllocation = useCallback(
    async ({
      selectedGroupsArray,
      selectedGroupsFte,
      selectedExistingGroups,
    }) => {
      const macroAllocationPromises = getMacroAllocationPromises({
        requestMacroAllocation,
        allocationProjectId: activeAllocationProject.id,
        selectedGroupsArray,
        isPlannerPage,
        selectedGroupsFte,
        targetGroupId,
        shouldUpdateRealtimeMemberFTE,
        selectedExistingGroups,
        desiredInputAsDelta,
        enableVacantRoleWithUpdate,
      });
      await Promise.all(macroAllocationPromises);
    },
    [
      activeAllocationProject,
      isPlannerPage,
      shouldUpdateRealtimeMemberFTE,
      requestMacroAllocation,
      targetGroupId,
      desiredInputAsDelta,
      enableVacantRoleWithUpdate,
    ]
  );

  return saveAllocation;
};

export default useRequestAllocation;
