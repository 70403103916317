import styled, { keyframes } from "styled-components";

const gradient = {
  lightest: "rgba(0,0,0,0.1)",
  lighter: "rgba(0,0,0,0.05)",
  darkLightest: "rgba(255,255,255,0.1)",
  darkLighter: "rgba(255,255,255,0.05)",
};

/* Infinite Rotation */
export const COLOR_CHANGE = keyframes`
0% {
  background-position: -468px 0;
}
100% {
  background-position: 468px 0;
}
`;

// Use for placeholder circles
// add props as neccessary
export const Circle = styled.div`
  justify-self: center;
  width: ${({ width }) => `${width || 36}px`};
  height: ${({ height }) => `${height || 36}px`};
  background-size: 200px;
  border-radius: 50%;
  background: ${({ theme }) =>
    `linear-gradient(to right, ${
      theme === "dark" ? gradient.darkLightest : gradient.lightest
    } 5%, ${theme === "dark" ? gradient.darkLighter : gradient.lighter} 65%, ${
      theme === "dark" ? gradient.darkLightest : gradient.lightest
    } 70% )`};
  animation: ${COLOR_CHANGE} 20s linear infinite;
`;

// use for placeholder lines
// add props as neccessary
export const Line = styled.div`
  width: 100px;
  border-radius: 8px;
  animation-delay: 0.2s;
  background: ${({ theme }) =>
    `linear-gradient(to right, ${
      theme === "dark" ? gradient.darkLightest : gradient.lightest
    } 5%, ${theme === "dark" ? gradient.darkLighter : gradient.lighter} 12%, ${
      theme === "dark" ? gradient.darkLightest : gradient.lightest
    } 55% )`};
  animation: ${COLOR_CHANGE} 10s linear both infinite;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : "0px")};
`;

export const TabsLine = styled(Line)`
  width: 100px;
  height: 30px;
  margin-right: 6px;
  display: inline-block;
`;
