import React, { useEffect, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";
import { Box, Flex, FlexItem, Modal } from "orcs-design-system";
import { NO_OBJECTIVES_ASSOCIATED_ID } from "src/consts/objectives";
import {
  useWorkspace,
  useGroupTypes,
} from "src/contexts/global/WorkspaceContext";
import ModalHeader from "src/components/ModalHeader";
import { getObjectiveLabel } from "src/util/objectives";
import ObjectiveSearchTree from "./ObjectiveSearchTree";
import ObjectiveSelectSection from "./ObjectiveSelectSection";
import VerifyObjectivesButton from "./VerifyObjectivesButton";
import ObjectiveCheckbox from "./ObjectiveCheckbox";
import SelectedTypes from "./SelectedTypes";

const getNoObjectivesAssociatedOption = (label) => {
  return {
    id: NO_OBJECTIVES_ASSOCIATED_ID,
    name: `Not assigned to ${label}`,
    parentGroup: {
      id: NO_OBJECTIVES_ASSOCIATED_ID,
      name: "",
    },
  };
};

const LinkObjectivesModal = ({
  rootGroup,
  targetGroup,
  objectiveIds = [],
  visible,
  onHideModal,
  onLinkObjective,
  onRemoveObjective,
  isVerifyMode,
  isVerifying,
  verifyLinkedObjectives,
}) => {
  const workspace = useWorkspace();
  const groupTypes = useGroupTypes();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [currentSelect, setCurrentSelect] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const [objectiveLabel, nullObjectiveOption] = useMemo(() => {
    const label = getObjectiveLabel(workspace);
    const option = getNoObjectivesAssociatedOption(label);

    return [label, option];
  }, [workspace]);

  useEffect(() => {
    setCurrentSelect([...objectiveIds]);
  }, [objectiveIds]);

  const hideModal = useCallback(() => {
    setSelectedGroup(null);
    setCurrentSelect([]);
    onHideModal();
  }, [onHideModal]);

  const handleObjectiveSelect = (objective) => async (e) => {
    const { id } = objective;
    const { checked } = e.target;
    try {
      setIsUpdating(true);
      if (checked) {
        setCurrentSelect((current) => [...current, id]);
        await onLinkObjective(id);
      } else {
        setCurrentSelect((current) => filter(current, (c) => c !== id));
        await onRemoveObjective(id);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const verifyAndClose = useCallback(() => {
    verifyLinkedObjectives();
    hideModal();
  }, [verifyLinkedObjectives, hideModal]);

  const objectivesModalFooter = (
    <Flex alignItems="center" justifyContent="space-between" width="100%">
      <ObjectiveCheckbox
        option={nullObjectiveOption}
        currentSelect={currentSelect}
        objectiveIds={objectiveIds}
        isUpdating={isUpdating}
        handleObjectiveSelect={handleObjectiveSelect}
      />
      {isVerifyMode && (
        <VerifyObjectivesButton
          objectiveIds={objectiveIds}
          isVerifying={isVerifying}
          verify={verifyAndClose}
        />
      )}
      <SelectedTypes targetGroup={targetGroup} groupTypes={groupTypes} />
    </Flex>
  );

  return (
    <Modal
      data-testid="cp-link-objectives-modal"
      visible={visible}
      width="80vw"
      height="70vh"
      maxHeight="90vh"
      maxWidth="90vw"
      onClose={hideModal}
      headerContent={<ModalHeader group={targetGroup} />}
      footerContent={objectivesModalFooter}
    >
      <Flex alignItems="stretch" height="calc(100% - 16px)" mt="r">
        <FlexItem flex="1 1 50%" mr="s">
          <Box
            borderRadius={2}
            boxBorder="default"
            p="s"
            height="100%"
            maxHeight="100%"
            overflowY="auto"
            data-testid="chapters-tree-wrapper"
            overflowX="hidden"
          >
            <ObjectiveSearchTree
              handleObjectiveSelect={handleObjectiveSelect}
              isUpdating={isUpdating}
              currentSelect={currentSelect}
              objectiveIds={objectiveIds}
              rootGroup={rootGroup}
              targetGroup={targetGroup}
              groupTypes={groupTypes}
              hideExternalUrl
            />
          </Box>
        </FlexItem>
        <FlexItem flex="1 1 50%" ml="s">
          <ObjectiveSelectSection
            targetGroup={targetGroup}
            isUpdating={isUpdating}
            selectedGroup={selectedGroup}
            currentSelect={currentSelect}
            objectiveIds={objectiveIds}
            handleObjectiveSelect={handleObjectiveSelect}
            objectiveLabel={objectiveLabel}
            showStatusBadge
          />
        </FlexItem>
      </Flex>
    </Modal>
  );
};

LinkObjectivesModal.propTypes = {
  rootGroup: PropTypes.object,
  targetGroup: PropTypes.object,
  objectiveIds: PropTypes.array,
  visible: PropTypes.bool,
  onHideModal: PropTypes.func,
  onLinkObjective: PropTypes.func,
  onRemoveObjective: PropTypes.func,
  isVerifyMode: PropTypes.bool,
  isVerifying: PropTypes.bool,
  verifyLinkedObjectives: PropTypes.func,
};

export default LinkObjectivesModal;
