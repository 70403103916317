// eslint-disable-next-line import/prefer-default-export
export const updatePersonMemberOf = (
  forceUpdatePerson,
  person,
  updatedPerson
) => {
  if (!forceUpdatePerson || !updatedPerson) {
    return;
  }

  // Update person object:
  // If the modal is rendered inside a function and dispatched to Modal context
  // There is no way we can update the person object unless re-render the Modal
  // But the side effect of re-rendering the modal may need more state changes
  // so update the person directly here, and rely on the AllocatonsModal state change to refresh
  // eslint-disable-next-line no-param-reassign
  person.memberOf = updatedPerson.memberOf;
};
