import { useMutation } from "@apollo/client";
import { get } from "lodash";
import {
  Box,
  Spacer,
  Modal,
  Icon,
  Flex,
  H2,
  Button,
  Select,
  Notification,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";

import GroupTypeBadge from "src/components/Badge/GroupTypeBadge";
import icons from "src/config/icons";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import TeamSearch from "src/components/TeamSearch";
import ErrorNotification from "src/components/ErrorNotification";
import TagsEditor from "src/components/TagsEditor/TagsEditorContainerSingleGroupAssociation";

import { addAssociation as addAssociationMutation } from "./queries/addAssociation.graphql";

const ModalHeader = ({ group, groupTypes }) => {
  return (
    <Flex alignItems="center" flexWrap="wrap">
      <H2 mr="s">Adding association to {group.name}</H2>
      <GroupTypeBadge group={group} groupTypes={groupTypes} />
    </Flex>
  );
};

ModalHeader.propTypes = {
  group: PropTypes.object,
  groupTypes: PropTypes.object,
};

const AddTeamAssociationModal = ({
  group,
  groupTypes,
  isVisible,
  onHideModal,
  tagsVisibleIn,
}) => {
  const directionOptions = [
    { value: "n", label: "non-directional" },
    { value: "p", label: "provides to" },
    { value: "c", label: "consumes from" },
  ];

  const [groupB, setGroupB] = useState(null);
  const [tag, setTag] = useState(null);
  const [direction, setDirection] = useState(directionOptions[0]);
  const [isAddingAssociation, setIsAddingAssociation] = useState(false);
  const [associationAdded, setAssociationAdded] = useState(false);
  const canSave = group && groupB && tag && direction;
  const [addAssociation, { error }] = useMutation(addAssociationMutation);

  const onAddAssociation = useCallback(async () => {
    try {
      setIsAddingAssociation(true);
      let providerEntityId = null;
      if (direction) {
        if (direction.value === "p") {
          providerEntityId = group.id;
        } else if (direction.value === "c") {
          providerEntityId = groupB.id;
        }
      }
      await addAssociation({
        variables: {
          input: {
            entityAId: group.id,
            entityBId: groupB.id,
            type: tag.type,
            displayValue: tag.displayValue,
            scopeId: null,
            providerEntityId,
          },
        },
      });
    } finally {
      setIsAddingAssociation(false);
    }
    setAssociationAdded(true);
    onHideModal();
  }, [
    group,
    groupB,
    tag,
    direction,
    addAssociation,
    setIsAddingAssociation,
    setAssociationAdded,
    onHideModal,
  ]);

  const onTagSelected = useCallback(
    (selectedTag) => {
      if (!error) {
        setTag(selectedTag);
      }
    },
    [setTag, error]
  );

  const onDirectionSelected = useCallback(
    (selectedOption) => {
      if (!error) {
        setDirection(selectedOption);
      }
    },
    [setDirection, error]
  );

  const onTeamBSelected = useCallback(
    (option) => {
      if (!error) {
        setGroupB(get(option, "group", null));
      }
    },
    [setGroupB, error]
  );

  const modalFooter = (
    <Flex alignItems="center" justifyContent="space-between" width="100%">
      <Button
        height="37px"
        variant="success"
        disabled={!canSave || isAddingAssociation || error}
        onClick={onAddAssociation}
        isLoading={isAddingAssociation}
        mr="r"
        iconLeft
      >
        <Icon icon={icons.plus} />
        Add
      </Button>
      {error && (
        <ErrorNotification
          floating={false}
          message="Sorry, an error occurred, please refresh to try to add an association again."
          error={error}
        />
      )}
    </Flex>
  );

  return (
    <>
      <Modal
        data-testid="cp-allocation-modal"
        visible={isVisible}
        maxWidth="90vw"
        maxHeight="90vh"
        height="auto"
        width={["90vw", "90vw", "80vw", "70vw", "50vw"]}
        overflow="visible"
        onClose={onHideModal}
        headerContent={<ModalHeader group={group} groupTypes={groupTypes} />}
        footerContent={modalFooter}
      >
        <Spacer mt="r">
          <Box>
            <TagsEditor
              onTagSelected={onTagSelected}
              label="Select association"
              tagsVisibleIn={tagsVisibleIn}
            />
          </Box>
          <Select
            inputId="direction-select"
            label="Select direction"
            placeholder="Select a direction"
            options={directionOptions}
            value={direction}
            onChange={onDirectionSelected}
          />
          <Box>
            <TeamSearch
              existingGroupIds={[]}
              kindFilters={["team", "externally_defined_team"]}
              onTeamSelected={onTeamBSelected}
              isProcessing={isAddingAssociation || !!error}
              onProcess={() => {}}
              showButton={false}
              divider={null}
              isClearable={false}
              groupTypes={groupTypes}
              label="Select team to associate with"
              limit={50}
            />
          </Box>
        </Spacer>
      </Modal>
      {associationAdded && (
        <Notification colour="success">Association added</Notification>
      )}
    </>
  );
};

AddTeamAssociationModal.propTypes = {
  isVisible: PropTypes.bool,
  group: GroupPropType.isRequired,
  groupTypes: GroupTypesPropType,
  onHideModal: PropTypes.func,
  tagsVisibleIn: PropTypes.string,
};

export default AddTeamAssociationModal;
