import React, { useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuBody, Button, Icon } from "orcs-design-system";
import icons from "src/config/icons";

import RejectPopupMenu from "../RejectPopupMenu";
import { isModifyRejectionMode } from "./RejectButton.logic";

// The onClick prop is passed down from ActionsMenu
const MenuTrigger = ({ enabled, isModifyMode, ...others }) => {
  let variant = "danger";

  if (enabled) {
    if (isModifyMode) {
      variant = "dangerAlternate";
    }
  } else {
    variant = "disabled";
  }

  return (
    <Button
      variant={variant}
      iconOnly
      height="32px"
      width="32px"
      type="button"
      {...others}
      data-testid="reject-button"
    >
      <Icon icon={icons.cancel} />
    </Button>
  );
};

MenuTrigger.propTypes = {
  enabled: PropTypes.bool,
  isModifyMode: PropTypes.bool,
};

const RejectButton = ({
  approvalReasons,
  enabled,
  onReject,
  approvalReasonId,
  approvalReasonText,
  requestSubmittedAt,
  approvalSavedAt,
}) => {
  const popupMenuRef = useRef(null);

  const [toggleState, setToggle] = useState(false);

  const closeMenu = useCallback(() => setToggle(false), []);

  const onToggle = () => {
    setToggle(!toggleState);
  };

  const isModifyMode = isModifyRejectionMode({
    approvalReasonId,
    requestSubmittedAt,
    approvalSavedAt,
  });
  const menuTriggerButton = (
    <MenuTrigger isModifyMode={isModifyMode} enabled={enabled} />
  );

  return (
    <ActionsMenuBody
      toggleState={toggleState}
      onToggle={onToggle}
      closeMenu={closeMenu}
      direction="left"
      customTriggerComponent={menuTriggerButton}
    >
      <RejectPopupMenu
        ref={popupMenuRef}
        approvalReasons={approvalReasons}
        reasonId={approvalReasonId}
        reasonText={approvalReasonText}
        onReject={onReject}
        closeMenu={closeMenu}
        isHidden={!toggleState}
      />
    </ActionsMenuBody>
  );
};

RejectButton.propTypes = {
  approvalReasons: PropTypes.object.isRequired,
  enabled: PropTypes.bool,
  onReject: PropTypes.func.isRequired,
  approvalReasonId: PropTypes.string,
  approvalReasonText: PropTypes.string,
  requestSubmittedAt: PropTypes.number,
  approvalSavedAt: PropTypes.number,
};

export default RejectButton;
