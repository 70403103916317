import React from "react";
import { ActionsMenuItem } from "orcs-design-system";

import {
  ACTIONS,
  VIEW_MODES,
  useViewMode,
  useDispatch,
} from "../../../context/ForecastContext";

const DownloadButton = () => {
  const viewMode = useViewMode();
  const dispatch = useDispatch();

  if (viewMode === VIEW_MODES.GROUPED_BY_CONSTRAINTS) {
    return null;
  }

  const handleDownload = () => {
    dispatch({
      type: ACTIONS.OPEN_EXPORT_DIALOG,
    });
  };

  return (
    <ActionsMenuItem
      data-testid="forecast-csv-download-button"
      onClick={handleDownload}
      className="guided-tour-allocation-forecast-download"
    >
      Download to CSV
    </ActionsMenuItem>
  );
};

export default DownloadButton;
