// @ts-check

/* eslint-disable no-else-return */

import { roundFte } from "src/util/roundingStrategy";

/**
 * @param {string} name
 * @param {string} [fte]
 * @returns {string}
 */
export const getNameWithFte = (name, fte = undefined) => {
  if (fte !== undefined) {
    return `${name} (${roundFte(fte)} FTE)`;
  } else {
    return name;
  }
};
