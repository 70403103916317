/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { Flex, H6, Popover, Icon } from "orcs-design-system";

const SkillRequirementsLabel = React.memo(({ popoverText }) => {
  return (
    <Flex alignItems="center" mb="s">
      <H6 mr="s" weight="bold">
        Requirements
      </H6>
      <Popover
        text={popoverText}
        direction="right"
        textAlign="left"
        width="250px"
      >
        <Icon icon={["fas", "info-circle"]} />
      </Popover>
    </Flex>
  );
});

SkillRequirementsLabel.propTypes = {
  popoverText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default SkillRequirementsLabel;
