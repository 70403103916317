import PropTypes from "prop-types";
import React from "react";

import TagsEditorContainer from "src/components/TagsEditor";
import { hasAdminOrPowerRole } from "src/services/auth";
import GroupPropType from "src/custom-prop-types/group";
import { isGroupActive } from "src/util/group";
import useHandleEntityTagClick from "src/hooks/useHandleEntityTagClick";

const TeamTagsSections = ({ team, user, tagsVisibleIn, featureFlags }) => {
  const onEntityTagClick = useHandleEntityTagClick();

  if (!team) {
    return null;
  }

  return (
    <TagsEditorContainer
      entityId={team.id}
      entityType="group"
      entityName={team.name}
      readOnly={!hasAdminOrPowerRole(user) || !isGroupActive(team)}
      onEntityTagClick={onEntityTagClick}
      groupType={team.type}
      comments={{
        groupIds: [team.id],
      }}
      tagsVisibleIn={tagsVisibleIn}
      featureFlags={featureFlags}
    />
  );
};

TeamTagsSections.propTypes = {
  team: GroupPropType,
  user: PropTypes.object,
  tagsVisibleIn: PropTypes.string,
  featureFlags: PropTypes.object,
};

export default React.memo(TeamTagsSections);
