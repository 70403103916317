import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { elementRenderer } from "src/comments/components/RichTextEditor/plugins/mentions";

const Element = (props) => {
  const { attributes, children, element } = props;
  const CustomElement = get(elementRenderer, element.type);
  if (CustomElement) {
    return <CustomElement {...props} />;
  }
  return <div {...attributes}>{children}</div>;
};

Element.propTypes = {
  attributes: PropTypes.object,
  element: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Element;
