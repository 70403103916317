import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Flex, Loading, Small } from "orcs-design-system";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { getObjectiveLabel } from "src/util/objectives";
import {
  ACTIONS,
  COLUMN_TYPES,
  useFinancialForecasting,
} from "../../../context/ForecastContext";
import { getObjectivesNotification } from "../../../context/reducer/modelReducer/util/newNotification";
import CustomGrid from "../../Shared/CustomGrid";
import TreeExpand from "../../Shared/TreeExpand";
import NotificationDot from "../../Shared/NotificationDot";
import useGridConfig from "../../util/gridConfig";

const getPlannerConfig = (gridConfig, isLoB) => {
  return isLoB
    ? [gridConfig.lineOfBusiness.templateColumns, gridConfig.lineOfBusiness.gap]
    : [gridConfig.planner.templateColumns.expandHeader, gridConfig.planner.gap];
};

const ObjectivesExpandHeader = ({
  loading,
  isObjectivesExpanded,
  grouping,
  dispatch,
  inlineStyle,
}) => {
  const { id, groupId, nestedLevel } = grouping;

  const shouldShowCost = useFinancialForecasting();
  const workspace = useWorkspace();
  const strategyLabel = getObjectiveLabel(workspace);

  const handleRoleToggle = useCallback(() => {
    dispatch({
      type: ACTIONS.TOGGLE_OBJECTIVES_EXPANSION,
      id,
    });
  }, [id, dispatch]);

  const roleExpandContractAction = isObjectivesExpanded ? "hide" : "show";
  const notification = getObjectivesNotification(
    grouping,
    COLUMN_TYPES.PLANNER_REQUESTED
  );

  const [templateColumns, gap] = getPlannerConfig(
    useGridConfig(),
    grouping.isLineOfBusiness
  );

  return (
    <TreeExpand
      isOpen={isObjectivesExpanded}
      indentLevel={nestedLevel}
      onToggle={handleRoleToggle}
      controlsGroupRoles={true}
      inlineStyle={inlineStyle}
    >
      {loading && <Loading mr={3} />}
      <CustomGrid
        gridTemplateColumns={templateColumns}
        gridGap={gap}
        className="guided-tour-allocation-planner-objectives-header"
      >
        <Small
          fontSize={0}
          mr="auto"
          data-testid={`${groupId}-lineItem-toggle`}
        >
          {`Click to ${roleExpandContractAction} ${strategyLabel}`}
        </Small>
        {
          // column juggling for line of business, this could be improved ...
          !grouping.isLineOfBusiness && (
            <>
              <div />
              <div />
            </>
          )
        }
        {shouldShowCost && <div />}
        <Flex alignItems="center" justifyContent="flex-start" width="100%">
          <NotificationDot notification={notification} isGrouping />
        </Flex>
        {grouping.isLineOfBusiness && <div />}
      </CustomGrid>
    </TreeExpand>
  );
};

ObjectivesExpandHeader.propTypes = {
  loading: PropTypes.bool,
  isObjectivesExpanded: PropTypes.bool,
  inlineStyle: PropTypes.bool,
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default ObjectivesExpandHeader;
