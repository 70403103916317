import React from "react";
import PropTypes from "prop-types";
import { Modal, Box } from "orcs-design-system";

import CommentPropType from "src/custom-prop-types/comment";

import CommentList from "./CommentList";
import RichTextEditor from "./RichTextEditor";

const CommentModal = (props) => {
  const {
    title,
    placeholder,
    onCancel,
    canAddNote = true,
    onAddNote,
    loading,
    comments,
  } = props;

  const modalHeader = title;
  const modalFooter = (
    <Box width="100%">
      <RichTextEditor
        placeholder={placeholder}
        disabled={!canAddNote}
        addNote={onAddNote}
        onCancelNote={onCancel}
      />
    </Box>
  );

  return (
    <Modal
      visible
      width="70vw"
      height="70vh"
      maxHeight="90vh"
      maxWidth="90vw"
      onClose={onCancel}
      headerContent={modalHeader}
      footerContent={modalFooter}
    >
      <CommentList comments={comments} loading={loading} />
    </Modal>
  );
};

CommentModal.propTypes = {
  title: PropTypes.element,
  placeholder: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  canAddNote: PropTypes.bool,
  onAddNote: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(CommentPropType),
  loading: PropTypes.bool,
};

export default CommentModal;
