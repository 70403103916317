import { NODE_TYPES } from "src/components/NodeVisualizer/consts";

export const getNodesAndEdgesFromObjectives = (objectives = []) => {
  const edges = [];
  const nodes = [];

  objectives.forEach((objective) => {
    if (objective.hierarchyDepth !== null) {
      const hasChildren =
        objectives.filter(
          (o) =>
            o.hierarchyDepth !== null &&
            o.hierarchyParentIds?.includes(objective.id)
        ).length > 0;
      const hasParents = objective.hierarchyParentIds.length > 0;

      const finalObjective = {
        id: objective.id,
        position: { x: 0, y: 0 },
        type: NODE_TYPES.OBJECTIVE_NODE,
        depth: objective.hierarchyDepth,
        label: objective.name,
        data: {
          ...objective,
          hasChildren,
          hasParents,
        },
      };

      // Add header node if it doesn't exist
      if (
        !nodes.find(
          (n) =>
            n.type === NODE_TYPES.OBJECTIVE_HEADER_NODE &&
            n.data.nodeType === objective.nodeType &&
            n.depth === objective.hierarchyDepth
        )
      ) {
        nodes.push({
          id: `${objective.type}_header_${objective.hierarchyDepth}`,
          position: { x: 0, y: 0 },
          type: NODE_TYPES.OBJECTIVE_HEADER_NODE,
          depth: objective.hierarchyDepth,
          label: objective.type,
          data: {
            id: `${objective.type}_header_${objective.hierarchyDepth}`,
            depth: objective.hierarchyDepth,
            type: NODE_TYPES.OBJECTIVE_HEADER_NODE,
            hierarchyParentIds: [],
            nodeType: objective.nodeType,
            label: objective.type,
            typeCount: objectives.filter(
              (o) =>
                o.type === objective.type &&
                o.hierarchyDepth === objective.hierarchyDepth
            ).length,
          },
        });
      }

      if (objective.teamIds) {
        finalObjective.data.teams = objective.teamIds.map((id) =>
          objectives.find((o) => o.id === id)
        );
      }

      if (objective.measureIds) {
        finalObjective.data.measures = objective.measureIds.map((id) =>
          objectives.find((o) => o.id === id)
        );
      }

      nodes.push(finalObjective);

      objective.hierarchyParentIds.forEach((parentId) => {
        edges.push({
          id: `${parentId}-${objective.id}`,
          source: parentId,
          target: objective.id,
          type: "objective-edge",
          data: {
            depthId: [
              objective.hierarchyDepth,
              objectives.find((o) => o.id === parentId).hierarchyDepth,
            ].join("-"),
          },
        });
      });
    }
  });

  return { edges, nodes };
};
