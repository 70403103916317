/* eslint-disable no-param-reassign */
import { map, get, reduce } from "lodash";
import { nanoid } from "nanoid";
import React, { Fragment, useState, useCallback } from "react";
import { Loading, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import AllocationConfigPropType from "src/custom-prop-types/allocationConfig";
import { roundFte } from "src/util/roundingStrategy";

import ErrorNotification from "src/components/ErrorNotification";
import IndividualAllocationRow from "./IndividualAllocationRow";
import IndividualAllocationTeamSearch from "./IndividualAllocationTeamSearch";
import AllocationResult from "./AllocationResult";

const calculateRemainingWithAllocatedFte = (
  updatedAllocs,
  personFte,
  decimalScale
) => {
  const tem = reduce(
    updatedAllocs,
    (sum, updatedAlloc) => {
      sum += updatedAlloc.fte;
      return sum;
    },
    0
  );
  const totalFteCalculated = roundFte(tem, decimalScale);

  const remainingFte = roundFte(personFte - totalFteCalculated, decimalScale);
  return {
    remainingFte: remainingFte > 0 ? remainingFte : 0,
    allocatedFte: totalFteCalculated > 0 ? totalFteCalculated : 0,
  };
};

const IndividualPersonAllocation = ({
  error,
  allocationProjectId,
  allocations,
  isLoading,
  onAllocateToNewGroup,
  onUnallocateFromGroup,
  onUpdateAllocation,
  personId,
  personFte,
  allocationConfig,
  layoutFunction,
  withSearch = true,
  notifyStatus,
  allocateToGroupMutationError,
}) => {
  const workspace = useWorkspace();
  const decimalScale = get(workspace, "config.fteConfig.decimal", 4);
  const enableFteCap = !!allocationConfig.enableAllocationFteCap;
  const enableSavingZeroFte =
    !!allocationConfig.enableSavingZeroFteAgainstIndividuals;

  const [isAddingToNewGroup, setIsAddingToNewGroup] = useState(false);
  const [isRemovingFromGroup, setIsRemovingFromGroup] = useState({
    targetGroupId: "",
    isRemoving: false,
  });
  const [selectedGroup, setSelectedGroup] = useState(null);

  const { allocatedFte = 0, remainingFte = 0 } =
    calculateRemainingWithAllocatedFte(allocations, personFte, decimalScale);

  const onTeamSelected = (value) => {
    setSelectedGroup(value);
  };

  const onAddToNewGroup = useCallback(async () => {
    const queryParam = {
      variables: {
        allocationId: nanoid(),
        allocationProjectId,
        targetGroupId: selectedGroup,
        personId,
        requestedFte: remainingFte,
      },
    };
    setIsAddingToNewGroup(true);
    await onAllocateToNewGroup(queryParam);
    setIsAddingToNewGroup(false);
  }, [
    allocationProjectId,
    selectedGroup,
    personId,
    remainingFte,
    onAllocateToNewGroup,
  ]);

  const onUpdateGroupFte = useCallback(
    async (allocationId, targetGroupId, requestedFte) => {
      const queryParam = {
        variables: {
          allocationId,
          allocationProjectId,
          targetGroupId,
          personId,
          requestedFte,
        },
      };
      await onUpdateAllocation(queryParam);
    },
    [allocationProjectId, personId, onUpdateAllocation]
  );

  const onRemoveFromGroup = useCallback(
    (allocationId, targetGroupId) => async () => {
      const queryParam = {
        variables: {
          allocationId,
          allocationProjectId,
          targetGroupId,
          personId,
        },
      };
      setIsRemovingFromGroup({
        targetGroupId,
        isRemoving: true,
      });
      await onUnallocateFromGroup(queryParam);
      setIsRemovingFromGroup({
        targetGroupId,
        isRemoving: false,
      });
    },
    [
      allocationProjectId,
      personId,
      setIsRemovingFromGroup,
      onUnallocateFromGroup,
    ]
  );

  const allocatedTargetGroupIds = map(allocations, "targetGroupId");

  if (isLoading) {
    return layoutFunction(<Loading large centered />);
  }
  return layoutFunction(
    withSearch ? (
      <IndividualAllocationTeamSearch
        existingAllocations={allocatedTargetGroupIds}
        onTeamSelected={onTeamSelected}
        isAddingToNewGroup={!allocateToGroupMutationError && isAddingToNewGroup}
        onAddToNewGroup={onAddToNewGroup}
        allocationConfig={allocationConfig}
      />
    ) : null,
    map(allocations, (allocation) => (
      <Fragment key={allocation.targetGroupId}>
        <IndividualAllocationRow
          allocation={allocation}
          isRemovingFromGroup={isRemovingFromGroup}
          onRemoveFromGroup={onRemoveFromGroup}
          onUpdateGroupFte={onUpdateGroupFte}
          notifyStatus={notifyStatus}
          personFte={personFte}
          enableFteCap={enableFteCap}
          enableSavingZeroFte={enableSavingZeroFte}
        />
      </Fragment>
    )),
    <>
      <AllocationResult
        isLoading={isLoading}
        allocatedFte={allocatedFte}
        personFte={personFte}
        remainingFte={remainingFte}
      />
      {allocateToGroupMutationError && (
        <Spacer mt="s">
          <ErrorNotification
            error={allocateToGroupMutationError}
            message={allocateToGroupMutationError?.message}
          />
        </Spacer>
      )}
      {error && <ErrorNotification message={error.message} error={error} />}
    </>
  );
};

IndividualPersonAllocation.propTypes = {
  error: PropTypes.object,
  allocationProjectId: PropTypes.string,
  allocations: PropTypes.arrayOf(
    PropTypes.shape({
      targetGroupId: PropTypes.string,
      fte: PropTypes.number,
      status: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  onAllocateToNewGroup: PropTypes.func,
  onUnallocateFromGroup: PropTypes.func,
  onUpdateAllocation: PropTypes.func,
  personId: PropTypes.string,
  personFte: PropTypes.number,
  allocationConfig: AllocationConfigPropType.isRequired,
  layoutFunction: PropTypes.func.isRequired,
  withSearch: PropTypes.bool,
  notifyStatus: PropTypes.func,
  allocateToGroupMutationError: PropTypes.object,
};

export default IndividualPersonAllocation;
