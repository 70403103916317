import { get } from "lodash";

export const shouldLoadIndirectMembersForGroup = (
  sourceGroup,
  groupTypesLookup
) => {
  const typeConfig = get(groupTypesLookup, sourceGroup.type, {});

  const { childTypes = [], isSupplyRoot } = typeConfig;

  // For supply root, no need to load indirect members
  if (isSupplyRoot) {
    return false;
  }

  if (!childTypes) {
    return false;
  }

  // Only load indirect members if any of the childTypes is not supplySource
  for (let i = 0, j = childTypes.length; i < j; i += 1) {
    const config = get(groupTypesLookup, childTypes[i], {});

    if (!config.isSupplySource) {
      return true;
    }
  }

  return false;
};

export const shouldLoadIndirectMembers = (
  sourceGroupId,
  groupLookup,
  groupTypesLookup
) => {
  const sourceGroup = get(groupLookup, sourceGroupId, {});

  return shouldLoadIndirectMembersForGroup(sourceGroup, groupTypesLookup);
};
