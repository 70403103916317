import { Avatar, Flex, FlexItem, Spacer, Badge, Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import FteBadge from "src/components/Badge/FteBadge";
import HighlightedTags from "src/components/HighlightedTags";
import * as placeholder from "src/components/Placeholder/PlaceholderStyles";
import PersonPropType from "src/custom-prop-types/person";
import { getInitials, getName } from "src/util/person";

const PersonName = styled.span`
  text-decoration: ${({ departed }) => (departed ? "line-through" : "none")};
`;

const PersonHeaderLoading = () => {
  return (
    <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        data-testid="cp-person-header-title-placeholder"
      >
        <placeholder.Circle />
        <FlexItem>
          <Spacer m="xs">
            <placeholder.Line
              data-testid="cp-person-header-title-description-placeholder"
              width={100}
              height={24}
            />

            <placeholder.Line
              data-testid="cp-person-header-title-description-placeholder"
              width={140}
              height={16}
            />
          </Spacer>
        </FlexItem>
      </Flex>
    </Flex>
  );
};

const PersonHeader = ({ loading, person, tagConfig, showFte = false }) => {
  if (loading || !person) {
    return <PersonHeaderLoading />;
  }

  const { jobTitle, avatar, fte, disabled, tags } = person;
  const personName = getName(person);

  return (
    <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
      <Box bg="white90" p="r" borderRadius="2">
        <Avatar
          titleLevel="H1"
          title={<PersonName departed={disabled}>{personName}</PersonName>}
          imageAlt={personName}
          subtitle={
            <>
              {jobTitle && (
                <Badge mr="xs" mt="xs">
                  {jobTitle}
                </Badge>
              )}
              {showFte && <FteBadge fte={fte || 0} />}
              {disabled && <Badge ml="xs">Departed</Badge>}
              <HighlightedTags tagConfig={tagConfig} tags={tags} />
            </>
          }
          initials={getInitials(person)}
          image={avatar}
          sizing="large"
        />
      </Box>
    </Flex>
  );
};

PersonHeader.propTypes = {
  loading: PropTypes.bool,
  person: PersonPropType,
  tagConfig: PropTypes.object,
  showFte: PropTypes.bool,
};

export default PersonHeader;
