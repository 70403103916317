import { get, size } from "lodash";
import { Spacer, Modal, Box, H5, Loading } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import { NOOP } from "src/allocation/consts";
import ModalHeader from "src/components/ModalHeader";
import RichTextEditor from "src/components/RichTextEditor";
import { GroupAttribute } from "src/custom-prop-types/groupAttribute";
import { attributeTypes, getDescriptionAttribute } from "src/util/group";
// import { reportError } from "src/util/sentry";
import { isValidUrl } from "src/util/url";

import EditableInputRows from "./sub-components/EditableInputRows";
import ModalFooter from "./sub-components/ModalFooter";
import {
  changeTypes,
  copywriting,
  formFieldsLabel,
  linksLabelAndPlaceholder,
} from "./TeamEdit.config";
import * as teamEditUtil from "./TeamEdit.util";

const TeamDescriptionEditModal = ({
  group,
  attributes,
  isVisible,
  onHandleTeamUpdate,
  onHideModal,
  loading = false,
}) => {
  const [formAttributes, setFormAttributes] = useState({});

  useEffect(() => {
    if (!isVisible) {
      return NOOP;
    }

    // Set initial values from attributes
    const initialFormValues = teamEditUtil.getInitialFormAttributes(attributes);
    setFormAttributes(initialFormValues);

    return () => {
      // Reset attributes when unload the modal
      setFormAttributes({});
    };
  }, [isVisible, attributes]);

  const [onSaveError, setOnSaveError] = useState();
  const [error, setError] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const defaultDescription = useMemo(() => {
    const descriptionAttribute = getDescriptionAttribute(attributes);

    return get(descriptionAttribute, "value");
  }, [attributes]);

  const clearError = (attributeFormId) => {
    const { [attributeFormId]: currentError, ...remainingErrors } = error;
    setError(remainingErrors);
    if (Object.keys(remainingErrors).length === 0) {
      setOnSaveError(null);
    }
  };

  const validateField = (attributeFormId) => (event) => {
    const value = get(event, "target.value");

    if (!isValidUrl(value)) {
      setError({
        ...error,
        [attributeFormId]: copywriting.FIELD_URL_ERROR,
      });
    } else {
      clearError(attributeFormId);
    }
  };

  const hasError = (attributeFormId) => {
    return Boolean(error[attributeFormId]);
  };

  const onFormValueChange =
    (attributeFormId, changeType, attributeType = attributeTypes.LINK) =>
    (event) => {
      const isDescription = attributeType === attributeTypes.DESCRIPTION;
      // rich text editor has a different onChange contract
      const changeValue = isDescription ? event : get(event, "target.value");
      const newFormAttribute = teamEditUtil.applyFormAttributeChange({
        formAttributes,
        attributeFormId,
        attributeType,
        changeType,
        changeValue,
      });
      if (changeType === changeTypes.DELETE) {
        clearError(attributeFormId);
      }
      setFormAttributes({
        ...formAttributes,
        [attributeFormId]: newFormAttribute,
      });
    };

  const onConfirm = async () => {
    if (size(error)) {
      setOnSaveError(copywriting.FORM_VALIDATION_ERROR);
    } else {
      setOnSaveError(null);
      setIsSaving(true);

      try {
        const newAttributes = teamEditUtil.getAttributes(formAttributes);
        await onHandleTeamUpdate(newAttributes);
        onHideModal();
      } catch (mutationError) {
        // reportError(mutationError);
        setOnSaveError(copywriting.FORM_SAVE_ERROR);
      }
      setIsSaving(false);
    }
  };

  const onCancel = async () => {
    setError({});
    setOnSaveError(null);
    setIsSaving(false);
    onHideModal();
  };

  const modalFooter = (
    <ModalFooter
      onConfirm={onConfirm}
      onCancel={onCancel}
      isSaving={isSaving}
      onSaveError={onSaveError}
    />
  );

  return (
    <Modal
      visible={isVisible}
      handleOnConfirm={onConfirm}
      onClose={onCancel}
      maxWidth="90vw"
      width="70vw"
      height="70vh"
      maxHeight="90vh"
      headerContent={<ModalHeader group={group} />}
      footerContent={modalFooter}
    >
      {loading && <Loading large centered m="r" />}
      {!loading && (
        <Box p="xs">
          <Spacer mb="r">
            <H5 weight="bold" mt="r" mb={2}>
              {formFieldsLabel.DESCRIPTION}
            </H5>
            <RichTextEditor
              defaultValue={defaultDescription}
              height={200}
              placeholder={formFieldsLabel.DESCRIPTION}
              onTextChange={onFormValueChange(
                attributeTypes.DESCRIPTION,
                changeTypes.VALUE,
                attributeTypes.DESCRIPTION
              )}
              isWithMentions={true}
            />
            <EditableInputRows
              formAttributes={teamEditUtil.getLinksFormAttributes(
                formAttributes
              )}
              attributeType={attributeTypes.LINK}
              onChange={onFormValueChange}
              title={formFieldsLabel.LINK}
              placeholder={linksLabelAndPlaceholder}
              hasError={hasError}
              validateField={validateField}
            />
          </Spacer>
        </Box>
      )}
    </Modal>
  );
};

TeamDescriptionEditModal.propTypes = {
  group: PropTypes.object,
  attributes: PropTypes.arrayOf(GroupAttribute),
  isVisible: PropTypes.bool,
  onHandleTeamUpdate: PropTypes.func,
  onHideModal: PropTypes.func,
  loading: PropTypes.bool,
};

export default React.memo(TeamDescriptionEditModal);
