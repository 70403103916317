import { useCallback } from "react";
import { useDispatch } from "./context/ForecastContext";

const useNotifyStatus = (type) => {
  const dispatch = useDispatch();

  const notifyStatus = useCallback(
    (params) => {
      dispatch({
        type,
        ...params,
      });
    },
    [dispatch, type]
  );

  return notifyStatus;
};

export default useNotifyStatus;
