/* eslint-disable no-param-reassign */
import {
  reduce,
  forEach,
  values,
  isArray,
  isEmpty,
  sortBy,
  filter,
} from "lodash";

const NO_PARENT = {
  id: "NO_PARENT",
  name: "No parent linked",
};

export const hasParents = (objectives) => {
  const objectivesWithParents = filter(objectives, (objective) => {
    const { parentObjectives } = objective;
    if (parentObjectives.length > 0) {
      return true;
    }
    return false;
  });
  return objectivesWithParents.length > 0;
};

export const convertObjectives = (objectives) => {
  const flipped = reduce(
    objectives,
    (prev, objective) => {
      const { parentObjectives } = objective;

      forEach(parentObjectives, (parentObjective) => {
        const { id: parentId } = parentObjective;
        if (prev[parentId] && isArray(prev[parentId]?.objectives)) {
          prev[parentId].objectives.push(objective);
        } else {
          prev[parentId] = {
            ...parentObjective,
            objectives: [objective],
          };
        }
      });

      if (isEmpty(parentObjectives)) {
        if (prev[NO_PARENT.id] && prev[NO_PARENT.id].objectives) {
          prev[NO_PARENT.id].objectives.push(objective);
        } else {
          prev[NO_PARENT.id] = {
            ...NO_PARENT,
            objectives: [objective],
          };
        }
      }

      return prev;
    },
    {}
  );

  return sortBy(sortBy(values(flipped), "name"), ({ id }) =>
    id === NO_PARENT.id ? 1 : 0
  );
};
