import { get } from "lodash";
import { Flex } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import TagSelectorContainer from "src/components/TagsEditor/sub-components/TagSelectorContainer";
import TagsEditor from "src/components/TagsEditor/TagsEditor";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import useHandleEntityTagClick from "src/hooks/useHandleEntityTagClick";

const ReadOnlyTags = ({ tags, entityType = "group" }) => {
  const workspace = useWorkspace();
  const handleTagClick = useHandleEntityTagClick();
  const tagConfig = get(workspace, "config.tagConfig", {});

  return (
    <Flex alignItems="center" flexWrap="wrap" role="list" height="100%">
      <TagsEditor
        readOnly
        tagTypesConfig={tagConfig}
        existingTags={tags}
        onEntityTagClick={handleTagClick}
        components={{ TagSelector: TagSelectorContainer }}
        small
        entityType={entityType}
      />
    </Flex>
  );
};

ReadOnlyTags.propTypes = {
  tags: PropTypes.object.isRequired,
  entityType: PropTypes.string,
};

export default ReadOnlyTags;
