import { Table } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import getColumnConfig from "./getColumnsConfig";

const TeamStrategiesTable = ({
  data = [],
  tableConfig = {},
  initialTableState = {},
  rowFilters,
}) => {
  const columns = getColumnConfig();
  const muiTableBodyCellProps = useCallback(
    ({ row }) => ({
      sx: () => ({
        "& .name-col": {
          textWrap: "wrap",
        },
        color: !row.originalSubRows ? "grey" : "",
        fontWeight: row.depth === 0 ? 600 : row.depth === 1 ? 500 : undefined,
      }),
    }),
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      enableExpanding
      enableExpandAll
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enablePagination={false}
      getSubRows={rowFilters}
      muiTableBodyCellProps={muiTableBodyCellProps}
      {...tableConfig}
      initialState={{
        ...initialTableState,
      }}
      defaultColumn={{
        // NOTE: Don't change this. These are just explicit defaults for clarity.
        // Read the docs in ColumnConfiguration.md for more info.
        grow: 0,
        size: 0,
        minSize: 0,
        maxSize: Number.MAX_SAFE_INTEGER,
      }}
      layoutMode="grid-no-grow"
      // required styling for <tr> to vert-center header text when using grid-no-grow (i.e. display:grid)
      muiTableHeadRowProps={() => ({
        sx: {
          alignItems: "center",
        },
      })}
    />
  );
};

TeamStrategiesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  tableConfig: PropTypes.object,
  initialTableState: PropTypes.object,
  rowFilters: PropTypes.func,
};

export default TeamStrategiesTable;
