import { get } from "lodash";

import {
  GroupBaseFragment,
  GroupFragment,
} from "src/queries/fragments/groupFragment.graphql";

// eslint-disable-next-line import/prefer-default-export
export const updateCache = (proxy, result, newGroup) => {
  try {
    const parentGroupId = newGroup.parentId;
    const fragmentData = proxy.readFragment({
      id: `Group:${parentGroupId}`,
      fragment: GroupBaseFragment,
      fragmentName: "GroupBaseFragment",
    });

    if (!fragmentData) {
      return;
    }

    const newTeam = get(result, "data.team");

    const existingTeams = get(fragmentData, "childTeams", []);
    const childTeams = [
      ...existingTeams,
      { ...newTeam, isHidden: false, memberCount: 0, attributes: [] },
    ];

    proxy.writeFragment({
      id: `Group:${parentGroupId}`,
      fragment: GroupFragment,
      data: {
        ...fragmentData,
        childTeams,
      },
      fragmentName: "GroupFragment",
    });
  } catch {
    // DO NOTHING
  }
};
