import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { Box, Divider, Small, H5 } from "orcs-design-system";
import { map, get, includes, slice, size, find } from "lodash";

import { ALLOCATION_THREAD_ID } from "src/consts/comments";
import { roundFte } from "src/util/roundingStrategy";

import Pagination from "src/components/Pagination/Pagination";
import { useCommentCounts, useShowCommentModal } from "src/comments/context";
import FancyCard, {
  FancyCardHeader,
  FancyCardBody,
  FancyCardFooter,
} from "src/components/FancyCard/FancyCard";
import { EmptyColumn, CustomGrid } from "src/allocation/allocation.styled";
import SuggestionBox from "src/allocation/components/SuggestionBox";

import { PAGE_SIZE, PAGE_NEIGHBOURS } from "src/allocation/consts";

import {
  ACTIONS,
  useUserInteraction,
  useModel,
  useParentGroup,
  useAllocationProject,
  useWorkspace,
  useDispatch,
} from "../../context";
import GroupRow from "./GroupRow";

const HeadingRow = ({ showNotesIcon }) => {
  return (
    <>
      <EmptyColumn />
      <Small color="greyDark" textAlign="center">
        <strong>Allocated FTE</strong>
      </Small>
      <Small color="greyDark" textAlign="center">
        <strong>Approved FTE</strong>
      </Small>
      {showNotesIcon && (
        <Small color="greyDark" textAlign="center">
          <strong>Notes</strong>
        </Small>
      )}
      <Small color="greyDark" textAlign="center">
        <strong>Status</strong>
      </Small>
    </>
  );
};
HeadingRow.propTypes = {
  showNotesIcon: PropTypes.bool.isRequired,
};

const TotalsRow = ({
  loading,
  totalCurrentlyAllocatedFte,
  totalTargetAllocationFte,
}) => {
  return (
    <>
      <H5>TOTALS</H5>
      <H5 textAlign="center">
        {!loading && roundFte(totalCurrentlyAllocatedFte)}
      </H5>
      <H5 textAlign="center">
        {!loading && roundFte(totalTargetAllocationFte)}
      </H5>
      <Divider spanGrid />
    </>
  );
};

TotalsRow.propTypes = {
  loading: PropTypes.bool.isRequired,
  totalCurrentlyAllocatedFte: PropTypes.number.isRequired,
  totalTargetAllocationFte: PropTypes.number.isRequired,
};

const TargetGroups = () => {
  const parentGroup = useParentGroup();
  const allocationProject = useAllocationProject();
  const allocationConfig = get(useWorkspace(), "config.allocation");
  const dispatch = useDispatch();

  const {
    loading,
    backgroundRefresh,
    groupLookup,
    display: { targetGroups },
    mainData,
  } = useModel();
  const { selectedSupplyGroupId, targetGroupSearch } = useUserInteraction();
  const selectedSourceGroup = groupLookup[selectedSupplyGroupId];

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const counts = useCommentCounts();
  const showCommentModal = useShowCommentModal();

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [selectedSourceGroup]);

  const onSearch = useCallback(
    (search) => {
      if (!search || !search.value) {
        if (targetGroupSearch) {
          // clearing the search
          dispatch({
            type: ACTIONS.TARGET_GROUP_SEARCHED,
            search: null,
          });
        }
        return;
      }

      dispatch({
        type: ACTIONS.TARGET_GROUP_SEARCHED,
        search,
      });
    },
    [dispatch, targetGroupSearch]
  );

  const onShowComments = useCallback(
    (activeTargetGroup) => {
      showCommentModal({
        allocationProjectId: allocationProject.id,
        targetGroup: activeTargetGroup,
        roleGroup: selectedSourceGroup,
      });
    },
    [showCommentModal, allocationProject, selectedSourceGroup]
  );

  const onPageChanged = useCallback(
    (pagedData) => {
      const { currentPage } = pagedData;
      setCurrentPageIndex(currentPage - 1);
    },
    [setCurrentPageIndex]
  );

  const showLoading = loading && !backgroundRefresh;

  return useMemo(() => {
    const showNotesIcon = includes(
      allocationConfig.roleTypes,
      get(selectedSourceGroup, "type")
    );
    const templateColumns = showNotesIcon
      ? `auto 64px 64px 37px 40px`
      : `auto 64px 64px 40px`;

    const start = currentPageIndex * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    const visibleRows = showLoading
      ? []
      : slice(targetGroups.sortedAndSearched, start, end);
    const totalCount = size(targetGroups.sortedAndSearched);

    const header = (
      <FancyCardHeader title="Teams and Groups">
        <Box py="s">
          <SuggestionBox
            placeholder="Type team, group name or skills"
            onSearch={onSearch}
            value={targetGroupSearch}
            loadSuggestions={targetGroups.onSearchSuggestion}
          />
        </Box>
        <CustomGrid
          width="100%"
          gridTemplateColumns={templateColumns}
          gridGap="10px"
          scrollbarFix
        >
          <HeadingRow showNotesIcon={showNotesIcon} />
        </CustomGrid>
      </FancyCardHeader>
    );

    if (showLoading || !selectedSourceGroup) {
      return (
        <FancyCard>
          {header}
          <FancyCardBody />
          <FancyCardFooter />
        </FancyCard>
      );
    }

    return (
      <FancyCard>
        {header}
        <FancyCardBody>
          <Box pt="s">
            <CustomGrid
              width="100%"
              gridTemplateColumns={templateColumns}
              gridGap="10px"
            >
              <TotalsRow
                loading={showLoading}
                totalCurrentlyAllocatedFte={
                  targetGroups.totalCurrentlyAllocatedFte
                }
                totalTargetAllocationFte={targetGroups.totalTargetAllocationFte}
              />
              {map(visibleRows, (targetGroup) => (
                <Fragment key={targetGroup.groupId}>
                  <GroupRow
                    targetGroup={targetGroup}
                    showNotesIcon={showNotesIcon}
                    notesCount={get(
                      counts,
                      ALLOCATION_THREAD_ID(
                        allocationProject.id,
                        selectedSourceGroup.id,
                        targetGroup.groupId
                      )
                    )}
                    onShowComments={onShowComments}
                    parentGroup={parentGroup}
                    selectedSourceGroup={selectedSourceGroup}
                    skillsRequirement={get(
                      find(
                        mainData.macroAllocationSummaries,
                        (summary) =>
                          get(summary, "groupId") ===
                          get(targetGroup, "groupId")
                      ),
                      "note",
                      null
                    )}
                  />
                  <Divider spanGrid />
                </Fragment>
              ))}
            </CustomGrid>
          </Box>
        </FancyCardBody>
        <FancyCardFooter data-testid="target-groups-paging-footer">
          <Pagination
            key={selectedSupplyGroupId}
            totalRecords={totalCount}
            pageLimit={PAGE_SIZE}
            pageNeighbours={PAGE_NEIGHBOURS}
            onPageChanged={onPageChanged}
          />
        </FancyCardFooter>
      </FancyCard>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showLoading,
    selectedSourceGroup,
    targetGroups,
    targetGroupSearch,
    currentPageIndex,
    counts,
  ]);
};

export default TargetGroups;
