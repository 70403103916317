import { filter, includes, size } from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";

import ObjectiveItemHeader from "./ObjectiveItemHeader";
import ObjectiveItemParents from "./ObjectiveItemParents";

const ObjectiveItem = ({
  objective,
  inlineStyle,
  readOnly,
  removeObjective,
  isUpdating,
  objectives,
}) => {
  const { id, name, parentIds } = objective;
  const [isExpanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((expanded) => !expanded);
  };

  const onDelete = (e) => {
    e.stopPropagation();
    // Delete the objective with the grouping
    removeObjective(id);
  };

  const parentObjectives = filter(objectives, (o) => includes(parentIds, o.id));

  return (
    <>
      <ObjectiveItemHeader
        hasToggle={size(parentObjectives) > 0}
        isExpanded={isExpanded}
        toggleExpand={toggleExpand}
        name={name}
        readOnly={readOnly}
        onDelete={onDelete}
        isUpdating={isUpdating}
        objective={objective}
        inlineStyle={inlineStyle}
      />
      <ObjectiveItemParents
        isExpanded={isExpanded}
        parentObjectives={parentObjectives}
      />
    </>
  );
};

ObjectiveItem.propTypes = {
  objective: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  removeObjective: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  inlineStyle: PropTypes.bool,
  objectives: PropTypes.array,
};

export default ObjectiveItem;
