import { forEach, values, sortBy, isNil } from "lodash";
import { systemtheme } from "orcs-design-system";
import { fullDisplayName } from "src/util/personName";
import { numberToLocaleString } from "src/util/toLocaleString";
import { getStartDate, getEndDate, toDateRanges } from "src/util/memberships";

const itemStyles = {
  style: `
  background-color: ${systemtheme.colors.primary};
  color: ${systemtheme.colors.white};
  border-radius: ${systemtheme.radii[2]};
  border: solid 1px ${systemtheme.colors.white};
  font-size: ${systemtheme.fontSizes[0]};
  font-weight: ${systemtheme.fontWeights[2]};
  padding: ${systemtheme.space.xs} ${systemtheme.space.s};
  margin-top: -6px;
  `,
};

const totalItemStyles = {
  style: `
  background-color: ${systemtheme.colors.greyLighter};
  color: ${systemtheme.colors.greyDarkest};
  border-radius: ${systemtheme.radii[2]};
  border: solid 1px ${systemtheme.colors.white};
  font-size: ${systemtheme.fontSizes[0]};
  font-weight: ${systemtheme.fontWeights[2]};
  padding: ${systemtheme.space.xs} ${systemtheme.space.s};
  margin-top: -1px;
  `,
};

const addTotalToGroups = ({ groups, allDates, items }) => {
  const groupTotalId = "total";

  groups.unshift({
    id: groupTotalId,
    content: "<small>Total FTE:</small>",
  });

  const dateRanges = toDateRanges(allDates);

  forEach(dateRanges, (dateRange) => {
    let totalFte = 0;

    forEach(items, (item) => {
      const { start, end, fte } = item;

      if (dateRange.start >= start && dateRange.end <= end) {
        totalFte += fte;
      }
    });

    const content = `${numberToLocaleString(totalFte)} FTE`;
    items.push({
      ...dateRange,
      content,
      group: groupTotalId,
      title: content,
      align: "auto",
      ...totalItemStyles,
    });
  });
};

export const convertMemberships = (memberships) => {
  const items = [];
  const people = {};
  const allDates = [];

  forEach(memberships, (membership) => {
    const { personId, person, startDate, endDate, attributes } = membership;
    const { fte: attrFte } = attributes || {};

    if (!person) {
      return;
    }

    const fte = isNil(attrFte) ? person.fte : attrFte;

    people[personId] = {
      id: personId,
      content: fullDisplayName(person),
      person,
    };

    const start = getStartDate(startDate);
    const end = getEndDate(endDate);
    const content = `${numberToLocaleString(fte)} FTE`;

    items.push({
      start,
      end,
      content,
      group: personId,
      fte,
      align: "auto",
      ...itemStyles,
    });

    allDates.push(start, end);
  });

  const groups = sortBy(values(people), "content");

  addTotalToGroups({ groups, allDates, items });

  return { items, groups };
};

export const getPersonContainerId = (person) => {
  return `person-container-${person.aggregateId}`;
};
