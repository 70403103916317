/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Loading, Box } from "orcs-design-system";
import { map } from "lodash";

import CommentPropType from "src/custom-prop-types/comment";

import { sortComments } from "../utils";
import Comment from "./Comment";

const NotesScrollable = styled.div`
  position: relative;
  height: inherit;
  overflow: visible;
`;

const CommentList = (props) => {
  const { comments = [], loading = false } = props;
  const scrollableRef = useRef(null);
  // when list of notes updates, scroll to bottom
  useEffect(() => {
    const el = scrollableRef.current;

    if (el) {
      el.parentNode.scrollTop = el.parentNode.scrollHeight;
    }
  }, [comments]);

  const sortedComments = useMemo(() => sortComments(comments), [comments]);

  return (
    <NotesScrollable ref={scrollableRef}>
      {loading ? (
        <Box mt={4}>
          <Loading large centered />
        </Box>
      ) : (
        map(sortedComments, (comment) => (
          <Comment key={comment.id} comment={comment} isHistorical={false} />
        ))
      )}
    </NotesScrollable>
  );
};

CommentList.propTypes = {
  comments: PropTypes.arrayOf(CommentPropType),
  loading: PropTypes.bool,
};

export default CommentList;
