import { get, some } from "lodash";

const isSupplyDemandGroupType = (group, workspace) => {
  const groupTypes = get(workspace, "config.groupTypes", {});
  const groupType = get(groupTypes, group?.type, {});
  const { isSupply = false, isDemand = false } = groupType;
  return [isSupply, isDemand];
};
export default isSupplyDemandGroupType;

export const doesGroupTypeAllowToHaveSupplyGroup = (group, workspace) => {
  const groupTypes = get(workspace, "config.groupTypes", {});
  const groupType = get(groupTypes, group?.type, {});
  const childTypes = get(groupType, "childTypes", []);
  return some(childTypes, (childType) => {
    const childGroupType = get(groupTypes, childType, {});
    return childGroupType?.isSupply;
  });
};
