import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { Editor, Transforms, Range } from "slate";

import { getVisibleTagTypes } from "src/util/tags";
import { useWorkspaceTagConfig } from "src/contexts/global/WorkspaceContext";
import { TAGS_UI_COMPONENTS } from "src/consts/tags";
import MentionContext from "./MentionContext";
import { insertMention } from "./helper";
import usePagedSearchTags from "./hooks/usePagedSearchTags";

const TagMentionContextProvider = ({ children }) => {
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");
  const tagConfig = useWorkspaceTagConfig();

  const tagTypes = useMemo(() => {
    const typeIds = getVisibleTagTypes(
      tagConfig,
      TAGS_UI_COMPONENTS.SKILLS_REQUIREMENT
    );

    return typeIds;
  }, [tagConfig]);

  const { tags, loading, debouncedSearchTags } = usePagedSearchTags({
    tagTypes,
  });

  const KeyDownHandler = useCallback(
    (editor) => (event) => {
      if (target) {
        switch (event.key) {
          case "ArrowDown": {
            event.preventDefault();
            const prevIndex = index >= tags.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          }
          case "ArrowUp": {
            event.preventDefault();
            const nextIndex = index <= 0 ? tags.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          }
          case "Tab":
          case "Enter": {
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention(editor, tags[index]);
            setTarget(null);
            break;
          }
          case "Escape": {
            event.preventDefault();
            setTarget(null);
            break;
          }
          default:
            break;
        }
      }
    },
    [index, tags, target]
  );

  const ChangeHandler = (editor) => async () => {
    const { selection } = editor;

    // detect #{search} input, calculate the location of the drop-down menu and getting the text after the # as a search term
    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);
      const lineBefore = Editor.before(editor, start, { unit: "line" });
      const lineBeforeRange =
        lineBefore && Editor.range(editor, lineBefore, start);
      const lineBeforeText =
        lineBeforeRange && Editor.string(editor, lineBeforeRange);
      const hashIndex = lineBeforeText && lineBeforeText.lastIndexOf("#");
      let hashPoint;
      if (hashIndex > -1) {
        const distance = lineBeforeText.length - hashIndex;
        hashPoint = Editor.before(editor, start, {
          distance,
          unit: "offset",
        });
      }
      const beforeRange = hashPoint && Editor.range(editor, hashPoint, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^#(\w+(\s\w+)?)$/);
      const beforeMatchText = beforeMatch && beforeMatch[1];

      if (beforeMatchText && beforeMatchText.length > 1) {
        setTarget(beforeRange);
        setSearch(beforeMatchText);
        setIndex(0);
        await debouncedSearchTags({
          variables: {
            input: {
              filter: {
                tagTypes,
                searchTerm: beforeMatchText,
                includeInactiveTags: false,
              },
              limit: 20,
            },
          },
        });
        return;
      }
    }

    setTimeout(() => {
      setTarget(null);
    }, 100);
  };

  const clearMentionSearchState = useCallback(() => {
    setTarget(null);
    setSearch("");
    setIndex(0);
  }, []);

  return (
    <MentionContext.Provider
      value={{
        target,
        index,
        search,
        tags,
        loading,
        setSearch,
        KeyDownHandler,
        ChangeHandler,
        clearMentionSearchState,
      }}
    >
      {children}
    </MentionContext.Provider>
  );
};

TagMentionContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default TagMentionContextProvider;
