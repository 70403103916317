export const NODE_TYPES = {
  PERSON_NODE: "person-node",
  CREATE_PERSON_NODE: "create-person-node",
  OBJECTIVE_NODE: "objective-node",
  OBJECTIVE_HEADER_NODE: "objective-header-node",
  SHOW_MORE_OBJECTIVES_NODE: "show-more-objectives-node",
  GROUP_NODE: "group-node",
  COLLECTION_NODE: "collection-node",
  EXPAND_BUTTON_NODE: "expand-button-node",
};

export const NODE_THEMES = {
  OBJECTIVE: "objective",
  WORK: "work",
  TEAM: "team",
  MEASURE: "measure",
};

export const NODE_GQL_TYPENAMES = {
  OBJECTIVE: "TeamStrategyObjectiveNode",
  WORK: "TeamStrategyWorkNode",
  TEAM: "TeamStrategyTeamNode",
  MEASURE: "TeamStrategyMeasureNode",
};
