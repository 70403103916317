import React from "react";
import PropTypes from "prop-types";
import { Badge } from "orcs-design-system";

const AllocationStatusBadge = ({ fteAvailable }) => {
  let variant = "success";
  let text = "Fully Allocated";

  if (fteAvailable > 0) {
    variant = "warning";
    text = "Under Allocated";
  } else if (fteAvailable < 0) {
    variant = "danger";
    text = "Over Allocated";
  }

  return (
    <Badge variant={variant} ml="s">
      {text}
    </Badge>
  );
};

AllocationStatusBadge.propTypes = {
  fteAvailable: PropTypes.number,
};

export default AllocationStatusBadge;
