// TODO: Fix
/* eslint-disable no-use-before-define */
import {
  map,
  flatMap,
  reduceRight,
  filter,
  find,
  unionBy,
  isEmpty,
} from "lodash";
import numeral from "numeral";

import { fullDisplayName } from "./personName";

const NODE_RELATIONSHIP_TYPE_PERSON = "Person";
const NODE_RELATIONSHIP_TYPE_TEAM = "Team";
const NODE_RELATIONSHIP_TYPE_SOURCE_TEAM = "SourceTeam";
const NODE_RELATIONSHIP_TYPE_DIVISION_IN = "DIVISION_IN";
const NODE_RELATIONSHIP_TYPE_COMPANY_IN = "COMPANY_IN";
const NODE_RELATIONSHIP_TYPE_EMPLOYED_AS = "EMPLOYED_AS";
const NODE_RELATIONSHIP_TYPE_LOCATED_AT = "LOCATED_AT";
const NODE_RELATIONSHIP_TYPE_MANAGING = "MANAGING";
const NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION = "GROUP_ASSOCIATION";
const GLYPH_TYPE_MEMBERSHIP_COUNT = "MemberCount";
const GLYPH_TYPE_GROUP_COUNT = "GroupCount";

const GROUP_ASSOCIATION_LABEL = "Associated to";

const isSourceTeam = (type, groupTypes) => groupTypes[type]?.isSupply;

const buildCompanyNodes = ({ company }) =>
  company
    ? [
        {
          id: company,
          name: company,
          type: "Company",
          relationships: [],
        },
      ]
    : [];

const buildJobNodes = ({ jobTitle }) =>
  jobTitle
    ? [
        {
          id: jobTitle,
          name: jobTitle,
          type: "Job",
          relationships: [],
        },
      ]
    : [];

const buildLocationNodes = ({ address }) =>
  address
    ? [
        {
          id: address,
          name: address,
          type: "Location",
          relationships: [],
        },
      ]
    : [];

const buildDivisionNodes = ({ aggregateId, division }) => {
  return division
    ? [
        {
          id: division.id,
          name: division.name,
          type: "Division",
          relationships: [aggregateId],
        },
      ]
    : [];
};

const buildMemberTeamNodes = ({ memberOf = [] }, groupTypes) => {
  return map(memberOf, ({ id, name, memberCount, groupCount, type }) => {
    return {
      id,
      name,
      type: isSourceTeam(type, groupTypes)
        ? NODE_RELATIONSHIP_TYPE_SOURCE_TEAM
        : NODE_RELATIONSHIP_TYPE_TEAM,
      glyphs: buildTeamGlyphs({ memberCount, groupCount }),
      relationships: [],
    };
  });
};

const buildManagingNodes = ({ managing = [] }, groupTypes) =>
  map(managing, (person) => buildPersonNode(person, groupTypes));

const isVacant = ({ firstName, surname }) =>
  firstName &&
  firstName.match(/.*vacant.*/i) &&
  surname &&
  surname.match(/.*role.*/i);

const buildPersonNode = (person, groupTypes) => {
  const {
    aggregateId,
    jobTitle,
    company,
    address,
    division,
    managing = [],
    memberOf,
  } = person;

  const sourceTeamIds = map(
    filter(memberOf, ({ type }) => isSourceTeam(type, groupTypes)),
    "id"
  );
  const teamIds = map(
    filter(memberOf, ({ type }) => !isSourceTeam(type, groupTypes)),
    "id"
  );

  return {
    id: aggregateId,
    name: fullDisplayName(person),
    type: NODE_RELATIONSHIP_TYPE_PERSON,
    style: isVacant(person) ? "vacant" : "default",
    relationships: [
      {
        type: NODE_RELATIONSHIP_TYPE_DIVISION_IN,
        ids: [division && division.id],
      },
      {
        type: NODE_RELATIONSHIP_TYPE_COMPANY_IN,
        ids: [company],
      },
      {
        type: NODE_RELATIONSHIP_TYPE_EMPLOYED_AS,
        ids: [jobTitle],
      },
      {
        type: NODE_RELATIONSHIP_TYPE_LOCATED_AT,
        ids: [address],
      },
      {
        type: NODE_RELATIONSHIP_TYPE_MANAGING,
        ids: map(managing, "aggregateId"),
      },
      {
        type: NODE_RELATIONSHIP_TYPE_TEAM,
        ids: teamIds,
      },
      {
        type: NODE_RELATIONSHIP_TYPE_SOURCE_TEAM,
        ids: sourceTeamIds,
      },
    ],
  };
};

const getNodeType = ({ type, groupTypes }) => {
  if (type === NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION) {
    return type;
  }
  return isSourceTeam(type, groupTypes)
    ? NODE_RELATIONSHIP_TYPE_SOURCE_TEAM
    : NODE_RELATIONSHIP_TYPE_TEAM;
};

const getNodeName = ({ type, name }) => {
  if (type === NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION) {
    return `${GROUP_ASSOCIATION_LABEL}\n${name}`;
  }
  return type ? `${type}\n${name}` : name;
};

const buildTeamNodes = (
  {
    id,
    name,
    type,
    members = [],
    childTeams = [],
    descendantGroups = [],
    memberCount,
    groupCount,
    groupAssociations = [],
  } = {},
  { includeEmptyTeams = false } = {},
  groupTypes
) => {
  const associatedGroups = map(groupAssociations, (groupAssociation) => {
    return {
      ...groupAssociation.group,
      id: `association-${groupAssociation.group.id}`,
      type: NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION,
    };
  });
  const targetChildTeams = includeEmptyTeams
    ? childTeams
    : filter(childTeams, (t) => t.memberCount > 0);
  const targetDescendants = includeEmptyTeams
    ? descendantGroups
    : filter(descendantGroups, (t) => t.memberCount > 0);

  const memberRelationships = map(members, "aggregateId");
  const childTeamRelationships = map(targetChildTeams, "id");
  const associationTeamRelationships = map(associatedGroups, "id");
  const descendantTeamRelationships = targetDescendants
    .filter((dg) => dg.directParentId === id)
    .map((dg) => dg.id);

  const teamNode = {
    id,
    name: getNodeName({
      type,
      name,
    }),
    type: getNodeType({
      type,
      groupTypes,
    }),
    style: members === null ? "bigTeam" : "default",
    glyphs: buildTeamGlyphs({ memberCount, groupCount }),
    relationships: [
      {
        type: NODE_RELATIONSHIP_TYPE_MANAGING,
        ids: [
          ...memberRelationships,
          ...childTeamRelationships,
          ...descendantTeamRelationships,
        ],
      },
    ],
  };

  if (!isEmpty(associationTeamRelationships)) {
    teamNode.relationships.push({
      type: NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION,
      ids: [...associationTeamRelationships],
    });
  }

  const memberNodes = flatMap(members, buildPersonGraph);
  const childTeamNodes = flatMap(targetChildTeams, (targetChildTeam) =>
    buildTeamNodes(targetChildTeam, {}, groupTypes)
  );
  const descendantNodes = flatMap(
    targetDescendants,
    buildDescendantNodes(targetDescendants, groupTypes)
  );
  const associationNodes = flatMap(associatedGroups, (associatedGroup) =>
    buildTeamNodes(associatedGroup, {}, groupTypes)
  );
  return [
    teamNode,
    ...memberNodes,
    ...childTeamNodes,
    ...descendantNodes,
    ...associationNodes,
  ];
};

const buildTeamGlyphs = ({ memberCount, groupCount }) => {
  const glyphs = [];
  if (memberCount && memberCount > 0) {
    glyphs.push({
      type: GLYPH_TYPE_MEMBERSHIP_COUNT,
      value: `${numeral(memberCount).format("0a")}`,
    });
  }
  if (groupCount && groupCount > 0) {
    glyphs.push({
      type: GLYPH_TYPE_GROUP_COUNT,
      value: `${numeral(groupCount).format("0a")}`,
    });
  }
  return glyphs;
};

const buildDescendantNodes = (descendantGroups, groupTypes) => (group) => {
  const [teamNode, ...otherNodes] = buildTeamNodes(group, {}, groupTypes);
  teamNode.relationships[0].ids = [
    ...teamNode.relationships[0].ids,
    ...descendantGroups
      .filter((dgg) => dgg.directParentId === group.id)
      .map((dgg) => dgg.id),
  ];

  return [teamNode, ...otherNodes];
};

// TODO: use parents instead
const buildAncestorNodes = (team, groupTypes) => {
  const {
    id: initialTeamId,
    directParentId: initialParentId,
    parentGroups,
  } = team || {};

  const findGroup = (targetId) => find(parentGroups, ["id", targetId]);

  let nextParentId = initialParentId;
  let nextChildId = initialTeamId;
  const parentNodes = [];

  while (nextParentId) {
    const parent = findGroup(nextParentId);
    if (parent) {
      const { id, name, type, directParentId, memberCount, groupCount } =
        parent;
      const parentNode = {
        id,
        name: getNodeName({
          type,
          name,
        }),
        type: getNodeType({
          type,
          groupTypes,
        }),
        glyphs: buildTeamGlyphs({ memberCount, groupCount }),

        relationships: [
          {
            type: NODE_RELATIONSHIP_TYPE_MANAGING,
            ids: [nextChildId],
          },
        ],
      };
      parentNodes.push(parentNode);
      nextParentId = directParentId;
      nextChildId = id;
    } else {
      break;
    }
  }
  return parentNodes;
};

const filterNullValue = (values) => filter(values);

const createManagerNode = (person, subordinateId) => ({
  id: person.managerId,
  name: fullDisplayName(person),
  type: NODE_RELATIONSHIP_TYPE_PERSON,
  style: isVacant(person) ? "vacant" : "default",
  relationships: [
    {
      type: NODE_RELATIONSHIP_TYPE_MANAGING,
      ids: [subordinateId],
    },
  ],
});

const buildManagerHierarchyNodes = ({
  aggregateId,
  managedByHierarchy = [],
}) => {
  const managers = filterNullValue(managedByHierarchy);
  const { nodes } = reduceRight(
    managers,
    (
      { nodes: n, subordinateId },
      { aggregateId: managerId, firstName, surname }
    ) => ({
      nodes: [
        ...n,
        createManagerNode({ managerId, firstName, surname }, subordinateId),
      ],
      subordinateId: managerId,
    }),
    {
      nodes: [],
      subordinateId: aggregateId,
    }
  );
  return nodes;
};

const buildPersonGraph = (person, groupTypes) => {
  const personNode = buildPersonNode(person, groupTypes);
  const companyNodes = buildCompanyNodes(person);
  const jobNodes = buildJobNodes(person);
  const locationNodes = buildLocationNodes(person);
  const divisionNodes = buildDivisionNodes(person);
  const teamNodes = buildMemberTeamNodes(person, groupTypes);
  const managerNodes = buildManagerHierarchyNodes(person);
  const managingNodes = buildManagingNodes(person, groupTypes);

  return [
    personNode,
    ...locationNodes,
    ...companyNodes,
    ...jobNodes,
    ...divisionNodes,
    ...managerNodes,
    ...managingNodes,
    ...teamNodes,
  ];
};

const buildTeamGraph = (team, settings, groupTypes) => {
  const ancestorNodes = buildAncestorNodes(team, groupTypes);
  const teamNodes = unionBy(buildTeamNodes(team, settings, groupTypes), "id");
  // const descendantNodes = buildDescendantNodes(team);
  return [...teamNodes, ...ancestorNodes];
};

export {
  buildPersonGraph,
  buildTeamGraph,
  NODE_RELATIONSHIP_TYPE_MANAGING,
  NODE_RELATIONSHIP_TYPE_GROUP_ASSOCIATION,
  NODE_RELATIONSHIP_TYPE_TEAM,
  NODE_RELATIONSHIP_TYPE_SOURCE_TEAM,
  GROUP_ASSOCIATION_LABEL,
  getNodeType,
  getNodeName,
};
