import { useEffect } from "react";

import eventEmitter, { EVENTS } from "src/services/eventEmitter";

const useSidebarListener = (callback) => {
  useEffect(() => {
    const handler = (params) => {
      Promise.resolve().then(() => {
        callback(params);
      });
    };

    eventEmitter.on(EVENTS.SIDEBAR_STATUS_CHANGED, handler);

    return () => {
      eventEmitter.removeListener(EVENTS.SIDEBAR_STATUS_CHANGED, handler);
    };
  }, [callback]);
};

export default useSidebarListener;
