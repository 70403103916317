import React, {
  useState,
  useCallback,
  useMemo,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { createEditor, Transforms, Editor } from "slate";
import { Slate, withReact, ReactEditor } from "slate-react";
import {
  withMentions,
  useMention,
  MentionMenu,
} from "src/comments/components/RichTextEditor/plugins/mentions";

import { StyledEditor } from "src/components/RichTextEditor/RichTextEditor.styled";

// TODO: Think about where to put this initialValue...
import { getInitialValue } from "src/comments/components/RichTextEditor/RichTextEditor.config";
import { isContentEmpty } from "src/comments/components/RichTextEditor/serializer/slatePlainTextSerializer";

import ElementNode from "./ElementNode";
import LeafNode from "./LeafNode";

const TextEditorWithMention = React.forwardRef(
  (
    {
      value,
      placeholder,
      onSkillInputChange,
      saveSkillsValue,
      valid,
      inValid,
      defaultValue,
    },
    ref
  ) => {
    const [skillInput, setSkillInput] = useState(
      defaultValue || getInitialValue()
    );
    const [isActive, setActive] = useState(false);

    const { KeyDownHandler, ChangeHandler, clearMentionSearchState } =
      useMention();

    const renderElement = useCallback(
      (props) => <ElementNode {...props} />,
      []
    );
    const renderLeaf = useCallback((props) => <LeafNode {...props} />, []);

    const editor = useMemo(() => withMentions(withReact(createEditor())), []);

    useImperativeHandle(ref, () => ({
      focus: () => {
        ReactEditor.focus(editor);
      },
      getValue: () => skillInput,
    }));

    const handlerKeyDown = useMemo(
      () => KeyDownHandler(editor),
      [KeyDownHandler, editor]
    );

    const onKeyDown = useCallback(
      (event) => {
        handlerKeyDown(event);
      },
      [handlerKeyDown]
    );

    const handleChange = useMemo(
      () => ChangeHandler(editor),
      [ChangeHandler, editor]
    );

    const onChange = useCallback(
      (newValue) => {
        setSkillInput(newValue);
        handleChange(newValue);

        if (onSkillInputChange) {
          onSkillInputChange(newValue);
        }
      },
      [handleChange, onSkillInputChange]
    );

    const onEditorBlur = () => {
      clearMentionSearchState();
      Transforms.deselect(editor);
      setActive(false);

      const isEmpty = isContentEmpty(skillInput);
      const isCurrentValueEmpty = isContentEmpty(value);
      if (isEmpty && isCurrentValueEmpty) {
        setSkillInput(getInitialValue());
        return;
      }

      if (isEqual(skillInput, value)) {
        return;
      }

      if (onSkillInputChange) {
        onSkillInputChange(skillInput);
      }

      if (saveSkillsValue) {
        saveSkillsValue(skillInput);
      }
    };

    const onEditorFocus = useCallback(() => {
      setActive(true);
      Transforms.select(editor, Editor.end(editor, []));
    }, [editor]);

    return (
      <Slate editor={editor} value={skillInput} onChange={onChange}>
        <StyledEditor
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onFocus={onEditorFocus}
          onBlur={onEditorBlur}
          spellCheck
          valid={valid}
          inValid={inValid}
        />
        {isActive && <MentionMenu editor={editor} />}
      </Slate>
    );
  }
);

TextEditorWithMention.displayName = "TextEditorWithMention";

TextEditorWithMention.propTypes = {
  onSkillsChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSkillInputChange: PropTypes.func,
  saveSkillsValue: PropTypes.func,
  valid: PropTypes.bool,
  inValid: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default TextEditorWithMention;
