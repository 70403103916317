import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Button,
  Small,
  Divider,
  Popover,
  Flex,
} from "orcs-design-system";
import { isEmpty, get } from "lodash";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { getObjectiveLabel } from "src/util/objectives";
import { ACTIONS } from "../../../context/ForecastContext";
import {
  isObjectivesVisible,
  isObjectivesVerified,
} from "../../util/objectives";

const LinkObjectives = ({
  grouping,
  objectives,
  dispatch,
  readOnly,
  linkObjectiveReadOnly,
}) => {
  const workspace = useWorkspace();

  const { group, root } = grouping;

  const handleLinkObjectives = useCallback(() => {
    dispatch({
      type: ACTIONS.SHOW_LINK_OBJECTIVES_DIALOG,
      targetGroup: group,
      objectiveIds: group.objectiveIds,
      grouping,
    });
  }, [grouping, group, dispatch]);

  const strategyLabel = getObjectiveLabel(workspace);
  const isObjectivesEmpty = isEmpty(objectives);

  let hint = null;
  if (isObjectivesEmpty) {
    const type = get(grouping, "group.type", "group");
    hint = (
      <Small color="greyDark" mx="r">
        <em>{`This ${type} does not currently have any linked ${strategyLabel}. Please click the button to choose and link ${strategyLabel}.`}</em>
      </Small>
    );
  }

  if (!isObjectivesVisible(root)) {
    return null;
  }

  if (!isObjectivesEmpty && !isObjectivesVerified(grouping)) {
    return (
      <Flex p="r" pt="s" alignItems="center">
        <Button
          disabled={readOnly}
          small
          data-testid="verify-objectives-button"
          onClick={handleLinkObjectives}
        >
          {`Verify ${strategyLabel}`}
        </Button>
      </Flex>
    );
  }

  const button = (
    <Button
      disabled={linkObjectiveReadOnly.readOnly}
      small
      iconLeft
      data-testid="link-objectives-button"
      onClick={handleLinkObjectives}
    >
      <Icon icon={["fas", "plus"]} />
      {`Link ${strategyLabel}`}
    </Button>
  );

  if (linkObjectiveReadOnly.readOnly) {
    return (
      <Flex p="r" pt="s" alignItems="center">
        <Popover
          direction="right"
          text={linkObjectiveReadOnly.text}
          textAlign="center"
          width="180px"
        >
          {button}
        </Popover>
      </Flex>
    );
  }

  return (
    <>
      <Flex p="r" pt="s" alignItems="center">
        {button}
        {hint}
      </Flex>
      <Divider />
    </>
  );
};

LinkObjectives.propTypes = {
  grouping: PropTypes.object.isRequired,
  objectives: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  linkObjectiveReadOnly: PropTypes.object,
};

export default LinkObjectives;
