import { map, forEach, trim } from "lodash";
import strind from "strind";
import Fuse from "fuse.js";

export const searchOptions = {
  shouldSort: false,
  tokenize: true,
  matchAllTokens: true,
  findAllMatches: true,
  threshold: 0.0,
  location: 0,
  distance: 0,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  includeMatches: true,
  keys: ["group.name", "tags.label", "hierarchyTokens"],
};

const addHighlightedToGroup = (searchResult) => {
  const results = map(searchResult, (result) => {
    const { item, matches } = result;

    const highlighted = [];
    forEach(matches, (match) => {
      const { indices, key, value } = match;
      const output = strind(
        value,
        indices,
        ({ chars: text, matches: isHighlighted }) => ({
          text,
          isHighlighted,
        })
      );
      highlighted.push({ key, value, indices: output.matched });
    });

    return { ...item, highlighted };
  });

  return results;
};

export const search = (items, searchTerm, options) => {
  const fuse = new Fuse(items, options);
  return fuse.search(searchTerm);
};

export default (groups, teamSearch) => {
  if (teamSearch) {
    const searchTerm = trim(teamSearch.value);
    const options = {
      ...searchOptions,
      keys: teamSearch.key ? [teamSearch.key] : searchOptions.keys,
    };
    const searchResult = search(groups, searchTerm, options);
    const results = addHighlightedToGroup(searchResult);
    return results;
  }

  return groups;
};
