/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { Box } from "orcs-design-system";
import GroupTitle from "src/components/GroupTitle";

const ModalHeader = React.memo(({ supplyGroup, requestorGroup }) => {
  return (
    <>
      <Box mb="xs">
        <GroupTitle group={supplyGroup} />
      </Box>
      <GroupTitle group={requestorGroup} />
    </>
  );
});

ModalHeader.propTypes = {
  requestorGroup: PropTypes.object,
  supplyGroup: PropTypes.object,
};

export default ModalHeader;
