import React from "react";
import { H3 } from "orcs-design-system";
import PropTypes from "prop-types";
import FancyCard, {
  FancyCardHeader,
  FancyCardBody,
  FancyCardFooter,
} from "src/components/FancyCard/FancyCard";

import IndividualPersonAllocationContainer from "./IndividualPersonAllocationContainer";

const Layout = (...children) => {
  return (
    <FancyCard height="100%">
      <FancyCardHeader>
        <H3 bold>Allocations</H3>
      </FancyCardHeader>
      <FancyCardBody>
        {children[0]}
        {children[1]}
      </FancyCardBody>
      <FancyCardFooter>{children[2]}</FancyCardFooter>
    </FancyCard>
  );
};

const IndividualPersonAllocationPage = ({
  allocationProjectId,
  personId,
  personFte,
  onPersonAllocating,
  onPersonAllocated,
  onPersonUnallocated,
  onPersonAllocationUpdated,
  layoutFunc = Layout,
  withSearch,
  notifyStatus,
}) => {
  return (
    <IndividualPersonAllocationContainer
      allocationProjectId={allocationProjectId}
      personId={personId}
      personFte={personFte}
      onPersonAllocating={onPersonAllocating}
      onPersonAllocated={onPersonAllocated}
      onPersonUnallocated={onPersonUnallocated}
      onPersonAllocationUpdated={onPersonAllocationUpdated}
      layoutFunction={layoutFunc}
      withSearch={withSearch}
      notifyStatus={notifyStatus}
    />
  );
};

IndividualPersonAllocationPage.propTypes = {
  allocationProjectId: PropTypes.string,
  personId: PropTypes.string,
  personFte: PropTypes.number,
  onPersonAllocating: PropTypes.func,
  onPersonAllocated: PropTypes.func,
  onPersonUnallocated: PropTypes.func,
  onPersonAllocationUpdated: PropTypes.func,
  layoutFunc: PropTypes.func,
  withSearch: PropTypes.bool,
  notifyStatus: PropTypes.func,
};

export default IndividualPersonAllocationPage;
