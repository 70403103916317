// eslint-disable-next-line import/prefer-default-export
export const copywriting = {
  EMPLOYED_BY: "EMPLOYED BY",
  LOCATION: "LOCATION",
  REPORTS_TO: "REPORTS TO",
  NOTIFY_TEAM_CHANGES: "Suggest change",
  MEMBER_OF: "Member of",
  ACCESS_TO: "Access to",
  TAGS: "TAGS",
  WORKS_WITH: "Works with",
  EDIT_ALLOCATIONS: "Edit allocations",
  DIVISION: "DIVISION",
  DIRECT_REPORTS: "Direct reports",
  BAND: "Band",
  FULL_NAME: "Full name",
  EMPLOYEE_ID: "Employee ID",
  FTE: "FTE",
  ALLOCATE: "Allocate",
  ALLOCATED: "Allocated",
  ASSIGN: "Assign",
  ASSIGNED: "Assigned",
};
