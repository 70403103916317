import { P } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { copywriting as appCopywriting } from "src/util/appConfig";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import { useReadonlyLayoutFlags } from "src/hooks/useLayoutFlags";
import { canContinue } from "src/util/error";
import { reportError } from "src/services/errorReporting";

import MembersList from "./MembersList";

const MembersListWrapper = ({
  loading,
  peopleList,
  groupTypes,
  error,
  setIsHidePanel,
  person,
  showLocalTime = false,
  user,
  userPerson,
  featureFlags,
  workspace,
  allocationProject,
}) => {
  const {
    layoutFlags: { cardView },
  } = useReadonlyLayoutFlags();

  if (!loading) {
    if (!canContinue(error)) {
      reportError(error);
      return <P>{appCopywriting.errorQueryingResults}</P>;
    }

    if (setIsHidePanel && peopleList.length === 0) {
      setIsHidePanel(true);
      return null;
    }
  }

  return (
    <MembersList
      person={person}
      showLocalTime={showLocalTime}
      loading={loading}
      peopleList={peopleList}
      groupTypes={groupTypes}
      cardView={cardView}
      user={user}
      userPerson={userPerson}
      featureFlags={featureFlags}
      workspace={workspace}
      allocationProject={allocationProject}
    />
  );
};

MembersListWrapper.propTypes = {
  loading: PropTypes.bool,
  showLocalTime: PropTypes.bool,
  error: PropTypes.object,
  peopleList: PropTypes.array,
  groupTypes: GroupTypesPropType,
  setIsHidePanel: PropTypes.func,
  person: PropTypes.object,
  user: PropTypes.object,
  featureFlags: PropTypes.object,
  allocationProject: PropTypes.object,
  userPerson: PropTypes.object,
  workspace: PropTypes.object,
};

export default MembersListWrapper;
