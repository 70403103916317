import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";

import GroupPropType from "src/custom-prop-types/group";
import useTagsVisibleInFilter from "src/app/Restricted/useTagsVisibleInFilter";
import TeamAssociationsPanel from "src/components/TeamAssociationsPanel";

const AssociationsTabPage = ({ teamId, team, user }) => {
  const workspace = useWorkspace();
  const groupTypes = get(workspace, "config.groupTypes");
  const tagsVisibleIn = useTagsVisibleInFilter();

  return (
    <TeamAssociationsPanel
      teamId={teamId}
      team={team}
      groupTypes={groupTypes}
      user={user}
      tagsVisibleIn={tagsVisibleIn}
      workspace={workspace}
    />
  );
};
AssociationsTabPage.propTypes = {
  teamId: PropTypes.string,
  team: GroupPropType,
  user: PropTypes.object,
};
export default AssociationsTabPage;
