import React from "react";
import { withForecastContextProvider } from "./context/ForecastContext";
import withCommentContextProvider from "./context/withCommentContextProvider";
import usePageMainQuery from "./usePageMainQuery";
import usePageSecondQuery from "./usePageSecondQuery";
import useFetchGroupExtraInfo from "./useFetchGroupExtraInfo";
import useSetupInitialGroupingExpansions from "./useSetupInitialGroupingExpansions";
import useViewModeRouteUpdater from "./useViewModeRouteUpdater";
import usePersistExpandedIds from "./usePersistExpandedIds";
import useRefreshPage from "./useRefreshPage";
import usePageGridConfig from "./usePageGridConfig";
import usePageBaselineDiffQuery from "./usePageBaselineDiffQuery";
import useUpdateLineItemHierarchies from "./useUpdateLineItemHierarchies";
import useLoadIntersectingFte from "./useLoadIntersectingFte";
import ForecastPage from "./components/ForecastPage";

const ForecastPageContainer = () => {
  usePageMainQuery();
  usePageSecondQuery();
  useFetchGroupExtraInfo();
  useSetupInitialGroupingExpansions();
  useViewModeRouteUpdater();
  usePersistExpandedIds();
  useRefreshPage();
  usePageGridConfig();
  useUpdateLineItemHierarchies();
  usePageBaselineDiffQuery();
  useLoadIntersectingFte();

  return <ForecastPage />;
};

export default withForecastContextProvider(
  withCommentContextProvider(ForecastPageContainer)
);
