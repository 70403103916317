import debug from "debug";

import mainQuery from "./mainQuery";
import secondaryQuery from "./secondaryQuery";
import model from "./model";
import userInteraction from "./userInteraction";

const debugLog = debug(
  "orchestrated:approver-individual-allocation-page:context:reducer"
);

export default (state, action) => {
  debugLog(`${action.type} - received`, action);
  const newState = {
    ...state,
    mainQuery: mainQuery(state.mainQuery, action),
    secondaryQuery: secondaryQuery(state.secondaryQuery, action),
    model: model(state.model, action),
    userInteraction: userInteraction(state.userInteraction, action),
  };

  debugLog(`${action.type} - complete`, newState);
  return newState;
};
