import * as ACTIONS from "../actionTypes";

import { buildPartialModel, buildFullModel } from "./buildInitialModel";
import updatePersonAllocations from "./updatePersonAllocations";
import {
  updatePersonForSubSupplyGroup,
  updatePersonFilter,
  updatePersonSearch,
  updateTargetGroupSearch,
} from "./display";

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.MAIN_QUERY_COMPLETE: {
      const { mainData: existingMainData } = state;
      const {
        data: mainData,
        variables,
        parentGroup,
        selectedSupplyGroupId,
        selectedSubSupplyGroupId,
        personFilter,
        personSearch,
        targetGroupSearch,
      } = action;

      if (state.firstLoad && !variables.skipSupplyGroups) {
        return {
          ...state,
          mainData,
          firstLoad: false,
          ...buildPartialModel({
            parentGroup,
            mainData,
            selectedSupplyGroupId,
            selectedSubSupplyGroupId,
            personFilter,
            personSearch,
            targetGroupSearch,
          }),
        };
      }

      if (variables.skipSupplyGroups) {
        // keep existing descendantSupplyGroups and dependant data
        return {
          ...state,
          mainData: {
            ...mainData,
            descendantSupplyGroups: existingMainData.descendantSupplyGroups,
          },
        };
      }

      return {
        ...state,
        mainData,
      };
    }

    case ACTIONS.SECONDARY_QUERY_COMPLETE: {
      const {
        data: secondaryData,
        parentGroup,
        selectedSupplyGroupId,
        selectedSubSupplyGroupId,
        personFilter,
        personSearch,
        targetGroupSearch,
      } = action;
      return {
        ...state,
        loading: false,
        backgroundRefresh: false,
        secondaryData,
        ...buildFullModel({
          parentGroup,
          mainData: state.mainData,
          secondaryData,
          selectedSupplyGroupId,
          selectedSubSupplyGroupId,
          personFilter,
          personSearch,
          targetGroupSearch,
          rebuildSupplyGroupTree: false,
        }),
      };
    }

    case ACTIONS.MAIN_QUERY_REFRESH: {
      const { backgroundRefresh } = action;

      return {
        ...state,
        loading: true,
        backgroundRefresh,
      };
    }

    case ACTIONS.SUPPLY_GROUP_SELECTED: {
      // For supply group change, a query is triggered
      // the query completed event will be handled and build the model again
      return {
        ...state,
        loading: true,
        backgroundRefresh: false,
      };
    }

    case ACTIONS.SUB_SUPPLY_GROUP_SELECTED:
    case ACTIONS.SUB_SUPPLY_GROUP_CLEARED: {
      // For sub supply group change, need to filter out the members for the sub supply group
      const { selectedSubSupplyGroupId, personFilter, personSearch } = action;

      return {
        ...state,
        display: updatePersonForSubSupplyGroup(
          state,
          personFilter,
          personSearch,
          selectedSubSupplyGroupId
        ),
      };
    }

    case ACTIONS.PERSON_FILTER_SELECTED: {
      const { filter, search, subGroupId } = action;
      return {
        ...state,
        display: updatePersonFilter(state, filter, search, subGroupId),
      };
    }

    case ACTIONS.PERSON_SEARCHED: {
      const { search } = action;
      return {
        ...state,
        display: updatePersonSearch(state, search),
      };
    }

    case ACTIONS.TARGET_GROUP_SEARCHED: {
      const { search } = action;
      return {
        ...state,
        display: updateTargetGroupSearch(state, search),
      };
    }

    case ACTIONS.PERSON_ALLOCATIONS_UPDATED: {
      const {
        personId,
        individualAllocations,
        personFilter,
        personSearch,
        targetGroupSearch,
      } = action;
      return {
        ...state,
        ...updatePersonAllocations({
          model: state,
          personId,
          individualAllocations,
          personFilter,
          personSearch,
          targetGroupSearch,
        }),
      };
    }

    default:
      return state;
  }
};
