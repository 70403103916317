/* eslint-disable no-param-reassign */
import { useApolloClient } from "@apollo/client";
import { debounce, find, get, keyBy, reduce, split, without } from "lodash";
import PropTypes, { arrayOf } from "prop-types";
import React, { useCallback, useMemo, useState } from "react";

import PersonPropType from "src/custom-prop-types/person";
import ErrorNotification from "src/components/ErrorNotification";
import { fullDisplayName } from "src/util/personName";

import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";
import PersonFormatOptionLabel from "../EntitySearchDropdowns/PersonSearchDropdown/PersonFormatOptionLabel";
import EntityDropdown from "../EntitySearchDropdowns/EntityDropdown";
import { peopleSearch } from "./queries/person.graphql";

const RESULT_SIZE = 20;

const AllocationPersonSearch = ({
  targetGroupId,
  autoSearch,
  existingMembers,
  filter,
  onPersonSelected,
  groupTypes,
  disabled = false,
  excludeMembersInGroup = false,
  disableCache = false,
  children,
  allocatedOptionLabel,
  allocateOptionLabel,
  autoFocus = false,
  onBlur,
  placeHolderText = "Search to allocate a member",
  labelText = "Type to search and select a person to add to this team.",
}) => {
  const client = useApolloClient();
  const [searchString, setSearchString] = useState("");
  const [queryError, setQueryError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const getQueryVars = useCallback(
    (keywordArray = "") => {
      const params = {
        searchTerms: without(split(keywordArray, " "), "") || [keywordArray],
        size: RESULT_SIZE,
      };

      if (filter) {
        params.filter = filter;
      }
      return params;
    },
    [filter]
  );

  const getPeopleResults = useCallback(
    (result, existingAllocationsByPersonId) => {
      const { memberResult, excludedMemberCount } = reduce(
        result.people,
        (acc, person) => {
          const alreadyInGroup =
            (targetGroupId &&
              !!find(
                person.allocations,
                (a) => a.targetGroupId === targetGroupId
              )) ||
            !!existingAllocationsByPersonId[person.aggregateId];

          if (alreadyInGroup && excludeMembersInGroup) {
            acc.excludedMemberCount += 1;
            return acc;
          }
          acc.memberResult.push({
            label: fullDisplayName(person),
            value: {
              person,
              alreadyInGroup,
              memberOf: get(person, "memberOf", []),
            },
          });
          return acc;
        },
        {
          memberResult: [],
          excludedMemberCount: 0,
        }
      );

      const total = result.totalCount - excludedMemberCount;

      if (total > RESULT_SIZE) {
        const message = `${total} results found, please refine your search`;
        memberResult.push({
          label: message,
          value: { message },
          isDisabled: true,
        });
      }
      return memberResult;
    },
    [excludeMembersInGroup, targetGroupId]
  );

  const existingAllocationsById = useMemo(
    () => keyBy(existingMembers, "aggregateId"),
    [existingMembers]
  );

  const loadOptions = useMemo(
    () =>
      debounce((input, callback) => {
        setIsSearching(true);
        client
          .query({
            query: peopleSearch,
            variables: getQueryVars(input),
            fetchPolicy: disableCache ? "no-cache" : "cache-first",
          })
          .then(({ data: newData, error: newError }) => {
            if (newError) {
              setQueryError(newError);
            }

            callback(
              newData.result.people
                ? getPeopleResults(newData.result, existingAllocationsById)
                : []
            );
            setIsSearching(false);
          });
      }, DEBOUNCE_SEARCH_TIME),
    [
      client,
      getQueryVars,
      disableCache,
      getPeopleResults,
      existingAllocationsById,
    ]
  );

  const onSelectPerson = useCallback(
    (option) => {
      onPersonSelected(option ? option.value : null);
      setSearchString("");
    },
    [onPersonSelected]
  );

  return (
    <>
      {children &&
        children({
          loadOptions,
          onSelectEntity: onSelectPerson,
          options: autoSearch ? true : [],
        })}
      {!children && (
        <EntityDropdown
          ariaLabel={labelText}
          id="person-select-dropdown"
          placeholder={placeHolderText}
          isSearchable={true}
          autoFocus={autoFocus}
          isLoading={isSearching}
          options={autoSearch ? true : []}
          loadOptions={loadOptions}
          onSelectEntity={onSelectPerson}
          value={searchString}
          disabled={disabled}
          isClearable={true}
          onBlur={onBlur}
          formatOptionLabel={(params) =>
            PersonFormatOptionLabel(
              params,
              groupTypes,
              allocatedOptionLabel,
              allocateOptionLabel
            )
          }
        />
      )}
      {queryError && (
        <ErrorNotification
          message="Sorry, an error occurred, please try searching again."
          error={queryError}
        />
      )}
    </>
  );
};

AllocationPersonSearch.propTypes = {
  targetGroupId: PropTypes.string,
  autoSearch: PropTypes.bool,
  existingMembers: arrayOf(PersonPropType),
  onPersonSelected: PropTypes.func,
  onBlur: PropTypes.func,
  filter: PropTypes.object,
  groupTypes: PropTypes.object,
  disabled: PropTypes.bool,
  children: PropTypes.func,
  excludeMembersInGroup: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disableCache: PropTypes.bool,
  allocatedOptionLabel: PropTypes.string,
  allocateOptionLabel: PropTypes.string,
  placeHolderText: PropTypes.string,
  labelText: PropTypes.string,
};

export default React.memo(AllocationPersonSearch);
