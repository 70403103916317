import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";
import { get, isEmpty } from "lodash";

import {
  useGroupTypesLookup,
  useLookupData,
  ACTIONS,
} from "../../../context/ForecastContext";

const AddNewTeamButton = ({ grouping, dispatch }) => {
  const groupTypesLookup = useGroupTypesLookup();
  const { addTeamBtnLabel } = useLookupData();

  const { group } = grouping;

  const showAddNewTeamModal = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      dispatch({
        type: ACTIONS.SHOW_ADD_NEW_TEAM_DIALOG,
        parentGroup: group,
      });
    },
    [dispatch, group]
  );

  const typeConfig = get(groupTypesLookup, group.type, {});

  if (!typeConfig.canCreateChild || isEmpty(typeConfig.childTypes)) {
    return null;
  }

  return (
    <ActionsMenuItem
      data-testid="grouping-add-team-button"
      onClick={showAddNewTeamModal}
    >
      {addTeamBtnLabel || "Add team"}
    </ActionsMenuItem>
  );
};

AddNewTeamButton.propTypes = {
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default AddNewTeamButton;
