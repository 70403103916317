import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { useExpandedMemberListIds } from "../../../context/ForecastContext";
import MemberItems from "./MemberItems";
import MembersExpandHeader from "./MembersExpandHeader";

const Members = ({ grouping, dispatch, isExpanded }) => {
  const { groupId, childGroupings } = grouping;
  const expandedMemberListIds = useExpandedMemberListIds() || {};
  const isMembersExpanded = !!expandedMemberListIds[groupId];
  const hasChildGroups = !isEmpty(childGroupings);

  return (
    <>
      {hasChildGroups && (
        <MembersExpandHeader
          grouping={grouping}
          isMembersExpanded={isMembersExpanded}
          dispatch={dispatch}
        />
      )}
      <MemberItems
        grouping={grouping}
        isMembersExpanded={hasChildGroups ? isMembersExpanded : isExpanded}
      />
    </>
  );
};

Members.propTypes = {
  grouping: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool,
  dispatch: PropTypes.func,
};

export default Members;
