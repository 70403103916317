import React from "react";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDrop } from "react-dnd";
import { nanoid } from "nanoid";
import { find, isEmpty } from "lodash";
import {
  Button,
  Icon,
  P,
  Flex,
  Loading,
  Popover,
  Spacer,
} from "orcs-design-system";

import { themeGet } from "@styled-system/theme-get";
import { NotesCount } from "src/allocation/allocation.styled";
import { DND_TYPE_PEOPLE } from "src/allocation/consts";
import GroupPropType from "src/custom-prop-types/group";
import AllocationStatusIcon from "src/allocation/components/AllocationStatusIcon";
import SkillsRequirementIcon from "src/allocation/components/SkillsRequirementIcon";

import { isContentEmpty } from "src/comments/components/RichTextEditor/serializer/slatePlainTextSerializer";

import { allocateIndividualAllocation } from "src/allocation/allocation.graphql";

import { ACTIONS, useDispatch, useUserInteraction } from "../../context";

import Hierarchy from "../HierarchySearchResult";

const DropTargetView = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  ${Loading} {
    margin-left: 10px;
    flex: 0 0 auto;
  }
  :before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 11px 0;
    box-sizing: content-box;
    background: ${(props) => themeGet("colors.primary10")(props)};
    transition: ${(props) => themeGet("transition.transitionDefault")(props)};
    opacity: ${(props) => (props.isDropping ? "1" : "0")};
  }
`;

const GroupRow = ({
  targetGroup,
  parentGroup,
  selectedSourceGroup,
  showNotesIcon,
  onShowComments,
  notesCount = 0,
  skillsRequirement,
}) => {
  const {
    groupId,
    group, // can be the team or allocation object containing targetGroup
    currentlyAllocatedFte: allocatedFte,
    targetAllocationFte: targetFte,
    hierarchyTokens,
    highlighted,
  } = targetGroup;
  const dispatch = useDispatch();
  const { targetGroupSearch, personSearch, personFilter } =
    useUserInteraction();
  const [onAllocateToNewGroup, { loading }] = useMutation(
    allocateIndividualAllocation
  );

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: DND_TYPE_PEOPLE,
    drop: async (item, monitor) => {
      const dragItem = monitor.getItem();
      const { person, allocationProjectId } = dragItem;

      let { remainingFte } = person;
      if (remainingFte < 0) {
        remainingFte = 0;
      }

      const result = await onAllocateToNewGroup({
        variables: {
          allocationId: nanoid(),
          allocationProjectId,
          targetGroupId: groupId,
          personId: person.aggregateId,
          requestedFte: remainingFte,
        },
      });

      if (!result.error) {
        dispatch({
          type: ACTIONS.PERSON_ALLOCATIONS_UPDATED,
          individualAllocations: result.data.individualAllocations,
          personId: person.aggregateId,
          targetGroupSearch,
          personSearch,
          personFilter,
        });
      }

      return { groupId, result };
    },
    canDrop: (item, monitor) => {
      const dragItem = monitor.getItem();
      if (dragItem.allocations) {
        const isAlreadyAllocated = find(dragItem.allocations, {
          targetGroupId: groupId,
        });
        return !isAlreadyAllocated;
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isDropping = canDrop && isOver;

  const onDisplayNotes = () => {
    onShowComments(group);
  };

  return (
    <>
      <DropTargetView
        ref={drop}
        isDropping={isDropping}
        data-testid="targetgroup-row"
      >
        <Hierarchy
          group={group}
          parentGroup={parentGroup}
          hierarchyTokens={hierarchyTokens}
          selectedSourceGroup={selectedSourceGroup}
          highlighted={highlighted}
        />
        {loading && <Loading />}
      </DropTargetView>
      <P textAlign="center">{allocatedFte}</P>
      <P textAlign="center">{targetFte}</P>
      {showNotesIcon && (
        <div>
          <Popover
            direction="topLeft"
            width="120px"
            textAlign="center"
            text="View/Add Notes"
          >
            <Button
              ariaLabel="View/Add Notes"
              variant="ghost"
              width="32px"
              iconOnly
              onClick={onDisplayNotes}
            >
              <Icon
                icon={
                  notesCount
                    ? ["fas", "comment-alt"]
                    : ["far", "comment-alt-lines"]
                }
                color="primary"
              />
              {notesCount ? (
                <NotesCount data-testid="notes-count">{notesCount}</NotesCount>
              ) : null}
            </Button>
          </Popover>
        </div>
      )}

      <Flex alignItems="center" justifyContent="right">
        <Spacer mx="xs">
          {!isEmpty(skillsRequirement) &&
            !isContentEmpty(skillsRequirement) && (
              <SkillsRequirementIcon skills={[skillsRequirement]} />
            )}
          <AllocationStatusIcon remainingFte={targetFte - allocatedFte} />
        </Spacer>
      </Flex>
    </>
  );
};

GroupRow.propTypes = {
  targetGroup: PropTypes.shape({
    groupId: PropTypes.string,
    group: PropTypes.object,
    currentlyAllocatedFte: PropTypes.number.isRequired,
    targetAllocationFte: PropTypes.number.isRequired,
    hierarchyTokens: PropTypes.arrayOf(PropTypes.string),
    highlighted: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        indices: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
            isHighlighted: PropTypes.bool.isRequired,
          })
        ),
      })
    ),
  }).isRequired,
  parentGroup: GroupPropType.isRequired,
  selectedSourceGroup: GroupPropType.isRequired,
  onShowComments: PropTypes.func.isRequired,
  notesCount: PropTypes.number,
  showNotesIcon: PropTypes.bool.isRequired,
  skillsRequirement: PropTypes.object,
};

export default GroupRow;
