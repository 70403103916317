import { map, values, sortBy, toLower } from "lodash";

import { getName } from "src/util/person";

export const getAllocationsFromMembers = (selectedMembers) => {
  return map(values(selectedMembers), (member) => {
    const { aggregateId, fte } = member;
    return {
      personId: aggregateId,
      requestedFte: fte,
    };
  });
};

export const getSortedMembers = (memberList) =>
  sortBy(memberList, (member) => toLower(getName(member)));
