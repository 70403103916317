import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { H3, P, Box, Divider, Spacer } from "orcs-design-system";
import HistoryRecord from "./HistoryRecord";

export const RecordList = ({ historyRecords }) => {
  if (!historyRecords || !historyRecords.length) {
    return <P>No history found.</P>;
  }

  return (
    <>
      {map(historyRecords, (record) => {
        return (
          <Fragment key={record.id}>
            <HistoryRecord {...record} />
            <Divider light />
          </Fragment>
        );
      })}
    </>
  );
};

RecordList.propTypes = {
  historyRecords: PropTypes.array.isRequired,
};

const HistoryRecordList = ({ historyRecords }) => {
  return (
    <Box bg="white" borderRadius={2} boxBorder="default" shadow="default" p="r">
      <Spacer mb="r">
        <H3>History</H3>
        <RecordList historyRecords={historyRecords} />
      </Spacer>
    </Box>
  );
};

HistoryRecordList.propTypes = {
  historyRecords: PropTypes.array.isRequired,
};

export default HistoryRecordList;
