import { get, isEmpty, size } from "lodash";
import { Divider, H2 } from "orcs-design-system";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { numberToLocaleString } from "src/util/toLocaleString";
import PersonPropType from "src/custom-prop-types/person";
import WorkspacePropType from "src/custom-prop-types/workspace";

import MembersList from "./MembersList";

function VacantRolesList({
  vacantRoles,
  selectedSort,
  loading,
  team,
  groupTypes,
  newMemberId,
  selectedPersonId,
  user,
  userPerson,
  featureFlags,
  workspace,
  allocationProject,
  renderFteBadge,
  handleSelectPerson,
  selectedMembers,
  showPersonCheckbox,
  bulkEditStatus,
}) {
  const [fullListMode, setFullListMode] = useState(false);
  const toggleMembersList = () => {
    setFullListMode((flag) => !flag);
  };

  if (isEmpty(vacantRoles)) {
    return null;
  }

  const vacantRoleLabel = get(
    workspace,
    "config.allocation.vacantRoleLabel",
    "vacant role"
  );

  const vacantRoleCountDisplay = loading
    ? "-"
    : numberToLocaleString(size(vacantRoles));

  return (
    <>
      <Divider mt="r" light />
      <H2 sizing="small" mt="r" weight="light">
        {`${vacantRoleCountDisplay} ${pluralize(
          vacantRoleLabel,
          size(vacantRoles)
        )}`}
      </H2>
      <MembersList
        bulkEditStatus={bulkEditStatus}
        showPersonCheckbox={showPersonCheckbox}
        handleSelectPerson={handleSelectPerson}
        selectedMembers={selectedMembers}
        fullListMode={fullListMode}
        selectedSort={selectedSort}
        loading={loading}
        team={team}
        groupTypes={groupTypes}
        members={vacantRoles}
        newMemberId={newMemberId}
        selectedPersonId={selectedPersonId}
        toggleMembersList={toggleMembersList}
        user={user}
        userPerson={userPerson}
        featureFlags={featureFlags}
        workspace={workspace}
        allocationProject={allocationProject}
        renderFteBadge={renderFteBadge}
      />
    </>
  );
}

VacantRolesList.propTypes = {
  selectedSort: PropTypes.object,
  loading: PropTypes.bool,
  team: PropTypes.object,
  groupTypes: PropTypes.object,
  vacantRoles: PropTypes.array,
  newMemberId: PropTypes.array,
  selectedPersonId: PropTypes.string,
  user: PropTypes.object,
  userPerson: PersonPropType,
  workspace: WorkspacePropType,
  featureFlags: PropTypes.object,
  allocationProject: PropTypes.object,
  renderFteBadge: PropTypes.func,
  handleSelectPerson: PropTypes.func,
  selectedMembers: PropTypes.object,
  showPersonCheckbox: PropTypes.bool,
  bulkEditStatus: PropTypes.object,
};

export default VacantRolesList;
