import React, { useMemo } from "react";
import { Badge, Box, Flex, FlexItem, H4 } from "orcs-design-system";
import styled, { css } from "styled-components";
import themeGet from "@styled-system/theme-get";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import colorToLuminance from "src/util/colorToLuminance";
import { NODE_THEMES } from "../../consts";
import GroupTypeBadge from "../../../GroupTypeBadge";
import { useObjectiveState } from "../../contexts/ObjectiveProviderContext";
import { objectiveNodeDataPropType } from "../propTypes";
import { RoundIconButton } from "../RoundIconButton";
import NodeTeamInfo from "./NodeTeamInfo";
import DatasourceIcon from "./DatasourceIcon";
import { THEME } from "./node.styled";

const NodeType = styled(H4)(({ $theme }) => {
  return css`
    color: ${themeGet(`colors.${$theme?.color}`)};
    text-transform: capitalize;
  `;
});

const StyledBadge = styled(Badge)(
  ({ statusColor }) => css`
    background-color: ${statusColor};
    color: ${colorToLuminance(statusColor) > 0.65 ? "black" : "white"};
  `
);

const NodeTitleSection = ({ data }) => {
  const { nodeType, status, statusColor } = data;
  const { pinnedObjectives } = useObjectiveState();

  const isPinned = useMemo(() => {
    return pinnedObjectives?.find(
      (pinnedObjective) => pinnedObjective.id === data.id
    );
  }, [pinnedObjectives, data.id]);

  const groupTypes = useGroupTypes();

  const theme = THEME[nodeType];
  const isTeamTypeNode = nodeType === NODE_THEMES.TEAM;

  const pinButtonTooltipText = isPinned
    ? "Unpin from canvas"
    : "Pin to canvas (all linked items will be highlighted)";

  return (
    <Box width="100%" mb="r">
      {!isTeamTypeNode && (
        <Flex>
          <NodeType
            $theme={theme}
            className="type"
            sizing="small"
            weight="bold"
            mb="r"
          >
            {data.type}
          </NodeType>
          {status && (
            <FlexItem>
              <StyledBadge ml="r" statusColor={statusColor}>
                {status}
              </StyledBadge>
            </FlexItem>
          )}

          {!isTeamTypeNode && (
            <Box ml="auto">
              <RoundIconButton
                size="lg"
                iconSize="3x"
                icon={["far", "thumbtack"]}
                className={isPinned ? "pinned" : "unpinned"}
                tooltip={pinButtonTooltipText}
                onClick={() => {}}
              />
            </Box>
          )}
        </Flex>
      )}

      <Flex mb="r" alignItems="center" gap="s">
        <DatasourceIcon data={data} />

        <H4 sizing="small" weight="bold">
          {data.name}
        </H4>

        {isTeamTypeNode && (
          <GroupTypeBadge group={data} groupTypes={groupTypes} />
        )}
      </Flex>

      {!!data.teams?.length && !isTeamTypeNode && (
        <NodeTeamInfo team={data.teams[0]} />
      )}
    </Box>
  );
};

NodeTitleSection.propTypes = {
  data: objectiveNodeDataPropType,
};

export default NodeTitleSection;
