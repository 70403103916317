import React, { useMemo, useState } from "react";
import {
  Box,
  ButtonGroupContainer,
  ButtonGroupItem,
  Flex,
  Grid,
  GridItem,
  Icon,
  Loading,
  Notification,
  StyledLink,
} from "orcs-design-system";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { filter, find, get, isEmpty, map } from "lodash";
import GroupPropType from "src/custom-prop-types/group";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import RichTextContent from "src/components/RichTextContent";
import { DEFAULT_STRATEGY_PLACEHOLDER_TEXT } from "src/allocation/consts";
import icons from "src/config/icons";
import { getTeamStrategyQuery } from "./getTeamStrategyQuery";
import StrategyVisualizer from "./StrategyVisualizer";

const StyledTeamStrategyTabBox = styled(Box)`
  position: relative;
  z-index: 1;
`;

const StyledSwitcherBox = styled(Box)`
  position: relative;
  ${({ viewMode }) =>
    viewMode === "visualizer"
      ? "box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.08);"
      : ""}

  transition: all 0.5s;

  &:first-child {
    left: ${themeGet("space.r")};
    top: ${themeGet("space.r")};
    position: absolute;
    z-index: 100;
  }
`;

const TeamStrategyTab = ({ team }) => {
  const workspace = useWorkspace();

  const { data, loading } = useQuery(getTeamStrategyQuery, {
    variables: { teamId: team.id },
    skip: isEmpty(team),
    fetchPolicy: "network-only",
  });

  const objectives = useMemo(() => data?.getTeamStrategy?.nodes || [], [data]);

  const views = useMemo(() => {
    return [
      {
        label: "Visualizer",
        value: "visualizer",
        renderView: () => (
          <Box key={team.id} borderRadius={2} bg="greyLightest" height="100%">
            <StrategyVisualizer objectives={objectives} key={team.id} />
          </Box>
        ),
      },
    ];
  }, [team, objectives]);

  const [selectedViewValue, setSelectedViewValue] = useState(views[0].value);

  const infoText = useMemo(
    () => get(workspace, "config.allocation.strategyInfoBannerText", ""),
    [workspace]
  );

  // not done loading team/objectives (can happen when loading directly into strategy tab)
  // (prevents rendering a flash of the wrong content)
  if (isEmpty(team) || loading) {
    return (
      <Box p="xl" boxBorder="default" borderRadius={2} bg="white" height="100%">
        <Flex alignItems="center" justifyContent="center" height="100%">
          <Loading large />
        </Flex>
      </Box>
    );
  }

  // this team has no objectives?
  if (isEmpty(objectives)) {
    const placeholderText = get(
      workspace,
      "config.allocation.strategyPlaceholderText",
      DEFAULT_STRATEGY_PLACEHOLDER_TEXT
    );
    const placeholderLinks = filter(
      get(workspace, "config.allocation.strategyPlaceholderLinks", []),
      (link) => link.url && link.label && !link.error
    );

    return (
      <Box p="xl" boxBorder="default" borderRadius={2} bg="white" height="100%">
        <Grid>
          <GridItem>
            <RichTextContent content={placeholderText} />
          </GridItem>
          {map(placeholderLinks, (link) => (
            <GridItem mt="r">
              <StyledLink href={link.url} target="_blank">
                {link.label}
              </StyledLink>
            </GridItem>
          ))}
        </Grid>
      </Box>
    );
  }

  const selectedView = find(views, { value: selectedViewValue });

  // this team has objectives
  return (
    <>
      {!isEmpty(infoText) && (
        <Notification mb="s">
          <Icon icon={icons.infoCircle} size="lg" mr="s" />
          {infoText}
        </Notification>
      )}
      <StyledTeamStrategyTabBox
        bg="white"
        boxBorder="default"
        borderRadius={2}
        height={selectedView.value === views[0].value ? "100%" : "fit-content"}
      >
        <StyledSwitcherBox
          bg="white"
          p="s"
          borderRadius={2}
          viewMode={selectedView.value}
        >
          <ButtonGroupContainer controlLabel="View:" labelDark>
            {views.map((tab) => (
              <ButtonGroupItem
                key={tab.value}
                name={tab.value}
                label={tab.label}
                value={tab.value}
                checked={tab.value === selectedView.value}
                onChange={() => {
                  setSelectedViewValue(tab.value);
                  trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
                    setting: "strategy_view_mode",
                    value: tab.value,
                  });
                }}
              />
            ))}
          </ButtonGroupContainer>
        </StyledSwitcherBox>
        {selectedView.renderView()}
      </StyledTeamStrategyTabBox>
    </>
  );
};

TeamStrategyTab.propTypes = {
  team: GroupPropType.isRequired,
};

export default TeamStrategyTab;
