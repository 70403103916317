import { useMutation } from "@apollo/client";
import { get, trim, map } from "lodash";
import { Box, Select } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect, useMemo } from "react";

import { NOOP } from "src/consts/shared";
import { ILLEGAL_TEAM_NAME_CHARACTERS } from "src/consts/team";
import ErrorNotification from "src/components/ErrorNotification";
import { validateTeamName } from "src/components/AddNewTeamModal/AddNewTeamModal.util";
import { getTeamChildTeamsQuery } from "src/queries/group.graphql";
import { createTeam as createTeamMutation } from "src/queries/mutations/group.graphql";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";

import FormatGroupHeading from "./FormatGroupHeading";
import FormatOptionLabel from "./FormatOptionLabel";

const NewGroupPlaceholder = "Create new team";
const InputNamePlaceholder = "Type new team name";

const NewChildBox = ({
  parentGroup,
  groupTypes,
  onAddingNewGroup,
  onNewGroupAdded,
}) => {
  const [placeholder, setPlaceholder] = useState(NewGroupPlaceholder);
  const [childName, setChildName] = useState("");
  const [messageFunction, setMessageFunction] = useState(NOOP);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [error, setError] = useState(null);

  const typeOptions = useMemo(() => {
    const type = get(parentGroup, "type");
    const childTypes = get(groupTypes[type], "childTypes", []);

    return map(childTypes, (t) => ({
      value: groupTypes[t],
      label: t,
    }));
  }, [parentGroup, groupTypes]);

  useEffect(() => {
    setPlaceholder(NewGroupPlaceholder);
    setChildName("");
  }, [parentGroup]);

  const setValidationError = useCallback((errorMsg) => {
    setMessageFunction(() => () => errorMsg);
  }, []);

  const [createTeam] = useMutation(createTeamMutation, {
    refetchQueries: [getTeamChildTeamsQuery],
  });

  const onInputChange = useCallback((str, { action }) => {
    if (action === "input-change") {
      setChildName(str);

      if (!str) {
        setDefaultOptions([]);
      }
    }

    return str;
  }, []);

  const onInputFocus = useCallback(() => {
    setPlaceholder(InputNamePlaceholder);
    validateTeamName({ name: childName }, parentGroup, setValidationError);
  }, [parentGroup, setValidationError, childName]);

  const onInputBlur = useCallback(() => {
    setPlaceholder(NewGroupPlaceholder);
  }, []);

  const onSelectChildType = useCallback(
    async ({ value }) => {
      if (!childName || !value || !value.id) {
        return;
      }

      const name = trim(childName.replace(ILLEGAL_TEAM_NAME_CHARACTERS, ""));
      const newGroup = {
        name,
        type: value.id,
        parentId: parentGroup.id,
      };

      setError(null);
      onAddingNewGroup(true);
      try {
        await createTeam({
          variables: newGroup,
          update: (proxy, result) => {
            // Update component state
            onAddingNewGroup(false);
            setDefaultOptions([]);
            setChildName("");

            const addedGroup = get(result, "data.team");
            // Notify new group added
            eventEmitter.emit(EVENTS.NEW_GROUP_ADDED, addedGroup);
            onNewGroupAdded(addedGroup);
          },
        });
      } catch (e) {
        onAddingNewGroup(false);
        setError(e);
      }
    },
    [childName, createTeam, onAddingNewGroup, onNewGroupAdded, parentGroup.id]
  );

  const loadTypes = useCallback(
    (input, callback) => {
      const name = trim(input.replace(ILLEGAL_TEAM_NAME_CHARACTERS, ""));
      const isValid = validateTeamName(
        { name },
        parentGroup,
        setValidationError
      );

      if (!isValid) {
        setDefaultOptions([]);
        callback([]);
        return;
      }

      const options = [
        {
          label: (
            <>
              What team type would you like <strong>{name}</strong> to be?
            </>
          ),
          options: typeOptions,
        },
      ];

      setDefaultOptions(options);
      callback(options);
    },
    [typeOptions, parentGroup, setValidationError]
  );

  return (
    <Box data-testid="cp-add-new-child-select">
      <Select
        name="create-child-box"
        inputId="create-child-box"
        placeholder={placeholder}
        value={null}
        defaultOptions={defaultOptions}
        inputValue={childName}
        onInputChange={onInputChange}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onChange={onSelectChildType}
        loadOptions={loadTypes}
        noOptionsMessage={messageFunction}
        formatOptionLabel={FormatOptionLabel}
        formatGroupLabel={FormatGroupHeading}
        ariaLabel="Type team name and select team type to create new team"
        selectType="async"
      />
      {error && <ErrorNotification message={error.message} error={error} />}
    </Box>
  );
};

NewChildBox.propTypes = {
  parentGroup: PropTypes.object,
  groupTypes: PropTypes.object,
  onAddingNewGroup: PropTypes.func,
  onNewGroupAdded: PropTypes.func,
};

export default React.memo(NewChildBox);
