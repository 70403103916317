import React from "react";
import PropTypes from "prop-types";

const Leaf = ({ attributes, children, leaf }) => {
  let component = children;

  if (leaf.bold) {
    component = <strong>{component}</strong>;
  }

  // if (leaf.code) {
  //   children = <code>{children}</code>;
  // }

  if (leaf.italic) {
    component = <em>{component}</em>;
  }

  if (leaf.underline) {
    component = <u>{component}</u>;
  }

  return <span {...attributes}>{component}</span>;
};

Leaf.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  leaf: PropTypes.object,
};

export default Leaf;
