import PropTypes from "prop-types";
import React from "react";

import PersonItem from "src/components/Person/PersonItem";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PersonPropType from "src/custom-prop-types/person";
import { getSupplyBadgeGroups } from "src/util/group";

const RelatedPerson = ({
  person,
  groupTypes,
  isLastItem,
  cardView,
  showLocalTime = false,
  user,
  userPerson,
  featureFlags,
  workspace,
  allocationProject,
}) => {
  const badgeGroups = getSupplyBadgeGroups({
    person,
    groupTypes,
  });

  return (
    <PersonItem
      key={person.aggregateId}
      person={person}
      badgeGroups={badgeGroups}
      groupTypes={groupTypes}
      isLastItem={isLastItem}
      cardView={cardView}
      showLocalTime={showLocalTime}
      user={user}
      userPerson={userPerson}
      featureFlags={featureFlags}
      workspace={workspace}
      allocationProject={allocationProject}
    />
  );
};

RelatedPerson.propTypes = {
  person: PersonPropType,
  cardView: PropTypes.bool,
  groupTypes: GroupTypesPropType,
  isLastItem: PropTypes.bool,
  showLocalTime: PropTypes.bool,
  user: PropTypes.object,
  featureFlags: PropTypes.object,
  allocationProject: PropTypes.object,
  userPerson: PropTypes.object,
  workspace: PropTypes.object,
};

export default RelatedPerson;
