import React, { useMemo, useEffect, useState, useCallback } from "react";
import { map, size, slice } from "lodash";
import styled from "styled-components";

import { Select, Flex, FlexItem } from "orcs-design-system";

import {
  OVER_ALLOCATED,
  UNDER_ALLOCATED,
  PAGE_SIZE,
  PAGE_NEIGHBOURS,
} from "src/allocation/consts";
import FancyCard, {
  FancyCardHeader,
  FancyCardBody,
  FancyCardFooter,
} from "src/components/FancyCard/FancyCard";
import LoadingPage from "src/components/LoadingPage";
import Pagination from "src/components/Pagination/Pagination";
import { getOptionByValue } from "src/util/selectUtil";

import SuggestionBox from "src/allocation/components/SuggestionBox";
import {
  ACTIONS,
  useParentGroup,
  useAllocationProject,
  useDispatch,
  useModel,
  useUserInteraction,
} from "../../context";

import PeopleCardTitle from "./PeopleCardTitle";
import Row from "./Row";

const CAPACITY_OPTIONS = [
  { label: "Show all people", value: null },
  { label: "Show over-allocated", value: OVER_ALLOCATED },
  { label: "Show under-allocated", value: UNDER_ALLOCATED },
];

const CustomSelect = styled(Select)`
  width: 200px;
  font-size: 1.4rem;
  margin-top: 0 !important;
  .Select-control {
    height: 40px;
  }
  .Select-placeholder,
  .Select-value-label {
    line-height: 38px;
  }
`;

const People = () => {
  const parentGroup = useParentGroup();
  const allocationProject = useAllocationProject();
  const dispatch = useDispatch();
  const {
    loading,
    backgroundRefresh,
    groupLookup,
    display: { members },
  } = useModel();
  const {
    selectedSupplyGroupId,
    selectedSubSupplyGroupId,
    personSearch,
    personFilter,
  } = useUserInteraction();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const selectedSourceGroupId =
    selectedSubSupplyGroupId || selectedSupplyGroupId;
  const selectedSourceGroup = groupLookup[selectedSourceGroupId];

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [selectedSourceGroupId]);

  const onSearch = useCallback(
    (search) => {
      if (!search || !search.value) {
        if (personSearch) {
          // clearing the search
          dispatch({
            type: ACTIONS.PERSON_SEARCHED,
            search: null,
          });
        }
        return;
      }

      dispatch({
        type: ACTIONS.PERSON_SEARCHED,
        search,
      });
    },
    [dispatch, personSearch]
  );

  const onFilterChange = useCallback(
    ({ value } = {}) => {
      if (value !== personFilter) {
        dispatch({
          type: ACTIONS.PERSON_FILTER_SELECTED,
          filter: value,
          search: personSearch,
          subGroupId: selectedSubSupplyGroupId,
        });
        setCurrentPageIndex(0);
      }
    },
    [dispatch, personFilter, personSearch, selectedSubSupplyGroupId]
  );

  const onPageChanged = useCallback(
    (pagedData) => {
      const { currentPage } = pagedData;
      setCurrentPageIndex(currentPage - 1);
    },
    [setCurrentPageIndex]
  );

  const showLoading = loading && !backgroundRefresh;

  // don't re-render unless members, or page index changes
  return useMemo(() => {
    const start = currentPageIndex * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    const visibleMembers = showLoading
      ? []
      : slice(members.sortedFilteredAndSearched, start, end);
    const totalCount = size(members.sortedFilteredAndSearched);

    return (
      <FancyCard>
        <FancyCardHeader>
          <PeopleCardTitle
            loading={showLoading}
            totalCount={totalCount}
            selectedSourceGroup={selectedSourceGroup}
            totalFte={members.totalFte}
          />
          <Flex
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mt="s"
          >
            <FlexItem flex="1 1 auto" mr="s">
              <SuggestionBox
                placeholder="Search for people, teams, skills"
                value={personSearch}
                loadSuggestions={members.onSearchSuggestion}
                onSearch={onSearch}
              />
            </FlexItem>
            <FlexItem flex="0 0 auto">
              <CustomSelect
                data-testid="person-filter"
                options={CAPACITY_OPTIONS}
                value={getOptionByValue(CAPACITY_OPTIONS, personFilter)}
                onChange={onFilterChange}
                isSearchable={false}
                ariaLabel="Filter selection"
              />
            </FlexItem>
          </Flex>
        </FancyCardHeader>
        <FancyCardBody>
          {showLoading && <LoadingPage />}
          {map(visibleMembers, (member) => (
            <Row
              key={member.aggregateId}
              allocationProjectId={allocationProject.id}
              group={parentGroup}
              person={member}
              allocations={member.allocations}
            />
          ))}
        </FancyCardBody>
        <FancyCardFooter data-testid="people-paging-footer">
          {!showLoading && (
            <Pagination
              key={selectedSourceGroupId}
              totalRecords={totalCount}
              pageLimit={PAGE_SIZE}
              pageNeighbours={PAGE_NEIGHBOURS}
              onPageChanged={onPageChanged}
            />
          )}
        </FancyCardFooter>
      </FancyCard>
    );
  }, [showLoading, members, currentPageIndex, personSearch, personFilter]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default People;
