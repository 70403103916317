import React from "react";
import PropTypes from "prop-types";

import MembersInRange from "../../Shared/MembersInRange";
import Members from "../../Shared/Members";

const LineItemMembers = ({
  lineItem,
  dispatch,
  withExtraColumn,
  showMemberTags,
  showIntersectingFteSum,
}) => {
  if (showIntersectingFteSum) {
    return (
      <MembersInRange lineItem={lineItem} withExtraColumn={withExtraColumn} />
    );
  }

  return (
    <Members
      lineItem={lineItem}
      isForecast
      withExtraColumn={withExtraColumn}
      showMemberTags={showMemberTags}
      dispatch={dispatch}
    />
  );
};
LineItemMembers.propTypes = {
  lineItem: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  withExtraColumn: PropTypes.bool,
  showMemberTags: PropTypes.bool,
  showIntersectingFteSum: PropTypes.bool,
};

export default LineItemMembers;
