/* eslint-disable import/prefer-default-export */
import {
  get,
  values,
  map,
  flatMap,
  filter,
  includes,
  sumBy,
  isNil,
} from "lodash";

import { isWithinHierarchy } from "src/allocation/util/group";

const isTargetGroupActive = (allocation) => {
  return !allocation?.targetGroup?.isHidden;
};

export const sourceGroupsWithCalculatedFtes = (
  sourceGroups,
  selectedGroup,
  currentSourceGroupFtes,
  macroAllocationTargets,
  membersWithMergedAllocations
) =>
  filter(
    map(sourceGroups, (sourceGroup) => ({
      ...sourceGroup,
      allocatedFte: sumBy(
        filter(
          flatMap(
            filter(values(membersWithMergedAllocations), (m) =>
              includes(get(m, "person.sourceGroupIds"), sourceGroup.id)
            ),
            "mergedAllocations"
          ),
          (allocation) =>
            isTargetGroupActive(allocation) &&
            isWithinHierarchy(
              selectedGroup.hierarchyIds,
              get(allocation, "targetGroup.hierarchyIds")
            )
        ),
        "fte"
      ),
      targetFte: sumBy(
        filter(
          macroAllocationTargets,
          (macroAllocation) =>
            isTargetGroupActive(macroAllocation) &&
            isWithinHierarchy(
              selectedGroup.hierarchyIds,
              get(macroAllocation, "targetGroup.hierarchyIds")
            ) &&
            sourceGroup.id === macroAllocation.sourceGroupId
        ),
        (ma) =>
          !isNil(ma.approvalSubmitted)
            ? ma.approvalSubmitted
            : ma.allocatedMemberFte
      ),
      note: map(
        filter(
          macroAllocationTargets,
          (macroAllocation) =>
            isTargetGroupActive(macroAllocation) &&
            isWithinHierarchy(
              selectedGroup.hierarchyIds,
              get(macroAllocation, "targetGroup.hierarchyIds")
            ) &&
            sourceGroup.id === macroAllocation.sourceGroupId
        ),
        "note"
      ),
    })),
    (sourceGroup) =>
      !(sourceGroup.allocatedFte === 0 && sourceGroup.targetFte === 0)
  );
