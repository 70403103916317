import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Checkbox, Flex, FlexItem, Icon } from "orcs-design-system";
import styled, { css } from "styled-components";
import themeGet from "@styled-system/theme-get";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import GroupHierarchyQueryBreadcrumb from "src/components/GroupHierarchyBreadcrumb/GroupHierarchyQueryBreadcrumb";

const FlexItemWithWhiteIcon = styled(FlexItem)`
  padding: 0 ${(props) => (props.withLeftSpace ? "4px" : "8px")};
`;

const Wrapper = styled(Box)`
  width: 100%;
  position: relative;
  margin-right: ${themeGet("space.s")};
  &:hover {
    &:before {
      content: "";
      background-color: ${themeGet("colors.primary10")};
      width: 100%;
      position: absolute;
      height: calc(100% + 12px);
      width: 2000px;
      top: -6px;
      left: -1000px;
    }
  }
  ${(props) =>
    props.active
      ? css`
          &:hover {
            &:before {
              background-color: ${themeGet("colors.primary10")};
            }
          }
          &:before {
            content: "";
            background-color: ${themeGet("colors.primary10")};
            width: 100%;
            position: absolute;
            height: calc(100% + 12px);
            width: 2000px;
            top: -6px;
            left: -1000px;
          }
        `
      : css``};
`;

const CustomTreeHeader = ({
  node,
  selectedTargets,
  onNodeChecked,
  refreshTree,
  showHierarchyBreadcrumb = false,
  active = false,
  ignoreDisableGroup,
  hideCheckbox,
  isDisabled,
  withLeftSpace,
}) => {
  const { id, name, config, group, children } = node;

  // Avoid the click on Checkbox triggering toggle in the tree
  const onWrapperClick = (e) => {
    e.stopPropagation();
  };

  const onSelectChange = (e) => {
    onNodeChecked(node, e.target.checked, refreshTree);
  };

  const hasChildren = !!children;

  const label = useMemo(() => {
    if (ignoreDisableGroup) return name;
    return isDisabled ? `${name} (already added)` : name;
  }, [isDisabled, name, ignoreDisableGroup]);

  return (
    <Wrapper
      onClick={onWrapperClick}
      width="100%"
      ml={withLeftSpace ? "xs" : undefined}
      active={active}
      data-id={`tree-node-${node.id}`}
    >
      {!config?.isHybrid ? (
        <Flex alignItems="center" justifyContent="flex-start">
          {!showHierarchyBreadcrumb && !hasChildren && (
            <FlexItemWithWhiteIcon withLeftSpace={withLeftSpace}>
              <Icon icon={["fal", "chevron-right"]} size="1x" color="white" />
            </FlexItemWithWhiteIcon>
          )}
          <FlexItem flex="0 0 70%">
            <Flex alignItems="center" justifyContent="flex-start">
              {showHierarchyBreadcrumb && (
                <FlexItem mr="s" data-testid="hierarchy-breadcrumb">
                  <GroupHierarchyQueryBreadcrumb
                    group={group}
                    showTeamName={false}
                  />
                </FlexItem>
              )}
              {hideCheckbox ? (
                <span>{label}</span>
              ) : (
                <Checkbox
                  label={label}
                  checked={selectedTargets[id]}
                  onChange={onSelectChange}
                  disabled={ignoreDisableGroup ? false : isDisabled}
                />
              )}
            </Flex>
          </FlexItem>
          <FlexItem ml="auto">
            <GroupTypeBadge group={group} />
          </FlexItem>
        </Flex>
      ) : (
        name
      )}
    </Wrapper>
  );
};

CustomTreeHeader.propTypes = {
  node: PropTypes.object,
  selectedTargets: PropTypes.object,
  onNodeChecked: PropTypes.func,
  refreshTree: PropTypes.func,
  showHierarchyBreadcrumb: PropTypes.bool,
  active: PropTypes.bool,
  ignoreDisableGroup: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
  isDisabled: PropTypes.bool,
  withLeftSpace: PropTypes.bool,
};

export default CustomTreeHeader;
