import { Small, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Icons from "src/config/icons";
import StyledButton from "./StyledButton";

const StyledExpandButton = styled(StyledButton)`
  margin-top: 16px;
`;

const ExpandListButton = ({ onClick }) => {
  return (
    <StyledExpandButton onClick={onClick} data-testid="expand-btn">
      <Small mr="xs">Expand</Small>
      <Icon icon={Icons.chevronDown} />
    </StyledExpandButton>
  );
};

ExpandListButton.propTypes = {
  onClick: PropTypes.func,
};

export default ExpandListButton;
