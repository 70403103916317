import Fuse from "fuse.js";
import { isEmpty, reduce, forEach, values, slice, filter } from "lodash";

const MAX_OPTIONS_LENGTH = 15;

export const searchOptions = {
  shouldSort: false,
  tokenize: true,
  matchAllTokens: true,
  findAllMatches: true,
  threshold: 0.0,
  location: 0,
  distance: 0,
  maxPatternLength: 32,
  minMatchCharLength: 1,
};

export const search = (items, searchTerm, options) => {
  const fuse = new Fuse(items, options);
  return fuse.search(searchTerm);
};

export const getGroupTypeFilters = (allocationConfig = {}, groupTypes = {}) => {
  return filter(
    allocationConfig?.targetTypes,
    (groupType) => !groupTypes[groupType]?.disableToHaveDirectReports
  );
};

const createOptionsFromMatches = (results, keyTypes) => {
  const options = reduce(
    slice(results, 0, MAX_OPTIONS_LENGTH),
    (prev, r) => {
      const { matches } = r;

      if (!isEmpty(matches)) {
        forEach(matches, (m) => {
          const { key, value } = m;
          if (!prev[value]) {
            // eslint-disable-next-line no-param-reassign
            prev[value] = {
              label: value,
              value,
              key,
              type: keyTypes[key] || key,
            };
          }
        });
      }

      return prev;
    },
    {}
  );

  return options;
};

export const createSearchMethod = (items, keys, keyTypes = {}) => {
  const customOptions = {
    ...searchOptions,
    includeMatches: true,
    keys,
  };

  return (searchTerm) => {
    const results = search(items, searchTerm, customOptions);
    if (!results || !results.length) {
      return [];
    }

    const options = createOptionsFromMatches(results, keyTypes);

    return values(options);
  };
};
