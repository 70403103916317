import { get } from "lodash";
import { Box, Divider, Notification, Checkbox, Flex } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import PersonAllocationsModal from "src/components/Person/AllocationsModal";
import { getPersonItemRowId } from "src/components/Person/PersonItem/PersonItem.util";
import ReportTeamChange from "src/components/Person/ReportTeamChange";
import { useModal } from "src/contexts/withModal";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PersonPropType from "src/custom-prop-types/person";
import WorkspacePropType from "src/custom-prop-types/workspace";
import { ADD_PERSON_TO_TEAM_SUCCESS_MESSAGE } from "src/consts/tags";
import { STATUS } from "src/components/BulkMoveMembersModal/consts";
import { isRealTimeAllocationsEnabled } from "src/util/allocations";
import { isSuggestChangeEnabled } from "src/util/featureFlags";
import { isGroupActive } from "src/util/group";
import { getName } from "src/util/person";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";

import PersonAvatarAndDetails from "./sub-components/PersonAvatarAndDetails";

const PersonListItem = ({
  isDisplayActionsButton = true,
  person,
  badgeGroups,
  groupTypes,
  group,
  highlighted,
  added,
  isLastItem,
  cardView,
  successNotificationMessage = ADD_PERSON_TO_TEAM_SUCCESS_MESSAGE,
  user,
  featureFlags,
  workspace,
  allocationProject,
  renderFteBadge,
  handleSelectPerson,
  selectedMembers,
  showPersonCheckbox,
  bulkEditStatus,
}) => {
  const [, dispatch] = useModal();
  const canChangeAllocations =
    isRealTimeAllocationsEnabled({
      user,
      workspace,
      allocationProject,
    }) && isGroupActive(group);
  const canReportTeamChange = isSuggestChangeEnabled(workspace);

  const onDisplayReportTeamChange = () => {
    const onToggleVisibility = (isModalVisible) => () => {
      dispatch({ type: "setModalVisible", isModalVisible });
    };
    const modalComponent = (
      <ReportTeamChange
        person={person}
        groupTypes={groupTypes}
        isVisible={true}
        onToggleVisibility={onToggleVisibility}
        workspace={workspace}
        featureFlags={featureFlags}
      />
    );
    dispatch({ type: "setModalComponent", modalComponent });
    trackEvent(EVENT_TRACKING.ALLOCATION_SUGGESTED);
  };

  const onEditingAllocations = () => {
    const onHideModal = () => {
      dispatch({ type: "setModalVisible", isModalVisible: false });
    };

    // We need a person object that can be changed in the modal
    // When user change anything of the person, the modal is not re-rendered,
    // so we have to update the person and re-fresh the modal
    // TODO: Find a good way to do this...
    const personForUpdate = { ...person };
    const modalComponent = (
      <PersonAllocationsModal
        isVisible={true}
        person={personForUpdate}
        workspace={workspace}
        groupTypes={groupTypes}
        allocationProject={allocationProject}
        onHideModal={onHideModal}
        currentGroup={group}
        forceUpdatePerson
      />
    );
    dispatch({ type: "setModalComponent", modalComponent });
  };

  let itemDivider = null;
  if (!isLastItem) {
    itemDivider = <Divider light />;
  }

  const shouldDisablePersonCheckbox =
    bulkEditStatus && bulkEditStatus?.status === STATUS.PROGRESS;

  const tagConfig = get(workspace, "config.tagConfig", {});

  return (
    <>
      <Box
        data-testid="person-list-item-row"
        bg={highlighted ? "primary10" : "default"}
        id={getPersonItemRowId(person)}
        py={cardView ? "0" : "r"}
        px={cardView ? "0" : ""}
      >
        {added && (
          <Notification mb="r" colour="success" icon={["fas", "check-circle"]}>
            {successNotificationMessage}
          </Notification>
        )}
        <Flex alignItems="center">
          {showPersonCheckbox && (
            <Checkbox
              ariaLabel={getName(person)}
              colour="primary"
              disabled={shouldDisablePersonCheckbox}
              checked={!!selectedMembers[person.aggregateId]}
              onChange={() => handleSelectPerson && handleSelectPerson(person)}
            />
          )}
          <PersonAvatarAndDetails
            tagConfig={tagConfig}
            person={person}
            badgeGroups={badgeGroups}
            groupTypes={groupTypes}
            group={group}
            cardView={cardView}
            isDisplayActionsButton={isDisplayActionsButton}
            canChangeAllocations={canChangeAllocations}
            canReportTeamChange={canReportTeamChange}
            onDisplayReportTeamChange={onDisplayReportTeamChange}
            onEditingAllocations={onEditingAllocations}
            renderFteBadge={renderFteBadge}
            workspace={workspace}
            user={user}
            allocationProject={allocationProject}
          />
        </Flex>
      </Box>
      {!cardView && itemDivider}
    </>
  );
};

PersonListItem.propTypes = {
  isDisplayActionsButton: PropTypes.bool,
  person: PersonPropType.isRequired,
  badgeGroups: PropTypes.arrayOf(GroupPropType),
  groupTypes: GroupTypesPropType,
  group: GroupPropType,
  highlighted: PropTypes.bool,
  cardView: PropTypes.bool,
  added: PropTypes.bool,
  isLastItem: PropTypes.bool,
  successNotificationMessage: PropTypes.string,
  user: PropTypes.object,
  workspace: WorkspacePropType,
  featureFlags: PropTypes.object,
  allocationProject: PropTypes.object,
  renderFteBadge: PropTypes.func,
  handleSelectPerson: PropTypes.func,
  selectedMembers: PropTypes.object,
  showPersonCheckbox: PropTypes.bool,
  bulkEditStatus: PropTypes.object,
};

export default PersonListItem;
