import PropTypes from "prop-types";
import React from "react";

import {
  withMentions,
  useMention,
} from "src/comments/components/RichTextEditor/plugins/mentions";

import Editor from "./Editor";

/**
 * Editor using Slate with mentions context
 *
 * @type {Component}
 */
const EditorWithMentions = ({
  "data-testid": dataTestId,
  defaultValue,
  height,
  placeholder,
  onTextChange,
  autoFocus,
}) => {
  const { KeyDownHandler, ChangeHandler } = useMention();

  return (
    <Editor
      data-testid={dataTestId}
      defaultValue={defaultValue}
      height={height}
      placeholder={placeholder}
      onTextChange={onTextChange}
      withMentions={withMentions}
      KeyDownHandler={KeyDownHandler}
      ChangeHandler={ChangeHandler}
      autoFocus={autoFocus}
    />
  );
};

EditorWithMentions.propTypes = {
  "data-testid": PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  height: PropTypes.number,
  onTextChange: PropTypes.func,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
};

EditorWithMentions.defaultProps = {
  autoFocus: true,
};

export default EditorWithMentions;
