import React from "react";
import { PropTypes } from "prop-types";
import { StyledLink } from "orcs-design-system";
import { ExternalUrl } from "../components/ExternalUrl";

const NameCell = ({ row }) => {
  const { loadMore, name } = row.original;

  if (loadMore) {
    return (
      <StyledLink fontSize="1">Click to load related item hierarchy</StyledLink>
    );
  }

  return (
    <ExternalUrl objective={row.original}>
      <span className="name-col">{name}</span>
    </ExternalUrl>
  );
};

NameCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      parentIds: PropTypes.arrayOf(PropTypes.string),
      loadMore: PropTypes.bool,
      name: PropTypes.string,
      kind: PropTypes.string,
      attributes: PropTypes.arrayOf(PropTypes.object),
      dataSource: PropTypes.shape({
        type: PropTypes.string,
      }),
    }),
  }),
};

export default NameCell;
