import { map, omit } from "lodash";

// __typename needed from fragment but we don't want it in mutation
// eslint-disable-next-line import/prefer-default-export
export const omitTypename = (arrayObj) =>
  // eslint-disable-next-line no-param-reassign
  map(arrayObj, (obj) => omit(obj, ["__typename"]));

// used to correlate issues in sentry
export const createTransactionId = () =>
  Math.random().toString(36).substr(2, 9);
