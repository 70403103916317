import React, { useMemo } from "react";
import PropTypes from "prop-types";

import {
  useUserInteraction,
  useActiveAllocationProject,
  useLookupData,
} from "../../../context/ForecastContext";

import LineItemContent from "../LineItems/LineItemContent";
import Objectives from "../Objectives";

const LineItem = ({ lineItem, dispatch }) => {
  const {
    id,
    group,
    isSupply,
    isDemand,
    grouping: { root, showObjectives },
  } = lineItem;
  const { expandedObjectivesIds } = useUserInteraction();
  const { groupLookup, hideApproverColumn } = useLookupData();
  const activeAllocationProject = useActiveAllocationProject();

  const groupId = group.id;

  // Objectives only exist inside lookup data, so need to get from there
  const targetGroup = groupLookup[groupId];

  const isObjectivesExpanded = expandedObjectivesIds[id];

  return useMemo(
    () => (
      <>
        <LineItemContent
          lineItem={lineItem}
          dispatch={dispatch}
          withRequestNotification={false}
          hideApproverColumn={hideApproverColumn}
        />
        {isDemand && (
          <Objectives
            isObjectivesExpanded={isObjectivesExpanded}
            grouping={{
              id,
              groupId,
              group: targetGroup,
              canEditObjectives: false,
              nestedLevel: 0,
              isSupply,
              isDemand,
              showObjectives,
              root,
            }}
            inlineStyle
            dispatch={dispatch}
            activeAllocationProject={activeAllocationProject}
            loadOnExpand
          />
        )}
      </>
    ),
    [lineItem.hash, isObjectivesExpanded] // eslint-disable-line
  );
};

LineItem.propTypes = {
  lineItem: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default LineItem;
