import React, { useState, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { Spacer, Box, H5, H3, Small, Modal } from "orcs-design-system";

import ErrorNotification from "src/components/ErrorNotification";
import TeamNameEditor from "src/components/TeamName/TeamNameEditor";

import { updateGroupName } from "src/pages/TeamDetailPage/team.graphql";

import {
  useUserInteraction,
  useDispatch,
  ACTIONS,
  useLookupData,
} from "../../context/ForecastContext";

const RenameTeamModal = () => {
  const {
    renamingTeam: { isOpen, groupId },
  } = useUserInteraction();
  const { groupLookup } = useLookupData();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);

  const group = groupLookup[groupId];
  const [onRenameTeamMutation, { loading }] = useMutation(updateGroupName, {
    onCompleted: ({ result }) => {
      const { name } = result;
      dispatch({ type: ACTIONS.TEAM_RENAMED, groupId, name });
    },
    onError: (err) => {
      setError(err);
    },
  });

  const handleRenameTeam = useCallback(
    async (newName) => {
      return onRenameTeamMutation({
        variables: {
          groupId,
          name: newName,
        },
      });
    },
    [onRenameTeamMutation, groupId]
  );

  const handleCloseModal = useCallback(() => {
    setError(null);
    dispatch({
      type: ACTIONS.CLOSE_RENAME_TEAM_MODAL,
    });
    dispatch({
      type: ACTIONS.MAIN_QUERY_REFRESH,
      backgroundRefresh: true,
    });
  }, [dispatch]);

  if (!isOpen) {
    return null;
  }

  if (error) {
    return (
      <Modal
        visible={true}
        maxWidth="80vw"
        width="450px"
        onClose={handleCloseModal}
      >
        <Spacer my="r">
          <H3>Cannot rename</H3>
          <Small>An error occurred</Small>
          <ErrorNotification error={error} />
        </Spacer>
      </Modal>
    );
  }

  return (
    <Modal
      visible={isOpen}
      maxWidth="80vw"
      width="450px"
      onClose={handleCloseModal}
    >
      <Box p="xxs">
        <Spacer my="s">
          <H5>Please enter new name</H5>
          <TeamNameEditor
            team={group}
            updateName={handleRenameTeam}
            canCancel={false}
            autoExitEditMode={false}
            isEditMode
            isLoading={loading}
          />
        </Spacer>
      </Box>
    </Modal>
  );
};

export default RenameTeamModal;
