import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Loading, Small } from "orcs-design-system";

import styled from "styled-components";
import { ACTIONS } from "../../../context/ForecastContext";
import TreeExpand from "../../Shared/TreeExpand";
import useGridConfig from "../../util/gridConfig";
import { getAction } from "../../util/label";
import CustomGrid from "../../Shared/CustomGrid";

const MembersCustomGrid = styled(CustomGrid)`
  margin: 0 0 4px;
`;

const MembersExpandHeader = ({
  loading,
  grouping,
  dispatch,
  isMembersExpanded,
}) => {
  const gridConfig = useGridConfig();
  const { templateColumns, gap } = gridConfig.constraint;

  const { groupId, nestedLevel } = grouping;

  const handleToggle = useCallback(() => {
    dispatch({
      type: ACTIONS.TOGGLE_MEMBERS_EXPANSION,
      lineItemId: groupId,
    });
  }, [dispatch, groupId]);

  const action = getAction(isMembersExpanded);

  return (
    <TreeExpand
      isOpen={isMembersExpanded}
      indentLevel={nestedLevel}
      onToggle={handleToggle}
      inlineStyle={false}
    >
      {loading && <Loading mr={3} />}
      <MembersCustomGrid gridTemplateColumns={templateColumns} gridGap={gap}>
        <Small fontSize={0} mr="auto">
          {`Click to ${action} members `}
        </Small>
      </MembersCustomGrid>
    </TreeExpand>
  );
};

MembersExpandHeader.propTypes = {
  loading: PropTypes.bool,
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isMembersExpanded: PropTypes.bool,
};

export default MembersExpandHeader;
