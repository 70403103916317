import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, FlexItem } from "orcs-design-system";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import NodeGraph from "../../Visualisations/Reagraph/NodeGraph";
import { visualisationPath } from "../../../util/routing";
import PATHS from "../../../consts/urlPaths";
import { buildTeamGraph } from "../../../util/buildNodes";
import TeamsWithTooManyMembersNotification from "./TeamsWithTooManyMembersNotification";

const handleNodeDoubleClick = () => (type, id) => {
  if (id) {
    window.location.href = visualisationPath(type, id);
  } else {
    window.location.href = PATHS.VISUALISATION;
  }
};

const Visualisation = ({ team, history, tooManyMembers }) => {
  const includeEmptyTeams = true;
  const groupTypes = useGroupTypes();

  const nodes = team
    ? buildTeamGraph(
        team,
        {
          includeEmptyTeams,
        },
        groupTypes
      )
    : [];

  return (
    <FlexItem flex="1 0 auto">
      <Box
        bg="white"
        shadow="default"
        borderRadius={2}
        height="100%"
        overflow="hidden"
      >
        <Flex width="100%" height="100%" flexBasis="100%">
          <NodeGraph
            nodes={nodes}
            targetNode={{ id: team?.id, type: "team" }}
            handleNodeDoubleClick={handleNodeDoubleClick(history)}
            showNavigation={false}
          />
        </Flex>
        {tooManyMembers && <TeamsWithTooManyMembersNotification />}
      </Box>
    </FlexItem>
  );
};

Visualisation.propTypes = {
  team: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  tooManyMembers: PropTypes.bool,
};

export default Visualisation;
