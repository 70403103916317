import { useMutation } from "@apollo/client";
import { last } from "lodash";
import { H3, P, Modal, Flex, Button, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { markGroupAsHidden } from "src/allocation/team.graphql";
import EE, { EVENTS } from "src/services/eventEmitter";
import urlPaths, { url } from "src/consts/urlPaths";
import ErrorNotification from "../ErrorNotification";

const ModalHeader = ({ team }) => {
  return (
    <H3 mr="s">
      <span>{`Remove ${team.type} `}</span>
      <strong>{team.name}</strong>
    </H3>
  );
};

ModalHeader.propTypes = {
  team: PropTypes.object,
};

const HideTeamModal = ({
  group,
  isVisible,
  onHideModal,
  eventEmitter = EE,
}) => {
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);

  const [hideTeamMutation, { error: hideTeamMutationError }] =
    useMutation(markGroupAsHidden);

  const onHideTeam = useCallback(async () => {
    setIsProcessing(true);
    await hideTeamMutation({
      variables: {
        id: group.id,
        isHidden: true,
      },
    });
    setIsProcessing(false);
    onHideModal();

    const parentGroupId = last(group.parentIds);
    if (parentGroupId) {
      // Navigate to parent group
      history.push(url(urlPaths.TEAM_DETAILS_BASE_PATH, { id: parentGroupId }));
    }
    eventEmitter.emit(EVENTS.GROUP_REMOVED, group);
  }, [
    group,
    hideTeamMutation,
    onHideModal,
    setIsProcessing,
    history,
    eventEmitter,
  ]);

  const modalFooter = (
    <Flex>
      <Spacer mr="s">
        <Button
          onClick={onHideTeam}
          disabled={isProcessing}
          isLoading={isProcessing}
          data-testid="cp-remove-team-btn"
          px="l"
        >
          Remove
        </Button>
        <Button variant="ghost" onClick={onHideModal}>
          Cancel
        </Button>
      </Spacer>
    </Flex>
  );

  return (
    <Modal
      visible={isVisible}
      onClose={onHideModal}
      maxWidth="90vw"
      maxHeight="90vh"
      width={["90vw", "90vw", "80vw", "70vw", "60vw", "50vw"]}
      footerContent={modalFooter}
      headerContent={<ModalHeader team={group} />}
    >
      <>
        <P my="r">Are you sure you&apos;d like to remove?</P>
        {hideTeamMutationError && (
          <ErrorNotification
            message={
              hideTeamMutationError.message ||
              "Sorry, an error occurred, please refresh and try again."
            }
            error={hideTeamMutationError}
          />
        )}
      </>
    </Modal>
  );
};

HideTeamModal.propTypes = {
  group: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func.isRequired,
  eventEmitter: PropTypes.object,
};

export default React.memo(HideTeamModal);
