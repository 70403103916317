import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Flex, Small } from "orcs-design-system";
import AllocationPersonSearch from "src/components/AllocationPersonSearch";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import { copywriting } from "src/pages/TeamPage/sub-components/TeamMembersPanel.config";
import { SourceHandle, StyledCard, TargetHandle } from "../node.styled";
import PersonNode from "../PersonNode";
import { useAllocatePersonToGroup } from "./useAllocatePersonToGroup";

const getLabelAndPlaceholderTexts = ({ targetGroup, filterGroup }) => {
  if (targetGroup && filterGroup) {
    return {
      labelText: `Search ${filterGroup.name} to allocate a member to ${targetGroup.name}`,
    };
  }

  return {
    labelText: `Search to allocate a member to ${targetGroup.name}`,
  };
};

const CreatePersonNode = ({
  onCreate,
  contextForCreation,
  items,
  onCancel,
}) => {
  const [personToAdd, setPersonToAdd] = useState(null);
  const existingMembers = items.map((item) => item.data);
  const groupTypes = useGroupTypes();

  const { targetGroup, filter, filterGroup } = contextForCreation;

  const { labelText, placeHolderText } = getLabelAndPlaceholderTexts({
    targetGroup,
    filterGroup,
  });

  const { allocatePersonToGroup, isAllocationAllowed } =
    useAllocatePersonToGroup({ targetGroup, filter });

  const onPersonSelected = useCallback(
    async (value) => {
      if (!value) {
        return;
      }

      const { person, alreadyInGroup } = value;

      if (person) {
        setPersonToAdd(person);

        if (!alreadyInGroup) {
          try {
            await allocatePersonToGroup(person);
          } finally {
            setPersonToAdd(false);
            onCreate(person.aggregateId);
          }
        } else {
          onCreate(person.aggregateId);
        }
      }
    },
    [allocatePersonToGroup, onCreate]
  );

  if (personToAdd) {
    return (
      <PersonNode className="highlighted" data={personToAdd} overrideHidden />
    );
  }

  return (
    <StyledCard
      width="auto"
      minWidth="350px"
      maxWidth="450px"
      disablePointerEvents
      className="nopan nodrag"
    >
      <TargetHandle $hidden type="target" position="top" />
      <Flex flexDirection="column" gap="r">
        <Small>{labelText}</Small>
        <AllocationPersonSearch
          targetGroupId={targetGroup.id}
          existingMembers={existingMembers}
          onPersonSelected={onPersonSelected}
          onAddToGroup={() => null}
          groupTypes={groupTypes}
          disabled={!isAllocationAllowed}
          allocatedOptionLabel={copywriting.ALLOCATED}
          allocateOptionLabel={copywriting.ALLOCATE}
          autoFocus
          onBlur={onCancel}
          filter={filter}
          labelText={labelText}
          placeHolderText={placeHolderText}
        />
      </Flex>
      <SourceHandle $hidden type="source" position="bottom" />
    </StyledCard>
  );
};

CreatePersonNode.propTypes = {
  contextForCreation: { team: PropTypes.object },
  items: PropTypes.array,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CreatePersonNode;
