import React from "react";
import PropTypes from "prop-types";
import { find } from "lodash";
import { Badge } from "orcs-design-system";
import { JIRA_STATUS_COLOR_TO_BADGE_VARIANT } from "src/consts/jira";
import { OBJECTIVE_ATTRIBUTE_TYPE } from "src/consts/objectives";

const StatusCell = ({ row }) => {
  const attributes = row.original?.attributes;

  const issueType = find(attributes, {
    attributeType: OBJECTIVE_ATTRIBUTE_TYPE.STATUS,
  });
  const statusText = issueType?.value;

  if (statusText === undefined) {
    return "";
  }

  const issueColor = find(attributes, {
    attributeType: OBJECTIVE_ATTRIBUTE_TYPE.STATUS_COLOR,
  });
  const badgeVariant = JIRA_STATUS_COLOR_TO_BADGE_VARIANT[issueColor?.value];

  return <Badge variant={badgeVariant}>{statusText}</Badge>;
};

StatusCell.propTypes = {
  row: PropTypes.object,
};

export default StatusCell;
