import { map, keyBy } from "lodash";

export const JOB_TITLE_TYPE = "Job title";
export const TAGS_GENERIC_TYPE = "Tags";
export const PERSON_NAME_TYPE = "Person name";
export const WITH_TAGS_TYPE = "With any tags";
export const GROUP_NAME_TYPE = "Group name";
export const LINE_ITEM_NAME_TYPE = "Lineitem name";
export const SINGLE_TAG = "Single tag";

export const MIN_SEARCH_LENGTH = 2;
export const MAX_SEARCH_LENGTH = 100;

export const createFuseSearchOptions = (keys) => ({
  includeMatches: true,
  matchAllTokens: true,
  findAllMatches: true,
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 250,
  maxPatternLength: 32,
  minMatchCharLength: MIN_SEARCH_LENGTH,
  keys,
});

const createJobTitleOption = (str) => {
  const adjustedStr = str.substring(0, MAX_SEARCH_LENGTH);
  return {
    id: JOB_TITLE_TYPE,
    label: `*${adjustedStr}*`,
    type: JOB_TITLE_TYPE,
    value: adjustedStr,
  };
};

const createTagsGenericOption = (str) => {
  return {
    id: TAGS_GENERIC_TYPE,
    label: `*${str}*`,
    type: TAGS_GENERIC_TYPE,
    value: str,
  };
};

const createSearchTermsOption = (str) => {
  const adjustedStr = str.substring(0, MAX_SEARCH_LENGTH);
  return {
    id: PERSON_NAME_TYPE,
    label: `*${adjustedStr}*`,
    type: PERSON_NAME_TYPE,
    value: adjustedStr,
  };
};

const createSearchWithTagsOption = () => {
  return {
    id: WITH_TAGS_TYPE,
    label: ":Member",
    type: WITH_TAGS_TYPE,
    value: WITH_TAGS_TYPE,
  };
};

const createSearchGroupNameOption = (str) => {
  return {
    id: GROUP_NAME_TYPE,
    label: `*${str}*`,
    type: GROUP_NAME_TYPE,
    value: str,
  };
};

const createSearchLineItemNameOption = (str) => {
  return {
    id: LINE_ITEM_NAME_TYPE,
    label: `*${str}*`,
    type: LINE_ITEM_NAME_TYPE,
    value: str,
  };
};

// If we have other pages that can search, move this to a common place
export const AllSearchOptions = {
  [GROUP_NAME_TYPE]: createSearchGroupNameOption,
  [LINE_ITEM_NAME_TYPE]: createSearchLineItemNameOption,
  [JOB_TITLE_TYPE]: createJobTitleOption,
  [TAGS_GENERIC_TYPE]: createTagsGenericOption,
  [PERSON_NAME_TYPE]: createSearchTermsOption,
  [WITH_TAGS_TYPE]: createSearchWithTagsOption,
  [SINGLE_TAG]: true,
};

export const replaceGroupNameOptionLabel = (option, label) => {
  // eslint-disable-next-line no-param-reassign
  option.type = option.type.replace("Group", label);
  return option;
};

export const replaceLineItemNameOptionLabel = (option, label) => {
  // eslint-disable-next-line no-param-reassign
  option.type = option.type.replace("Lineitem", label);
  return option;
};

export const mergePeople = (members, pitMembers) => {
  const pitMembersById = keyBy(pitMembers, "aggregateId");

  return map(members, (m) => {
    const { aggregateId } = m;

    const pitPerson = pitMembersById[aggregateId];
    if (pitPerson) {
      return {
        ...m,
        prevAllocations: pitPerson.allocations,
      };
    }

    return m;
  });
};
