import React, { useCallback } from "react";

import { get } from "lodash";
import MoveGroupModalComponent from "src/components/MoveGroupModal";
import {
  useUserInteraction,
  useDispatch,
  ACTIONS,
  useGroupTypesLookup,
  useLookupData,
} from "../../context/ForecastContext";
import MoveGroupByTagModal from "./MoveGroupByTagModal";

const MoveGroupModal = () => {
  const {
    moveGroup: { isOpen, grouping },
  } = useUserInteraction();
  const { enableMoveGroups, enableMoveGroupByTag } = useLookupData();
  const groupTypes = useGroupTypesLookup();
  const dispatch = useDispatch();

  const onHideModal = useCallback(() => {
    dispatch({
      type: ACTIONS.MAIN_QUERY_REFRESH,
      backgroundRefresh: true,
    });
    dispatch({
      type: ACTIONS.CLOSE_MOVE_GROUP_MODAL,
    });
  }, [dispatch]);

  if (isOpen && enableMoveGroupByTag) {
    return (
      <MoveGroupByTagModal
        group={get(grouping, "group", {})}
        groupTypes={groupTypes}
        isVisible={isOpen}
        onHideModal={onHideModal}
        onGroupMoved={onHideModal}
      />
    );
  }

  if (isOpen && enableMoveGroups) {
    return (
      <MoveGroupModalComponent
        group={get(grouping, "group", {})}
        groupTypes={groupTypes}
        isVisible={isOpen}
        onHideModal={onHideModal}
        onGroupMoved={onHideModal}
      />
    );
  }

  return null;
};

export default MoveGroupModal;
