import { useQuery } from "@apollo/client";
import { getDirectMembersAndChildTeamsQuery } from "src/queries/group.graphql";

const useOrgChartDataLoader = ({ team }) => {
  const { data, loading, refetch } = useQuery(
    getDirectMembersAndChildTeamsQuery,
    {
      variables: {
        groupId: team?.id,
      },
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
      skip: !team?.id,
    }
  );

  return {
    loading,
    data,
    refetch,
  };
};

export default useOrgChartDataLoader;
