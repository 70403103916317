export default (group) => {
  return {
    firstLoad: true,
    loading: true,
    backgroundRefresh: false,
    parentGroup: group,
    mainData: {},
    secondaryData: {},
    groupLookup: { [group.id]: group },
    supplyGroupTree: {},
    memberLookup: {},
    targetGroupLookup: {},
    targetGroupTagsLookup: {},
    display: {
      members: {
        sorted: [],
        sortedAndFiltered: [],
        sortedFilteredAndSearched: [],
        totalFte: 0,
        onSearchSuggestion: () => [],
      },
      targetGroups: {
        sorted: [],
        sortedAndSearched: [],
        onSearchSuggestion: () => [],
        totalCurrentlyAllocatedFte: 0,
        totalTargetAllocationFte: 0,
      },
    },
  };
};
