import { filter, isEmpty, isNil, map, sortBy } from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { Box, Divider, H3, P, StyledLink } from "orcs-design-system";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { isSupplyOrDemand } from "src/util/customerConfig";

import { workspaceIdFromUrl } from "src/consts/urlPaths";
import { useAllocationProject } from "src/contexts/global/GlobalContext";

import QuickLinkRow from "./QuickLinkRow";

const QuickLinks = ({ person }) => {
  const params = useParams();
  const workspace = useWorkspace();
  const activeAllocationProject = useAllocationProject();
  const selectedWorkspace = workspaceIdFromUrl();

  if (person.memberOf) {
    // loading complete
    const { memberOf } = person;
    const sortedMemberOfGroups = sortBy(memberOf, ["name"]);
    const filteredMemberOfGroups = filter(sortedMemberOfGroups, (group) =>
      isSupplyOrDemand(workspace.config.groupTypes, group)
    );
    const { accessGroups } = person;
    if (isEmpty(filteredMemberOfGroups) && isEmpty(accessGroups)) {
      const browseTeamsUrl = !isNil(params.workspaceId)
        ? `/${params.workspaceId}/teams`
        : "/teams";
      return (
        <Box bg="white" boxBorder="default" shadow="default" borderRadius={2}>
          <H3 p="r">Quick links</H3>
          <Divider light />
          <P color="greyDark" p="r">
            You do not have any quick links,{" "}
            <StyledLink to={browseTeamsUrl}>
              click here to browse Teams
            </StyledLink>
            .
          </P>
        </Box>
      );
    }
    return (
      <Box>
        {!isEmpty(filteredMemberOfGroups) && (
          <Box bg="white" boxBorder="default" shadow="default" borderRadius={2}>
            <H3 p="r">Quick links - my teams</H3>
            <Divider light />
            {map(filteredMemberOfGroups, (team) => {
              return (
                <QuickLinkRow
                  key={team.id}
                  team={team}
                  config={workspace.config}
                  activeAllocationProject={activeAllocationProject}
                  selectedWorkspace={selectedWorkspace}
                />
              );
            })}
          </Box>
        )}
        {!isEmpty(accessGroups) && (
          <Box
            mt="l"
            bg="white"
            boxBorder="default"
            shadow="default"
            borderRadius={2}
          >
            <H3 p="r">Quick links - team access</H3>
            <Divider light />

            {map(accessGroups, (team) => {
              return (
                <QuickLinkRow
                  key={team.id}
                  team={team}
                  config={workspace.config}
                  activeAllocationProject={activeAllocationProject}
                  selectedWorkspace={selectedWorkspace}
                />
              );
            })}
          </Box>
        )}
      </Box>
    );
  }
  return null;
};

QuickLinks.propTypes = {
  person: PropTypes.object.isRequired,
};

export default QuickLinks;
