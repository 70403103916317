import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

import { useMutation } from "@apollo/client";

import { nanoid } from "nanoid";
import { TextInput, Box, Flex } from "orcs-design-system";
import { isUndefined } from "lodash";
import { updateFteIndividualAllocation } from "src/allocation/allocation.graphql";
import FailedIndicator from "../FailedIndicator";
import {
  useActiveAllocationProject,
  useLookupData,
} from "../../../context/ForecastContext";

import useUpdateAllocationsCache from "./hooks/useUpdateAllocationsCache";
import { getCurrentFte } from "./utils/person.util";

const NumberInput = styled(TextInput)`
  width: 50px;
  padding: 5px 8px 6px 8px;
`;

const getWrapperStyle = (showMacroAllocationInput) => {
  if (showMacroAllocationInput) {
    return {
      position: "relative",
    };
  }
  return {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  };
};

const PersonFte = ({ person, targetGroupId, updatePersonFte }) => {
  const { showMacroAllocationInput } = useLookupData();
  const [isValid, setIsValid] = useState(null);
  const [savingError, setSavingError] = useState(null);
  const [updateAllocationFte] = useMutation(updateFteIndividualAllocation);
  const activeAllocationProject = useActiveAllocationProject();
  const updateAllocationsCache = useUpdateAllocationsCache();

  const currentFte = useMemo(
    () => getCurrentFte(person, targetGroupId),
    [person, targetGroupId]
  );
  const [value, setValue] = useState(currentFte);

  const updateFte = async () => {
    const personId = person.aggregateId;
    const requestedFte = value;
    try {
      setSavingError(null);
      updateAllocationsCache(personId, targetGroupId, requestedFte);

      await updateAllocationFte({
        variables: {
          allocationId: nanoid(),
          allocationProjectId: activeAllocationProject.id,
          targetGroupId,
          personId,
          requestedFte,
        },
      });
      setIsValid(true);
    } catch (error) {
      setSavingError(error);
      setIsValid(false);
    }
  };

  const onBlur = () => {
    if (value === currentFte) {
      return;
    }

    updateFte();
  };

  const onValueChange = ({ floatValue }) => {
    setIsValid(null);

    if (!isUndefined(floatValue)) {
      setValue(floatValue);
      updatePersonFte(person.aggregateId, floatValue, value);
    }
  };

  const isFailed = isValid === false;

  return (
    <Box width="100%" style={getWrapperStyle(showMacroAllocationInput)}>
      <Flex alignItems="center" justifyContent="flex-start">
        <FailedIndicator
          popoverMessage={savingError?.message}
          isFailed={isFailed}
          retry={updateFte}
        />
        <Box ml="s">
          <NumberInput
            height="32px"
            value={value}
            data-testid="forecast-fte-input"
            valid={isValid}
            invalid={isFailed}
            numberProps={{
              allowNegative: false,
              onValueChange,
              onBlur,
            }}
          />
        </Box>
      </Flex>
    </Box>
  );
};

PersonFte.propTypes = {
  targetGroupId: PropTypes.string,
  person: PropTypes.object,
  updatePersonFte: PropTypes.func,
};

export default PersonFte;
