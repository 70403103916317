// eslint-disable-next-line import/prefer-default-export
export const getModalHeaderVerb = (isHidden, loading) => {
  if (isHidden) {
    if (loading) {
      return "Unhiding";
    }

    return "Unhide";
  }

  if (loading) {
    return "Hiding";
  }

  return "Hide";
};
