import mainQuery from "./mainQuery/initialState";
import secondaryQuery from "./secondaryQuery/initialState";
import model from "./model/initialState";
import userInteraction from "./userInteraction/initialState";

export default ({
  parentGroup,
  workspace,
  allocationProject,
  previousInteractionState,
  requiredTagTypes,
}) => ({
  parentGroup,
  allocationProject,
  workspace,
  mainQuery: mainQuery(
    parentGroup,
    workspace,
    allocationProject,
    previousInteractionState,
    requiredTagTypes
  ),
  secondaryQuery: secondaryQuery(requiredTagTypes),
  model: model(parentGroup),
  userInteraction: userInteraction(parentGroup, previousInteractionState),
});
