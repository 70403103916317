import React from "react";
import PropTypes from "prop-types";
import { Popover, Badge } from "orcs-design-system";
import pluralize from "pluralize";

import { numberToLocaleString } from "src/util/toLocaleString";

const GroupMemberCountInnerBadge = ({ count, className }) => (
  <Badge display="block" noWrap={true} className={className}>
    {`${numberToLocaleString(count)} ${pluralize("member", count)}`}
  </Badge>
);

GroupMemberCountInnerBadge.propTypes = {
  count: PropTypes.number.isRequired,
  className: PropTypes.string,
};

const GroupMemberCountBadgeToolTipContent = ({
  isTotalMemberCount,
  isTotalDescendantGroupMemberCount,
  isDirectMemberCount,
}) => {
  if (isTotalMemberCount) {
    return (
      <>
        The total count of all the <strong>unique</strong>,{" "}
        <strong>direct</strong> and <strong>indirect</strong> members, within
        this group
      </>
    );
  }

  if (isTotalDescendantGroupMemberCount) {
    return (
      <>
        The total count of all the <strong>unique</strong> members within{" "}
        <strong>subgroups</strong>
      </>
    );
  }

  if (isDirectMemberCount) {
    return (
      <>
        The total count of all the <strong>direct</strong> members in this group
      </>
    );
  }

  return null;
};

GroupMemberCountBadgeToolTipContent.propTypes = {
  isTotalMemberCount: PropTypes.bool,
  isTotalDescendantGroupMemberCount: PropTypes.bool,
  isDirectMemberCount: PropTypes.bool,
};

const GroupMemberCountBadge = ({
  count,
  isTotalMemberCount,
  isTotalDescendantGroupMemberCount,
  isDirectMemberCount,
  hideWhenZero,
  showToolTip = false,
  className = "",
  toolTipPosition = "bottom",
}) => {
  if (hideWhenZero && !count) {
    return null;
  }

  const tooltip = showToolTip && (
    <GroupMemberCountBadgeToolTipContent
      isTotalMemberCount={isTotalMemberCount}
      isTotalDescendantGroupMemberCount={isTotalDescendantGroupMemberCount}
      isDirectMemberCount={isDirectMemberCount}
    />
  );

  return showToolTip ? (
    <Popover
      text={tooltip}
      direction={toolTipPosition}
      textAlign="left"
      width="205px"
    >
      <GroupMemberCountInnerBadge count={count} className={className} />
    </Popover>
  ) : (
    <GroupMemberCountInnerBadge count={count} className={className} />
  );
};

GroupMemberCountBadge.propTypes = {
  count: PropTypes.number.isRequired,
  isTotalMemberCount: PropTypes.bool,
  isTotalDescendantGroupMemberCount: PropTypes.bool,
  isDirectMemberCount: PropTypes.bool,
  hideWhenZero: PropTypes.bool,
  showToolTip: PropTypes.bool,
  className: PropTypes.string,
  toolTipPosition: PropTypes.string,
};

export default GroupMemberCountBadge;
