import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { map, size } from "lodash";
import { Divider } from "orcs-design-system";

import { isLineItemHasNotifications } from "../../util/quickActivityViewFilters";
import LineItem from "./LineItem";

const LineItems = ({ grouping, isExpanded, dispatch }) => {
  const { lineItems } = grouping;

  if (!isExpanded) {
    return null;
  }

  return map(lineItems, (lineItem, index) => {
    if (!isLineItemHasNotifications(lineItem)) {
      return null;
    }

    return (
      <Fragment key={lineItem.groupId}>
        <LineItem lineItem={lineItem} dispatch={dispatch} />
        {index !== size(lineItems) - 1 && <Divider spanGrid />}
      </Fragment>
    );
  });
};

LineItems.propTypes = {
  grouping: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default LineItems;
