import themeGet from "@styled-system/theme-get";
import { Theme } from "./node.styled";

export const getObjectiveTheme = (objectiveData) => {
  return (
    Theme[objectiveData?.dataSource] ||
    Theme[objectiveData.objectiveType] ||
    Theme[objectiveData.type] ||
    Theme.group
  );
};

const collectChildrenIds = (nodes, selectedNodeId, childrenIds = new Set()) => {
  const selectedNode = nodes.find((node) => node?.id === selectedNodeId);

  if (selectedNode && selectedNode.data && selectedNode.data.childrenIds) {
    selectedNode.data.childrenIds.forEach((childId) => {
      childrenIds.add(childId);
      const childNode = nodes.find((node) => node.id === childId);
      if (childNode) {
        collectChildrenIds(nodes, childNode?.id, childrenIds);
      }
    });
  }
  return Array.from(childrenIds);
};

const collectParentIds = (nodes, selectedNodeId, parentIds = new Set()) => {
  const selectedNode = nodes.find((node) => node?.id === selectedNodeId);

  if (selectedNode && selectedNode.data && selectedNode.data.childrenIds) {
    selectedNode.data.parentIds.forEach((parentId) => {
      parentIds.add(parentId);
      const parentNode = nodes.find((node) => node.id === parentId);
      if (parentNode) {
        collectParentIds(nodes, parentNode?.id, parentIds);
      }
    });
  }
  return Array.from(parentIds);
};

export const collectSelectedNodes = (nodes, selectedNodeId) => {
  if (!selectedNodeId) {
    return [];
  }
  const childrenIds = collectChildrenIds(nodes, selectedNodeId);
  const parentIds = collectParentIds(nodes, selectedNodeId);
  return [...parentIds, ...childrenIds, selectedNodeId];
};

export const collectFadedNodes = (nodes, selectedNodeId) => {
  if (!selectedNodeId) {
    return nodes;
  }
  const selectedNodes = collectSelectedNodes(nodes, selectedNodeId);
  const graphNodes = nodes.map((node) => {
    const data = {
      ...node.data,
      fadedNode: !selectedNodes.includes(node.id),
      active: node?.id === selectedNodeId,
    };

    return {
      targetPosition: "left",
      sourcePosition: "right",
      ...node,
      data,
    };
  });

  return graphNodes;
};

export const styleSelectedNodesWithEdges = ({
  edges,
  nodes,
  selectedNodeId,
  theme,
}) => {
  if (!selectedNodeId) {
    return edges;
  }
  const selectedNodes = collectSelectedNodes(nodes, selectedNodeId);

  return edges.map((edge) => {
    const sourceNode = nodes.find((node) => node.id === edge.source);
    const targetNode = nodes.find((node) => node.id === edge.target);

    if (
      (!sourceNode?.x && !sourceNode?.y) ||
      (!targetNode?.x && !targetNode?.y)
    ) {
      return { ...edge };
    }

    const objectiveTheme = getObjectiveTheme(
      targetNode?.data || sourceNode?.data
    );

    const fadedStyle =
      targetNode.data?.expanded || sourceNode.data?.expanded
        ? {
            stroke: themeGet(`colors.${objectiveTheme.color}`)({ theme }),
          }
        : { stroke: "#b1b1b7", strokeWidth: 1 };

    const style =
      selectedNodes.includes(targetNode?.id) &&
      selectedNodes.includes(sourceNode?.id)
        ? {
            ...edge.style,
            stroke: themeGet(`colors.${objectiveTheme.color}`)({ theme }),
            strokeWidth: 5,
          }
        : fadedStyle;

    return {
      ...edge,
      style,
    };
  });
};
