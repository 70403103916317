import { get, isEmpty } from "lodash";
import { Flex, FlexItem, Small, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";

import {
  getFormattedMsg,
  isContentEmpty,
} from "src/components/RichTextContent/RichTextContent.util";
import TeamEdit from "src/components/Team/TeamEdit";
import * as placeholder from "src/components/Placeholder/PlaceholderStyles";
import { getDescriptionAttribute } from "src/util/group";

import DescriptionSection from "./DescriptionSection";
import EditDescriptionButton from "./EditDescriptionButton";
import LinksSection from "./LinksSection";
import getLinks from "./utils/getLinks";

const TeamDescription = ({
  attributes,
  loading,
  canUpdateGroupAttributes,
  handleUpdateTeamDetails,
  editDetailsMode,
  setEditDetailsMode,
}) => {
  const description = useMemo(() => {
    const descriptionAttribute = getDescriptionAttribute(attributes);
    const desc = get(descriptionAttribute, "value", null);
    return getFormattedMsg(desc);
  }, [attributes]);

  const links = getLinks(attributes);

  const onToggleEditMode = useCallback(
    (flag) => {
      setEditDetailsMode(flag);
    },
    [setEditDetailsMode]
  );

  const onSetEditMode = useCallback(() => {
    setEditDetailsMode(true);
  }, [setEditDetailsMode]);

  if (loading) {
    return (
      <placeholder.Line
        data-testid="cp-team-description-placeholder-line"
        width={260}
        height={90}
        mb={4}
      />
    );
  }

  const showDescription = !isContentEmpty(description);
  const showLinks = !isEmpty(links);
  const showSection = canUpdateGroupAttributes || showDescription || showLinks;
  const noContent = !showDescription && !showLinks && !editDetailsMode;

  const flexStack = noContent ? "0 0 auto" : "1 1 100%";
  const addMarginBottom = noContent ? "0" : "s";
  const addMarginRight = !noContent ? "0" : "s";

  return (
    <FlexItem flex={flexStack} mr={addMarginRight}>
      <Spacer mb={addMarginBottom}>
        {!showSection && (
          <Small display="block" color="greyDark">
            <em>No description has been set.</em>
          </Small>
        )}
        {!editDetailsMode && showSection && (
          <Flex
            alignItems={["flex-start", "flex-start", "flex-start"]}
            justifyContent="space-between"
            flexDirection={["column", "column", "column", "row"]}
          >
            {(showDescription || showLinks) && (
              <FlexItem>
                <Spacer mb="s">
                  <DescriptionSection
                    description={description}
                    showDescription={showDescription}
                  />
                  <LinksSection links={links} showLinks={showLinks} />
                </Spacer>
              </FlexItem>
            )}
            {canUpdateGroupAttributes && (
              <FlexItem flex="0 0 auto">
                <EditDescriptionButton
                  onSetEditMode={onSetEditMode}
                  showDescription={showDescription}
                />
              </FlexItem>
            )}
          </Flex>
        )}
        {editDetailsMode && (
          <TeamEdit
            attributes={attributes}
            defaultDescription={description}
            onHandleTeamUpdate={handleUpdateTeamDetails}
            onToggleEditMode={onToggleEditMode}
          />
        )}
      </Spacer>
    </FlexItem>
  );
};

TeamDescription.propTypes = {
  attributes: PropTypes.array,
  loading: PropTypes.bool,
  canUpdateGroupAttributes: PropTypes.bool,
  handleUpdateTeamDetails: PropTypes.func.isRequired,
  editDetailsMode: PropTypes.bool,
  setEditDetailsMode: PropTypes.func,
};

export default TeamDescription;
