import React from "react";
import styled from "styled-components";
import { Box, Loading } from "orcs-design-system";

export const LoadingWrapper = styled(Box)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5%;
  z-index: 10;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 20vh;
`;

export const FullPageLoading = () => {
  return (
    <LoadingWrapper>
      <Loading large />
    </LoadingWrapper>
  );
};
