import React, { useCallback } from "react";
import { get } from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import { updateTeamAttributes } from "src/pages/TeamDetailPage/team.graphql";
import { getGroupAttributes } from "src/allocation/team.graphql";
import TeamDescriptionEditModal from "src/components/TeamDescriptionEditModal";
// import { getCanUpdateGroupAttributes } from "src/util/group";

import {
  useUserInteraction,
  useDispatch,
  ACTIONS,
} from "../../context/ForecastContext";

const EditTeamDetailsModal = () => {
  const {
    editingTeamDetails: { isOpen, targetGroup },
  } = useUserInteraction();
  const dispatch = useDispatch();

  const teamAttributes = get(targetGroup, "attributes");
  const groupId = get(targetGroup, "id");

  const { data, loading } = useQuery(getGroupAttributes, {
    variables: {
      groupId,
    },
    fetchPolicy: "network-only",
    skip: !groupId || Boolean(teamAttributes),
  });

  const [onUpdateGroupAttributes] = useMutation(updateTeamAttributes);

  const handleHideModal = useCallback(() => {
    dispatch({
      type: ACTIONS.CLOSE_TEAM_DETAILS_EDITING_DIALOG,
    });
  }, [dispatch]);

  // const canUpdateGroupAttributes = getCanUpdateGroupAttributes(team);

  const handleUpdateTeamDetails = useCallback(
    async (attributes) => {
      if (groupId) {
        const response = await onUpdateGroupAttributes({
          variables: {
            groupId,
            attributes,
          },
        });
        return response.data.group;
      }
      return targetGroup;
    },
    [groupId, targetGroup, onUpdateGroupAttributes]
  );

  if (!isOpen) {
    return null;
  }

  const targetAttributes = get(data, "result.attributes", teamAttributes);

  return (
    <TeamDescriptionEditModal
      loading={loading}
      group={targetGroup}
      attributes={targetAttributes}
      isVisible={isOpen}
      onHandleTeamUpdate={handleUpdateTeamDetails}
      onHideModal={handleHideModal}
    />
  );
};

export default EditTeamDetailsModal;
