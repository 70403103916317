import { useState, useCallback } from "react";
import { isNil } from "lodash";
import { requestMacroAllocationAbsolute } from "src/allocation/allocation.graphql";

import { ACTIONS } from "../../../context/ForecastContext";
import useSaveMutation from "../useSaveMutation";

const useSaveFteForCell = (cell, dispatch) => {
  const {
    id,
    macroAllocationId,
    allocationProjectId,
    targetGroupId,
    sourceGroupId,
    value,
    defaultedValue,
  } = cell;

  const [isSaving, setIsSaving] = useState(false);

  const onRequestInputChange = useCallback(
    (newValue) => {
      dispatch({
        type: ACTIONS.REQUEST_INPUT_UPDATE,
        cellId: id,
        value: newValue,
      });
    },
    [dispatch, id]
  );

  const onRequestSaveStatusChange = useCallback(
    (status, data) => {
      setIsSaving(false);
      if (!data) {
        return;
      }
      dispatch({
        type: ACTIONS.CELL_VALUE_SAVE_STATUS_UPDATE,
        cellId: id,
        status,
        macroAllocation: data.macroAllocation,
      });
    },
    [setIsSaving, dispatch, id]
  );

  const [updateRequestValue, lastSavedValue] = useSaveMutation(
    value,
    requestMacroAllocationAbsolute,
    onRequestSaveStatusChange
  );

  const saveFte = useCallback(
    (fteValue, checkValueChange = true) => {
      updateRequestValue(
        fteValue,
        {
          id: macroAllocationId,
          allocationProjectId,
          sourceGroupId,
          targetGroupId,
          requested: fteValue,
        },
        checkValueChange
      );
    },
    [
      updateRequestValue,
      macroAllocationId,
      allocationProjectId,
      sourceGroupId,
      targetGroupId,
    ]
  );

  const onValueChange = useCallback(
    ({ floatValue = null }) => {
      if (value !== floatValue) {
        onRequestInputChange(floatValue);
      }
    },
    [onRequestInputChange, value]
  );

  const onBlur = useCallback(() => {
    if (isNil(value)) {
      onRequestInputChange(defaultedValue);
    }
  }, [value, defaultedValue, onRequestInputChange]);

  return {
    onBlur,
    onValueChange,
    saveFte,
    updateRequestValue,
    lastSavedValue,
    isSaving,
  };
};

export default useSaveFteForCell;
