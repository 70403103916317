import React, { useCallback } from "react";
import GuidedTour from "src/guidedTour/GuidedTour";

import {
  useForecastContext,
  PAGE_MODES,
  ACTIONS,
} from "../../context/ForecastContext";

const ForecastMainGuidedTour = () => {
  const [
    {
      pageMode,
      mainQuery: {
        variables: { isRequestorGroup },
      },
      model: {
        dynamicModel: { groupings },
      },
      userInteraction: { viewMode },
      lookupData: { parentGroupId, groupLookup, supplyLabel, demandLabel },
    },
    dispatch,
  ] = useForecastContext();

  const openFirstLineItem = useCallback(() => {
    dispatch({
      type: ACTIONS.OPEN_FIRST_LINE_ITEM,
      groupings,
    });
  }, [dispatch, groupings]);

  const isForecastPage = pageMode === PAGE_MODES.FORECAST;
  const isPlannerPage = pageMode === PAGE_MODES.PLANNER;

  const tourName = isForecastPage
    ? "allocationForecastTour"
    : "allocationPlannerTour";

  return (
    <GuidedTour
      tourName={tourName}
      run={isForecastPage || isPlannerPage}
      data={{
        group: groupLookup[parentGroupId],
        supplyLabel,
        demandLabel,
        viewMode,
        isRequestorGroup,
        openFirstLineItem,
      }}
    />
  );
};

export default ForecastMainGuidedTour;
