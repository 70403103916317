import { values, sumBy } from "lodash";

import flattenMembers from "./members/flatten";
import sortMembers from "./members/sort";
import filterMembers from "./members/filter";
import searchMembers from "./members/search";
import memberSearchSuggestionFn from "./members/searchSuggestionFn";

import sortGroups from "./targetGroups/sort";
import searchGroups from "./targetGroups/search";
import groupSearchSuggestionFn from "./targetGroups/searchSuggestionFn";

export const buildInitialDisplay = ({
  model,
  selectedSubSupplyGroupId,
  personFilter,
  personSearch,
  targetGroupSearch,
}) => {
  const rawMembers = values(model.memberLookup);
  const flattenedMembers = flattenMembers(rawMembers);
  const sortedMembers = sortMembers(flattenedMembers);
  const filteredMembers = filterMembers(
    sortedMembers,
    personFilter,
    selectedSubSupplyGroupId
  );
  const searchedMembers = searchMembers(filteredMembers, personSearch);
  const members = {
    sorted: sortedMembers,
    sortedAndFiltered: filteredMembers,
    sortedFilteredAndSearched: searchedMembers,
    totalFte: sumBy(searchedMembers, "fte"),
    onSearchSuggestion: memberSearchSuggestionFn(filteredMembers),
  };

  const rawGroups = values(model.targetGroupLookup);
  const sortedGroups = sortGroups(rawGroups);
  const searchedGroups = searchGroups(sortedGroups, targetGroupSearch);
  const targetGroups = {
    sorted: sortedGroups,
    sortedAndSearched: searchedGroups,
    onSearchSuggestion: groupSearchSuggestionFn(sortedGroups),
    totalCurrentlyAllocatedFte: sumBy(searchedGroups, "currentlyAllocatedFte"),
    totalTargetAllocationFte: sumBy(searchedGroups, "targetAllocationFte"),
  };

  return {
    members,
    targetGroups,
  };
};

export const updatePersonForSubSupplyGroup = (
  model,
  personFilter,
  personSearch,
  subGroupId
) => {
  const filteredMembers = filterMembers(
    model.display.members.sorted,
    personFilter,
    subGroupId
  );
  const searchedMembers = searchMembers(filteredMembers, personSearch);

  const display = {
    ...model.display,
    members: {
      ...model.display.members,
      sortedAndFiltered: filteredMembers,
      sortedFilteredAndSearched: searchedMembers,
      totalFte: sumBy(searchedMembers, "fte"),
      onSearchSuggestion: memberSearchSuggestionFn(filteredMembers),
    },
  };

  return display;
};

export const updatePersonFilter = (
  model,
  personFilter,
  personSearch,
  subGroupId
) => {
  const filteredMembers = filterMembers(
    model.display.members.sorted,
    personFilter,
    subGroupId
  );
  const searchedMembers = searchMembers(filteredMembers, personSearch);

  const display = {
    ...model.display,
    members: {
      ...model.display.members,
      sortedAndFiltered: filteredMembers,
      sortedFilteredAndSearched: searchedMembers,
      totalFte: sumBy(searchedMembers, "fte"),
      onSearchSuggestion: memberSearchSuggestionFn(filteredMembers),
    },
  };

  return display;
};

export const updatePersonSearch = (model, personSearch) => {
  const searchedMembers = searchMembers(
    model.display.members.sortedAndFiltered,
    personSearch
  );

  const display = {
    ...model.display,
    members: {
      ...model.display.members,
      sortedFilteredAndSearched: searchedMembers,
      totalFte: sumBy(searchedMembers, "fte"),
    },
  };

  return display;
};

export const updateTargetGroupSearch = (model, groupSearch) => {
  const searchedGroups = searchGroups(
    model.display.targetGroups.sorted,
    groupSearch
  );

  const display = {
    ...model.display,
    targetGroups: {
      ...model.display.targetGroups,
      sortedAndSearched: searchedGroups,
      totalCurrentlyAllocatedFte: sumBy(
        searchedGroups,
        "currentlyAllocatedFte"
      ),
      totalTargetAllocationFte: sumBy(searchedGroups, "targetAllocationFte"),
    },
  };

  return display;
};
