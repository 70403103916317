import { Icon, Small, Spacer, Button, Flex } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import icon from "src/config/icons";

import { copywriting } from "../TeamEdit.config";

const TeamEditFooter = ({ onConfirm, onCancel, isSaving, onSaveError }) => {
  return (
    <Flex flexWrap="wrap" width="100%">
      <Spacer mr="s">
        <Button
          ariaLabel="Save"
          onClick={onConfirm}
          variant="success"
          iconLeft
          disabled={isSaving}
          isLoading={isSaving}
          data-testid="cp-teamedit-form-save-btn"
        >
          <Icon icon={["fas", "save"]} />
          Save
        </Button>
        <Button ariaLabel="Cancel" variant="ghost" onClick={onCancel} iconLeft>
          <Icon icon={["fas", "times"]} />
          Cancel
        </Button>
      </Spacer>
      {onSaveError && (
        <Small
          color="danger"
          fontWeight="bold"
          display="block"
          ml="auto"
          my="s"
        >
          <Icon icon={icon.exclamation} mr="xs" />
          {onSaveError}
        </Small>
      )}
      {isSaving && (
        <Small
          color="success"
          fontWeight="bold"
          display="block"
          ml="auto"
          my="s"
        >
          <Icon icon={icon.save} mr="xs" />
          {copywriting.FORM_SAVING}
        </Small>
      )}
    </Flex>
  );
};

TeamEditFooter.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isSaving: PropTypes.bool,
  onSaveError: PropTypes.string,
};

export default React.memo(TeamEditFooter);
