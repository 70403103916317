import styled from "styled-components";
import { Grid } from "orcs-design-system";

const CustomGrid = styled(Grid)`
  align-items: center;
  justify-items: center;
  width: 100%;
  padding-right: 15px;
  > div:first-child {
    justify-self: flex-start;
  }
`;

export default CustomGrid;
