export const TEAM_ACTION_MENU_TYPE = {
  EXPLORE_IN_ORG: "explore_in_org",
  CHANGE_TEAM_DETAILS: "change_team_details",
  ADD_TEAM: "add_team",
  DOWNLOAD: "download",
  MOVE_TEAM: "move_team",
  ADVANCED_MOVE: "advanced_move",
  REMOVE_TEAM: "remove_team",
  CLONE_HIERARCHY: "clone_hierarchy",
  SELECT_COVER_IMAGE: "select_cover_image",
};
