// @ts-check

/* eslint-disable no-else-return */
/**
 *
 * @param {object} args
 * @param {{type: string, id: string}} args.group
 * @param {{allocations: {fte: number, targetGroupId: string}[]}} args.person
 * @param {Record<string, {isSupply?: boolean}>} args.groupTypes
 * @returns {{fte?: number, isSupply: boolean}}
 */
export const getAllocatedFteForOrgChart = ({ group, person, groupTypes }) => {
  if (groupTypes[group.type]?.isSupply) {
    return { fte: undefined, isSupply: true };
  }

  const foundAllocation = person.allocations.find(
    (g) => g.targetGroupId === group.id
  );

  const showFte = !!foundAllocation;

  if (showFte) {
    return { fte: foundAllocation.fte, isSupply: false };
  } else {
    return { fte: undefined, isSupply: false };
  }
};
