import React, { useCallback } from "react";
import { Box, ButtonGroupContainer, ButtonGroupItem } from "orcs-design-system";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";

import {
  usePageMode,
  useMainQuery,
  useViewMode,
  useLookupData,
  ACTIONS,
  VIEW_MODES,
  PAGE_MODES,
  useDispatch,
  useRootGroup,
} from "../../context/ForecastContext";

const ViewMode = () => {
  const { enableForecastCurrentGroupOnlyMode } = useWorkspaceFeatureFlags();
  const pageMode = usePageMode();
  const viewMode = useViewMode();
  const {
    variables: { isRequestorGroup },
  } = useMainQuery();
  const { supplyLabel, demandLabel, enableForecastConstraints } =
    useLookupData();
  const rootGroup = useRootGroup();
  const dispatch = useDispatch();

  const supportConstraints =
    enableForecastConstraints &&
    !isRequestorGroup &&
    pageMode === PAGE_MODES.FORECAST;

  const supportQuickView =
    pageMode === PAGE_MODES.PLANNER && rootGroup.isSource;

  // Only show the forecast CURRENT_GROUP_ONLY_VIEW tab for demand and toggle on
  const supportCurrentGroupOnlyMode =
    pageMode === PAGE_MODES.FORECAST &&
    rootGroup.isDemand &&
    enableForecastCurrentGroupOnlyMode;

  const handleChangeViewMode = useCallback(
    (e) => {
      dispatch({
        type: ACTIONS.SET_VIEW_MODE,
        viewMode: e.currentTarget.value,
        pageMode,
      });
    },
    [dispatch, pageMode]
  );

  return (
    <Box
      className="guided-tour-allocation-forecast-view-mode"
      data-testid="view-mode-toggle"
    >
      <ButtonGroupContainer controlLabel="View:" labelDark>
        <ButtonGroupItem
          name="viewMode"
          label={supplyLabel}
          value={VIEW_MODES.GROUPED_BY_SUPPLY}
          checked={viewMode === VIEW_MODES.GROUPED_BY_SUPPLY}
          onChange={handleChangeViewMode}
        />
        <ButtonGroupItem
          name="viewMode"
          label={demandLabel}
          value={VIEW_MODES.GROUPED_BY_DEMAND}
          checked={viewMode === VIEW_MODES.GROUPED_BY_DEMAND}
          onChange={handleChangeViewMode}
        />
        {supportCurrentGroupOnlyMode && (
          <ButtonGroupItem
            name="viewMode"
            label={`Current ${demandLabel}`}
            value={VIEW_MODES.CURRENT_GROUP_ONLY_VIEW}
            checked={viewMode === VIEW_MODES.CURRENT_GROUP_ONLY_VIEW}
            onChange={handleChangeViewMode}
          />
        )}
        {supportQuickView && (
          <ButtonGroupItem
            name="viewMode"
            label="Action required"
            value={VIEW_MODES.QUICK_ACTIVITY_VIEW}
            checked={viewMode === VIEW_MODES.QUICK_ACTIVITY_VIEW}
            onChange={handleChangeViewMode}
          />
        )}
        {supportConstraints && (
          <ButtonGroupItem
            name="viewMode"
            label="Constraint"
            value={VIEW_MODES.GROUPED_BY_CONSTRAINTS}
            checked={viewMode === VIEW_MODES.GROUPED_BY_CONSTRAINTS}
            onChange={handleChangeViewMode}
          />
        )}
      </ButtonGroupContainer>
    </Box>
  );
};

export default ViewMode;
