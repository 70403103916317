import React from "react";
import { Notification } from "orcs-design-system";

import { useModel } from "../context";

const AllocationsRefreshNotification = () => {
  const { loading, backgroundRefresh } = useModel();

  if (backgroundRefresh && loading)
    return (
      <Notification
        bottom="20px"
        centered
        loading={true}
        floating
        colour="success"
      >
        Allocations Refreshing...
      </Notification>
    );

  return null;
};

export default AllocationsRefreshNotification;
