import React from "react";
import PropTypes from "prop-types";
import isSupplyDemandGroupType, {
  doesGroupTypeAllowToHaveSupplyGroup,
} from "src/util/isSupplyDemandGroupType";
import { FTE_SUM_TYPE, FTE_SUM_TARGET } from "src/consts/team";
import FteQueryBadge from "src/components/FteQueryBadge";
import GroupPropType from "../../custom-prop-types/group";
import FteQueryBadgeForDemandAndSupply from "./FteQueryBadgeForDemandAndSupply";

const FteQueryBadgeContainer = ({ team, workspace, showToolTip }) => {
  const [isSupply, isDemand] = isSupplyDemandGroupType(team, workspace);

  if (
    isSupply ||
    (isDemand && !doesGroupTypeAllowToHaveSupplyGroup(team, workspace))
  ) {
    return (
      <FteQueryBadge
        groupId={team?.id}
        fteSumTarget={FTE_SUM_TARGET.ALL_MEMBERS}
        fteSumType={
          isSupply ? FTE_SUM_TYPE.CAPACITY_FTE : FTE_SUM_TYPE.ALLOCATED_FTE
        }
        showToolTip={showToolTip}
      />
    );
  }

  return <FteQueryBadgeForDemandAndSupply team={team} />;
};

FteQueryBadgeContainer.propTypes = {
  team: GroupPropType.isRequired,
  workspace: PropTypes.object,
  showToolTip: PropTypes.bool,
};

export default FteQueryBadgeContainer;
