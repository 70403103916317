import React, { Suspense, useState, useMemo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { P } from "orcs-design-system";
import { filter, isEmpty } from "lodash";

import { isDemandGroup } from "src/util/customerConfig";
import { timelineOptions } from "src/util/memberships";
import { FullPageLoading } from "src/components/FullPageLoading";

import { RelativeContainer } from "../RelativeContainer";
import { convertMemberships, getGroupContainerId } from "./utils";
import TimelineGroupHeaders from "./TimelineGroupHeaders";

const Timeline = React.lazy(() => import("src/components/Timeline"));

const TimelineView = ({
  person,
  memberships,
  groupTypes,
  isLoaded,
  fteConfig,
}) => {
  const [isGroupHeadersRendered, setIsGroupHeadersRendered] = useState(false);
  const [isTimelineRendered, setIsTimelineRendered] = useState(false);
  const timelineRef = useRef(null);

  const timeline = timelineRef.current?.timeline;

  const { items, groups } = useMemo(() => {
    if (!memberships) {
      return { items: [], groups: [] };
    }
    const demandMemberships = filter(memberships, ({ group }) =>
      isDemandGroup(groupTypes, group)
    );

    const convertedData = convertMemberships(
      demandMemberships,
      person,
      fteConfig
    );

    // Use below function to re-render the timeline
    if (timeline) {
      timeline.setData(convertedData);
    }

    return convertedData;
  }, [memberships, groupTypes, person, timeline, fteConfig]);

  const onTimelineRendered = useCallback(() => {
    setIsTimelineRendered(true);
  }, []);

  const options = {
    ...timelineOptions,
    groupTemplate: (group) => {
      if (!group) {
        return "";
      }

      const { group: targetGroup, content } = group;

      if (!targetGroup) {
        return content;
      }

      const el = document.createElement("div");
      el.id = getGroupContainerId(targetGroup);

      return el;
    },
  };

  const hasNoMembership = isEmpty(groups);

  return (
    <RelativeContainer mt="r">
      {(!isLoaded || !isGroupHeadersRendered) && <FullPageLoading />}
      {hasNoMembership && <P>No memberships</P>}
      {!hasNoMembership && (
        <Suspense>
          <Timeline
            ref={timelineRef}
            options={options}
            initialItems={items}
            initialGroups={groups}
            onTimelineRendered={onTimelineRendered}
          />
        </Suspense>
      )}
      <TimelineGroupHeaders
        isTimelineRendered={isTimelineRendered}
        groups={groups}
        setIsGroupHeadersRendered={setIsGroupHeadersRendered}
      />
    </RelativeContainer>
  );
};

TimelineView.propTypes = {
  person: PropTypes.object,
  memberships: PropTypes.array,
  groupTypes: PropTypes.object,
  isLoaded: PropTypes.bool,
  fteConfig: PropTypes.object,
};

export default TimelineView;
