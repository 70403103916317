import React, { useMemo, useCallback } from "react";
import { filter } from "lodash";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider } from "orcs-design-system";
import { teamDetailsPath } from "src/util/routing";
import GroupPropType from "src/custom-prop-types/group";
import PersonPropType from "src/custom-prop-types/person";
import { TEAM_TAB } from "src/consts/urlPaths";
import { STATUS_UNALLOCATED } from "src/allocation/consts";
import { getCity } from "src/allocation/util/person";
import PersonAllocationDetails from "./PersonAllocationDetails";

const getActiveAllocations = (allocations) => {
  return filter(allocations, ({ status }) => {
    return !status || status !== STATUS_UNALLOCATED;
  });
};

const Row = ({ group, allocationProjectId, person, allocations, readOnly }) => {
  const city = useMemo(() => getCity(person), [person]);

  const history = useHistory();

  const activeAllocations = useMemo(
    () => getActiveAllocations(allocations),
    [allocations]
  );

  const onAllocate = useCallback(() => {
    const teamPath = teamDetailsPath(group.id, TEAM_TAB.INDIVIDUAL_ALLOCATIONS);
    history.push(`${teamPath}/${allocationProjectId}/${person.aggregateId}`);
  }, [history, group.id, allocationProjectId, person.aggregateId]);

  return (
    <>
      <Box py="s">
        <PersonAllocationDetails
          person={person}
          allocations={activeAllocations}
          city={city}
          onAllocate={onAllocate}
          readOnly={readOnly}
        />
      </Box>
      <Divider />
    </>
  );
};

Row.propTypes = {
  group: GroupPropType.isRequired,
  allocationProjectId: PropTypes.string.isRequired,
  person: PersonPropType.isRequired,
  allocations: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
};

export default Row;
