import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import CollapseBtn from "src/components/CollapsedList/CollapseButton";
import FluidGrid from "src/components/FluidGrid";
import PersonPropType from "src/custom-prop-types/person";
import WorkspacePropType from "src/custom-prop-types/workspace";
import { useReadonlyLayoutFlags } from "src/hooks/useLayoutFlags";
import useShortList from "src/hooks/useShortList";

import FullMembersList from "./FullMembersList";
import ShortMembersList from "./ShortMembersList";

const MembersList = ({
  disableSort = false,
  selectedSort,
  loading,
  team,
  groupTypes,
  members,
  newMemberId,
  selectedPersonId,
  isAddingToGroup,
  toggleMembersList,
  withAllGroups = false,
  successNotificationMessage,
  isDisplayActionsButton,
  user,
  userPerson,
  featureFlags,
  workspace,
  allocationProject,
  renderFteBadge,
  handleSelectPerson,
  selectedMembers,
  showPersonCheckbox,
  bulkEditStatus,
  fullListMode,
  listLength: shortListLength,
  containerRef: memberContainerRef,
}) => {
  const [listLength, containerRef] = useShortList(
    fullListMode,
    shortListLength,
    memberContainerRef
  );
  const {
    layoutFlags: { cardView },
  } = useReadonlyLayoutFlags();

  if (!loading && isEmpty(members) && !isAddingToGroup) {
    return null;
  }

  let memberList = null;

  if (!fullListMode && listLength > 0) {
    memberList = (
      <ShortMembersList
        loading={loading}
        members={members}
        listLength={listLength}
        toggleMembersList={toggleMembersList}
      />
    );
  } else {
    memberList = (
      <FullMembersList
        disableSort={disableSort}
        bulkEditStatus={bulkEditStatus}
        showPersonCheckbox={showPersonCheckbox}
        handleSelectPerson={handleSelectPerson}
        selectedMembers={selectedMembers}
        isDisplayActionsButton={isDisplayActionsButton}
        successNotificationMessage={successNotificationMessage}
        selectedSort={selectedSort}
        loading={loading}
        team={team}
        groupTypes={groupTypes}
        members={members}
        newMemberId={newMemberId}
        selectedPersonId={selectedPersonId}
        isAddingToGroup={isAddingToGroup}
        toggleMembersList={toggleMembersList}
        withAllGroups={withAllGroups}
        cardView={cardView}
        user={user}
        userPerson={userPerson}
        featureFlags={featureFlags}
        workspace={workspace}
        allocationProject={allocationProject}
        renderFteBadge={renderFteBadge}
      />
    );
    if (cardView) {
      memberList = <FluidGrid>{memberList}</FluidGrid>;
    }
  }

  return (
    <div ref={containerRef}>
      {fullListMode && listLength > 0 && (
        <CollapseBtn onClick={toggleMembersList} />
      )}
      {memberList}
    </div>
  );
};

MembersList.propTypes = {
  fullListMode: PropTypes.bool,
  selectedSort: PropTypes.object,
  loading: PropTypes.bool,
  team: PropTypes.object,
  groupTypes: PropTypes.object,
  members: PropTypes.array,
  newMemberId: PropTypes.array,
  selectedPersonId: PropTypes.string,
  isAddingToGroup: PropTypes.bool,
  toggleMembersList: PropTypes.func,
  withAllGroups: PropTypes.bool,
  isDisplayActionsButton: PropTypes.bool,
  successNotificationMessage: PropTypes.string,
  user: PropTypes.object,
  userPerson: PersonPropType,
  workspace: WorkspacePropType,
  featureFlags: PropTypes.object,
  allocationProject: PropTypes.object,
  renderFteBadge: PropTypes.func,
  handleSelectPerson: PropTypes.func,
  selectedMembers: PropTypes.object,
  showPersonCheckbox: PropTypes.bool,
  bulkEditStatus: PropTypes.object,
  disableSort: PropTypes.bool,
  listLength: PropTypes.number,
  containerRef: PropTypes.object,
};

export default MembersList;
