import React from "react";
import PropTypes from "prop-types";
import ObjectivesFlow from "src/components/ObjectivesFlow";

const StrategyVisualizationView = ({ useObjectivesVisualizerResult }) => {
  const {
    team,
    objectives,
    loading,
    onNodeClick,
    selectedNode,
    newNodeLoading,
    loadingObjective,
  } = useObjectivesVisualizerResult;

  return (
    <ObjectivesFlow
      objectives={objectives}
      onNodeClick={onNodeClick}
      loading={loading}
      newNodeLoading={newNodeLoading}
      selectedNode={selectedNode}
      team={team}
      loadingObjective={loadingObjective}
    />
  );
};

StrategyVisualizationView.propTypes = {
  useObjectivesVisualizerResult: PropTypes.object.isRequired,
};

export default StrategyVisualizationView;
