import React from "react";
import { Button, Popover, Icon } from "orcs-design-system";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import PropTypes from "prop-types";

const WrapperButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: ${themeGet("colors.grey")};
  margin: 0;

  &:hover,
  &:focus {
    background-color: transparent;
    border: none;
  }

  span[class*="Icon_"] {
    background-color: ${themeGet("colors.greyLighter")};
    padding: 0 4px;
    border-radius: ${themeGet("radii.2")};
    color: ${themeGet("colors.grey")};
  }
`;

const ExpandButton = ({ clickHandler, popoverText, btnRef }) => {
  return (
    <WrapperButton onClick={clickHandler} p="xxs" mb="s" ref={btnRef}>
      <Popover
        direction="bottomRight"
        text={popoverText}
        width="220px"
        textAlign="left"
        inlineBlock
      >
        <Icon title="arrow-alt-left" icon={["fas", "arrow-alt-left"]} />
      </Popover>
    </WrapperButton>
  );
};

ExpandButton.propTypes = {
  popoverText: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
  btnRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default ExpandButton;
