import { size, isEmpty } from "lodash";
import {
  ActionsMenuHeading,
  ActionsMenuItem,
  Icon,
  Box,
  Small,
  Button,
  Spacer,
} from "orcs-design-system";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React from "react";

import { MAX_MOVE_LIMIT } from "src/components/BulkMoveMembersModal/consts";
import TeamSearch from "src/components/TeamSearch";

const TeamSearchAction = ({
  groupFilter,
  teamId,
  selectedMembers,
  showTeamSearch,
  onClickMoveTeam,
  isProcessing,
  onTeamSelected,
  handleBulkMove,
  groupTypes,
  closeMenu,
  reset,
  targetGroupOption,
}) => {
  const onClickBulkMove = () => {
    handleBulkMove();
    closeMenu();
  };

  const hasExceededLimit = size(selectedMembers) > MAX_MOVE_LIMIT;

  return (
    <>
      {!showTeamSearch && (
        <ActionsMenuItem type="button" onClick={onClickMoveTeam}>
          Move to another team
        </ActionsMenuItem>
      )}
      {showTeamSearch && (
        <>
          <ActionsMenuHeading onClick={reset} canClick={showTeamSearch}>
            <Icon icon={["fas", "angle-left"]} mr="xs" />
            Back to menu
          </ActionsMenuHeading>

          <Box width={["100%", "100%", "485px"]} id="editableContent" p="s">
            <TeamSearch
              existingGroupIds={[teamId]}
              onTeamSelected={onTeamSelected}
              isProcessing={isProcessing}
              typeFilters={groupFilter}
              groupTypes={groupTypes}
              showButton={false}
            />
            {hasExceededLimit && (
              <Small color="white" display="block" mt="s">
                Up to 100 people can be moved at once.
              </Small>
            )}
            <Spacer mt="s">
              <Button
                variant="success"
                disabled={
                  size(selectedMembers) === 0 ||
                  hasExceededLimit ||
                  isEmpty(targetGroupOption)
                }
                onClick={onClickBulkMove}
              >{`Move ${size(selectedMembers)} selected ${pluralize(
                "person",
                size(selectedMembers)
              )}`}</Button>
            </Spacer>
          </Box>
        </>
      )}
    </>
  );
};

TeamSearchAction.propTypes = {
  teamId: PropTypes.string,
  onClickMoveTeam: PropTypes.func,
  showTeamSearch: PropTypes.bool,
  isProcessing: PropTypes.bool,
  handleBulkMove: PropTypes.func,
  closeMenu: PropTypes.func,
  groupTypes: PropTypes.object,
  onTeamSelected: PropTypes.func,
  reset: PropTypes.func,
  selectedMembers: PropTypes.object,
  targetGroupOption: PropTypes.object,
  groupFilter: PropTypes.array,
};

export default TeamSearchAction;
