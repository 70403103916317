import React from "react";
import { entries, values, some } from "lodash";

import GuidedTour from "src/guidedTour/GuidedTour";

import {
  useForecastContext,
  VIEW_MODES,
  PAGE_MODES,
} from "../../context/ForecastContext";

const ForecastLineItem = () => {
  const [
    {
      pageMode,
      mainQuery: {
        loading,
        variables: { isRequestorGroup },
      },
      userInteraction: {
        expandedLineItemsGroupIds,
        expandedGroupIds,
        viewMode,
      },
      model: {
        dynamicModel: {
          lookups: { groupingLookup },
        },
      },
      lookupData: { parentGroupId, groupLookup, supplyLabel, demandLabel },
    },
  ] = useForecastContext();

  const userExpandedLineItems =
    !loading && some(values(expandedLineItemsGroupIds), Boolean);
  const userExpandedGroupWithLineItems =
    !loading &&
    some(
      entries(expandedGroupIds),
      ([id, enabled]) =>
        enabled &&
        groupingLookup[id] &&
        groupingLookup[id].childGroupings.length === 0
    );

  const isLineItemsExpanded =
    !loading &&
    viewMode !== VIEW_MODES.GROUPED_BY_CONSTRAINTS &&
    (userExpandedLineItems || userExpandedGroupWithLineItems);

  const isForecastPage = pageMode === PAGE_MODES.FORECAST;
  const isPlannerPage = pageMode === PAGE_MODES.PLANNER;

  const tourName = isForecastPage
    ? "allocationForecastLineItemTour"
    : "allocationPlannerLineItemTour";

  return (
    <GuidedTour
      tourName={tourName}
      run={(isForecastPage || isPlannerPage) && isLineItemsExpanded}
      data={{
        group: groupLookup[parentGroupId],
        supplyLabel,
        demandLabel,
        viewMode,
        isRequestorGroup,
      }}
    />
  );
};

export default ForecastLineItem;
