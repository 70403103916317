import { P } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { MentionElement } from "src/comments/components/RichTextEditor/plugins/mentions";

import { BLOCK_TAGS, INLINE_TAGS } from "../RichTextEditor.config";

import LinkElement from "./LinkElement";

const { ul, li, ol, link } = BLOCK_TAGS;

/**
 * Render a Slate node in RTE TextArea
 * @param {*} props - refer to PropTypes
 */
const Element = (props) => {
  const { mention } = INLINE_TAGS;
  const { attributes, children, element } = props;

  switch (element.type) {
    case ul:
      return (
        <ul data-testid="cp-element-ul" {...attributes}>
          {children}
        </ul>
      );
    case li:
      return (
        <li data-testid="cp-element-li" {...attributes}>
          {children}
        </li>
      );
    case ol:
      return (
        <ol data-testid="cp-element-ol" {...attributes}>
          {children}
        </ol>
      );
    case link:
      return <LinkElement {...props} />;
    case mention:
      return <MentionElement {...props} />;
    // case 'block-quote':
    //   return <blockquote {...attributes}>{children}</blockquote>;
    // case 'heading-one':
    //   return <h1 {...attributes}>{children}</h1>;
    // case 'heading-two':
    //   return <h2 {...attributes}>{children}</h2>;
    default:
      return (
        <P data-testid="cp-element-paragraph" mb="s" {...attributes}>
          {children}
        </P>
      );
  }
};

Element.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  element: PropTypes.object,
};

export default Element;
