import { useApolloClient, useMutation } from "@apollo/client";
import React, { useCallback, useState, useEffect } from "react";
import { Box, H6, Modal, Flex, Button, Notification } from "orcs-design-system";
import { nanoid } from "nanoid";
import AllocationModalHeader from "src/allocation/components/AllocationModalHeader";
import { unallocateIndividualAllocation } from "src/allocation/allocation.graphql";
import ErrorNotification from "src/components/ErrorNotification";
import { fullDisplayName } from "src/util/personName";

import {
  useUserInteraction,
  useActiveAllocationProject,
  useDispatch,
  useLookupData,
  ACTIONS,
} from "../../context/ForecastContext";
import { refetchQuery } from "./AllocationModal/AllocationModal.util";

const RemoveMemberModal = () => {
  const client = useApolloClient();
  const {
    removeMember: { isOpen, grouping, person, searchVariables },
  } = useUserInteraction();
  const { showMemberMoveBadge } = useLookupData();
  const allocationProject = useActiveAllocationProject();
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setIsProcessing(false);
      setError(null);
    }
  }, [isOpen]);

  const handleCloseModal = useCallback(() => {
    if (isProcessing) {
      return;
    }

    dispatch({
      type: ACTIONS.CLOSE_REMOVE_MEMBER_MODAL,
    });
  }, [dispatch, isProcessing]);

  const [removeMember] = useMutation(unallocateIndividualAllocation);

  const runRemoveMember = useCallback(async () => {
    const { aggregateId: personId } = person;

    const targetGroupId = grouping.id;

    try {
      setIsProcessing(true);

      // Bulk move members
      await removeMember({
        variables: {
          allocationId: nanoid(),
          allocationProjectId: allocationProject.id,
          targetGroupId,
          personId,
        },
      });

      if (showMemberMoveBadge) {
        setTimeout(() => {
          // Refetch existing members list
          refetchQuery(client, searchVariables.targetGroupId, searchVariables);
        }, 100);
      }

      // Refresh the planner page
      dispatch({
        type: ACTIONS.MAIN_QUERY_REFRESH,
        backgroundRefresh: true,
      });

      handleCloseModal();
    } catch (e) {
      setError(e);
    } finally {
      setIsProcessing(false);
    }
  }, [
    person,
    grouping,
    removeMember,
    allocationProject,
    showMemberMoveBadge,
    dispatch,
    handleCloseModal,
    client,
    searchVariables,
  ]);

  if (!isOpen && !grouping) {
    return null;
  }

  // Set move and cancel button to disabled if processing or have any error
  const isBusy = isProcessing || !!error;

  const footerContent = (
    <Box width="100%">
      <Flex alignItems="center" justifyContent="flex-end" width="100%">
        <Button
          onClick={handleCloseModal}
          variant="ghost"
          mr="r"
          disabled={isBusy}
        >
          Cancel
        </Button>
        <Button
          onClick={runRemoveMember}
          variant={isBusy ? "disabled" : "success"}
          disabled={isBusy}
        >
          Remove
        </Button>
      </Flex>
    </Box>
  );

  return (
    <Modal
      visible={isOpen}
      width="60vw"
      overflow="visible"
      headerContent={<AllocationModalHeader person={person} />}
      footerContent={footerContent}
      onClose={handleCloseModal}
    >
      <>
        <H6 my="r" weight="bold">
          {`Do you want to remove ${fullDisplayName(person)} from ${
            grouping.group.name
          }?`}
        </H6>
        <Box mt="r">
          {isProcessing && !error && (
            <Notification loading closable={false}>
              Removing in progress ...
            </Notification>
          )}
          {error && (
            <ErrorNotification
              message={`Sorry, an error occurred, please refresh and try moving again. (${error.message})`}
              error={error}
              closable={false}
            />
          )}
        </Box>
      </>
    </Modal>
  );
};

export default RemoveMemberModal;
