import React from "react";
import { Badge, Box } from "orcs-design-system";
import { PropTypes } from "prop-types";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import { Theme } from "../../ObjectivesFlow/node.styled";

const types = {
  strat_outcome: { variant: "primaryDark", label: "Strategic Outcome" },
  okr: { variant: "primary", label: "Objective Key & result" },
  epic: { variant: "secondary", label: "Epic" },
  work_item: { variant: "warning", label: "Work Item" },
  company: { variant: "primary", label: "Company" },
  domain: { variant: "success", label: "Group" },
  group: { variant: "success", label: "Group" },
  no_objective: { variant: "default", label: "Not Assigned" },
  team: { variant: "default", label: "Team" },
};

const TypeBadge = ({ row }) => {
  const { dataSource, loadMore, kind, objectiveTypeDisplayName } = row.original;

  if (kind === "team") {
    return (
      <Box display="inline-block" mr="s">
        <GroupTypeBadge group={row.original} />
      </Box>
    );
  }

  const theme = Theme[dataSource?.type || objectiveTypeDisplayName || kind];
  const type = types[dataSource?.type || objectiveTypeDisplayName || kind];
  const variant = theme?.color || type?.variant || "default";

  if (loadMore) {
    return "";
  }

  return (
    <Box mr="s">
      <Badge variant={variant}>{type?.label || objectiveTypeDisplayName}</Badge>
    </Box>
  );
};

TypeBadge.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      loadMore: PropTypes.bool,
      kind: PropTypes.string,
      dataSource: PropTypes.shape({
        type: PropTypes.string,
      }),
      objectiveTypeDisplayName: PropTypes.string,
    }),
  }),
};

export default TypeBadge;
