import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  get,
  values,
  map,
  filter,
  some,
  includes,
  union,
  isEqual,
} from "lodash";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

import PersonPropType from "src/custom-prop-types/person";
import GroupPropType from "src/custom-prop-types/group";
import IndividualAllocationPropType from "src/custom-prop-types/individualAllocation";
import AllocationConfigPropType from "src/custom-prop-types/allocationConfig";
import AllocationProjectPropType from "src/custom-prop-types/allocationProject";
import { getDescendantGroups as getDescendantGroupsQuery } from "src/allocation/allocation.graphql";
import { mergeExistingAllocationsWithChanges } from "src/allocation/util/PeopleContainer";

import SourceGroups from "../components/SourceGroups";

import {
  sourceGroupsWithCalculatedFtes,
  gatherAndSearchForRelevantSourceGroups,
} from "../util";

const SourceGroupsContainer = ({
  selectedGroup,
  selectedMembers,
  allocationProject,
  allocationConfig,
  data,
  onSourceGroupsSelected,
}) => {
  const { state: locationState } = useLocation();
  const initialSearchText = get(locationState, "searchText", null);
  const [searchTerm, setSearchTerm] = useState(initialSearchText);
  const [searchedSourceGroups, setSearchedSourceGroups] = useState([]);
  const [visibleSourceGroups, setVisibleSourceGroups] = useState([]);

  const { data: sourceGroupsData } = useQuery(getDescendantGroupsQuery, {
    variables: {
      groupTypes: union(
        allocationConfig.approverTypes,
        allocationConfig.roleTypes
      ),
    },
  });

  useEffect(() => {
    if (data && sourceGroupsData) {
      const individualAllocationsApiQueryResult = get(
        data,
        "individualAllocations",
        []
      );
      const groupMembersApiQueryResult = get(data, "members.members", []);
      const sourceGroupsApiQueryResult = get(sourceGroupsData, "groups", []);
      const sourceGroupPlannedFtesApiQueryResult = get(
        data,
        "sourceGroupFtes",
        []
      );
      const macroAllocationTargetsApiQueryResult = get(
        data,
        "macroAllocationTargets",
        []
      );
      const membersWithMergedAllocations = mergeExistingAllocationsWithChanges(
        selectedGroup,
        groupMembersApiQueryResult,
        individualAllocationsApiQueryResult,
        {
          detectAndRemoveUnallocatedMembers: true,
          filterUnrelatedAllocations: true,
        }
      );

      const newlySearchedSourceGroups = gatherAndSearchForRelevantSourceGroups(
        sourceGroupsApiQueryResult,
        map(sourceGroupPlannedFtesApiQueryResult, "group"),
        map(macroAllocationTargetsApiQueryResult, "sourceGroup"),
        map(values(membersWithMergedAllocations), "person"),
        searchTerm
      );

      if (!isEqual(searchedSourceGroups, newlySearchedSourceGroups)) {
        setSearchedSourceGroups(newlySearchedSourceGroups);
        if (searchTerm) {
          onSourceGroupsSelected(newlySearchedSourceGroups);
        } else {
          onSourceGroupsSelected(null);
        }
      }

      const newVisibleSourceGroups = selectedMembers
        ? filter(newlySearchedSourceGroups, (role) =>
            some(selectedMembers, (m) => includes(m.sourceGroupIds, role.id))
          )
        : newlySearchedSourceGroups;

      const newVisibleSourceGroupsWithFtes = sourceGroupsWithCalculatedFtes(
        newVisibleSourceGroups,
        selectedGroup,
        sourceGroupPlannedFtesApiQueryResult,
        macroAllocationTargetsApiQueryResult,
        membersWithMergedAllocations
      );

      if (!isEqual(visibleSourceGroups, newVisibleSourceGroupsWithFtes)) {
        setVisibleSourceGroups(newVisibleSourceGroupsWithFtes);
      }
    } else {
      setSearchTerm(initialSearchText);
      onSourceGroupsSelected(null);
      setSearchedSourceGroups(null);
      setVisibleSourceGroups(null);
    }
  }, [
    data,
    sourceGroupsData,
    selectedGroup,
    searchTerm,
    initialSearchText,
    selectedMembers,
    searchedSourceGroups,
    visibleSourceGroups,
    onSourceGroupsSelected,
  ]);

  const onSearch = useCallback(
    (newSearchTerm) => {
      setSearchTerm(newSearchTerm);
    },
    [setSearchTerm]
  );

  return (
    <SourceGroups
      loading={!data}
      allocationProject={allocationProject}
      selectedGroup={selectedGroup}
      sourceGroups={visibleSourceGroups}
      onSearch={onSearch}
      initialSearchText={initialSearchText}
      supplyLabel={get(allocationConfig, "supplyLabel", "Role")}
    />
  );
};

SourceGroupsContainer.propTypes = {
  selectedGroup: GroupPropType.isRequired,
  allocationConfig: AllocationConfigPropType.isRequired,
  allocationProject: AllocationProjectPropType.isRequired,
  selectedMembers: PropTypes.arrayOf(PersonPropType),
  data: PropTypes.shape({
    individualAllocations: PropTypes.arrayOf(IndividualAllocationPropType),
    members: PropTypes.shape({
      members: PropTypes.arrayOf(PersonPropType),
    }),
    sourceGroups: PropTypes.arrayOf(
      PropTypes.shape({
        group: GroupPropType,
      })
    ),
  }),
  onSourceGroupsSelected: PropTypes.func.isRequired,
};

export default SourceGroupsContainer;
