import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";

import {
  ACTIONS,
  useLookupData,
  useRootGroup,
} from "../../context/ForecastContext";

const getTypeName = (groupTypes, type) => {
  const typeConfig = groupTypes[type] || {};
  return typeConfig.shortName || typeConfig.name || type;
};

const TeamDetailsButton = ({
  group,
  dispatch,
  isDemand,
  isSupply,
  detailsLabel,
  isPageEditable = true,
}) => {
  const {
    isDemand: isRootDemand,
    isSource: isRootSupply,
    isLineOfBusiness,
  } = useRootGroup();
  const { groupTypesLookup } = useLookupData();

  // Only when the group type the same as root type or is line of bussiness
  const canEdit =
    ((isRootDemand === isDemand && isRootSupply === isSupply) ||
      isLineOfBusiness) &&
    isPageEditable;

  const actionType = canEdit
    ? ACTIONS.SHOW_TEAM_DETAILS_EDITING_DIALOG
    : ACTIONS.SHOW_TEAM_DETAILS_DIALOG;

  const handleViewTeamDetails = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch({
        type: actionType,
        targetGroup: group,
      });
    },
    [group, actionType, dispatch]
  );

  const suffix =
    detailsLabel || `${getTypeName(groupTypesLookup, group.type)} details`;

  return (
    <ActionsMenuItem onClick={handleViewTeamDetails}>
      {`${canEdit ? "Change" : "View"} ${suffix}`}
    </ActionsMenuItem>
  );
};

TeamDetailsButton.propTypes = {
  group: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isDemand: PropTypes.bool,
  isSupply: PropTypes.bool,
  detailsLabel: PropTypes.string,
  isPageEditable: PropTypes.bool,
};

export default TeamDetailsButton;
