export const ADD_PERSON_TO_TAG_SUCCESS_MESSAGE = "Person tagged successfully";
export const ADD_GROUP_TO_TAG_SUCCESS_MESSAGE = "Group tagged successfully";
export const ADD_PERSON_TO_TEAM_SUCCESS_MESSAGE = "Member successfully added";
export const ADD_ENTITY_TO_TEAM_SUCCESS_MESSAGE = "Group successfully added";

export const TAG_STATIC_ATTRIBUTE = "static_attributes";
export const TAG_STATIC_ATTRIBUTE_BG_TYPE = "bgImage";

export const TAG_ENTITY_PERSON_TYPE = "person";
export const TAG_ENTITY_TEAM_TYPE = "group";

export const PERSON_SEARCH_PLACEHOLDER = "Search to add tag to person";
export const PERSON_SEARCH_PLACEHOLDER_DISABLED =
  "Tagging new people is currently disabled for this tag";

export const TEAM_SEARCH_PLACEHOLDER = "Search to add tag to team";
export const TEAM_SEARCH_PLACEHOLDER_DISABLED =
  "Tagging new team is currently disabled for this tag";

export const ENTITIES_BY_TAG_ID_LIMIT = 200;
export const MIN_TEAM_NAME_LENGTH = 3;
export const ILLEGAL_TEAM_NAME_CHARACTERS = /[^A-Za-z0-9-_&()+=\s:>,/"']/g;

export const GROUPS_SORT_OPTIONS = [
  { value: "groupByName", label: "Sort by Name" },
  { value: "groupByType", label: "Sort by Type" },
];

export const FTE_SUM_TYPE = {
  CAPACITY_FTE: "CAPACITY_FTE",
  ALLOCATED_FTE: "ALLOCATED_FTE",
};

export const FTE_SUM_TARGET = {
  ALL_MEMBERS: "ALL_MEMBERS",
  CHILD_GROUPS: "CHILD_GROUPS",
  DIRECT_MEMBERS: "DIRECT_MEMBERS",
};
