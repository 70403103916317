import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

import {
  useDispatch,
  useExpandedGroupIds,
  useExpandedLineItemsGroupIds,
  useExpandedMemberListIds,
  useExpandedObjectivesIds,
  useExpandedTagsIds,
  useFinancialForecasting,
  useLookupData,
  useRootGroup,
  useSearchMode,
  useGroupSearchMode,
  useSearchResult,
  useViewMode,
} from "../../context/ForecastContext";
import TreeIndentWrapper from "../Shared/TreeIndentWrapper";
import GroupingExpandHeader from "./GroupingExpandHeader";
import LineItems from "./LineItems";
import Objectives from "./Objectives";
import GroupingTags from "./GroupingTags";

const PlannerGrouping = ({ grouping }) => {
  const { activeAllocationProject, hideApproverColumn } = useLookupData();
  const viewMode = useViewMode();
  const searchMode = useSearchMode();
  const groupSearchMode = useGroupSearchMode();
  const searchResult = useSearchResult();
  const expandedGroupIds = useExpandedGroupIds();
  const expandedLineItemsGroupIds = useExpandedLineItemsGroupIds();
  const expandedMemberListIds = useExpandedMemberListIds();
  const expandedObjectivesIds = useExpandedObjectivesIds();
  const expandedTagsIds = useExpandedTagsIds();
  const shouldShowCost = useFinancialForecasting();
  const dispatch = useDispatch();
  const { enableSetGroupTags } = useLookupData();

  const rootGroup = useRootGroup();
  // Hide the lineItems for LOB - Supply
  const showLineItems = !rootGroup.isLineOfBusiness;

  const {
    id,
    nestedLevel,
    childGroupings,
    canToggleLineItems,
    isDemand,
    isLineOfBusiness,
    isMovedOut,
  } = grouping;

  const isExpanded = expandedGroupIds[id] && !isMovedOut;
  const isLineItemsExpanded =
    expandedLineItemsGroupIds[id] || !canToggleLineItems;
  const isObjectivesExpanded = expandedObjectivesIds[id];
  const isTagsExpanded = expandedTagsIds[id];
  const showObjectives = isDemand || isLineOfBusiness;

  return useMemo(
    () => {
      if (
        (searchMode || groupSearchMode) &&
        searchResult &&
        !searchResult.expandedGroupIdsForSearch[id]
      ) {
        return null;
      }

      return (
        <>
          <GroupingExpandHeader
            grouping={grouping}
            isExpanded={isExpanded}
            viewMode={viewMode}
            dispatch={dispatch}
            hideApproverColumn={hideApproverColumn}
          />
          {isExpanded && (
            <TreeIndentWrapper indentLevel={nestedLevel}>
              {showObjectives && (
                <Objectives
                  isObjectivesExpanded={isObjectivesExpanded}
                  grouping={grouping}
                  dispatch={dispatch}
                  activeAllocationProject={activeAllocationProject}
                />
              )}
              {enableSetGroupTags && (
                <GroupingTags
                  isTagsExpanded={isTagsExpanded}
                  grouping={grouping}
                  dispatch={dispatch}
                  activeAllocationProject={activeAllocationProject}
                />
              )}
              {showLineItems && (
                <LineItems
                  grouping={grouping}
                  isExpanded={isLineItemsExpanded}
                  expandedMemberListIds={expandedMemberListIds}
                  dispatch={dispatch}
                  shouldShowCost={shouldShowCost}
                  hideApproverColumn={hideApproverColumn}
                  searchMode={searchMode}
                  groupSearchMode={groupSearchMode}
                  searchResult={searchResult}
                />
              )}
              {map(childGroupings, (childGrouping) => (
                <PlannerGrouping
                  key={childGrouping.id}
                  grouping={childGrouping}
                />
              ))}
            </TreeIndentWrapper>
          )}
        </>
      );
    },
    // eslint-disable-next-line
    [
      grouping.hash,
      searchMode,
      searchResult,
      isExpanded,
      isLineItemsExpanded,
      isObjectivesExpanded,
      isTagsExpanded,
      expandedMemberListIds,
    ]
  );
};

PlannerGrouping.propTypes = {
  grouping: PropTypes.object.isRequired,
};

export default PlannerGrouping;
