/* eslint-disable react/display-name */
import React, { useReducer, useMemo } from "react";
import PropTypes from "prop-types";
import constate from "constate";
import { useLocation } from "react-router-dom";
import { get } from "lodash";
import { TAGS_UI_COMPONENTS } from "src/consts/tags";
import { getVisibleTagTypes } from "src/util/tags";

import reducer from "./reducer";
import initialState from "./reducer/initialState";
import * as ACTIONS from "./reducer/actionTypes";

const useMainReducer = ({
  group,
  workspace,
  allocationProject,
  initialState: initialStateOverride,
}) => {
  const { state } = useLocation();
  const previousInteractionState = get(state, `userInteraction`);

  const requiredTagTypes = useMemo(
    () =>
      getVisibleTagTypes(
        workspace.config.tagConfig,
        TAGS_UI_COMPONENTS.SKILLS_REQUIREMENT
      ),
    [workspace]
  );

  const [contextState, dispatch] = useReducer(reducer, {
    ...initialState({
      parentGroup: group,
      workspace,
      allocationProject,
      previousInteractionState,
      requiredTagTypes,
    }),
    ...initialStateOverride,
  });

  return {
    contextState,
    dispatch,
  };
};

const [
  MainContextProvider,
  useDispatch,
  useMainQuery,
  useSecondaryQuery,
  useUserInteraction,
  useModel,
  useAllocationProject,
  useParentGroup,
  useWorkspace,
] = constate(
  useMainReducer,
  (value) => value.dispatch,
  ({ contextState }) => contextState.mainQuery,
  ({ contextState }) => contextState.secondaryQuery,
  ({ contextState }) => contextState.userInteraction,
  ({ contextState }) => contextState.model,
  ({ contextState }) => contextState.allocationProject,
  ({ contextState }) => contextState.parentGroup,
  ({ contextState }) => contextState.workspace
);

// a helpful simple HOC to easily provide the context
const withMainContextProvider =
  (WrappedComponent) =>
  ({ group, workspace, allocationProject }) => {
    // for some reason, this context is being re-created on internal state changes.
    // this memo stops it re-creating fore the same group, workspace, project combo.
    return useMemo(
      () => {
        return (
          <MainContextProvider
            group={group}
            workspace={workspace}
            allocationProject={allocationProject}
          >
            <WrappedComponent />
          </MainContextProvider>
        );
      },
      [group.id, workspace.id, allocationProject.id] // eslint-disable-line
    );
  };

export {
  ACTIONS,
  withMainContextProvider,
  MainContextProvider,
  useDispatch,
  useMainQuery,
  useSecondaryQuery,
  useUserInteraction,
  useModel,
  useAllocationProject,
  useParentGroup,
  useWorkspace,
};

MainContextProvider.propTypes = {
  group: PropTypes.object.isRequired,
  workspace: PropTypes.object.isRequired,
  allocationProject: PropTypes.object.isRequired,
  initialState: PropTypes.object,
  children: PropTypes.node,
};
