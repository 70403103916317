import { includes } from "lodash";
import { Small } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { DefaultTreeHeader } from "src/shared/TreeView/TreeView";

const CustomTreeHeader = ({
  node,
  onSelectGroup,
  targetGroupTypes,
  existingGroupIds,
  actionLabel = "Select",
  ...others
}) => {
  const { group } = node;

  const nodeStatus = () => {
    let status = null;
    if (
      group &&
      targetGroupTypes[group.type] &&
      !includes(existingGroupIds, group.id)
    ) {
      status = (
        <Small color="primary">
          <em>{actionLabel}</em>
        </Small>
      );
    }

    return status;
  };

  return (
    <DefaultTreeHeader
      node={node}
      {...others}
      nodeStatus={nodeStatus}
      onActivate={() => onSelectGroup(group, node)}
    />
  );
};

CustomTreeHeader.propTypes = {
  node: PropTypes.object,
  onSelectGroup: PropTypes.func,
  targetGroupTypes: PropTypes.object,
  actionLabel: PropTypes.string,
  existingGroupIds: PropTypes.array,
};

export default CustomTreeHeader;
