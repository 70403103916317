import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Spacer, Box } from "orcs-design-system";

import {
  attributeTypes,
  getAllAttributesByType,
  getDescriptionAttribute,
} from "src/util/group";
import RichTextContent from "src/components/RichTextContent";
import { createNoDescriptionText } from "src/util/createNoDescriptionText";
import LinksList from "./LinksList";

const TeamDescription = ({ group, attributes }) => {
  const teamDescriptionAttribute = getDescriptionAttribute(attributes);
  const links = getAllAttributesByType(attributes, attributeTypes.LINK);

  const descriptionValue = get(teamDescriptionAttribute, "value");
  const description = descriptionValue || null;

  if (description === null) {
    return (
      <Box m="r">
        <RichTextContent content={createNoDescriptionText(group)} />
      </Box>
    );
  }

  return (
    <Spacer pt="r" pb="r">
      <RichTextContent content={description} />
      <LinksList links={links} />
    </Spacer>
  );
};

TeamDescription.propTypes = {
  group: PropTypes.object,
  attributes: PropTypes.array,
};

export default TeamDescription;
