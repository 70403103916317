import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { get, keyBy, isEmpty } from "lodash";

import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import History from "src/components/History";
import LoadingPage from "src/components/LoadingPage";
import {
  convertCommentsToHistory,
  updatePlanningHistoryRecords,
} from "src/components/History/history.util";
import GroupPropType from "src/custom-prop-types/group";
import ErrorPage from "../../ErrorPage";
import {
  getAllocationProjects as getAllocationProjectsQuery,
  getDescendantGroups as getDescendantGroupsQuery,
  getGroups as getGroupsQuery,
  getGroupHistory as getGroupHistoryQuery,
} from "./teamHistory.graphql";

import { getFilters, getGroupIdsFromComments } from "./index.util";

export const GROUP_COMMENTS_LIMIT = 200;

const TeamHistory = ({ team }) => {
  const groupTypes = useGroupTypes();
  const { data: allocationProjectsData, loading: allocationProjectsLoading } =
    useQuery(getAllocationProjectsQuery);

  const { id: groupId } = team;
  const { data: groupsData, loading: groupIdsLoading } = useQuery(
    getDescendantGroupsQuery,
    {
      variables: {
        groupId,
      },
    }
  );

  const groups = get(groupsData, "groups", []);
  const existingGroups = useMemo(
    () => ({ ...keyBy(groups, "id"), [groupId]: team }),
    [groups, groupId, team]
  );

  const commentFilters = useMemo(() => {
    return getFilters();
  }, []);

  const {
    data: commentsData,
    loading: commentsLoading,
    error,
  } = useQuery(getGroupHistoryQuery, {
    variables: {
      input: {
        groupId,
        commentFilters,
        limit: GROUP_COMMENTS_LIMIT,
        includeChildGroups: true,
      },
    },
    fetchPolicy: "network-only",
    skip: !groupsData,
  });

  const otherGroupIds = useMemo(() => {
    const comments = get(commentsData, "result.comments");
    return getGroupIdsFromComments(comments, existingGroups);
  }, [commentsData, existingGroups]);

  const { data: otherGroupsData } = useQuery(getGroupsQuery, {
    variables: {
      groupIds: otherGroupIds,
    },
    skip: isEmpty(otherGroupIds),
  });

  const groupsMap = useMemo(() => {
    return {
      ...existingGroups,
      ...keyBy(get(otherGroupsData, "otherGroups", []), "id"),
    };
  }, [existingGroups, otherGroupsData]);

  const historyData = useMemo(() => {
    const comments = get(commentsData, "result.comments");
    return convertCommentsToHistory({ comments, groupsMap, groupTypes });
  }, [commentsData, groupTypes, groupsMap]);

  const historyRecords = useMemo(() => {
    return updatePlanningHistoryRecords({
      historyData,
      groupsMap,
      groupTypes,
      allocationProjects: allocationProjectsLoading
        ? []
        : allocationProjectsData.allocationProjects,
    });
  }, [
    historyData,
    groupsMap,
    groupTypes,
    allocationProjectsData,
    allocationProjectsLoading,
  ]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (groupIdsLoading || commentsLoading || allocationProjectsLoading) {
    return <LoadingPage waitingFor="getCommentsQuery" />;
  }

  return <History historyRecords={historyRecords} />;
};

TeamHistory.propTypes = {
  team: GroupPropType,
};

export default TeamHistory;
