import React from "react";
import PropTypes from "prop-types";
import { find, last, split, startsWith } from "lodash";
import { P, StyledLink } from "orcs-design-system";
import { TAGS } from "src/consts/comments";
import { teamDetailsPath } from "../../util/routing";
import AuthorTitle from "../AuthorTitle";
import GenericHistoryContent from "./GenericHistoryContent";

const GROUP_ID_PREFIX = TAGS.GROUP_ID("");

const GroupUpdateHistoryContent = ({ comment, groupsMap }) => {
  const { tags, content, authorTitle } = comment;
  const groupIdTag = find(tags, (t) => startsWith(t, GROUP_ID_PREFIX));
  const groupId = last(split(groupIdTag, ":"));
  const group = groupsMap[groupId];
  if (content.includes("GROUP_LINK_TOKEN")) {
    const splitContent = split(content, "GROUP_LINK_TOKEN");
    const preContent = splitContent[0];
    const postContent = splitContent.length > 1 ? splitContent[1] : "";
    if (!group) {
      const genericContent = preContent + groupId + postContent;
      return (
        <GenericHistoryContent
          content={genericContent}
          authorTitle={authorTitle}
        />
      );
    }
    return (
      <>
        <P>
          {preContent}
          <StyledLink to={teamDetailsPath(group)}>{group.name}</StyledLink>
          {postContent}
        </P>
        <AuthorTitle action="Changed" authorTitle={authorTitle} />
      </>
    );
  }

  return <GenericHistoryContent content={content} authorTitle={authorTitle} />;
};

GroupUpdateHistoryContent.propTypes = {
  comment: PropTypes.object,
  groupsMap: PropTypes.object,
};

export default GroupUpdateHistoryContent;
