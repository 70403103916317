/* istanbul ignore file */
import { themeGet } from "@styled-system/theme-get";

const customStyles = ({ theme }) => ({
  menu: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    color: themeGet("colors.greyDark")({ theme }),
    fontSize: themeGet("fontSizes.0")({ theme }),
    border: "none",
    borderRadius: "0",
    overflow: "visible",
    zIndex: 12,
    boxShadow: "none",
    marginBottom: "0",
    left: `calc(-${themeGet("appScale.sideNavSize")({ theme })} - 2px)`,
    width: `calc(100% + ${themeGet("appScale.sideNavSize")({ theme })} + 11px)`,
  }),
  menuList: (provided) => ({
    ...provided,
    overflow: "auto",
    color: themeGet("colors.greyDark")({ theme }),
    // width: `calc(100% + ${themeGet("space.s")({ theme })})`,
    // minWidth: "233px",
    backgroundColor: themeGet("colors.white")({ theme }),
    border: `1px solid ${themeGet("colors.greyLight")({ theme })}`,
    marginTop: themeGet("space.s")({ theme }),
    boxShadow: themeGet("shadows.boxDefault")({ theme }),
    borderRadius: themeGet("radii.2")({
      theme,
    }),
    padding: themeGet("space.xs")({
      theme,
    }),
  }),
  control: (provided) => ({
    ...provided,
    cursor: "pointer",
    height: "15px",
    minHeight: "15px",
    border: "none",
    backgroundColor: "transparent",
    fontSize: themeGet("fontSizes.0")({ theme }),
    overflow: "hidden",
    boxShadow: "none",
    "svg path": {
      transition: "fill 300ms ease-in-out",
    },
    "[class*='singleValue']": {
      transition: "color 300ms ease-in-out",
    },
    "&:focus-within, &:hover": {
      outline: "none",
      "svg path": {
        fill: themeGet("colors.primary")({ theme }),
      },
      "[class*='singleValue']": {
        color: themeGet("colors.primary")({ theme }),
      },
    },
  }),
  container: (provided) => ({
    ...provided,
    fontSize: themeGet("fontSizes.0")({ theme }),
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: themeGet("fontSizes.0")({ theme }),
    color: themeGet("colors.greyDark")({ theme }),
    marginLeft: "0",
    maxWidth: "210px",
    marginRight: "0",
    position: "relative",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0",
    height: "15px",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: themeGet("space.xxs")({ theme }),
    color: themeGet("colors.greyDark")({ theme }),
    "&:hover": {
      color: themeGet("colors.danger")({ theme }),
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    "> div": {
      padding: "0",
      height: "auto",
      "> svg": {
        height: "15px",
        width: "15px",
      },
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: themeGet("space.xxs")({ theme }),
    color: themeGet("colors.greyDark")({ theme }),
    "&:hover": {
      color: themeGet("colors.primary")({ theme }),
    },
  }),
  option: (provided) => ({
    ...provided,
    cursor: "pointer",
    backgroundColor: "transparent",
    fontSize: themeGet("fontSizes.0")({ theme }),
    color: themeGet("colors.greyDark")({ theme }),
    whiteSpace: "break-spaces",
    padding: `${themeGet("space.xs")({ theme })} ${themeGet("space.s")({
      theme,
    })}`,
    marginBottom: "4px",
    transition: "all 200ms ease-in-out",
    "&:hover": {
      backgroundColor: themeGet("colors.primaryLightest")({ theme }),
      borderRadius: themeGet("radii.2")({ theme }),
    },
    "&:last-of-type": {
      marginBottom: "0",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: themeGet("colors.greyDark")({ theme }),
    fontSize: themeGet("fontSizes.0")({ theme }),
  }),
});

export default customStyles;
