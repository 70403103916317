import { filter, includes, forEach } from "lodash";

export const getTypesWithParents = (targetGroupTypes, groupTypes) => {
  const typesWithParents = {};

  forEach(targetGroupTypes, (type) => {
    let nextParents = filter(groupTypes, (t) =>
      includes(t.childTypes, type.id)
    );
    const visited = {};

    while (nextParents.length > 0) {
      const parents = [];
      forEach(nextParents, (p) => {
        typesWithParents[p.id] = p;
        visited[p.id] = true;

        parents.push(
          ...filter(
            groupTypes,
            (t) => includes(t.childTypes, p.id) && !visited[t.id]
          )
        );
      });

      nextParents = parents;
    }
  });

  return { ...typesWithParents, ...targetGroupTypes };
};
