import React, { useCallback } from "react";
import { ActionsMenuItem } from "orcs-design-system";
import { NOOP } from "src/allocation/consts";

import {
  useMainQuery,
  useDispatch,
  ACTIONS,
} from "../../../context/ForecastContext";

const DefaultViewButton = () => {
  const { loading } = useMainQuery();
  const dispatch = useDispatch();

  const handleDefaultView = useCallback(() => {
    dispatch({
      type: ACTIONS.APPLY_DEFAULT_GROUPINGS_TREE_EXPANSION,
    });
  }, [dispatch]);

  return (
    <ActionsMenuItem
      data-testid="default-view-button"
      onClick={loading ? NOOP : handleDefaultView}
      className="guided-tour-allocation-forecast-default-view"
    >
      Reset to default view
    </ActionsMenuItem>
  );
};

export default DefaultViewButton;
