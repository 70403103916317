import { sortBy, sum } from "lodash";

const getNestedObjectives = (flatArray, loadMore) => {
  const topLevelObjectives = Object.values(
    flatArray.reduce((acc, item) => {
      if (!item.parentIds.length) {
        acc[item.id] = item;
      } else {
        item.parentIds.forEach((parentId) => {
          const parent = flatArray.find((i) => i.id === parentId);

          if (!parent) {
            acc[parentId] = {
              id: parentId,
              loadMore: () => loadMore(parentId),
              children: [],
            };
          }
        });
      }
      return acc;
    }, {})
  );

  const getChildren = (item) => {
    const children = [
      ...flatArray
        .filter((child) => child.parentIds.includes(item.id))
        .map((child) => getChildren(child)),
    ];

    return {
      ...item,
      children,
      memberCount: sum(
        children.map((child) => child.teamCount || child.memberCount || 0)
      ),
    };
  };

  return sortBy(topLevelObjectives.map(getChildren), "id");
};

export default getNestedObjectives;
