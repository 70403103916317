/* eslint-disable no-param-reassign */
import { map, reduce, some, forEach, sortBy, every } from "lodash";
import { TAG_OPERATIONS } from "src/consts/tags";
import { fullDisplayName } from "src/util/personName";

export const convertGroupingToTreeData = ({
  grouping,
  toggled = false,
  isRoot = false,
  isGrouping = false,
  isLineItem = false,
  parentNode = undefined,
  withChildGroups = true,
}) => {
  if (!grouping) {
    return [];
  }

  const { id, group, childGroupings, lineItems } = grouping;

  const node = {
    id,
    name: group.name,
    active: false,
    toggled,
    checked: false,
    isRoot,
    isGrouping,
    isLineItem,
    target: grouping,
    loading: false,
    loaded: false,
    parentNode,
  };

  const childLineItems = map(lineItems, (item) => {
    return convertGroupingToTreeData({
      grouping: item,
      isLineItem: true,
      parentNode: node,
      withChildGroups,
    });
  });

  let childGroups = [];
  if (withChildGroups) {
    childGroups = map(childGroupings, (child) => {
      return convertGroupingToTreeData({
        grouping: child,
        isGrouping: true,
        isLineItem: false,
        parentNode: node,
        withChildGroups,
      });
    });
  }

  node.children = [...childLineItems, ...childGroups];

  return node;
};

export const addMembersToGroup = (
  node,
  membersList,
  enableDeparturedPeopleTagEditing
) => {
  // Insert the people list into the node children
  // and sort the people by name.
  node.children = sortBy(
    map(membersList, (person) => {
      if (!enableDeparturedPeopleTagEditing && person.disabled) {
        return null;
      }

      return {
        id: person.aggregateId,
        name: fullDisplayName(person),
        active: false,
        toggled: false,
        checked: false,
        target: person,
        parentNode: node,
        isPerson: true,
        loading: false,
        children: null,
      };
    }).filter((p) => !!p),
    "name"
  );

  node.loaded = true;
};

export const loadMembersForNode = async ({
  node,
  refreshTree,
  loadMembers,
  enableDeparturedPeopleTagEditing,
}) => {
  const { isLineItem, loaded, target } = node;
  if (!isLineItem || loaded) {
    refreshTree();
    return;
  }

  node.loading = true;
  node.toggled = true;
  refreshTree();

  const requestorCell = target.cells[1];
  const { sourceGroupId, targetGroupId } = requestorCell || {};

  // Load members
  const membersList = await loadMembers({ sourceGroupId, targetGroupId });

  // Add members and refresh
  addMembersToGroup(node, membersList, enableDeparturedPeopleTagEditing);

  node.loading = false;
  refreshTree();
};

export const uncheckParentNode = (node, setSelectedGroups) => {
  const { parentNode } = node;
  if (parentNode) {
    if (parentNode.isRoot) {
      return;
    }

    if (parentNode.checked) {
      parentNode.checked = false;

      const { id: parentId } = parentNode;
      setSelectedGroups((sg) => ({ ...sg, [parentId]: false }));
      uncheckParentNode(parentNode, setSelectedGroups);
    }
  }
};

/**
 * The function checks the parent node if all children are checked.
 * @param {*} node
 * @param {*} setSelectedGroups
 * @returns
 */
export const checkParentNode = (node, setSelectedGroups) => {
  const { parentNode } = node;
  if (parentNode) {
    if (parentNode.isRoot) {
      return;
    }

    if (every(parentNode.children, "checked")) {
      parentNode.checked = true;
      const { id: parentId } = parentNode;
      setSelectedGroups((sg) => ({ ...sg, [parentId]: true }));

      checkParentNode(parentNode, setSelectedGroups);
    }
  }
};

export const toggleLineItemNode = async ({
  node,
  checked,
  setSelectedGroups,
  setSelectedPeople,
  refreshTree,
  loadMembers,
  enableDeparturedPeopleTagEditing,
}) => {
  // If line item node is in loading state, do nothing
  if (node.loading) {
    return;
  }

  if (node.checked === checked) {
    return;
  }

  node.checked = checked;

  setSelectedGroups((g) => ({ ...g, [node.id]: checked }));
  if (!checked) {
    uncheckParentNode(node, setSelectedGroups);
  } else {
    checkParentNode(node, setSelectedGroups);
  }

  await loadMembersForNode({
    node,
    refreshTree,
    loadMembers,
    enableDeparturedPeopleTagEditing,
  });

  // Tick all members
  setSelectedPeople((p) => {
    const members = reduce(
      node.children,
      (prev, c) => {
        // eslint-disable-next-line no-param-reassign
        prev[c.id] = checked ? c : null;
        c.checked = checked;
        return prev;
      },
      {}
    );
    return { ...p, ...members };
  });
};

export const togglePersonNode = ({
  node,
  checked,
  setSelectedGroups,
  setSelectedPeople,
}) => {
  if (node.checked === checked) {
    return;
  }

  node.checked = checked;
  setSelectedPeople((p) => ({ ...p, [node.id]: checked ? node : null }));

  // Check all children to untick or tick the parentNode
  const { parentNode } = node;
  const { id: parentId, children } = parentNode;
  if (checked) {
    const allChecked = every(children, (c) => c.checked);
    if (allChecked) {
      setSelectedGroups((sg) => ({ ...sg, [parentId]: true }));
    }
  } else {
    setSelectedGroups((sg) => ({ ...sg, [parentId]: false }));
    uncheckParentNode(node, setSelectedGroups);
  }
};

export const toggleGroupingNode = async ({
  node,
  checked,
  setSelectedGroups,
  setSelectedPeople,
  refreshTree,
  loadMembers,
  enableDeparturedPeopleTagEditing,
}) => {
  // If the grouping node is in loading state, do nothing
  if (node.loading) {
    return;
  }

  if (node.checked === checked) {
    return;
  }

  node.checked = checked;
  setSelectedGroups((g) => ({ ...g, [node.id]: checked }));

  node.toggled = true;

  // TODO: below expanding lineItem and groupings may cause the tree fire a lot of requests
  // to back end to load members at the same time. better to fire one request
  for (let i = 0; i < node.children.length; i += 1) {
    const child = node.children[i];
    const { isLineItem, isGrouping } = child;
    if (isLineItem) {
      toggleLineItemNode({
        node: child,
        checked,
        setSelectedGroups,
        setSelectedPeople,
        refreshTree,
        loadMembers,
        enableDeparturedPeopleTagEditing,
      });
    } else if (isGrouping) {
      toggleGroupingNode({
        node: child,
        checked,
        setSelectedGroups,
        setSelectedPeople,
        refreshTree,
        loadMembers,
        enableDeparturedPeopleTagEditing,
      });
    }
  }

  if (!checked) {
    uncheckParentNode(node, setSelectedGroups);
  }
};

export const buildMutationList = (selectedPeople, selectedTags) => {
  const list = reduce(
    selectedPeople,
    (prev, node) => {
      if (!node || !node.target) {
        return prev;
      }

      const { aggregateId, tags } = node.target;
      forEach(selectedTags, (selectedTag) => {
        if (!selectedTag.value) {
          return;
        }

        if (some(tags, (t) => t.id === selectedTag.value)) {
          return;
        }

        prev.push({
          entityId: aggregateId,
          tagId: selectedTag.value,
          operation: TAG_OPERATIONS.ADDED,
        });
      });

      return prev;
    },
    []
  );

  return list;
};

export const getLineItems = (selectedPeople) => {
  const lineItems = reduce(
    selectedPeople,
    (prev, node) => {
      if (node && node.parentNode) {
        prev[node.parentNode.id] = false;
      }

      return prev;
    },
    {}
  );

  return lineItems;
};
