import React from "react";
import PropTypes from "prop-types";
import { Box } from "orcs-design-system";

import IndividualPersonAllocationPage from "src/allocation/pages/PersonAllocationPage/IndividualPersonAllocationPage";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import PersonMemberships from "src/components/PersonDetailPage/PersonMemberships";

import useNotifyStatus from "../../../../useNotifyStatus";
import { ACTIONS } from "../../../../context/ForecastContext";
import Header from "./Header";

const Layout = (...children) => {
  return <Box>{children}</Box>;
};

const PersonMembershipsLayout = (body) => {
  return <Box>{body}</Box>;
};

const PersonAllocation = ({
  person,
  supplySourceGroup,
  groupTypes,
  allocationProjectId,
  workspace,
}) => {
  const { enableFutureAllocationsUI } = useWorkspaceFeatureFlags();
  const { aggregateId: personId, fte: personFte } = person;
  const notifyStatus = useNotifyStatus(
    ACTIONS.TOGGLE_BUTTON_STATUS_ADD_MEMBER_MODAL
  );

  let body = null;
  if (enableFutureAllocationsUI) {
    body = (
      <PersonMemberships
        key="memberships"
        person={person}
        layoutFunction={PersonMembershipsLayout}
      />
    );
  } else {
    body = (
      <IndividualPersonAllocationPage
        allocationProjectId={allocationProjectId}
        personId={personId}
        personFte={personFte}
        workspace={workspace}
        groupTypes={groupTypes}
        layoutFunc={Layout}
        withSearch={false}
        notifyStatus={notifyStatus}
      />
    );
  }

  return (
    <Box mt="l">
      <Header person={person} supplySourceGroup={supplySourceGroup} />
      <Box mt="s">{body}</Box>
    </Box>
  );
};

PersonAllocation.propTypes = {
  person: PropTypes.object,
  supplySourceGroup: PropTypes.object,
  groupTypes: PropTypes.object,
  allocationProjectId: PropTypes.string,
  workspace: PropTypes.object,
};

export default PersonAllocation;
