import React from "react";
import { Flex, ProgressBar, Small } from "orcs-design-system";
import themeGet from "@styled-system/theme-get";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledProgressBar = styled(ProgressBar)`
  & > div {
    background: ${(props) =>
      themeGet(`colors.${props.color || "successLight"}`)};
  }
`;

const ObjectiveProgressBar = ({ progress, width = "100%" }) => {
  return (
    <Flex alignItems="center" width={width}>
      <StyledProgressBar
        ariaLabel="Percent Completed"
        containerWidth={100}
        fillWidth={Number(progress)}
        mr="s"
      />
      <Small fontWeight="300">{progress}%</Small>
    </Flex>
  );
};

ObjectiveProgressBar.propTypes = {
  progress: PropTypes.number,
  width: PropTypes.string,
};

export default ObjectiveProgressBar;
