/* eslint-disable import/prefer-default-export */
import {
  map,
  filter,
  includes,
  sortBy,
  trim,
  some,
  forEach,
  unionBy,
} from "lodash";

import Fuse from "fuse.js";
import strind from "strind";

const options = {
  includeMatches: true,
  shouldSort: false,
  tokenize: true,
  matchAllTokens: true,
  findAllMatches: true,
  threshold: 0.0,
  location: 0,
  distance: 0,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ["name"],
};

const search = (items, searchTerm) => {
  const fuse = new Fuse(items, options);
  const searchResult = fuse.search(searchTerm);
  return map(searchResult, (result) => {
    const { item, matches } = result;
    const highlighted = [];
    forEach(matches, (match) => {
      const { indices, key, value } = match;
      const output = strind(
        value,
        indices,
        ({ chars: text, matches: isHighlighted }) => ({
          text,
          isHighlighted,
        })
      );
      highlighted.push({ key, value, indices: output.matched });
    });

    return { ...item, highlighted };
  });
};

export const gatherAndSearchForRelevantSourceGroups = (
  allCurrentSourceGroups,
  originalPlannedSourceGroups,
  extraPlannedSourceGroups,
  members,
  searchTerm
) => {
  const membersCurrentSourceGroups = filter(
    allCurrentSourceGroups,
    (sourceGroup) =>
      some(members, (member) => includes(member.sourceGroupIds, sourceGroup.id))
  );
  const allRelevantSourceGroups = unionBy(
    membersCurrentSourceGroups,
    originalPlannedSourceGroups,
    extraPlannedSourceGroups,
    "id"
  );

  const membersSourceGroups = sortBy(allRelevantSourceGroups, "hierarchy");

  if (searchTerm) {
    return search(membersSourceGroups, trim(searchTerm));
  }

  return membersSourceGroups;
};
