import { Button, Icon, Box, Flex, FlexItem, Divider } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";
import MacroInput from "src/components/_DirectoryMacroInput";

const IndividualAllocationRow = ({
  allocation,
  isRemovingFromGroup,
  onUpdateGroupFte,
  onRemoveFromGroup,
  decimalScale = 2,
  groupTypes,
  enableSavingZeroFte,
  personFte,
  enableFteCap,
}) => {
  const { allocationId, targetGroupId, targetGroup, fte, status } = allocation;
  const onAllocationUpdate = (delta, totalFte) => {
    return onUpdateGroupFte(allocationId, targetGroupId, totalFte, targetGroup);
  };
  const { targetGroupId: removedTargetGroupId, isRemoving } =
    isRemovingFromGroup;

  const isBtnLoading = removedTargetGroupId === targetGroupId && isRemoving;
  const isBtnDisabled = isRemoving;

  return (
    <>
      <Box py="r" pr="s" pl="1px">
        <Flex
          alignItems={["flex-start", "flex-start", "center"]}
          flexDirection={["column", "column", "row"]}
        >
          <FlexItem flexGrow="1">
            <GroupHierarchyBreadcrumb
              group={targetGroup}
              groupTypes={groupTypes}
            />
          </FlexItem>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            width={["100%", "100%", "auto"]}
            mt={["r", "r", "0"]}
          >
            <MacroInput
              autoWidth={true}
              min={0}
              max={enableFteCap ? personFte : undefined}
              baseValue={fte}
              isDisplayDelta={false}
              isDisplayStatus={false}
              onChange={onAllocationUpdate}
              status={status}
              decimalScale={decimalScale}
              enableSavingZeroFte={enableSavingZeroFte}
            />
            <Button
              variant="danger"
              small
              height="28px"
              iconLeft
              ml="r"
              isLoading={isBtnLoading}
              disabled={isBtnDisabled}
              onClick={onRemoveFromGroup(
                allocationId,
                targetGroupId,
                targetGroup
              )}
            >
              <Icon icon={["fas", "user-times"]} />
              Remove
            </Button>
          </Flex>
        </Flex>
      </Box>
      <Divider light />
    </>
  );
};

IndividualAllocationRow.propTypes = {
  allocation: PropTypes.shape({
    allocationId: PropTypes.string,
    targetGroupId: PropTypes.string,
    targetGroup: PropTypes.object.isRequired,
    fte: PropTypes.number,
    status: PropTypes.string,
  }),
  isRemovingFromGroup: PropTypes.shape({
    targetGroupId: PropTypes.string,
    isRemoving: PropTypes.bool,
  }),
  onRemoveFromGroup: PropTypes.func,
  onUpdateGroupFte: PropTypes.func,
  decimalScale: PropTypes.number,
  groupTypes: PropTypes.object,
  enableSavingZeroFte: PropTypes.bool,
  personFte: PropTypes.number,
  enableFteCap: PropTypes.bool,
};

export default IndividualAllocationRow;
