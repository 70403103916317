import React from "react";

import {
  VIEW_MODES,
  PAGE_MODES,
  usePageMode,
  useViewMode,
} from "../context/ForecastContext";
import PlannerGroupings from "./PlannerGroupings";
import ForecastGroupings from "./ForecastGroupings";
import ConstraintGroupings from "./ConstraintGroupings";

const Groupings = () => {
  const pageMode = usePageMode();
  const viewMode = useViewMode();

  const isConstraintsMode = viewMode === VIEW_MODES.GROUPED_BY_CONSTRAINTS;

  return (
    <div
      data-testid="page-forecast-panel"
      className="guided-tour-allocation-forecast-panel"
    >
      {pageMode === PAGE_MODES.FORECAST && isConstraintsMode && (
        <ConstraintGroupings />
      )}
      {pageMode === PAGE_MODES.FORECAST && !isConstraintsMode && (
        <ForecastGroupings />
      )}
      {pageMode === PAGE_MODES.PLANNER && <PlannerGroupings />}
    </div>
  );
};

export default Groupings;
