import themeGet from "@styled-system/theme-get";
import styled, { css } from "styled-components";

export const MasonryStyled = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: ${themeGet("space.r")};

  > div {
    outline: none;
  }
`;

export const MasonryCardContainer = styled.button`
  cursor: pointer;
  position: relative;
  padding: 0;
  overflow: hidden;
  border-radius: ${themeGet("radii.2")};
  transition: ${themeGet("transition.transitionDefault")};
  width ${(props) => `${props.width}px`};
  height ${(props) => `${props.height}px`};
  background ${(props) => props.color};
  opacity: ${(props) => (props.isSelected ? 1 : 0.9)};
  border-style: solid;
  border-color: ${(props) =>
    props.isSelected ? themeGet("colors.success") : themeGet("colors.white")};
    border-width: ${(props) => (props.isSelected ? "4px" : "1px")};
  ${(props) =>
    props.isSelected
      ? css`
          img {
            transform: scale(1.1);
          }
        `
      : css``};

  &:hover {
    opacity: 1;
    border-style: solid;
    border-color: ${(props) =>
      props.isSelected ? themeGet("colors.success") : themeGet("colors.black")};
    border-width: ${(props) => (props.isSelected ? "4px" : "1px")};
    box-shadow: ${(props) =>
      props.isSelected ? "none" : `0 2px 6px 0px rgba(0,0,0,0.3)`};
    img {
      transform: scale(1.1);
    }
  }
`;

export const MasonryLabel = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  background: ${themeGet("colors.black50")};
  color: ${themeGet("colors.white")};
  font-size: ${themeGet("fontSizes.0")};
  font-weight: ${themeGet("fontWeights.2")};
  padding: ${themeGet("space.2")} ${themeGet("space.1")};
`;

export const MasonryImage = styled.img`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  transition: ${themeGet("transition.transitionDefault")};
`;

export const MasonryAuthor = styled.a`
  color: inherit;
  text-decoration: none;
`;
