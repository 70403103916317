import { Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import CollapsedList from "src/components/CollapsedList";
import ExpandButton from "src/components/CollapsedList/ExpandButton";
import * as placeholder from "src/components/Placeholder/PlaceholderStyles";
import PersonPropType from "src/custom-prop-types/person";
import { getPeoplePath } from "src/util/paths";
import { getInitials, sortMembersByName } from "src/util/person";

const ShortMembersList = ({
  loading,
  members,
  listLength,
  toggleMembersList,
}) => {
  return (
    <Box mt="r">
      <CollapsedList
        loading={loading}
        items={members}
        sortItems={sortMembersByName}
        getItemInitials={getInitials}
        getLinkPath={getPeoplePath}
        listLength={listLength}
        onListClick={toggleMembersList}
        placeholder={
          <placeholder.Circle
            width={48}
            height={48}
            data-testid="short-members-list-placeholder"
          />
        }
      />
      <ExpandButton onClick={toggleMembersList} />
    </Box>
  );
};

ShortMembersList.propTypes = {
  loading: PropTypes.bool,
  members: PropTypes.arrayOf(PersonPropType),
  listLength: PropTypes.number,
  toggleMembersList: PropTypes.func,
};

export default ShortMembersList;
