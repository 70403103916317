import { get, set, map, groupBy, filter, reduce, entries, every } from "lodash";

import { STATUS_UNALLOCATED } from "src/allocation/consts";
import { isWithinHierarchy } from "src/allocation/util/group";

/**
 * This function will inspect one given allocation and determine if it represents
 * a possible removal
 *
 * @param {object} group The subject group
 * @param {object} allocation The allocation to inspect
 * @returns {boolean} true if allocations represents removal
 */
const isRemovalAllocationOrEquivalent = (group, allocation) => {
  // yes, they've been unallocated
  if (allocation.status === STATUS_UNALLOCATED) {
    return true;
  }

  // they've been allocated, but to an outside group so this still represents a removal
  if (
    !isWithinHierarchy(
      group.hierarchyIds,
      get(allocation, "targetGroup.hierarchyIds")
    )
  ) {
    return true;
  }

  // any other allocation should mean they are still allocated to this group and not removed
  return false;
};

/**
 * This function will inspect all given allocations and determine if they all represent
 * a removal from this group
 *
 * @param {object} group The subject group
 * @param {object[]} individualAllocationsForPerson collection of allocations for 1 person
 * @returns {boolean} true if all allocations line up to represent removal
 */
const isFullyUnallocatedPerson = (group, individualAllocationsForPerson) => {
  return every(individualAllocationsForPerson, (allocation) =>
    isRemovalAllocationOrEquivalent(group, allocation)
  );
};

/**
 * This function is for combining current members with allocations (adds/removes)
 * to create a final list of future members for the group.
 *
 * It takes the original members of a group (currently allocated) and combines
 * the member's current allocations with the any future allocations. This creates a list
 * of members with mergedAllocations and accompanying meta info about their status
 * of either being added, removed, or no change. The returning result represents the
 * future state of all the members within the group.
 *
 * Note: The method assumes the members are a list of direct and indirect members.
 *
 * @param {object} group The subject group.
 * @param {object[]} members The current direct/indirect members of the subject
 *                           group
 * @param {object[]} newIndividualAllocations The future allocations concerning the
 *                                            subject group
 * @param {object} options Single named option detectAndRemoveUnallocatedMembers
 *                         which applies removal logic to the member, useful for
 *                         not removing
 * @returns {object} member map with meta data ie ( {id: {person, isNewlyAdded, isRemoved, mergedAllocations}} )
 */
export default (
  group,
  members,
  newIndividualAllocations,
  {
    detectAndRemoveUnallocatedMembers = false,
    filterUnrelatedAllocations = false,
  } = {}
) => {
  // create a list of original members and their allocations
  const originalMembers = reduce(
    members,
    (result, person) => {
      const { aggregateId: personId, allocations } = person;
      set(result, personId, {
        person,
        isNewlyAdded: false,
        isRemoved: false,
        mergedAllocations: map(allocations, (allocation) => ({
          ...allocation,
          personId, // add personId to allocation to be the same shape as newIndividualAllocations
        })),
      });
      return result;
    },
    {}
  );

  const allocationsOnlyRelatedToGroup = filterUnrelatedAllocations
    ? filter(newIndividualAllocations, (allocation) =>
        isWithinHierarchy(
          group.hierarchyIds,
          get(allocation, "targetGroup.hierarchyIds")
        )
      )
    : newIndividualAllocations;

  // merge the originalMembers with individualAllocations to create the final result
  const membersWithMergedAllocations = reduce(
    entries(groupBy(allocationsOnlyRelatedToGroup, "personId")),
    (result, [personId, newIndividualAllocationsForPerson]) => {
      const existingResult = result[personId];
      if (existingResult) {
        set(result, personId, {
          person: existingResult.person,
          isNewlyAdded: existingResult.isNewlyAdded,
          isRemoved:
            detectAndRemoveUnallocatedMembers &&
            isFullyUnallocatedPerson(group, newIndividualAllocationsForPerson),
          mergedAllocations: newIndividualAllocationsForPerson, // overwrite with new allocations
        });
      } else {
        set(result, personId, {
          person: newIndividualAllocationsForPerson[0].person, // should safely always have at least 1 result (groupBy)
          isNewlyAdded: true,
          isRemoved:
            detectAndRemoveUnallocatedMembers &&
            isFullyUnallocatedPerson(group, newIndividualAllocationsForPerson), // can still be a newly added & removed member.
          mergedAllocations: newIndividualAllocationsForPerson, // new allocations
        });
      }
      return result;
    },
    originalMembers
  );

  return membersWithMergedAllocations;
};
